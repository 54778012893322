/** @format */

import React from "react";
import PropTypes from "prop-types";
import { StaticStrings } from "../../../constants/static-string-constants";
import i18n from "../../../utils/i18n";
import Dialog from "../../../components/dialog/Dialog";

const CMEPostNoticeModal = ({
  // Modal Props
  toggle,
  isOpen,
  ...rest
}) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  const contactSupport = () => {
    window.location.href = `mailto:${StaticStrings.supportEmail}`;
    toggle();
  };

  /****************************** Render **************************************/
  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      toggle={toggle}
      body={
        <div className="w-100">
          <h2 className="d-flex align-items-center justify-content-center flex-grow-1 helv-med text-20 line-height-26 mt-2 mb-4">
            {i18n.t("CMECaseScreens.postNoticeTitle")}
          </h2>
          <p className="text-16 line-height-22">
            {i18n.t("CMECaseScreens.postNoticeBody")}
          </p>
        </div>
      }
      confirmText={i18n.t("CMECaseScreens.postNoticeContactButton")}
      cancelText={i18n.t("CMECaseScreens.postNoticeCloseButton")}
      onConfirm={contactSupport}
    />
  );
};

CMEPostNoticeModal.propTypes = {
  toggle: PropTypes.func,
  isOpen: PropTypes.bool
};

export default CMEPostNoticeModal;
