/** @format */

export const buildOptions = Object.freeze({
  DEV: "dev",
  QA: "qa",
  STAGING: "staging",
  RELEASE: "release",
});

export default {
  imgixUrl: "https://figure1.imgix.net/",
  versionBaseUrl: "https://s3.amazonaws.com/figure1-version-url/",
  buildEnv: buildOptions.QA,
  appSchema: "figure1pro-qa://",
  mixpanelToken: "586e3b5930216d86f3ce5d2723b75b60",
  mixpanelUrl: "https://tracking-proxy-qa.web.app/tracking",
  smartlookToken: "b6ce454d37b2f4a29ff5c3079fecbef7352fd688",
  uxcamToken: "1cyxryetfbn2hje",
  iterableAPIKey: "a2fa2de1fe7446a1bc057939a1bdc0b3",
  gtm_token: "GTM-WT3JTC7",
  gtm_auth: "yx3dUZnpExryXcf0PQmtUw",
  gtm_preview: "env-15",
  ipinfo: "5db3d1e380ae71",
};
