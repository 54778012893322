import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router";
import { fetchCaseLabel } from "../../actions/case-label.actions";
import {
  requestCaseSyncIfNeeded,
  resetCaseDetails,
  toggleCaseDetailsListener
} from "../../actions/case.actions";
import Loading from "../../components/loading";
import {
  CASE_CME_FRONTMATTER,
  CASE_CME_INELIGIBLE
} from "../../constants/routes";
import useCaseCmeEligible from "../../hooks/useCaseCmeEligible";
import { getUrl } from "../../utils/route-utils";
import CaseCMEFrontMatter from "./CaseCMEFrontMatter";
import CaseCMEIneligible from "./CaseCMEIneligible";
import CaseCMEPage from "./CaseCMEPage";

const CaseCME = ({ showIntro, ineligible }) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const history = useHistory();
  const routeParams = useParams();
  const caseId = routeParams.caseId;
  const caseDetails = useSelector((state) => state.case.cases[caseId]?.data);
  const eligibleCase = useCaseCmeEligible(caseDetails);

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    dispatch(fetchCaseLabel());
  }, [dispatch]);

  useEffect(() => {
    if (caseDetails && !eligibleCase && !ineligible) {
      history.replace(getUrl(CASE_CME_INELIGIBLE, { caseId: caseId }));
    }
    if (caseDetails && eligibleCase && ineligible) {
      history.replace(getUrl(CASE_CME_FRONTMATTER, { caseId: caseId }));
    }
  }, [caseDetails, eligibleCase, ineligible]);

  useEffect(() => {
    dispatch(requestCaseSyncIfNeeded(caseId));
    dispatch(toggleCaseDetailsListener(caseId, true));
    return () => {
      dispatch(toggleCaseDetailsListener(caseId, false));
      dispatch(resetCaseDetails(caseId));
    };
  }, [caseId, dispatch]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (!caseDetails) {
    return <Loading />;
  }

  if (ineligible) {
    return <CaseCMEIneligible caseId={caseId} />;
  }

  if (showIntro) {
    return <CaseCMEFrontMatter caseId={caseId} />;
  }

  return <CaseCMEPage caseId={caseId} />;
};

export default CaseCME;
