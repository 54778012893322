import React from "react";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import BaseDialogHeader from "../../../components/dialog/BaseDialogHeader";
import i18n from "../../../utils/i18n";

import CMESuccessPic from "../../../assets/images/CME_success.svg";
import { Link, NavLink } from "react-router-dom";
import { CASE_DETAIL, CME_ROOT } from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";
import { useSelectCurrentUser } from "../../../selectors/user.selectors";

const CaseCMESuccessModal = ({ onClose, isOpen = false, caseId }) => {
  const user = useSelectCurrentUser();

  return (
    <Modal isOpen={isOpen} toggle={onClose} onAbort={onClose}>
      <BaseDialogHeader onCancel={onClose} showCloseButton={true} />

      <ModalBody className="px-0 mt-5 pt-1">
        <div className="bg-lighter-gray px-4 py-3  d-flex justify-content-between align-items-center">
          <h5 className="helv-bold text-26 m-0 text-break">
            {i18n.t("CaseCME.completeModal.title")}
            <br />
            {user?.username}
          </h5>
          <img src={CMESuccessPic} alt="" width={100} />
        </div>

        <div className="mx-4 mb-3 py-3 d-flex justify-content-between border-bottom border-width-3 border-lighter-gray">
          <div className="text-22" style={{ fontWeight: "600" }}>
            {i18n.t("CaseCME.completeModal.creditsText")}
          </div>
          <div
            className="text-22 d-flex align-items-center"
            style={{ fontWeight: "600" }}>
            <CustomIcon icon="drawer_CME" className="icon-aqua" size={28} />
            <span>{i18n.t("CaseCME.creditsAmount")}</span>
          </div>
        </div>
        <p className="px-4 text-18 text-center m-0">
          {i18n.t("CaseCME.completeModal.description")}
        </p>
        <div className="text-18 text-center">
          <NavLink to={`${CME_ROOT}?completed=true`}>
            {i18n.t("CaseCME.completeModal.cmeLink")}
          </NavLink>
        </div>
      </ModalBody>
      <ModalFooter className="pt-4 px-5 pb-5 border-0">
        <div className="d-flex justify-content-center w-100">
          <Link
            className="btn btn-lg btn-primary py-3 px-4"
            to={`${getUrl(CASE_DETAIL, { caseId })}?similar=true`}>
            {i18n.t("CaseCME.completeModal.similarCasesLink")}
          </Link>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CaseCMESuccessModal;
