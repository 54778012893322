/** @format */

import React from "react";

const QuestionSection = ({ questionText, answerText }) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <div className="mb-4">
      <p className="m-0 text-14 line-height-21 mb-1">{questionText}</p>
      <p className="m-0 text-18 line-height-21">{answerText}</p>
    </div>
  );
};

export default QuestionSection;
