import React from "react";
import PropTypes from "prop-types";
import { ModalBody } from "reactstrap";
import classNames from "../../utils/class-names-utils";

const BaseDialogBody = ({ center, fullWidthBody, children, className }) => {
  return (
    <ModalBody
      className={classNames(
        "d-flex flex-column text-16",
        fullWidthBody ? "mx-0 px-0" : "mx-4",
        center
          ? "justify-content-center align-items-center text-center"
          : "justify-content-start align-items-start",
        className
      )}>
      {children}
    </ModalBody>
  );
};

BaseDialogBody.propTypes = {
  center: PropTypes.bool
};

BaseDialogBody.defaultProps = {
  center: false
};

export default BaseDialogBody;
