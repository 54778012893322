import React from "react";
import { Link } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import * as ROUTES from "../../constants/routes";
import THEMES from "../theme/Theme";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import i18n from "../../utils/i18n";
import { caseAuthor, getUserDisplayName } from "../../utils/user-utils";
import useIsGated from "../../hooks/useIsGated";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import useDialog from "../dialog/useDialog";
import AnonymousCaseModalContents from "../case/AnonymousCaseModalContents";
import F1Badge from "../common/F1Badge";
import AcceptedAnsIcon from "../../assets/images/accepted_answer.svg";
import useGatedActionGate from "../../hooks/useGatedActionGate";

const CommentHeader = ({
  caseContent,
  comment = {},
  reply,
  showEdited = true,
  showAcceptedAnswerBadge = true
}) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();
  const { inform } = useDialog();
  const { author = {} } = comment;
  const { userUuid = null } = author;
  const uid = userUuid ? userUuid : comment.authorUuid;
  const isGated = useIsGated();
  const isAcceptedAnswer = comment?.isAcceptedAnswer;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const gatedClick = useGatedActionGate(
    () => {},
    GATED_MODAL_TYPE.PROFILE,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.AVATAR_NAME
  );

  const anonClick = async () => {
    await inform({
      body: <AnonymousCaseModalContents />,
      primaryButtonText: i18n.t("common.confirm"),
      extra: {
        confirmClassName: "w-295px text-16 py-3"
      }
    });
  };
  /** ********************************** RENDER ***************************************/

  const topPadding = reply ? "pt-0" : "pt-2";
  const isCommentAuthorDeleted = comment?.author?.isDeleted;

  const caseAuthorObj = caseAuthor(caseContent);
  const isAnonymous = comment.isAnonymous || comment?.author.isAnonymous;

  const isCommentAuthorIsCaseAuthor =
    !!isAnonymous || comment?.author?.userUuid === caseAuthorObj?.userUuid;

  let byLine;
  let userCommentDisplayName = getUserDisplayName(comment?.author);

  let userLink = isGated ? (
    <div
      onClick={gatedClick}
      className={classNames(
        "cursor-pointer",
        "text-decoration-none",
        theme === THEMES.DARK ? "text-light" : "text-default-black"
      )}>
      <div className="font-weight-500">{comment.username}</div>
    </div>
  ) : (
    <Link
      className={classNames(
        "text-decoration-none",
        theme === THEMES.DARK ? "text-light" : "text-default-black"
      )}
      to={getUrl(ROUTES.PROFILE_DETAIL, { userUuid: uid })}>
      <div className="font-weight-500">{comment.username}</div>
    </Link>
  );
  if (isAnonymous) {
    userCommentDisplayName = null;
    byLine = i18n.t("caseDetail.anonymousCase.postedAnonymously");
    userLink = (
      <div
        onClick={() => (isGated ? gatedClick() : anonClick())}
        className={classNames(
          "cursor-pointer",
          "text-decoration-none",
          theme === THEMES.DARK ? "text-light" : "text-battleship-gray"
        )}>
        <div className="d-flex align-items-center">
          {author.professionName}
          {author.professionName && author.specialtyName && (
            <>
              {" "}
              <div className="divider divider-aqua divider-14 mx-1 mb-1" />{" "}
            </>
          )}
          {author.specialtyName}
        </div>
      </div>
    );
  }

  let content = isCommentAuthorDeleted ? (
    <p className="m-0 p-0 helv-med text-muted">
      {i18n.t("Profile.deletedAccount")}
    </p>
  ) : (
    <>
      <div className="d-flex align-items-center">
        {userLink}
        {userCommentDisplayName && (
          <>
            <div className="divider divider-aqua divider-14 mx-2 mb-1" />
            <div
              className={classNames(
                theme === THEMES.DARK ? "text-light-gray-blue" : "text-muted"
              )}>
              {userCommentDisplayName}
            </div>
          </>
        )}
      </div>
    </>
  );

  return (
    <div>
      <div
        className={`d-flex align-items-center justify-content-between ${topPadding}`}>
        {content}{" "}
        {comment?.edited && showEdited && (
          <span className="text-muted">
            {i18n.t("comments.editCommentLabel")}
          </span>
        )}
      </div>
      <div className="d-flex align-items-center">
        {isCommentAuthorIsCaseAuthor && (
          <F1Badge
            pill={false}
            textColor="white"
            color="battleship-gray"
            name={i18n.t("comments.author")}
            className={"mr-2 text-12 helv-med"}
          />
        )}
        {isAcceptedAnswer && showAcceptedAnswerBadge && (
          <F1Badge
            pill={false}
            textColor="default-black"
            color="lighter-gray"
            name={i18n.t("comments.acceptedAnswer")}
            className={"d-flex align-items-center text-13 helv-med"}
            iconImg={AcceptedAnsIcon}
            iconImgClassName={"w-18px h-18px mr-1"}
          />
        )}
        {byLine && <p className="mb-0 text-muted">{byLine}</p>}
      </div>
    </div>
  );
};

export default CommentHeader;
