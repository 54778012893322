import React from "react";
import { trackAcceptedAnswerEvent } from "../../../actions/metrics.actions";
import AcceptedAnsIcon from "../../../assets/images/accepted_answer.svg";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../custom-icon/CustomIcon";

const AcceptedAnswerCard = ({
  caseUuid,
  isCaseAuthor,
  acceptedAnswer,
  scrollToAcceptedAnswer
}) => {
  /** ********************************** CONFIG ***************************************/
  const isReported = acceptedAnswer?.isReported || acceptedAnswer?.reportedAt;
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const onClickAcceptedAnswerCard = () => {
    trackAcceptedAnswerEvent({
      event:
        METRICS_EVENT_NAME.COMMENT_OR_REPLY.ACCEPTED_ANSWER
          .CLICKED_ACCEPTED_ANSWER,
      commentUuid: acceptedAnswer.commentUuid,
      caseUuid: caseUuid
    });
    scrollToAcceptedAnswer();
  };

  /** ********************************** RENDER ***************************************/
  const AcceptedAnswerComponent = (
    <div className="d-flex cursor-pointer" onClick={onClickAcceptedAnswerCard}>
      <div className="d-flex align-items-start">
        <img src={AcceptedAnsIcon} alt="" />
      </div>
      <div className="w-100 ml-2 d-flex flex-column">
        <div className="helv-bold text-16 line-height-18">
          {i18n.t("comments.acceptedAnswerCard.title")}
        </div>
        <div className="mt-1 helv-med text-14 line-height-18 text-muted">
          {i18n.t("comments.acceptedAnswerCard.note", {
            username: acceptedAnswer?.author?.displayName
          })}
          <CustomIcon
            icon="double_chevron_2"
            className="ml-1 icon-battleship-gray"
            size={12}
          />
        </div>
      </div>
    </div>
  );

  const ReportedAcceptedAnswerComponent = (
    <div className="d-flex">
      <div className="d-flex align-items-start">
        <CustomIcon icon="reported_accepted_answer" size={20} />
      </div>
      <div className="w-100 ml-2 d-flex flex-column">
        <div className="helv-bold text-16 line-height-18">
          {i18n.t("comments.acceptedAnswerCard.reportedAnswerTitle")}
        </div>
        <div className="mt-1 helv-med text-14 line-height-18 text-muted">
          {i18n.t("comments.acceptedAnswerCard.reportedAnswerDescription")}
        </div>
      </div>
    </div>
  );

  if (isReported && !isCaseAuthor) {
    return null;
  }

  return (
    <>
      {isReported ? ReportedAcceptedAnswerComponent : AcceptedAnswerComponent}
      <hr className="border-lighter-gray border-width-3" />
    </>
  );
};

export default AcceptedAnswerCard;
