import React from "react";
import { compose } from "recompose";
import { connect } from "react-redux";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { GTMProvider } from "@elgorditosalsero/react-gtm-hook";

import UnauthenticatedApp from "./pages/unauthenticated-app";
import HomeApp from "./pages/home-app";
import Search from "./pages/search/Search";
import withAuthentication from "./components/session/with-authentication";
import * as ROUTES from "./constants/routes";
import { getUrl } from "./utils/route-utils";
import { fetchTopicsIfNeeded as _fetchTopicsIfNeeded } from "./actions/topics.actions";
import {
  initMetricsService,
  initSuperProps,
  toggleTracking
} from "./actions/metrics.actions";
import {
  activateListener as _activateConfigurationListeners,
  fetchVersionConfigs as _fetchVersionConfigs
} from "./actions/configuration.actions";
import ThemeProvider from "./components/theme/ThemeProvider";
import DialogProvider from "./components/dialog/DialogProvider";
import ScrollRestoreProvider from "./components/scroll-restore/ScrollRestoreProvider";
import PrivateRoute from "./components/common/PrivateRoute";
import CaseDetail from "./pages/case-detail/CaseDetail";
import Profile from "./pages/profile/Profile";
import SavedCases from "./pages/saved-cases/SavedCases";
import Notifications from "./pages/notifications/Notifications";
import Drafts from "./pages/drafts/Drafts";
import SplashPage from "./pages/authentication/SplashPage";
import LoginPage from "./pages/authentication/LoginPage";
import ResetPasswordPage from "./pages/authentication/ResetPasswordPage";
import OnboardingNonHcpPage from "./pages/authentication/OnboardingNonHcpPage";
import ClinicalMoments from "./pages/clinical-moments/ClinicalMoments";
import CME from "./pages/cme/CME";
import BrowserBlock from "./pages/authentication/components/BrowserBlock";
import { isEdge, isEdgeChromium } from "react-device-detect";
import { METRICS_EVENT_PROPERTY } from "./metrics/constants.metrics";
import appSettings from "./appSettings";
import RedirectPage from "./pages/legacy/RedirectPage";
import ProfileUsernameRedirect from "./pages/profile/ProfileUsernameRedirect";
import NotFoundPage from "./pages/authentication/404Page";
import Settings from "./pages/settings/SettingsPage";
import InterestSettings from "./pages/settings/InterestSettings";
import SettingsLanding from "./pages/settings/SettingsLanding";
import Cases from "./pages/cases/Cases";
import UngatedRoute from "./components/common/UngatedRoute";
import NetworkPage from "./pages/profile/NetworkPage";

import AppConfig from "./AppConfig";
import QueryPreserveRedirect from "./components/common/QueryPreserveRedirect";
import CreateRegistrationPageV2 from "./pages/authentication/CreateRegistrationPage.v2";
import VerificationPageV2 from "./pages/authentication/VerificationPage.v2";
import PhotoUploadPage from "./pages/authentication/PhotoUploadPage";
import SelectUsernamePage from "./pages/authentication/SelectUsernamePage";
import RegistrationCountryPageV2 from "./pages/authentication/RegistrationCountryPage.v2";
import LegacyOnboardingPage from "./pages/authentication/LegacyOnboardingPage";
import { FEED_NAMES } from "./constants/feed-type";
import ClaimGroupPage from "./pages/authentication/ClaimGroupPage";
import GroupWelcomeSplashPage from "./pages/authentication/GroupWelcomeSplashPage";
import withLocalization from "./components/common/WithLocalization";
import moment from "moment";
import { toggleStateListeners } from "./actions/login.actions";
import ProfileSettings from "./pages/settings/ProfileSettings";
import ProfileVerificationSettings from "./pages/settings/ProfileVerificationSettings";
import Groups from "./pages/groups/Groups";
import AccountSettings from "./pages/settings/AccountSettings";
import AccountDeletionPage from "./pages/settings/AccountDeletionPage";
import CaseCME from "./pages/case-cme/CaseCME";
import AutoReg from "./pages/authentication/auto-registration/AutoReg";
import AutoRegInformation from "./pages/authentication/auto-registration/AutoRegInformation";
import PostDraftDetails from "./pages/upload-case/PostDraftDetails";
import ForgotPasswordPage from "./pages/authentication/ForgotPasswordPage";
import GenerateLoginLinkPage from "./pages/authentication/GenerateLoginLinkPage";
import LoginWithLinkPage from "./pages/authentication/LoginWithLinkPage";
import CreateNewCasePostOptions from "./pages/upload-case/CreateNewCasePostOptions";
import CaseDraftDetails from "./pages/upload-case/CaseDraftDetails";

class App extends React.Component {
  componentDidMount() {
    const {
      initializeMetricsService,
      activateConfigurationListeners,
      fetchVersionConfigs
    } = this.props;

    initializeMetricsService();
    activateConfigurationListeners();
    fetchVersionConfigs();
    moment.updateLocale("en", {
      relativeTime: {
        future: "in %s",
        past: "%s ago",
        s: "1min",
        ss: "1min",
        m: "%dmin",
        mm: "%dmins",
        h: "%dh",
        hh: "%dh",
        d: "%dd",
        dd: "%dd",
        w: "%dw",
        ww: "%dw",
        M: "%dm",
        MM: "%dm",
        y: "%dy",
        yy: "%dy"
      }
    });
  }

  componentWillUnmount() {
    this.props.onUnsubscribeFirestoreListeners();
  }

  render() {
    const isBlocked = isEdge || isEdgeChromium;
    const gtmParams = {
      id: AppConfig.gtm_token,
      environment: {
        gtm_auth: AppConfig.gtm_auth,
        gtm_preview: AppConfig.gtm_preview
      }
    };

    const blockedView = isBlocked ? <BrowserBlock /> : null;

    return (
      <GTMProvider state={gtmParams}>
        <Router>
          <ThemeProvider>
            <DialogProvider>
              {blockedView ? (
                blockedView
              ) : (
                <>
                  <ScrollRestoreProvider>
                    <Routing />
                    <DeepLinkRouting />
                  </ScrollRestoreProvider>
                </>
              )}
            </DialogProvider>
          </ThemeProvider>
        </Router>
      </GTMProvider>
    );
  }
}

const Routing = () => {
  return (
    <>
      <Switch>
        <PrivateRoute path={ROUTES.HOME_ROOT_TOPIC} component={HomeApp} />
        <PrivateRoute path={ROUTES.HOME_ROOT_FEED} component={HomeApp} />
        <PrivateRoute path={ROUTES.HOME_ROOT} component={HomeApp} />

        <Route
          path={ROUTES.AUTO_REGISTRATION_INFORMATION}
          component={AutoRegInformation}
        />
        <Route path={ROUTES.AUTO_REGISTRATION} component={AutoReg} />

        <PrivateRoute
          path={ROUTES.GROUPS_FEED_INVITE}
          component={Groups}
          componentProps={{ showInviteModal: true }}
        />
        <PrivateRoute
          path={ROUTES.GROUPS_MEMBERS}
          component={Groups}
          componentProps={{ showMembersModal: true }}
        />
        <PrivateRoute path={ROUTES.GROUPS_FEED} component={Groups} />
        <PrivateRoute path={ROUTES.GROUPS_HOME} component={Groups} />

        <Route
          path={ROUTES.REGISTRATION_NEW_V2}
          component={CreateRegistrationPageV2}
        />
        <Route
          exact
          path={ROUTES.REGISTRATION_ONBOARDING_V2}
          component={LegacyOnboardingPage}
        />
        <Route
          path={ROUTES.REGISTRATION_COUNTRY_V2}
          component={RegistrationCountryPageV2}
        />
        <Route
          exact
          path={ROUTES.REGISTRATION_ONBOARDING_V2}
          component={LegacyOnboardingPage}
        />
        <Route
          path={ROUTES.REGISTRATION_INFORMATION_V2}
          component={VerificationPageV2}
        />
        <Route
          path={ROUTES.REGISTRATION_NPI_CONFIRMATION}
          component={AutoRegInformation}
        />
        <Route
          path={ROUTES.REGISTRATION_VERFICATION_PHOTO_V2}
          component={PhotoUploadPage}
        />
        <Route
          path={ROUTES.REGISTRATION_USERNAME_V2}
          component={SelectUsernamePage}
        />
        <Route
          path={ROUTES.REGISTRATION_ONBOARDING_NON_HCP}
          component={OnboardingNonHcpPage}
        />

        <PrivateRoute
          exact
          path={ROUTES.PROFILE_NETWORK}
          component={NetworkPage}
        />
        <PrivateRoute
          exact
          path={ROUTES.PROFILE_NETWORK_TYPE}
          component={NetworkPage}
        />
        <Route path={ROUTES.SIGN_IN} component={LoginPage} />

        <Route exact path={ROUTES.CLAIM_GROUP} component={ClaimGroupPage} />
        <Route
          exact
          path={ROUTES.CLAIM_GROUP_WITH_PROFILE}
          component={ClaimGroupPage}
        />
        <Route
          exact
          path={ROUTES.GROUP_WELCOME_SPLASH}
          component={GroupWelcomeSplashPage}
        />

        <Route
          path={ROUTES.FORGOT_PASSWORD_OPTIONS}
          component={ForgotPasswordPage}
        />
        <Route
          path={ROUTES.GENERATE_LOGIN_LINK}
          component={GenerateLoginLinkPage}
        />
        <Route path={ROUTES.RESET_PASSWORD} component={ResetPasswordPage} />

        <Route
          exact
          path={ROUTES.ACCEPT_LOGIN_LINK}
          component={LoginWithLinkPage}
        />

        <Route path={ROUTES.REGISTRATION} component={SplashPage} />

        <Route path={ROUTES.LEGACY_HELLO} component={RedirectPage} />
        <Route path={ROUTES.LEGACY_RD} component={RedirectPage} />
        <Route path={ROUTES.LEGACY_REDIRECT} component={RedirectPage} />

        <PrivateRoute
          exact
          path={ROUTES.CASE_CME_INELIGIBLE}
          component={CaseCME}
          componentProps={{ ineligible: true }}
        />
        <PrivateRoute
          exact
          path={ROUTES.CASE_CME_FRONTMATTER}
          component={CaseCME}
          componentProps={{ showIntro: true }}
        />
        <PrivateRoute exact path={ROUTES.CASE_CME} component={CaseCME} />
        <UngatedRoute
          path={ROUTES.CASE_COMMENT_DETAIL}
          component={CaseDetail}
        />

        <PrivateRoute
          path={ROUTES.CREATE_NEW_CASE_POST_OPTIONS}
          component={CreateNewCasePostOptions}
        />

        <UngatedRoute path={ROUTES.CASE_DETAIL} component={CaseDetail} />
        <PrivateRoute
          path={ROUTES.CASE_POSTING_NEW}
          component={CaseDraftDetails}
        />
        <PrivateRoute
          path={ROUTES.CASE_POSTING_EDIT}
          component={CaseDraftDetails}
        />

        <PrivateRoute path={ROUTES.POST_NEW} component={PostDraftDetails} />
        <PrivateRoute path={ROUTES.POST_EDIT} component={PostDraftDetails} />

        <PrivateRoute path={ROUTES.SEARCH_PATH} component={Search} />
        <PrivateRoute path={ROUTES.SEARCH_ROOT} component={Search} />
        <PrivateRoute
          path={ROUTES.PROFILE_USERNAME_REDIRECT}
          component={ProfileUsernameRedirect}
        />
        <PrivateRoute path={ROUTES.PROFILE_DETAIL} component={Profile} />
        <PrivateRoute path={ROUTES.SAVED_CASES_ROOT} component={SavedCases} />

        <PrivateRoute
          path={ROUTES.NOTIFICATIONS_ROOT}
          component={Notifications}
        />
        <PrivateRoute path={ROUTES.DRAFTS_ROOT} component={Drafts} />
        <UngatedRoute
          path={ROUTES.CLINICAL_MOMENTS_COMMENT_DETAIL}
          component={ClinicalMoments}
        />
        <UngatedRoute
          path={ROUTES.CLINICAL_MOMENTS_DETAIL}
          component={ClinicalMoments}
        />
        <PrivateRoute path={ROUTES.CME_ROOT} component={CME} />
        <PrivateRoute
          exact
          path={ROUTES.SETTINGS_ROOT}
          component={Settings}
          componentProps={{ childView: SettingsLanding }}
        />
        <PrivateRoute
          exact
          path={ROUTES.SETTINGS_INTERESTS}
          component={Settings}
          componentProps={{ childView: InterestSettings }}
        />
        <PrivateRoute
          exact
          path={ROUTES.SETTINGS_ACCOUNT}
          component={Settings}
          componentProps={{ omitSaveOption: true, childView: AccountSettings }}
        />
        <PrivateRoute
          exact
          path={ROUTES.SETTINGS_ACCOUNT_DELETE}
          component={AccountDeletionPage}
        />

        <PrivateRoute
          exact
          path={ROUTES.SETTINGS_PROFILE}
          component={Settings}
          componentProps={{ omitSaveOption: true, childView: ProfileSettings }}
        />

        <PrivateRoute
          exact
          path={ROUTES.SETTINGS_PROFILE_EDIT}
          component={Settings}
          componentProps={{ omitSaveOption: true, childView: ProfileSettings }}
        />

        <PrivateRoute
          exact
          path={ROUTES.SETTINGS_PROFILE_VERIFICATION}
          component={ProfileVerificationSettings}
        />
        <Route path={ROUTES.NOT_FOUND} component={NotFoundPage} />
        <UngatedRoute path={ROUTES.CASES} component={Cases} />
        <Route component={UnauthenticatedApp} />
      </Switch>
    </>
  );
};

const DeepLinkRouting = () => {
  return (
    <Switch>
      <QueryPreserveRedirect
        preserveQueryString={true}
        from="/user/detail/:userUuid"
        to={ROUTES.PROFILE_DETAIL}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        from="/feed(s?)/(mfy|rfy)"
        to={getUrl(ROUTES.HOME_ROOT_FEED, { feed: FEED_NAMES.RECOMMENDED })}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        from="/feed(s?)/everything"
        to={getUrl(ROUTES.HOME_ROOT_FEED, { feed: FEED_NAMES.EVERYTHING })}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        from="/feed(s?)/topic/:feedTypeUuid"
        to={getUrl(ROUTES.HOME_ROOT_TOPIC, {
          feed: FEED_NAMES.TOPIC,
          feedTypeUuid: ":feedTypeUuid"
        })}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        from="/drafts/new"
        to={ROUTES.CASE_POSTING}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        from="/drafts/detail/:draftId"
        to={ROUTES.CASE_POSTING_EDIT}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        from="/drafts/list"
        to={ROUTES.DRAFTS_ROOT}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        exact
        from="/cme/:caseId"
        to={ROUTES.CLINICAL_MOMENTS_DETAIL}
      />
      <QueryPreserveRedirect
        preserveQueryString={true}
        exact
        from="/cme/:caseId/comment/:commentId"
        to={ROUTES.CLINICAL_MOMENTS_COMMENT_DETAIL}
      />
    </Switch>
  );
};

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => ({
  fetchVersionConfigs: () => dispatch(_fetchVersionConfigs()),
  activateConfigurationListeners: () => {
    dispatch(_activateConfigurationListeners());
  },
  onUnsubscribeFirestoreListeners: () => {
    dispatch(toggleStateListeners());
  },
  initializeMetricsService: () => {
    dispatch(initMetricsService());
    dispatch(
      initSuperProps({
        [METRICS_EVENT_PROPERTY.SUPER.PLATFORM]: "web",
        [METRICS_EVENT_PROPERTY.SUPER.VERSION]: appSettings.version
      })
    );
    dispatch(toggleTracking(true));
  },
  fetchTopicsIfNeeded: () => {
    dispatch(_fetchTopicsIfNeeded());
  }
});

export default compose(
  withAuthentication,
  withLocalization,
  connect(mapStateToProps, mapDispatchToProps)
)(App);
