import { useDispatch } from "react-redux";
import { toggleSignUpModal } from "../actions/global.actions";

const useSignUpModalClick = () => {
  const dispatch = useDispatch();

  const showSignUpModal = (e) => {
    e?.preventDefault?.();
    e?.stopPropegation?.();

    dispatch(toggleSignUpModal(true));
  };

  const hideSignUpModal = (e) => {
    e?.preventDefault?.();
    e?.stopPropegation?.();

    dispatch(toggleSignUpModal(false));
  };
  return { showSignUpModal, hideSignUpModal };
};

export default useSignUpModalClick;
