import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { get } from "lodash/fp";
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem
} from "reactstrap";
import {
  PROFILE_DETAIL,
  PROFILE_NETWORK_TYPE,
  SETTINGS_ROOT,
  SIGN_OUT
} from "../../constants/routes";
import F1Avatar from "../common/F1Avatar";
import DropdownItemLink from "../common/DropdownItemLink";
import { getUrl } from "../../utils/route-utils";
import appSettings from "../../appSettings";
import { isMobile } from "react-device-detect";

const ProfileDropdown = () => {
  /** ********************************** CONFIG ***************************************/

  const { t } = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const avatar = useSelector(get("user.avatar"));
  const username = useSelector(get("user.displayName"));
  const userUuid = useSelector(get("user.userUuid"));

  /** ********************************** RENDER ***************************************/

  return (
    <Dropdown
      className="dropdown-profile"
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}>
      <DropdownToggle
        className="pl-3 cursor-pointer"
        tag="span"
        aria-expanded={isDropdownOpen}
        caret>
        <F1Avatar
          size={isMobile ? "sm" : null}
          avatar={avatar}
          username={username}
        />
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu--pointer-top-right" right>
        <DropdownItemLink to={getUrl(PROFILE_DETAIL, { userUuid })} exact>
          {t("navigation.my-profile")}
        </DropdownItemLink>
        <DropdownItemLink
          to={{
            pathname: getUrl(PROFILE_NETWORK_TYPE, {
              userUuid,
              viewType: "followers"
            }),
            state: {
              context: "Hamburger menu"
            }
          }}
          exact>
          {t("Network.MyNetwork")}
        </DropdownItemLink>
        <DropdownItemLink to={SETTINGS_ROOT}>
          {t("navigation.account-settings")}
        </DropdownItemLink>
        <DropdownItemLink to={SIGN_OUT}>
          {t("navigation.log-out")}
        </DropdownItemLink>
        <DropdownItem divider />
        <span tabIndex="-1" className="dropdown-item-text text-battleship-gray">
          Version: {appSettings.version}
        </span>
      </DropdownMenu>
    </Dropdown>
  );
};

ProfileDropdown.propTypes = {};

export default ProfileDropdown;
