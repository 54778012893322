import React from "react";
import PropTypes from "prop-types";
import BaseDialog from "./BaseDialog";
import BaseDialogButton from "./BaseDialogButton";
import DIALOG_TYPE from "./DialogType";
import BaseDialogHeader from "./BaseDialogHeader";
import BaseDialogBody from "./BaseDialogBody";
import BaseDialogFooter from "./BaseDialogFooter";
import CustomIcon from "../custom-icon/CustomIcon";

const Dialog = ({
  // Header Props
  title,
  subtitle,
  icon,
  iconSize = 40,
  showCloseButton = true,

  // Body Props
  body,
  fullWidthBody = false,

  // Footer props
  customFooter,

  // Confirm Button Props
  confirmText,
  confirmLoading,
  confirmDisabled,
  confirmClassName,

  // Cancel Button Props
  cancelText,
  cancelLoading,
  cancelDisabled,
  onCancelClick,
  cancelClassName,

  // Base Dialog Props
  type,
  isOpen,
  onConfirm,
  onCancel,
  onClosed,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const isInformDialog = type === DIALOG_TYPE.INFORM;
  const isGatedDialog = type === DIALOG_TYPE.GATED;
  const isConfirmReversedDialog = type === DIALOG_TYPE.CONFIRM_REVERSED;

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const handleCancelClick = (e) => {
    if (typeof onCancelClick === "function") onCancelClick(e);
    onCancel(e);
  };

  /************************************ RENDER ***************************************/

  return (
    <BaseDialog
      centered
      isOpen={isOpen}
      onCancel={onCancel}
      onClosed={onClosed}
      toggle={rest.backdrop ? onCancel : () => {}}
      {...rest}>
      <BaseDialogHeader
        center={!isInformDialog}
        onCancel={onCancel}
        showCloseButton={showCloseButton}>
        {icon && <CustomIcon icon={icon} size={iconSize} />}
        {title && <h4 className="mt-2 mb-0 helv-med text-18">{title}</h4>}

        {subtitle && (
          <h5 className="mb-0 mt-2 text-16 line-height-tight">{subtitle}</h5>
        )}
      </BaseDialogHeader>

      <BaseDialogBody center={!isInformDialog} fullWidthBody={fullWidthBody}>
        {isInformDialog && (title || subtitle) && (
          <div className="border-bottom border-width-4 border-lighter-gray w-100 mb-3" />
        )}
        {body}
      </BaseDialogBody>

      {!!customFooter ? (
        customFooter
      ) : (
        <BaseDialogFooter>
          {!!confirmText && (
            <BaseDialogButton
              block={true}
              outline={false}
              color={isConfirmReversedDialog ? "secondary" : "primary"}
              isLoading={confirmLoading}
              isDisabled={confirmDisabled}
              className={confirmClassName}
              onClick={onConfirm}>
              {confirmText}
            </BaseDialogButton>
          )}
          {!!cancelText && (
            <BaseDialogButton
              block={true}
              className={`mt-2 ${cancelClassName ? cancelClassName : ""}`}
              outline={isInformDialog || isGatedDialog}
              color={
                isInformDialog || isGatedDialog || isConfirmReversedDialog
                  ? "primary"
                  : "secondary"
              }
              isLoading={cancelLoading}
              isDisabled={cancelDisabled}
              onClick={handleCancelClick}>
              {cancelText}
            </BaseDialogButton>
          )}
        </BaseDialogFooter>
      )}
    </BaseDialog>
  );
};

Dialog.propTypes = {
  isOpen: PropTypes.bool,
  type: PropTypes.oneOf(Object.keys(DIALOG_TYPE)),
  title: PropTypes.node,
  subtitle: PropTypes.node,
  body: PropTypes.node,
  confirmText: PropTypes.string,
  cancelText: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  onClosed: PropTypes.func
};

Dialog.defaultProps = {
  type: DIALOG_TYPE.CONFIRM
};

export default Dialog;
