import React, { useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import FeedStructure from "./components/FeedStructure";
import { feedHasEOF } from "../../utils/feed-utils";
import feedActions from "../../actions/feeds.actions";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";
import { getAndParseLocalStorageItem } from "../../utils/local-storage-utils";
import LOCAL_STORAGE_CONSTANTS, {
  tooltipScreens
} from "../../constants/local-storage-constants";
import { useSelectCurrentUser } from "../../selectors/user.selectors";
import { isCaseCmeEligible } from "../../utils/case-utils";
import useUserCompletedOnboarding from "../../hooks/useUserCompletedOnboarding";
import { userSignedIn } from "../../utils/route-utils";

const TopicalFeedScreen = (props) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const feedStore = useSelector((state) => state.feeds?.feedList);
  const feedTypeUuid = useCurrentFeedTypeUuid(props?.isGroupFeed);
  const contentFeedFilters = useSelector(
    (state) => state.feeds.filters[feedTypeUuid] || {}
  );
  const hasActiveFilter = !!Object.values(contentFeedFilters).find((v) => !!v);
  const visibleFeed = feedTypeUuid ? feedStore[feedTypeUuid] : null;
  const dataToServe = visibleFeed?.feed || [];
  const hasEOF = visibleFeed?.eof || feedHasEOF(dataToServe);
  const isProcessing = visibleFeed?.isProcessing;
  const user = useSelectCurrentUser();
  const isUserCompleted = useUserCompletedOnboarding();
  const isSignedIn = useSelector((state) => userSignedIn(state.user));

  const tooltips =
    getAndParseLocalStorageItem(LOCAL_STORAGE_CONSTANTS.GET_CREDITS_TOOLTIP) ||
    {};
  const displayed = tooltips[tooltipScreens.FEED];

  const onRefresh = useCallback(() => {
    dispatch(feedActions.fetchFeed(feedTypeUuid, true));
  }, [dispatch, feedTypeUuid]);

  const loadMore = useCallback(() => {
    if (!hasEOF && !isProcessing) {
      dispatch(feedActions.fetchFeed(feedTypeUuid, true));
    }
  }, [dispatch, feedTypeUuid, hasEOF, isProcessing]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (feedTypeUuid && !dataToServe?.length && !isProcessing && !hasEOF) {
      dispatch(feedActions.fetchFeed(feedTypeUuid));
    }
  }, [dispatch, dataToServe, feedTypeUuid, isProcessing, hasEOF]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  if (
    !isProcessing &&
    (!dataToServe.length || (dataToServe.length === 1 && hasEOF)) &&
    (hasActiveFilter || props?.isGroupFeed)
  ) {
    return <>{props.emptyFeedComponent}</>;
  }

  if (!isProcessing && dataToServe.length && !displayed) {
    const ungated = !isSignedIn || !isUserCompleted;
    const firstCase = dataToServe.find((ele) =>
      isCaseCmeEligible({ user, ungated, caseData: ele })
    );

    if (firstCase) {
      Object.assign(firstCase, {
        showTooltip: true
      });
    }
  }

  return (
    <>
      <FeedStructure
        onRefresh={onRefresh}
        loadMore={loadMore}
        data={dataToServe}
        isLoading={isProcessing}
        isEOF={true}
        feedTypeUuid={feedTypeUuid}
        eofCard={props.eofComponent}
        isGroupFeed={props?.isGroupFeed || false}
      />
    </>
  );
};

export default TopicalFeedScreen;
