import { useSelector } from "react-redux";

const useCurrentGroup = (feedUuid) => {
  return useSelector((state) => {
    if (
      !state.groups?.data ||
      (state.groups?.data && state.groups?.isProcessing)
    )
      return undefined;

    return state.groups?.data?.[feedUuid];
  });
};

export default useCurrentGroup;
