import { useDispatch } from "react-redux";
import { getSpecialtiesOptions } from "../actions/case-specialties.actions";

const useCombinedSpecialties = () => {
  const dispatch = useDispatch();
  const specialtiesOptions = dispatch(getSpecialtiesOptions());
  const combinedSpecialties = [
    ...(specialtiesOptions?.specialties || []),
    ...(specialtiesOptions?.subSpecialties || [])
  ].sort((a, b) => {
    if (a.label < b.label) {
      return -1;
    }
    if (a.label > b.label) {
      return 1;
    }
    return 0;
  });
  return combinedSpecialties;
};

export default useCombinedSpecialties;
