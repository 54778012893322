/** @format */

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { capitalize } from "lodash";
import CME_SECTION, {
  CME_QUESTION_SECTIONS
} from "../../../constants/cme-section";
import useDialog from "../../../components/dialog/useDialog";
import useInitialSection from "../hooks/useInitialSection";
import i18n from "../../../utils/i18n";
import CMEModalSectionFrontMatter from "./CMEModalSectionFrontMatter";
import CMEModalSectionQuestions from "./CMEModalSectionQuestions";
import CMEModalSectionActivity from "./CMEModalSectionActivity";
import CMEModalSectionPostTestSummary from "./CMEModalSectionPostTestSummary";
import CMEModalSectionComplete from "./CMEModalSectionComplete";
import ShareCaseDialog from "../../../components/case/ShareCaseDialog";
import {
  SHARE_CONTEXT,
  SHARE_LOCATIONS
} from "../../../metrics/constants.metrics";

const CMEActivityModal = ({
  // Component Props
  caseUuid,
  // Modal Props
  toggle,
  isOpen,
  close,
  section,
  slideIndex,
  ...rest
}) => {
  /****************************** Config **************************************/
  const { confirm } = useDialog();
  const [currentSection, setCurrentSection] = useInitialSection(caseUuid);
  const caseContent = useSelector((state) => state.case.cases[caseUuid]?.data);
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  /****************************** Hooks ***************************************/

  useEffect(() => {
    if (section && caseContent) {
      navigateToSection(section);
    }
  }, [section, caseContent]);
  /****************************** Functions ***********************************/

  const navigateToSection = (targetSection) => {
    setCurrentSection(targetSection);
  };

  const onExit = async (showConfirmation = true) => {
    if (showConfirmation) {
      const confirmed = await confirm({
        message: i18n.t("CMECaseScreens.confirmExitTitle"),
        messageDetail: "",
        confirmText: capitalize(i18n.t("CMECaseScreens.confirmExitButton")),
        cancelText: capitalize(i18n.t("CMECaseScreens.continueButton"))
      });

      if (!confirmed) {
        return;
      }
    }

    toggle();
  };

  const onShareClick = () => {
    setIsShareDialogOpen(true);
  };

  /****************************** Render **************************************/

  return (
    <>
      <Modal
        className="h-screen w-screen mh-100 mw-100 m-0 overflow-hidden"
        contentClassName="h-100 border-0 rounded-0 bg-darkest mh-100 overflow-y-auto2 d-flex flex-column"
        isOpen={isOpen}
        backdrop="static"
        keyboard={false}
        {...rest}>
        {currentSection === CME_SECTION.FRONT_MATTER ? (
          <CMEModalSectionFrontMatter
            caseContent={caseContent}
            navigateToSection={navigateToSection}
            onExit={onExit}
            onShareClick={onShareClick}
            initialIndex={slideIndex}
          />
        ) : currentSection === CME_SECTION.POST_TEST_SUMMARY ? (
          <CMEModalSectionPostTestSummary
            caseContent={caseContent}
            navigateToSection={navigateToSection}
            onExit={onExit}
            onShareClick={onShareClick}
            initialIndex={slideIndex}
          />
        ) : CME_QUESTION_SECTIONS.includes(currentSection) ? (
          <CMEModalSectionQuestions
            caseContent={caseContent}
            sectionType={currentSection}
            navigateToSection={navigateToSection}
            onExit={onExit}
            onShareClick={onShareClick}
            initialIndex={slideIndex}
          />
        ) : currentSection === CME_SECTION.ACTIVITY ? (
          <CMEModalSectionActivity
            caseContent={caseContent}
            navigateToSection={navigateToSection}
            onExit={onExit}
            onShareClick={onShareClick}
            initialIndex={slideIndex}
          />
        ) : currentSection === CME_SECTION.COMPLETION ? (
          <CMEModalSectionComplete caseContent={caseContent} onExit={onExit} />
        ) : null}
      </Modal>
      <ShareCaseDialog
        isOpen={isShareDialogOpen}
        caseContent={caseContent ?? {}}
        location={SHARE_LOCATIONS.TOP_BAR}
        context={SHARE_CONTEXT.SLIDE_VIEW}
        onClosed={() => setIsShareDialogOpen(false)}
      />
    </>
  );
};

CMEActivityModal.propTypes = {
  caseUuid: PropTypes.string.isRequired
};

export default CMEActivityModal;
