/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import { toggleStateListeners } from "../../../actions/login.actions";
import { signUpV2 } from "../../../actions/non-user.actions";
import { fetchFallbackLocationsIfNeeded } from "../../../actions/reference.actions";
import { useSelectFallbackCountryAndRegions } from "../../../selectors/references.selectors";
import i18n from "../../../utils/i18n";
import { getUrl } from "../../../utils/route-utils";
import * as ROUTES from "../../../constants/routes";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { fetchInfoWithNpi } from "../../../actions/user-verification.actions";
import { trackRegistration } from "../../../actions/metrics.actions";
import { trim } from "lodash";
import { generatePassword } from "../../../utils/generatePassword";
import useRedirectLinkHook from "../../../hooks/useRedirectLinkHook";
import usePopup from "../../../components/popup/usePopup";
import { checkNpiNumber } from "../../../utils/form-utils";
import TOAST_TYPE from "../../../components/popup/ToastType";

const AutoRegHomePage = () => {
  /** ********************************** CONFIG ***************************************/
  const routeParams = useParams();
  const email = decodeURIComponent(routeParams.email);
  const npi = parseInt(routeParams?.npi);
  const dispatch = useDispatch();
  const history = useHistory();
  const onboardingNavigate = useOnboardingNavigation();
  const { countries } = useSelectFallbackCountryAndRegions();
  const defaultCountry = countries.find((c) => c.countryCode === "US");
  const { showToast } = usePopup();
  const [failed, setFailed] = useState(false);

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    dispatch(fetchFallbackLocationsIfNeeded());
  }, [dispatch]);

  useRedirectLinkHook();

  const signup = useCallback(async () => {
    if (
      email === undefined ||
      defaultCountry === undefined ||
      npi === undefined
    ) {
      return;
    }

    if (!checkNpiNumber(`${npi}`)) {
      showToast({
        message: "Invalid NPI number",
        toastType: TOAST_TYPE.ERROR,
        persist: false
      });
      setFailed(true);
      return;
    }

    const password = await generatePassword();
    if (password === undefined) {
      return;
    }

    trackRegistration({
      email: trim(email),
      country: defaultCountry,
      npi: npi
    });

    const result = await dispatch(
      signUpV2(email, password, defaultCountry?.value, null, npi)
    );

    if (result.error && result.message) {
      history.push(getUrl(ROUTES.SIGN_IN));
    } else {
      const npiRes = await dispatch(fetchInfoWithNpi(npi));
      if (npiRes.error && npiRes.message) {
        history.push(getUrl(ROUTES.SIGN_IN));
      } else {
        trackRegistration({
          registrationSuccess: true,
          email: trim(email),
          country: defaultCountry,
          npi: npi
        });
        dispatch(toggleStateListeners(result.authResult?.user, true));
        // TODO: make sure if we need this group welcome splash page or not here
        if (result.user?.group_uuids && result.user?.group_uuids.length > 0) {
          history.push(
            getUrl(ROUTES.GROUP_WELCOME_SPLASH, {
              groupUuid: result.user?.group_uuids[0]
            })
          );
        } else {
          onboardingNavigate(result.user);
        }
      }
    }
  }, [defaultCountry]);

  useEffect(() => {
    signup();
  }, [signup]);

  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <div className="w-100 vh-100 d-flex flex-column justify-content-center align-items-center">
      {failed ? (
        <h2 className="helv-bold text-22 line-height-28 text-center">
          {i18n.t("RegistrationScreens.autoRegistration.failure")}
        </h2>
      ) : (
        <>
          <span className="mb-4">
            <Spinner
              style={{ width: "4rem", height: "4rem" }}
              color="primary"
            />
          </span>
          <h2 className="helv-bold text-22 line-height-28 text-center">
            {i18n.t("RegistrationScreens.autoRegistration.title")}
          </h2>
          <p className="mt-2 text-18 line-height-26 text-center">
            {i18n.t("RegistrationScreens.autoRegistration.description")}
          </p>
        </>
      )}
    </div>
  );
};

export default AutoRegHomePage;
