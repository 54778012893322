/** @format */

import { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { setVideoState } from "../../actions/video-player.actions";

/**
 * Handle pausing videos based on in view when scrolling and
 * navigating between screens. Save video progress whenever
 * these events trigger, as well as when video component unmounts.
 */
const useInViewPlaybackControl = ({
  isInView,
  _setPause,
  _currentTimeRef,
  contentUuid
}) => {
  const dispatch = useDispatch();

  const saveVideoState = useCallback(
    (videoState) => {
      if (contentUuid) {
        dispatch(setVideoState(contentUuid, videoState));
      }
    },
    [dispatch, contentUuid]
  );

  // Set pause based on video being in scroll view
  useEffect(() => {
    _setPause(!isInView);
  }, [_setPause, isInView]);

  useEffect(() => {
    return () => {
      _setPause(true);
      // eslint-ignore-next-line
      saveVideoState({ currentTime: _currentTimeRef.current }); // eslint-disable-line
    };
  }, [_setPause, saveVideoState, _currentTimeRef]);
};

export default useInViewPlaybackControl;
