/** @format */
import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { isMobileOnly } from "react-device-detect";
import { CONTENT_TYPE } from "../../constants/content-type";
import CaseDetailCard from "./CaseDetailCard";
import QuestionDetailCard from "./QuestionDetailCard";
import QuizSummaryDetailCard from "./QuizSummaryDetailCard";
import CaseScientificLiteratureCard from "./CaseScientificLiteratureCard";
import SimilarCases from "../similar-cases/SimilarCases";
import i18n from "../../utils/i18n";
import { useHistory } from "react-router-dom";
import CaseDetailAuthor from "./CaseDetailAuthor";
import MoreForYouNavigation from "./MoreForYouNavigation";
import useIsGated from "../../hooks/useIsGated";
import Comments from "../../components/comments/Comments";

const ContentItem = (props) => {
  /** ********************************** CONFIG ***************************************/
  const { item } = props;

  const currentItemType = item.contentType || CONTENT_TYPE.BASIC;
  const similarCasesRef = useRef();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const isGated = useIsGated();
  const sponsoredGated = props?.item?.isSponsored && isGated;
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    const similar = urlParams.get("similar");

    if (similar) {
      similarCasesRef.current?.scrollIntoView({
        behavior: "smooth",
        block: "end"
      });
      window.history.replaceState(
        {},
        document.title,
        history.location.pathname
      );
    }
  }, [urlParams, similarCasesRef?.current, history]);
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  const commentsUi = isMobileOnly ? (
    <div className="feed-sidebar-right mb-2">
      <Comments
        caseContent={props?.caseContent}
        currentItem={props?.currentItem}
        commentStatus={props?.commentStatus}
        hideComments={!props?.features?.commentsEnabled}
        hideCommentsSwitch={props?.shouldHideCommentsSwitch}
        hideReactions={!props?.features?.reactionsEnabled}
        userReactions={props?.caseDetails?.userReactions || []}
        position={props?.position}
        commentId={props?.commentId}
        isMedicalCase={props?.item?.isCase}
      />
    </div>
  ) : null;

  return (
    <>
      {currentItemType === CONTENT_TYPE.BASIC && (
        <>
          <CaseDetailCard
            caseContent={props.item}
            userReactions={props.userReactions || []}
            commentStatus={props.commentStatus}
            campaignUuid={props.campaignUuid}
            videoPlayerConfig={{ forceAutoplay: true, forceMuted: true }}
            onShareClick={props.onShareClick}
          />
          {commentsUi}
          {item.features.similarCasesEnabled && item.isCase && (
            <SimilarCases
              ref={similarCasesRef}
              className="mb-2"
              relatedCases={props.relatedCases || []}
              title={i18n.t("caseDetail.similarCases")}
            />
          )}
          {props.casePublications.length > 0 && item.isCase && (
            <CaseScientificLiteratureCard
              caseUuid={item.caseUuid}
              casePublications={props.casePublications}
            />
          )}
          {!sponsoredGated && <MoreForYouNavigation />}
        </>
      )}
      {[
        CONTENT_TYPE.QUIZ_SERIES,
        CONTENT_TYPE.QUIZ,
        CONTENT_TYPE.POLL
      ].includes(currentItemType) && (
        <>
          <QuestionDetailCard
            caseContent={props.item}
            campaignUuid={props.campaignUuid}
            userReactions={props.userReactions || []}
            nbQuestions={props.nbQuestions}
            currentIndex={props.currentIndex}
            maxIndex={props.maxIndex}
            onNext={props.onNext}
            onPrev={props.onPrev}
            commentStatus={props.commentStatus}
            videoPlayerConfig={{ forceAutoplay: true, forceMuted: true }}
            onShareClick={props.onShareClick}
            showHeader={true}
          />
          {commentsUi}
          {!isGated && <MoreForYouNavigation />}
        </>
      )}

      {currentItemType === CONTENT_TYPE.QUIZ_SUMMARY && (
        <>
          <CaseDetailAuthor className="mb-2" caseContent={props.item} />
          <QuizSummaryDetailCard
            caseContent={props.item}
            campaignUuid={props.campaignUuid}
            Comment={props.userReactions || []}
            nbQuestions={props.nbQuestions}
            currentIndex={props.currentIndex}
            maxIndex={props.maxIndex}
            nbUserRightAnswers={props.nbUserRightAnswers}
            onPrev={props.onPrev}
            onShareClick={props.onShareClick}
          />
        </>
      )}
    </>
  );
};

ContentItem.propTypes = {
  nbUserRightAnswers: PropTypes.number,
  currentIndex: PropTypes.number,
  nbQuestions: PropTypes.number,
  userReactions: PropTypes.any, // it seems like the type varies from case to case?
  relatedCases: PropTypes.any,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  item: PropTypes.shape({
    caseUuid: PropTypes.string,
    contentUuid: PropTypes.string,
    title: PropTypes.string,
    questionAnswerDetails: PropTypes.string,
    externalLinkText: PropTypes.string,
    externalLinkUrl: PropTypes.string,
    buttonText: PropTypes.string,
    buttonUrl: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyUuid: PropTypes.string,
        depth: PropTypes.number,
        firstName: PropTypes.string,
        legacyAccount: PropTypes.bool,
        userUid: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        userUuid: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        lastName: PropTypes.string
      })
    ),
    author_uid: PropTypes.string,
    labels: PropTypes.array,
    allReactions: PropTypes.object,
    publishedAt: PropTypes.string,
    author_username: PropTypes.string,
    author_profession_label: PropTypes.string,
    userSaved: PropTypes.array,
    meshTerms: PropTypes.array,
    specialtyUuids: PropTypes.array,
    features: PropTypes.object,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        caseUuid: PropTypes.string,
        contentUuid: PropTypes.string,
        displayOrder: PropTypes.number,
        filename: PropTypes.string,
        height: PropTypes.number,
        mediaId: null,
        mediaUuid: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        uuid: PropTypes.string,
        width: PropTypes.number
      })
    ),
    userAnswer: PropTypes.string,
    questionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        displayOrder: PropTypes.number,
        isAnswer: PropTypes.bool,
        questionOptionUuid: PropTypes.string,
        text: PropTypes.string,
        votes: PropTypes.number
      })
    ),
    updates: PropTypes.array,
    displayOrder: PropTypes.number,
    heading: PropTypes.string,
    caption: PropTypes.string,
    contentType: PropTypes.string,
    commentCount: PropTypes.number,
    isFeedCard: PropTypes.bool,
    colour: PropTypes.string,
    sponsoredContent: PropTypes.shape({
      disclosureText: PropTypes.string,
      sponsoredText: PropTypes.string
    })
  }),
  casePublications: PropTypes.arrayOf(
    PropTypes.shape({
      caseUuid: PropTypes.string,
      displayOrder: PropTypes.number,
      pubMedId: PropTypes.string,
      pubUuid: PropTypes.string,
      source: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      uuid: PropTypes.string
    })
  ).isRequired,
  onShareClick: PropTypes.func.isRequired
};

export default ContentItem;
