import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setInitialReferrer } from "../actions/global.actions";

const useSetInitialReferrer = () => {
  const dispatch = useDispatch();
  const initialReferrer = useSelector((state) => state.global?.initialReferrer);
  const referrerVal = document.referrer;

  useEffect(() => {
    if (initialReferrer === null) {
      dispatch(setInitialReferrer(referrerVal));
    }
  }, [referrerVal, initialReferrer, dispatch]);
};

export default useSetInitialReferrer;
