/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

const REFERENCE_DOCUMENT = "topics";

export const fetchAll = async () => {
  try {
    const doc = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_NEW)
      .doc(REFERENCE_DOCUMENT)
      .get();

    if (doc.exists) {
      return doc.data();
    }
    return {};
  } catch (error) {
    console.error("There was an error fetching topics: ", error.message);
    throw new Error(`There was an error fetching topics: ${error.message}`);
  }
};
