import React from "react";
import { Nav, NavItem } from "reactstrap";
import {
  SETTINGS_ACCOUNT,
  SETTINGS_INTERESTS,
  SETTINGS_PROFILE
  // SETTINGS_GENERAL_SETTINGS,
  // SETTINGS_ACTIVITY_NOTIFICATIONS,
  // SETTINGS_CONTENT_NOTIFICATIONS
} from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";
import { NavLink } from "react-router-dom";

import "./SettingsNavigation.scss";
import useBreakpoint, { BREAKPOINT_SIZES } from "../../../hooks/useBreakpoint";
import i18n from "../../../utils/i18n";
import MobileNavigation from "./MobileNavigation";

const SettingsNavigation = () => {
  const size = useBreakpoint();
  if (
    size === BREAKPOINT_SIZES.EXTRA_SMALL ||
    size === BREAKPOINT_SIZES.SMALL
  ) {
    return <MobileNavigation />;
  }

  return (
    <Nav className="settings-navigation flex-column">
      <NavItem>
        <NavLink
          to={{
            pathname: getUrl(SETTINGS_PROFILE)
          }}
          activeClassName="active"
          className="nav-link">
          <div>{i18n.t("Settings.profileTitle")}</div>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          to={{
            pathname: getUrl(SETTINGS_INTERESTS)
          }}
          activeClassName="active"
          className="nav-link">
          <div>{i18n.t("Settings.yourInterestsTitle")}</div>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          to={{
            pathname: getUrl(SETTINGS_ACCOUNT)
          }}
          activeClassName="active"
          className="nav-link">
          <div>{i18n.t("Settings.manageAccountTitle")}</div>
        </NavLink>
      </NavItem>
    </Nav>
  );
};

export default SettingsNavigation;
