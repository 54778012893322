import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "../../components/dialog/Dialog";
import i18n from "../../utils/i18n";
import { useHistory, useParams } from "react-router-dom";
import useCurrentGroup from "../../hooks/useCurrentGroup";
import { useDispatch, useSelector } from "react-redux";
import groupActions from "../../actions/groups.actions";
import { showErrorMessage } from "../../actions/global.actions";
import { GROUPS_HOME } from "../../constants/routes";
import { trackGroupEvent, trackPageEntry } from "../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";

const LeaveGroupModal = ({ isOpen, toggle }) => {
  /************************************ CONFIG ***************************************/
  const routeParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const currentGroup = useCurrentGroup(routeParams?.feedTypeUuid);
  const userUuid = useSelector((state) => state.user?.userUuid);
  const isProcessing = useSelector((state) => state.groups?.isProcessing);

  /************************************ HOOKS ****************************************/
  useEffect(() => {
    if (isOpen) {
      trackPageEntry(METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_LEAVE_ENTRY, {
        groupUuid: currentGroup?.groupUuid
      });
    }
  }, [isOpen, currentGroup?.groupUuid]);
  /*********************************** FUNCTIONS *************************************/

  const leaveGroup = async () => {
    const groupUuid = currentGroup?.groupUuid;
    trackGroupEvent({
      eventName: METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_LEAVE,
      groupUuid
    });

    const result = await dispatch(
      groupActions.removeMembers(groupUuid, [userUuid])
    );

    if (result.error) {
      dispatch(showErrorMessage(i18n.t(result.error.message)));
      return;
    }
    history.push(GROUPS_HOME);
  };

  /************************************ RENDER ***************************************/

  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      toggle={toggle}
      body={
        <div className="w-100">
          <h2 className="d-flex align-items-center justify-content-center px-2 flex-grow-1 mx-0 mt-5 mb-3 helv-bold text-20 line-height-25">
            {i18n.t("GroupsFeed.LeaveGroupModal.title")}
          </h2>
        </div>
      }
      confirmText={i18n.t("GroupsFeed.LeaveGroupModal.leaveGroup")}
      confirmLoading={isProcessing}
      onConfirm={leaveGroup}
      cancelText={i18n.t("common.cancel")}
    />
  );
};

LeaveGroupModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

LeaveGroupModal.defaultProps = {};

export default LeaveGroupModal;
