// passwordOptions contains all necessary string data needed to generate the password
const passwordOptions = {
  num: "1234567890",
  specialChar: "!@#$%&()*+,^-./:<=>?[]_{~}|",
  lowerCase: "abcdefghijklmnopqrstuvwxyz",
  upperCase: "ABCDEFGHIJKLMNOPQRSTUVWXYZ"
};

const getRandomChar = (fromString) => {
  return fromString[Math.floor(Math.random() * fromString.length)];
};

// generate password for auto-registration
export const generatePassword = () => {
  // initial state for password information
  let passInfo = "";

  // list of chosen characters
  const passChars = [];

  // add numerical characters to password data
  passInfo += passwordOptions.num;
  // add a number to the list of chosen characters
  passChars.push(getRandomChar(passwordOptions.num));

  // add special characters to password data
  passInfo += passwordOptions.specialChar;
  // add a special character to the list of chosen characters
  passChars.push(getRandomChar(passwordOptions.specialChar));

  // add lowercase characters to password data
  passInfo += passwordOptions.lowerCase;
  // add a lowercase character to the list of chosen characters
  passChars.push(getRandomChar(passwordOptions.lowerCase));

  // add uppercase characters to password data
  passInfo += passwordOptions.upperCase;
  // add an uppercase character to the list of chosen characters
  passChars.push(getRandomChar(passwordOptions.upperCase));

  // while there aren't enough characters
  while (passChars.length < 20) {
    // choose a random char from charInfo
    passChars.push(getRandomChar(passInfo));
  }

  // shuffle the list of characters using Fisher-Yates algorithm
  // see https://stackoverflow.com/a/2450976/8376184
  for (let i = passChars.length - 1; i > 0; i--) {
    const swapIndex = Math.floor(Math.random() * (i + 1));
    const temp = passChars[i];
    passChars[i] = passChars[swapIndex];
    passChars[swapIndex] = temp;
  }

  // return the password character list concatenated to a string
  return passChars.join("");
};
