import React from "react";
import { capitalize } from "lodash";
import i18n from "../../utils/i18n";
import DialogContext from "./DialogContext";
import DIALOG_TYPE from "./DialogType";

function useDialog() {
  const dialogContext = React.useContext(DialogContext);

  return {
    confirm: async ({
      message,
      messageDetail,
      confirmText = capitalize(i18n.t("common.deleteString")),
      cancelText = capitalize(i18n.t("common.cancel")),
      extra = {}
    }) => {
      return dialogContext?.openConfirmation({
        type: DIALOG_TYPE.CONFIRM,
        title: message,
        subtitle: "",
        body: messageDetail,
        confirmText: confirmText,
        cancelText: cancelText,
        ...extra
      });
    },
    inform: async ({
      title,
      subtitle = "",
      body,
      primaryButtonText,
      primaryButtonLoading = false,
      primaryButtonDisabled = false,
      secondaryButtonText = "",
      secondaryButtonLoading = false,
      secondaryButtonDisabled = false,
      onSecondaryClick = () => {},
      extra = {}
    }) => {
      return dialogContext?.openConfirmation({
        type: DIALOG_TYPE.INFORM,
        title,
        subtitle,
        body,
        confirmText: primaryButtonText,
        confirmLoading: primaryButtonLoading,
        confirmDisabled: primaryButtonDisabled,
        cancelText: secondaryButtonText,
        cancelLoading: secondaryButtonLoading,
        cancelDisabled: secondaryButtonDisabled,
        onCancelClick: onSecondaryClick,
        ...extra
      });
    },
    cancelDialog: async () => {
      dialogContext?.cancelDialog();
    }
  };
}

export default useDialog;
