import React, { useState } from "react";
import i18n from "../../../utils/i18n";
import { useDispatch } from "react-redux";
import groupActions from "../../../actions/groups.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import "./InviteF1UsersFooter.scss";
import { trackGroupInviteFigure1Members } from "../../../actions/metrics.actions";

const InviteF1UsersFooter = ({
  selectedUsers,
  setSelectedUsers,
  currentGroup
}) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const [isProcessing, setIsProcessing] = useState(false);

  const selectedCount = selectedUsers.length;
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const sendInvite = async () => {
    try {
      trackGroupInviteFigure1Members({
        groupUuid: currentGroup?.groupUuid,
        userUuids: selectedUsers
      });
      setIsProcessing(true);
      const result = await dispatch(
        groupActions.inviteMembers({
          groupUuid: currentGroup.groupUuid,
          inviteeUuids: selectedUsers
        })
      );
      if (result.error) {
        throw new Error(result.message);
      }

      // reset selection
      setSelectedUsers([]);
    } catch (e) {
      console.log(e.message);
    } finally {
      setIsProcessing(false);
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <div className="d-flex align-items-center justify-content-between px-3 py-2 border-top invite-F1-user-footer">
      <h2
        className={`m-0 text-14 ${
          selectedCount > 0 ? "text-cool-blue" : "text-muted"
        }`}>
        {i18n.t("GroupsFeed.InviteF1UsersModal.membersSelected", {
          noOfMembers: selectedCount > 0 ? selectedCount : "No"
        })}
      </h2>
      <LoadingButton
        className="my-2 text-16 helv-med px-4"
        color="primary"
        disabled={selectedCount === 0}
        loading={isProcessing}
        onClick={sendInvite}>
        {i18n.t("GroupsFeed.InviteF1UsersModal.sendInvite")}
      </LoadingButton>
    </div>
  );
};

export default InviteF1UsersFooter;
