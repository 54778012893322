import React, { useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import { MAX_DIAGNOSIS_LENGTH } from "../../constants/case-constants";
import i18n from "../../utils/i18n";
import BaseDialogHeader from "../dialog/BaseDialogHeader";

const ResolvedCaseModal = ({ onSave, onCancel, draft, isOpen = true }) => {
  const [errors, setErrors] = useState({});
  const [diagnosis, setDiagnosis] = useState(draft?.diagnosis || "");

  const validateForm = (text) => {
    const newErrors = {};

    //validation
    if (!text.length) {
      newErrors.diagnosis = i18n.t("shareCase.resolvedModal.Error1");
    } else if (text.length > MAX_DIAGNOSIS_LENGTH) {
      newErrors.diagnosis = i18n.t("shareCase.resolvedModal.Error2");
    }
    setErrors(newErrors);
    return newErrors;
  };

  const localOnSave = () => {
    const newErrors = validateForm(diagnosis);

    if (!newErrors.diagnosis) {
      onSave({ diagnosis });
    }
  };

  const onTextChange = (e) => {
    validateForm(e.target.value);
    setDiagnosis(e.target.value);
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} onAbort={onCancel}>
      <BaseDialogHeader onCancel={onCancel} showCloseButton={true} />

      <ModalBody className="px-5 mt-5">
        <h5 className="helv-bold text-18">
          {i18n.t("shareCase.resolvedModal.Title")}
        </h5>
        <FormGroup className="mb-0">
          <Label
            for="diagnosis"
            className="d-flex text-nowrap justify-content-between">
            <span className="text-15 font-weight-500 text-default-black">
              {i18n.t("shareCase.resolvedModal.Label")}
            </span>
            <span className="text-muted">
              {diagnosis?.length || 0} / {MAX_DIAGNOSIS_LENGTH}
            </span>
          </Label>

          <TextareaAutosize
            className={
              errors.diagnosis
                ? "autoresize-textarea is-invalid"
                : "autoresize-textarea"
            }
            onChange={onTextChange}
            minRows={6}
            maxRows={6}
            placeholder={i18n.t("shareCase.resolvedModal.Placeholder")}
            value={diagnosis}
          />

          <FormFeedback>{errors.diagnosis}</FormFeedback>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="pt-4 px-5 pb-5 border-0">
        <div className="d-flex flex-column w-100">
          <Button block={true} size="lg" color="primary" onClick={localOnSave}>
            {i18n.t("shareCase.resolvedModal.ConfirmButton")}
          </Button>
          <Button block={true} size="lg" color="secondary" onClick={onCancel}>
            {i18n.t("shareCase.resolvedModal.CancelButton")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ResolvedCaseModal;
