import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import {
  requestCaseSyncIfNeeded,
  toggleCaseDetailsListener
} from "../../actions/case.actions";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import { getCaseTypeUrl } from "../../utils/case-utils";
import QueryPreserveRedirect from "../../components/common/QueryPreserveRedirect";
import AppPrompt from "../../components/app-prompt/AppPrompt";

const Cases = () => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const routeParams = useParams();
  const caseUuid = routeParams.caseId;
  const caseData = useSelector((state) => state.case.cases[caseUuid]?.data);
  const [targetUrl, setTargetUrl] = useState(null);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    dispatch(requestCaseSyncIfNeeded(caseUuid));
    dispatch(toggleCaseDetailsListener(caseUuid, true));
    return () => {
      dispatch(toggleCaseDetailsListener(caseUuid, false));
    };
  }, [caseUuid, dispatch]);

  useEffect(() => {
    if (caseData) {
      if (caseData.caseState === "NOTFOUND") {
        setTargetUrl(getUrl(ROUTES.NOT_FOUND));
      } else {
        setTargetUrl(getUrl(getCaseTypeUrl(caseData), { caseId: caseUuid }));
      }
    }
  }, [caseData, caseUuid]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (targetUrl) {
    return (
      <QueryPreserveRedirect
        preserveQueryString={true}
        to={{
          pathname: targetUrl
        }}
      />
    );
  }
  return (
    <Figure1Layout enableGatedBanner footer={<AppPrompt />}>
      <Figure1Page1Col />
    </Figure1Layout>
  );
};

export default Cases;
