/** @format */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { CLINICAL_MOMENTS_DETAIL } from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";
import i18n from "../../../utils/i18n";
import { withRouter } from "react-router-dom";
import { trackDownloadCertificate } from "../../../actions/metrics.actions";
import * as ROUTES from "../../../constants/routes";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import LinesEllipsis from "react-lines-ellipsis";
import { Button } from "reactstrap";
import CMESummaryModal from "./CMESummaryModal";
import { Constants, Utils } from "@figure1/f1-pro-fe-brain";
import "./CMECompletedActivityCell.scss";
import CertDownloadButton from "./CertDownloadButton";

const CASE_CME = Constants.CmeConstants.CME_TYPES.CASE_CME;

const CMECompletedActivityCell = ({ activity, history }) => {
  /************************************ CONFIG ***************************************/
  let url = "";
  if (activity && activity.media && activity.media.url) {
    url = activity.media.url;
  }

  const newImage = {
    backgroundImage: `url("${url}?fit=crop&w=103&h=132")`
  };

  const containerStyle = {
    cursor: "pointer",
    width: "100%"
  };

  const [showSummaryModal, setShowSummaryModal] = useState(false);
  const date = moment(activity.completedAt)?.format("MMMM DD, YYYY");
  const earnedCredits = activity?.credits || 0;
  const cmeType = Utils.CmeUtils.getCmeType(activity);

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const navigateToActivity = useCallback(() => {
    const url =
      cmeType === "case" ? ROUTES.CASE_DETAIL : CLINICAL_MOMENTS_DETAIL;

    history.push(
      getUrl(url, {
        caseId: activity.caseUuid
      })
    );
  }, [activity.caseUuid, history, cmeType]);

  const onDownload = (e) => {
    if (e) {
      e.persist();
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }

    trackDownloadCertificate({
      link: activity.certificateDownloadUrl,
      caseUuid: activity.caseUuid,
      campaignUuid: activity.campaignUuid,
      caseType: cmeType
    });
    window.location.href = activity.certificateDownloadUrl;
  };

  const toggleSummaryModal = () =>
    setShowSummaryModal((prevState) => !prevState);
  /************************************ RENDER ***************************************/

  const newActionButton = (
    <CertDownloadButton activity={activity} downloadCert={onDownload} />
  );

  return (
    <div
      onClick={navigateToActivity}
      className="d-flex flex-column"
      style={containerStyle}>
      <div className="d-flex flex-row m-2 completedActivityOuterDiv">
        <div
          className="bg-lighter-grey completedActivityImage"
          style={newImage}
        />
        <div className="d-flex flex-column justify-content-between flex1">
          <div className="d-flex flex-row align-items-center justify-content-between py-1 pr-1 pl-0 my-0 mx-2 border-bottom">
            <span className="helv-med text-muted text-12 line-height-15">
              <CustomIcon
                icon={"webnav_education_selected"}
                size={16}
                className="ml-0 mr-1 mt-0 icon-battleship-gray "
              />
              <span>
                {`${earnedCredits} ${i18n.t("CMEScreens.credit", {
                  count: earnedCredits
                })}`}
              </span>
            </span>
            <span className="text-muted text-12 line-height-15">{date}</span>
          </div>
          <div className="mb-2 flex1 p-2">
            <LinesEllipsis
              className="text-14 line-height-18"
              text={activity.title}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
            <span className="text-muted text-13 line-height-15 text-capitalize">
              {cmeType}
            </span>
          </div>
          <div className="d-flex flex-row align-items-center justify-content-between mx-2 mb-2">
            {activity.cmeType === CASE_CME ? (
              <Button
                className="p-0"
                color="link"
                onClick={(e) => {
                  if (e) {
                    e.persist();
                    e.nativeEvent.stopImmediatePropagation();
                    e.stopPropagation();
                  }
                  toggleSummaryModal();
                }}>
                {i18n.t("CMEScreens.seeSummary")}
              </Button>
            ) : (
              <span />
            )}
            {newActionButton}
          </div>
        </div>
      </div>
      <CMESummaryModal
        isOpen={showSummaryModal}
        toggle={toggleSummaryModal}
        activity={activity}
      />
    </div>
  );
};

CMECompletedActivityCell.propTypes = {
  activity: PropTypes.shape({
    caseUuid: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    credits: PropTypes.number,
    media: PropTypes.object,
    currentSlide: PropTypes.number,
    totalSlide: PropTypes.number,
    buttonUrl: PropTypes.string
  }).isRequired
};

export default withRouter(CMECompletedActivityCell);
