import React, { useState } from "react";
import { Button, CardBody, Collapse } from "reactstrap";
import { trackDiagnosisClick } from "../../actions/metrics.actions";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";

const CaseDetailDiagnosis = ({
  caseUuid,
  diagnosis,
  label,
  className = ""
}) => {
  /** ********************************** CONFIG ***************************************/
  const [isOpen, setIsOpen] = useState(false);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const handleToggleDiagnosis = () => {
    // Only track when they are opening the diagnosis
    if (!isOpen) {
      trackDiagnosisClick({ caseUuid });
    }
    setIsOpen((currentIsOpen) => !currentIsOpen);
  };

  /** ********************************** RENDER ***************************************/

  return (
    <CardBody className={`pt-0 ${className}`}>
      <div className="d-inline-flex justify-content-between align-items-center w-100 mt-2">
        <p className="d-inline-flex justify-content-center align-items-center mb-0 text-14 font-weight-bold">
          <CustomIcon
            className="icon-denim-blue ml-0 my-0 mr-2"
            icon="diagnosis"
            size={20}
          />
          {label}
        </p>
        <Button
          className="d-inline-flex justify-content-center align-items-center text-cool-blue text-13 p-0"
          color={"link"}
          onClick={handleToggleDiagnosis}>
          {isOpen
            ? i18n.t("AddUpdateScreen.hideDiagnosis")
            : i18n.t("AddUpdateScreen.showDiagnosis")}
          <CustomIcon
            className="icon-cool-blue ml-2"
            rotation={isOpen ? "270deg" : "90deg"}
            icon="drawer_chevron"
            size="10"
          />
        </Button>
      </div>
      <Collapse isOpen={isOpen}>
        <p className="feed-card-info text-15 mb-0 mt-2">{diagnosis}</p>
      </Collapse>
    </CardBody>
  );
};

export default CaseDetailDiagnosis;
