import { Actions, Hooks } from "@figure1/f1-pro-fe-brain";
import Firebase from "../firebase";
import AppConfig from "../AppConfig";

const hooks = Hooks(Actions(Firebase, AppConfig));

export const {
  useCaseTranslation,
  useCommentTranslation,
  useIsAnonymousCaseAuthor,
  useIsAnonymousCommentAuthor,
  useIsCaseAuthor
} = hooks;
