/** @format */

import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import CME_SECTION from "../constants/cme-section";
import { getCMEBundleFor } from "../utils/cme-utils";

export const useInteractiveCaseState = (caseUuid) => {
  const _state = useSelector((state) => {
    return state?.user?.interactiveCaseStates?.[caseUuid];
  });

  return _state;
};

export const useSelectUserVote = (caseUuid, contentUuid) => {
  return useSelector((state) => {
    return state?.user?.interactiveCaseStates?.[caseUuid]?.[contentUuid]
      ?.userVote;
  });
};

export const useSelectUserTextInput = (caseUuid, contentUuid) => {
  return useSelector((state) => {
    return state?.user?.interactiveCaseStates?.[caseUuid]?.[contentUuid]
      ?.userFreeFormText;
  });
};

export const useLocalQuizScore = (caseUuid) => {
  return useSelector((state) => {
    const contentItems = state.case.cases?.[caseUuid]?.data?.contentItems;
    const userSelections = state?.user?.interactiveCaseStates?.[caseUuid];

    return calculateCorrectQuizScore(contentItems, userSelections);
  });
};

export const useCorrectQuizScore = (contentItems, caseUuid) => {
  return useSelector((state) => {
    const userSelections = state?.user?.interactiveCaseStates?.[caseUuid];

    return calculateCorrectQuizScore(contentItems, userSelections);
  });
};

const calculateCorrectQuizScore = (contentItems, userSelections) => {
  const items = getCMEBundleFor(CME_SECTION.POST_TEST, contentItems);

  let nbCorrectAnswer = 0;
  if (!userSelections || isEmpty(items)) {
    return nbCorrectAnswer;
  }
  for (const item of items) {
    const selection = userSelections?.[item.contentUuid];
    if (selection) {
      const option = item.questionOptions.find(
        (option) => option.questionOptionUuid === selection.userVote
      );

      if (option) {
        nbCorrectAnswer += option.isAnswer;
      }
    }
  }
  return nbCorrectAnswer;
};
