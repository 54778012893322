/** @format */

import React from "react";
import { Controller, useWatch } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import classNames from "../../../utils/class-names-utils";
import i18n from "../../../utils/i18n";

const MAX_INPUT_LENGTH = 2500;

const CMEFormElementTextarea = ({
  question,
  control,
  errors,
  onFieldDirtied
}) => {
  /****************************** Config **************************************/
  const placeholderText =
    question.answerGroups?.[0]?.answers?.[0]?.answerSuggestedText ||
    i18n.t("CaseCMEQuestions.defaultPlaceholder");
  const answerUuid = question.answerGroups?.[0]?.answers?.[0]?.answerUuid;

  const isTerminator =
    !question.answerGroups?.[0]?.answers?.[0].nextQuestion &&
    !question.nextQuestion;

  const fieldWatch = useWatch({
    control,
    name: [answerUuid],
    defaultValue: {
      [answerUuid]: ""
    }
  });

  const textContents = fieldWatch ? fieldWatch[answerUuid] : null;

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/
  const onChangeEv = () => {
    onFieldDirtied(question, question.answerGroups?.[0]?.answers?.[0]);
  };

  /****************************** Render **************************************/
  return (
    <FormGroup>
      <Label
        for="caption"
        className="text-14 text-default-black d-flex w-100 justify-content-between align-items-end">
        <div style={{ width: "80%" }}>{question.questionText}</div>
        <span className="text-muted text-nowrap">
          {textContents?.length || 0} / {MAX_INPUT_LENGTH}
        </span>
      </Label>
      <Controller
        control={control}
        name={answerUuid}
        rules={{
          required: {
            value: true,
            message: isTerminator
              ? i18n.t("CaseCMEQuestions.requiredErrorFinal")
              : i18n.t("CaseCMEQuestions.requiredError")
          },
          maxLength: {
            value: MAX_INPUT_LENGTH,
            message: i18n.t("CaseCMEQuestions.answerTooLong")
          }
        }}
        invalid={!!errors[answerUuid] ? 1 : 0}
        render={({ onChange, name }, { invalid }) => (
          <TextareaAutosize
            className={classNames(
              "form-control text-16 text-default-black resize-none w-100",
              !!invalid && "is-invalid"
            )}
            name={name}
            minRows={5}
            maxRows={7}
            placeholder={placeholderText}
            onChange={(e) => {
              onChangeEv(e);
              onChange(e);
            }}
          />
        )}
        defaultValue={""}
      />
      {errors[answerUuid] && (
        <FormFeedback>{errors[answerUuid]?.message}</FormFeedback>
      )}
    </FormGroup>
  );
};

export default CMEFormElementTextarea;
