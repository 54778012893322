/** @format */

import React, { useEffect, useState } from "react";
import { Button, Carousel, CarouselItem } from "reactstrap";
import { capitalize } from "lodash";
import PropTypes from "prop-types";
import CME_SECTION from "../../../constants/cme-section";
import { CaseDataProp } from "../../../prop-types/ContentProp";
import i18n from "../../../utils/i18n";
import CMEModalSection from "./CMEModalSection";
import useCarouselNavigation from "../../../hooks/useCarouselNavigation";
import {
  useLocalQuizScore,
  useCorrectQuizScore
} from "../../../selectors/interactive-case-state";
import F1Spacer from "../../../components/common/F1Spacer";
import useListenInteractiveCaseState from "../../../hooks/useListenInteractiveCaseState";
import { getCMEBundleFor } from "./CMEUtils";
import CMEPostTestSlide from "./CMEPostTestSlide";
import useTrackSlideView from "../../../hooks/useTrackSlideView";
import { trackQuestionCompletion } from "../../../actions/metrics.actions";
import useIsGated from "../../../hooks/useIsGated";

const CMEModalSectionPostTestSummary = ({
  caseContent,
  onExit,
  navigateToSection,
  initialIndex,
  onShareClick
}) => {
  /****************************** Config **************************************/
  const { caseUuid, campaignUuid, caseType } = caseContent;

  const surveyItems = getCMEBundleFor(
    CME_SECTION.SURVEY,
    caseContent?.contentItems
  );
  const hasSurvey = surveyItems.length > 0;

  const testItems = getCMEBundleFor(
    CME_SECTION.POST_TEST,
    caseContent?.contentItems
  );
  const questionCount = testItems.length;

  const isGated = useIsGated();
  const localCorrectCount = useLocalQuizScore(caseUuid);
  const serverCorrectCount = useCorrectQuizScore(testItems, caseUuid);

  const correctCount = isGated ? localCorrectCount : serverCorrectCount;

  const passingScore = Math.floor(
    (caseContent.passingScore / questionCount) * 100
  );
  const failed = correctCount < (caseContent.passingScore || questionCount);
  const [sentCompletionMetric, setSendCompletionMetric] = useState(false);

  const { activeIndex, next, previous, onExiting, onExited } =
    useCarouselNavigation({ items: [0], currentIndex: initialIndex || 0 });

  const { onNext, onPrev, sendMetrics } = useTrackSlideView({
    items: surveyItems,
    activeIndex,
    next,
    previous
  });

  /****************************** Hooks ***************************************/

  useListenInteractiveCaseState(caseUuid);

  useEffect(() => {
    if (
      !sentCompletionMetric &&
      [correctCount, questionCount, caseUuid, campaignUuid, caseType].every(
        (i) => i != null
      )
    ) {
      trackQuestionCompletion({
        score: correctCount / questionCount,
        caseUuid,
        campaignUuid,
        type: caseType,
        isUngated: isGated
      });
      setSendCompletionMetric(true);
    }
  }, [
    sentCompletionMetric,
    correctCount,
    questionCount,
    caseUuid,
    campaignUuid,
    caseType,
    isGated
  ]);
  /****************************** Functions ***********************************/

  const complete = () => navigateToSectionWithMetrics(CME_SECTION.COMPLETION);

  const takeSurvey = () => navigateToSectionWithMetrics(CME_SECTION.SURVEY);

  const reTest = () => navigateToSectionWithMetrics(CME_SECTION.POST_TEST);

  const viewActivity = () => navigateToSectionWithMetrics(CME_SECTION.ACTIVITY);

  const navigateToSectionWithMetrics = (section) => {
    sendMetrics();
    navigateToSection(section);
  };

  const primaryButtonText = (_hasSurvey, _failed) => {
    if (_failed) {
      return i18n.t("CMECaseScreens.retakeTest");
    }
    if (_hasSurvey) {
      return i18n.t("CMECaseScreens.takeSurvey");
    }
    return i18n.t("continue");
  };

  const primaryAction = (_hasSurvey, _failed) => {
    if (_failed) {
      reTest();
      return;
    }
    if (_hasSurvey) {
      takeSurvey();
      return;
    }

    complete();
  };

  /****************************** Render **************************************/

  return (
    <CMEModalSection
      canShare={!caseContent.isSponsored}
      onExit={onExit}
      onShareClick={onShareClick}
      footer={
        <>
          <Button
            block
            color="denim-blue text-15"
            onClick={() => {
              primaryAction(hasSurvey, failed);
            }}
            size="lg">
            {capitalize(primaryButtonText(hasSurvey, failed))}
          </Button>
          {!failed && (
            <Button
              className="btn-text text-15"
              block
              color="white"
              onClick={viewActivity}
              size="lg">
              {capitalize(i18n.t("CMECaseScreens.viewActivity"))}
            </Button>
          )}
        </>
      }>
      <Carousel
        className="pt-0 w-100"
        activeIndex={activeIndex}
        next={onNext}
        previous={onPrev}
        interval={false}>
        {[
          <CarouselItem onExiting={onExiting} onExited={onExited} key="0">
            <F1Spacer className="sticky-top t-0 w-100 mx-0 mb-1 pt-4 bg-darkest opacity-90" />
            <CMEPostTestSlide
              questionCount={questionCount}
              correctCount={correctCount}
              passingScore={passingScore}
              failed={failed}
              hasSurvey={hasSurvey}
              caseContent={caseContent}
            />
          </CarouselItem>
        ]}
      </Carousel>
    </CMEModalSection>
  );
};

CMEModalSectionPostTestSummary.propTypes = {
  caseContent: CaseDataProp.isRequired,
  onExit: PropTypes.func.isRequired,
  navigateToSection: PropTypes.func.isRequired
};

export default CMEModalSectionPostTestSummary;
