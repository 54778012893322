import React, { useEffect, useState } from "react";
import Figure1Layout from "../../../@layouts/Figure1Layout";
import Figure1Page1Col from "../../../@layouts/Figure1Page1Col";
import RegistrationHeaderV2 from "../components/RegistrationHeader.v2";
import i18n from "../../../utils/i18n";
import { Button, Card } from "reactstrap";
import FormGlobalError from "../components/FormGlobalError";
import LoadingButton from "../../../components/common/LoadingButton";
import { isMobile } from "react-device-detect";
import FormUserInfoV2 from "../components/FormUserInfo.v2";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { updateUserProfile } from "../../../actions/user-profiles.actions";
import {
  clearFetchedNpi,
  fetchInfoWithNpi,
  postVerification
} from "../../../actions/user-verification.actions";
import {
  trackNPIFastTrackPageEntry,
  trackVerifyNpi,
  trackVerifyNpiEntry
} from "../../../actions/metrics.actions";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import * as ROUTES from "../../../constants/routes";
import Loading from "../../../components/loading";
import { METRICS_USER_PROPERTY } from "../../../metrics/constants.metrics";

const AutoRegInformation = () => {
  /** ********************************** CONFIG ***************************************/
  const { handleSubmit, control, errors, watch, setValue, getValues } = useForm(
    {
      mode: "onChange"
    }
  );
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();
  const isLoadingData = useSelector(
    (state) =>
      state.userVerification?.isProcessing || !state.userVerification?.npiData
  );
  const npiData = useSelector((state) => state.userVerification?.npiData);
  const npiNumFromUser = useSelector(
    (state) => state.user?.verification?.npi?.npiNumber
  );

  const hasVerificationRecord = useSelector(
    (state) => !!state.user?.verification?.verificationUuid
  );

  const onboardingNavigate = useOnboardingNavigation();

  const attemptedNpiNumber = useSelector(
    (state) => state.userVerification?.attemptedNpiNumber
  );
  const verificationMessage = useSelector(
    (state) => state.userVerification?.message
  );
  const fetchedNpiIncomplete = useSelector(
    (state) => state.userVerification?.incomplete
  );
  const npiIncomplete =
    fetchedNpiIncomplete === true ||
    (npiData && !(npiData.firstName && npiData.specialty));
  const npiNumber = npiData?.npiNumber || attemptedNpiNumber;

  /** *********************************** HOOKS ***************************************/
  useEffect(() => {
    trackNPIFastTrackPageEntry({ [METRICS_USER_PROPERTY.NPI]: npiNumber });
  }, []);

  useEffect(() => {
    if (!npiData && npiNumFromUser) {
      dispatch(fetchInfoWithNpi(parseInt(npiNumFromUser)));
    }
  }, [dispatch, npiData, npiNumFromUser]);

  /*********************************** FUNCTIONS *************************************/
  const handleSubmitClick = async (formData) => {
    // if form's still being constructed, these fields won't exist, so they'll be undefined
    if (
      formData.profession === undefined ||
      (Object.keys(formData).indexOf("specialty") > -1 &&
        formData.specialty === undefined) ||
      (Object.keys(formData).indexOf("hiddenSpecialty") > -1 &&
        formData.hiddenSpecialty === undefined)
    ) {
      return;
    }

    setIsSaving(true);
    setErrorMessage("");

    let specialtyId = null;
    if (formData.specialty) {
      specialtyId = formData.specialty.value
        ? formData.specialty.value
        : formData.specialty;
    } else if (formData.hiddenSpecialty) {
      specialtyId = formData.hiddenSpecialty;
    }

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      primarySpecialty: specialtyId ? specialtyId : formData.profession?.value,
      force_synchronous: true
    };

    if (formData?.graduationYear && formData?.graduationMonth) {
      payload.graduationDate = `${formData?.graduationYear.value}-${formData?.graduationMonth.value}-01`;
    }

    trackVerifyNpi({ ...payload, npiNumber: npiNumber });

    try {
      // Save npi request first
      const result = await dispatch(updateUserProfile(payload));
      let responseUser = result?.user;

      if (!result || result.error) {
        throw new Error(result?.message || "Error updating user information");
      }

      if (!hasVerificationRecord) {
        // we want to skip all of this if going through normal regflow,
        // the npi request is made ahead of time. -- Corey
        const verificationResult = await dispatch(
          postVerification({
            method: "npi",
            npiNumber: parseInt(npiNumber)
          })
        );

        responseUser = verificationResult?.user;
        if (!verificationResult || verificationResult.error) {
          throw new Error(
            verificationResult?.error ||
              "Error sending NPI verification request"
          );
        }
      }
      onboardingNavigate(responseUser);
    } catch (e) {
      setErrorMessage(e.message);
      setIsSaving(false);
    }
  };
  const handleCancelClick = () => {
    setIsSaving(false);
    dispatch(clearFetchedNpi());
    history.push(getUrl(ROUTES.REGISTRATION_INFORMATION_V2));
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-3 mt-lg-5">
            <form onSubmit={handleSubmit(handleSubmitClick)}>
              <h1 className="helv-bold text-center text-22 line-height-28 mb-1">
                {i18n.t(
                  "RegistrationScreens.autoRegistrationInformation.title"
                )}
              </h1>
              {isLoadingData ? (
                <div className="mt-5 position-relative">
                  <Loading />
                </div>
              ) : (
                <>
                  {npiNumber && (
                    <p className="d-flex justify-content-center align-items-center color-blue-gray text-center text-16 line-height-18 m-0">
                      {i18n.t(
                        "RegistrationScreens.autoRegistrationInformation.npiNo"
                      )}{" "}
                      {npiNumber}
                      {i18n.t(
                        "RegistrationScreens.autoRegistrationInformation.npiNotYou"
                      )}
                      <Button
                        className="px-0 ml-1 text-16"
                        onClick={handleCancelClick}
                        color="link">
                        {i18n.t(
                          "RegistrationScreens.autoRegistrationInformation.btnTryAgain"
                        )}
                      </Button>
                    </p>
                  )}
                  <div className="mt-4 shadow">
                    <Card className="p-3 p-lg-4 rounded-0">
                      <FormGlobalError errorText={errorMessage} />
                      {attemptedNpiNumber &&
                        npiIncomplete &&
                        !verificationMessage && (
                          <div className="alert bg-secondary color-blue-gray">
                            {i18n.t(
                              "RegistrationScreens.autoRegistrationInformation.notFound"
                            )}
                          </div>
                        )}
                      {npiIncomplete && verificationMessage && (
                        <FormGlobalError
                          classNames="text-left"
                          errorText={i18n.t(verificationMessage)}
                        />
                      )}
                      <FormUserInfoV2
                        existingData={npiData}
                        control={control}
                        errors={errors}
                        watch={watch}
                        setValue={setValue}
                        getValues={getValues}
                      />
                      <LoadingButton
                        size="lg"
                        color="primary"
                        loading={isSaving}
                        className={`text-16 ${
                          isMobile ? "min-w-130px" : "min-w-171px"
                        }`}>
                        {i18n.t(
                          "RegistrationScreens.verificationNpiPage.modalConfirm"
                        )}
                      </LoadingButton>
                    </Card>
                  </div>
                </>
              )}
            </form>
          </div>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default AutoRegInformation;
