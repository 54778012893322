import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Card from "reactstrap/lib/Card";
import i18n from "../../../utils/i18n";
import LoadingButton from "../../../components/common/LoadingButton";
import { useForm } from "react-hook-form";

import FormUserInfoV2 from "./FormUserInfo.v2";
import useIsUSAUser from "../../../hooks/useIsUSAUser";
import verificationBadge from "../../../assets/images/reg_flow_verification_web.svg";
import { updateUserProfile } from "../../../actions/user-profiles.actions";
import FormGlobalError from "./FormGlobalError";
import {
  trackVerifyNonNpi,
  trackVerifyNonNpiEntry
} from "../../../actions/metrics.actions";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { isMobile } from "react-device-detect";
import { useVerifiedMemberStatus } from "../../../selectors/member-status-selector";

const VerificationNonNpi = () => {
  /** ********************************** CONFIG ***************************************/
  const isUsa = useIsUSAUser();
  const onboardingNavigate = useOnboardingNavigation();

  const {
    handleSubmit,
    control,
    errors,
    watch,
    formState,
    setValue,
    getValues
  } = useForm({
    mode: "onChange"
  });

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const user = useSelector((state) => state.user);
  const isVerified = useVerifiedMemberStatus();

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (!isUsa) {
      trackVerifyNonNpiEntry();
    }
  }, [isUsa]);
  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async (formData) => {
    // if form's still being constructed, these fields won't exist, so they'll be undefined
    if (
      formData.profession === undefined ||
      formData.firstName === undefined ||
      formData.lastName === undefined
    ) {
      return;
    }
    if (!formState.isValid) {
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    let specialtyId = null;
    if (formData.hiddenSpecialty) {
      // hidden input for Other HCP
      specialtyId = formData.hiddenSpecialty;
    } else if (formData.specialty) {
      // select dropdown
      if (formData.specialty.value) {
        specialtyId = formData.specialty.value;
      } else {
        specialtyId = formData.specialty;
      }
    }

    const payload = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      primarySpecialty: specialtyId ? specialtyId : formData.profession?.value
    };

    if (formData?.graduationYear && formData?.graduationMonth) {
      payload.graduationDate = `${formData?.graduationYear.value}-${formData?.graduationMonth.value}-01`;
    }

    trackVerifyNonNpi(payload);

    const result = await dispatch(updateUserProfile(payload));

    if (result.error) {
      setErrorMessage(result.message);
      setIsLoading(false);
    } else {
      onboardingNavigate(result.user);
    }
  };

  /** ********************************** RENDER ***************************************/

  return (
    <div className="shadow">
      <Card className="p-3 p-lg-4 rounded-0">
        <div className="d-flex justify-content-between">
          {isUsa && !isVerified && (
            <div className="mb-3">
              <h3 className="text-18 helv-bold mb-1 align-items-center">
                {i18n.t("RegistrationScreens.verificationNonNpi.title")}
              </h3>
              <p className="text-14 color-battleship-gray mb-0 mr-2">
                {i18n.t("RegistrationScreens.verificationNonNpi.description")}
              </p>
            </div>
          )}
          {!isUsa && !isMobile && (
            <div className="mb-3">
              <div className="d-flex justify-content-between align-items-center">
                <h3 className="text-18 helv-bold mb-1 mr-5">
                  {i18n.t("RegistrationScreens.verificationNonNpi.titleNonUSA")}
                </h3>
                <img src={verificationBadge} alt="" className="" />
              </div>
            </div>
          )}
        </div>
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <FormGlobalError errorText={errorMessage} />

          <FormUserInfoV2
            control={control}
            errors={errors}
            watch={watch}
            setValue={setValue}
            getValues={getValues}
            existingData={user?.initialized ? user : {}}
          />

          <div className="text-center">
            <LoadingButton
              className="w-100 mt-2"
              color="primary"
              outline
              loading={isLoading}
              spinnerColor="primary"
              disabled={isLoading}
              size="lg">
              {i18n.t("RegistrationScreens.verificationNonNpi.btnNext")}
            </LoadingButton>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default VerificationNonNpi;
