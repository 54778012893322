import React from "react";
import PropTypes from "prop-types";
import { Button, FormGroup, Input, ListGroupItem } from "reactstrap";
import F1Avatar from "../../../components/common/F1Avatar";
import ProfessionLine from "../../../components/common/ProfessionLine";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const UserInviteRow = ({
  image,
  username,
  displayName,
  profileDisplayName,
  onClick,
  userUuid,
  professionName,
  tree,
  avatar,
  invited = false,
  checked
}) => {
  const name = displayName || username;
  const profession =
    profileDisplayName || tree?.profileDisplayName || professionName;

  return (
    <ListGroupItem className="d-inline-flex align-items-center justify-content-between px-0 cursor-pointer">
      <div
        className="d-flex flex-grow-1 align-items-start justify-content-between px-0"
        onClick={onClick}>
        <F1Avatar className="mr-2" avatar={image || avatar} username={name} />
        <div className="flex-fill text-left">
          <p className="text-14 helv-med m-0">{username}</p>
          <p className="text-13 text-muted m-0">{name}</p>
          {!!profession && (
            <p className="text-12 text-muted m-0">
              {<ProfessionLine profession={profession} />}
            </p>
          )}
        </div>
      </div>

      {invited && (
        <Button
          className="my-2 text-15 helv-bold px-3 py-2"
          color="secondary"
          disabled>
          <CustomIcon icon={"feed_follow"} size={16} />{" "}
          {i18n.t("GroupsFeed.InviteF1UsersModal.invited")}
        </Button>
      )}
      {!invited && (
        <FormGroup key={userUuid}>
          <Input
            type="checkbox"
            name={userUuid}
            checked={checked}
            onChange={onClick}
          />
        </FormGroup>
      )}
    </ListGroupItem>
  );
};

UserInviteRow.propTypes = {
  image: PropTypes.string,
  username: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  profileDisplayName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  userUuid: PropTypes.string.isRequired
};

UserInviteRow.defaultProps = {
  image: "",
  displayName: "",
  profileDisplayName: ""
};

export default UserInviteRow;
