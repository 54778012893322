import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import {
  Button,
  Card,
  CardBody,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import { MAX_DIAGNOSIS_LENGTH } from "../../constants/case-constants";
import { CASE_LABEL_TYPES } from "../../constants/case-label-types";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";
import ResolvedCaseModal from "./ResolvedCaseModal";
import UnresolvedCaseModal from "./UnresolvedCaseModal";

const CaseStatusGroup = ({
  setValue,
  control,
  errors,
  draft,
  caseLabels,
  isGroupCase
}) => {
  /** ********************************** CONFIG ***************************************/
  const [requestHelpModalOpen, setRequestHelpModalOpen] = useState(false);
  const [requestHelpModalOpenOnce, setRequestHelpModalOpenOnce] =
    useState(false);
  const [requestResolvedModalOpen, setResolvedModalOpen] = useState(false);
  const [requestResolvedModalOpenOnce, setRequestResolvedModalOpenOnce] =
    useState(false);
  const [localPaging, setLocalPaging] = useState(draft ? draft.paging : false);
  const [localRequestHelp, setLocalRequestHelp] = useState(
    draft ? draft.requestHelp : true
  );
  const [localDiagnosis, setLocalDiagnosis] = useState(
    draft ? draft.diagnosis : false
  );

  const draftLabels = draft?.labelsUuid || [];
  const resolvedLabel = caseLabels.filter(
    (l) => l.labelKind === CASE_LABEL_TYPES.RESOLVED
  )[0] || { name: "", labelUuid: "nolabelresolved" };

  const unresolvedLabel = caseLabels.filter(
    (l) => l.labelKind === CASE_LABEL_TYPES.UNRESOLVED
  )[0] || { name: "", labelUuid: "nolabelunresolved" };

  const fieldWatch = useWatch({
    control,
    name: ["requestHelp", "paging", "status", "diagnosis"],
    defaultValue: {
      requestHelp: draft ? draft.requestHelp : true,
      paging: draft ? draft.paging : false,
      status: [resolvedLabel.labelUuid, unresolvedLabel.labelUuid].find(
        (labelUuid) => draftLabels.includes(labelUuid)
      ),
      diagnosis: draft ? draft.diagnosis : ""
    }
  });

  const unresolvedLabelSelected = unresolvedLabel
    ? fieldWatch?.status === unresolvedLabel.labelUuid
    : false;

  const resolvedLabelSelected = resolvedLabel
    ? fieldWatch?.status === resolvedLabel.labelUuid
    : false;

  const diagnosisContents = fieldWatch ? fieldWatch.diagnosis : null;

  const diagnosisError = errors.diagnosis // if there's an error
    ? errors.diagnosis.type === "required" // if it's required, otherwise it's max length
      ? i18n.t("shareCase.resolvedModal.Error1")
      : i18n.t("shareCase.resolvedModal.Error2")
    : "";

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    setValue(
      "status",
      draftLabels.find(
        (labelUuid) =>
          labelUuid === resolvedLabel.labelUuid ||
          labelUuid === unresolvedLabel.labelUuid
      ) || null
    );
  }, [draftLabels, setValue]);

  /** ********************************* FUNCTIONS *************************************/

  const onCancelRequestHelp = () => {
    setRequestHelpModalOpen(false);
  };

  const onCancelResolveModal = () => {
    setResolvedModalOpen(false);
  };

  const onSaveRequestHelp = ({ requestHelp, paging }) => {
    setValue("requestHelp", requestHelp);
    setValue("paging", paging);
    setLocalPaging(paging);
    setLocalRequestHelp(requestHelp);

    setRequestHelpModalOpen(false);
  };

  const onSaveResolve = ({ diagnosis }) => {
    setValue("diagnosis", diagnosis);
    setLocalDiagnosis(diagnosis);
    onCancelResolveModal();
  };

  const onDiagnosisChange = (e) => {
    setLocalDiagnosis(e.target.value);
  };

  const onEditRequestHelp = () => {
    setRequestHelpModalOpen(true);
  };

  const onResolveModalOpen = () => {
    if (!requestResolvedModalOpenOnce) {
      setResolvedModalOpen(true);
      setRequestResolvedModalOpenOnce(true);
    }
  };

  const onResoveClick = () => {
    setValue("status", resolvedLabel.labelUuid, { shouldValidate: true });
    onResolveModalOpen();
  };

  const onUnresolveClick = () => {
    setValue("status", unresolvedLabel.labelUuid, { shouldValidate: true });
    if (!requestHelpModalOpenOnce && !isGroupCase) {
      onEditRequestHelp();
    }
    setLocalRequestHelp(true);
    setValue("requestHelp", true);
    setRequestHelpModalOpenOnce(true);
  };

  /** ********************************** RENDER ***************************************/
  return (
    <>
      <FormGroup tag="fieldset" name="status" className="mb-3">
        <Label
          className="text-15 font-weight-500 text-default-black"
          for="status">
          {i18n.t("shareCase.caseStatusLabel")}
        </Label>
        <Controller
          control={control}
          name="status"
          rules={{
            required: true
          }}
          invalid={!!errors.status ? 1 : 0}
          defaultValue={
            draftLabels.find(
              (labelUuid) =>
                labelUuid === resolvedLabel.labelUuid ||
                labelUuid === unresolvedLabel.labelUuid
            ) || null
          }
          render={({ value }) => (
            <>
              <div
                className={`d-flex flex-row w-100 ${
                  errors.status ? "is-invalid-invisible" : ""
                }`}>
                <Button
                  onClick={onResoveClick}
                  className={`mr-2 ${errors.status ? "is-invalid" : ""} ${
                    resolvedLabel.labelUuid === value
                      ? "active-button"
                      : "inactive-border-button"
                  }`}>
                  {i18n.t("FeedFilter.resolved")}
                </Button>

                <Button
                  onClick={onUnresolveClick}
                  className={`${errors.status ? "is-invalid" : ""} ${
                    unresolvedLabel.labelUuid === value
                      ? "active-button"
                      : "inactive-border-button"
                  }`}>
                  {i18n.t("FeedFilter.unresolved")}
                </Button>
              </div>
              <FormFeedback>
                {i18n.t("shareCase.caseStatusRequired")}
              </FormFeedback>
            </>
          )}
        />
      </FormGroup>
      <Controller
        as={<Input />}
        control={control}
        id="requestHelp"
        name="requestHelp"
        type="hidden"
        defaultValue={draft?.requestHelp || true}
      />
      <Controller
        as={<Input />}
        control={control}
        id="paging"
        name="paging"
        type="hidden"
        defaultValue={draft?.paging || false}
      />

      <div className={resolvedLabelSelected ? "" : "d-none"}>
        <FormGroup className="mb-0">
          <Label for="diagnosis" className="w-100">
            <span className="text-15 font-weight-500 text-default-black">
              {i18n.t("shareCase.resolvedModal.Label")}
            </span>
            <div className=" d-flex align-items-center text-nowrap justify-content-between flex-wrap">
              <span className="text-14 font-weight-normal text-battleship-gray">
                {i18n.t("shareCase.resolvedModal.Label2")}
              </span>
              <span className="text-muted">
                {diagnosisContents?.length || 0} / {MAX_DIAGNOSIS_LENGTH}
              </span>
            </div>
          </Label>
          <Controller
            control={control}
            name="diagnosis"
            rules={{
              maxLength: MAX_DIAGNOSIS_LENGTH
            }}
            invalid={!!errors.diagnosis ? 1 : 0}
            as={
              <TextareaAutosize
                className={
                  errors.diagnosis
                    ? "autoresize-textarea is-invalid"
                    : "autoresize-textarea"
                }
                minRows={6}
                maxRows={6}
                placeholder={i18n.t("shareCase.resolvedModal.Placeholder")}
                onChange={onDiagnosisChange}
              />
            }
            defaultValue={localDiagnosis || ""}
          />

          <FormFeedback>{diagnosisError}</FormFeedback>
        </FormGroup>
        <ResolvedCaseModal
          onSave={onSaveResolve}
          onCancel={onCancelResolveModal}
          draft={draft}
          isOpen={requestResolvedModalOpen}
        />
      </div>
      {unresolvedLabelSelected && !isGroupCase && (
        <>
          <Card className="shadow-sm mb-3">
            <CardBody className="d-flex flex-row justify-content-between align-items-start px-3 py-2">
              <div>
                <div className="text-14 helv-bold text-default-black">
                  {i18n.t("shareCase.caseResolved.Title")}
                </div>
                <div className="text-13">
                  <span>
                    {localRequestHelp
                      ? i18n.t("shareCase.caseResolved.Help")
                      : i18n.t("shareCase.caseResolved.NoHelp")}
                  </span>
                  <CustomIcon
                    size={13}
                    className="mx-1"
                    icon={localPaging ? "bell_filled" : "bell_filled_crossed"}
                  />
                  <span>
                    {localPaging
                      ? i18n.t("shareCase.caseResolved.Page")
                      : i18n.t("shareCase.caseResolved.NoPage")}
                  </span>
                </div>
              </div>
              <Button className="p-0" color="link" onClick={onEditRequestHelp}>
                {i18n.t("shareCase.caseResolved.EditButton")}
              </Button>
            </CardBody>
          </Card>

          <UnresolvedCaseModal
            onSave={onSaveRequestHelp}
            onCancel={onCancelRequestHelp}
            draft={draft}
            isOpen={requestHelpModalOpen}
          />
        </>
      )}
    </>
  );
};

export default CaseStatusGroup;
