/** @format */

import React from "react";
import { Controller, useWatch } from "react-hook-form";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import i18n from "../../../utils/i18n";
import "./CMEFormElements.scss";

const MAX_INPUT_LENGTH = 500;

const CMEFormElementCheckbox = ({
  answer,
  control,
  errors,
  checkCB,
  checked,
  className
}) => {
  /****************************** Config **************************************/
  /****************************** Hooks ***************************************/
  /****************************** Functions ***********************************/

  const onChangeEv = (e) => {
    checkCB(answer, e.target.checked);
  };

  const inputKey = `${answer.answerUuid}-text`;

  const fieldWatch = useWatch({
    control,
    name: [inputKey],
    defaultValue: {
      [inputKey]: ""
    }
  });

  const textContents = fieldWatch ? fieldWatch[inputKey] : null;
  const placeholderText =
    answer?.answerSuggestedText ||
    i18n.t("CaseCMEQuestions.defaultAdditionalTextPlaceholder");

  /****************************** Render **************************************/

  return (
    <>
      <Controller
        control={control}
        name={answer.answerUuid}
        id={answer.answerUuid}
        render={({ onChange, name }) => (
          <FormGroup check className={className}>
            <Label check className="text-16 text-default-black">
              <Input
                type="checkbox"
                name={name}
                onChange={(e) => {
                  onChangeEv(e);
                  onChange(e.target.checked);
                }}
                checked={checked}
              />
              {answer.answerText}
            </Label>
          </FormGroup>
        )}
      />
      {answer?.hasExtraInput && checked && (
        <FormGroup>
          <Label className="d-flex justify-content-between">
            <span className="text-15 text-default-black">
              {i18n.t("CaseCMEQuestions.additionalTextTitle")}
            </span>
            <span className="text-muted text-nowrap">
              {textContents?.length || 0} / {MAX_INPUT_LENGTH}
            </span>
          </Label>
          <Controller
            className="mr-1"
            as={Input}
            control={control}
            id={inputKey}
            name={inputKey}
            type="text"
            defaultValue=""
            maxLength={MAX_INPUT_LENGTH}
            rules={{
              required: true
            }}
            invalid={!!errors[inputKey]}
            placeholder={placeholderText}
          />
          {!!errors[inputKey] && (
            <FormFeedback>
              {i18n.t("CaseCMEQuestions.requiredInput")}
            </FormFeedback>
          )}
        </FormGroup>
      )}
    </>
  );
};

export default CMEFormElementCheckbox;
