import React, { useEffect, useState } from "react";
import { Controller, useForm, useWatch } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import TextareaAutosize from "react-textarea-autosize";
import {
  Card,
  CardBody,
  CustomInput,
  FormFeedback,
  FormGroup,
  Input,
  Label
} from "reactstrap";
import Figure1Layout from "../../@layouts/Figure1Layout";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import {
  trackSettingsDeleteAccount,
  trackSettingsDeleteAccountReasonsEntry
} from "../../actions/metrics.actions";
import { deleteUser } from "../../actions/user.actions";
import AppPrompt from "../../components/app-prompt/AppPrompt";
import F1Spacer from "../../components/common/F1Spacer";
import LoadingButton from "../../components/common/LoadingButton";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";

const MAX_OTHER_LENGTH = 200;

const AccountDeletionPage = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const [isDeleting, setIsDeleting] = useState(false);
  const userUid = useSelector((state) => state.user.userUid);
  const { handleSubmit, control, errors, register, getValues, trigger } =
    useForm({
      mode: "onChange",
      reValidateMode: "onChange",
      defaultValues: {
        deleteReason: {
          reason0: false,
          reason1: false,
          reason2: false,
          reason3: false,
          reason4: false,
          reason5: false,
          reason6: false,
          otherValue: ""
        }
      }
    });
  const {
    "deleteReason.reason6": otherChecked,
    "deleteReason.otherValue": otherValue
  } = useWatch({
    control,
    name: ["deleteReason.reason6", "deleteReason.otherValue"]
  });

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    trackSettingsDeleteAccountReasonsEntry();
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  const save = async (data) => {
    setIsDeleting(true);

    trackSettingsDeleteAccount({
      reasonNotHcp: data.deleteReason.reason0,
      reasonVerification: data.deleteReason.reason1,
      reasonNotUsingApp: data.deleteReason.reason2,
      reasonBreak: data.deleteReason.reason3,
      reasonTooManyEmailNotifications: data.deleteReason.reason4,
      reasonPrivacyConcerns: data.deleteReason.reason5,
      reasonOther: data.deleteReason.otherValue
    });

    try {
      await dispatch(deleteUser({ userUid }));
    } finally {
      setIsDeleting(false);
    }
  };

  const validateCheckboxes = () => {
    const values = getValues();

    const isReasonSelected =
      values.deleteReason.reason0 ||
      values.deleteReason.reason1 ||
      values.deleteReason.reason2 ||
      values.deleteReason.reason3 ||
      values.deleteReason.reason4 ||
      values.deleteReason.reason5 ||
      values.deleteReason.reason6;

    return isReasonSelected
      ? true
      : i18n.t("Settings.Account.Deletion.formOptionRequired");
  };

  const validateDeleteField = (value) => {
    return value === "DELETE"
      ? true
      : i18n.t("Settings.Account.Deletion.deleteFieldRequired");
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPrompt />}>
      <Figure1Page1Col
        mainContentSmall={
          <>
            <Card className="border-0 mb-1">
              <CardBody className="d-flex justify-content-center align-items-center">
                <h2 className="m-0 text-18 helv-bold">
                  {i18n.t("Settings.Account.Deletion.title")}
                </h2>
              </CardBody>
            </Card>
            <Card className="border-0 mb-4 py-2 px-4">
              <CardBody className="d-flex flex-column">
                <Label for="exampleCheckbox">
                  <p className="text-14 line-height-21 mb-0">
                    {i18n.t("Settings.Account.Deletion.formTitle")}
                  </p>
                  <p className="text-13 text-battleship-gray line-height-21 mb-0">
                    {i18n.t("Settings.Account.Deletion.formSubtitle")}
                  </p>
                </Label>
                {errors.deleteReason && (
                  <FormFeedback className="d-block mb-2 mt-0">
                    {i18n.t("Settings.Account.Deletion.formOptionRequired")}
                  </FormFeedback>
                )}
                {[
                  i18n.t("Settings.Account.Deletion.formOption1"),
                  i18n.t("Settings.Account.Deletion.formOption2"),
                  i18n.t("Settings.Account.Deletion.formOption3"),
                  i18n.t("Settings.Account.Deletion.formOption4"),
                  i18n.t("Settings.Account.Deletion.formOption5"),
                  i18n.t("Settings.Account.Deletion.formOption6"),
                  i18n.t("Settings.Account.Deletion.formOptionOther")
                ].map((x, i) => (
                  <CustomInput
                    key={x}
                    className="text-default-black text-16 line-height-21 mb-3"
                    type="checkbox"
                    id={"deleteReason.reason" + i}
                    name={"deleteReason.reason" + i}
                    label={x}
                    innerRef={register({
                      validate: { minChecked: validateCheckboxes }
                    })}
                    onChange={trigger}
                  />
                ))}
                {otherChecked && (
                  <FormGroup className="mb-4">
                    <Label
                      className="d-flex text-nowrap justify-content-between"
                      for="caption">
                      <span className="text-14 text-default-black">
                        {i18n.t(
                          "Settings.Account.Deletion.formOptionOtherLabel"
                        )}
                      </span>
                      <span className="text-muted">
                        {otherValue?.length || 0} / {MAX_OTHER_LENGTH}
                      </span>
                    </Label>
                    <Controller
                      className={classNames(
                        "form-control text-15 text-default-black resize-none",
                        !!errors.deleteReason?.otherValue && "is-invalid"
                      )}
                      as={TextareaAutosize}
                      control={control}
                      id="deleteReason.otherValue"
                      name="deleteReason.otherValue"
                      placeholder={i18n.t(
                        "Settings.Account.Deletion.formOptionOtherPlaceholder"
                      )}
                      type="textarea"
                      defaultValue={""}
                      minRows={1}
                      maxRows={6}
                      rules={{
                        required: {
                          value: true,
                          message: i18n.t(
                            "Settings.Account.Deletion.deleteFieldRequired"
                          )
                        },
                        maxLength: {
                          value: MAX_OTHER_LENGTH,
                          message: i18n.t(
                            "Settings.Account.Deletion.formOptionOtherLength",
                            { length: MAX_OTHER_LENGTH }
                          )
                        }
                      }}
                    />
                    {!!errors.deleteReason?.otherValue && (
                      <FormFeedback>
                        {errors.deleteReason?.otherValue.message}
                      </FormFeedback>
                    )}
                  </FormGroup>
                )}

                <F1Spacer className="border-top border-width-3 border-lighter-gray mt-3 mb-2" />

                <Label for="deleteConfirmation">
                  {i18n.t("Settings.Account.Deletion.deleteFieldLabel")}
                </Label>
                <Controller
                  as={Input}
                  control={control}
                  id="deleteConfirmation"
                  name="deleteConfirmation"
                  placeholder={i18n.t(
                    "Settings.Account.Deletion.deleteFieldPlaceholder"
                  )}
                  invalid={!!errors.deleteConfirmation}
                  defaultValue=""
                  rules={{
                    validate: {
                      required: validateDeleteField
                    }
                  }}
                />
                {!!errors.deleteConfirmation && (
                  <FormFeedback>
                    {errors.deleteConfirmation.message}
                  </FormFeedback>
                )}

                <LoadingButton
                  className="text-16 text-bold px-5 py-2 mx-auto mt-5 mb-4"
                  size="lg"
                  outline
                  color="coral"
                  spinnerColor="coral"
                  loading={isDeleting}
                  disabled={isDeleting}
                  onClick={handleSubmit(save)}>
                  {i18n.t("Settings.Account.deleteAccount")}
                </LoadingButton>
              </CardBody>
            </Card>
          </>
        }
      />
    </Figure1Layout>
  );
};

export default AccountDeletionPage;
