import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { find, get, isEmpty, negate } from "lodash";
import { activateListener } from "../../actions/saved-cases.actions";
import { shouldShowDraftToUser } from "../../actions/user-draft.actions";
import { SAVED_CASE_TYPES } from "../../constants/saved-cases-constants";
import i18n from "../../utils/i18n";
import { getUserCountry } from "../../utils/cme-utils";
import { trackLogoClick } from "../../actions/metrics.actions";
import { FEED_META_LABELS } from "../../constants/feed-type";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";
import { isMobile } from "react-device-detect";
import useIsGroupFeed from "../../hooks/useIsGroupFeed";
import { Navbar } from "reactstrap";
import CentreNavComponent from "./CentreNavComponent";
import FeedSearch from "./FeedSearch";
import { HOME_ROOT } from "../../constants/routes";
import RightNavComponent from "./RightNavComponent";

const GlobalHeader = () => {
  const searchRef = useRef(null);
  const routeParams = useParams();

  const dispatch = useDispatch();

  const isGroupFeed = useIsGroupFeed(routeParams.feedTypeUuid);
  const currentFeedUuid = useCurrentFeedTypeUuid();

  const currentUserId = useSelector((state) => state.user.userUid);
  const isUS = useSelector((state) => getUserCountry(state.user) === "US");
  const cmeLinkText = isUS
    ? i18n.t("navigation.cme")
    : i18n.t("navigation.cme-non-us");
  const savedCasesStore = useSelector((state) => state.savedCases);
  const numDrafts = useSelector((state) =>
    Object.values(get(state, "userDraft.data", {}))
      .filter(shouldShowDraftToUser)
      .length.toString()
  );
  const numNotifications = useSelector((state) =>
    get(state, "activity.activityMeta.newCount", 0)
  );

  const feed = useSelector((state) => {
    const currentFeed = get(
      state,
      `references.feedsMeta.data.${currentFeedUuid}`
    );
    const everythingFeed = Object.values(
      state?.references?.feedsMeta?.data || {}
    )?.find((m) => m.label === FEED_META_LABELS.EVERYTHING);

    return find([currentFeed, everythingFeed], negate(isEmpty));
  });

  useEffect(() => {
    dispatch(activateListener(currentUserId, SAVED_CASE_TYPES.ALL));
  }, [dispatch, currentUserId, savedCasesStore.data]);

  const onLogoClick = () => {
    trackLogoClick({ isGated: false });
  };

  return (
    <header className="bg-white p-0">
      <Navbar
        className={`primary-navbar border-bottom ${
          isMobile
            ? "justify-content-center"
            : "justify-content-between header--shadowed"
        }`}
        color="white"
        light
        expand>
        {!isMobile && (
          <div className="d-inline-flex justify-content-end align-items-center side-segment search-segment">
            <NavLink
              onClick={onLogoClick}
              to={HOME_ROOT}
              className={`navbar-brand`}>
              Figure 1 PRO
            </NavLink>
            <FeedSearch ref={searchRef} currentFeed={feed} />
          </div>
        )}
        <div
          className={`align-items-center flex-grow-1 ${
            isMobile
              ? "justify-content-between"
              : "d-inline-flex justify-content-center"
          }`}>
          <CentreNavComponent
            isGroupFeed={isGroupFeed}
            currentFeedUuid={currentFeedUuid}
            cmeLinkText={cmeLinkText}
            numNotifications={numNotifications}
          />
        </div>
        {!isMobile && (
          <div
            className={`d-inline-flex justify-content-end align-items-center side-segment`}>
            <RightNavComponent searchRef={searchRef} numDrafts={numDrafts} />
          </div>
        )}
      </Navbar>
    </header>
  );
};

GlobalHeader.propTypes = {};

export default GlobalHeader;
