import React from "react";
import i18n from "../../utils/i18n";
import AnonImg from "../../assets/images/anonymous.svg";

const AnonymousCaseModalContents = () => {
  return (
    <div className="text p-2 px-4">
      <img src={AnonImg} className="mb-2" style={{ width: "51px" }} />
      <h1 className="helv-bold text-20">
        {i18n.t("AnonymousCaseModal.title")}
      </h1>
      <p className="mb-0 text-15 text-battleship-gray">
        {i18n.t("AnonymousCaseModal.description")}
      </p>
      <hr className="border-width-3" />
      <h2 className="helv-bold text-16">
        {i18n.t("AnonymousCaseModal.subtitle")}
      </h2>
      <p className="mb-0 text-15 text-battleship-gray">
        {i18n.t("AnonymousCaseModal.subdescription")}
      </p>
    </div>
  );
};

export default AnonymousCaseModalContents;
