import React from "react";
import { Card } from "reactstrap";
import CaseBanner from "./CaseBanner";
import { useCaseBanner } from "../../hooks/use-case-banner.hooks";
import CaseDetailCardInner from "./CaseDetailCardInner";
import PagingHeader from "./PagingHeader";
import { decideIfPagingIsActive } from "../../utils/case-utils";

const CaseDetailCard = ({
  caseContent,
  commentStatus,
  videoPlayerConfig,
  onShareClick
}) => {
  /** ********************************** CONFIG ***************************************/

  const { showBanner, bannerType, handleShowBanner } = useCaseBanner();
  const isPagingActive = decideIfPagingIsActive(
    caseContent.publishedAt,
    caseContent.isPagingCase
  );
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {caseContent && (
        <div className="feed-card">
          {showBanner && <CaseBanner bannerType={bannerType} />}
          <Card>
            {isPagingActive && <PagingHeader />}
            <CaseDetailCardInner
              caseContent={caseContent}
              commentStatus={commentStatus}
              videoPlayerConfig
              handleShowBanner={handleShowBanner}
              onShareClick={onShareClick}
            />
          </Card>
        </div>
      )}
    </>
  );
};

export default CaseDetailCard;
