import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "reactstrap";
import {
  trackProfileEditClick,
  trackProfileVerifyClick
} from "../../../../actions/metrics.actions";
import LoadingButton from "../../../../components/common/LoadingButton";
import CustomIcon from "../../../../components/custom-icon/CustomIcon";
import {
  SETTINGS_PROFILE,
  SETTINGS_PROFILE_EDIT,
  SETTINGS_PROFILE_VERIFICATION
} from "../../../../constants/routes";
import useVerifiedUserGate from "../../../../hooks/use-verified-user.hooks";
import FormUserInfoV2 from "../../../../pages/authentication/components/FormUserInfo.v2";
import i18n from "../../../../utils/i18n";
import { getUrl } from "../../../../utils/route-utils";
import useProfileLogic from "../../hooks/useProfileLogic";

import "./profileComponents.scss";

const AccountDetails = () => {
  /** ********************************** CONFIG ***************************************/

  const loc = useLocation();
  const history = useHistory();
  const user = useSelector((state) => state.user);

  const autoEditDetails =
    loc?.pathname?.indexOf("/edit") > 0 && user?.isVerified;
  const [useVerifyPath, setUseVerifyPath] = useState(false);
  const [gradDate, setGradDate] = useState(null);

  const saveLabel = useVerifyPath
    ? i18n.t("Settings.Profile.AccountDetails.verifyButton")
    : i18n.t("common.save");

  const {
    saving,
    editMode,
    form,
    onSave,
    onEditMode,
    onCancel,
    setTempDetails,
    clearTempDetails
  } = useProfileLogic(autoEditDetails);

  const { handleSubmit, control, errors, watch, setValue, getValues } = form;

  const professionChangeRequest = user?.professionChangeRequest || {};
  const hasProfessionChangeRequest = !!Object.keys(professionChangeRequest)
    .length;

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (user.graduationDate) {
      const splitDate = user.graduationDate.split("-");
      const tempDate = new Date(
        splitDate[0],
        splitDate[1] - 1,
        splitDate[2],
        0,
        0
      );
      setGradDate(
        new Intl.DateTimeFormat("default", {
          month: "long",
          year: "numeric"
        }).format(tempDate)
      );
    }
  }, [user.graduationDate]);

  useEffect(() => {
    if (!user.isVerified) {
      history.replace(getUrl(SETTINGS_PROFILE));
    }
  }, [user, history]);
  /** ********************************* FUNCTIONS *************************************/
  const onProfessionChange = (v) => {
    if (v && user) {
      setUseVerifyPath(
        v.label?.toLowerCase() !== user.professionName?.toLowerCase() &&
          !user.verification?.npi
      );
    }
  };

  const onEditClick = () => {
    history.replace(getUrl(SETTINGS_PROFILE_EDIT));
    clearTempDetails();
    onEditMode();
    trackProfileEditClick(hasProfessionChangeRequest);
  };

  const onCancelClick = () => {
    history.replace(getUrl(SETTINGS_PROFILE));
    clearTempDetails();
    onCancel();
  };

  const onSaveClick = async () => {
    handleSubmit(async (v) => {
      v.originalGradDate = user.graduationDate;

      if (useVerifyPath) {
        trackProfileVerifyClick();
        await setTempDetails(v);
        history.push(getUrl(SETTINGS_PROFILE_VERIFICATION));
      } else {
        const success = await onSave(v);
        if (success) {
          history.replace(getUrl(SETTINGS_PROFILE));
        }
      }
    })();
  };

  const onVerifiedEditClick = useVerifiedUserGate(onEditClick);
  const professionName = hasProfessionChangeRequest
    ? professionChangeRequest?.profession?.professionName
    : user.professionName;
  const specialtyName = hasProfessionChangeRequest
    ? professionChangeRequest?.onboardingDisplayName
    : user.primarySpecialty?.onboardingDisplayName;
  /** ********************************** RENDER ***************************************/

  if (!user) return null;

  return (
    <>
      <h2 className="d-flex justify-content-between align-items-center text-18">
        <span className="helv-bold">
          {i18n.t("Settings.Profile.AccountDetails.title")}
        </span>{" "}
        {!editMode && (
          <Button color="link" onClick={onVerifiedEditClick}>
            <CustomIcon
              icon="profile_edit_pencil"
              className="icon-denim-blue"
              size={18}
            />{" "}
            {i18n.t("Settings.Profile.AccountDetails.editDetailsButton")}
          </Button>
        )}
      </h2>
      {user?.isVerified && (
        <p className="d-flex align-items-center text-14">
          <CustomIcon
            icon={"verification_checkshield_web"}
            size={16}
            className="mr-2"
          />{" "}
          {i18n.t("Settings.Profile.AccountDetails.accountVerified")}
        </p>
      )}
      <div className="mb-3">
        {!editMode && (
          <>
            <h3 className="label text-14 helv-med mb-0">
              {i18n.t("Settings.Profile.AccountDetails.nameField")}
            </h3>
            <p className="text-18 mb-3">{user.displayName}</p>

            <h3 className="label text-14 helv-med mb-0">
              {i18n.t("Settings.Profile.AccountDetails.professionField")}
            </h3>
            {hasProfessionChangeRequest && (
              <>
                <p className="text-18 mb-0">{professionName}</p>
                <p className="d-flex align-items-center text-14 mb-3 text-battleship-gray">
                  <CustomIcon
                    className="icon-battleship-gray mr-1"
                    size={14}
                    icon="information_filled"
                  />
                  {i18n.t(
                    "Settings.Profile.AccountDetails.pendingProfessionChangeText"
                  )}
                </p>
              </>
            )}
            {!hasProfessionChangeRequest && (
              <p className="text-18 mb-3">{professionName}</p>
            )}

            {specialtyName !== professionName && (
              <>
                <h3 className="label text-14 helv-med mb-0">
                  {i18n.t(
                    "Settings.Profile.AccountDetails.primarySpecialtyField"
                  )}
                </h3>

                <p className="text-18 mb-3">{specialtyName}</p>
              </>
            )}

            {gradDate && (
              <>
                <h3 className="label text-14 helv-med mb-0">
                  {i18n.t(
                    "Settings.Profile.AccountDetails.ExpectedGraduationField"
                  )}
                </h3>
                <p className="text-18 mb-3">{gradDate}</p>
              </>
            )}
          </>
        )}

        {editMode && (
          <>
            <FormUserInfoV2
              control={control}
              errors={errors}
              watch={watch}
              setValue={setValue}
              getValues={getValues}
              existingData={user?.initialized ? user : {}}
              updateMode={true}
              professionChangeCB={onProfessionChange}
            />

            <div className="d-flex justify-content-between">
              <Button className="w-215px" onClick={onCancelClick}>
                {i18n.t("common.cancel")}
              </Button>
              <LoadingButton
                className="w-215px"
                color="primary"
                spinnerColor="light"
                disabled={saving}
                loading={saving}
                onClick={onSaveClick}>
                {saveLabel}
              </LoadingButton>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default AccountDetails;
