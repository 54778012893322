import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Figure1Layout from "../../@layouts/Figure1Layout";
import i18n from "../../utils/i18n";
import { REGISTRATION_STATES } from "../../constants/registration-states";

import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import RegistrationHeader from "./components/RegistrationHeader";
import { Link } from "react-router-dom";
import Button from "reactstrap/lib/Button";

import { userSignedIn } from "../../utils/route-utils";
import { signOut } from "../../actions/login.actions";
import introVideo from "../../assets/video/splash screen_slowest_web.mp4";
import fallbackImg from "../../assets/images/web hero.webp";

import "./SplashPage.scss";
import ExternalTextLink from "./components/ExternalTextLink";
import { StaticStrings } from "../../constants/static-string-constants";
import AppPrompt from "../../components/app-prompt/AppPrompt";

const SplashPage = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();

  const isSignedIn = useSelector((state) => userSignedIn(state.user));
  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (isSignedIn) {
      dispatch(signOut(null, true, false));
    }
  }, [dispatch, isSignedIn]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout
      noGatedHeader={true}
      topHeader={<RegistrationHeader />}
      footer={
        <div className="m-4">
          <AppPrompt isDark />
        </div>
      }>
      <div className="text-white d-flex flex-column align-items-center m-0 spashPage">
        <>
          <h1 className="text-center helv-standard">
            {i18n.t("RegistrationScreens.introPage.title")}
          </h1>
          <div id="background-media">
            <video
              style={{ backgroundColor: "white" }}
              poster={fallbackImg}
              playsInline
              autoPlay
              loop
              muted>
              <source src={introVideo} type="video/mp4" />
              <img src={fallbackImg} alt="" />
            </video>
          </div>
          <Button
            className="w-295px mb-3 btn-primary text-dark-blue helv-bold text-16"
            color="white"
            tag={Link}
            to={ROUTES.REGISTRATION_NEW_V2}>
            {i18n.t("RegistrationScreens.introPage.btnCreateAccount")}
          </Button>
          <Button
            className="w-295px helv-bold text-16 mb-3"
            outline
            tag={Link}
            to={getUrl(ROUTES.SIGN_IN, {
              loginState: REGISTRATION_STATES.LOGIN
            })}>
            {i18n.t("RegistrationScreens.introPage.btnSignIn")}
          </Button>
          <ExternalTextLink
            className="helv-standard text-14 text-white text-underline"
            url={StaticStrings.aboutFigure1}
            linkText={i18n.t("RegistrationScreens.introPage.learnMore")}
          />
        </>
      </div>
    </Figure1Layout>
  );
};

export default SplashPage;
