/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

const REFERENCE_DOCUMENT = "specialties";

export const fetchSpecialties = async () => {
  try {
    const doc = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_NEW)
      .doc(REFERENCE_DOCUMENT)
      .get();
    return doc.exists ? doc.data().registration : {};
  } catch (error) {
    console.error("failed to fetch specialties with error: ", error.message);
    throw error;
  }
};

export const fetchTaggingSpecialties = async () => {
  try {
    const doc = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_NEW)
      .doc(REFERENCE_DOCUMENT)
      .get();
    return doc.exists ? doc.data().tagging : {};
  } catch (error) {
    console.error("failed to fetch specialties with error: ", error.message);
    throw error;
  }
};
