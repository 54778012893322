import React, { useState, useImperativeHandle, useCallback } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import SearchForm from "../../../components/forms/SearchForm";
import {
  useGetSearchSuggestions,
  useGetSearchResults
} from "../../../hooks/search.hooks";
import SearchSuggestionContainer from "./SearchSuggestionContainer";
import { SUGGESTION_SECTIONS } from "../../../constants/suggestion-section-constants";
import { trackUserSearch } from "../../../actions/metrics.actions";
import { SEARCH_TYPE } from "../../../metrics/constants.metrics";
import classNames from "../../../utils/class-names-utils";
import {
  clearSearchSuggestions,
  updateSearchTerm
} from "../../../actions/search.actions";
import { useSelectSearchTerm } from "../../../selectors/search.selectors";

const UsersSearch = React.forwardRef((props, searchRef) => {
  const dispatch = useDispatch();

  const searchInput = useSelectSearchTerm();
  const search = useGetSearchResults();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const { searchSuggestions, showSearchSuggestions } =
    useGetSearchSuggestions(searchInput);

  const clearSearchTerms = useCallback(() => {
    setIsDropdownOpen(false);
    dispatch(clearSearchSuggestions());
  }, [dispatch]);

  useImperativeHandle(searchRef, () => ({
    clearSearchTerms: () => {
      clearSearchTerms();
    }
  }));

  const searchUpdate = (text) => {
    setIsDropdownOpen(true);
    if (text) {
      dispatch(updateSearchTerm(text));
    } else {
      clearSearchTerms();
    }
  };

  const handleSearch = ({ _searchTerms, _searchIndex }) => {
    clearSearchTerms();
    search({
      searchTerm: _searchTerms,
      searchResultType: "profile",
      searchIndex: _searchIndex,
      isPaginating: true
    });
  };

  // _searchTerms can be whatever's typed in the search bar or a search suggestion
  const onSearchEverything = (context, _searchTerms) => {
    sendMetrics(context, _searchTerms);

    handleSearch({ _searchTerms });
  };

  const sendMetrics = (context, term) => {
    trackUserSearch({
      context,
      term
    });
  };

  const fixedSuggestions = [];

  const data = [
    {
      title: SUGGESTION_SECTIONS.FIXED_CHANNELS,
      data: fixedSuggestions
    }
  ];

  if (showSearchSuggestions) {
    data.unshift({
      title: SUGGESTION_SECTIONS.SEARCH_TERM,
      data: searchSuggestions.map((item) => ({
        ...item,
        onPress: () => {
          onSearchEverything(SEARCH_TYPE.SUGGESTION, item.text);
        }
      }))
    });
  }

  return (
    <div
      className={classNames(
        "feed-search-form",
        isDropdownOpen ? "feed-search-form--open" : undefined,
        props.className
      )}>
      <SearchForm
        defaultValue={searchInput}
        onChange={(e) => searchUpdate(e.target.value)}
        onClear={() => clearSearchTerms()}
        onEnter={() => onSearchEverything(SEARCH_TYPE.DIRECT, searchInput)}
        autoFocus={props.autoFocus}
        inputStyle={props.inputStyle}
      />
      {isDropdownOpen && <SearchSuggestionContainer data={data} />}
    </div>
  );
});

UsersSearch.propTypes = {
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool
};

UsersSearch.defaultProps = {
  autoFocus: false
};

export default UsersSearch;
