import { capitalize } from "lodash";
import React, { useEffect } from "react";
import ReactMarkdown from "react-markdown";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";
import ISILayout from "../../components/isi/ISILayout";
import { CASE_CME } from "../../constants/routes";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import MiniCaseCard from "./components/MiniCaseCard";
import { trackCaseCMEFrontMatterEntry } from "../../actions/metrics.actions";
import "./CaseCMEFrontMatter.scss";

const CaseCMEFrontMatter = ({ caseId }) => {
  /** ********************************** CONFIG ***************************************/
  const caseDetails = useSelector((state) => state.case.cases[caseId]?.data);
  const frontMatterString = useSelector(
    (state) => state.configuration.data?.caseCMEFrontMatterText
  );
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    trackCaseCMEFrontMatterEntry(caseId);
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <ISILayout enableGatedBanner>
      <Figure1Page3Col
        isChildPage
        mainContent={
          <>
            <Card className="mb-1 border-0">
              <CardBody className="text-center">
                <h1 className="text-18 flex-1 helv-bold flex-grow-1 text-nowrap mb-0">
                  {i18n.t("CaseCME.caseActivityLabel")}
                </h1>
              </CardBody>
            </Card>
            <Card className="border-0">
              <CardBody className="front-matter-string">
                <ReactMarkdown>{frontMatterString}</ReactMarkdown>
              </CardBody>
              <CardBody className="d-flex justify-content-center">
                <Link
                  className="btn btn-lg btn-primary w-215px"
                  to={getUrl(CASE_CME, { caseId })}>
                  {capitalize(i18n.t("common.next"))}
                </Link>
              </CardBody>
            </Card>
          </>
        }
        leftSidebarContent={<MiniCaseCard caseDetails={caseDetails} />}
      />
    </ISILayout>
  );
};

export default CaseCMEFrontMatter;
