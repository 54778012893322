/** @format */

import React, { useLayoutEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, Container, Row } from "reactstrap";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import useISI from "../../../components/isi/useISI";
import { ContentProp } from "../../../prop-types/ContentProp";
import { ISI_FOOTER_HEIGHT } from "../../../constants/isi-constants";
import useWindowDimensions from "../../../hooks/use-window-size.hooks";
import ShareButton from "../../../components/case/ShareButton";

function calculateModalFooterOffset(modalBodyElement, modalFooterElement) {
  const bodyRect = modalBodyElement.getBoundingClientRect();
  const footerRect = modalFooterElement.getBoundingClientRect();

  const bodyCenterX = bodyRect.width / 2;
  const footerCenterX = footerRect.width / 2;

  return bodyRect.left + bodyCenterX - footerCenterX;
}

const CMEModalSection = React.forwardRef(
  ({ content, canShare, footer, onExit, onShareClick, children }, ref) => {
    /****************************** Config **************************************/
    const { showFooter } = useISI(content ?? {}, false);
    const modalBodyRef = useRef(null);
    const modalFooterRef = useRef(null);
    const [footerLeftPosition, setFooterLeftPosition] = useState(null);
    const windowDimensions = useWindowDimensions();

    /****************************** Hooks ***************************************/

    useLayoutEffect(() => {
      if (modalBodyRef.current && modalFooterRef.current) {
        const leftOffset = calculateModalFooterOffset(
          modalBodyRef.current,
          modalFooterRef.current
        );
        setFooterLeftPosition(leftOffset);
      }
    }, [windowDimensions]);

    /****************************** Functions ***********************************/

    /****************************** Render **************************************/

    return (
      <>
        <div
          className="position-relative flex-grow-1 overflow-y-auto"
          ref={ref}>
          <div
            className="modal-body d-flex justify-content-center position-absolute l-0 r-0 t-0 mw-491 m-auto py-0"
            ref={modalBodyRef}>
            <>
              <Button
                className="btn-text d-inline-flex justify-content-center align-items-center position-fixed t-0 l-0 z-fixed"
                color="battleship-gray"
                onClick={onExit}>
                <div className="bg-battleship-gray rounded mr-lg-2 rounded-circle">
                  <CustomIcon
                    className="icon-darkest m-2"
                    icon="modal_exit"
                    size={12}
                  />
                </div>
                <span className="d-none d-lg-block">
                  {i18n.t("CMECaseScreens.exitActivity")}
                </span>
              </Button>
              {canShare && (
                <ShareButton
                  className="btn-text position-fixed t-0 r-0 z-fixed mr-2"
                  iconClassName="mr-lg-2"
                  text={
                    <span className="d-none d-lg-block">
                      {i18n.t("CaseAction.sharePost")}
                    </span>
                  }
                  onClick={onShareClick}
                />
              )}
              {children}
            </>
          </div>
          {footer && (
            <div
              className="modal-footer d-flex justify-content-center fixed-bottom r-0 mw-499 mx-auto1 mb-3 bg-dark border-0 rounded"
              style={{
                boxShadow: "0px -9px 22px 0 rgba(25, 24, 24, 0.64)",
                bottom: showFooter ? ISI_FOOTER_HEIGHT : 0,
                left: footerLeftPosition
              }}
              ref={modalFooterRef}>
              <Container className="px-4">
                <Row className="align-items-center">{footer}</Row>
              </Container>
            </div>
          )}
        </div>
      </>
    );
  }
);

CMEModalSection.propTypes = {
  content: ContentProp,
  children: PropTypes.node,
  footer: PropTypes.node,
  onExit: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired
};

export default CMEModalSection;
