/** @format */

const FIREBASE_ERROR = {
  AUTH_EXPIRED: "auth/expired-action-code",
  INVALID_ACTION_CODE: "auth/invalid-action-code",
  INVALID_EMAIL: "auth/invalid-email",
  WRONG_PASSWORD: "auth/wrong-password",
  EMAIL_EXISTS: "auth/email-already-in-use"
};

export default FIREBASE_ERROR;
