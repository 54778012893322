/** @format */
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import {
  Button,
  CardFooter,
  CardTitle,
  CardBody,
  Card,
  CardText
} from "reactstrap";
import LinesEllipsis from "react-lines-ellipsis";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { quizVote } from "../../actions/quiz.actions";
import VIDEO_CONTROL_MODE from "../../constants/video-control-mode-constants";
import { useCaseBanner } from "../../hooks/use-case-banner.hooks";
import {
  useInteractiveCaseState,
  useSelectUserVote
} from "../../selectors/interactive-case-state";
import classNames from "../../utils/class-names-utils";
import CaseMediaView from "./CaseMediaView";
import CaseBanner from "./CaseBanner";
import CaseHeader from "./CaseHeader";
import HeadingBanner from "./HeadingBanner";
import TagsContainer from "./TagsContainer";
import VoteOptionsList from "./VoteOptionsList";
import CustomIcon from "../custom-icon/CustomIcon";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";
import { getCaseTypeUrl } from "../../utils/case-utils";

const ON_VOTE_TRANSITION_TIME = 1000;

const QuestionCard = ({
  caseContent,
  goToQuestionDetailView,
  className,
  position,
  onIsFrameSwipeVisible
}) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const { showBanner, bannerType, handleShowBanner } = useCaseBanner();
  const [isExpanded, setIsExpanded] = useState(false);

  const caseState = useInteractiveCaseState(caseContent.caseUuid);
  const userAnswer = useSelectUserVote(
    caseContent.caseUuid,
    caseContent.contentUuid
  );

  let displayCta = false;
  if (caseContent.buttonText) {
    displayCta = true;
  }

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const toggleExpandedView = () => {
    if (!displayCta) {
      setIsExpanded(!isExpanded);
    }
  };

  const onVote = async (questionOptionUuid) => {
    try {
      dispatch(
        quizVote(
          caseContent.contentUuid,
          questionOptionUuid,
          caseContent.caseUuid
        )
      );
    } catch (e) {
      console.log("ERROR: ", e.message);
    }
  };

  /** ********************************** RENDER ***************************************/
  const linkUrl = getCaseTypeUrl(caseContent);

  return (
    <div className={classNames("feed-card", className)}>
      {showBanner && <CaseBanner bannerType={bannerType} />}
      <Card className="border-outline-gray rounded-0">
        <CaseHeader
          caseContent={caseContent}
          caseNotification={handleShowBanner}
        />

        {caseContent.media?.length > 0 && (
          <CaseMediaView
            className="pb-0"
            canNavigate={true}
            caseUuid={caseContent.caseUuid}
            caseType={caseContent.caseType}
            media={caseContent.media}
            videoPlayerConfig={{
              videoControlMode: VIDEO_CONTROL_MODE.FEED
            }}
            linkUrl={linkUrl}
            context={CASE_CARD_ORIGIN.FEED}
            onIsFrameSwipeVisible={onIsFrameSwipeVisible}
          />
        )}

        {!!caseContent.heading && (
          <HeadingBanner
            text={caseContent.heading}
            isPoll={caseContent.isPoll}
          />
        )}

        <CardBody>
          <TagsContainer
            caseContent={caseContent}
            currentIndex={0}
            caseState={caseState}
            isFeedCard
          />
        </CardBody>

        <CardBody className="pt-0">
          <Link
            className="text-decoration-none text-default-black"
            to={{
              pathname: getCaseTypeUrl(caseContent),
              state: {
                position
              }
            }}>
            <CardTitle className="mb-2" tag="h6">
              <LinesEllipsis
                className="text-darker text-16"
                text={caseContent.title}
                maxLine={isExpanded ? Number.MAX_VALUE.toString() : "3"}
                ellipsis="..."
                trimRight
                basedOn="letters"
                component="span"
              />
            </CardTitle>
            <CardText
              tag="div"
              className={classNames(
                "feed-card-info feed-card-info-markdown",
                isExpanded ? undefined : "text-clamp-21-3"
              )}>
              <ReactMarkdown>{caseContent.caption}</ReactMarkdown>
            </CardText>
          </Link>
        </CardBody>

        <CardBody className="position-relative pt-0">
          {!isExpanded && !displayCta && (
            <div
              className="position-absolute t-0 r-0 b-0 l-0 z-1"
              style={{
                backgroundImage:
                  "linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.25) 25%, rgba(255, 255, 255, 1) 100%)"
              }}
            />
          )}
          {displayCta && (
            <Button
              className="text-16"
              size="lg"
              color="denim-blue"
              outline
              onClick={goToQuestionDetailView}>
              {caseContent.buttonText}
            </Button>
          )}
          {!displayCta && (
            <div
              className={classNames(
                "overflow-hidden",
                isExpanded ? "mh-100" : "mh-80px"
              )}>
              <VoteOptionsList
                options={caseContent.questionOptions}
                userAnswer={userAnswer}
                onVote={(optionUuid) => {
                  if (isExpanded) {
                    onVote(optionUuid);
                  } else {
                    toggleExpandedView();
                  }
                }}
                onAfterVote={() => {
                  if (userAnswer) {
                    goToQuestionDetailView();
                  } else {
                    // Give time for UI to update and show user their selection before navigation
                    setTimeout(() => {
                      goToQuestionDetailView();
                    }, ON_VOTE_TRANSITION_TIME);
                  }
                }}
              />
            </div>
          )}
        </CardBody>

        {!displayCta && (
          <CardFooter className="bg-white border-0">
            <Button
              className="btn-text d-flex align-self-end bg-denim-blue-opaque rounded-circle p-1 mx-auto"
              size="sm"
              color="light"
              onClick={toggleExpandedView}
              style={{
                transform: `rotate(${isExpanded ? "180deg" : "0deg"})`,
                transition: "transform 0.25s"
              }}>
              <CustomIcon
                className="icon-light"
                size={16}
                icon="detail_comment_sort"
              />
            </Button>
          </CardFooter>
        )}
      </Card>
    </div>
  );
};

export default QuestionCard;

QuestionCard.propTypes = {
  goToQuestionDetailView: PropTypes.func,
  caseContent: PropTypes.shape({
    authorProfessionalLabel: PropTypes.string,
    authorProfessionalUuid: PropTypes.string,
    author_uid: PropTypes.string,
    author_username: PropTypes.string,
    contentType: PropTypes.string,
    contentCount: PropTypes.number,
    contentUuid: PropTypes.string,
    heading: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    caseUuid: PropTypes.string,
    case_uuid: PropTypes.string,
    isSaved: PropTypes.bool,
    is_saved: PropTypes.bool,
    buttonText: PropTypes.string,
    questionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        displayOrder: PropTypes.number,
        isAnswer: PropTypes.bool,
        questionOptionUuid: PropTypes.string,
        text: PropTypes.string,
        votes: PropTypes.number
      })
    ).isRequired,
    sponsoredContent: PropTypes.shape({
      disclosureText: PropTypes.string,
      sponsoredText: PropTypes.string
    }),
    userAnswer: PropTypes.string,
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        depth: PropTypes.number,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        label: PropTypes.string,
        legacyAccount: PropTypes.bool,
        name: PropTypes.string,
        specialtyUuid: PropTypes.string,
        authorUid: PropTypes.string,
        username: PropTypes.string
      })
    ),
    media: PropTypes.arrayOf(
      PropTypes.shape({
        caseUuid: PropTypes.string,
        contentUuid: PropTypes.string,
        displayOrder: PropTypes.number,
        filename: PropTypes.string,
        height: PropTypes.number,
        mediaId: null,
        mediaUuid: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        uuid: PropTypes.string,
        width: PropTypes.number
      })
    ),
    shareLink: PropTypes.string,
    share_link: PropTypes.string
  }),
  onIsFrameSwipeVisible: PropTypes.func
};
