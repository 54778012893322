import { useSelector } from "react-redux";
import { isUserUSA } from "../utils/user-utils";

const useIsUSAUser = () => {
  const user = useSelector((state) => state.user);
  // make sure they're actually a user before bothering to check
  return isUserUSA(user);
};

export default useIsUSAUser;
