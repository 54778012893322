import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../utils/i18n";
import Dialog from "../../dialog/Dialog";
import CustomIcon from "../../custom-icon/CustomIcon";
import "./AcceptedAnswerDialog.scss";
import AcceptedComment from "./AcceptedComment";
import { useDispatch, useSelector } from "react-redux";
import F1HorizontalDivider from "../../common/F1HorizontalDivider";
import { markAcceptedAnswer } from "../../../actions/comment.actions";
import {
  showErrorMessage,
  showSuccessMessage
} from "../../../actions/global.actions";
import { trackAcceptedAnswerEvent } from "../../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";
import Loading from "../../loading";
import ReportedComment from "./ReportedComment";

const ACTION_TYPES = {
  ADD_NEW: "add new",
  REPLACE: "replace",
  REMOVE: "remove"
};

const AcceptedAnswerDialog = ({
  isOpen,
  toggle,
  comment,
  caseContent,
  hasAcceptedAnswer
}) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const isProcessing = useSelector((state) => state.comment?.acceptProcessing);
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const onSubmitCall = async (action, successMessage, eventType) => {
    try {
      trackAcceptedAnswerEvent({
        event: eventType,
        commentUuid: comment.commentUuid,
        caseUuid: caseContent.caseUuid
      });

      const result = await dispatch(
        markAcceptedAnswer({
          commentUuid: comment?.commentUuid,
          isAnswer: action !== ACTION_TYPES.REMOVE
        })
      );

      if (!result.error) {
        toggle();
        dispatch(showSuccessMessage(successMessage, null, false, null, false));
      }
    } catch (error) {
      dispatch(showErrorMessage(error, null, false, error));
    }
  };

  const selectNewAcceptedAnswer = () => {
    const successMessage = i18n.t(
      "comments.acceptedAnswerDialog.selectNewGlobalMessage"
    );
    onSubmitCall(
      ACTION_TYPES.ADD_NEW,
      successMessage,
      METRICS_EVENT_NAME.COMMENT_OR_REPLY.ACCEPTED_ANSWER
        .SELECTED_ACCEPTED_ANSWER
    );
  };
  const replaceAcceptedAnswer = () => {
    const successMessage = i18n.t(
      "comments.acceptedAnswerDialog.replaceGlobalMessage"
    );
    onSubmitCall(
      ACTION_TYPES.REPLACE,
      successMessage,
      METRICS_EVENT_NAME.COMMENT_OR_REPLY.ACCEPTED_ANSWER
        .REPLACED_ACCEPTED_ANSWER
    );
  };
  const removeAcceptedAnswer = () => {
    const successMessage = i18n.t(
      "comments.acceptedAnswerDialog.removeGlobalMessage"
    );
    onSubmitCall(
      ACTION_TYPES.REMOVE,
      successMessage,
      METRICS_EVENT_NAME.COMMENT_OR_REPLY.ACCEPTED_ANSWER
        .REMOVED_ACCEPTED_ANSWER
    );
  };

  /************************************ RENDER ***************************************/
  const SelectNewBody = (
    <div className="w-100">
      <h2 className="d-flex align-items-center justify-content-center flex-grow-1 helv-med text-20 line-height-26 my-4">
        {i18n.t("comments.acceptedAnswerDialog.selectNewTitle")}
      </h2>
      <div className="acceptedCommentBox px-3 py-3">
        <AcceptedComment caseUuid={caseContent?.caseUuid} comment={comment} />
      </div>
      <p className="d-flex m-0 mt-3 text-left text-13 line-height-16 text-battleship-gray">
        <CustomIcon
          className="icon-battleship-gray mr-2"
          size={26}
          icon="information_filled"
        />
        {i18n.t("comments.acceptedAnswerDialog.medicallyRelevantNote")}
      </p>
    </div>
  );

  const ReplaceBody = (
    <div className="w-100">
      <h2 className="d-flex align-items-center justify-content-center flex-grow-1 helv-med text-20 line-height-26 mt-4">
        {i18n.t("comments.acceptedAnswerDialog.replaceTitle")}
      </h2>
      <p className="text-muted text-15 line-height-20 m-0 mt-3 px-2">
        {i18n.t("comments.acceptedAnswerDialog.replaceDescription")}
      </p>
      <div className="acceptedCommentBox px-3 py-3 mt-4">
        {caseContent?.acceptedAnswer?.isReported ? (
          <ReportedComment comment={caseContent?.acceptedAnswer} />
        ) : (
          <AcceptedComment
            caseUuid={caseContent?.caseUuid}
            comment={caseContent?.acceptedAnswer}
            currentAcceptedAnswer
          />
        )}

        <F1HorizontalDivider className="w-100" color="light-gray">
          <span className="color-battleship-gray text-14 line-height-18">
            <CustomIcon
              icon="double_chevron_2"
              className="mr-3 icon-light-gray"
              size={12}
            />
            {i18n.t("comments.acceptedAnswerDialog.replaceWith")}
            <CustomIcon
              icon="double_chevron_2"
              className="ml-3 icon-light-gray"
              size={12}
            />
          </span>
        </F1HorizontalDivider>
        <AcceptedComment
          caseUuid={caseContent?.caseUuid}
          comment={comment}
          newAcceptedAnswer
        />
      </div>
    </div>
  );

  const RemoveBody = (
    <div className="w-100">
      <h2 className="d-flex align-items-center justify-content-center flex-grow-1 helv-med text-20 line-height-26 my-4">
        {i18n.t("comments.acceptedAnswerDialog.removeTitle")}
      </h2>
      <div className="acceptedCommentBox px-3 py-3">
        <AcceptedComment caseUuid={caseContent?.caseUuid} comment={comment} />
      </div>
    </div>
  );

  const { body, confirmText, onSubmit } = (() => {
    if (isProcessing) {
      return {
        body: (
          <div style={{ minHeight: "400px" }}>
            <Loading />
          </div>
        ),
        confirmText: null,
        onSubmit: () => {}
      };
    } else if (!hasAcceptedAnswer) {
      return {
        body: SelectNewBody,
        confirmText: i18n.t("comments.selectAcceptedAnswer"),
        onSubmit: selectNewAcceptedAnswer
      };
    } else if (hasAcceptedAnswer && !comment.isAcceptedAnswer) {
      return {
        body: ReplaceBody,
        confirmText: i18n.t("comments.replaceAcceptedAnswer"),
        onSubmit: replaceAcceptedAnswer
      };
    } else if (hasAcceptedAnswer && comment.isAcceptedAnswer) {
      return {
        body: RemoveBody,
        confirmText: i18n.t("comments.removeAcceptedAnswer"),
        onSubmit: removeAcceptedAnswer
      };
    }
    return null;
  })();

  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      toggle={toggle}
      body={body}
      confirmText={confirmText}
      confirmLoading={isProcessing}
      confirmClassName={"w-295px helv-bold"}
      cancelText={i18n.t("common.cancel")}
      cancelClassName={"w-295px helv-bold"}
      onConfirm={onSubmit}
    />
  );
};

AcceptedAnswerDialog.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

AcceptedAnswerDialog.defaultProps = {};

export default AcceptedAnswerDialog;
