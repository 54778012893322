/**
 * All possible types of activities sent from Backend.
 *
 * @format
 */

export const NOTIFICATION_TYPES = {
  APPROVE: "approve",
  REJECT: "reject",
  CASE_DELETE: "case_delete",
  COMMENT_DELETE: "comment_delete",
  DRAFT_REMINDER: "draft_reminder",
  REACT: "react",
  COMMENT: "comment",
  COMMENT_REPLY: "comment_reply",
  COMMENT_REPLY_OP: "comment_reply_op",
  COMMENT_SAVED_CASE: "comment_saved_case",
  COMMENT_SAVED_CASE_OP: "comment_saved_case_op",
  NEW_FOLLOWER: "new_follower",
  NEW_CASE_FOLLOWED_USER: "new_case_followed_user",
  NEW_CASE_USER_SAVED_CASE: "new_case_user_saved_case",
  SAVED_CASE_UPDATE: "saved_case_update",
  PAGING: "paging",
  NEW_GROUP_CASE: "new_group_case",
  CASE_NOT_DIAGNOSIS_CHOSEN: "case_not_diagnosis_chosen",
  PROFESSION_CHANGE_APPROVED: "profession_change_approved",
  COMMENTED_CASE_DIAGNOSIS: "commented_case_diagnosis",
  SAVED_CASE_DIAGNOSIS: "saved_case_diagnosis",
  LIKED_CASE_DIAGNOSIS: "liked_case_diagnosis",
  GROUP_INVITE_ACCEPTED: "group_invite_accepted",
  NEW_ACCEPTED_ANSWER_SELECTED: "new_accepted_answer_selected",
  NEW_ACCEPTED_ANSWER_LIKED_CASE: "new_accepted_answer_liked_case",
  NEW_ACCEPTED_ANSWER_COMMENTED_CASE: "new_accepted_answer_commented_case",
  NEW_ACCEPTED_ANSWER_SAVED_CASE: "new_accepted_answer_saved_case",
  ACCEPTED_ANSWER_DELETED: "accepted_answer_deleted",
  CASE_NOT_ACCEPTED_ANSWER_CHOSEN: "case_not_accepted_answer_chosen"
};

export const USER_ACTIVITY_TYPES = [NOTIFICATION_TYPES.NEW_FOLLOWER];

export const ACTIVITY_STATES = {
  NEW: "new",
  ACKNOWLEDGED: "acknowledged"
};

export const CASE_NOTIFICATIONS = [
  NOTIFICATION_TYPES.APPROVE,
  NOTIFICATION_TYPES.NEW_CASE_FOLLOWED_USER,
  NOTIFICATION_TYPES.NEW_CASE_USER_SAVED_CASE,
  NOTIFICATION_TYPES.REACT,
  NOTIFICATION_TYPES.SAVED_CASE_UPDATE,
  NOTIFICATION_TYPES.PAGING,
  NOTIFICATION_TYPES.NEW_GROUP_CASE,
  NOTIFICATION_TYPES.CASE_NOT_DIAGNOSIS_CHOSEN,
  NOTIFICATION_TYPES.COMMENTED_CASE_DIAGNOSIS,
  NOTIFICATION_TYPES.SAVED_CASE_DIAGNOSIS,
  NOTIFICATION_TYPES.LIKED_CASE_DIAGNOSIS,
  NOTIFICATION_TYPES.NEW_ACCEPTED_ANSWER_LIKED_CASE,
  NOTIFICATION_TYPES.NEW_ACCEPTED_ANSWER_COMMENTED_CASE,
  NOTIFICATION_TYPES.NEW_ACCEPTED_ANSWER_SAVED_CASE,
  NOTIFICATION_TYPES.ACCEPTED_ANSWER_DELETED,
  NOTIFICATION_TYPES.CASE_NOT_ACCEPTED_ANSWER_CHOSEN
];

export const COMMENT_REPLY_NOTIFICATIONS = [
  NOTIFICATION_TYPES.COMMENT,
  NOTIFICATION_TYPES.COMMENT_REPLY,
  NOTIFICATION_TYPES.COMMENT_REPLY_OP,
  NOTIFICATION_TYPES.COMMENT_SAVED_CASE,
  NOTIFICATION_TYPES.COMMENT_SAVED_CASE_OP,
  NOTIFICATION_TYPES.NEW_ACCEPTED_ANSWER_SELECTED
];
