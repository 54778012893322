import React, { useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import { Card, CardBody, FormFeedback, FormGroup, FormText } from "reactstrap";
import LoadingButton from "../../components/common/LoadingButton";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { MAX_SUMMARY_CHAR } from "../../constants/case-constants";
import i18n from "../../utils/i18n";
import ShareWithInput from "./components/ShareWithInput";
import ImagesUploader from "./components/ImagesUploader";
import SpecialtySelectInput from "./components/SpecialtySelectInput";
import useUserHasGroups from "../../hooks/useUserHasGroups";
import PlainTextEditor from "./components/PlainTextEditor";
import { trim } from "lodash";

const NewPostForm = ({
  draft,
  combinedSpecialties,
  control,
  errors,
  setValue,
  isSaving,
  fileRef,
  onFileEdit,
  onRemoveFile,
  learnMoreClick,
  onSubmit
}) => {
  /** ********************************** CONFIG ***************************************/
  const hasGroups = useUserHasGroups();
  const [captionError, setCaptionError] = useState(null);
  const fieldWatch = useWatch({
    control,
    name: ["groupUuid", "caption", "specialties"],
    defaultValue: {
      groupUuid: null,
      caption: "",
      specialties: []
    }
  });

  const disableSubmit =
    isSaving ||
    (hasGroups && !fieldWatch?.groupUuid?.value) ||
    !trim(fieldWatch?.caption);
  /** ********************************** HOOKS ***************************************/
  useEffect(() => {
    setValue("caption", draft?.caption);
  }, [draft?.caption, setValue]);

  useEffect(() => {
    if (errors?.caption) {
      switch (errors?.caption?.type) {
        case "maxLength":
          setCaptionError("shareCase.caseSummaryLength");
          break;
        case "required":
        default:
          setCaptionError("shareCase.caseSummaryRequired");
          break;
      }
    }
  }, [errors?.caption]);
  /** ********************************** FUNCTIONS ***************************************/
  const onCaptionChange = (v) => {
    setValue("caption", v);
  };
  /** ********************************** RENDER ***************************************/
  if (!Object.keys(draft || {}).length) return null;

  return (
    <form onSubmit={onSubmit}>
      <Card className="px-5 py-4">
        <CardBody className="bg-lighter-gray rounded d-flex flex-column align-items-center justify-content-between mb-2">
          <p className="m-0 helv-med text-14 line-height-18">
            <CustomIcon className="mr-2" icon="what_can_I_share" size={20} />
            {i18n.t("sharePost.sharePostQue")}
          </p>
          <LoadingButton
            size="md"
            spinnerSize="sm"
            spinnerColor="primary"
            onClick={learnMoreClick}
            className="cursor-pointer helv-med text-cool-blue text-14 py-0">
            {i18n.t("sharePost.sharePostQueButton")}
          </LoadingButton>
        </CardBody>
        {/* share with dropdown */}
        <ShareWithInput
          draft={draft}
          control={control}
          errors={errors}
          setValue={setValue}
          className="mt-4"
          label={i18n.t("sharePost.shareWith")}
          placeholder={i18n.t("sharePost.shareWithPlaceholder")}
        />
        {/* about post text area */}
        <FormGroup className="mt-3">
          <Controller
            control={control}
            name="caption"
            rules={{
              required: true,
              maxLength: MAX_SUMMARY_CHAR
            }}
            invalid={!!errors?.caption ? 1 : 0}
            as={
              <PlainTextEditor
                defaultValue={draft?.caption || ""}
                label={i18n.t("sharePost.postAbout")}
                maxLength={MAX_SUMMARY_CHAR}
                placeholder={i18n.t("sharePost.postAboutPlaceholder")}
                callback={onCaptionChange}
                className={errors?.caption ? "is-invalid" : ""}
              />
            }
            defaultValue={draft?.caption || ""}
          />
          <FormText className="text-13 line-height-18">
            <CustomIcon
              className="mr-2 icon-battleship-gray"
              icon={"sheet_copy_link"}
              size={14}
            />
            {i18n.t("sharePost.postAboutFootnote")}
          </FormText>
          <FormFeedback>{i18n.t(captionError)}</FormFeedback>
        </FormGroup>
        {/* images uploader */}
        <ImagesUploader
          className="mt-3"
          label={i18n.t("sharePost.addPhotos")}
          fileRef={fileRef}
          media={draft?.media}
          onFileEdit={onFileEdit}
          onRemoveFile={onRemoveFile}
        />
        {/* specialties dropdown */}
        <SpecialtySelectInput
          draft={draft}
          combinedSpecialties={combinedSpecialties}
          control={control}
          errors={errors}
          className="mt-3"
          label={i18n.t("sharePost.addSpecialties")}
          placeholder={i18n.t("sharePost.addSpecialtiesPlaceholder")}
        />

        <LoadingButton
          spinnerSize="sm"
          spinnerColor="secondary"
          disabled={disableSubmit}
          loading={isSaving}
          color="primary"
          size="md"
          type="submit"
          className="w-100 text-15 py-3 mt-4">
          {i18n.t("sharePost.submitPost")}
        </LoadingButton>
      </Card>
    </form>
  );
};

export default NewPostForm;
