import React from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import { capitalize, get, trim } from "lodash";
import PropTypes from "prop-types";
import { submitCaseUpdate } from "../../actions/case.actions";
import { FEED_FILTERS, FILTER_KEYS } from "../../constants/feed-filters";
import CustomIcon from "../custom-icon/CustomIcon";
import LoadingButton from "../common/LoadingButton";
import usePopup from "../popup/usePopup";
import TOAST_TYPE from "../popup/ToastType";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";
import {
  MAX_DIAGNOSIS_LENGTH,
  MAX_SUMMARY_CHAR
} from "../../constants/case-constants";
import { trackCaseUpdate } from "../../actions/metrics.actions";

const CaseDetailUpdateModal = ({
  // Component specific props
  caseId,
  // Modal specific props
  toggle,
  isOpen,
  title,
  children,
  onSubmit,
  ...rest
}) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const { showToast } = usePopup();
  const isProcessing = useSelector((state) =>
    get(state, ["case", "cases", caseId, "isProcessing"], false)
  );
  const caseContent = useSelector((state) =>
    get(state, ["case", "cases", caseId, "data"], {})
  );
  const resolved = (caseContent.labels || []).includes(
    i18n.t("FeedFilter.resolved").toLowerCase()
  );
  const { handleSubmit, errors, control, watch } = useForm({
    mode: "onChange",
    defaultValues: {
      status: resolved ? 1 : 0
    }
  });
  const watchCaption = watch("caption");
  const watchDiagnosis = watch("diagnosis");
  const watchStatus = watch("status");

  const hasExistingDiagnosis = caseContent.diagnoses?.[0]?.text.length > 0;
  const showDiagnosisField =
    !hasExistingDiagnosis &&
    watchStatus === FEED_FILTERS[FILTER_KEYS.filter_resolved].order;

  const disableAddUpdateButton = isProcessing || !trim(watchCaption);
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const save = async (values) => {
    try {
      trackCaseUpdate({
        caseContent,
        diagnosis: showDiagnosisField,
        caseCaption: values.caption,
        unresolved:
          watchStatus === FEED_FILTERS[FILTER_KEYS.filter_unresolved].order
      });
      const result = await dispatch(
        submitCaseUpdate(
          caseId,
          values.caption,
          values.status === FEED_FILTERS[FILTER_KEYS.filter_resolved].order,
          showDiagnosisField ? values.diagnosis : undefined
        )
      );

      if (!result.error) {
        toggle();
      } else {
        showToast({
          message: i18n.t("AddUpdateScreen.postFailed"),
          toastType: TOAST_TYPE.ERROR
        });
      }
    } catch (error) {
      console.log("Submit update error: ", error.message);
      showToast({
        message: i18n.t("AddUpdateScreen.postFailed"),
        toastType: TOAST_TYPE.ERROR
      });
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <Modal centered isOpen={isOpen} toggle={toggle} {...rest}>
      <ModalHeader
        className="border-0"
        toggle={toggle}
        close={
          <Button close onClick={toggle}>
            <CustomIcon icon="modal_exit" size={14} color={"black"} />
          </Button>
        }
      />
      <ModalBody className="px-5 pt-3">
        <FormGroup className="mb-4">
          <Label
            className="d-flex text-nowrap justify-content-between"
            for="caption">
            <span className="text-15 font-weight-500 text-default-black">
              {i18n.t("AddUpdateScreen.prompt")}
            </span>
            <span className="text-muted">
              {watchCaption?.length || 0} / {MAX_SUMMARY_CHAR}
            </span>
          </Label>
          <Controller
            className={classNames(
              "form-control text-15 text-default-black resize-none",
              !!errors.caption && "is-invalid"
            )}
            as={TextareaAutosize}
            control={control}
            id="caption"
            name="caption"
            placeholder={i18n.t("AddUpdateScreen.summaryPlaceholder")}
            type="textarea"
            defaultValue={""}
            minRows={4}
            maxRows={6}
            rules={{
              required: {
                value: true,
                message: i18n.t("AddUpdateScreen.summaryRequired")
              },
              maxLength: {
                value: MAX_SUMMARY_CHAR,
                message: i18n.t("AddUpdateScreen.summaryLength", {
                  length: MAX_SUMMARY_CHAR
                })
              }
            }}
          />
          {!!errors.caption && (
            <FormFeedback>{errors.caption.message}</FormFeedback>
          )}
        </FormGroup>
        <FormGroup tag="fieldset" name="status" className="mb-4">
          <Label
            className="text-15 text-default-black font-weight-500 d-block"
            for="status">
            {i18n.t("AddUpdateScreen.toggleTitle")}
          </Label>
          <Controller
            control={control}
            name="status"
            render={({ onChange, value }) =>
              [
                FEED_FILTERS[FILTER_KEYS.filter_resolved],
                FEED_FILTERS[FILTER_KEYS.filter_unresolved]
              ].map((s) => (
                <Button
                  key={s.order}
                  className={`mr-2 ${errors.status ? "is-invalid" : ""} ${
                    s.order === value
                      ? "active-button"
                      : "inactive-border-button"
                  }`}
                  onClick={() => onChange(s.order)}>
                  {capitalize(s.name)}
                </Button>
              ))
            }
          />
        </FormGroup>
        <FormGroup
          className={classNames("mb-4", showDiagnosisField ? "" : "d-none")}>
          <Label
            className="text-15 text-default-black font-weight-500 d-block line-height-tight"
            for="diagnosis">
            <span className="text-15 font-weight-500 text-default-black">
              {i18n.t("AddUpdateScreen.diagnosisLabel")}
            </span>
            <div className=" d-flex align-items-center text-nowrap justify-content-between flex-wrap">
              <span className="text-14 font-weight-normal text-battleship-gray">
                {i18n.t("AddUpdateScreen.diagnosisLabel2")}
              </span>
              <span className="text-muted">
                {watchDiagnosis?.length || 0} / {MAX_DIAGNOSIS_LENGTH}
              </span>
            </div>
          </Label>
          <Controller
            className={classNames(
              "form-control text-15 text-default-black resize-none",
              !!errors.diagnosis && "is-invalid"
            )}
            as={TextareaAutosize}
            control={control}
            id="diagnosis"
            name="diagnosis"
            placeholder={i18n.t("AddUpdateScreen.diagnosisPlaceholder")}
            type="textarea"
            defaultValue={""}
            minRows={6}
            maxRows={6}
            rules={{
              maxLength: {
                value: MAX_DIAGNOSIS_LENGTH,
                message: i18n.t("AddUpdateScreen.diagnosisLength", {
                  length: MAX_DIAGNOSIS_LENGTH
                })
              }
            }}
          />
          {!!errors.diagnosis && (
            <FormFeedback>{errors.diagnosis.message}</FormFeedback>
          )}
        </FormGroup>
      </ModalBody>
      <ModalFooter className="border-0 px-5 pb-5 pt-0">
        <LoadingButton
          block
          size="lg"
          color="primary"
          spinnerColor="light"
          loading={isProcessing}
          disabled={disableAddUpdateButton}
          onClick={handleSubmit(save)}>
          {capitalize(i18n.t("AddUpdateScreen.submit"))}
        </LoadingButton>
      </ModalFooter>
    </Modal>
  );
};

CaseDetailUpdateModal.propTypes = {
  caseId: PropTypes.string.isRequired,
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func,
  children: PropTypes.node
};

CaseDetailUpdateModal.defaultProps = {};

export default CaseDetailUpdateModal;
