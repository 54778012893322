import React from "react";
import PropTypes from "prop-types";
import { ListGroupItem, ListGroupItemHeading } from "reactstrap";
import { useTranslation } from "react-i18next";
import { SUGGESTION_SECTIONS } from "../../../constants/suggestion-section-constants";
import F1SectionList from "../../../components/common/F1SectionList";

const SearchSuggestion = ({ text, onPress }) => {
  return (
    <ListGroupItem action tag="button" onClick={onPress}>
      {text}
    </ListGroupItem>
  );
};

const FeedSearchOptionItem = ({ name, onPress, searchTerms }) => {
  const { t } = useTranslation();
  return (
    <ListGroupItem
      action
      tag="button"
      className="list-group-item--bordered mb-0 py-2"
      onClick={onPress}>
      {t("searchFeed.optionLabel1")} <b>{name}</b>{" "}
      {t("searchFeed.optionLabel2").replace(
        "REPLACE_WITH_SEARCH_TERMS",
        searchTerms
      )}
    </ListGroupItem>
  );
};

const FeedSearchFeedList = ({ feedOptions, onPress }) => {
  const { t } = useTranslation();
  const renderFeedOption = (f) => {
    const selectFeed = () => onPress(f.uuid);
    return (
      <ListGroupItem key={f.uuid} action tag="button" onClick={selectFeed}>
        {f.name}
      </ListGroupItem>
    );
  };
  return (
    <>
      <ListGroupItem
        key={`channel-heading`}
        className="list-group-item--bordered">
        <ListGroupItemHeading>
          {t("searchFeed.feedGroupLabel")}
        </ListGroupItemHeading>
      </ListGroupItem>
      {feedOptions.map(renderFeedOption)}
    </>
  );
};

const SearchSuggestionContainer = ({ data }) => {
  const { t } = useTranslation();

  return (
    <F1SectionList
      className="feed-search-suggestion-container"
      sections={data}
      renderItem={({ item, section, index }) => {
        if (section.title === SUGGESTION_SECTIONS.SEARCH_TERM) {
          return (
            <SearchSuggestion
              key={`search-suggestion-${item.text}-${index}`}
              text={item.text}
              onPress={item.onPress}
            />
          );
        } else if (section.title === SUGGESTION_SECTIONS.FIXED_CHANNELS) {
          return (
            <FeedSearchOptionItem
              key={`fixed-channel-suggestion-${
                item?.channel?.feed_type_uuid || "search-option-" + index
              }`}
              name={t("searchFeed.figure1")}
              searchTerms={item.searchTerms}
              onPress={item.onPress}
            />
          );
        } else if (section.title === SUGGESTION_SECTIONS.MATCHING_CHANNELS) {
          return (
            <FeedSearchFeedList
              key={index}
              feedOptions={item.feedOptions}
              onPress={item.onPress}
            />
          );
        }
      }}
    />
  );
};

SearchSuggestionContainer.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      data: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string,
          onPress: PropTypes.func,
          channel: PropTypes.shape({
            feed_type_uuid: PropTypes.string
          }),
          feed_type_uuid: PropTypes.string,
          searchTerms: PropTypes.string,
          feedOptions: PropTypes.arrayOf(
            PropTypes.shape({
              name: PropTypes.string,
              uuid: PropTypes.string
            })
          )
        })
      )
    })
  ).isRequired,
  everythingFeed: PropTypes.shape({
    feed_type_uuid: PropTypes.string
  }).isRequired
};

export default SearchSuggestionContainer;
