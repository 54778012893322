/** @format */

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Card, CardBody, CardText, Col, Row } from "reactstrap";
import { ContentProp } from "../../../prop-types/ContentProp";
import { useCaseBanner } from "../../../hooks/use-case-banner.hooks";
import i18n from "../../../utils/i18n";
import CaseBanner from "../../../components/case/CaseBanner";
import CaseTitle from "../../../components/case/CaseTitle";
import CaseUserCell from "../../../components/case/CaseUserCell";
import ShareCaseDialog from "../../../components/case/ShareCaseDialog";
import ShareButton from "../../../components/case/ShareButton";
import {
  SHARE_CONTEXT,
  SHARE_LOCATIONS
} from "../../../metrics/constants.metrics";

const CMCoverSlideRow = ({ content, caseMeta, onStartActivity }) => {
  /****************************** Config **************************************/
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const { showBanner, bannerType } = useCaseBanner();

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <>
      {showBanner && (
        <Row>
          <Col>
            <CaseBanner bannerType={bannerType} />
          </Col>
        </Row>
      )}
      <Row className="no-gutters mb-4">
        <Col className="bg-dark-gray" lg={6}>
          <Card className="bg-transparent text-white border-0 h-100">
            <CardBody>
              <CaseTitle
                title={content.title ? content.title : content.caption}
                publishedAt={content.publishedAt}
                isPagingCase={content.isPagingCase}
                size={28}
                maxLine={3}
              />
            </CardBody>
            <CardBody className="pt-0 flex-grow-0 d-inline-flex justify-content-start align-items-center">
              {content.feedCardLabel && (
                <CardText className="border border-width-1 border-white rounded py-1 px-2 text-white text-13 mb-0 flex-shrink-0 mr-3">
                  {content.feedCardLabel}
                </CardText>
              )}
              <div className="d-inline-flex justify-content-between align-items-center w-100 flex-grow-0">
                <CaseUserCell
                  caseContent={caseMeta}
                  tagline={i18n.t("CMEScreens.hostBy")}
                  inline
                />
              </div>
            </CardBody>
          </Card>
        </Col>

        <Col className="bg-dark-gray" lg={1} />

        <Col className="bg-dark-gray" lg={5}>
          <Card className="bg-transparent text-white border-0 h-100 justify-content-between">
            <CardBody className="d-inline-flex justify-content-end align-items-center w-100 flex-grow-0">
              <ShareButton
                className=""
                buttonColor="charcoal-gray"
                iconColor="white"
                onClick={() => setIsShareDialogOpen(true)}
              />
            </CardBody>
            <CardBody className="pt-0 flex-grow-0">
              <Button
                className="py-3"
                color="aqua"
                size="lg"
                block
                onClick={onStartActivity}>
                {content?.buttonText}
              </Button>
            </CardBody>
          </Card>
        </Col>
      </Row>

      <ShareCaseDialog
        isOpen={isShareDialogOpen}
        caseContent={caseMeta}
        location={SHARE_LOCATIONS.TOP_BAR}
        context={SHARE_CONTEXT.DETAILED_VIEW}
        onClosed={() => setIsShareDialogOpen(false)}
      />
    </>
  );
};

CMCoverSlideRow.propTypes = {
  content: ContentProp,
  caseMeta: PropTypes.object
};

export default CMCoverSlideRow;
