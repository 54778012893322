import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toggleLoginModal } from "../../actions/global.actions";
import useInitialRouteRedirect from "../../hooks/useInitialRouteRedirect";
import BecomeMemberCTA from "../../pages/authentication/components/BecomeMemberCTA";
import LoginForm from "../../pages/authentication/components/LoginForm";
import i18n from "../../utils/i18n";
import BaseDialog from "../dialog/BaseDialog";
import BaseDialogBody from "../dialog/BaseDialogBody";
import BaseDialogFooter from "../dialog/BaseDialogFooter";
import BaseDialogHeader from "../dialog/BaseDialogHeader";

const LoginModal = () => {
  /************************************ CONFIG ***************************************/

  const dispatch = useDispatch();
  const isOpen = useSelector((state) => state?.global?.loginModalVisible);

  /************************************ HOOKS ****************************************/

  useInitialRouteRedirect();

  /*********************************** FUNCTIONS *************************************/

  const onCancel = () => {
    dispatch(toggleLoginModal(false));
  };

  /************************************ RENDER ***************************************/

  return (
    <BaseDialog isOpen={isOpen} centered>
      <BaseDialogHeader center onCancel={onCancel} showCloseButton>
        <h3 className="helv-bold text-18">
          {i18n.t("RegistrationScreens.loginForm.modalTitle")}
        </h3>
        <hr className="border-lighter-gray border-width-3" />
      </BaseDialogHeader>

      <BaseDialogBody>
        <LoginForm
          inModal={true}
          className="w-100"
          onSignIn={onCancel}
          preventRedirect={true}
        />
        <hr className="w-100 border-lighter-gray mb-0 border-width-3" />
      </BaseDialogBody>

      <BaseDialogFooter>
        <BecomeMemberCTA inModal={true} />
      </BaseDialogFooter>
    </BaseDialog>
  );
};

export default LoginModal;
