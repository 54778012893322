import React, { useEffect } from "react";
import PropTypes from "prop-types";
import PreviewStructure from "./PreviewStructure";
import CategoryStructure from "./CategoryStructure";
import ExploreCategoryCTA from "./ExploreCategoryCTA";
import i18n from "../../utils/i18n";
import AutoSizer from "../common/AutoSizer";
import useIsRecommendedFeed from "../../hooks/useIsRecommendedFeed";
import { useDispatch } from "react-redux";
import { getPreviewFeedMetaIfNeeded } from "../../actions/reference.actions";
import { FEED_META_LABELS } from "../../constants/feed-type";
import GroupsNavigation from "./GroupsNavigation";
import useAppPrompt from "../app-prompt/useAppPrompt";
import CreateGroupCard from "./CreateGroupCard";
import DiscoverGroupsCard from "./DiscoverGroupsCard";
import { isMobile } from "react-device-detect";
import DetailPageQuizIcon from "../../assets/images/detail_page_quiz.svg";

const ChannelNavigation = ({ activeFeed, isGroupFeed }) => {
  /****************************** Config **************************************/
  const isRecommended = useIsRecommendedFeed();
  const dispatch = useDispatch();
  const { isShown: isAppPromptShown, height: appPromptHeight } = useAppPrompt();
  const showPublicGroups = isGroupFeed && !isMobile;

  /****************************** Hooks ***************************************/

  useEffect(() => {
    dispatch(getPreviewFeedMetaIfNeeded());
  }, [dispatch]);

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <AutoSizer
      className={`feed-sidebar-left z-1 mt-0 sticky-top position-lg-fixed overflow-y-lg-auto b-lg-3 px-2`}
      style={isAppPromptShown && { marginBottom: appPromptHeight }}>
      {
        <div>
          {isGroupFeed && (
            <>
              <GroupsNavigation />
              <CreateGroupCard />
            </>
          )}
          {showPublicGroups && <DiscoverGroupsCard />}
          {isRecommended && (
            <>
              <ExploreCategoryCTA
                feedLabel={FEED_META_LABELS.QUIZZES}
                title={i18n.t("ExploreCategoryCTA.quizzesTitle")}
                titleImg={DetailPageQuizIcon}
                description={i18n.t("ExploreCategoryCTA.quizzesDescription")}
                showNewBadge
              />
              <ExploreCategoryCTA
                feedLabel={FEED_META_LABELS.EVERYTHING}
                title={i18n.t("ExploreCategoryCTA.newCaseTitle")}
                description={i18n.t("ExploreCategoryCTA.newCaseDescription")}
              />
              <ExploreCategoryCTA
                feedLabel={FEED_META_LABELS.TRENDING}
                title={i18n.t("ExploreCategoryCTA.trendingCaseTitle")}
                description={i18n.t(
                  "ExploreCategoryCTA.trendingCaseDescription"
                )}
              />

              <CategoryStructure activeFeed={activeFeed} />
              <PreviewStructure activeFeed={activeFeed} />
            </>
          )}
        </div>
      }
    </AutoSizer>
  );
};

ChannelNavigation.propTypes = {
  activeFeed: PropTypes.string
};

export default ChannelNavigation;
