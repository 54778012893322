/** @format */

import _, { get, isEmpty, cloneDeep } from "lodash";
import * as usersProfileDB from "../db/user-profile.db";
import {
  deleteUserInfoEntryCall,
  followUserCall,
  syncUserProfileCall,
  syncUserProfileWithNameCall,
  unfollowUserCall,
  uploadUserAvatarCall
} from "../api/user-profile.cloud-functions";
import { PROFILE_INFO_TYPE } from "../constants/profile-info-constants";
import { updateUser, validateUsername } from "../api/user.cloud-functions";
import i18n from "../utils/i18n";
import { getDataURL } from "../utils/media-utils";
import { profileTypeFlags } from "../utils/user-profile-utils";
import { isRequestSuccess } from "../utils/general-utils";

const actionsPrefix = "userProfiles";

export const USER_PROFILE_LISTENER_ON = `${actionsPrefix}/USER_PROFILE_LISTENER_ON`;
export const USER_PROFILE_LISTENER_OFF = `${actionsPrefix}/USER_PROFILE_LISTENER_OFF`;
export const USER_PROFILE_UPDATED = `${actionsPrefix}/USER_PROFILE_UPDATED`;
export const FETCH_USER_CONTENT = `${actionsPrefix}/FETCH_USER_CONTENT`;
export const USER_CONTENT_UPDATED = `${actionsPrefix}/USER_CONTENT_UPDATED`;
export const UPDATE_USER_PROFILE = `${actionsPrefix}/UPDATE_USER_PROFILE`;
export const UPDATE_USER_PROFILE_COMPLETE = `${actionsPrefix}/UPDATE_USER_PROFILE_COMPLETE`;
export const DELETE_USER_INFO_ENTRY = `${actionsPrefix}/DELETE_USER_INFO_ENTRY`;
export const DELETE_USER_INFO_ENTRY_COMPLETE = `${actionsPrefix}/DELETE_USER_INFO_ENTRY_COMPLETE`;
export const CACHE_SPECIALTIES = `${actionsPrefix}/CACHE_SPECIALTIES`;
export const CLEAR_SPECIALTY_UPDATE_CACHE = `${actionsPrefix}/CLEAR_SPECIALTY_UPDATE_CACHE`;
export const SYNC_USER_PROFILE = `${actionsPrefix}/SYNC_USER_PROFILE`;
export const TOGGLE_USER_FOLLOW = `${actionsPrefix}/TOGGLE_USER_FOLLOW`;
export const TOGGLE_USER_FOLLOW_COMPLETE = `${actionsPrefix}/TOGGLE_USER_FOLLOW_COMPLETE`;
export const UPLOAD_AVATAR = `${actionsPrefix}/UPLOAD_AVATAR`;
export const UPLOAD_AVATAR_COMPLETE = `${actionsPrefix}/UPLOAD_AVATAR_COMPLETE`;
export const AVATAR_UPDATED = `${actionsPrefix}/AVATAR_UPDATED`;
export const FETCH_USER_FOLLOWERS = `${actionsPrefix}/FETCH_USER_FOLLOWERS`;
export const FETCH_USER_FOLLOWING = `${actionsPrefix}/FETCH_USER_FOLLOWING`;
export const USER_FOLLOWING_LISTENER_ON = `${actionsPrefix}/USER_FOLLOWING_LISTENER_ON`;
export const USER_FOLLOWING_LISTENER_OFF = `${actionsPrefix}/USER_FOLLOWING_LISTENER_OFF`;
export const USER_FOLLOWING_UPDATED = `${actionsPrefix}/USER_FOLLOWING_UPDATED`;
export const USER_FOLLOWERS_UPDATED = `${actionsPrefix}/USER_FOLLOWERS_UPDATED`;
export const USER_PROFILE_ACTIVE_TAB_UPDATED = `${actionsPrefix}/USER_PROFILE_ACTIVE_TAB_UPDATED`;

const mutateFetchedProfileData = (profile) => {
  return { ...cloneDeep(profile), ...profileTypeFlags(profile) };
};

export const fetchUserProfile = (userUuid) => {
  return async (dispatch) => {
    if (!userUuid) {
      return;
    }

    try {
      const profile = await usersProfileDB.fetchUserProfile(userUuid);
      dispatch({
        type: USER_PROFILE_UPDATED,
        userUuid: userUuid,
        profile: mutateFetchedProfileData(profile)
      });
    } catch (error) {
      console.log("failed to fetch profile for ", userUuid);
    }
  };
};

export const listenUserProfile = (userUuid, on) => {
  return async (dispatch, getState) => {
    if (!userUuid) {
      return;
    }

    const unsubscribe = get(
      getState().userProfiles,
      ["listeners", userUuid],
      null
    );

    if (on) {
      if (unsubscribe) {
        return;
      }

      const listener = usersProfileDB.listenUserProfileUpdate(
        userUuid,
        (doc) => {
          if (doc.exists) {
            const profile = mutateFetchedProfileData(doc.data());
            dispatch({
              type: USER_PROFILE_UPDATED,
              userUuid: userUuid,
              profile: profile
            });
          }
        }
      );
      return dispatch({
        type: USER_PROFILE_LISTENER_ON,
        userUuid: userUuid,
        listener: listener
      });
    } else {
      if (unsubscribe) {
        unsubscribe();
      }

      return dispatch({
        type: USER_PROFILE_LISTENER_OFF,
        userUuid: userUuid
      });
    }
  };
};

export const fetchUserCreatedContent = (userUuid, size, refreshing, type) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_USER_CONTENT,
      userUuid: userUuid,
      contentType: type
    });
    try {
      const data = get(
        getState().userProfiles,
        ["contents", userUuid, type, "data"],
        []
      );
      const lastTimestamp = isEmpty(data)
        ? null
        : data[data.length - 1].createdAt;

      const timestamp = refreshing ? new Date().toString() : lastTimestamp;
      const userContent = await usersProfileDB.fetchUserContent(
        userUuid,
        size,
        timestamp,
        type
      );
      dispatch({
        type: USER_CONTENT_UPDATED,
        userUuid: userUuid,
        content: userContent,
        refreshing: refreshing,
        isEndReached: userContent.length < size,
        contentType: type
      });
    } catch (error) {
      console.warn(error.message);
    }
  };
};

export const updateUserProfileBasicInfo = (payload) => {
  return async (dispatch) => {
    return await dispatch(updateUserProfile(payload, "basicProfileUpdated"));
  };
};

export const addOrUpdateInfoEntry = ({
  type,
  description,
  location,
  startYear,
  endYear,
  id
}) => {
  return async (dispatch) => {
    let data = {
      description: description,
      startYear: Number(startYear)
    };
    if (location) {
      data.location = location;
    }

    if (endYear && !isNaN(endYear)) {
      data.endYear = Number(endYear);
    }
    switch (type) {
      case PROFILE_INFO_TYPE.EXPERIENCE:
        if (id) {
          data.experienceUuid = id;
        }
        return await dispatch(
          updateUserProfile({ [type]: [data] }, "profileExperience")
        );
      case PROFILE_INFO_TYPE.EDUCATION:
        if (id) {
          data.educationUuid = id;
        }
        return await dispatch(
          updateUserProfile({ [type]: [data] }, "profileEducation")
        );
      case PROFILE_INFO_TYPE.AFFILIATIONS:
        if (id) {
          data.affiliationUuid = id;
        }
        return await dispatch(
          updateUserProfile({ [type]: [data] }, "profileAffiliations")
        );
      default:
        console.debug("Unknown profile info type: ", type);
        break;
    }
  };
};

export const createAccount = ({
  primarySpecialty,
  userCustomSpecialty,
  username,
  interests
}) => {
  return async (dispatch) => {
    if (username) {
      try {
        dispatch({
          type: UPDATE_USER_PROFILE
        });
        const response = await validateUsername(username);

        if (isRequestSuccess(response)) {
          return await dispatch(
            updateUserProfile(
              {
                username: username,
                ...(primarySpecialty && { primarySpecialty }),
                ...(userCustomSpecialty && { userCustomSpecialty }),
                interests: interests
              },
              "registrationUsername"
            )
          );
        } else {
          return dispatch({
            type: UPDATE_USER_PROFILE_COMPLETE,
            error: true,
            message: i18n.t(
              "RegistrationScreens.CreateAccountScreens.invalidUsername"
            )
          });
        }
      } catch (error) {
        return dispatch({
          type: UPDATE_USER_PROFILE_COMPLETE,
          error: true,
          message: i18n.t(
            "RegistrationScreens.CreateAccountScreens.invalidUsername"
          )
        });
      }
    } else {
      return dispatch({
        type: UPDATE_USER_PROFILE_COMPLETE,
        error: true,
        message: "Missing username"
      });
    }
  };
};

export const updateInterests = (interests) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_PROFILE
    });

    return await dispatch(
      updateUserProfile({ interests: interests }, "profileInterests")
    );
  };
};

export const updateSpecialties = (
  specialtyIDs,
  specialties,
  originalSpecialties,
  userUuid
) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_PROFILE,
      specialties: specialties,
      userUuid: userUuid
    });

    const result = await dispatch(
      updateUserProfile(
        { specialties: specialtyIDs },
        "registrationSpecialtySelections"
      )
    );
    // reset specialties if action failed
    if (result.error) {
      dispatch({
        type: UPDATE_USER_PROFILE,
        specialties: originalSpecialties,
        userUuid: userUuid
      });
    }
    return result;
  };
};

export const updateEmail = (email) => {
  return async (dispatch) => {
    return await dispatch(updateUserProfile({ email: email }));
  };
};

export const updateUserProfile = (payload, screenId) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_USER_PROFILE
    });
    try {
      const result = await updateUser(payload, screenId);

      if (isRequestSuccess(result)) {
        return dispatch({
          type: UPDATE_USER_PROFILE_COMPLETE,
          user: result.store
        });
      } else {
        return dispatch({
          type: UPDATE_USER_PROFILE_COMPLETE,
          error: true,
          message: "Failed to update user profile"
        });
      }
    } catch (error) {
      return dispatch({
        type: UPDATE_USER_PROFILE_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const deleteUserInfoEntry = (type, id) => {
  return async (dispatch) => {
    if (!id) {
      console.debug("no id passed in for deleteUserInfoEntry");
      return dispatch({
        type: DELETE_USER_INFO_ENTRY_COMPLETE,
        error: true,
        message: "no id passed in for deleteUserInfoEntry"
      });
    }
    dispatch({
      type: DELETE_USER_INFO_ENTRY
    });

    try {
      let result;
      switch (type) {
        case PROFILE_INFO_TYPE.EXPERIENCE:
          result = await deleteUserInfoEntryCall({
            [PROFILE_INFO_TYPE.EXPERIENCE]: [id]
          });
          break;
        case PROFILE_INFO_TYPE.EDUCATION:
          result = await deleteUserInfoEntryCall({
            [PROFILE_INFO_TYPE.EDUCATION]: [id]
          });
          break;
        case PROFILE_INFO_TYPE.AFFILIATIONS:
          result = await deleteUserInfoEntryCall({
            [PROFILE_INFO_TYPE.AFFILIATIONS]: [id]
          });
          break;
        default:
          result = {};
      }
      if (isRequestSuccess(result)) {
        return dispatch({
          type: DELETE_USER_INFO_ENTRY_COMPLETE
        });
      } else {
        return dispatch({
          type: DELETE_USER_INFO_ENTRY_COMPLETE,
          error: true,
          message: "Failed to delete profile entry"
        });
      }
    } catch (error) {
      return dispatch({
        type: DELETE_USER_INFO_ENTRY_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const addSpecialtiesToCache = (specialties) => {
  return {
    type: CACHE_SPECIALTIES,
    specialties: specialties
  };
};

export const changeSpecialty = (newSpecialty, oldSpecialty) => {
  return async (dispatch, getState) => {
    if (oldSpecialty) {
      if (oldSpecialty.treeUuid !== newSpecialty.treeUuid) {
        const cachedSpecialties = getState().userProfiles.updateCache;
        // remove old specialty and its subspecialties from cache
        const cleanedSpecialties = cachedSpecialties.filter(
          (s) => !s.path.startsWith(oldSpecialty.path)
        );
        return dispatch({
          type: CACHE_SPECIALTIES,
          specialties: [newSpecialty, ...cleanedSpecialties]
        });
      }
    } else {
      const cachedSpecialties = getState().userProfiles.updateCache;
      return dispatch({
        type: CACHE_SPECIALTIES,
        specialties: [newSpecialty, ...cachedSpecialties]
      });
    }
  };
};

export const deleteSpecialty = (specialty) => {
  return async (dispatch, getState) => {
    const cachedSpecialties = getState().userProfiles.updateCache;
    // remove old specialty and its subspecialties from cache
    const cleanedSpecialties = cachedSpecialties.filter(
      (s) => !s.path.startsWith(specialty.path)
    );
    return dispatch({
      type: CACHE_SPECIALTIES,
      specialties: cleanedSpecialties
    });
  };
};

export const changeSubspecialties = (specialty, newSubspecialties) => {
  return async (dispatch, getState) => {
    const cachedSpecialties = getState().userProfiles.updateCache;
    // remove original subspecialties from cache, but not the specialty itself
    const cleanedSpecialties = cachedSpecialties.filter(
      (s) =>
        !s.path.startsWith(specialty.path) || s.treeUuid === specialty.treeUuid
    );
    return dispatch({
      type: CACHE_SPECIALTIES,
      specialties: [...cleanedSpecialties, ...newSubspecialties]
    });
  };
};

export const clearSpecialtyUpdate = () => {
  return {
    type: CLEAR_SPECIALTY_UPDATE_CACHE
  };
};

export const syncUserProfileIfNeeded = (userUuid) => {
  return async (dispatch) => {
    try {
      // check if user profile doc exists
      const result = await usersProfileDB.fetchUserProfile(userUuid);
      if (result) {
        return false;
      }

      dispatch({
        type: SYNC_USER_PROFILE,
        uuid: userUuid
      });
      const syncResult = await syncUserProfileCall(userUuid);
      if (!isRequestSuccess(syncResult?.status)) {
        return dispatch({
          type: USER_PROFILE_UPDATED,
          userUuid: userUuid,
          profile: {}
        });
      }

      return true;
    } catch (error) {
      console.debug(error.message);
    }
  };
};

/**
 * 1. look up in userProfileDB with username
 *  hit -> update profile data and return uuid
 *  miss -> proceed to next step
 * 2. Request profile sync with username, expect a uuid in response
 *  found -> return uuid
 *  not found -> return null
 *
 * param: username
 * return: uuid or null
 * */
export const syncUserProfileWithUsernameIfNeeded = (username) => {
  return async (dispatch) => {
    try {
      const profiles = await usersProfileDB.fetchUserProfileByName(username);

      if (profiles && profiles.length > 0) {
        // There should be only one user per username at most
        const profile = profiles[0];
        dispatch({
          type: USER_PROFILE_UPDATED,
          userUuid: profile.userUuid,
          profile: profile
        });
        return profile.userUuid;
      }

      const result = await syncUserProfileWithNameCall(username);
      return result.store?.userUuid;
    } catch (error) {
      console.debug(error.message);
    }
  };
};

export const toggleUserFollow = (userUuid, on) => {
  return async (dispatch, getState) => {
    dispatch({
      userUuid: userUuid,
      type: TOGGLE_USER_FOLLOW,
      on: on
    });
    try {
      const currentUserUuid = getState().user.userUuid;
      const result = on
        ? await followUserCall(userUuid)
        : await unfollowUserCall(userUuid);
      if (isRequestSuccess(result)) {
        let modified = [
          ...(getState().userProfiles.following[currentUserUuid]?.data || {})
        ];
        if (on) {
          if (!modified.find((e) => e.userUuid === userUuid)) {
            modified = [...modified, { userUuid: userUuid }];
          }
        } else {
          modified = modified.filter((u) => u.userUuid !== userUuid);
        }
        dispatch({
          type: USER_FOLLOWING_UPDATED,
          payload: {
            following: modified,
            refreshing: true,
            userUuid: currentUserUuid
          }
        });

        return dispatch({
          type: TOGGLE_USER_FOLLOW_COMPLETE,
          on: on,
          userUuid: userUuid
        });
      } else {
        return dispatch({
          type: TOGGLE_USER_FOLLOW_COMPLETE,
          error: true,
          message: "Failed to update user follow state",
          userUuid: userUuid
        });
      }
    } catch (error) {
      return dispatch({
        type: TOGGLE_USER_FOLLOW_COMPLETE,
        error: true,
        message: error.message,
        userUuid: userUuid
      });
    }
  };
};

/**
 * @param {File} file
 */
export const uploadAvatarImage = (file) => {
  return async (dispatch, getState) => {
    const userUuid = getState().user.userUuid;
    const originalAvatar = get(
      getState().userProfiles.profiles,
      [userUuid, "profile", "avatar"],
      ""
    );

    try {
      // update avatar promptly before change pushed to firestore.
      dispatch({
        type: AVATAR_UPDATED,
        avatar: await getDataURL(file),
        userUuid: userUuid
      });

      dispatch({
        type: UPLOAD_AVATAR
      });

      const data = new FormData();
      data.append("picture", file, file.name);
      const result = await uploadUserAvatarCall(data);
      if (isRequestSuccess(result)) {
        return dispatch({
          type: UPLOAD_AVATAR_COMPLETE
        });
      } else {
        // revert back avatar if request failed
        dispatch({
          type: AVATAR_UPDATED,
          avatar: originalAvatar,
          userUuid: userUuid
        });
        return dispatch({
          type: UPLOAD_AVATAR_COMPLETE,
          error: true,
          message: "Failed to upload avatar image"
        });
      }
    } catch (error) {
      // revert back avatar if request failed
      dispatch({
        type: AVATAR_UPDATED,
        avatar: originalAvatar,
        userUuid: userUuid
      });

      console.debug(error.message);
      return dispatch({
        type: UPLOAD_AVATAR_COMPLETE,
        error: true,
        message: error.message
      });
    }
  };
};

export const setOnboardingCompleteIfNeeded = () => {
  return (dispatch, getState) => {
    const state = getState();
    const user = state?.user;

    if (!user.onboardingCompleted) {
      dispatch(updateUserProfile({ onboardingCompleted: true }));
    }
  };
};

export const setActiveProfileTab = (
  userUuid,
  tab = usersProfileDB.USER_CONTENT_TYPES.CASE
) => {
  return (dispatch) => {
    dispatch({
      type: USER_PROFILE_ACTIVE_TAB_UPDATED,
      userUuid,
      tab
    });
  };
};

const getLastUsername = (getState, path, userUuid) => {
  const data = _.get(getState().userProfiles, [path, userUuid, "data"], []);
  return _.isEmpty(data) ? null : data[data.length - 1].username;
};

export const fetchUserFollowers = (userUuid, size, refreshing) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_USER_FOLLOWERS,
      userUuid: userUuid
    });
    try {
      const username = refreshing
        ? null
        : getLastUsername(getState, "followers", userUuid);
      const followers = await usersProfileDB.fetchUserFollower(
        userUuid,
        size,
        username
      );

      return dispatch({
        type: USER_FOLLOWERS_UPDATED,
        payload: {
          userUuid: userUuid,
          followers: followers,
          refreshing: refreshing
        }
      });
    } catch (error) {
      console.warn(error.message);
    }
  };
};

export const fetchUserFollowing = (userUuid, size, refreshing) => {
  return async (dispatch, getState) => {
    dispatch({
      type: FETCH_USER_FOLLOWING,
      userUuid: userUuid
    });
    try {
      const start = (getState().userProfiles?.following?.[userUuid].data || [])
        .length;
      const username = refreshing
        ? null
        : getLastUsername(getState, "following", userUuid);
      const following = await usersProfileDB.fetchUserFollowing(
        userUuid,
        size,
        username,
        start
      );
      return dispatch({
        type: USER_FOLLOWING_UPDATED,
        payload: {
          userUuid: userUuid,
          following: following,
          refreshing: refreshing
        }
      });
    } catch (error) {
      console.warn(error.message);
    }
  };
};

export const toggleUserFollowingListener = (userUuid) => {
  return async (dispatch, getState) => {
    if (userUuid) {
      if (getState().user && !getState().user.followingListener) {
        const listener = usersProfileDB.listenUserFollowingChange((doc) => {
          if (doc && doc.size > 0) {
            let following = [];
            for (const d of doc.docs) {
              following.push({ ...d.data(), userUuid: d.id });
            }
            dispatch({
              type: USER_FOLLOWING_UPDATED,
              payload: {
                following: following,
                userUuid: userUuid,
                refreshing: true
              }
            });
          }
        }, userUuid);
        return dispatch({
          type: USER_FOLLOWING_LISTENER_ON,
          followingListener: listener
        });
      }
    } else {
      if (getState().user.followingListener) {
        await getState().user.followingListener();
      }
      return {
        type: USER_FOLLOWING_LISTENER_OFF
      };
    }
  };
};

export const disableUserProfilesListeners = () => {
  return async (dispatch, getState) => {
    for (const listener of Object.values(getState().userProfiles?.listeners)) {
      if (typeof listener === "function") await listener();
    }
  };
};
