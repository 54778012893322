import React, { useEffect, useState, useCallback } from "react";
import { isMobile } from "react-device-detect";
import { Card } from "reactstrap";
import PropTypes from "prop-types";
import CommentContainer from "./CommentContainer";
import CommentThread from "./CommentThread";
import CommentTabs from "./CommentTabs";
import SocialContainer from "../case/SocialContainer";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";
import { isPhysician } from "../../utils/specialty-utils";
import { useSelector } from "react-redux";
import useIsGated from "../../hooks/useIsGated";

const CaseDetailComments = ({
  caseContent,
  currentItem,
  commentStatus,
  hideReactions,
  hideComments,
  hideCommentsSwitch,
  position,
  userReactions = [],
  caseLinkUrl,
  forceAllComments = false,
  commentId,
  isMedicalCase = true
}) => {
  /** ********************************** CONFIG ***************************************/

  const { theme } = useTheme();
  const [selectedTab, setSelectedTab] = useState(null);
  const [sortBy, setSortBy] = useState("new");
  const user = useSelector((state) => state.user);
  const isGated = useIsGated();

  const physicianComments = commentStatus?.physicianComments || null;

  const showTabs = !!(physicianComments || []).filter((c) => {
    return c.isPhysician && !c.isDeleted && !c.isReported;
  }).length;
  const commentQueueEnabled = caseContent?.features?.commentQueueEnabled;
  const isGroupCase = !!caseContent?.groupUuid;

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    // physicianComments starts life as null, so this check
    // is what we want to do. An empty array is a valid state
    if (physicianComments) {
      if (forceAllComments || hideCommentsSwitch) {
        setSelectedTab("all");
      } else {
        const filter = isPhysician(user) && showTabs ? "physician" : "all";
        setSelectedTab(filter);
      }
    }
  }, [physicianComments, showTabs, forceAllComments, user, hideCommentsSwitch]);
  /** ********************************* FUNCTIONS *************************************/

  const onChangeSort = useCallback((sortValue) => {
    setSortBy(sortValue);
  }, []);

  /** ********************************** RENDER ***************************************/

  const tabs =
    !hideComments &&
    selectedTab &&
    !hideCommentsSwitch &&
    showTabs &&
    !forceAllComments ? (
      <CommentTabs
        selectedTab={selectedTab}
        onChangeTab={(tab) => setSelectedTab(tab)}
        commentDetails={commentStatus}
      />
    ) : null;

  return (
    <>
      {caseContent && (!hideComments || !hideReactions) && (
        <Card
          className={classNames(
            "rounded-0",
            theme === THEMES.DARK ? "border-0" : null
          )}
          color={theme === THEMES.DARK ? "dark-gray" : "white"}
          inverse={theme === THEMES.DARK}>
          <div
            className={classNames(
              isMobile ? "" : "sticky-top",
              "t-5 t-lg-0",
              theme === THEMES.DARK ? "bg-dark-gray" : "bg-white"
            )}>
            <div
              className={classNames(
                "border-bottom border-width-3 py-2",
                theme === THEMES.DARK
                  ? "border-charcoal-gray px-3"
                  : "border-lighter-gray px-3"
              )}>
              <SocialContainer
                caseContent={caseContent}
                hideComments={hideComments}
                hideReactions={hideReactions}
                userReactions={userReactions || []}
                position={position}
                socialContext={CASE_CARD_ORIGIN.DETAIL}
                caseLinkUrl={caseLinkUrl}
                hideSave={false}
                activateListener={false}
                iconSize={24}
              />
            </div>
            {tabs}
            {!hideComments && (
              <CommentContainer
                caseUuid={caseContent.caseUuid}
                contentUuid={currentItem.contentUuid}
                onChangeSort={onChangeSort}
                caseContent={caseContent}
                commentStatus={commentStatus}
                position={position}
                isGroup={isGroupCase}
                isMedicalCase={isMedicalCase}
              />
            )}
          </div>

          {!hideComments && !isGated && (
            <CommentThread
              caseUuid={caseContent.caseUuid}
              contentUuid={currentItem.contentUuid}
              reportEnabled={!commentQueueEnabled}
              commentStatus={commentStatus}
              sortBy={sortBy}
              filter={selectedTab}
              position={position}
              caseContent={caseContent}
              isGroup={isGroupCase}
              setSelectedTab={setSelectedTab}
              commentId={commentId}
              isMedicalCase={isMedicalCase}
            />
          )}
        </Card>
      )}
    </>
  );
};

CaseDetailComments.propTypes = {
  caseContent: PropTypes.object,
  currentItem: PropTypes.object,
  commentStatus: PropTypes.shape({
    physicianCount: PropTypes.number,
    allCount: PropTypes.number,
    originalTree: PropTypes.arrayOf(PropTypes.object)
  }),
  hideComments: PropTypes.bool,
  hideCommentsSwitch: PropTypes.bool,
  hideReactions: PropTypes.bool
};

export default CaseDetailComments;
