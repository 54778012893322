import React from "react";
import { Badge } from "reactstrap";
import PropTypes from "prop-types";
import classnames from "../../utils/class-names-utils";

const F1Badge = (props) => {
  const {
    pill = true,
    px = 2,
    color,
    textColor,
    name,
    className,
    iconImg,
    iconImgClassName,
    ...rest
  } = props;
  return (
    <Badge
      {...rest}
      pill={pill}
      className={classnames(
        "f1-badge",
        textColor ? `text-${textColor}` : undefined,
        `px-${px}`,
        className
      )}
      color={color}>
      {iconImg && <img className={iconImgClassName} src={iconImg} alt="" />}
      {name}
    </Badge>
  );
};

F1Badge.propTypes = {
  /**
   * The content within the badge
   */
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * Optional set a new text color. If not set, the text color will based off of the provided color prop.
   */
  textColor: PropTypes.string
};

export default F1Badge;
