import React from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import { trackShareCase } from "../../actions/metrics.actions";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { CREATE_NEW_CASE_POST_OPTIONS } from "../../constants/routes";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import i18n from "../../utils/i18n";

const GroupEmptyFeedCard = ({ currentGroup }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card
        className={`border-outline-gray rounded-0 ${
          isMobile ? "px-3 py-2" : "px-5 py-4"
        } mb-4`}>
        <CardBody className="text-center">
          <div className="mb-4">
            <h3 className="text-16 helv-bold m-0 line-height-21">
              {i18n.t("GroupsFeed.EmptyFeedCard.title1")}
            </h3>
            <h3 className="text-16 helv-bold m-0 line-height-21">
              {i18n.t("GroupsFeed.EmptyFeedCard.title2", {
                groupname: currentGroup?.groupName
              })}
            </h3>
          </div>
          <p className="text-15 mb-4 line-height-21">
            {i18n.t("GroupsFeed.EmptyFeedCard.description")}
          </p>
          <Button
            className="w-75 text-16 py-3"
            size="lg"
            color="primary"
            tag={Link}
            to={CREATE_NEW_CASE_POST_OPTIONS}
            onClick={() => {
              trackShareCase({
                context: UPLOAD_CASE_ORIGIN.GROUP_FEED,
                groupUuid: currentGroup?.groupUuid
              });
            }}>
            <CustomIcon
              className="m-0 mr-2 mb-1 icon-white"
              icon={"plus"}
              size={16}
            />
            {i18n.t("navigation.submit-case")}
          </Button>
        </CardBody>
      </Card>
    </>
  );
};

export default GroupEmptyFeedCard;
