/** @format */

import _ from "lodash";
import { Actions } from "@figure1/f1-pro-fe-brain";
import {
  saveCase as saveCaseCall,
  reportCase as reportCaseCall,
  submitCaseReaction as submitCaseReactionCall,
  triggerCaseSyncCall,
  submitCaseUpdateCall,
  updateCaseProgressCall
} from "../api/case.cloud-functions";
import { isRequestSuccess } from "../utils/general-utils";
import * as caseDB from "../db/case.db";
import Firebase from "../firebase";
import DB_CONSTANTS from "../db/db-constants";

const actionsPrefix = "case-list";

export const FETCH_DETAILS_START = `${actionsPrefix}/FETCH_DETAILS_START`;
export const FETCH_DETAILS_COMPLETE = `${actionsPrefix}/FETCH_DETAILS_COMPLETE`;
export const SAVE_CASE = `${actionsPrefix}/SAVE_CASE`;
export const SAVE_CASE_COMPLETE = `${actionsPrefix}/SAVE_CASE_COMPLETE`;
export const REPORT_CASE = `${actionsPrefix}/REPORT_CASE`;
export const REPORT_CASE_COMPLETE = `${actionsPrefix}/REPORT_CASE_COMPLETE`;
export const SUBMIT_CASE_REACTION = `${actionsPrefix}/SUBMIT_CASE_REACTION`;
export const SUBMIT_CASE_REACTION_COMPLETE = `${actionsPrefix}/SUBMIT_CASE_REACTION_COMPLETE`;
export const DISPLAY_REACTION_START = `${actionsPrefix}/DISPLAY_REACTION_START`;
export const DISPLAY_REACTION_END = `${actionsPrefix}/DISPLAY_REACTION_END`;
export const DETAILS_LISTENER_UPDATE = `${actionsPrefix}/DETAILS_LISTENER_UPDATE`;
export const DETAILS_UPDATED = `${actionsPrefix}/DETAILS_UPDATED`;
export const DETAILS_RESET = `${actionsPrefix}/DETAILS_RESET`;
export const SYNC_CASE = `${actionsPrefix}/SYNC_CASE`;
export const SYNC_CASE_COMPLETE = `${actionsPrefix}/SYNC_CASE_COMPLETE`;
export const USER_ACTIONS_UPDATED = `${actionsPrefix}/USER_ACTIONS_UPDATED`;
export const RELATED_CASES_UPDATED = `${actionsPrefix}/RELATED_CASES_UPDATED`;
export const USER_ACTIONS_LISTENER_UPDATED = `${actionsPrefix}/USER_ACTIONS_LISTENER_UPDATED`;
export const RELATED_CASES_LISTENER_UPDATE = `${actionsPrefix}/RELATED_CASES_LISTENER_UPDATE`;
export const SUBMIT_CASE_UPDATE = `${actionsPrefix}/SUBMIT_CASE_UPDATE`;
export const SUBMIT_CASE_UPDATE_COMPLETE = `${actionsPrefix}/SUBMIT_CASE_UPDATE_COMPLETE`;
export const UPDATE_CASE_PROGRESS = `${actionsPrefix}/UPDATE_CASE_PROGRESS`;
export const UPDATE_CASE_PROGRESS_COMPLETE = `${actionsPrefix}/UPDATE_CASE_PROGRESS_COMPLETE`;
export const CASE_PUBLICATIONS_UPDATED = `${actionsPrefix}/CASE_PUBLICATIONS_UPDATED`;
export const CASE_PUBLICATIONS_LISTENER_UPDATE = `${actionsPrefix}/CASE_PUBLICATIONS_LISTENER_UPDATE`;

export const { viewTranslatedCase, viewOriginalCaseTranslation } =
  Actions(Firebase).CaseActions;

export const reportCase = (caseUuid, reason) => {
  return async (dispatch) => {
    dispatch({
      type: REPORT_CASE,
      caseUuid: caseUuid
    });
    try {
      const response = await reportCaseCall(caseUuid, reason);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: REPORT_CASE_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: REPORT_CASE_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "Failed to report case due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: REPORT_CASE_COMPLETE,
        caseUuid: caseUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const saveCase = (caseUuid, isSave) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SAVE_CASE,
      caseUuid: caseUuid
    });
    try {
      const response = await saveCaseCall(caseUuid, isSave);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: SAVE_CASE_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: SAVE_CASE_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "Failed to save case due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: SAVE_CASE_COMPLETE,
        caseUuid: caseUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const submitCaseReaction = (caseUuid, reaction, isOn) => {
  return async (dispatch) => {
    dispatch(updateReaction(caseUuid, reaction, isOn));
    dispatch({
      type: SUBMIT_CASE_REACTION,
      caseUuid: caseUuid,
      reaction: reaction,
      isOn: isOn
    });
    try {
      const response = await submitCaseReactionCall(caseUuid, reaction, isOn);
      console.log("response", response);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: SUBMIT_CASE_REACTION_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: SUBMIT_CASE_REACTION_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "Failed to submit case reaction due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: SUBMIT_CASE_REACTION_COMPLETE,
        caseUuid: caseUuid,
        error: true,
        message: error.message
      });
    }
  };
};

const updateReaction = (caseUuid, reaction, isOn) => {
  return async (dispatch, getState) => {
    const detailCase = _.get(
      getState().case,
      ["cases", caseUuid, "data"],
      null
    );

    const delta = (selectedReaction) => {
      if (selectedReaction === null && isOn) {
        return 1;
      } else if (selectedReaction && !isOn) {
        return -1;
      }
      return 0;
    };

    // update detail data if presented
    if (detailCase) {
      const selectedReaction = _.get(
        getState().case.cases,
        [caseUuid, "userReactions", "0"],
        null
      );

      if (!detailCase.allReactions) {
        detailCase.allReactions = {};
      }
      detailCase.allReactions[reaction] =
        (detailCase.allReactions[reaction]
          ? detailCase.allReactions[reaction]
          : 0) + delta(selectedReaction);
      dispatch({
        type: DETAILS_UPDATED,
        caseUuid: caseUuid,
        data: detailCase
      });
    }
  };
};

export const displayReactionStart = (caseUuid) => {
  return {
    type: DISPLAY_REACTION_START,
    caseUuid: caseUuid
  };
};

export const displayReactionEnd = (caseUuid) => {
  return {
    type: DISPLAY_REACTION_END,
    caseUuid: caseUuid
  };
};

export const toggleCaseDetailsListener = (caseUuid, on) => {
  return async (dispatch, getState) => {
    const detailListener = _.get(
      getState().case.cases,
      [caseUuid, "listeners", "detailListener"],
      null
    );
    if (on) {
      if (!detailListener) {
        try {
          const listener = await caseDB.listenForDetailsUpdate(
            caseUuid,
            (doc) => {
              if (doc && doc.exists) {
                const data = doc.data();
                dispatch({
                  type: DETAILS_UPDATED,
                  caseUuid: caseUuid,
                  data: data
                });
              }
            }
          );
          return dispatch({
            type: DETAILS_LISTENER_UPDATE,
            caseUuid: caseUuid,
            detailListener: listener
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    } else {
      if (detailListener) {
        detailListener();
      }
      return dispatch({
        type: DETAILS_LISTENER_UPDATE,
        caseUuid: caseUuid,
        detailListener: null
      });
    }
  };
};

export const toggleCaseUserActionsListener = (caseUuid, on) => {
  return async (dispatch, getState) => {
    const actionListener = _.get(
      getState().case.cases,
      [caseUuid, "listeners", "userActionsListener"],
      null
    );
    if (on) {
      if (!actionListener) {
        try {
          const listener = await caseDB.listenForUserActionsUpdate(
            caseUuid,
            getState().user.userUuid,
            (doc) => {
              if (doc && doc.exists) {
                const data = doc.data();
                dispatch({
                  type: USER_ACTIONS_UPDATED,
                  caseUuid: caseUuid,
                  actions: data
                });
              }
            }
          );
          return dispatch({
            type: USER_ACTIONS_LISTENER_UPDATED,
            caseUuid: caseUuid,
            userActionsListener: listener
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    } else {
      if (actionListener) {
        actionListener();
      }
      return dispatch({
        type: USER_ACTIONS_LISTENER_UPDATED,
        caseUuid: caseUuid,
        userActionsListener: null
      });
    }
  };
};

export const toggleRelatedCasesListener = (caseUuid, on) => {
  return async (dispatch, getState) => {
    const relatedCasesListener = _.get(
      getState().case.cases,
      [caseUuid, "listeners", "relatedCasesListener"],
      null
    );
    if (on) {
      if (!relatedCasesListener) {
        try {
          const listener = await caseDB.listenForRelatedCases(
            caseUuid,
            (doc) => {
              let relatedCases = [];
              if (doc && doc.size > 0) {
                for (let r of doc.docs) {
                  relatedCases.push(r.data());
                }
              }
              dispatch({
                type: RELATED_CASES_UPDATED,
                caseUuid: caseUuid,
                relatedCases: relatedCases
              });
            }
          );
          return dispatch({
            type: RELATED_CASES_LISTENER_UPDATE,
            caseUuid: caseUuid,
            relatedCasesListener: listener
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    } else {
      if (relatedCasesListener) {
        relatedCasesListener();
      }
      return dispatch({
        type: RELATED_CASES_LISTENER_UPDATE,
        caseUuid: caseUuid,
        relatedCasesListener: null
      });
    }
  };
};

export const toggleCasePublicationsListener = (caseUuid, on) => {
  return async (dispatch, getState) => {
    const casePublicationsListener = _.get(
      getState().case.cases,
      [caseUuid, "listeners", "casePublicationsListener"],
      null
    );
    if (on) {
      if (!casePublicationsListener) {
        try {
          const listener = await caseDB.listenForCasePublications(
            caseUuid,
            (doc) => {
              if (doc && doc.size > 0) {
                let casePublications = [];
                for (let r of doc.docs) {
                  casePublications.push(r.data());
                }
                dispatch({
                  type: CASE_PUBLICATIONS_UPDATED,
                  caseUuid: caseUuid,
                  casePublications: casePublications
                });
              }
            }
          );
          return dispatch({
            type: CASE_PUBLICATIONS_LISTENER_UPDATE,
            caseUuid: caseUuid,
            casePublicationsListener: listener
          });
        } catch (error) {
          console.log(error.message);
        }
      }
    } else {
      if (casePublicationsListener) {
        casePublicationsListener();
      }
      return dispatch({
        type: CASE_PUBLICATIONS_LISTENER_UPDATE,
        caseUuid: caseUuid,
        casePublicationsListener: null
      });
    }
  };
};

export const resetCaseDetails = (caseUuid) => {
  return {
    type: DETAILS_RESET,
    caseUuid: caseUuid
  };
};

export const requestCaseSyncIfNeeded = (caseUuid) => {
  return async (dispatch, getState) => {
    // check if case doc exists, skip sync if it does
    const result = await caseDB.fetchCase(caseUuid);
    const currentVersion =
      getState().configuration.versions?.[DB_CONSTANTS.CASES_V2]?.current || 0;
    const isSyncing = getState()?.case?.syncing?.indexOf(caseUuid) > -1;
    const upToDateVersion = result?.version
      ? result.version >= currentVersion
      : false;

    if (isSyncing || (result && result.caseState && upToDateVersion)) {
      return false;
    }

    dispatch({
      type: SYNC_CASE,
      caseUuid: caseUuid
    });
    try {
      const response = await triggerCaseSyncCall(caseUuid);
      if (isRequestSuccess(response)) {
        return dispatch({
          type: SYNC_CASE_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: SYNC_CASE_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "failed to sync case due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: SYNC_CASE_COMPLETE,
        caseUuid: caseUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const submitCaseUpdate = (caseUuid, text, resolved, diagnosis) => {
  return async (dispatch, getState) => {
    dispatch({
      type: SUBMIT_CASE_UPDATE,
      caseUuid: caseUuid
    });
    try {
      const response = await submitCaseUpdateCall(
        caseUuid,
        text,
        resolved,
        diagnosis
      );
      if (isRequestSuccess(response)) {
        return dispatch({
          type: SUBMIT_CASE_UPDATE_COMPLETE,
          caseUuid: caseUuid
        });
      } else {
        return dispatch({
          type: SUBMIT_CASE_UPDATE_COMPLETE,
          caseUuid: caseUuid,
          error: true,
          message: "Failed to submit case update due to server error"
        });
      }
    } catch (error) {
      return dispatch({
        type: SUBMIT_CASE_UPDATE_COMPLETE,
        caseUuid: caseUuid,
        error: true,
        message: error.message
      });
    }
  };
};

export const updateCaseProgress = (caseUuid, contentPosition, isComplete) => {
  return async (dispatch) => {
    dispatch({
      type: UPDATE_CASE_PROGRESS,
      payload: {
        caseUuid: caseUuid,
        contentPosition: contentPosition,
        isComplete: isComplete
      }
    });
    try {
      const response = await updateCaseProgressCall(
        caseUuid,
        contentPosition,
        isComplete
      );
      if (isRequestSuccess(response)) {
        return dispatch({
          type: UPDATE_CASE_PROGRESS_COMPLETE,
          payload: { caseUuid: caseUuid }
        });
      } else {
        return dispatch({
          type: UPDATE_CASE_PROGRESS_COMPLETE,
          payload: {
            caseUuid: caseUuid,
            error: true,
            message: "Failed to update case progress due to server error"
          }
        });
      }
    } catch (error) {
      return dispatch({
        type: UPDATE_CASE_PROGRESS_COMPLETE,
        payload: { caseUuid: caseUuid, error: true, message: error.message }
      });
    }
  };
};
