import React from "react";
import { Controller } from "react-hook-form";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import Select from "react-select";
import i18n from "../../../utils/i18n";
import { includesInput } from "../../../utils/search-utils";

const SpecialtySelectInput = ({
  draft,
  control,
  errors = {},
  className,
  label,
  combinedSpecialties,
  placeholder
}) => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ***************************************/
  /** ********************************** FUNCTIONS ***************************************/
  const validateSpeciality = (value) => {
    if (Array.isArray(value) && (value.length < 1 || value.length > 10)) {
      return false;
    }

    return !!value;
  };

  /** ********************************** RENDER ***************************************/
  if (!combinedSpecialties) return;

  return (
    <FormGroup className={className}>
      <Label
        for="specialties"
        className="helv-med text-15 line-height-21 text-default-black mb-2">
        {label}
      </Label>
      <Controller
        control={control}
        name="specialties"
        rules={{
          validate: validateSpeciality
        }}
        invalid={!!errors?.specialties ? 1 : 0}
        defaultValue={
          combinedSpecialties?.filter((s) =>
            [
              ...(draft?.specialtiesUuid || []),
              ...(draft?.subSpecialtiesUuid || [])
            ]?.includes(s.value)
          ) ?? []
        }
        as={
          <Select
            style={{
              width: "100%",
              resize: "none",
              borderColor: "#ccd3d8",
              borderRadius: "1px",
              lineHeight: "1.3rem",
              padding: "10px 15px 10px 15px"
            }}
            className={
              errors?.specialties ? "react-select is-invalid" : "react-select"
            }
            isMulti
            isClearable={false}
            options={combinedSpecialties}
            placeholder={placeholder}
            filterOption={includesInput}
          />
        }
      />
      <FormFeedback>
        {i18n.t("shareCase.primarySpecialtyRequired")}
      </FormFeedback>
    </FormGroup>
  );
};

export default SpecialtySelectInput;
