import React from "react";
import LinesEllipsisLoose from "react-lines-ellipsis/lib/loose";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";

const CaseTitle = ({ title, size = "16", maxLine = "2", className }) => {
  const { theme } = useTheme();

  // There's a calculation issue with LinesEllipsis, so I'm swapping it to
  // LinesEllipsisLoose which does a css solution. It seems to work fine, but
  // we could run into some browser issue in the future -- Corey
  return (
    <>
      <LinesEllipsisLoose
        style={{ whiteSpace: "pre-wrap", wordBreak: "break-word" }}
        className={classNames(
          `text-${size} line-height-tight mr-auto`,
          theme === THEMES.DARK ? "" : "text-default-black font-weight-bold",
          className
        )}
        text={title}
        maxLine={maxLine}
        ellipsis="..."
        lineHeight={"1.25em"}
      />
    </>
  );
};

export default CaseTitle;
