import React, { useEffect } from "react";
import { Container } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Redirect } from "react-router-dom";
import { signOut } from "../../actions/login.actions";
import * as ROUTES from "../../constants/routes";
import { userSignedIn } from "../../utils/route-utils";
import Loading from "../../components/loading";

const LogoutPage = () => {
  const isSignedIn = useSelector((state) => userSignedIn(state.user));
  const dispatch = useDispatch();

  const redirectRoute = useSelector(
    (state) => state.authentication?.redirectRoute
  );

  useEffect(() => {
    dispatch(signOut(null, true));
  }, [dispatch]);

  if (!isSignedIn) {
    if (redirectRoute) {
      return <Redirect to={redirectRoute} />;
    }
    return <Redirect to={ROUTES.SIGN_IN} />;
  }

  return (
    <Container>
      <Loading />
    </Container>
  );
};

export default LogoutPage;
