import React from "react";
import { Input, FormGroup } from "reactstrap";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import useIsGated from "../../hooks/useIsGated";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import useGatedActionGate from "../../hooks/useGatedActionGate";

const SearchForm = ({
  onChange,
  onClear,
  onEnter,
  defaultValue,
  className = "",
  autoFocus = false,
  inputStyle
}) => {
  const { t } = useTranslation();
  const isGated = useIsGated();

  const gatedClick = useGatedActionGate(
    () => {},
    GATED_MODAL_TYPE.GENERIC,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.TOPBAR_NAV_SEARCH
  );

  // bit of a hack, but it works
  const searchInput = isGated ? (
    <Input
      name="search"
      type="search"
      placeholder={t("Search.searchFieldPlaceholder")}
      readOnly={true}
      style={{ backgroundColor: "white", fontSize: 16 }}
      onClick={gatedClick}
      autoFocus={autoFocus}
      enterKeyHint="search"
    />
  ) : (
    <Input
      name="search"
      type="search"
      style={inputStyle}
      placeholder={t("Search.searchFieldPlaceholder")}
      onChange={onChange}
      onKeyPress={(e) => {
        if (e.key === "Enter" && onEnter) {
          onEnter(e);
        } else if (e.key === "Escape" && onClear) {
          onClear(e);
        }
      }}
      defaultValue={defaultValue}
      autoComplete="off"
      autoFocus={autoFocus}
      enterKeyHint="search"
    />
  );
  return (
    <FormGroup className={`search-group ${className} w-100`}>
      <span className="icon icon-search" />
      {searchInput}
    </FormGroup>
  );
};

SearchForm.propTypes = {
  onChange: PropTypes.func,
  onClear: PropTypes.func,
  onEnter: PropTypes.func,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  autoFocus: PropTypes.bool
};

export default SearchForm;
