import React from "react";
import { Navbar } from "reactstrap";
import i18n from "../../utils/i18n";
import { Link } from "react-router-dom";
import { getUrl, getUrlWithRedirect } from "../../utils/route-utils";
import { ROOT, SIGN_IN, SIGN_UP } from "../../constants/routes";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import { trackLogoClick, trackUngatedCTA } from "../../actions/metrics.actions";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import useSignUpModalClick from "../../hooks/useSignUpModalClick";

const GatedHeaderV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const { showLoginModal } = useLoginModalClick();
  const { showSignUpModal } = useSignUpModalClick();

  /** ********************************* FUNCTIONS *************************************/

  const onLogoClick = () => {
    trackLogoClick({ isGated: true });
  };
  /** ********************************** RENDER ***************************************/

  return (
    <header className="bg-white header--shadowed gated-header">
      <Navbar
        className="d-flex primary-nav justify-content-between align-items-center"
        color="white"
        light
        expand>
        <span className="navbar-brand align-self-center">
          {i18n.t("common.figure1")}
        </span>

        <div className="d-inline-flex justify-content-end align-items-center side-segment">
          <Link
            className={`d-inline-flex justify-content-center align-items-center nav-link btn helv-bold text-16 text-center text-primary mr-2`}
            to={getUrlWithRedirect(SIGN_IN)}
            onClick={(e) => {
              trackUngatedCTA(UNGATED_ACTIONS.CTA_LOCATION.TOP_BAR, true);
              showLoginModal(e);
            }}>
            {i18n.t("RegistrationScreens.loginForm.btnSignIn")}
          </Link>

          <Link
            onClick={(e) => {
              trackUngatedCTA(UNGATED_ACTIONS.CTA_LOCATION.TOP_BAR, false);
              showSignUpModal(e);
            }}
            className={`d-inline-flex justify-content-center align-items-center nav-link btn btn-primary helv-bold text-center`}
            to={getUrlWithRedirect(SIGN_UP)}>
            {i18n.t("RegistrationScreens.createRegistration.btnSignup")}
          </Link>
        </div>
      </Navbar>
    </header>
  );
};

export default GatedHeaderV2;
