import React, { useState } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import { trackInviteColleaguesStart } from "../../../actions/metrics.actions";
import CircleCustomIcon from "../../../components/custom-icon/CircleCustomIcon";
import { StaticStrings } from "../../../constants/static-string-constants";
import { useSelectCurrentUser } from "../../../selectors/user.selectors";
import i18n from "../../../utils/i18n";
import { getFullUrl } from "../../../utils/route-utils";
import InviteColleaguesModal from "./InviteColleaguesModal";

const MoreOptionItem = ({ className, icon, text, onClick }) => {
  return (
    <Card className="border-0 cursor-pointer" onClick={onClick}>
      <CardBody
        className={`h-95px d-flex align-items-center justify-content-start ${className}`}>
        <CircleCustomIcon
          circleColor="white"
          icon={icon}
          innerClassName="mb-1"
          size={36}
          className="p-2 mr-2"
        />
        <p className="m-0 helv-med text-15 line-height-18">{text}</p>
      </CardBody>
    </Card>
  );
};

const MoreOptions = () => {
  /** ********************************** CONFIG ***************************************/
  const [inviteColleagueModalOpen, setInviteColleagueModalOpen] =
    useState(false);
  const user = useSelectCurrentUser();
  const inviteColleagesLink = getFullUrl(
    StaticStrings.inviteColleagesProfileUrl,
    {
      userUuid: user?.userUuid,
      USER_UUID: user?.userUuid
    }
  );
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const toggleInviteColleagueModal = () => {
    setInviteColleagueModalOpen(!inviteColleagueModalOpen);
  };
  const openInviteColleagueModal = () => {
    trackInviteColleaguesStart();
    setInviteColleagueModalOpen(true);
  };
  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Row>
        <Col>
          <MoreOptionItem
            className="bg-lighter-gray"
            icon="who_can_join"
            text={i18n.t("Profile.profileMoreOptions.inviteColleagues")}
            onClick={openInviteColleagueModal}
          />
        </Col>
      </Row>
      {inviteColleagueModalOpen && (
        <InviteColleaguesModal
          isOpen={inviteColleagueModalOpen}
          toggle={toggleInviteColleagueModal}
          linkToCopy={inviteColleagesLink}
        />
      )}
    </>
  );
};

export default MoreOptions;
