import React, { useState } from "react";
import { Button } from "reactstrap";
import i18n from "../../../utils/i18n";
import CircleCustomIcon from "../../../components/custom-icon/CircleCustomIcon";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { useForm } from "react-hook-form";
import { updateUserProfileBasicInfo } from "../../../actions/user-profiles.actions";
import TOAST_TYPE from "../../../components/popup/ToastType";
import usePopup from "../../../components/popup/usePopup";
import { useDispatch, useSelector } from "react-redux";
import LoadingButton from "../../../components/common/LoadingButton";
import useVerifiedUserGate from "../../../hooks/use-verified-user.hooks";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";
import { trackProfileSaveClick } from "../../../actions/metrics.actions";
import {
  clearTransientEditDetails,
  setTransientEditDetails
} from "../../../actions/user-verification.actions";

const InnerActionBar = ({ onCancel, onSave, saving }) => (
  <div className="mt-2 d-flex justify-content-end">
    <Button
      className="w-125px mr-2"
      color="secondary"
      onClick={onCancel}
      disabled={saving}>
      {i18n.t("common.cancel")}
    </Button>
    <LoadingButton
      className="w-125px"
      color="primary"
      spinnerColor="light"
      disabled={saving}
      loading={saving}
      onClick={onSave}>
      {i18n.t("common.save")}
    </LoadingButton>
  </div>
);

const ReadOnlyBar = ({ onEditMode, displayText, addLabel }) => (
  <div className="d-flex justify-content-between align-items-start">
    {displayText && (
      <p className="text-18 mb-0" style={{ whiteSpace: "pre-line" }}>
        {displayText}
      </p>
    )}
    <Button
      color="link"
      className="p-0 border-0 d-flex align-items-center"
      onClick={onEditMode}>
      {displayText ? (
        <CircleCustomIcon
          icon="profile_edit_pencil"
          circleColor="secondary"
          innerClassName="icon-denim-blue"
          size={18}
        />
      ) : (
        <>
          <CustomIcon
            icon="plus_sign"
            className="icon-denim-blue mr-1"
            size={18}
          />{" "}
          {i18n.t(addLabel)}
        </>
      )}
    </Button>
  </div>
);

const useProfileLogic = (startEditing = false) => {
  const { handleSubmit, control, errors, watch, setValue, getValues } =
    useForm();
  const { showToast } = usePopup();
  const dispatch = useDispatch();

  const [editMode, setEditMode] = useState(startEditing);
  const [saving, setSaving] = useState(false);

  const onEditMode = () => {
    setEditMode(true);
  };

  const onCancel = () => {
    setEditMode(false);
  };

  const transientDetails = useSelector(
    (state) => state.userVerification?.transientEditDetails
  );

  const sendEditMetrics = (basics) => {
    let eventName = null;
    let extra = {};

    if (basics.specialties) {
      eventName =
        METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.SECTIONS
          .SECONDARY_SPECIALTIES;
    }

    if (basics.userPracticeLocation) {
      eventName =
        METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.SECTIONS.PRACTICE_LOCATION;
    }

    if (basics.userPracticeHospital) {
      eventName =
        METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.SECTIONS.PRACTICE_HOSPITAL;
    }

    if (basics.userBio) {
      eventName = METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.SECTIONS.BIOGRAPHY;
    }

    if (basics.graduationDate || basics.firstName || basics.lastName) {
      eventName =
        METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.SECTIONS.ACCOUNT_DETAILS;
    }

    if (basics.graduationDate) {
      extra[METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.PROPS.PRIOR_GRAD_DATE] =
        basics.originalGradDate;
      extra[METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.PROPS.NEW_GRAD_DATE] =
        basics.graduationDate;
    }

    if (eventName) {
      trackProfileSaveClick(eventName, extra);
    }
  };

  const save = async (values) => {
    const payload = { ...values, userCustomSpecialty: false };
    if (payload.specialties) {
      const specialties = (values.specialties || []).map(
        (specialty) => specialty.treeUuid
      );

      payload.specialties = specialties?.length ? specialties : null;
    }

    if (values.specialty) {
      payload.primarySpecialty = values.specialty?.value;
      delete payload.specialty;
      delete payload.profession;
    } else if (values.hiddenSpecialty) {
      payload.primarySpecialty = values.hiddenSpecialty;
      delete payload.hiddenSpecialty;
    }

    if (payload?.graduationYear && payload?.graduationMonth) {
      payload.graduationDate = `${payload?.graduationYear.value}-${payload?.graduationMonth.value}-01`;
      delete payload.graduationYear;
      delete payload.graduationMonth;
    }

    let result;
    try {
      sendEditMetrics(payload);
      setSaving(true);

      result = await dispatch(updateUserProfileBasicInfo(payload));

      if (result.error) {
        throw result.error;
      } else {
        onCancel();
      }
    } catch (error) {
      showToast({
        message: i18n.t("Profile.updateError"),
        toastType: TOAST_TYPE.ERROR
      });
    } finally {
      setSaving(false);
      return result;
    }
  };

  const verifiedEditModeClick = useVerifiedUserGate(onEditMode);

  return {
    actionBar: () => (
      <InnerActionBar
        onCancel={onCancel}
        onSave={handleSubmit(save)}
        saving={saving}
      />
    ),
    readOnlyBar: (displayText, addLabel) => (
      <ReadOnlyBar
        onEditMode={verifiedEditModeClick}
        displayText={displayText}
        addLabel={addLabel}
      />
    ),
    saving,
    editMode,
    form: {
      handleSubmit,
      control,
      errors,
      watch,
      setValue,
      getValues
    },
    onEditMode: verifiedEditModeClick,
    onCancel,
    onSave: save,
    transientDetails,
    setTempDetails: async (details) => {
      await dispatch(setTransientEditDetails(details));
    },
    clearTempDetails: async () => {
      await dispatch(clearTransientEditDetails());
    }
  };
};

export default useProfileLogic;
