import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import { Controller, useForm } from "react-hook-form";
import { trim } from "lodash";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import { forgotPassword } from "../../../actions/non-user.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import { trackEvent } from "../../../actions/metrics.actions";
import {
  METRICS_EVENT_KEY,
  METRICS_EVENT_NAME,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics.js";
import { useContactSupport } from "../../../hooks/useContactSupport";
import EmailIcon from "../../../assets/images/email_icon_web.svg";
import { setLocalStorageItem } from "../../../utils/local-storage-utils";
import LOCAL_STORAGE_CONSTANTS from "../../../constants/local-storage-constants";
import Regex from "../../../constants/regex";

export const GENERATE_LINK_STATES = {
  GENERATE: "generate",
  USERNAME_MATCH: "match"
};

const GenerateLoginLink = () => {
  /** ********************************** CONFIG ***************************************/
  let history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, control, watch } = useForm();
  const [generateLinkState, setGenerateLinkState] = useState(
    GENERATE_LINK_STATES.GENERATE
  );
  const [error, setError] = useState("");
  const watchEmail = watch("email");

  const [maskedEmail, setMaskedEmail] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const onContactSupport = useContactSupport();

  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async (data) => {
    setIsLoading(true);
    setError("");

    const resetResults = await dispatch(
      forgotPassword(trim(data.email), false)
    );

    setIsLoading(false);

    trackEvent(METRICS_EVENT_NAME.FORGOT_PASSWORD_LOGIN_LINK_REQUEST, {
      [METRICS_EVENT_KEY.INPUT]: data.email
    });

    if (resetResults.error) {
      setError(i18n.t(resetResults.message));
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]: `${resetResults.message} (${data.email})`
      });
    } else {
      setMaskedEmail(resetResults.email);
      const isEmail = Regex.SimpleEmail.test(watchEmail);
      if (isEmail) {
        setLocalStorageItem(
          LOCAL_STORAGE_CONSTANTS.LOGIN_LINK_EMAIL,
          JSON.stringify(watchEmail)
        );
      }
      setGenerateLinkState(GENERATE_LINK_STATES.USERNAME_MATCH);
    }
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {generateLinkState === GENERATE_LINK_STATES.GENERATE && (
        <>
          <h1 className="helv-bold text-center text-22 line-height-28 mb-3">
            {i18n.t(
              "RegistrationScreens.generateLoginLinkForm.generateLoginLinkTitle"
            )}
          </h1>
          <Card className="p-4 mt-5 mb-4 rounded-0 shadow">
            <h2 className="helv-bold text-18 line-height-28">
              {i18n.t(
                "RegistrationScreens.generateLoginLinkForm.enterEmailOrUserNameTitle"
              )}
            </h2>
            <p className="text-blue-gray text-14 line-height-18">
              {i18n.t(
                "RegistrationScreens.generateLoginLinkForm.resetPasswordDescription"
              )}
            </p>
            {error && (
              <p className="m-0 p-1 rounded bg-alert-red text-center text-coral text-14 line-height-20">
                {error}
              </p>
            )}
            <form className="mt-3" onSubmit={handleSubmit(handleSubmitClick)}>
              <FormGroup>
                <Label for="email">
                  {i18n.t("RegistrationScreens.loginForm.emailOrUsername")}
                </Label>
                <Controller
                  as={Input}
                  control={control}
                  id="email"
                  name="email"
                  placeholder={i18n.t(
                    "RegistrationScreens.loginForm.emailOrUsernamePlaceholder"
                  )}
                  rules={{ required: true }}
                />
                <FormFeedback>
                  {i18n.t("RegistrationScreens.loginForm.emailRequired")}
                </FormFeedback>
              </FormGroup>
              <div className="d-flex align-items-center mt-4">
                <LoadingButton
                  loading={isLoading}
                  disabled={!trim(watchEmail)}
                  spinnerColor="white"
                  type="submit"
                  className="w-100 mt-2 mb-3"
                  size="lg"
                  color="primary">
                  {i18n.t(
                    "RegistrationScreens.generateLoginLinkForm.btnSendLoginLink"
                  )}
                </LoadingButton>
              </div>
            </form>
          </Card>
        </>
      )}
      {generateLinkState === GENERATE_LINK_STATES.USERNAME_MATCH && (
        <div className="d-flex flex-column justify-content-center align-items-center text-center">
          <img src={EmailIcon} alt="" className="mt-4" />
          <h5 className="helv-bold text-22 line-height-28 mt-4">
            {i18n.t(
              "RegistrationScreens.generateLoginLinkForm.checkEmailTitle"
            )}
          </h5>
          <p className="mb-3 text-16 line-height-21">
            {i18n.t(
              "RegistrationScreens.generateLoginLinkForm.checkEmailDescription",
              { email: maskedEmail }
            )}
          </p>
          <LoadingButton
            className="mt-5 mb-3 w-75"
            color="primary"
            size="lg"
            loading={isLoading}
            spinnerColor="white"
            disabled={isLoading}
            onClick={() => history.push(getUrl(ROUTES.SIGN_IN))}>
            {i18n.t("common.done")}
          </LoadingButton>
        </div>
      )}
      <div className="text-center text-15 line-height-20">
        <span className="text-muted">
          {i18n.t("RegistrationScreens.generateLoginLinkForm.dontHaveAccess")}{" "}
        </span>
        <span
          onClick={onContactSupport}
          className="text-cool-blue cursor-pointer">
          {i18n.t("RegistrationScreens.contactSupport")}
        </span>
      </div>
    </>
  );
};

export default GenerateLoginLink;
