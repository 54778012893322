import React, { useRef, useState } from "react";
import { uniqueId } from "lodash";
import useResizeObserver from "../../hooks/use-resize-observer.hooks";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";

const TextExpander = ({
  className,
  numberOfLines,
  seeMoreText = i18n.t("common.seeMore"),
  seeLessText = i18n.t("common.seeLess"),
  children
}) => {
  /** ********************************** CONFIG ***************************************/
  const paragraphRef = useRef(null);
  const [id] = useState(uniqueId("textExpander"));
  const [isTextTruncated, setIsTextTruncated] = useState(undefined);
  const [isExpanded, setIsExpanded] = useState(false);

  /** ********************************** HOOKS ****************************************/

  useResizeObserver({
    resizeObserverCallback: (entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      const entry = entries[0];
      setIsTextTruncated(entry.target.scrollHeight > entry.contentRect.height);
    },
    elementRef: paragraphRef
  });

  /** ********************************* FUNCTIONS *************************************/

  const onSeeMoreClick = (e) => {
    setIsExpanded(e.target.checked);
  };

  /** ********************************** RENDER ***************************************/

  return (
    <div>
      <input
        id={id}
        type="checkbox"
        className="position-absolute opacity-0 pointer-events-none"
        onChange={onSeeMoreClick}
      />
      <p
        ref={paragraphRef}
        className={classNames("m-0 text-clamp line-height-21", className)}
        style={{ WebkitLineClamp: isExpanded ? "unset" : numberOfLines }}>
        {children}
      </p>
      {(isTextTruncated || isExpanded) && (
        <label
          htmlFor={id}
          role="button"
          className="btn-link text-cool-blue cursor-pointer mb-0">
          {isExpanded ? seeLessText : seeMoreText}
        </label>
      )}
    </div>
  );
};

export default TextExpander;
