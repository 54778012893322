/** @format */

import React, { useState } from "react";
import { Card, CardBody, CardHeader, Button } from "reactstrap";
import { trackInviteColleaguesStart } from "../../../actions/metrics.actions";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { StaticStrings } from "../../../constants/static-string-constants";
import { useSelectCurrentUser } from "../../../selectors/user.selectors";
import i18n from "../../../utils/i18n";
import { getFullUrl } from "../../../utils/route-utils";
import InviteColleaguesModal from "./InviteColleaguesModal";

const InviteColleaguesCTA = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const user = useSelectCurrentUser();
  const inviteColleagesLink = getFullUrl(
    StaticStrings.inviteColleagesProfileUrl,
    {
      userUuid: user?.userUuid,
      USER_UUID: user?.userUuid
    }
  );

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const openModal = () => {
    trackInviteColleaguesStart();
    setModalOpen(true);
  };

  return (
    <>
      <Card>
        <CardHeader className="bg-white">
          <div className="d-flex flex justify-content-between font-weight-bold align-items-center">
            {i18n.t("InviteColleagues.cta.title")}{" "}
            <CustomIcon icon="invite_colleagues" size={18} />
          </div>
        </CardHeader>
        <CardBody>
          <p>{i18n.t("InviteColleagues.cta.text")}</p>
          <Button color="primary" className="py-2 px-3" onClick={openModal}>
            {i18n.t("InviteColleagues.cta.button")}
          </Button>
        </CardBody>
      </Card>
      {modalOpen && (
        <InviteColleaguesModal
          isOpen={modalOpen}
          toggle={toggleModal}
          linkToCopy={inviteColleagesLink}
        />
      )}
    </>
  );
};

export default InviteColleaguesCTA;
