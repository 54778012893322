import React from "react";
import { Controller } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";
import i18n from "../../../../utils/i18n";
import useProfileLogic from "../../hooks/useProfileLogic";

const PracticeHospitalForm = ({ profileData }) => {
  const { actionBar, editMode, readOnlyBar, form } = useProfileLogic();
  const ActionBarComponent = actionBar();
  const ReadOnlyBarComponent = readOnlyBar(
    profileData.practiceHospital,
    "Settings.Profile.addPracticeHospitalCTA"
  );
  if (editMode) {
    return (
      <>
        <FormGroup>
          <Label for="userPracticeHospital">
            {i18n.t("Profile.hospitalLabel")}
          </Label>
          <Controller
            as={Input}
            control={form.control}
            id="userPracticeHospital"
            name="userPracticeHospital"
            type="text"
            placeholder={i18n.t("Profile.hospitalPlaceholder")}
            defaultValue={profileData.practiceHospital || ""}
          />
        </FormGroup>
        {ActionBarComponent}
      </>
    );
  } else {
    return (
      <div className="mb-2">
        <h3 className="label text-14 helv-med">
          <span>{i18n.t("Profile.hospitalLabel")}</span>
        </h3>
        {ReadOnlyBarComponent}
      </div>
    );
  }
};

export default PracticeHospitalForm;
