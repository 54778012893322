import React from "react";
import HomePage from "./home/HomePage";
import Figure1Layout from "../@layouts/Figure1Layout";
import AppPrompt from "../components/app-prompt/AppPrompt";

const HomeApp = () => {
  return (
    <Figure1Layout footer={<AppPrompt />}>
      <HomePage />
    </Figure1Layout>
  );
};

export default HomeApp;
