/** @format */

export const CASE_LABEL_TYPES = {
  UNRESOLVED: "unresolved",
  RARE_CONDITION: "rare_condition",
  RESOLVED: "resolved",
  VERIFIED_LITERATURE: "verified_literature",
  TRENDING: "trending",
  TEACHING_CASE: "teaching_case",
  COMMON_PRESENTATION: "common_presentation"
};

export const ELIGIBLE_CASE_LABELS_ABOUT_THIS_CASE = [
  CASE_LABEL_TYPES.UNRESOLVED,
  CASE_LABEL_TYPES.RESOLVED,
  CASE_LABEL_TYPES.RARE_CONDITION,
  CASE_LABEL_TYPES.COMMON_PRESENTATION,
  CASE_LABEL_TYPES.TRENDING
];
