/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

const REFERENCE_DOCUMENT = "labels";

export const fetchAll = async () => {
  try {
    let res = {};
    const doc = await getCollectionRef(DB_CONSTANTS.REFERENCE_DATA_NEW)
      .doc(REFERENCE_DOCUMENT)
      .get();
    if (doc.exists) {
      res = doc.data().all;
    }
    return res;
  } catch (error) {
    console.error("There was an error fetching caseLabels: ", error.message);
    throw new Error("There was an error fetching caseLabels");
  }
};
