import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import i18n from "../../../utils/i18n";
import FormElementProfession from "./FormElementProfession";
import _, { has } from "lodash";
import {
  fetchProfessionsIfNeeded,
  fetchProfessionSpecialtiesIfNeeded
} from "../../../actions/reference.actions";
import {
  OTHER_PROFESSION_CATEGORY_LABELS,
  PROFESSION_CATEGORY_LABELS,
  LEGACY_ONLY_PROFESSIONS
} from "../../../constants/profession-category-labels";
import FormElementNameV2 from "./FormElementName.v2";
import FormElementSpecialtyV2 from "./FormElementSpecialty.v2";
import { Controller } from "react-hook-form";
import { FormGroup, FormText, Input, Label } from "reactstrap";
import FormElementGraduationInfo from "./FormElementGraduationInfo";

const FormUserInfoV2 = ({
  existingData,
  control,
  errors,
  watch,
  setValue,
  getValues,
  updateMode = false,
  specialtyChangeCB = () => {},
  professionChangeCB = () => {}
}) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const professions = useSelector((state) => state?.references?.professions);
  const professionSpecialties = useSelector(
    (state) => state?.references?.professionSpecialties
  );

  const watchProfession = watch("profession");
  const watchSpecialty = watch("specialty");

  const professionChangeRequest = existingData?.professionChangeRequest;

  const [isStudentProfession, setIsStudentProfession] = useState(false);
  const [professionList, setProfessionList] = useState(null);
  const [specialtiesData, setSpecialtiesData] = useState(null);
  const [professionUuid, setProfessionUuid] = useState(null);
  const [initialProfession, setInitialProfession] = useState(null);
  const [initialSpecialty, setInitialSpecialty] = useState(null);

  const showSpecialtyPicker = watchProfession?.value;
  /** *********************************** HOOKS ***************************************/
  useEffect(() => {
    if (watchProfession) {
      professionChangeCB(watchProfession);
      setIsStudentProfession(
        watchProfession.label?.toLowerCase()?.indexOf("student") > -1
      );
    }
  }, [watchProfession, professionChangeCB]);

  useEffect(() => {
    if (watchSpecialty) {
      specialtyChangeCB(watchSpecialty);
    }
  }, [watchSpecialty, specialtyChangeCB]);

  useEffect(() => {
    if (existingData) {
      // watchprofession is here so that if the user actually SELECTS a profession, this doesn't clobber it
      // when the user aleady exists.
      if (!watchProfession && !initialProfession && professionList) {
        // using a user object
        let treeItem;
        if (existingData.primarySpecialty?.tree) {
          treeItem = existingData.primarySpecialty?.tree;
        }
        // npi data response
        else if (existingData.specialty) {
          treeItem = existingData.specialty;
        }
        let foundProfession;

        professionList.forEach((section) => {
          if (!foundProfession) {
            foundProfession = section.options.find(
              (category) =>
                category.value ===
                  treeItem?.profession?.professionCategoryLabel ||
                category.value === treeItem?.profession?.professionUuid
            );
          }
        });

        setInitialProfession(foundProfession);
        setValue("profession", foundProfession);
      }

      if (!initialSpecialty && specialtiesData) {
        // using a user object
        let currentSpecialty;
        if (existingData?.primarySpecialty?.tree?.subspecialty) {
          currentSpecialty = {
            label:
              existingData.primarySpecialty.tree.subspecialty.specialtyName,
            value: existingData.primarySpecialty.tree.treeUuid
          };
        } else if (existingData?.primarySpecialty?.tree?.specialty) {
          currentSpecialty = {
            label: existingData.primarySpecialty.tree.specialty.specialtyName,
            value: existingData.primarySpecialty.tree.treeUuid
          };
        } else if (existingData?.specialty?.specialty) {
          currentSpecialty = {
            label: existingData.specialty.specialty.specialtyName,
            value: existingData.specialty.treeUuid
          };
        }

        setInitialSpecialty(currentSpecialty);
        setValue("specialty", currentSpecialty);
      }
    }
  }, [
    existingData,
    professionList,
    specialtiesData,
    watchProfession,
    initialProfession,
    initialSpecialty,
    setValue
  ]);

  const useInitialSpecialty =
    watchProfession?.value === initialProfession?.value;

  /**
   * Bug: Changing between Other HCP/Other student still shows submit
   * enabled if specify profession has been filled on initial profession select.
   * Does nothing and disables button after press though!
   */
  // Clear out specialty/specific professions on profession change.
  useEffect(() => {
    setValue("specialty", null);
    // eslint-disable-next-line
  }, [watchProfession]);

  useEffect(() => {
    const formValues = getValues();

    if (watchProfession?.value) {
      const profList =
        professions?.specificProfessions?.[formValues.profession.value];

      if (profList && profList[0].value) {
        setProfessionUuid(profList[0].value);
        dispatch(fetchProfessionSpecialtiesIfNeeded(profList[0].value));
      } else {
        setProfessionUuid(watchProfession?.value);
        dispatch(
          fetchProfessionSpecialtiesIfNeeded(formValues.profession.value)
        );
      }
    }
    // eslint-disable-next-line
  }, [dispatch, professions, watchProfession]);

  useEffect(() => {
    if (professionSpecialties && professionUuid) {
      const currentSpecialties = professionSpecialties[professionUuid] || [];
      const specialtyList = _.compact(
        currentSpecialties.map((s) => {
          if (
            currentSpecialties.length > 1 &&
            s.onboardingDisplayName === watchProfession?.label
          ) {
            // don't exclude specialties that ONLY have one specialty (which will have the same name)
            // exclude specialties that are the "same" as the profession
            return null;
          }
          return {
            label: s.onboardingDisplayName,
            value: s.treeUuid
          };
        })
      );

      setSpecialtiesData(_.orderBy(specialtyList, "label"));
    }
  }, [
    professionSpecialties,
    professionUuid,
    showSpecialtyPicker,
    watchProfession
  ]);

  useEffect(() => {
    dispatch(fetchProfessionsIfNeeded());
  }, [dispatch]);

  useEffect(() => {
    if (professions) {
      const validProfessionsCategories = [
        {
          label: "Popular",
          options: (professions?.categories || []).filter((p) =>
            has(p, "hidden")
              ? !p.hidden
              : ![
                  ...LEGACY_ONLY_PROFESSIONS,
                  PROFESSION_CATEGORY_LABELS.NON_HCP
                ].includes(p.value)
          )
        }
      ];

      // strip out OTHER. Could be done above, but above's overly complicated,
      // and I'm not deconstructing it now. -- Corey
      validProfessionsCategories[0].options =
        validProfessionsCategories[0].options.filter((cat) => {
          return !(OTHER_PROFESSION_CATEGORY_LABELS.indexOf(cat.value) > -1);
        });

      // Manually inject the other's children into the list
      const otherProfessionsGroup = { label: "Other", options: [] };
      // Other healthcare professional

      OTHER_PROFESSION_CATEGORY_LABELS.filter(
        // strip out other professional: https://figure1.atlassian.net/browse/F1P-4731
        (l) => l !== "otherhealthcareprofessional"
      ).forEach((label) => {
        const otherProfessions =
          professions?.specificProfessions?.[label]?.length > 0
            ? professions?.specificProfessions[label]
            : [];
        otherProfessionsGroup.options.push(...otherProfessions);
      });

      validProfessionsCategories.push(otherProfessionsGroup);
      setProfessionList(validProfessionsCategories);
    }
  }, [professions]);

  /** ********************************* FUNCTIONS *************************************/

  const formatGroupLabel = (data) => (
    <div>
      <span>{data.label}</span>
      <span>{data.options.length}</span>
    </div>
  );

  /** ********************************** RENDER ***************************************/

  const professionChangeRequestLength = Object.keys(
    professionChangeRequest || {}
  ).length;

  if (!professionList || (existingData && !Object.keys(existingData).length)) {
    return null;
  }

  return (
    <>
      <FormElementNameV2
        errors={errors}
        control={control}
        setValue={setValue}
        model={existingData}
      />
      {professionChangeRequestLength > 0 && (
        <>
          <FormGroup>
            <Label className="mb-1">
              {i18n.t("RegistrationScreens.userInfo.professionLabel")}
            </Label>
            <Input
              disabled={true}
              value={professionChangeRequest?.profession?.professionName}
            />
            <FormText>
              {i18n.t("RegistrationScreens.userInfo.v2.professionUnderReview")}
            </FormText>
          </FormGroup>
          {professionChangeRequest?.subspecialty?.specialtyName && (
            <FormGroup>
              <Label className="mb-1">
                {i18n.t("RegistrationScreens.userInfo.specialtyLabel")}
              </Label>
              <Input
                disabled={true}
                value={professionChangeRequest?.subspecialty?.specialtyName}
              />
            </FormGroup>
          )}
        </>
      )}

      <div className={professionChangeRequestLength !== 0 ? "d-none" : ""}>
        <FormElementProfession
          name="profession"
          label={i18n.t("RegistrationScreens.userInfo.professionLabel")}
          placeholder={i18n.t(
            "RegistrationScreens.userInfo.professionPlaceholder"
          )}
          errors={errors}
          control={control}
          professionList={professionList}
          initialValue={initialProfession}
          showFeedback={true}
          formatGroupLabel={formatGroupLabel}
          updateMode={updateMode}
        />
        {specialtiesData?.length > 1 && (
          <FormElementSpecialtyV2
            name="specialty"
            label={i18n.t("RegistrationScreens.userInfo.specialtyLabel")}
            placeholder={i18n.t(
              "RegistrationScreens.userInfo.specialtyPlaceholder"
            )}
            errors={errors}
            control={control}
            specialties={specialtiesData}
            watch={watch}
            setValue={setValue}
            initialValue={useInitialSpecialty ? initialSpecialty : null}
            showFeedback={true}
          />
        )}
        {specialtiesData?.length === 1 && (
          <Controller
            as={<Input />}
            control={control}
            id="hiddenSpecialty"
            name="hiddenSpecialty"
            type="hidden"
            defaultValue={specialtiesData[0].value}
          />
        )}
      </div>

      {isStudentProfession && (
        <FormElementGraduationInfo
          errors={errors}
          control={control}
          graduationDate={existingData?.graduationDate}
        />
      )}
    </>
  );
};

export default FormUserInfoV2;
