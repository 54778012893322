import React from "react";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const BulletIcon = ({ icon, size = 24 }) => {
  return (
    <div
      className="d-flex align-items-center justify-content-center mr-3 w-36px h-36px bg-lighter-gray"
      style={{
        borderRadius: 18
      }}>
      <CustomIcon size={size} icon={icon} />
    </div>
  );
};

export default BulletIcon;
