import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "../../components/dialog/Dialog";
import i18n from "../../utils/i18n";
import { useHistory, useParams } from "react-router-dom";
import useCurrentGroup from "../../hooks/useCurrentGroup";
import F1FlatList from "../../components/common/F1FlatList";
import SearchProfileResultItem from "../search/components/SearchProfileResultItem";
import { getUrl } from "../../utils/route-utils";
import { PROFILE_DETAIL } from "../../constants/routes";
import { useDispatch, useSelector } from "react-redux";
import groupActions from "../../actions/groups.actions";
import { trackPageEntry } from "../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";

const GroupMembersModal = ({ isOpen, toggle }) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const history = useHistory();
  const routeParams = useParams();
  const currentGroup = useCurrentGroup(routeParams?.feedTypeUuid);
  const groupUuid = currentGroup?.groupUuid;
  const members = useSelector(
    (state) => state.groups?.members[groupUuid]?.data || []
  );

  /************************************ HOOKS ****************************************/
  useEffect(() => {
    if (isOpen) {
      trackPageEntry(
        METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_VIEW_MEMBERS_ENTRY,
        {
          groupUuid: currentGroup?.groupUuid
        }
      );
    }
  }, [isOpen, currentGroup?.groupUuid]);

  useEffect(() => {
    if (currentGroup?.groupUuid) {
      dispatch(groupActions.fetchGroupMembers(currentGroup?.groupUuid));
    }
  }, [dispatch, currentGroup?.groupUuid]);

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      toggle={toggle}
      body={
        <div className="w-100">
          <h2 className="d-flex align-items-center justify-content-center px-0 pb-2 flex-grow-1 m-0 helv-bold text-18 line-height-28">
            {i18n.t("GroupsFeed.MembersModal.title")}
          </h2>
          <hr className="border-width-4 border-lighter-gray" />
          <div className="pre-scrollable pr-2">
            <F1FlatList
              bordered={false}
              data={members}
              renderItem={({ item }) => (
                <SearchProfileResultItem
                  key={`${item.userUuid}`}
                  badgeText={
                    currentGroup.groupCreatorUuid === item.userUuid
                      ? i18n.t("GroupsFeed.MembersModal.creator")
                      : ""
                  }
                  {...item}
                  onClick={() => {
                    history.push(
                      getUrl(PROFILE_DETAIL, {
                        userUuid: item.userUuid
                      })
                    );
                  }}
                />
              )}
            />
          </div>
        </div>
      }
    />
  );
};

GroupMembersModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

GroupMembersModal.defaultProps = {};

export default GroupMembersModal;
