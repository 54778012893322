/** @format */

import { get } from "lodash";
import * as auth from "../api/auth";
import * as userDB from "../db/user.db";
import { refreshUserPreferences } from "../api/communications.cloud-functions";
import { toggleUserListener } from "./user.actions";
import {
  disableUserProfilesListeners,
  toggleUserFollowingListener
} from "./user-profiles.actions";
import { toggleListenForUserPreferences } from "./preferences-center.actions";
import { toggleUserFeedMetaListener } from "./user-feed-meta.actions";
import activityCentreActions from "./activity-centre.actions";
import { toggleUserDraftListener } from "./user-draft.actions";
import { RESET_ALL, resetGatedState, setGlobalLoading } from "./global.actions";
import LEGACY_ONBOARDING_STATE from "../constants/legacy-onboarding-state";
import * as ROUTES from "../constants/routes";
import { getUrl } from "../utils/route-utils";
import {
  activateElasticSearchConfigListener,
  deactivateAllListeners as deactivateConfigurationListeners
} from "./configuration.actions";
import { initAnonUserInfo, trackUserSignOut } from "../actions/metrics.actions";
import { deactivateAllListeners as deactivateSavedCaseListeners } from "./saved-cases.actions";
import {
  associateAnonUser,
  createUngatedUser
} from "../api/user.cloud-functions";
import NonUserActionTypes from "./non-user-action-types";
import { Constants } from "@figure1/f1-pro-fe-brain";
import {
  isLegacyUser,
  isNonHCP,
  isUserCompletedSignup
} from "../utils/onboarding-utils";
import i18n from "../utils/i18n";
import LOCAL_STORAGE_CONSTANTS from "../constants/local-storage-constants";
import { getAndParseLocalStorageItem } from "../utils/local-storage-utils";
import { disableCommentsListeners } from "./comment.actions";
import {
  toggleUserCmeAvailableListener,
  toggleUserCmeCompletedListener,
  toggleUserCmeMetaListener
} from "./user-cme.actions";
import { toggleUserVotesListener } from "./user-vote-answer.actions";
import { getUserCountry } from "../utils/cme-utils";
import anonymousAuthorsActions from "./anonymousAuthors.actions";

const actionsPrefix = "login";

export const AUTO_LOGIN = `${actionsPrefix}/AUTO_LOGIN`;
export const AUTO_LOGIN_COMPLETE = `${actionsPrefix}/AUTO_LOGIN_COMPLETE`;
export const AUTHENTICATE = `${actionsPrefix}/AUTHENTICATE`;
export const AUTHENTICATE_COMPLETE = `${actionsPrefix}/AUTHENTICATE_COMPLETE`;
export const AUTHENTICATE_ERROR = `${actionsPrefix}/AUTHENTICATE_ERROR`;
export const START_VERIFICATION = `${actionsPrefix}/START_VERIFICATION`;
export const SIGNOUT = `${actionsPrefix}/SIGNOUT`;
export const SET_REDIRECT_ROUTE = `${actionsPrefix}/SET_REDIRECT_ROUTE`;
export const CLEAR_REDIRECT_ROUTE = `${actionsPrefix}/CLEAR_REDIRECT_ROUTE`;
export const SET_INITIAL_ROUTE = `${actionsPrefix}/SET_INITIAL_ROUTE`;
export const REAUTHENTICATE = `${actionsPrefix}/REAUTHENTICATE`;
export const REAUTHENTICATE_COMPLETE = `${actionsPrefix}/REAUTHENTICATE_COMPLETE`;
export const LEGACY_MIGRATION = `${actionsPrefix}/LEGACY_MIGRATION`;
export const LEGACY_MIGRATION_COMPLETE = `${actionsPrefix}/LEGACY_MIGRATION_COMPLETE`;

export const anonSignIn = (preventRouting) => {
  return async (dispatch, getState) => {
    try {
      const authUser = getAndParseLocalStorageItem(
        LOCAL_STORAGE_CONSTANTS.AUTH_USER
      );
      const email = getState().nonUser?.anonUserEmail;

      let user;
      let userUid;

      if (authUser) {
        user = authUser;
        userUid = authUser?.uid;
      } else {
        const result = await auth.anonLogin();
        user = result.user;
        userUid = user?.uid;
      }

      const userUuid = await createUngatedUser({
        email: email ? email : null,
        userUid
      });

      if (userUuid) {
        const anonUser = {
          userUid,
          userUuid
        };
        if (email) {
          anonUser.email = email;
        }

        if (!authUser) {
          initAnonUserInfo(anonUser);
        }

        dispatch(
          setAnonUserData(anonUser.userUid, anonUser.userUuid, anonUser.email)
        );
      }

      if (!authUser) {
        await handleUserPreloadedData(user);
        dispatch(handleUserInitialRoute(user, preventRouting));
      }
    } catch (error) {
      // ???
    }
  };
};

export const setAnonUserData = (userUid, userUuid, email) => {
  return async (dispatch) => {
    const data = { type: NonUserActionTypes.SET_ANON_DATA };

    if (userUid) {
      data.userUid = userUid;
    }

    if (userUuid) {
      data.userUuid = userUuid;
    }

    if (email) {
      data.email = email;
    }

    dispatch(data);
  };
};

export const signIn = (email, pass, emitVerificationEvent, preventRedirect) => {
  return async (dispatch, getState) => {
    try {
      dispatch(startVerification());
      const anonUid = getState().nonUser.anonUserUid;
      const existingUserInStorage = getAndParseLocalStorageItem(
        LOCAL_STORAGE_CONSTANTS.AUTH_USER
      );
      const result = await auth.login(email, pass);
      const user = await userDB.fetchUser(result.user?.uid);

      if (anonUid) {
        await associateAnonUser({
          anonUid,
          userUid: user.userUid
        });
      }
      if (existingUserInStorage?.uid === user.userUid) {
        // same user's already here so authchange will never file, as such
        // we have to trigger the danged thing manually
        dispatch(handleAuthChange(result));
      }

      // In case an anon-user login/sign up, clear ungated state.
      dispatch(resetGatedState());
      dispatch(handleUserInitialRoute(user, preventRedirect));

      if (emitVerificationEvent) {
        dispatch({
          type: LEGACY_MIGRATION_COMPLETE
        });
      }
      return dispatch(authenticationVerificationComplete(result));
    } catch (error) {
      return dispatch(
        authenticationVerificationError(
          new Error(i18n.t("RegistrationScreens.loginForm.failedLoginMessage"))
        )
      );
    }
  };
};

export const handleUngatedSignIn = () => {
  return async (dispatch, getState) => {
    const user = getState()?.user;
    const routeNameResult = await handleUserNavigation(user);
    dispatch(setInitialRoute(routeNameResult));
  };
};

export const handleUserInitialRoute = (user, preventRedirect) => {
  return async (dispatch, getState) => {
    const redirectRoute = getState().authentication?.redirectRoute;
    const isGated = getState().global?.isGated;

    if (!preventRedirect) {
      if (redirectRoute && isUserCompletedSignup(user)) {
        dispatch(setInitialRoute(redirectRoute));
        dispatch(setRedirectRoute(null));
      } else if (!isGated) {
        const routeNameResult = await handleUserNavigation(user);
        dispatch(setInitialRoute(routeNameResult));
      }
    } else if (user.isAnonymous !== true && !isUserCompletedSignup(user)) {
      // user is logged in and not completed (partial regflow complete),
      // so off to regflow they go, preventRedirect be danged.
      const routeNameResult = await handleUserNavigation(user);

      dispatch(setInitialRoute(routeNameResult));
    }
  };
};

export const handleAuthChange = (authResult) => {
  return async (dispatch, getState) => {
    if (authResult) {
      // Signed in
      const existingUser = getState().authentication;
      const user = await userDB.fetchUser(authResult.uid);

      await handleUserPreloadedData(user);

      // Yeah, I know what this looks like.
      if (
        (!existingUser ||
          !existingUser?.isAuthenticated ||
          existingUser?.isAnonymous) &&
        !authResult.isAnonymous
      ) {
        await dispatch(toggleStateListeners());
        // we're going from anon or nothing to logged in, so we need to trigger our listeners
        await dispatch(toggleStateListeners(authResult, true));
      }

      dispatch(authenticationVerificationComplete({ user: authResult }));
      dispatch(setGlobalLoading(false));
    } else {
      // Signed out
      try {
        const rr = getState().authentication.redirectRoute;

        await dispatch(toggleStateListeners(null));
        // check groups redirect??
        await dispatch({ type: RESET_ALL });
        dispatch(setGlobalLoading(false));
        if (rr) {
          await dispatch(setRedirectRoute(rr));
        }
      } catch (e) {
        console.log("err", e);
      }
    }
  };
};

export const toggleStateListeners = (user, forceListener) => {
  return async (dispatch) => {
    if (user) {
      await dispatch(toggleUserListener(user.uid, forceListener));
      await dispatch(toggleListenForUserPreferences(user.uid, forceListener));
      await dispatch(toggleUserFeedMetaListener(user.uid, forceListener));
      await dispatch(
        toggleUserDraftListener(user.uid, user.email, forceListener)
      );
      await dispatch(activateElasticSearchConfigListener());
      await dispatch(activityCentreActions.activateActivityMetaDataListener());
      await dispatch(anonymousAuthorsActions.activateListener(user.uid));
    } else {
      await dispatch(toggleUserCmeMetaListener(false));
      await dispatch(toggleUserCmeAvailableListener(false));
      await dispatch(toggleUserCmeCompletedListener(false));
      await dispatch(toggleUserVotesListener());
      await dispatch(disableUserProfilesListeners());
      await dispatch(disableCommentsListeners());
      await dispatch(toggleListenForUserPreferences());
      await dispatch(toggleUserFeedMetaListener());
      await dispatch(toggleUserDraftListener());
      await dispatch(deactivateSavedCaseListeners());
      await dispatch(deactivateConfigurationListeners());
      await dispatch(
        activityCentreActions.deactivateActivityMetaDataListener()
      );
      await dispatch(toggleUserFollowingListener());
      await dispatch(toggleUserListener());
      await dispatch(anonymousAuthorsActions.deactivateAllListeners());
    }
  };
};

export const signOut = (redirectRoute, doAuthSignout, signoutClick = true) => {
  return async (dispatch, getState) => {
    const rr = redirectRoute
      ? redirectRoute
      : getState().authentication.redirectRoute;
    if (rr) {
      await dispatch(setRedirectRoute(rr));
    }
    if (doAuthSignout) {
      await auth.signOut();
      trackUserSignOut({ signoutClick });
    }
  };
};

/**
 * Examples of data safe and desirable to load here
 * 1. Profile Data
 * 2. Settings Data
 * 3. Preferences for Communications
 */
const handleUserPreloadedData = async (user) => {
  const uid = get(user, "uid", "no uid for user");

  try {
    await refreshUserPreferences(user);
  } catch (error) {
    console.error(`There was an error refreshing preferences for user ${uid}`);
  }
};

export const getRegistrationRoute = (user, initializing = false) => {
  switch (newOnboardingState(user, initializing)) {
    case Constants.ONBOARDING_STATE.COUNTRY:
      return ROUTES.REGISTRATION_COUNTRY_V2;
    case Constants.ONBOARDING_STATE.CONFIRMATION:
      return ROUTES.AUTO_REGISTRATION_INFORMATION;
    case Constants.ONBOARDING_STATE.USA_INFORMATION:
    case Constants.ONBOARDING_STATE.INFORMATION:
    case Constants.ONBOARDING_STATE.GRAD_DATE:
      return ROUTES.REGISTRATION_INFORMATION_V2;
    case Constants.ONBOARDING_STATE.USERNAME:
      return ROUTES.REGISTRATION_USERNAME_V2;
    case Constants.ONBOARDING_STATE.VERIFICATION:
      return ROUTES.REGISTRATION_VERFICATION_PHOTO_V2;
    case Constants.ONBOARDING_STATE.USERNAME:
      return ROUTES.REGISTRATION_USERNAME_V2;
    case Constants.ONBOARDING_STATE.COMPLETED:
      return getUrl(ROUTES.HOME_ROOT);
    case LEGACY_ONBOARDING_STATE.NON_HCP:
      return ROUTES.REGISTRATION_ONBOARDING_NON_HCP; //NON_HCP.ROOT;
    default:
      return ROUTES.REGISTRATION;
  }
};

export const newOnboardingState = (user, initializing) => {
  if (!user) {
    return Constants.ONBOARDING_STATE.SIGN_UP;
  }

  if (isNonHCP(user)) {
    return LEGACY_ONBOARDING_STATE.NON_HCP;
  }

  if (isUserCompletedSignup(user)) {
    return Constants.ONBOARDING_STATE.COMPLETED;
  }

  if (user.onboardingState) {
    if (
      initializing &&
      getUserCountry(user) === "US" &&
      user.onboardingState === Constants.ONBOARDING_STATE.VERIFICATION
    ) {
      return Constants.ONBOARDING_STATE.USA_INFORMATION;
    } else {
      return user.onboardingState;
    }
  }

  // If user went through old reg flow but didn't complete, go to country screen
  return Constants.ONBOARDING_STATE.COUNTRY;
};

const handleUserNavigation = async (user) => {
  let routeName;

  if (user) {
    if (
      (isLegacyUser(user) && !isUserCompletedSignup(user)) ||
      !user?.onboardingState
    ) {
      routeName = ROUTES.REGISTRATION_ONBOARDING_V2;
    } else if (isUserCompletedSignup(user) || user?.onboardingState) {
      routeName = await getRegistrationRoute(user);
    } else {
      routeName = ROUTES.REGISTRATION_COUNTRY_V2;
    }
  }
  return routeName;
};

export const setInitialRoute = (route) => {
  return {
    type: SET_INITIAL_ROUTE,
    initialRoute: route
  };
};

export const setRedirectRoute = (route) => {
  return {
    type: SET_REDIRECT_ROUTE,
    redirectRoute: route
  };
};

const startVerification = () => {
  return {
    type: START_VERIFICATION
  };
};

const authenticationVerificationComplete = (loginResult) => {
  return {
    type: AUTHENTICATE_COMPLETE,
    loginResult: loginResult
  };
};

const authenticationVerificationError = (error) => {
  return {
    type: AUTHENTICATE_ERROR,
    loginResult: error,
    error
  };
};
