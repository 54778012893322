import React, { useState } from "react";

import { Button } from "reactstrap";

import "./GroupsNavigation.scss";
import CustomIcon from "../custom-icon/CustomIcon";
import { Link } from "react-router-dom";
import i18n from "../../utils/i18n";
import CreateGroupModal from "../../pages/groups/CreateGroupModal";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";

const CreateGroupCard = () => {
  /** ********************************** CONFIG ***************************************/
  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const toggleCretaeGroupModal = () =>
    setCreateGroupModalOpen((prevState) => !prevState);
  const verifiedToggleCreateGroup = useVerifiedUserGate(toggleCretaeGroupModal);
  /** ********************************** RENDER ***************************************/

  return (
    <div className="d-none d-lg-block">
      <Button
        className="w-100 text-16 py-3"
        size="lg"
        color="primary"
        tag={Link}
        onClick={verifiedToggleCreateGroup}>
        <CustomIcon
          className="m-0 mr-2 mb-1 icon-white"
          icon={"plus"}
          size={16}
        />
        {i18n.t("navigation.create-group")}
      </Button>
      <CreateGroupModal
        isOpen={isCreateGroupModalOpen}
        toggle={toggleCretaeGroupModal}
      />
    </div>
  );
};

export default CreateGroupCard;
