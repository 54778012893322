/** @format */
import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain";

const whatsNewReducer = (
  baseState = InitialReducerStates.WHATS_NEW_INITIAL_STATE,
  action
) => {
  const state = { ...BrainReducers.WhatsNewReducer(baseState, action) };

  switch (action.type) {
    default:
      return state;
  }
};

export default whatsNewReducer;
