import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import "./CaseCardBanner.scss";

const CaseCardBanner = ({
  iconImg,
  iconImgClassName,
  icon,
  iconClassName,
  header,
  subHeader
}) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Card className="case-card-banner border-0">
      <CardBody className="d-inline-flex justify-content-start align-items-start p-2 border-0">
        {iconImg && <img className={iconImgClassName} src={iconImg} alt="" />}
        {icon && <CustomIcon className={iconClassName} icon={icon} size={20} />}
        <div>
          <p className="mb-0 font-weight-bold line-height-tight text-default-black">
            {header}
          </p>
          <p className="mb-0 text-13 text-battleship-gray">{subHeader}</p>
        </div>
      </CardBody>
    </Card>
  );
};

export default CaseCardBanner;
