/** @format */

import { getCollectionRef } from "./firestore";
import DB_CONSTANTS from "./db-constants";

export const listenForChanges = async (callback, userUid) => {
  try {
    return getCollectionRef(DB_CONSTANTS.USER_VOTES)
      .doc(userUid)
      .onSnapshot(callback, (onObservableNextOrError) => {
        console.error("onObservableNextOrError: ", onObservableNextOrError);
      });
  } catch (e) {
    console.log("ERROR: ", e.message);
  }
};
