import React, { useEffect, useRef } from "react";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import UsersSearch from "../userSearch/UsersSearch";
import { useSelector } from "react-redux";
import { SEARCH_RESULT_TYPES } from "../../../constants/search-result-constants";
import { isEmpty, remove } from "lodash";
import F1FlatList from "../../../components/common/F1FlatList";
import Loading from "../../../components/loading";
import UserInviteRow from "./UserInviteRow";
import { trackPageEntry } from "../../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";

const formatProfiles = (data) =>
  data.map((raw) => ({
    image: raw?.avatar,
    username: raw?.username,
    displayName: raw?.displayName,
    profileDisplayName: raw?.profileDisplayName,
    userUuid: raw?.userUuid,
    invited: raw?.invited
  }));

const InviteF1UsersBody = ({
  selectedUsers,
  setSelectedUsers,
  currentGroup
}) => {
  /************************************ CONFIG ***************************************/
  const searchRef = useRef(null);

  const invitees = useSelector((state) => state.groups?.invitees);
  const isLoading = useSelector((state) => state?.search?.isLoading);
  const results = useSelector((state) => {
    const rawResults =
      state?.search?.searchResults?.[SEARCH_RESULT_TYPES.PROFILE];

    rawResults?.forEach((u) => {
      u.invited = invitees[currentGroup.groupUuid]?.includes(u.userUuid);
      u.selected = selectedUsers.includes(u.userUuid) && !u.invited;
    });

    if (isEmpty(rawResults)) {
      return [];
    } else {
      return formatProfiles(rawResults);
    }
  });

  /************************************ HOOKS ****************************************/
  useEffect(() => {
    trackPageEntry(
      METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_INVITE_F1_MEMBERS_ENTRY,
      {
        groupUuid: currentGroup?.groupUuid
      }
    );
  }, [currentGroup?.groupUuid]);
  /*********************************** FUNCTIONS *************************************/
  const toggleSelection = (item) => {
    if (selectedUsers.includes(item.userUuid)) {
      setSelectedUsers([
        ...remove(selectedUsers, (userUuid) => userUuid !== item.userUuid)
      ]);
    } else {
      setSelectedUsers([...selectedUsers, item.userUuid]);
    }
  };
  /************************************ RENDER ***************************************/

  return (
    <div className="w-100">
      <h2
        className={`d-flex align-items-center flex-grow-1 m-0 justify-content-center px-0 mb-2 helv-bold text-18 line-height-28`}>
        {i18n.t("GroupsFeed.InviteF1UsersModal.title")}
      </h2>
      <hr className="border-width-4 border-lighter-gray" />
      <div>
        <UsersSearch ref={searchRef} />
        {isLoading && (
          <div className="d-flex flex-column align-items-center justify-content-center h-400px">
            <Loading />
          </div>
        )}
        {results.length === 0 && !isLoading && (
          <div className="d-flex flex-column align-items-center justify-content-center h-400px">
            <CustomIcon icon="search-members-icon" size={40} />
            <h2 className="m-0 helv-bold text-18 line-height-20 mt-3">
              {i18n.t("GroupsFeed.InviteF1UsersModal.findMembers")}
            </h2>
            <h2 className="m-0 text-muted text-16 line-height-21 mt-2">
              {i18n.t("GroupsFeed.InviteF1UsersModal.searchMembersDesc1")}
            </h2>
            <h2 className="m-0 text-muted text-16 line-height-21">
              {i18n.t("GroupsFeed.InviteF1UsersModal.searchMembersDesc2")}
            </h2>
          </div>
        )}
        {results.length > 0 && !isLoading && (
          <div className="mt-2 pre-scrollable pr-2">
            <F1FlatList
              bordered={false}
              data={results || []}
              renderItem={({ item }) => (
                <UserInviteRow
                  key={`${item.userUuid}`}
                  {...item}
                  showCheckbox
                  checked={selectedUsers.includes(item.userUuid)}
                  onClick={() => toggleSelection(item)}
                />
              )}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default InviteF1UsersBody;
