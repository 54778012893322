/** @format */

const actionsPrefix = "nonUser";

export const NonUserActionTypes = {
  SIGN_UP: `${actionsPrefix}/SIGN_UP`,
  SIGN_UP_COMPLETE: `${actionsPrefix}/SIGN_UP_COMPLETE`,
  FORGOT_PASSWORD: `${actionsPrefix}/FORGOT_PASSWORD`,
  FORGOT_PASSWORD_COMPLETE: `${actionsPrefix}/FORGOT_PASSWORD_COMPLETE`,
  CHECK_AUTH: `${actionsPrefix}/CHECK_AUTH`,
  CHECK_AUTH_COMPLETE: `${actionsPrefix}/CHECK_AUTH_COMPLETE`,
  SET_ANON_DATA: `${actionsPrefix}/SET_ANON_DATA`,
  EMAIL_LOGIN_START: `${actionsPrefix}/EMAIL_LOGIN_START`,
  EMAIL_LOGIN_COMPLETE: `${actionsPrefix}/EMAIL_LOGIN_COMPLETE`
};

export default NonUserActionTypes;
