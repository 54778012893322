import React from "react";
import ToggleButton from "react-toggle-button";

const defaultStyles = {
  containerStyle: {
    width: 75
  },
  trackStyle: {
    height: 35,
    width: 75,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ccd3d8"
  },
  activeLabelStyle: {
    fontSize: 12,
    left: 0
  },
  inactiveLabelStyle: {
    fontSize: 12,
    color: "000",
    padding: 0
  },
  thumbStyle: {
    height: 29,
    width: 29,
    marginLeft: 2
  },
  colors: {
    activeThumb: {
      base: "#fff",
      hover: "#fff"
    },
    inactiveThumb: {
      base: "#CCD3D8",
      hover: "#fff"
    },
    active: {
      base: "#3BAFA8",
      hover: "#B1BFD7"
    },
    inactive: {
      base: "#fff",
      hover: "#3BAFA8"
    }
  },

  thumbAnimateRange: [1, 41]
};

const shortStyles = {
  ...defaultStyles,
  containerStyle: {
    width: 50
  },
  trackStyle: {
    height: 32,
    width: 50,
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#ccd3d8"
  },
  thumbStyle: {
    height: 26,
    width: 26,
    marginLeft: 2
  },
  colors: {
    activeThumb: {
      base: "#fff",
      hover: "#fff"
    },
    inactiveThumb: {
      base: "#fff",
      hover: "#fff"
    },
    active: {
      base: "#3BAFA8",
      hover: "#B1BFD7"
    },
    inactive: {
      base: "#ccd3d8",
      hover: "#3BAFA8"
    }
  },
  thumbAnimateRange: [1, 19]
};

const disabledStyles = {
  colors: {
    activeThumb: {
      base: "#fff",
      hover: "#fff"
    },
    inactiveThumb: {
      base: "#fff",
      hover: "#fff"
    },
    inactive: {
      base: "#ccd3d8",
      hover: "#ccd3d8"
    },
    active: {
      base: "#ccd3d8",
      hover: "#ccd3d8"
    }
  },
  inactiveLabelStyleHover: {
    cursor: "default"
  },
  trackStyleHover: { cursor: "default" },
  thumbStyleHover: { cursor: "default" }
};

const F1ToggleButton = (props) => {
  const {
    value,
    onClick,
    inactiveLabel,
    activeLabel,
    disabled,
    short = false
  } = props;

  const targetStyles = {
    ...(short ? shortStyles : defaultStyles),
    ...(disabled ? disabledStyles : {})
  };

  return (
    <ToggleButton
      value={disabled ? false : value}
      onClick={disabled ? () => {} : onClick}
      {...targetStyles}
      inactiveLabel={inactiveLabel}
      activeLabel={activeLabel}
    />
  );
};

export default F1ToggleButton;
