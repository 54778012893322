import React from "react";
import { useSelector } from "react-redux";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { isMobile } from "react-device-detect";
import { Button } from "reactstrap";
import "../GroupWelcomeSplashPage.scss";

const InstitutionalSplashPage = ({ onContinueClick }) => {
  /** ********************************** CONFIG ***************************************/
  const selectedGroup = useSelector((state) => state.groups?.currentGroup);
  /** ********************************* HOOKS *************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <h1 className={`helv-standerd mb-2 ${isMobile && "text-left"}`}>
        {i18n.t(
          "RegistrationScreens.groupWelcomeSplashPage.institutional.title",
          {
            groupname: selectedGroup.groupName
          }
        )}
      </h1>
      <p className={`color-white text-20 mb-4 ${isMobile && "text-left"}`}>
        {i18n.t(
          "RegistrationScreens.groupWelcomeSplashPage.institutional.description"
        )}
      </p>

      <ul>
        <li className="d-flex mb-3 text-20 align-items-center">
          <CustomIcon
            className="mr-3 flex-shrink-0"
            size={32}
            icon="groups_welcome_CME"
          />{" "}
          <div className="color-white">
            {i18n.t(
              "RegistrationScreens.groupWelcomeSplashPage.institutional.bullet1"
            )}
          </div>
        </li>
        <li className="d-flex mb-3 text-20 align-items-center">
          <CustomIcon
            className="mr-3 flex-shrink-0"
            size={32}
            icon="groups_welcome_safe"
          />{" "}
          <div className="color-white">
            {i18n.t(
              "RegistrationScreens.groupWelcomeSplashPage.institutional.bullet2"
            )}
          </div>
        </li>
        <li className="d-flex text-20 align-items-center">
          <CustomIcon
            className="mr-3 flex-shrink-0"
            size={32}
            icon="groups_welcome_updates"
          />{" "}
          <div className="color-white">
            {i18n.t(
              "RegistrationScreens.groupWelcomeSplashPage.institutional.bullet3",
              {
                groupname: selectedGroup.groupName
              }
            )}
          </div>
        </li>
      </ul>

      <div className="d-flex mt-4 w-100 justify-content-center">
        <Button
          className="mt-4 w-215px"
          color="primary"
          size="lg"
          onClick={onContinueClick}>
          {i18n.t("RegistrationScreens.groupWelcomeSplashPage.btnGetStarted")}
        </Button>
      </div>
    </>
  );
};

export default InstitutionalSplashPage;
