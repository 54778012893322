import React from "react";
import { Button } from "reactstrap";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";
import useAppPrompt from "./useAppPrompt";

const AppPromptFullCTA = ({ secondary = false }) => {
  const { appUrl, currentUrl, onOpenApp } = useAppPrompt();
  const primaryBtnColor = secondary ? "secondary" : "denim-blue";
  const secondaryBtnColor = secondary ? "link" : "secondary";
  const primaryClassName = secondary ? "text-primary text-14 helv-bold" : "";
  const secondaryClassName = secondary
    ? "d-flex text-15 align-items-center justify-content-center helv-bold"
    : "text-primary";

  const appHeaderClassName = secondary
    ? "text-battleship-gray text-14  helf-med mb-0"
    : "text-battleship-gray text-14 helf-med ";
  return (
    <>
      <Button
        tag="a"
        href={appUrl}
        color={primaryBtnColor}
        className={primaryClassName}
        size="lg"
        block
        onClick={onOpenApp}>
        {i18n.t("AppPrompt.btnDownload")}
      </Button>
      <hr className="border-width-2" />
      <p className={appHeaderClassName}>
        {i18n.t("AppPrompt.largePrompt.openAppHeader")}
      </p>
      <Button
        color={secondaryBtnColor}
        className={secondaryClassName}
        tag="a"
        href={currentUrl}
        size="lg"
        block
        onClick={onOpenApp}>
        {secondary && i18n.t("AppPrompt.btnOpenSecondary")}
        {!secondary && i18n.t("AppPrompt.btnOpen")}
        {secondary && (
          <CustomIcon
            className="icon-denim-blue"
            icon="drawer_chevron"
            size="12"
          />
        )}
      </Button>
    </>
  );
};

export default AppPromptFullCTA;
