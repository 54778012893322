/** @format */
import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain";
import { RESET_ALL } from "../actions/global.actions";

const actvityCentreReducer = (
  baseState = InitialReducerStates.ACTIVITY_CENTRE_INITIAL_STATE,
  action
) => {
  const state = { ...BrainReducers.ActivityCentreReducer(baseState, action) };

  switch (action.type) {
    case RESET_ALL:
      return InitialReducerStates.ACTIVITY_CENTRE_INITIAL_STATE;
    default:
      return state;
  }
};

export default actvityCentreReducer;
