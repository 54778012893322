import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import LoadingButton from "../../../components/common/LoadingButton";
import { authCheckAndSignIn } from "../../../actions/non-user.actions";
import FormElementPassword from "./FormElementPassword";
import { Link, useLocation } from "react-router-dom";
import {
  trackEvent,
  trackLogInPageEntry,
  trackUserSignIn
} from "../../../actions/metrics.actions";
import {
  METRICS_EVENT_NAME,
  METRICS_EVENT_PROPERTY,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics.js";
import { useContactSupport } from "../../../hooks/useContactSupport";
import LegacyTransitionModal from "./LegacyTransitionModal";
import FormGlobalError from "./FormGlobalError";
import { setGlobalLoading } from "../../../actions/global.actions";
import { setRedirectRoute } from "../../../actions/login.actions";
import { trim } from "lodash";

const LoginForm = ({
  inModal = false,
  className = "",
  preventRedirect = false,
  onSignIn = () => {}
}) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const loc = useLocation();

  const { handleSubmit, control, errors } = useForm({
    mode: "onChange"
  });

  const user = useSelector((state) => state.user);
  const migratingLegacyAccount = useSelector(
    (state) => state.authentication.migratingLegacyAccount
  );

  const [signUpRoute, setSignUpRoute] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const loginErrorMessage = useSelector((state) => {
    if (state?.authentication?.error) {
      return state.authentication.errorResult?.message;
    } else if (state?.nonUser?.error) {
      return state.nonUser.message;
    }
    return null;
  });

  /****************************** Hooks ***************************************/

  // trigger state change if it's legacy sign up route
  useEffect(() => {
    if (signUpRoute && user?.userUid) {
      setSignUpRoute(false);
    }
  }, [signUpRoute, user]);

  useEffect(() => {
    trackLogInPageEntry({
      [METRICS_EVENT_PROPERTY.MODAL]: inModal
    });
  }, [inModal]);

  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async (loginData) => {
    try {
      setIsLoading(true);
      dispatch(setGlobalLoading(true));
      trackUserSignIn({});

      if (preventRedirect) {
        dispatch(setRedirectRoute(loc.pathname));
      }

      const result = await dispatch(
        authCheckAndSignIn(
          trim(loginData.email),
          loginData.password,
          preventRedirect
        )
      );

      if (!result.error) {
        trackEvent(METRICS_EVENT_NAME.ERROR, {
          [METRICS_USER_PROPERTY.ERROR_MESSAGE]: result.message ?? ""
        });
        // automatic signup path for legacy user
        if (!result.onboardingCompleted) {
          setSignUpRoute(true);
        }
        trackUserSignIn({ afterSignInsuccess: true });
        onSignIn();
      } else {
        throw new Error(result.message);
      }
    } catch (e) {
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]: e.message
      });
      dispatch(setGlobalLoading(false));
      setIsLoading(false);
    }
  };

  const forgotLinks = (
    <p className="mb-0 text-center">
      <Link
        className="helv-med pl-0 text-cool-blue text-16"
        to={ROUTES.FORGOT_PASSWORD_OPTIONS}
        onClick={() => {
          trackEvent(METRICS_EVENT_NAME.FORGOT_PASSWORD);
        }}>
        {i18n.t("RegistrationScreens.loginForm.forgotPassword")}
      </Link>
    </p>
  );
  /** ********************************** RENDER ***************************************/

  return (
    <>
      <form onSubmit={handleSubmit(handleSubmitClick)} className={className}>
        <FormGlobalError errorText={loginErrorMessage} />

        <FormGroup className="mb-4">
          <Label for="email">
            {i18n.t("RegistrationScreens.loginForm.emailOrUsername")}
          </Label>
          <Controller
            as={Input}
            control={control}
            disabled={isLoading}
            id="email"
            name="email"
            type="text"
            invalid={!!errors.email}
            placeholder={i18n.t(
              "RegistrationScreens.loginForm.emailOrUsernamePlaceholder"
            )}
            rules={{ required: true }}
          />
          <FormFeedback>
            {i18n.t("RegistrationScreens.loginForm.emailRequired")}
          </FormFeedback>
        </FormGroup>

        <FormElementPassword
          control={control}
          errors={errors}
          isLoading={isLoading}
        />

        <LoadingButton
          className="mt-2 mb-4 w-100"
          color="primary"
          size="lg"
          loading={isLoading}
          spinnerColor="white"
          disabled={isLoading}>
          {i18n.t("RegistrationScreens.loginForm.btnSignIn")}
        </LoadingButton>

        {forgotLinks}
      </form>

      <LegacyTransitionModal isOpen={migratingLegacyAccount} />
    </>
  );
};

LoginForm.propTypes = {
  registrationState: PropTypes.string,
  setRegistrationState: PropTypes.func
};

export default LoginForm;
