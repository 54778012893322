import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Alert } from "reactstrap";
import Dialog from "../../components/dialog/Dialog";
import i18n from "../../utils/i18n";
import { useDispatch } from "react-redux";
import InvitePeopleOptionsBody from "./components/InvitePeopleOptionsBody";
import InviteF1UsersBody from "./components/InviteF1UsersBody";
import useDialog from "../../components/dialog/useDialog";
import { capitalize } from "lodash";
import InviteF1UsersFooter from "./components/InviteF1UsersFooter";
import { resetSearch, updateSearchTerm } from "../../actions/search.actions";
import { getFullUrl } from "../../utils/route-utils";
import { useCurrentGroup } from "../../selectors/group.selectors";
import { trackGroupEvent, trackPageEntry } from "../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";
import { useRef } from "react";
import { Source, StaticStrings } from "../../constants/static-string-constants";
import { isMobile } from "react-device-detect";
import { useSelectCurrentUser } from "../../selectors/user.selectors";

const ALERT_DURATION_MS = 3000;

const InviteModal = ({ isOpen, toggle }) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const [showInviteF1Users, setShowInviteF1Users] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [showLinkCopiedAlert, setShowLinkCopiedAlert] = useState(false);
  const linkCopiedTimeoutRef = useRef(null);
  const currentGroup = useCurrentGroup();
  const user = useSelectCurrentUser();

  const claimGroupUrl = getFullUrl(StaticStrings.claimGroupUrl, {
    groupUuid: currentGroup?.groupUuid,
    GROUP_UUID: currentGroup?.groupUuid,
    SOURCE: isMobile ? Source.MOBILE_WEB : Source.WEB,
    USER_UUID: user?.userUuid
  });
  /************************************ HOOKS ****************************************/
  useEffect(() => {
    if (isOpen) {
      trackPageEntry(METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_INVITE_ENTRY, {
        groupUuid: currentGroup?.groupUuid
      });
    }
  }, [isOpen, currentGroup?.groupUuid]);
  /*********************************** FUNCTIONS *************************************/
  const copyLink = () => {
    trackGroupEvent({
      eventName: METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_COPY_INVITE_LINK,
      groupUuid: currentGroup?.groupUuid
    });

    navigator.clipboard.writeText(claimGroupUrl);

    showLinkCopiedMessage();
  };

  const showLinkCopiedMessage = () => {
    setShowLinkCopiedAlert(true);
    clearTimeout(linkCopiedTimeoutRef.current);

    linkCopiedTimeoutRef.current = setTimeout(() => {
      clearTimeout(linkCopiedTimeoutRef.current);
      setShowLinkCopiedAlert(false);
    }, ALERT_DURATION_MS);
  };

  const inviteF1Members = () => {
    setShowInviteF1Users((prevState) => !prevState);
  };

  const clearSearch = () => {
    dispatch(updateSearchTerm(""));
    dispatch(resetSearch());
    setSelectedUsers([]);
  };

  const onToggle = async () => {
    if (selectedUsers.length > 0) {
      const confirmed = await confirm({
        message: i18n.t("GroupsFeed.ExitConfirmation.title"),
        messageDetail: "",
        confirmText: capitalize(i18n.t("common.exit")),
        cancelText: capitalize(i18n.t("common.cancel"))
      });

      if (!confirmed) {
        return;
      }
    }
    clearSearch();
    setShowInviteF1Users(false);
    toggle();
  };

  const onSkip = () => {
    trackGroupEvent({
      eventName: METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_SKIP_INVITE,
      groupUuid: currentGroup?.groupUuid
    });
    toggle();
  };
  /************************************ RENDER ***************************************/

  let body = (
    <InvitePeopleOptionsBody
      copyLink={copyLink}
      inviteF1Members={inviteF1Members}
      onSkip={onSkip}
    />
  );
  if (showInviteF1Users) {
    body = (
      <InviteF1UsersBody
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        currentGroup={currentGroup}
      />
    );
  }
  let footer = <></>;
  if (showInviteF1Users) {
    footer = (
      <InviteF1UsersFooter
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        currentGroup={currentGroup}
      />
    );
  }
  return (
    <Dialog
      isOpen={isOpen}
      onCancel={onToggle}
      toggle={onToggle}
      body={body}
      customFooter={footer}
      title={
        showLinkCopiedAlert && (
          <Alert className="bg-denim-blue border-denim-blue rounded-top-0 text-white text-15 font-weight-bold w-295px mt-n3 mb-0 mx-auto">
            {i18n.t("GroupsFeed.InviteModal.linkCopiedTitle")}
          </Alert>
        )
      }
    />
  );
};

InviteModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

InviteModal.defaultProps = {};

export default InviteModal;
