import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useEffect, useState } from "react";
import { $getRoot, $createParagraphNode, $createTextNode } from "lexical";

export function InitialContentPlugin({ defaultValue }) {
  const [editor] = useLexicalComposerContext();
  const [initialSet, setInitialSet] = useState(false);

  useEffect(() => {
    editor.update(() => {
      if (!initialSet) {
        const root = $getRoot();
        const paragraph = $createParagraphNode();
        const text = $createTextNode(defaultValue);

        paragraph.append(text);
        root.clear();
        root.append(paragraph);
        root.selectEnd();
        setInitialSet(true);
      }
    });
  }, [editor, defaultValue, initialSet]);

  return null;
}
