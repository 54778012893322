import React, { useState } from "react";
import { Card } from "reactstrap";
import "./GroupsNavigation.scss";
import GroupsNavItem from "./GroupsNavItem";
import { getUrl } from "../../utils/route-utils";
import { GROUPS_FEED, GROUPS_HOME } from "../../constants/routes";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";
import CustomIcon from "../custom-icon/CustomIcon";
import { useLocation } from "react-router-dom";
import useUserHasGroups from "../../hooks/useUserHasGroups";
import i18n from "../../utils/i18n";
import { useSelectUserGroups } from "../../selectors/group.selectors";
import CreateGroupModal from "../../pages/groups/CreateGroupModal";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";

const Create = ({ toggleCretaeGroupModal }) => (
  <div
    onClick={toggleCretaeGroupModal}
    className="d-flex flex-nowrap add-more align-items-center text-13 helv-med text-cool-blue cursor-pointer">
    <CustomIcon className="m-0 mr-1 icon-cool-blue" icon={"plus"} size={13} />
    {i18n.t("GroupNavigation.create")}
  </div>
);

const GroupsNavigation = () => {
  /** ********************************** CONFIG ***************************************/
  const location = useLocation();
  const pathname = location.pathname;
  const hasGroups = useUserHasGroups();

  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false);

  const groups = useSelectUserGroups();

  const groupsLoading = useSelector((state) => state.groups?.isProcessing);

  const isGroupFeedPage = pathname?.indexOf(GROUPS_HOME) !== -1;

  const mobileClasses = isMobile ? "mobile d-flex px-2" : "px-0";
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const toggleCretaeGroupModal = () =>
    setCreateGroupModalOpen((prevState) => !prevState);
  const verifiedToggleCreateGroup = useVerifiedUserGate(toggleCretaeGroupModal);
  /** ********************************** RENDER ***************************************/

  if (groupsLoading) return null;

  return (
    <Card
      className={`groups-navigation py-2 border-outline-gray mb-3 ${mobileClasses}`}>
      <h3 className="font-weight-bold text-12 p-2 mb-2 d-flex justify-content-between align-items-center">
        <span className="text-uppercase">
          {i18n.t("GroupNavigation.myGroups")}
        </span>
        {!isGroupFeedPage && !hasGroups && (
          <Create toggleCretaeGroupModal={verifiedToggleCreateGroup} />
        )}
      </h3>
      <hr style={{ borderTopWidth: "2px" }} className="mt-0 mb-2" />

      {groups.length === 0 && (
        <div
          className="text-12 text-center text-muted"
          style={{ padding: "11px 27px" }}>
          {i18n.t("GroupNavigation.emptyGroupsMessage")}
        </div>
      )}
      {groups.map((group) => (
        <GroupsNavItem
          key={group.groupUuid}
          imgUrl={group.groupAvatar}
          url={getUrl(GROUPS_FEED, {
            feedTypeUuid: group.groupUuid
          })}
          name={group.groupName}
          membersCount={group.membersCount || 0}
          isActive={
            isGroupFeedPage && pathname?.indexOf(`/${group.groupUuid}`) !== -1
          }
        />
      ))}
      <CreateGroupModal
        isOpen={isCreateGroupModalOpen}
        toggle={toggleCretaeGroupModal}
      />
    </Card>
  );
};

export default GroupsNavigation;
