import React from "react";
import { useHistory } from "react-router-dom";
import F1FullScreenOptionsCard from "../../../components/common/F1FullScreenOptionsCard";
import i18n from "../../../utils/i18n";
import { getUrl } from "../../../utils/route-utils";
import * as ROUTES from "../../../constants/routes";

const ForgotPasswordOptions = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();

  /** ********************************** HOOKS ***************************************/

  /** ********************************* FUNCTIONS *************************************/
  const goToEmailLoginLink = () => {
    history.push(getUrl(ROUTES.GENERATE_LOGIN_LINK));
  };
  const goToResetPassword = () => {
    history.push(getUrl(ROUTES.RESET_PASSWORD));
  };
  /** ********************************** RENDER ***************************************/

  return (
    <>
      <F1FullScreenOptionsCard
        icon="email_icon_no_border"
        circleIcon={true}
        title={i18n.t(
          "RegistrationScreens.forgotPasswordOptions.emailLoginLink.title"
        )}
        badgeText={i18n.t(
          "RegistrationScreens.forgotPasswordOptions.emailLoginLink.badgeText"
        )}
        description={i18n.t(
          "RegistrationScreens.forgotPasswordOptions.emailLoginLink.description"
        )}
        showChevron={true}
        className="mb-4"
        onOptionClick={goToEmailLoginLink}
      />
      <F1FullScreenOptionsCard
        icon="lock_icon"
        circleIcon={true}
        title={i18n.t(
          "RegistrationScreens.forgotPasswordOptions.resetPassword.title"
        )}
        description={i18n.t(
          "RegistrationScreens.forgotPasswordOptions.resetPassword.description"
        )}
        showChevron={true}
        onOptionClick={goToResetPassword}
      />
    </>
  );
};

export default ForgotPasswordOptions;
