import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { useForm } from "react-hook-form";
import { postVerification } from "../../../../actions/user-verification.actions";
import VerificationNPIForm from "../../../authentication/components/VerificationNPIForm";
import { trackProfileNpiVerificationClick } from "../../../../actions/metrics.actions";

const NPIForm = ({ isSaving, setIsSaving, onSave }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();

  const { handleSubmit, control, errors } = useForm({
    mode: "onBlur"
  });

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const onNPISubmit = async (formData) => {
    try {
      setIsSaving(true);
      trackProfileNpiVerificationClick();
      // save the transient details first
      const saveProfileResult = await onSave();

      if (saveProfileResult) {
        const verificationResult = await dispatch(
          postVerification({
            method: "npi",
            npiNumber: parseInt(formData.npiNumber)
          })
        );

        if (!verificationResult || verificationResult.error) {
          throw new Error(
            verificationResult?.error ||
              "Error sending NPI verification request"
          );
        }
      }
      // show modal?
    } catch (e) {
      setIsSaving(false);
    }
  };
  /** ********************************** RENDER ***************************************/

  return (
    <>
      <VerificationNPIForm
        control={control}
        errors={errors}
        onSubmit={handleSubmit(onNPISubmit)}
        isLoading={isSaving}
        updateMode={true}
      />
    </>
  );
};

export default NPIForm;
