import React from "react";
import CaseTagButton from "./CaseTagButton";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";

const CaseTagsGroup = ({ control, caseLabels, draftLabels, label, errors }) => {
  /** ********************************** CONFIG ***************************************/
  const validClass =
    errors?.filter((e) => !!e).length === caseLabels?.length
      ? "button-list is-invalid"
      : "button-list";

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <FormGroup tag="fieldset" name="caseLabels">
      <Label
        className="text-15 font-weight-500 text-default-black"
        for="caseLabels">
        {label}
      </Label>
      <div className={`d-flex flex-wrap ${validClass}`}>
        {(caseLabels ?? []).map(
          ({ labelUuid, name: labelName }, labelIndex) => (
            <Controller
              key={labelUuid}
              control={control}
              name={`caseLabels[${labelIndex}]`}
              defaultValue={
                (draftLabels || []).find(
                  (draftLabelUuid) => draftLabelUuid === labelUuid
                ) || ""
              }
              render={({ onChange, value }) => (
                <CaseTagButton
                  key={labelUuid}
                  labelItem={{
                    key: labelUuid,
                    name: labelName,
                    active: labelUuid === value
                  }}
                  handleClick={() =>
                    onChange(labelUuid === value ? undefined : labelUuid)
                  }
                />
              )}
            />
          )
        )}
      </div>
    </FormGroup>
  );
};

CaseTagsGroup.propTypes = {
  control: PropTypes.any,
  caseLabels: PropTypes.array,
  draftLabels: PropTypes.arrayOf(PropTypes.string),
  label: PropTypes.string
};

export default CaseTagsGroup;
