import React, { useEffect } from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import i18n from "../../utils/i18n";
import ForgotPasswordOptions from "./components/ForgotPasswordOptions";
import { trackPageEntry } from "../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";

const ForgotPasswordPage = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************* HOOKS *************************************/
  useEffect(() => {
    trackPageEntry(
      METRICS_EVENT_NAME.SCREEN.FORGOT_PASSWORD.FORGOT_PASSWORD_ENTRY
    );
  }, []);
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-5">
            <h1 className="helv-bold text-center text-22 line-height-28 mb-3">
              {i18n.t("RegistrationScreens.forgotPasswordOptions.title")}
            </h1>
            <p className="text-center text-16 line-height-24 px-4">
              {i18n.t("RegistrationScreens.forgotPasswordOptions.description")}
            </p>
            <div className="mt-5">
              <ForgotPasswordOptions />
            </div>
          </div>
        }
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default ForgotPasswordPage;
