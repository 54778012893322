import React, { useState } from "react";
import {
  Button,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import i18n from "../../utils/i18n";
import F1ToggleButton from "../common/F1ToggleButton";
import CustomIcon from "../custom-icon/CustomIcon";
import BaseDialogHeader from "../dialog/BaseDialogHeader";

const UnresolvedCaseModal = ({ onSave, onCancel, draft, isOpen = true }) => {
  const [requestHelp, setRequestHelp] = useState(
    draft?.requestHelp !== undefined ? draft?.requestHelp : true
  );
  const [paging, setPaging] = useState(draft?.paging);

  const localOnSave = () => {
    onSave({ requestHelp, paging });
  };

  return (
    <Modal isOpen={isOpen} onAbort={onCancel}>
      <BaseDialogHeader onCancel={onCancel} showCloseButton={true} />

      <ModalBody className="px-5 mt-5">
        <h5 className="helv-bold text-18">
          {i18n.t("shareCase.unresolvedModal.Title")}
        </h5>
        <p className="text-15 mb-4">
          {i18n.t("shareCase.unresolvedModal.Description")}
        </p>
        <FormGroup className="mb-4">
          <Label className="text-16 font-weight-500 text-default-black">
            {i18n.t("shareCase.unresolvedModal.Label")}
          </Label>
          <div className="d-flex flex-row w-100">
            <Button
              onClick={() => {
                setRequestHelp(true);
              }}
              className={`w-105px text-15 mr-2 ${
                requestHelp ? "active-button" : "inactive-border-button"
              }`}>
              {i18n.t("shareCase.unresolvedModal.Yes")}
            </Button>

            <Button
              onClick={() => {
                setRequestHelp(false);
                setPaging(false);
              }}
              className={`w-105px text-15 ${
                !requestHelp ? "active-button" : "inactive-border-button"
              }`}>
              {i18n.t("shareCase.unresolvedModal.No")}
            </Button>
          </div>
        </FormGroup>

        <div
          className={`d-flex flex-row border rounded border-width-1 border-battleship-grey p-3 ${
            !requestHelp ? "opacity-50" : ""
          }`}>
          <div className="d-flex flex-row align-items-start">
            <CustomIcon
              icon="page_case"
              size={28}
              className="mr-2 flex-shrink-0"
            />
            <div className="mr-4">
              <div className="text-16">
                {i18n.t("shareCase.unresolvedModal.PageTitle")}
              </div>
              <p className="text-muted mb-0 text-14">
                {i18n.t("shareCase.unresolvedModal.PageDescription")}
              </p>
            </div>

            <F1ToggleButton
              disabled={!requestHelp}
              short={true}
              inactiveLabel={""}
              activeLabel={""}
              value={paging}
              onClick={() => setPaging(!paging)}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className="pt-4 px-5 pb-5 border-0">
        <Button block={true} size="lg" color="primary" onClick={localOnSave}>
          {i18n.t("shareCase.unresolvedModal.ConfirmButton")}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UnresolvedCaseModal;
