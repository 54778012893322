/** @format */

export const CASE_ACTION = {
  SAVE: "save",
  UNSAVE: "unsave",
  REPORT: "report"
};

export const CASE_TYPES = {
  POST: "post",
  PAGING: "paging"
};

export const DRAFT_STATES = {
  DRAFT: "draft",
  PENDING_APPROVAL: "pending_approval",
  REJECTED: "rejected"
};

export const REJECTION_REASONS = {
  NON_CLINICAL_PHOTOS: "non_clinical_photos",
  IDENTIFYING_INFO: "identifying_info",
  INSUFFICIENT_CAPTION: "insufficient_caption"
};

export const CASE_REACTION = {
  CLINICALLY_USEFUL: "clinicallyUseful",
  AGREE: "agree",
  INFORMATIVE: "informative"
};

export const COMMENT_TYPES = {
  COMMENT: "comment",
  REPLY: "reply",
  EDIT: "edit"
};

export const COMMENT_SORT_TYPES = {
  TOP: "top",
  NEW: "new",
  OLD: "old"
};

export const MAX_SUMMARY_CHAR = 2500;
export const MAX_DIAGNOSIS_LENGTH = 500;
