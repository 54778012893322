import React from "react";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";
import CustomIcon from "../custom-icon/CustomIcon";
import i18n from "../../utils/i18n";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";
import { trackGroupEvent } from "../../actions/metrics.actions";

const GroupIcon = ({ isActive }) => (
  <div
    className="d-flex bg-gradient justify-content-center align-items-center"
    style={{
      height: "36px",
      width: "36px",
      borderRadius: "2px",
      background: isActive
        ? "linear-gradient(137deg, #a774cf 14%, #6e40a1 84%)"
        : "#b8a5cc"
    }}>
    <CustomIcon icon={"groups_people"} color={"white"} size={20} />
  </div>
);

const GroupsNavItem = ({
  url,
  name,
  membersCount,
  imgUrl,
  isActiveFn = (match) => {
    return !!match;
  },
  isActive
}) => {
  /** ********************************** CONFIG ***************************************/
  const mobileClasses = isMobile ? "px-2 py-2" : "px-3 py-2";
  /** ********************************** HOOKS ****************************************/

  const handleNavLinkClick = () => {
    trackGroupEvent({
      eventName: METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_NAME_CLICK
    });
  };

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <NavLink
      isActive={isActiveFn}
      to={{
        pathname: url,
        state: {
          context: "Navigation"
        }
      }}
      activeClassName="active"
      className={`d-flex align-items-center justify-content-between nav-item ${mobileClasses}`}
      onClick={handleNavLinkClick}>
      <div className="d-flex flex-row align-items-center">
        {imgUrl && (
          <img className="w-36px h-36px object-cover" src={imgUrl} alt="" />
        )}
        {!imgUrl && <GroupIcon isActive={isActive} />}
        <div className="pl-2">
          <span className="font-weight-bold text-14">{name}</span>
          <br />
          <span className="text-13">
            {i18n.t("GroupNavigation.noOfMembers", {
              count: membersCount
            })}
          </span>
        </div>
      </div>
      <div>
        <CustomIcon
          className="icon-battleship-gray"
          icon="drawer_draft_chevron"
          size={18}
        />
      </div>
    </NavLink>
  );
};

export default GroupsNavItem;
