export const getAndParseLocalStorageItem = (name) => {
  try {
    return JSON.parse(localStorage.getItem(name));
  } catch (e) {
    console.log(`Error getting ${name} from local storage: `, e.message);
    return null;
  }
};

export const setLocalStorageItem = (name, value) => {
  try {
    localStorage?.setItem(name, value);
    return getAndParseLocalStorageItem(name);
  } catch (e) {
    console.log(`Error setting ${name} from local storage: `, e.message);
    return null;
  }
};

export const resetLocalStorageItem = (name) => {
  try {
    localStorage?.setItem(name, null);
    return null;
  } catch (e) {
    console.log(`Error resetting ${name} from local storage: `, e.message);
    return null;
  }
};
