import { async } from "@figure1/f1-pro-fe-brain";
import TOAST_TYPE from "../components/popup/ToastType";

const actionsPrefix = "global";

export const RESET_ALL = `${actionsPrefix}/RESET_ALL`;
export const SHOW_GLOBAL_MESSAGE = `${actionsPrefix}/SHOW_GLOBAL_MESSAGE`;
export const HIDE_GLOBAL_MESSAGE = `${actionsPrefix}/HIDE_GLOBAL_MESSAGE`;
export const SET_GATED = `${actionsPrefix}/SET_GATED`;
export const SET_GATED_MESSAGE_TYPE = `${actionsPrefix}/SET_GATED_MESSAGE_TYPE`;
export const SET_GATED_MESSAGE_ORIGIN = `${actionsPrefix}/SET_GATED_MESSAGE_ORIGIN`;
export const SET_GATED_MESSAGE_REDIRECT = `${actionsPrefix}/SET_GATED_MESSAGE_REDIRECT`;
export const SET_GATED_MESSAGE_USE_MODALS = `${actionsPrefix}/SET_GATED_MESSAGE_USE_MODALS`;
export const RESET_GATED_MODAL = `${actionsPrefix}/RESET_GATED_MODAL`;
export const RESET_GATED_STATE = `${actionsPrefix}/RESET_GATED_STATE`;
export const APP_PROMPT_INTERACTED = `${actionsPrefix}/APP_PROMPT_INTERACTED`;
export const APP_PROMPT_HEIGHT_CHANGED = `${actionsPrefix}/APP_PROMPT_HEIGHT_CHANGED`;
export const SET_GATED_VIEW_COUNT = `${actionsPrefix}/SET_GATED_VIEW_COUNT`;
export const SET_EMAIL_MISMATCH_DETAILS = `${actionsPrefix}/SET_EMAIL_MISMATCH_DETAILS`;
export const CLEAR_EMAIL_MISMATCH_DETAILS = `${actionsPrefix}/CLEAR_EMAIL_MISMATCH_DETAILS`;
export const SET_GLOBAL_LOADING = `${actionsPrefix}/SET_GLOBAL_LOADING`;
export const PERFORM_SIGNOUT = `${actionsPrefix}/PERFORM_SIGNOUT`;
export const TOGGLE_LOGIN_MODAL = `${actionsPrefix}/TOGGLE_LOGIN_MODAL`;
export const TOGGLE_SIGNUP_MODAL = `${actionsPrefix}/TOGGLE_SIGNUP_MODAL`;
export const TOGGLE_EXTERNAL_MODAL = `${actionsPrefix}/TOGGLE_EXTERNAL_MODAL`;
export const SET_INITIAL_REFERRER = `${actionsPrefix}/SET_INITIAL_REFERRER`;

export const showGlobalMessage = (
  message,
  title = null,
  messageType = TOAST_TYPE.INFO,
  persist = false,
  error
) => {
  return async function (dispatch) {
    dispatch({
      type: SHOW_GLOBAL_MESSAGE,
      title,
      message,
      messageType,
      error,
      persist
    });
  };
};

export const showErrorMessage = (
  message,
  title = null,
  persist = false,
  error
) => {
  return showGlobalMessage(message, title, TOAST_TYPE.ERROR, persist, error);
};

export const showInfoMessage = (message, title = null, persist = false) => {
  return showGlobalMessage(message, title, TOAST_TYPE.INFO, persist);
};

export const showSuccessMessage = (message, title = null, persist = false) => {
  return showGlobalMessage(message, title, TOAST_TYPE.SUCCESS, persist);
};

export const hideGlobalMessage = () => {
  return async function (dispatch) {
    dispatch({
      type: HIDE_GLOBAL_MESSAGE
    });
  };
};

export const setGatedState = (isGated, isSponsored) => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED,
      isGated,
      isSponsored
    });
  };
};

export const clearGatedState = () => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED,
      isGated: false,
      isSponsored: false
    });
  };
};

export const setGatedMessageType = (messageType) => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_TYPE,
      messageType
    });
  };
};

export const clearGatedMessageType = () => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_TYPE,
      messageType: null
    });
  };
};

export const setGatedMessageOrigin = (origin) => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_ORIGIN,
      origin
    });
  };
};

export const clearGatedMessageOrigin = () => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_ORIGIN,
      origin: null
    });
  };
};

export const setGatedMessageRedirect = (redirect) => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_REDIRECT,
      redirect
    });
  };
};

export const clearGatedMessageRedirect = () => {
  return async function (dispatch) {
    dispatch({
      type: SET_GATED_MESSAGE_REDIRECT,
      redirect: null
    });
  };
};

export const resetGatedModal = () => {
  return (dispatch) => {
    dispatch({ type: RESET_GATED_MODAL });
  };
};

export const resetGatedState = () => {
  return (dispatch) => {
    dispatch({ type: RESET_GATED_STATE });
  };
};

export const interactAppPrompt = (isExpanded) => {
  return {
    type: APP_PROMPT_INTERACTED,
    isExpanded
  };
};

export const setAppPromptHeight = (height) => {
  return {
    type: APP_PROMPT_HEIGHT_CHANGED,
    height
  };
};

export const tickUngatedViewCount = () => {
  return (dispatch) => {
    dispatch({
      type: SET_GATED_VIEW_COUNT
    });
  };
};

export const setGlobalLoading = (loading) => {
  return (dispatch) => {
    dispatch({
      type: SET_GLOBAL_LOADING,
      loading
    });
  };
};

export const setEmailMismatchModal = (showModal, groupFilterUuid) => {
  return async (dispatch) => {
    dispatch({
      type: SET_EMAIL_MISMATCH_DETAILS,
      emailMismatchModal: {
        showModal,
        groupFilterUuid
      }
    });
  };
};

export const clearEmailMismatchModal = () => {
  return async (dispatch) => {
    dispatch({
      type: CLEAR_EMAIL_MISMATCH_DETAILS,
      emailMismatchModal: {
        showModal: false,
        groupFilterUuid: ""
      }
    });
  };
};

export const performSignout = (perform) => {
  return (dispatch) => {
    dispatch({
      type: PERFORM_SIGNOUT,
      perform
    });
  };
};

export const toggleLoginModal = (visible) => {
  return async function (dispatch) {
    dispatch({
      type: TOGGLE_LOGIN_MODAL,
      visible
    });
  };
};

export const toggleSignUpModal = (visible) => {
  return async function (dispatch) {
    dispatch({
      type: TOGGLE_SIGNUP_MODAL,
      visible
    });
  };
};

export const toggleExternalLinkModal = (visible, link) => {
  return async function (dispatch) {
    dispatch({
      type: TOGGLE_EXTERNAL_MODAL,
      visible,
      link: link || null
    });
  };
};

export const setInitialReferrer = (value) => {
  return async function (dispatch) {
    dispatch({
      type: SET_INITIAL_REFERRER,
      value
    });
  };
};
