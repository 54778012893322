import React from "react";
import { useSelector } from "react-redux";
import Card from "reactstrap/lib/Card";
import i18n from "../../../utils/i18n";
import LoadingButton from "../../../components/common/LoadingButton";
import { FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import { checkNpiNumber } from "../../../utils/form-utils";

import verificationBadge from "../../../assets/images/reg_flow_verification_web.svg";
import { StaticStrings } from "../../../constants/static-string-constants";
import ExternalTextLink from "./ExternalTextLink";
import FormGlobalError from "./FormGlobalError";
import { isMobile } from "react-device-detect";

const VerificationNPIForm = ({
  isLoading,
  onSubmit,
  errors,
  control,
  updateMode = false
}) => {
  const npiFetchError = useSelector((state) => {
    return state.userVerification.error ? state.userVerification.message : null;
  });

  return (
    <div className="mt-3 shadow">
      <Card className="p-3 p-lg-4 rounded-0">
        <div className="d-flex justify-content-between align-items-center mb-3">
          <div>
            <h2 className="d-flex text-18 helv-bold mb-1 align-items-center">
              {i18n.t("RegistrationScreens.verificationPage.v2.title")}
              {!updateMode && (
                <span className="text-10 bg-lighter-gray text-denim-blue px-2 py-1 rounded ml-2 text-uppercase helv-med">
                  {i18n.t("RegistrationScreens.verificationPage.v2.titleTag")}
                </span>
              )}
            </h2>
            <p className="text-14 color-battleship-gray mb-0">
              {!updateMode && (
                <>{i18n.t("RegistrationScreens.verificationPage.v2.byline")}</>
              )}
              {updateMode && (
                <>
                  {i18n.t(
                    "RegistrationScreens.verificationPage.v2.UpdateByline"
                  )}
                </>
              )}
            </p>
          </div>
          {!isMobile && <img src={verificationBadge} alt="" className="" />}
        </div>
        <form onSubmit={onSubmit}>
          <FormGlobalError errorText={npiFetchError} />

          <FormGroup className="mb-4">
            <Label for="npiNumber">
              {i18n.t("RegistrationScreens.verificationPage.v2.npiLabel")}
            </Label>
            <Controller
              className="mr-1"
              defaultValue={""}
              as={Input}
              control={control}
              id="npiNumber"
              name="npiNumber"
              type="text"
              placeholder={i18n.t(
                "RegistrationScreens.verificationPage.v2.npiPlaceholder"
              )}
              rules={{ required: true, validate: checkNpiNumber }}
              invalid={!!errors?.npiNumber}
            />
            <FormFeedback>
              {i18n.t("RegistrationScreens.verificationNpiPage.v2.npiInvalid")}
            </FormFeedback>
          </FormGroup>

          <p className="color-battleship-gray text-14">
            {i18n.t("RegistrationScreens.verificationNpiPage.findNPIText")}
            <ExternalTextLink
              className="text-cool-blue cursor-pointer"
              url={StaticStrings.npiRegistry}
              linkText={i18n.t(
                "RegistrationScreens.verificationNpiPage.findNPILinkText"
              )}
            />
          </p>

          <div className="text-center">
            <LoadingButton
              className="w-100 mt-2"
              color="primary"
              loading={isLoading}
              spinnerColor="white"
              disabled={isLoading}
              size="lg">
              {i18n.t("RegistrationScreens.verificationNpiPage.v2.btnSubmit")}
            </LoadingButton>
          </div>
        </form>
      </Card>
    </div>
  );
};

export default VerificationNPIForm;
