import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useHistory, useParams } from "react-router";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import { withOpenModalManagement } from "../../components/common/WithModalManagement";
import CaseDetailPage from "./CaseDetailPage";
import CaseDetailUpdateModal from "../../components/case/CaseDetailUpdateModal";
import { fetchCaseLabel } from "../../actions/case-label.actions";
import QueryPreserveRedirect from "../../components/common/QueryPreserveRedirect";
import { useIsCaseAuthor } from "../../hooks";
import WhatsNewModal from "../whatsnew/WhatsNewModal";

const CaseDetail = () => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const history = useHistory();
  const routeParams = useParams();
  const caseId = routeParams.caseId;
  const commentId = routeParams?.commentId;
  const questionIndex = routeParams.questionIndex;
  const position = routeParams.position;
  const caseData = useSelector((state) => state.case.cases[caseId]?.data);
  const isAuthor = useIsCaseAuthor(caseData);

  const caseDetailRoute = getUrl(ROUTES.CASE_DETAIL, { caseId });
  const caseCommentDetailRoute = getUrl(ROUTES.CASE_COMMENT_DETAIL, {
    caseId,
    commentId
  });

  const editCaseRoutes = useMemo(
    () => [
      {
        path: ROUTES.CASE_DETAIL_UPDATE,
        component: withOpenModalManagement(CaseDetailUpdateModal),
        props: { caseId, onClosed: () => history.replace(caseDetailRoute) }
      }
    ],
    [caseId, caseDetailRoute, history]
  );

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    dispatch(fetchCaseLabel());
  }, [dispatch]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <WhatsNewModal />
      <CaseDetailPage
        caseId={caseId}
        commentId={commentId}
        questionIndex={questionIndex}
        position={position}
      />
      {isAuthor ? (
        <Switch>
          {editCaseRoutes.map((route) => (
            <Route key={route.path} path={route.path}>
              <route.component {...route.props} />
            </Route>
          ))}
        </Switch>
      ) : (
        <QueryPreserveRedirect
          preserveQueryString={true}
          to={{
            pathname: commentId ? caseCommentDetailRoute : caseDetailRoute,
            state: {
              position
            }
          }}
        />
      )}
    </>
  );
};

export default CaseDetail;
