import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";
import THEMES from "../theme/Theme";
import useTheme from "../theme/useTheme";

const BackButton = ({ buttonText, className }) => {
  const history = useHistory();
  const initialReferrer = useSelector((state) => state.global?.initialReferrer);
  const [initialReferrerVal, setInitialReferrerVal] = useState(undefined);
  const { theme } = useTheme();

  useEffect(() => {
    if (initialReferrerVal === undefined) {
      setInitialReferrerVal(initialReferrer);
    }
  }, [initialReferrer, initialReferrerVal]);

  if (initialReferrerVal !== null) {
    return (
      <Button
        color={theme === THEMES.DARK ? "theme-dark" : "link"}
        className={classNames(className, "btn btn-md")}
        onClick={() => history.goBack()}>
        <div className="d-inline-flex flex-row align-items-center text-nowrap">
          <CustomIcon
            className={classNames(
              "rotate-180",
              theme === THEMES.DARK ? "icon-light-gray" : "icon-denim-blue"
            )}
            icon="drawer_chevron"
            size="12"
          />
          <div className="pl-1">
            {buttonText ? buttonText : i18n.t("common.back")}
          </div>
        </div>
      </Button>
    );
  }
  return null;
};

export default BackButton;
