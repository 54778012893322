import { useDispatch } from "react-redux";
import { toggleLoginModal } from "../actions/global.actions";

const useLoginModalClick = () => {
  const dispatch = useDispatch();

  const showLoginModal = (e) => {
    e?.preventDefault?.();
    e?.stopPropegation?.();

    dispatch(toggleLoginModal(true));
  };

  const hideLoginModal = (e) => {
    e?.preventDefault?.();
    e?.stopPropegation?.();

    dispatch(toggleLoginModal(false));
  };
  return { showLoginModal, hideLoginModal };
};

export default useLoginModalClick;
