import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import {
  Button,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "reactstrap";
import { trackGroupEvent, trackPageEntry } from "../../actions/metrics.actions";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";
import i18n from "../../utils/i18n";
import GroupDescriptionModal from "./GroupDescriptionModal";
import GroupMembersModal from "./GroupMembersModal";
import LeaveGroupModal from "./LeaveGroupModal";

const GroupOptionsDropdown = ({ currentGroup, showMembersModal }) => {
  /** ********************************** CONFIG ***************************************/
  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const [isDescriptionModalOpen, setDescriptionModalOpen] = useState(false);
  const [isMembersModalOpen, setMembersModalOpen] = useState(false);
  const [isLeaveModalOpen, setLeaveModalOpen] = useState(false);

  const classes = isMobile
    ? "dropdown-menu--right dropdown-menu--pointer-top-center"
    : "dropdown-menu--left dropdown-menu--pointer-top-right";
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (showMembersModal) {
      setMembersModalOpen(true);
    }
  }, [showMembersModal]);
  /** ********************************* FUNCTIONS *************************************/
  const toggleDropdown = () => {
    if (!isDropdownOpen) {
      trackGroupEvent({
        eventName: METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_ELLIPSES,
        groupUuid: currentGroup?.groupUuid
      });
    }
    setDropdownOpen((prevState) => !prevState);
  };
  const toggleDescriptionModal = () =>
    setDescriptionModalOpen((prevState) => !prevState);
  const toggleMembersModal = () =>
    setMembersModalOpen((prevState) => !prevState);
  const toggleLeaveModal = () => setLeaveModalOpen((prevState) => !prevState);
  /** ********************************** RENDER ***************************************/
  return (
    <Dropdown isOpen={isDropdownOpen} toggle={toggleDropdown}>
      <DropdownToggle
        className="dropdown-toggle-button"
        tag="span"
        aria-expanded={isDropdownOpen}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <Button className="ml-2 p-1">
            <CustomIcon
              className="icon-default-black"
              icon="ellipses"
              size={20}
            />
          </Button>
        </div>
      </DropdownToggle>
      <DropdownMenu className={`${classes} dropdown-menu-scrollable`} right>
        {!!currentGroup?.groupDescription && (
          <DropdownItem onClick={toggleDescriptionModal}>
            <div className="case-dropdown-item align-items-center">
              <CustomIcon
                className="icon-default-black"
                icon="groups_view-description"
                size={20}
              />
              <div className="case-dropdown-text-area helv-bold text-14">
                {i18n.t("GroupsFeed.Banner.viewDesc")}
              </div>
            </div>
          </DropdownItem>
        )}
        <DropdownItem onClick={toggleMembersModal}>
          <div className="case-dropdown-item align-items-center">
            <CustomIcon
              className="icon-default-black"
              icon="groups_view-members"
              size={20}
            />
            <div className="case-dropdown-text-area helv-bold text-14">
              {i18n.t("GroupsFeed.Banner.viewMem")}
            </div>
          </div>
        </DropdownItem>
        <DropdownItem onClick={toggleLeaveModal}>
          <div className="case-dropdown-item align-items-center">
            <CustomIcon icon="groups_leave" color="#ff3649" size={20} />
            <div className="case-dropdown-text-area helv-bold text-14">
              {i18n.t("GroupsFeed.Banner.leaveGroup")}
            </div>
          </div>
        </DropdownItem>
      </DropdownMenu>
      <GroupDescriptionModal
        isOpen={isDescriptionModalOpen}
        toggle={toggleDescriptionModal}
        customFooter={<></>}
      />
      <GroupMembersModal
        isOpen={isMembersModalOpen}
        toggle={toggleMembersModal}
        customFooter={<></>}
      />
      <LeaveGroupModal
        isOpen={isLeaveModalOpen}
        toggle={toggleLeaveModal}
        customFooter={<></>}
      />
    </Dropdown>
  );
};

export default GroupOptionsDropdown;
