import React, { useCallback, useEffect, useState } from "react";
import { trim } from "lodash";
import { Controller, useForm } from "react-hook-form";
import { Card, FormGroup, Input, Label, Spinner } from "reactstrap";
import { useDispatch } from "react-redux";
import LoadingButton from "../../../components/common/LoadingButton";
import LOCAL_STORAGE_CONSTANTS from "../../../constants/local-storage-constants";
import {
  getAndParseLocalStorageItem,
  resetLocalStorageItem
} from "../../../utils/local-storage-utils";
import i18n from "../../../utils/i18n";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { handleLoginLink } from "../../../actions/non-user.actions";
import { useLocation } from "react-router-dom";
import { LOGIN_LINK_PREFIX } from "../../../constants/routes";

const LoginWithLink = () => {
  /** ********************************** CONFIG ***************************************/
  const loc = useLocation();
  const loginUrl = `${LOGIN_LINK_PREFIX}/${loc.search}`;
  const dispatch = useDispatch();

  const { handleSubmit, control, watch } = useForm();
  const [error, setError] = useState("");
  const watchEmail = watch("email");

  const [cachedEmail, setCachedEmail] = useState(
    getAndParseLocalStorageItem(LOCAL_STORAGE_CONSTANTS.LOGIN_LINK_EMAIL)
  );
  const [isLoading, setIsLoading] = useState(false);

  const onboardingNavigate = useOnboardingNavigation();
  /** ********************************* HOOKS *************************************/
  useEffect(() => {
    const fetchCachedEmail = async () => {
      try {
        if (cachedEmail) {
          await loginWithEmail(cachedEmail);
          resetLocalStorageItem(LOCAL_STORAGE_CONSTANTS.LOGIN_LINK_EMAIL);
        } else {
          setCachedEmail(null);
        }
      } catch (e) {
        console.log("failed to login with cached email", e.message);
      }
    };

    fetchCachedEmail();
  }, []);
  /** ********************************* FUNCTIONS *************************************/
  const handleSubmitClick = async (values) => {
    loginWithEmail(values.email);
  };

  const loginWithEmail = useCallback(
    async (email) => {
      if (!loginUrl || !email) {
        return;
      }
      setIsLoading(true);

      const result = await dispatch(handleLoginLink({ loginUrl, email }));

      if (result.error) {
        setCachedEmail(null);
        setError(result.message);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        onboardingNavigate(result.user);
      }
    },
    [dispatch, loginUrl, onboardingNavigate]
  );
  /** ********************************** RENDER ***************************************/
  if (cachedEmail !== null) {
    return (
      <div className="w-100 vh-100 d-flex flex-column justify-content-center align-items-center">
        <Spinner style={{ width: "4rem", height: "4rem" }} color="primary" />
      </div>
    );
  }

  return (
    <>
      <h1 className="helv-bold text-center text-22 line-height-28 mb-2">
        {i18n.t("RegistrationScreens.loginWithLinkForm.title")}
      </h1>
      <p className="text-center text-blue-gray text-16 line-height-26">
        {i18n.t("RegistrationScreens.loginWithLinkForm.description")}
      </p>
      <Card className="px-4 py-3 mt-4 rounded-0 shadow">
        {error && (
          <p className="m-0 p-1 rounded bg-alert-red text-center text-coral text-14 line-height-20">
            {error}
          </p>
        )}
        <form className="mt-3" onSubmit={handleSubmit(handleSubmitClick)}>
          <FormGroup>
            <Label for="email">
              {i18n.t("RegistrationScreens.loginWithLinkForm.emailAddress")}
            </Label>
            <Controller
              as={Input}
              control={control}
              id="email"
              name="email"
              placeholder={i18n.t(
                "RegistrationScreens.loginWithLinkForm.enterHere"
              )}
              rules={{ required: true }}
            />
          </FormGroup>
          <div className="d-flex align-items-center mt-4">
            <LoadingButton
              loading={isLoading}
              disabled={!trim(watchEmail)}
              spinnerColor="white"
              type="submit"
              className="w-100 mt-3 mb-3"
              size="lg"
              color="primary">
              {i18n.t("RegistrationScreens.loginForm.btnSignIn")}
            </LoadingButton>
          </div>
        </form>
      </Card>
    </>
  );
};

export default LoginWithLink;
