import { useDispatch, useSelector } from "react-redux";
import { showSuccessMessage } from "../actions/global.actions";
import { trackCopyLink, trackShare } from "../actions/metrics.actions";
import useIsGated from "./useIsGated";
import i18n from "../utils/i18n";
import { caseAuthor } from "../utils/user-utils";
import { Source, StaticStrings } from "../constants/static-string-constants";
import { getUrl } from "../utils/route-utils";
import { isMobile } from "react-device-detect";

const useShareCase = ({ caseContent, location, context }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const isGated = useIsGated();
  const commentStatus = useSelector(
    (state) => state.comment.caseComments?.[caseContent?.caseUuid]
  );

  const copyLink = () => {
    const workaroundLink = StaticStrings.fig1;
    const defaultShareLink =
      caseContent?.shareLink &&
      caseContent?.shareLink.replace("/case-detail/", "/cases/");
    const shareLink =
      (defaultShareLink &&
        getUrl(`${defaultShareLink}${StaticStrings.caseShareLinkParams}`, {
          CASE_UUID: caseContent?.caseUuid,
          SOURCE: isMobile ? Source.MOBILE_WEB : Source.WEB,
          USER_UUID: user?.userUuid
        })) ||
      workaroundLink;

    // Workaround for the occasions when the shareLink has the wrong url for whatever reason
    navigator.clipboard.writeText(shareLink);

    dispatch(showSuccessMessage(i18n.t("CaseAction.linkCopied")));
    trackCopyLink({ caseContent, ungated: isGated });
  };

  const shareCase = () => {
    trackShare({
      caseContent: caseContent,
      commentStatus: commentStatus,
      context,
      isAuthor: caseAuthor(caseContent)?.userUuid === user?.userUuid,
      via: "CopyToPasteboard",
      location,
      ungated: isGated
    });
  };

  return { copyLink, shareCase };
};

export default useShareCase;
