import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";

import ISILayout from "../../components/isi/ISILayout";
import { PROFILE_DETAIL } from "../../constants/routes";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import { trackCaseCMEFrontMatterEntry } from "../../actions/metrics.actions";

import CMEImage from "../../assets/images/CME bottom sheet.svg";
import "./CaseCMEFrontMatter.scss";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { useSelectCurrentUser } from "../../selectors/user.selectors";
import BackButton from "../../components/common/BackButton";

const CaseCMEIneligible = ({ caseId }) => {
  /** ********************************** CONFIG ***************************************/

  const user = useSelectCurrentUser();

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    trackCaseCMEFrontMatterEntry(caseId);
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <ISILayout enableGatedBanner>
      <Figure1Page3Col
        headerClass="flex-sm-column-reverse flex-lg-row pt-3 pt-lg-3"
        mainContent={
          <>
            <BackButton
              buttonText={i18n.t("CaseCMEIneligible.backButton")}
              className="pl-0"
            />
            <Card className="border-0 mt-2">
              <CardBody className="text-center px-5 py-4">
                <img src={CMEImage} alt="" className="w-125px mb-4" />
                <h1 className="text-21 helv-bold mb-3">
                  {i18n.t("CaseCMEIneligible.title")}
                </h1>
                <p className="text-15 text-battleship-gray">
                  {i18n.t("CaseCMEIneligible.question")}{" "}
                  <Link
                    to={getUrl(PROFILE_DETAIL, { userUuid: user.userUuid })}
                    className="d-inline-flex align-items-center">
                    {i18n.t("CaseCMEIneligible.questionLink")}{" "}
                    <CustomIcon
                      icon="web_chevron"
                      className="icon-denim-blue"
                      size="13"
                    />
                  </Link>
                </p>
              </CardBody>
            </Card>
          </>
        }
      />
    </ISILayout>
  );
};

export default CaseCMEIneligible;
