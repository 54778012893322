import React, { useState } from "react";
import { Button, Card, CardBody } from "reactstrap";
import { trackInviteColleaguesStart } from "../../../actions/metrics.actions";
import CircleCustomIcon from "../../../components/custom-icon/CircleCustomIcon";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { StaticStrings } from "../../../constants/static-string-constants";
import { useSelectCurrentUser } from "../../../selectors/user.selectors";
import i18n from "../../../utils/i18n";
import { getFullUrl } from "../../../utils/route-utils";
import InviteColleaguesModal from "../../profile/components/InviteColleaguesModal";

const InviteColleaguesCard = () => {
  /** ********************************** CONFIG ***************************************/
  const [modalOpen, setModalOpen] = useState(false);
  const user = useSelectCurrentUser();
  const inviteColleagesLink = getFullUrl(
    StaticStrings.inviteColleagesProfileUrl,
    {
      userUuid: user?.userUuid,
      USER_UUID: user?.userUuid
    }
  );
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };
  const openModal = () => {
    trackInviteColleaguesStart();
    setModalOpen(true);
  };
  /** ********************************** RENDER ***************************************/

  return (
    <Card className="border-outline-gray">
      <CardBody className="d-flex align-items-center justify-content-between">
        <CircleCustomIcon
          circleColor="lighter-gray-2"
          icon="who_can_join"
          innerClassName="mb-1"
          size={46}
          className="p-2 mr-2"
        />
        <div>
          <p className="m-0 helv-bold text-14 line-height-18">
            {i18n.t("InviteColleaguesCard.title")}
          </p>
          <Button
            className={
              "mt-2 text-13 text-left line-height-21 p-0 text-link-color"
            }
            color="link"
            onClick={openModal}>
            {i18n.t("InviteColleaguesCard.linkText")}
            <CustomIcon
              color="link-color"
              icon="drawer_draft_chevron"
              size={13}
            />
          </Button>
        </div>
      </CardBody>
      {modalOpen && (
        <InviteColleaguesModal
          isOpen={modalOpen}
          toggle={toggleModal}
          linkToCopy={inviteColleagesLink}
        />
      )}
    </Card>
  );
};

export default InviteColleaguesCard;
