/** @format */

import React, { useState } from "react";
import { Button } from "reactstrap";
import { Constants } from "@figure1/f1-pro-fe-brain";
import i18n from "../../../utils/i18n";
import CMEPostNoticeModal from "./CMEPostNoticeModal";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const CERTIFICATE_STATUS = Constants.CmeConstants.CERTIFICATE_STATUS;

const CertDownloadButton = ({ activity, downloadCert }) => {
  /****************************** Config **************************************/
  const status = activity?.certificateStatus || CERTIFICATE_STATUS.INELIGIBLE;
  const downloadUrl = activity.certificateDownloadUrl;
  const ineligible = status === CERTIFICATE_STATUS.INELIGIBLE;
  const certAvailable =
    !!downloadUrl && status === CERTIFICATE_STATUS.COMPLETED;

  const [showNoCertModal, setShowNoCertModal] = useState(false);
  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/
  const toggleNoCertModal = (e) => {
    if (e) {
      e.persist();
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }
    setShowNoCertModal((prevState) => !prevState);
  };

  /****************************** Render **************************************/
  return (
    <>
      {ineligible ? (
        <>
          <span
            className="helv-med font-italic text-13 text-muted"
            onClick={toggleNoCertModal}>
            {i18n.t("CMEScreens.noCertAvailable")}
            <CustomIcon
              className="icon-battleship-gray ml-2"
              icon="contact_support"
              size={16}
            />
          </span>
          <CMEPostNoticeModal
            isOpen={showNoCertModal}
            toggle={toggleNoCertModal}
          />
        </>
      ) : certAvailable ? (
        <Button
          color="primary"
          className="helv-bold text-13 bg-denim-blue color-white px-2 py-1 h-28px"
          onClick={downloadCert}>
          {i18n.t("CMEScreens.downloadThis")}
        </Button>
      ) : (
        <span className="helv-med font-italic text-13 text-muted">
          {i18n.t("CMEScreens.generatingCerti")}
        </span>
      )}
    </>
  );
};

CertDownloadButton.propTypes = {};

export default CertDownloadButton;
