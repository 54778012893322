/** @format */

import React from "react";
import ReactMarkdown from "react-markdown";
import moment from "moment";

const ActivityItemSummary = ({ longMessage, markdownMessage, createdAt }) => {
  return (
    <>
      <div className="notif-markdown">
        <ReactMarkdown className="whitespace-pre-wrap">
          {markdownMessage || longMessage}
        </ReactMarkdown>
      </div>
      <div className="mb-0 text-muted">{moment(createdAt).fromNow(true)}</div>
    </>
  );
};

export default ActivityItemSummary;
