import { useCallback } from "react";
import { trackWhoCanJoinModalEntry } from "../actions/metrics.actions";
import useDialog from "../components/dialog/useDialog";
import i18n from "../utils/i18n";

export function useWhoCanJoinDialog() {
  const { inform } = useDialog();

  const handleContactSupportClick = useCallback(async () => {
    trackWhoCanJoinModalEntry();
    await inform({
      body: (
        <div className="pr-3">
          <h5 className="helv-bold text-18">
            {i18n.t("RegistrationScreens.whoCanJoinModal.title")}
          </h5>
          <p className="text-15">
            {i18n.t("RegistrationScreens.whoCanJoinModal.description")}
          </p>
          <ul className="check-list">
            <li className="text-16 m-0 mb-3">
              {i18n.t("RegistrationScreens.whoCanJoinModal.point1")}
            </li>
            <li className="text-16 m-0 mb-3">
              {i18n.t("RegistrationScreens.whoCanJoinModal.point2")}
            </li>
            <li className="text-16 m-0 mb-3">
              {i18n.t("RegistrationScreens.whoCanJoinModal.point3")}
            </li>
          </ul>
        </div>
      ),
      extra: { icon: "who_can_join", iconSize: 70 }
    });
  }, [inform]);

  return handleContactSupportClick;
}
