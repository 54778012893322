import React, { useEffect, useState } from "react";
import F1AvatarLink from "../../common/F1AvatarLink";
import CommentHeader from "../CommentHeader";
import { useCommentTranslation } from "../../../hooks";
import { injectUserTag } from "../../../utils/comment-utils";
import F1Badge from "../../common/F1Badge";
import i18n from "../../../utils/i18n";

const AcceptedComment = ({
  caseUuid,
  comment = {},
  currentAcceptedAnswer = false,
  newAcceptedAnswer = false
}) => {
  /** ********************************** CONFIG ***************************************/
  const [injectedCommentText, setInjectedCommentText] = useState("");

  const { text } = useCommentTranslation(caseUuid, comment.commentUuid);
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    setInjectedCommentText(injectUserTag(text));
  }, [text]);
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const avatar = <F1AvatarLink user={comment.author ?? comment} size="sm" />;
  return (
    <>
      <div className="d-flex">
        {avatar}
        <div className="w-100 ml-2 d-flex align-items-center text-15">
          <CommentHeader
            comment={comment}
            reply
            showEdited={false}
            showAcceptedAnswerBadge={false}
          />
        </div>
      </div>
      {(currentAcceptedAnswer || newAcceptedAnswer) && (
        <div className="d-flex mt-1">
          <F1Badge
            pill={false}
            textColor={currentAcceptedAnswer ? "default-black" : "white"}
            color={currentAcceptedAnswer ? "lighter-gray" : "battleship-gray"}
            name={
              currentAcceptedAnswer
                ? i18n.t("comments.currentAcceptedAnswer")
                : i18n.t("comments.newAcceptedAnswer")
            }
            className={"d-flex align-items-center text-13 helv-med"}
          />
        </div>
      )}
      <div className="mt-2 text-15 text-clamp-20-3 text-left">
        {injectedCommentText}
      </div>
    </>
  );
};

export default AcceptedComment;
