import React from "react";
import { useSelector } from "react-redux";
import i18n from "../../../utils/i18n";
import { isMobile } from "react-device-detect";
import "../GroupWelcomeSplashPage.scss";
import { Button, Card } from "reactstrap";
import LinesEllipsis from "react-lines-ellipsis";

const SelfFormingSplashPage = ({ onContinueClick }) => {
  /** ********************************** CONFIG ***************************************/
  const selectedGroup = useSelector((state) => state.groups?.currentGroup);
  const groupName = selectedGroup?.groupName || "";
  const groupDescription = selectedGroup?.groupDescription || "";
  /** ********************************* HOOKS *************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <h1
        className={`helv-standerd mb-4 ${
          isMobile ? "text-left" : "text-center"
        }`}>
        {i18n.t(
          "RegistrationScreens.groupWelcomeSplashPage.self-forming.title"
        )}
        <br />
        {i18n.t(
          "RegistrationScreens.groupWelcomeSplashPage.self-forming.group"
        )}
      </h1>

      <Card
        className={`selfGroupDetailBox bg-darker-blue color-white my-4 p-4 ${
          isMobile ? " w-85" : "w-400px"
        }`}>
        <div className="mb-3">
          <label className="text-14 line-height-21">
            {i18n.t(
              "RegistrationScreens.groupWelcomeSplashPage.self-forming.groupName"
            )}
          </label>
          <h2 className="helv-med text-18 line-height-21">{groupName}</h2>
        </div>
        <div>
          <label className="text-14 line-height-21">
            {i18n.t(
              "RegistrationScreens.groupWelcomeSplashPage.self-forming.description"
            )}
          </label>
          <LinesEllipsis
            className="helv-med text-18 line-height-21"
            text={groupDescription}
            maxLine="2"
            ellipsis="..."
            trimRight
            basedOn="words"
          />
        </div>
      </Card>

      <div className="d-flex mt-2 w-100 justify-content-center">
        <Button
          className="mt-4 w-295px"
          color="light"
          size="lg"
          onClick={onContinueClick}>
          {i18n.t("RegistrationScreens.groupWelcomeSplashPage.btnGetStarted")}
        </Button>
      </div>
    </>
  );
};

export default SelfFormingSplashPage;
