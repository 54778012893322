import React from "react";
import { useHistory } from "react-router-dom";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import i18n from "../../../utils/i18n";

const GroupOption = ({ url, name, membersCount, isActive, closeModal }) => {
  /************************************ CONFIG ***************************************/
  const history = useHistory();
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const goToGroupFeed = () => {
    closeModal();
    history.replace(url);
  };

  /************************************ RENDER ***************************************/
  return (
    <div
      onClick={goToGroupFeed}
      className="d-flex justify-content-between align-item-center bg-white py-3">
      <div className="text-left">
        <h2 className="helv-med text-16 mb-1 line-height-25">{i18n.t(name)}</h2>
        <h3 className="text-14 m-0">
          <span className="helv-bold">{i18n.t(membersCount)}</span>{" "}
          <span className="text-muted">
            {i18n.t("GroupsFeed.Banner.noOfMembers", {
              count: membersCount
            })}
          </span>
        </h3>
      </div>
      <div className="d-flex flex-row align-self-center">
        <CustomIcon icon={isActive ? "web_check" : "web_chevron"} size={20} />
      </div>
    </div>
  );
};

export default GroupOption;
