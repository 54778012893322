import React from "react";
import CustomIcon from "../custom-icon/CustomIcon";

const CaseTagButton = ({ labelItem, handleClick }) => {
  const color = labelItem?.active ? "denim-blue" : "default-black";
  const iconName = labelItem?.active ? "feed_follow_check" : "plus";
  const buttonClass = labelItem?.active ? "active-button" : "inactive-button";
  return (
    <button
      className={`d-flex justify-content-between align-items-center mb-2 p-2 mr-2 btn ${buttonClass}`}
      onClick={(e) => {
        e.preventDefault();
        handleClick(labelItem.key);
      }}>
      <CustomIcon icon={iconName} className={`mr-1 icon-${color}`} size={14} />

      <span className={!labelItem.active ? `ml-0` : undefined}>
        {labelItem.name}
      </span>
    </button>
  );
};

export default CaseTagButton;
