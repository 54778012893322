/** @format */

import React from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import AppPrompt from "../../components/app-prompt/AppPrompt";
import NotificationsDetailsPage from "./NotificationsDetailsPage";

const Notifications = () => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ****************************************/
  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPrompt />}>
      <NotificationsDetailsPage />
    </Figure1Layout>
  );
};

export default Notifications;
