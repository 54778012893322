import { useEffect } from "react";
import { Button } from "reactstrap";
import { trackSettingsDeleteAccountEntry } from "../../../../actions/metrics.actions";
import CustomIcon from "../../../../components/custom-icon/CustomIcon";
import BaseDialogFooter from "../../../../components/dialog/BaseDialogFooter";
import Dialog from "../../../../components/dialog/Dialog";
import DIALOG_TYPE from "../../../../components/dialog/DialogType";
import i18n from "../../../../utils/i18n";

const DeleteAccountDialog = ({
  username,
  isOpen,
  onDeleteAccount,
  onClosed
}) => {
  /************************************ CONFIG ***************************************/
  const messages = [
    i18n.t("Settings.Account.deleteInfoText1"),
    i18n.t("Settings.Account.deleteInfoText2"),
    i18n.t("Settings.Account.deleteInfoText3"),
    i18n.t("Settings.Account.deleteInfoText4")
  ];

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    if (isOpen) {
      trackSettingsDeleteAccountEntry();
    }
  }, [isOpen]);

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/
  return (
    <Dialog
      isOpen={isOpen}
      type={DIALOG_TYPE.INFORM}
      body={
        <div>
          <h5 className="helv-bold text-22 my-4">
            {i18n.t("Settings.Account.deleteConfirmation", { username })}
          </h5>
          <ul className="list-unstyled mb-0">
            {messages.map((message) => (
              <li
                key={message}
                className="d-inline-flex align-items-start text-16 line-height-18 m-0 mb-4">
                <CustomIcon
                  className="icon-coral flex-shrink-0 mr-2"
                  icon="clear_search_x"
                  size={18}
                />
                <span>{message}</span>
              </li>
            ))}
          </ul>
        </div>
      }
      customFooter={
        <BaseDialogFooter>
          <Button
            className={"text-16 text-bold px-5 py-2 mx-auto"}
            block
            size="lg"
            outline
            color="coral"
            onClick={onDeleteAccount}>
            {i18n.t("Settings.Account.deleteAccount")}
          </Button>
          <Button
            className={"text-16 text-bold px-5 py-2 mx-auto mt-3 btn-text"}
            block
            size="lg"
            color="battleship-gray"
            onClick={onClosed}>
            {i18n.t("Settings.Account.cancelButton")}
          </Button>
        </BaseDialogFooter>
      }
      toggle={onClosed}
      onCancel={onClosed}
      onClosed={onClosed}
    />
  );
};

export default DeleteAccountDialog;
