/** @format */

export const VERIFICATION_STATUS = {
  PENDING_MANUAL: "pending_manual_verification",
  UNKNOWN: "unknown",
  VERIFIED: "verified",
  INFO_NEEDED: "info_needed",
  UNVERIFIABLE: "unverifiable",
  UPDATED_INFO: "updated_info",
  DUPLICATE_NPI: "duplicate_npi",
  CHANGE_REQUESTED: "change_requested"
};

export default VERIFICATION_STATUS;
