import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch, useLocation } from "react-router-dom";

import * as ROUTES from "../constants/routes";
import { userSignedIn } from "../utils/route-utils";
import LogoutPage from "./authentication/LogoutPage";
import RegistrationLoginPage from "./authentication/LoginPage";
import NotFoundPage from "./authentication/404Page";
import { setRedirectRoute } from "../actions/login.actions";
import LOCAL_STORAGE_CONSTANTS from "../constants/local-storage-constants";
import QueryPreserveRedirect from "../components/common/QueryPreserveRedirect";
import CreateRegistrationPageV2 from "./authentication/CreateRegistrationPage.v2";

const UnauthenticatedApp = () => {
  const isSignedIn = useSelector((state) => userSignedIn(state.user));
  const dispatch = useDispatch();
  // they're not signed in, but they _do_ have an auth token
  const authExists = !!localStorage.getItem(LOCAL_STORAGE_CONSTANTS.AUTH_USER);

  const loc = useLocation();
  const pathname = loc?.state?.from?.pathname;

  useEffect(() => {
    if (pathname) {
      dispatch(setRedirectRoute(pathname));
    }
  }, [dispatch, loc, pathname]);

  const reRouteToOldRoute = () => {
    // when routed to here when not logged in, we have a history of the target page
    // so fetch it and when they're logged in, we'll kick em back there before they get
    // routed home.
    const oldRoute = pathname;

    if (authExists || isSignedIn) {
      if (oldRoute) {
        return (
          <QueryPreserveRedirect preserveQueryString={true} to={oldRoute} />
        );
      } else {
        return (
          <QueryPreserveRedirect
            preserveQueryString={true}
            to={ROUTES.HOME_ROOT}
          />
        );
      }
    }
    return null;
  };

  const conditionallyRenderDefaultLogin = (defaultRoute) => {
    return authExists || isSignedIn ? (
      <QueryPreserveRedirect preserveQueryString={true} to={ROUTES.HOME_ROOT} />
    ) : (
      <QueryPreserveRedirect preserveQueryString={true} to={defaultRoute} />
    );
  };

  const conditionallyRenderDefaultOrPage = (pageComponent) => {
    const oldRoute = reRouteToOldRoute();

    return oldRoute ? oldRoute : pageComponent;
  };

  return (
    <Switch>
      <Route exact path={ROUTES.ROOT}>
        {conditionallyRenderDefaultLogin(ROUTES.REGISTRATION)}
      </Route>

      <Route exact path={ROUTES.SIGN_IN}>
        {conditionallyRenderDefaultOrPage(<RegistrationLoginPage />)}
      </Route>

      <Route exact path={ROUTES.SIGN_UP}>
        {conditionallyRenderDefaultOrPage(<CreateRegistrationPageV2 />)}
      </Route>

      <Route exact path={ROUTES.SIGN_OUT}>
        <LogoutPage />
      </Route>
      <Route path="*">
        <NotFoundPage />
      </Route>
    </Switch>
  );
};

export default UnauthenticatedApp;
