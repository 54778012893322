import React, { useEffect, useRef } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { fetchProfessionSpecialtiesIfNeeded } from "../../../../actions/reference.actions";
import i18n from "../../../../utils/i18n";
import { includesInput } from "../../../../utils/search-utils";
import useProfileLogic from "../../hooks/useProfileLogic";

const SecondarySpecialtiesForm = ({ profileData }) => {
  const specialtiesRef = useRef([]);
  const dispatch = useDispatch();

  const specialties = useSelector((state) => {
    if (
      state.references.professionSpecialties[profileData?.professionUuid] &&
      !specialtiesRef.current.length
    ) {
      specialtiesRef.current = state.references.professionSpecialties[
        profileData?.professionUuid
      ]
        .filter((specialty) => !!specialty.specialty)
        .sort((a, b) => {
          if (a.onboardingDisplayName < b.onboardingDisplayName) return -1;
          if (a.onboardingDisplayName > b.onboardingDisplayName) return 1;
          return 0;
        });
    }

    return specialtiesRef.current;
  });

  const flattenedSpecialties = profileData?.secondarySpecialties
    ?.map((specialty) => specialty.onboardingDisplayName)
    .join(", ");

  const { actionBar, editMode, readOnlyBar, form } = useProfileLogic();
  const ActionBarComponent = actionBar();
  const ReadOnlyBarComponent = readOnlyBar(
    flattenedSpecialties,
    "Settings.Profile.addSecondarySpecialtiesCTA"
  );

  useEffect(() => {
    dispatch(fetchProfessionSpecialtiesIfNeeded(profileData?.professionUuid));
  }, [dispatch, profileData?.professionUuid]);

  if (!specialties.length) {
    return null;
  }

  if (editMode) {
    return (
      <>
        <FormGroup>
          <Label for="specialties">
            {i18n.t("Profile.secondarySpecialtiesLabel")}
          </Label>
          <Controller
            control={form.control}
            id="specialties"
            name="specialties"
            defaultValue={profileData.secondarySpecialties}
            as={
              <Select
                isMulti
                options={specialties}
                placeholder={i18n.t("Profile.secondarySpecialtiesPlaceholder")}
                getOptionLabel={(option) => option.onboardingDisplayName}
                getOptionValue={(option) => option.treeUuid}
                filterOption={includesInput}
              />
            }
          />
          <FormFeedback>
            {i18n.t("Profile.secondarySpecialtiesError")}
          </FormFeedback>
        </FormGroup>
        {ActionBarComponent}
      </>
    );
  } else {
    return (
      <div className="mb-2">
        <h3 className="label text-14 helv-med">
          <span>{i18n.t("Profile.secondarySpecialtiesLabel")}</span>{" "}
        </h3>
        {ReadOnlyBarComponent}
      </div>
    );
  }
};

export default SecondarySpecialtiesForm;
