import React from "react";
import PropTypes from "prop-types";
import i18n from "../../utils/i18n";
import { Button, CardBody, CardText } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import "./ShareCaseBanner.scss";

const ShareCaseBanner = ({ isPoll, onShareClick }) => {
  /************************************ CONFIG ***************************************/
  const message = i18n
    .t("CaseAction.sharePostMessage2")
    // eslint-disable-next-line no-template-curly-in-string
    .replace("${TYPE}", isPoll ? "poll" : "quiz");

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <CardBody className="share-case-banner d-inline-flex justify-content-between align-items-center w-100 rounded-lg">
      <div className="d-flex justify-content-start align-items-center">
        <CustomIcon className="m-0" icon="share_arrow_circle" size={34} />
        <CardText className="ml-2 mb-0 text-16 font-weight-bold">
          {message}
        </CardText>
      </div>
      <Button
        className="flex-shrink-0 px-4 font-weight-bold"
        color="denim-blue"
        onClick={onShareClick}>
        {i18n.t("CaseAction.sharePost")}
      </Button>
    </CardBody>
  );
};

ShareCaseBanner.propTypes = {
  isPoll: PropTypes.bool,
  onShareClick: PropTypes.func
};

export default ShareCaseBanner;
