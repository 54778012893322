import React, { useRef } from "react";
import { useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { trackCaseCMECTAClick } from "../../actions/metrics.actions";
import { CASE_CME_FRONTMATTER } from "../../constants/routes";
import { tooltipScreens } from "../../constants/local-storage-constants";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import useIsCaseCMECompleted from "../../hooks/useIsCaseCMECompleted";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import CustomIcon from "../custom-icon/CustomIcon";
import useDialog from "../dialog/useDialog";
import ProvisionalMemberCMEMessage from "../provisional-member-message/ProvisionalMemberCMEMessage";
import CaseCMECTAModalContents from "./CaseCMECTAModalContents";
import GetCreditsTooltip from "./get-credits-tooltip/GetCreditsTooltip";

const CaseCMEMobileCTA = ({ caseContent, className }) => {
  /** ********************************** CONFIG ***************************************/
  const cmeCompleted = useIsCaseCMECompleted(caseContent?.caseUuid);
  const { inform } = useDialog();
  const history = useHistory();
  const tooltipRef = useRef(null);
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const onBtnClick = async () => {
    trackCaseCMECTAClick(caseContent?.caseUuid, false);
    const result = await inform({
      body: <CaseCMECTAModalContents />,
      primaryButtonText: i18n.t("CaseCMECTA.modal.startBtn"),
      extra: {
        confirmClassName: "w-295px text-16 py-3"
      }
    });

    if (result) {
      history.push(
        getUrl(CASE_CME_FRONTMATTER, { caseId: caseContent.caseUuid })
      );
    }
  };

  // commented out for now as feature descoped for this release -- Corey
  // const onSummaryBtnClick = () => {
  //   // nothing for now
  // };

  const verifiedOnBtnClick = useVerifiedUserGate(
    onBtnClick,
    <ProvisionalMemberCMEMessage />
  );
  /** ********************************** RENDER ***************************************/

  const cmeCTA = (
    <>
      <div ref={tooltipRef}>
        <Button
          onClick={verifiedOnBtnClick}
          color="primary"
          className={`mr-1 helv-bold d-flex flex-col align-items-center justify-content-start ${className} px-2`}>
          <CustomIcon
            icon="webnav_education_unselected"
            size={22}
            color="white"
          />{" "}
          <span className="ml-1">{i18n.t("CaseCMECTA.mobileBtnText")}</span>
        </Button>
      </div>
      <GetCreditsTooltip
        screen={tooltipScreens.CASE_DETAIL}
        target={tooltipRef}
        placement={"bottom"}
      />
    </>
  );

  // commented out for now as feature descoped for this release -- Corey
  // const completedCmeCTA = (
  //   <Button
  //     onClick={onSummaryBtnClick}
  //     color="light-gray"
  //     className={`mr-1 helv-bold d-flex flex-col align-items-center justify-content-start ${className}`}>
  //     <CustomIcon icon="web_check" size={18} color="green" />{" "}
  //     <span className="ml-1">{i18n.t("CaseCMECTA.mobileSummaryBtnText")}</span>
  //   </Button>
  // );

  return (
    <>
      {caseContent && (
        <>
          {/* 
          commented out for now as feature descoped for this release -- Corey
          {cmeCompleted && <>{completedCmeCTA}</>} */}
          {!cmeCompleted && <>{cmeCTA}</>}
        </>
      )}
    </>
  );
};

export default CaseCMEMobileCTA;
