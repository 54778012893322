import {
  InitialReducerStates,
  Reducers as BrainReducers
} from "@figure1/f1-pro-fe-brain";
import {
  CACHE_GROUP_UUID,
  RESET_GROUP_UUID_CACHE
} from "../actions/groups.actions";

const groupsReducer = (
  state = InitialReducerStates.GROUP_INITIAL_STATE,
  action
) => {
  const base = { ...BrainReducers.GroupsReducer(state, action) };

  switch (action.type) {
    case CACHE_GROUP_UUID:
      return { ...state, cachedUuid: action.payload?.groupUuid };
    case RESET_GROUP_UUID_CACHE:
      return { ...state, cachedUuid: null };

    default:
      return base;
  }
};

export default groupsReducer;
