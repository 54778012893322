import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { hideGlobalMessage } from "../../actions/global.actions";
import Toast from "./Toast";
import TOAST_TYPE from "../popup/ToastType";

const TOAST_TRANSITION_DURATION = 200;
const TOAST_BLINK_DURATION = 300;

const ToastContainer = (props) => {
  /************************************ CONFIG ***************************************/
  const { duration = 3000 } = props;
  const dispatch = useDispatch();
  const alertContainerRef = React.useRef(null);
  const timeoutRef = React.useRef(null);
  const blinkTimeoutRef = React.useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const toastMessage = useSelector((state) => state.global.message);
  const toastTitle = useSelector((state) => state.global.title);
  const toastPersist = useSelector((state) => state.global.persist);

  const toastColor = useSelector((state) => {
    switch (state.global.messageType) {
      case TOAST_TYPE.ERROR:
        return "danger";
      case TOAST_TYPE.SUCCESS:
        return "success";
      default:
        return "info";
    }
  });

  const toastIcon = useSelector((state) => {
    switch (state.global.messageType) {
      case TOAST_TYPE.ERROR:
        return "exclamation-triangle";
      default:
        return null;
    }
  });

  const toastIsAvailable = useSelector(
    (state) => !isEmpty(state.global.message)
  );
  /************************************ HOOKS ****************************************/

  useEffect(() => {
    if (toastIsAvailable && alertContainerRef.current) {
      alertContainerRef.current.scrollIntoView({ behavior: "smooth" });
    }

    setIsOpen(toastIsAvailable);

    if (toastIsAvailable) {
      if (!toastPersist) {
        timeoutHideMessage();
      } else {
        clearTimeout(timeoutRef.current);
        if (isOpen) {
          blinkMessage();
        }
      }
    }
    // eslint-disable-next-line
  }, [isOpen, toastIsAvailable, toastPersist, toastMessage]);

  /*********************************** FUNCTIONS *************************************/

  const hideMessage = () => {
    setIsOpen(false);
    toggleBlink(false);
    // Provide enough time for the toast to hide before dispatching the action.
    setTimeout(() => {
      dispatch(hideGlobalMessage());
    }, TOAST_TRANSITION_DURATION);
  };

  const toggleBlink = (enable) => {
    const target = alertContainerRef.current;
    if (target) {
      if (enable) {
        target.classList.add("blink");
      } else {
        target.classList.remove("blink");
      }
    }
  };

  const timeoutHideMessage = () => {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = setTimeout(() => {
      clearTimeout(timeoutRef.current);
      hideMessage();
    }, duration);
  };

  const blinkMessage = () => {
    toggleBlink(true);
    clearTimeout(blinkTimeoutRef.current);
    blinkTimeoutRef.current = setTimeout(() => {
      clearTimeout(blinkTimeoutRef.current);
      toggleBlink(false);
    }, TOAST_BLINK_DURATION);
  };
  /************************************ RENDER ***************************************/

  return (
    <div ref={alertContainerRef}>
      <Toast
        isOpen={isOpen}
        onHide={hideMessage}
        message={toastMessage}
        color={toastColor}
        icon={toastIcon}
        title={toastTitle}
      />
    </div>
  );
};
export default ToastContainer;
