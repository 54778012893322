import React, { useCallback, useRef } from "react";
import { useHistory, useParams } from "react-router-dom";
import useInfiniteScroll from "react-infinite-scroll-hook";
import { Selectors, Utils } from "@figure1/f1-pro-fe-brain";
import CaseCard from "../../../components/case/CaseCard";
import CaseCardLoading from "../../../components/case/CaseCardLoading";
import FEED_CARD_TYPE from "../../../constants/feed-card-type";
import CASE_TYPE from "../../../constants/case-type";
import QuestionCard from "../../../components/case/QuestionCard";
import HighlightCard from "../../../components/case/HighlightCard";
import InView from "react-intersection-observer";
import {
  cacheFeedViewMetrics,
  trackFeedView,
  trackFeedViewEntry,
  trackPreviewFeedCard,
  trackPreviewFeedCardEntry
} from "../../../actions/metrics.actions";
import { useDispatch, useSelector } from "react-redux";
import {
  decideIfPagingIsActive,
  getCaseTypeUrl
} from "../../../utils/case-utils";
import NormalPreviewFeedCard from "./case-carousel/NormalPreviewFeedCard";
import { FEED_NAMES } from "../../../constants/feed-type";
import i18n from "../../../utils/i18n";
import { CASE_LABEL_TYPES } from "../../../constants/case-label-types";

const IGNORE_QUIZ_SUMMARY = 1;

const findLabel = (caseContent) => {
  const isPagingActive = decideIfPagingIsActive(
    caseContent?.publishedAt,
    caseContent?.isPagingCase
  );

  if (
    isPagingActive &&
    caseContent?.labels.includes(CASE_LABEL_TYPES.UNRESOLVED)
  ) {
    return i18n.t("CaseCard.labels.paging");
  } else if (
    caseContent?.requestHelp &&
    caseContent?.labels.includes(CASE_LABEL_TYPES.UNRESOLVED)
  ) {
    return i18n.t("CaseCard.labels.unresolved");
  } else if (caseContent?.labels.includes(CASE_LABEL_TYPES.RARE_CONDITION)) {
    return i18n.t("CaseCard.labels.rare");
  } else if (caseContent?.labels.includes(CASE_LABEL_TYPES.TRENDING)) {
    return i18n.t("CaseCard.labels.trending");
  }
  return;
};

const ContentFeed = ({
  loadMore,
  isLoading,
  data,
  isEOF,
  eofCard,
  feedTypeUuid // will be the group ID if we're looking at a group, which is fetched at the TopicalFeedScreen component
}) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const history = useHistory();
  const routeParams = useParams();

  const filters = useSelector((state) => state.feeds.filters?.[feedTypeUuid]);
  const feedName = useSelector(
    (state) => state.userFeedMeta.data?.feeds?.[feedTypeUuid]?.feed_name
  );
  const currentLanguageCode = useSelector(
    Selectors.LocalizationSelectors.selectCurrentLanguageCode
  );
  const visibleDurations = useRef({});
  const groupUuid = routeParams.feed === FEED_NAMES.GROUP ? feedTypeUuid : null;
  const frameSwipeApplicableMap = useRef({});

  /** ********************************** HOOKS ****************************************/

  const infiniteRef = useInfiniteScroll({
    loading: isLoading,
    hasNextPage: true,
    onLoadMore: loadMore,
    scrollContainer: "window",
    threshold: window.innerHeight * 4
  });

  /** ********************************* FUNCTIONS *************************************/
  const pushDetail = (caseContent, index) => {
    history.push({
      pathname: getCaseTypeUrl(caseContent),
      state: {
        index
      }
    });
  };

  const onIntersectionalTrigger = (inView, index) => {
    const item = data[index];
    const uuid = item?.case_uuid || item?.previewFeedTypeUuid;

    if (inView) {
      if (!visibleDurations.current[uuid]) {
        visibleDurations.current[uuid] = new Date().getTime();
        if (item?.previewFeedLabel) {
          trackPreviewFeedCardEntry({
            type: item?.previewFeedLabel,
            channel: feedName,
            position: index
          });
        } else {
          trackFeedViewEntry({
            caseClassification: item?.caseClassification,
            caseUuid: item?.caseUuid,
            channel: feedName,
            position: index,
            filters: filters,
            groupUuid,
            translationApplicable: Utils.CaseUtils.isTranslationEnabled(
              item,
              currentLanguageCode
            ),
            currentLanguage: currentLanguageCode,
            frameSwipeApplicable:
              frameSwipeApplicableMap.current[item?.caseUuid] ?? false,
            hasDiagnosis: item.hasDiagnosis ?? false,
            acceptedAnswer: !!item?.hasAcceptedAnswer,
            label: findLabel(item),
            caseSpecialties: item?.specialtyNames,
            isAnonymous: item?.isAnonymous
          });
        }
      }
    } else {
      const removedDuration = visibleDurations.current[uuid];
      if (removedDuration) {
        delete visibleDurations.current[index];
        const duration = new Date().getTime() - removedDuration;

        if (item?.previewFeedLabel) {
          trackPreviewFeedCard({
            type: item?.previewFeedLabel,
            channel: feedName,
            position: index,
            duration: duration
          });
        } else {
          trackFeedView({
            caseClassification: item?.caseClassification,
            caseUuid: item?.caseUuid,
            channel: feedName,
            position: index,
            duration: duration,
            filters: filters,
            groupUuid,
            label: findLabel(item),
            isAnonymous: item?.isAnonymous
          });
        }

        dispatch(
          cacheFeedViewMetrics({ caseUuid: item?.caseUuid, feedTypeUuid })
        );
      }
    }
  };

  const makeKey = (item, index) => {
    return `${item.uuid || item.caseUuid || item.previewFeedTypeUuid}-${index}`;
  };

  const handleIsFrameSwipeVisible = useCallback((caseUuid, isVisible) => {
    frameSwipeApplicableMap.current[caseUuid] = isVisible;
  }, []);

  /** ********************************** RENDER ***************************************/

  let content = (
    <>
      <CaseCardLoading key="loading-1" />
      <CaseCardLoading key="loading-2" />
      <CaseCardLoading key="loading-3" />
    </>
  );

  if (data.length) {
    content = data.map((item, index) => {
      let contentItem = null;

      if (item.feedCardType === FEED_CARD_TYPE.BASIC) {
        // Note: These also include polls!
        if (
          [CASE_TYPE.QUIZ, CASE_TYPE.QUIZ_SERIES].includes(item.caseType) ||
          Object.keys(item.questionOptions || []).length > 0
        ) {
          contentItem = (
            <QuestionCard
              key={makeKey(item, index)}
              caseContent={{
                ...item,
                // A quiz series's `contentCount` (in feed data) includes the summary slide.
                // We need to ignore that from showing question progress.
                contentCount:
                  item.caseType === CASE_TYPE.QUIZ_SERIES
                    ? item.contentCount - IGNORE_QUIZ_SUMMARY
                    : item.contentCount
              }}
              goToQuestionDetailView={() => {
                pushDetail(item, index);
              }}
              position={index}
              onIsFrameSwipeVisible={handleIsFrameSwipeVisible}
            />
          );
        } else {
          contentItem = (
            <CaseCard
              key={makeKey(item, index)}
              caseContent={item}
              position={index}
              onIsFrameSwipeVisible={handleIsFrameSwipeVisible}
            />
          );
        }
      } else if (item.feedCardType === FEED_CARD_TYPE.END_OF_FEED) {
        return null;
      } else if (item.feedCardType === FEED_CARD_TYPE.HIGHLIGHT) {
        contentItem = (
          <HighlightCard
            key={makeKey(item, index)}
            content={item}
            onClick={() => {
              pushDetail(item, index);
            }}
          />
        );
      } else if (item.feedCardType === FEED_CARD_TYPE.PROMO) {
        return null;
      } else if (item.feedCardType === FEED_CARD_TYPE.PREVIEW_FEED) {
        contentItem = (
          <NormalPreviewFeedCard key={makeKey(item, index)} {...item} />
        );
      } else {
        return null;
      }
      return (
        <InView
          key={makeKey(item, index)}
          onChange={(inView) => onIntersectionalTrigger(inView, index)}>
          {contentItem}
        </InView>
      );
    });
  }

  const endCard = isEOF && eofCard ? eofCard : null;

  return (
    <div ref={infiniteRef}>
      {content}
      {!isEOF && <CaseCardLoading key="loading-skeleton" />}
      {endCard}
    </div>
  );
};

export default ContentFeed;
