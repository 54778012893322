import React from "react";
import { Button } from "reactstrap";
import i18n from "../../../utils/i18n";
import BulletIcon from "./BulletIcon";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import "./InvitePeopleOptionsBody.scss";

const InvitePeopleOptionsBody = ({ copyLink, inviteF1Members, onSkip }) => {
  /************************************ CONFIG ***************************************/
  const commonClasses = "d-flex align-items-center flex-grow-1 m-0";
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <div className="w-100">
      <h2
        className={`${commonClasses} justify-content-center px-0 mb-2 helv-bold text-18 line-height-28`}>
        {i18n.t("GroupsFeed.InviteModal.title")}
      </h2>
      <hr className="border-width-4 border-lighter-gray" />
      <div className="mb-3">
        <p
          className={`${commonClasses} text-left px-0 mb-1 helv-bold text-18 line-height-28`}>
          {i18n.t("GroupsFeed.InviteModal.invitePeople")}
        </p>
        <p
          className={`${commonClasses} text-left px-0 mb-4 text-muted text-16 line-height-20`}>
          {i18n.t("GroupsFeed.InviteModal.invitePeopleDesc")}
        </p>
        <ul className="p-0 mb-4">
          <li
            onClick={copyLink}
            className="d-flex py-2 px-1 mb-2 text-20 align-items-center justify-content-between cursor-pointer invite-option-list-item">
            <div className="d-flex flex-row align-items-center w-85">
              <div className="w-36px mr-3">
                <BulletIcon icon="sheet_copy_link" size={20} />{" "}
              </div>
              <div className="text-left">
                <p className="helv-med text-16 line-height-20 m-0">
                  {i18n.t("GroupsFeed.InviteModal.item1Label")}
                </p>
                <p className="text-muted text-14 line-height-20 m-0">
                  {i18n.t("GroupsFeed.InviteModal.item1Desc")}
                </p>
              </div>
            </div>
            <CustomIcon icon="drawer_draft_chevron" size={18} />
          </li>
          <li
            onClick={inviteF1Members}
            className="d-flex py-2 px-1 text-20 align-items-center justify-content-between cursor-pointer invite-option-list-item">
            <div className="d-flex flex-row align-items-center">
              <BulletIcon icon="feed_search" size={18} />{" "}
              <div className="text-left">
                <p className="helv-med text-16 line-height-20 m-0">
                  {i18n.t("GroupsFeed.InviteModal.item2Label")}
                </p>
                <p className="text-muted text-14 line-height-20 m-0">
                  {i18n.t("GroupsFeed.InviteModal.item2Desc")}
                </p>
              </div>
            </div>
            <CustomIcon icon="drawer_draft_chevron" size={18} />
          </li>
        </ul>
      </div>
      <div className="d-flex align-items-center justify-content-end">
        <Button
          className="my-2 text-16 helv-med px-4"
          color="secondary"
          onClick={onSkip}>
          {i18n.t("GroupsFeed.InviteModal.skipForNow")}
        </Button>
      </div>
    </div>
  );
};

export default InvitePeopleOptionsBody;
