import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import { useSelector } from "react-redux";
import Dialog from "../../components/dialog/Dialog";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import { GROUPS_FEED, GROUPS_HOME } from "../../constants/routes";
import { useLocation } from "react-router-dom";
import Loading from "../../components/loading";
import GroupOption from "./components/GroupOption";
import { useSelectUserGroups } from "../../selectors/group.selectors";
import CreateGroupModal from "./CreateGroupModal";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";

const PrivateGroupsModal = ({ isOpen, toggle }) => {
  /************************************ CONFIG ***************************************/
  const location = useLocation();
  const pathname = location.pathname;

  const groups = useSelectUserGroups();

  const groupsLoading = useSelector((state) => state.groups?.isProcessing);
  const isGroupFeedPage = pathname?.indexOf(GROUPS_HOME) !== -1;

  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/
  const toggleCreateGroupModal = () =>
    setCreateGroupModalOpen((prevState) => !prevState);
  const verifiedToggleCreateGroup = useVerifiedUserGate(toggleCreateGroupModal);
  /************************************ RENDER ***************************************/

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onCancel={toggle}
        body={
          <>
            {groupsLoading && <Loading />}
            {!groupsLoading && (
              <div className="w-100">
                <div className="d-flex align-items-center justify-content-center px-0 flex-grow-1">
                  <h2 className="m-0 helv-bold text-18 line-height-28">
                    {i18n.t("GroupsFeed.Banner.modalTitle")}
                  </h2>
                </div>
                <hr className="border-width-4 border-lighter-gray" />
                {groups.map((group) => (
                  <GroupOption
                    key={group.groupUuid}
                    url={getUrl(GROUPS_FEED, {
                      feedTypeUuid: group.groupUuid
                    })}
                    name={group.groupName}
                    membersCount={group.membersCount || 0}
                    isActive={
                      isGroupFeedPage &&
                      pathname?.indexOf(`/${group.groupUuid}`) !== -1
                    }
                    closeModal={toggle}
                  />
                ))}
                <div
                  onClick={verifiedToggleCreateGroup}
                  className="d-flex justify-content-start align-item-center bg-white py-3">
                  <div className="text-left">
                    <h2 className="helv-med text-16 line-height-25 link-color m-0 d-flex align-item-center">
                      <CustomIcon icon="web_new" size={20} />
                      <span className="pl-2">
                        {i18n.t("GroupsFeed.Banner.newGroup")}
                      </span>
                    </h2>
                  </div>
                </div>
              </div>
            )}
          </>
        }
        customFooter={<></>}
      />
      <CreateGroupModal
        isOpen={isCreateGroupModalOpen}
        toggle={toggleCreateGroupModal}
      />
    </>
  );
};

PrivateGroupsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired
};

PrivateGroupsModal.defaultProps = {};

export default PrivateGroupsModal;
