import { useEffect } from "react";
import { matchPath, useHistory } from "react-router-dom";

const useOnPageLeave = (currentRoute, onPageLeave) => {
  const history = useHistory();

  useEffect(() => {
    return () => {
      const match = matchPath(history.location.pathname, {
        path: currentRoute
      });

      if (!match) {
        onPageLeave();
      }
    };
  }, [currentRoute, history.location.pathname, onPageLeave]);
};

export default useOnPageLeave;
