/** @format */
import * as ROUTES from "../constants/routes";

export const Source = {
  WEB: "web",
  MOBILE_WEB: "mobile_web"
};

export const StaticStrings = {
  usa: "United States",
  canada: "Canada",
  supportEmail: "support@figure1.com",
  loginSupportEmail: "support@figure1.com?subject=Login%20Help",
  privacyLink: "https://www.figure1.com/pro-privacy",
  termsOfServiceUrl: "https://www.figure1.com/pro-terms-of-service",
  communityGuidelines: "https://www.figure1.com/pro-community-guidelines",
  clinicalCases: "https://www.figure1.com/resources/clinical-cases",
  contentPolicy: "https://www.figure1.com/content-policy",
  npiRegistry: "https://npiregistry.cms.hhs.gov",
  feedback: "https://www.figure1.com/resources/faqs/feedback/feedback/",
  aboutFigure1: "https://www.figure1.com/about/",
  middleDot: "\u2022",
  iOSStoreUrl:
    "https://apps.apple.com/us/app/figure-1-medical-cases/id645948529",
  androidStoreUrl:
    "https://play.google.com/store/apps/details?id=com.figure1.android",
  discoverGroups: "https://www.figure1.com/public-groups/",
  caseShareLinkParams:
    "?utm_campaign=Acquisition&utm_content=:CASE_UUID&utm_medium=external&utm_source=share_post_:SOURCE&utm_term=:USER_UUID",
  claimGroupUrl: `${ROUTES.CLAIM_GROUP}?utm_campaign=Acquisition&utm_content=:GROUP_UUID&utm_medium=external&utm_source=group_invite_:SOURCE&utm_term=:USER_UUID`,
  inviteColleagesProfileUrl: `${ROUTES.PROFILE_DETAIL}?utm_campaign=Acquisition&utm_content=memberReferral&utm_medium=external&utm_source=invite&utm_term=:USER_UUID`,
  fig1: "https://www.figure1.com"
};
