import { useSelector } from "react-redux";

const useIsAStudent = () => {
  const user = useSelector((state) => state.user);
  return useIsAStudentByName(
    user?.primarySpecialty?.tree?.profession?.professionCategoryLabel
  );
};

export const useIsAStudentByName = (name) => {
  return name?.toLowerCase().indexOf("student") > -1;
};

export default useIsAStudent;
