import React from "react";
import CustomIcon from "./CustomIcon";

const CircleCustomIcon = ({
  icon,
  color,
  size,
  rotation,
  style,
  circleColor,
  innerClassName,
  className = ""
}) => {
  const circleColorHex =
    circleColor.indexOf("#") === 0
      ? { style: { backgroundColor: circleColor } }
      : {};
  const circleColorClass =
    circleColor.indexOf("#") === 0 ? "" : `bg-${circleColor}`;

  return (
    <span
      {...circleColorHex}
      className={`d-inline-flex ${circleColorClass} rounded rounded-circle p-1 ${className}`}>
      <CustomIcon
        className={innerClassName}
        icon={icon}
        color={color}
        size={size}
        rotation={rotation}
        style={style}
      />
    </span>
  );
};

export default CircleCustomIcon;
