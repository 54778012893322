import React from "react";
import { FormFeedback, FormGroup, FormText, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import { includesInput } from "../../../utils/search-utils";
import i18n from "../../../utils/i18n";

const FormElementProfession = ({
  professionList,
  label,
  control,
  errors,
  placeholder,
  groupClassName,
  initialValue = null,
  showFeedback = false,
  errorKey = "profession",
  updateMode = false
}) => {
  /** ********************************** CONFIG ***************************************/
  const isInvalid = !!errors[errorKey];
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <FormGroup className={groupClassName ?? "mt-4"}>
      <Label for={"profession"} className="mb-1">
        {label}
      </Label>
      <Controller
        className={`mr-1 ${isInvalid ? "is-invalid" : ""}`}
        as={<Select options={professionList} filterOption={includesInput} />}
        control={control}
        id={"profession"}
        name={"profession"}
        type="text"
        placeholder={placeholder}
        rules={{ required: true }}
        invalid={isInvalid}
        defaultValue={initialValue}
      />
      {updateMode && (
        <FormText>
          {i18n.t("RegistrationScreens.userInfo.v2.professionUpdateText")}
        </FormText>
      )}
      {showFeedback && (
        <FormFeedback>
          {i18n.t("RegistrationScreens.userInfo.v2.professionErrorText")}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default FormElementProfession;
