import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useBeforeunload } from "react-beforeunload";
import { useHistory, useLocation } from "react-router-dom";
import _, { isEmpty } from "lodash";
import { isMobileOnly } from "react-device-detect";
import { Selectors, Utils } from "@figure1/f1-pro-fe-brain";
import Figure1Page2Col from "../../@layouts/Figure1Page2Col";
import {
  resetCaseDetails,
  requestCaseSyncIfNeeded,
  toggleCaseDetailsListener,
  toggleCaseUserActionsListener,
  toggleRelatedCasesListener,
  toggleCasePublicationsListener
} from "../../actions/case.actions";
import { resetComments } from "../../actions/comment.actions";

import CaseCardLoading from "../../components/case/CaseCardLoading";
import Comments from "../../components/comments/Comments";
import ContentItem from "../../components/case/ContentItem";
import AutoSizer from "../../components/common/AutoSizer";
import ISILayout from "../../components/isi/ISILayout";
import useScrollRestore from "../../components/scroll-restore/useScrollRestore";
import { defaultFeatures } from "../../constants/case-feature-constants";
import { QUESTION_CONTENT_TYPES } from "../../constants/content-type";
import {
  getCurrentContentItem,
  hideCommentsSwitch
} from "../../utils/case-utils";
import { isPoll, isQuiz, isSponsoredContent } from "../../utils/tactic-utils";
import { useInteractiveCaseState } from "../../selectors/interactive-case-state";
import {
  cacheDetailViewMetrics,
  trackCaseDetailEntryView,
  trackCaseDetailView,
  trackSlideView
} from "../../actions/metrics.actions";
import useComments from "../../components/comments/useComments";
import i18n from "../../utils/i18n";
import * as ROUTES from "../../constants/routes";
import CaseEmptyView from "../../components/case/CaseEmptyView";
import { ISI_FOOTER_HEIGHT } from "../../constants/isi-constants";
import useISI from "../../components/isi/useISI";
import useIsGated from "../../hooks/useIsGated";
import useAppPrompt from "../../components/app-prompt/useAppPrompt";
import { Card, CardBody, Col, Row } from "reactstrap";
import CaseDetailCardInner from "../../components/case/CaseDetailCardInner";
import { setGatedState } from "../../actions/global.actions";
import useIsSponsored from "../../hooks/useIsSponsored";
import SimilarCases from "../../components/similar-cases/SimilarCases";
import { FEED_META_LABELS } from "../../constants/feed-type";
import {
  getPreviewFeedIfNeeded,
  getPreviewFeedMetaIfNeeded
} from "../../actions/reference.actions";
import GatedCommentBar from "../../components/case/GatedCommentBar";
import CaseCarouselContainer from "../feed/components/case-carousel/CaseCarouselContainer";
import useGatedBlockCounter from "../../hooks/useGatedBlockCounter";
import BlockedCaseDetailPage from "./blocked-case-detail/BlockedCaseDetailPage";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import {
  SHARE_CONTEXT,
  UNGATED_ACTIONS
} from "../../metrics/constants.metrics";
import { useCaseTranslation } from "../../hooks";
import useOnPageLeave from "../../hooks/useOnPageLeave";
import QueryPreserveRedirect from "../../components/common/QueryPreserveRedirect";
import ShareCaseDialog from "../../components/case/ShareCaseDialog";
import CaseCMECTA from "../../components/case/CaseCMECTA";
import CaseCMEMobileCTA from "../../components/case/CaseCMEMobileCTA";
import useCaseCmeEligible from "../../hooks/useCaseCmeEligible";
import AboutThisCaseCard from "../../components/case/AboutThisCaseCard";
import { ELIGIBLE_CASE_LABELS_ABOUT_THIS_CASE } from "../../constants/case-label-types";
import useGetCommentContentID from "../../hooks/useGetCommentContentID";
import BlockedCaseUpsell from "../../components/case/BlockedCaseUpsell";
import BackButton from "../../components/common/BackButton";

const CaseDetailPage = ({ caseId, commentId, questionIndex }) => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();

  const dispatch = useDispatch();
  const scrollRef = useScrollRestore();
  const { isShown: isAppPromptShown, height: appPromptHeight } = useAppPrompt();
  const enterTimestamp = useRef(new Date().getTime());
  const location = useLocation();

  const metrics = useRef({});
  const isGated = useIsGated();
  const isSponsored = useIsSponsored();
  const userExists = useAnonUserExists();

  const caseState = useInteractiveCaseState(caseId);
  const { exceededLimit, tickCount, sessionCount, countLimit } =
    useGatedBlockCounter();
  const limitBroke = exceededLimit();

  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
  const [shareLocation, setShareLocation] = useState("");
  const [position, setPosition] = useState(null);
  const [caseDetails, setCaseDetails] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(questionIndex || 0);
  const [haveSetUngated, setHaveSetUngated] = useState(false);

  const caseStatus = useSelector((state) => state.case.cases[caseId]);
  const showCMECTA = useCaseCmeEligible(caseDetails?.data);

  const locationState = location?.state;
  const features = caseStatus?.data?.features || defaultFeatures;
  const isGroupCase = !!caseDetails?.data?.groupUuid;
  const isAnonymous = !!caseDetails?.data?.isAnonymous;
  const hasAcceptedAnswer = !isEmpty(caseDetails?.data?.acceptedAnswer);

  const details = caseStatus?.data || {};
  const detailsItems = _.get(details, "contentItems", []);
  const contentUuidForComments = useGetCommentContentID(detailsItems);

  const { commentStatus } = useComments(caseId, contentUuidForComments);
  const commentCount = commentStatus?.allCount || 0;

  const nbQuestions = detailsItems.filter((i) => {
    return QUESTION_CONTENT_TYPES.includes(i.contentType);
  }).length;

  const nbUserRightAnswers = caseState?.numCorrect || 0;
  const currentItem = getCurrentContentItem(details, currentIndex) || {};
  const shouldHideCommentsSwitch = hideCommentsSwitch(details, currentIndex);

  // WHY DO WE MAKE THIS INSTEAD OF JUST USING THE DANGED DETAILS -- Corey
  const itemContent = {
    authors: details.authors,
    author_uid: details.author_uid,
    labels: details.labels,
    allReactions: details.allReactions,
    publishedAt: details.publishedAt,
    updatedAt: details.updatedAt,
    author_username: details.author_username,
    author_profession_label: details.author_profession_label,
    userSaved: details.userSaved,
    meshTerms: details.meshTerms,
    specialtyUuids: details.specialtyUuids,
    specialtyNames: details.specialtyNames,
    features: features,
    unverifiedViewCount: details.unverifiedViewCount,
    isPagingCase: details.isPagingCase,
    isi: details.isi, // Update this once the ISI backend is setup,
    isPoll: isPoll(currentItem),
    isQuiz: isQuiz(currentItem),
    shareLink: details.shareLink,
    isSponsored: isSponsoredContent(details),
    isCaseCme: details.isCaseCme,
    requestHelp: details.requestHelp,
    caseState: details.caseState,
    caseClassification: details.caseClassification,
    isCase: !Utils.CaseUtils.isNonMedicalContent(details),
    hasAnswer: !!details.acceptedAnswer,
    ...currentItem
  };

  const { showFooter: showISIFooter } = useISI(itemContent, false);
  const showNewUngated = isGated && !isSponsored;

  useBeforeunload(() => {
    metrics.current.duration = new Date().getTime() - enterTimestamp.current;
    trackCaseDetailView(metrics.current);
  });

  const metaDataId = useSelector(
    (state) =>
      Object.values(state?.references?.feedsMeta?.data || {})?.find((m) => {
        return m.label === FEED_META_LABELS.TRENDING;
      })?.feed_type_uuid
  );

  const [feedFetchMade, setFeedFetchMade] = useState(false);
  const [trackedCaseId, setTrackedCaseId] = useState(false);

  const trendingCasesData = useSelector(
    (state) => state?.references?.feeds?.[metaDataId]?.data
  );

  const { isLoadingTranslation, isTranslationEnabled, viewOriginalCase } =
    useCaseTranslation(itemContent);

  const currentLanguageCode = useSelector(
    Selectors.LocalizationSelectors.selectCurrentLanguageCode
  );

  const canViewGroupCase = useSelector((state) => {
    return (
      state.user?.groups?.find(
        (g) => g.groupUuid === caseDetails?.data?.groupUuid
      ) || false
    );
  });

  const isStaticCase = Utils.CaseUtils.isStaticCase(itemContent?.caseState);
  const showAboutThisCaseCard =
    !isStaticCase &&
    itemContent.isCase &&
    itemContent?.labels?.some((label) =>
      ELIGIBLE_CASE_LABELS_ABOUT_THIS_CASE.includes(label)
    );

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    return () => {
      dispatch(resetCaseDetails(caseId));
      dispatch(resetComments(caseId));
    };
  }, [dispatch, caseId]);

  useEffect(() => {
    dispatch(requestCaseSyncIfNeeded(caseId));
    dispatch(toggleCaseDetailsListener(caseId, true));
    dispatch(toggleCaseUserActionsListener(caseId, true));
    dispatch(toggleRelatedCasesListener(caseId, true));
    dispatch(toggleCasePublicationsListener(caseId, true));
    return () => {
      dispatch(toggleCaseDetailsListener(caseId, false));
      dispatch(toggleCaseUserActionsListener(caseId, false));
      dispatch(toggleRelatedCasesListener(caseId, false));
      dispatch(toggleCasePublicationsListener(caseId, false));
    };
  }, [isGated, caseId, dispatch]);

  useEffect(() => {
    if (!metaDataId) {
      dispatch(getPreviewFeedMetaIfNeeded());
    }
  }, [metaDataId, dispatch]);

  useEffect(() => {
    if (metaDataId && !feedFetchMade) {
      dispatch(
        getPreviewFeedIfNeeded({
          feedTypeUuid: metaDataId
        })
      );
      setFeedFetchMade(true);
    }
  }, [metaDataId, feedFetchMade, dispatch]);

  useEffect(() => {
    if (isGated && !haveSetUngated && contentUuidForComments) {
      dispatch(setGatedState(true, itemContent.isSponsored));
      setHaveSetUngated(true);
    }
  }, [
    dispatch,
    isGated,
    contentUuidForComments,
    haveSetUngated,
    itemContent.isSponsored
  ]);

  useEffect(() => {
    if (caseStatus) {
      if (caseStatus.relatedCases) {
        setCaseDetails(caseStatus);
      }
    }
  }, [caseStatus]);

  // METRICS EFFECTS
  useEffect(() => {
    metrics.current.ungated = isGated;
    metrics.current.isAnonymous = isAnonymous;
    metrics.current.acceptedAnswer = hasAcceptedAnswer;

    if (locationState?.position != null) {
      metrics.current.position = locationState.position;
      setPosition(locationState.position);
    }
    if (caseDetails) {
      metrics.current.caseContent = caseDetails?.data;
    }
    if (commentCount != null) {
      metrics.current.commentCount = commentCount;
    }

    metrics.current.translationApplicable = isTranslationEnabled;
    metrics.current.currentLanguage = currentLanguageCode;
    metrics.current.hasDiagnosis = caseDetails?.data?.hasDiagnosis ?? false;

    // Only send this if we have the data, and we've not already sent it
    if (
      trackedCaseId !== caseId &&
      metrics.current?.caseContent &&
      metrics.current?.commentCount != null
    ) {
      if (
        isGated &&
        !isSponsored &&
        !limitBroke &&
        !itemContent.isPoll &&
        !itemContent.isQuiz
      ) {
        tickCount();
      }

      if (isGated) {
        if (userExists) {
          if (itemContent.isPoll) {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.EXISTING_USER_POLL;
          } else if (itemContent.isQuiz) {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.EXISTING_USER_QUIZ;
          } else {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.EXISTING_USER;
          }
        } else {
          if (itemContent.isPoll) {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.NEW_USER_POLL;
          } else if (itemContent.isQuiz) {
            metrics.current.ungatedState =
              UNGATED_ACTIONS.BLOCKED_STATUS.NEW_USER_QUIZ;
          } else {
            if (sessionCount >= countLimit) {
              metrics.current.ungatedState =
                UNGATED_ACTIONS.BLOCKED_STATUS.NEW_LIMIT_HIT_IN_SESSION;
            } else if (limitBroke) {
              metrics.current.ungatedState =
                UNGATED_ACTIONS.BLOCKED_STATUS.NEW_LIMIT_HIT;
            } else {
              metrics.current.ungatedState =
                UNGATED_ACTIONS.BLOCKED_STATUS.NEW_NO_VIEWS;
            }
          }
        }
      }

      trackCaseDetailEntryView(metrics.current);
      dispatch(cacheDetailViewMetrics(metrics.current?.caseContent?.caseUuid));

      metrics.current = {};

      setTrackedCaseId(caseId);
    }
  }, [
    isAnonymous,
    hasAcceptedAnswer,
    trackedCaseId,
    caseId,
    locationState,
    caseDetails,
    commentCount,
    isGated,
    userExists,
    limitBroke,
    sessionCount,
    countLimit,
    dispatch,
    isSponsored,
    tickCount,
    currentLanguageCode,
    isTranslationEnabled
  ]);

  // not actually redundant! this fires when the component unloads
  // the other one above is when the page is changed
  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      metrics.current.duration = new Date().getTime() - enterTimestamp.current;
      // eslint-disable-next-line
      trackCaseDetailView(metrics.current);
    };
  }, []);

  useOnPageLeave(ROUTES.CASE_DETAIL, viewOriginalCase);

  /** ********************************* FUNCTIONS *************************************/

  const onNext = () => {
    if (detailsItems.length > currentIndex + 1) {
      scrollRef.current.scrollTo(0, 0);
      setCurrentIndex(currentIndex + 1);
      trackSlideView(
        caseId,
        currentIndex?.contentUuid,
        details?.campaignUuid,
        currentIndex + 1
      );
    }
  };

  const onPrev = () => {
    if (currentIndex - 1 >= 0) {
      scrollRef.current.scrollTo(0, 0);
      setCurrentIndex(currentIndex - 1);
      trackSlideView(
        caseId,
        currentIndex?.contentUuid,
        details?.campaignUuid,
        currentIndex - 1
      );
    }
  };

  const onShareDialogOpen = (location) => {
    setShareLocation(location);
    setIsShareDialogOpen(true);
  };

  const onShareDialogClose = () => {
    setShareLocation("");
    setIsShareDialogOpen(false);
  };

  let content = null;
  let rightContent = null;

  if (_.isEmpty(details) || isLoadingTranslation || !caseDetails) {
    content = <CaseCardLoading />;
  } else if (!_.isEmpty(details) && isGated && isGroupCase) {
    return (
      <QueryPreserveRedirect
        preserveQueryString={true}
        to={{
          pathname: ROUTES.REGISTRATION,
          search: `?redirect=${location.pathname}`,
          state: { from: location.pathname }
        }}
      />
    );
  } else if (
    !details?.displayable ||
    (!_.isEmpty(details) && isGroupCase && !canViewGroupCase)
  ) {
    // Note that "displayable" is set to tru in the case reducer if the
    // caseStatus is one of DISPLAYABLE_CASE_STATES; APPROVED, SC_APPROVED,
    // and SC_REVIEW as of this writing
    content = <CaseEmptyView className="ml-3" onOk={history.goBack} />;
  } else if (
    showNewUngated &&
    (exceededLimit() ||
      !!userExists ||
      itemContent.isPoll ||
      itemContent.isQuiz)
  ) {
    return (
      <BlockedCaseDetailPage
        trendingCasesData={trendingCasesData}
        caseDetails={caseDetails}
        itemContent={itemContent}
        commentStatus={commentStatus}
      />
    );
  } else if (showNewUngated) {
    if (!isMobileOnly) {
      content = (
        <>
          <Card>
            <CaseDetailCardInner
              caseContent={itemContent}
              userReactions={caseDetails.userReactions || []}
              commentStatus={commentStatus}
              campaignUuid={details?.campaignUuid}
              videoPlayerConfig={{ forceAutoplay: true, forceMuted: true }}
              showNewUngated={showNewUngated}
              onShareClick={onShareDialogOpen}
            />
            <hr className="my-0" />
            <GatedCommentBar
              caseDetails={caseDetails}
              linkText={i18n.t("Gated.CommentBar.signUpText")}
            />
          </Card>
          {showNewUngated && showAboutThisCaseCard && (
            <Card className="mt-2">
              <CardBody>
                <AboutThisCaseCard
                  caseContent={itemContent}
                  showNewUngated={showNewUngated}
                />
              </CardBody>
            </Card>
          )}
        </>
      );
    } else {
      content = (
        <>
          <Card className="mb-2">
            <CaseDetailCardInner
              caseContent={itemContent}
              userReactions={caseDetails.userReactions || []}
              commentStatus={commentStatus}
              campaignUuid={details?.campaignUuid}
              videoPlayerConfig={{ forceAutoplay: true, forceMuted: true }}
              showNewUngated={showNewUngated}
              onShareClick={onShareDialogOpen}
            />
            <hr className="my-0" />
            <GatedCommentBar
              caseDetails={caseDetails}
              linkText={i18n.t("Gated.CommentBar.signUpText")}
            />
          </Card>
          {showNewUngated && showAboutThisCaseCard && (
            <Card className="mt-2">
              <CardBody>
                <AboutThisCaseCard
                  caseContent={itemContent}
                  showNewUngated={showNewUngated}
                />
              </CardBody>
            </Card>
          )}
          {itemContent.features.similarCasesEnabled && itemContent.isCase && (
            <CaseCarouselContainer
              className="pl-3"
              cases={caseDetails?.relatedCases?.slice(0, 6) || []}
              context={"Ungated case"}
              headerElement={
                <div className="mb-2 text-14 helv-bold">
                  {" "}
                  {i18n.t("caseDetail.similarCases")}
                </div>
              }
              imgSize={124}
              colSize={2}
              nextItemVisiblePercent={0.27}
            />
          )}
          {trendingCasesData?.length && (
            <CaseCarouselContainer
              className="pl-3"
              cases={trendingCasesData?.slice(0, 6) || []}
              context={"Ungated case"}
              headerElement={
                <div className="mb-2 text-14 helv-bold">
                  {" "}
                  {i18n.t("caseDetail.trendingCases")}
                </div>
              }
              imgSize={124}
              colSize={2}
              nextItemVisiblePercent={0.27}
            />
          )}
        </>
      );
    }
    if (!isMobileOnly) {
      rightContent = (
        <>
          {isGated && <BlockedCaseUpsell itemContent={itemContent} />}
          {itemContent.features.similarCasesEnabled && itemContent.isCase && (
            <div className="mb-3">
              <SimilarCases
                relatedCases={caseDetails.relatedCases || []}
                itemLimit={4}
                size={6}
                title={i18n.t("caseDetail.similarCases")}
              />
            </div>
          )}

          {trendingCasesData?.length && (
            <SimilarCases
              relatedCases={trendingCasesData}
              size={6}
              itemLimit={4}
              title={i18n.t("caseDetail.trendingCases")}
            />
          )}
        </>
      );
    }
  } else {
    content = (
      <ContentItem
        item={itemContent}
        caseContent={caseDetails?.data}
        currentItem={currentItem}
        features={features}
        shouldHideCommentsSwitch={shouldHideCommentsSwitch}
        position={position}
        commentId={commentId}
        userReactions={caseDetails.userReactions || {}}
        relatedCases={caseDetails.relatedCases || []}
        maxIndex={detailsItems.length - 1}
        currentIndex={currentIndex}
        nbQuestions={nbQuestions}
        nbUserRightAnswers={nbUserRightAnswers}
        onNext={onNext}
        onPrev={onPrev}
        commentStatus={commentStatus}
        campaignUuid={details?.campaignUuid}
        casePublications={caseDetails.casePublications || []}
        onShareClick={onShareDialogOpen}
      />
    );

    rightContent = !isMobileOnly && (
      <div className="feed-sidebar-right">
        {showCMECTA && !isMobileOnly && (
          <CaseCMECTA caseContent={caseDetails?.data} className="mb-3" />
        )}

        <Comments
          caseContent={caseDetails?.data}
          currentItem={currentItem}
          commentStatus={commentStatus}
          hideComments={!features.commentsEnabled}
          hideCommentsSwitch={shouldHideCommentsSwitch}
          hideReactions={!features.reactionsEnabled}
          userReactions={caseDetails?.userReactions || []}
          position={position}
          commentId={commentId}
          isMedicalCase={itemContent.isCase}
        />
      </div>
    );
  }

  /** ********************************** RENDER ***************************************/

  const styleProps = {};
  if (showISIFooter && !isMobileOnly) {
    styleProps.marginBottom = ISI_FOOTER_HEIGHT;
  }
  if (isAppPromptShown) {
    styleProps.marginBottom = (styleProps.marginBottom ?? 0) + appPromptHeight;
  }
  if (isGated && !isSponsored) {
    if (isSponsored) {
      styleProps.bottom = "5rem";
    } else {
      styleProps.bottom = "0";
    }
  }

  const layoutProps = {
    isChildPage: true
  };

  if (isMobileOnly) {
    delete layoutProps.isChildPage;
    layoutProps.headerClass = "py-0";
    layoutProps.headerRowSlot = (
      <Row className="py-2">
        <Col>
          <BackButton />
        </Col>
        {showCMECTA && (
          <Col className="d-flex justify-content-end">
            <CaseCMEMobileCTA caseContent={caseDetails?.data} />
          </Col>
        )}
      </Row>
    );
  }

  return (
    <ISILayout isi={itemContent} enableGatedBanner>
      <Figure1Page2Col
        {...layoutProps}
        mainContent={content}
        rightSidebarContent={
          <>
            {rightContent && (
              <AutoSizer
                className={`position-lg-fixed ${
                  isGated && !isSponsored ? "" : "b-lg-2"
                } overflow-y-lg-auto ${isGated && !isSponsored ? "gated" : ""}`}
                style={styleProps}>
                {rightContent}
              </AutoSizer>
            )}
          </>
        }
      />
      <ShareCaseDialog
        isOpen={isShareDialogOpen}
        caseContent={itemContent ?? {}}
        location={shareLocation}
        context={SHARE_CONTEXT.DETAILED_VIEW}
        onClosed={onShareDialogClose}
      />
    </ISILayout>
  );
};

export default CaseDetailPage;
