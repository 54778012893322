import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import * as ROUTES from "../../../constants/routes";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../../utils/route-utils";
import { useForm } from "react-hook-form";
import {
  clearFetchedNpi,
  postVerification
} from "../../../actions/user-verification.actions";
import { trackCheckNpi, trackEvent } from "../../../actions/metrics.actions";
import NpiConfirmationModal from "./NpiConfirmationModal";
import VerificationNPIForm from "./VerificationNPIForm";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import {
  METRICS_EVENT_NAME,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics";

const VerificationNpiV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  let history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { handleSubmit, control, errors, formState } = useForm({
    mode: "onBlur"
  });

  const user = useSelector((state) => state.user);

  const [isLoading, setIsLoading] = useState(false);
  const onboardingNavigate = useOnboardingNavigation();
  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (user.onboardingCompleted && isLoading) {
      history.push(getUrl(ROUTES.REGISTRATION_VERFICATION_COMPLETE));
    }
  }, [history, isLoading, user.onboardingCompleted]);

  /** ********************************* FUNCTIONS *************************************/

  const onCancelModal = () => {
    dispatch(clearFetchedNpi());
    setIsModalOpen(false);
    setIsLoading(false);
  };

  const handleSubmitClick = async (formData) => {
    if (!formState.isValid) {
      return;
    }

    setIsLoading(true);

    const submissionData = {
      method: "npi",
      npiNumber: parseInt(formData.npiNumber)
    };

    trackCheckNpi(submissionData.npiNumber);

    const verificationResult = await dispatch(postVerification(submissionData));

    if (!verificationResult || verificationResult.error) {
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]:
          verificationResult?.error ?? "Error sending NPI verification request"
      });
      setIsLoading(false);
      return;
    } else {
      onboardingNavigate(verificationResult.user);
    }
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <VerificationNPIForm
        isLoading={isLoading}
        onSubmit={handleSubmit(handleSubmitClick)}
        errors={errors}
        control={control}
      />
      {isModalOpen && (
        <NpiConfirmationModal
          isOpen={isModalOpen}
          existingData={user}
          onCancel={onCancelModal}
        />
      )}
    </>
  );
};

export default VerificationNpiV2;
