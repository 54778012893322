/** @format */

import React from "react";
import { Link } from "react-router-dom";
import { ListGroupItem } from "reactstrap";
import PropTypes from "prop-types";
import { trackUploadCase } from "../../../actions/metrics.actions";
import { CASE_TYPES, DRAFT_STATES } from "../../../constants/case-constants";
import * as ROUTES from "../../../constants/routes";
import LoadingButton from "../../../components/common/LoadingButton";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import { DraftProp } from "../../../prop-types/DraftProp";
import { getUrl } from "../../../utils/route-utils";
import DraftListItemText from "./DraftListItemText";
import DraftListItemThumbnail from "./DraftListItemThumbnail";
import i18n from "../../../utils/i18n";
import { Constants } from "@figure1/f1-pro-fe-brain";

const DraftListItem = ({ draft, isProcessing, onDelete }) => {
  /************************************ CONFIG ***************************************/
  const editDraftUrl = getUrl(
    draft?.caseClassification ===
      Constants.CaseType.CASE_CLASSIFICATIONS.NON_MEDICAL
      ? ROUTES.POST_EDIT
      : ROUTES.CASE_POSTING_EDIT,
    {
      draftUid: draft.uid
    }
  );

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/
  const onDeleteClick = (e) => {
    e.stopPropagation();
    onDelete(draft.uid);
  };

  const onClick = () => {
    trackUploadCase(
      "TapDraftCase",
      draft.paging ? CASE_TYPES.PAGING : CASE_TYPES.POST,
      draft.uid
    );
  };
  return (
    <ListGroupItem className="px-0">
      <div className="text-current text-decoration-none">
        <div
          style={{ width: "100%" }}
          className="d-inline-flex align-items-center cursor-pointer">
          <Link
            style={{ width: "100%" }}
            className="text-current text-decoration-none d-inline-flex align-items-center cursor-pointer"
            to={editDraftUrl}
            onClick={onClick}>
            <div className="position-relative mr-3">
              <DraftListItemThumbnail
                url={draft.media?.[0]?.url}
                state={draft.state}
              />
            </div>
            <div className="flex-grow-1 mr-3">
              <DraftListItemText
                title={draft.title}
                caption={draft.caption}
                state={draft.state}
              />
            </div>
          </Link>
          <div className="border-lighter-gray border-left border-width-2 pl-2">
            <LoadingButton
              className="btn-text d-flex align-self-end bg-danger rounded-circle p-1 mr-1 my-3"
              size="sm"
              color="light"
              spinnerColor="light"
              disabled={isProcessing}
              onClick={onDeleteClick}>
              <CustomIcon
                className="icon-light"
                size={15}
                icon={"profile_trash"}
              />
            </LoadingButton>
          </div>
        </div>
        {draft.state === DRAFT_STATES.REJECTED && (
          <Link
            className="text-current text-decoration-none"
            to={editDraftUrl}
            onClick={onClick}>
            <div className="warning-box font-weight-bold mt-1">
              <CustomIcon icon={"Activity_background"} size={19} />{" "}
              {i18n.t("DraftsScreen.requiredEdits")}
            </div>
          </Link>
        )}
      </div>
    </ListGroupItem>
  );
};

DraftListItem.propTypes = {
  draft: DraftProp,
  isProcessing: PropTypes.bool,
  onDelete: PropTypes.func.isRequired
};

export default DraftListItem;
