import { Utils } from "@figure1/f1-pro-fe-brain";
import React, { useEffect } from "react";
import Comment from "./Comment";

const ReplyThread = ({
  replies,
  reportEnabled,
  caseUuid,
  contentUuid,
  position,
  caseContent,
  commentStatus,
  isGroup = false,
  highlightComment,
  isThreadOpen,
  toggleReplyThread
}) => {
  /** ********************************** CONFIG ***************************************/
  const isInReplies = replies?.find(
    (item) => item.commentUuid === highlightComment?.commentUuid
  );
  const isNonMedicalPost = Utils.CaseUtils.isNonMedicalContent(caseContent);
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (isInReplies && !isThreadOpen) {
      toggleReplyThread();
    }
  }, [isInReplies, isThreadOpen, toggleReplyThread]);
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="p-0">
      {replies &&
        replies.map((r) => {
          if (r.commentUuid) {
            return (
              <Comment
                key={r.commentUuid}
                comment={r}
                reply
                reportEnabled={reportEnabled}
                caseUuid={caseUuid}
                contentUuid={contentUuid}
                position={position}
                caseContent={caseContent}
                commentStatus={commentStatus}
                isGroup={isGroup}
                highlightComment={highlightComment}
                isMedicalCase={!isNonMedicalPost}
              />
            );
          }
          return null;
        })}
    </div>
  );
};

export default ReplyThread;
