import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody } from "reactstrap";
import { trackCaseCMECTAClick } from "../../actions/metrics.actions";
import { CASE_CME_FRONTMATTER } from "../../constants/routes";
import { tooltipScreens } from "../../constants/local-storage-constants";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import useIsCaseCMECompleted from "../../hooks/useIsCaseCMECompleted";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import CustomIcon from "../custom-icon/CustomIcon";
import useDialog from "../dialog/useDialog";
import ProvisionalMemberCMEMessage from "../provisional-member-message/ProvisionalMemberCMEMessage";
import CaseCMECTAModalContents from "./CaseCMECTAModalContents";
import GetCreditsTooltip from "./get-credits-tooltip/GetCreditsTooltip";
import CMEPic from "../../assets/images/case cme.svg";
import CMESummaryModal from "../../pages/cme/components/CMESummaryModal";

const CaseCMECTA = ({ caseContent, className }) => {
  /** ********************************** CONFIG ***************************************/
  const cmeCompleted = useIsCaseCMECompleted(caseContent?.caseUuid);
  const { inform } = useDialog();
  const history = useHistory();
  const tooltipRef = useRef(null);
  const [showSummaryModal, setShowSummaryModal] = useState(false);
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const toggleSummaryModal = () =>
    setShowSummaryModal((prevState) => !prevState);

  const onBtnClick = async () => {
    trackCaseCMECTAClick(caseContent?.caseUuid, false);

    const result = await inform({
      body: <CaseCMECTAModalContents />,
      primaryButtonText: i18n.t("CaseCMECTA.modal.startBtn"),
      extra: {
        confirmClassName: "w-295px text-16 py-3"
      }
    });

    if (result) {
      history.push(
        getUrl(CASE_CME_FRONTMATTER, { caseId: caseContent.caseUuid })
      );
    }
  };

  const verifiedOnBtnClick = useVerifiedUserGate(
    onBtnClick,
    <ProvisionalMemberCMEMessage />
  );
  /** ********************************** RENDER ***************************************/

  const cmeCTA = (
    <>
      <div className="d-flex flex-row align-items-center justify-content-center mt-1">
        <p className="mb-0 mr-4 text-13 line-height-16 flex-grow-1 text-muted">
          {i18n.t("CaseCMECTA.explanation")}
        </p>
      </div>
      <div className="mt-3">
        <div ref={tooltipRef}>
          <Button
            color="primary"
            className="text-nowrap px-3 py-2 w-100"
            onClick={verifiedOnBtnClick}>
            {i18n.t("CaseCMECTA.btnText")}
          </Button>
        </div>
        <GetCreditsTooltip
          screen={tooltipScreens.CASE_DETAIL}
          target={tooltipRef}
          placement={"bottom"}
        />
      </div>
    </>
  );

  const completedCmeCTA = (
    <div className="d-flex flex-col align-items-center justify-content-center mt-2">
      <p className="mb-0 mr-4 text-15 line-height-20 flex-grow-1 d-flex align-items-baseline">
        <span className="mr-1">{i18n.t("CaseCMECTA.completedText")}</span>
        <CustomIcon icon="web_check" size={15} color="green" />
      </p>
      <Button
        color="link"
        className="text-nowrap p-0 helv-bold"
        onClick={toggleSummaryModal}>
        {i18n.t("CaseCMECTA.summaryBtnText")}{" "}
        <CustomIcon icon="web_chevron" size={12} color="primary" />
      </Button>
      <CMESummaryModal
        isOpen={showSummaryModal}
        toggle={toggleSummaryModal}
        activity={caseContent}
      />
    </div>
  );

  return (
    <>
      {caseContent && (
        <Card className={className}>
          <CardBody>
            <div
              className={`d-flex flex-col justify-content-start ${
                cmeCompleted && "align-items-center"
              }`}>
              <div className="align-items-start">
                <img src={CMEPic} alt="" />
              </div>
              <div className="ml-2">
                <h5 className="text-16 line-height-18 helv-med m-0">
                  {i18n.t("CaseCMECTA.title")}
                </h5>
                {!cmeCompleted && <>{cmeCTA}</>}
              </div>
            </div>
            {cmeCompleted && <>{completedCmeCTA}</>}
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default CaseCMECTA;
