import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Card, CardBody } from "reactstrap";
import {
  trackResetPasswordSendLinkClick,
  trackSettingsDeleteAccountContinue,
  trackSettingsManageAccountEntry,
  trackSettingsSignOut
} from "../../actions/metrics.actions";
import { SETTINGS_ACCOUNT_DELETE, SIGN_OUT } from "../../constants/routes";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import DeleteAccountDialog from "./components/account/DeleteAccountDialog";
import OptionRowCard from "./components/account/OptionRowCard";
import PasswordResetLinkDialog from "./components/account/PasswordResetLinkDialog";
import ResetConfirmationDialog from "./components/account/ResetConfirmationDialog";
import accountActions from "../../actions/account.actions";

const AccountSettings = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const dispatch = useDispatch();

  const [isDeleteAccountDialogOpen, setIsDeleteAccountDialogOpen] =
    useState(false);
  const [isResetPasswordDialogOpen, setIsResetPasswordDialogOpen] =
    useState(false);
  const [isResetConfirmationDialogOpen, setIsResetConfirmationDialogOpen] =
    useState(false);
  const username = useSelector((state) => state.user.username);
  const userEmail = useSelector((state) => state.user.email);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    trackSettingsManageAccountEntry();
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  const handleResetPasswordClick = async () => {
    trackResetPasswordSendLinkClick();
    try {
      const result = await dispatch(accountActions.resetPassword());
      if (result.error) {
        throw new Error(result.message);
      }

      setIsResetPasswordDialogOpen(false);
      setIsResetConfirmationDialogOpen(true);
    } catch (e) {
      console.log("failed to reset password: ", e.message);
    }
  };

  const handleSignOutClick = () => {
    trackSettingsSignOut();
    history.push(getUrl(SIGN_OUT));
  };

  const handleDeleteAccountClick = () => {
    trackSettingsDeleteAccountContinue();
    history.push(getUrl(SETTINGS_ACCOUNT_DELETE));
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card className="border-0 mb-1">
        <CardBody className="d-flex justify-content-center align-items-center">
          <h2 className="m-0 text-18 helv-bold">
            {i18n.t("Settings.Account.title")}
          </h2>
        </CardBody>
      </Card>
      <Card className="border-0 mb-4 py-2 px-4">
        <CardBody>
          <OptionRowCard
            className="mb-4"
            icon="lock_icon"
            iconColor="default-black"
            iconCircleColor="lighter-gray p-1"
            text={i18n.t("Settings.Account.resetPassword")}
            textColor="default-black"
            onClick={() => setIsResetPasswordDialogOpen(true)}
          />
          <OptionRowCard
            className="mb-4"
            icon="groups_leave"
            iconColor="default-black"
            iconCircleColor="lighter-gray p-1"
            text={i18n.t("Settings.Account.signOut")}
            textColor="default-black"
            onClick={handleSignOutClick}
          />
          <OptionRowCard
            icon="profile_trash"
            iconColor="red"
            iconCircleColor="red-opaque"
            text={i18n.t("Settings.Account.deleteAccount")}
            textColor="red"
            onClick={() => setIsDeleteAccountDialogOpen(true)}
          />
        </CardBody>
      </Card>
      <PasswordResetLinkDialog
        isOpen={isResetPasswordDialogOpen}
        onClosed={() => setIsResetPasswordDialogOpen(false)}
        onResetClick={handleResetPasswordClick}
      />
      <ResetConfirmationDialog
        userEmail={userEmail}
        isOpen={isResetConfirmationDialogOpen}
        onClosed={() => setIsResetConfirmationDialogOpen(false)}
      />
      <DeleteAccountDialog
        username={username}
        isOpen={isDeleteAccountDialogOpen}
        onClosed={() => setIsDeleteAccountDialogOpen(false)}
        onDeleteAccount={handleDeleteAccountClick}
      />
    </>
  );
};

export default AccountSettings;
