/** @format */

import React, { useRef, useState } from "react";
import { Alert, Button, Input, Label } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";

import CustomIcon from "../../../components/custom-icon/CustomIcon";
import Dialog from "../../../components/dialog/Dialog";
import DIALOG_TYPE from "../../../components/dialog/DialogType";
import Regex from "../../../constants/regex";
import { isEmpty, trim, uniq } from "lodash";
import { showErrorMessage } from "../../../actions/global.actions";
import { sendInvites } from "../../../actions/InviteColleagues.actions";
import {
  trackInviteColleaguesAddEmail,
  trackInviteColleaguesRemoveEmail,
  trackInviteColleaguesSubmission
} from "../../../actions/metrics.actions";
import i18n from "../../../utils/i18n";
import F1HorizontalDivider from "../../../components/common/F1HorizontalDivider";
import LoadingButton from "../../../components/common/LoadingButton";

const MAX_EMAIL = 15;
const ALERT_DURATION_MS = 3000;

const InviteColleaguesModal = ({ isOpen, toggle, linkToCopy = "" }) => {
  const [emails, setEmails] = useState({ email0: "" });
  const [emailErrors, setEmailErrors] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [showLinkCopiedAlert, setShowLinkCopiedAlert] = useState(false);
  const linkCopiedTimeoutRef = useRef(null);
  const isProcessing = useSelector(
    (state) => state.inviteColleages.isProcessing
  );
  const processingError = useSelector((state) => state.inviteColleages.error);

  const dispatch = useDispatch();

  const canAddEmails = Object.keys(emails).length < MAX_EMAIL;
  const hasFirstMail = emails["email0"].length > 0;

  const canSendEmails =
    hasFirstMail &&
    Object.keys(emails).length <= MAX_EMAIL &&
    Object.keys(emailErrors).length === 0;

  const [keyIndex, setKeyIndex] = useState(0);

  const validateEmail = (email, key) => {
    const isEmail = Regex.SimpleEmail.test(email);
    const newErrors = { ...emailErrors };
    if (isEmail) {
      delete newErrors[key];
    } else {
      newErrors[key] = i18n.t("InviteColleagues.invalidEmail");
    }
    setEmailErrors(newErrors);
  };

  const onSubmit = () => {
    try {
      const allEmails = uniq(
        Object.values(emails).filter((email) => !isEmpty(email))
      ).map((email) => trim(email));

      if (allEmails.length) {
        trackInviteColleaguesSubmission(allEmails);
        dispatch(sendInvites({ emails: allEmails }));
        setSubmitted(true);
      }
    } catch (e) {
      dispatch(showErrorMessage(e.message, "", false, e));
    }
  };

  const onClose = () => {
    toggle();
  };

  const onEmailAdd = () => {
    const newKey = `email${keyIndex + 1}`;
    setEmails({ ...emails, [newKey]: "" });
    setKeyIndex(keyIndex + 1);
    trackInviteColleaguesAddEmail();
  };

  const onFieldChange = (e, key) => {
    const newEmails = { ...emails };
    newEmails[key] = e.target.value;
    setEmails(newEmails); // debounce?
  };

  const onFieldRemove = (key) => {
    const newEmails = { ...emails };
    const newErrors = { ...emailErrors };
    delete newEmails[key];
    delete newErrors[key];
    setEmails(newEmails);
    setEmailErrors(newErrors);
    trackInviteColleaguesRemoveEmail();
  };

  const onBlurInput = (e, index) => {
    if (e.target.value?.length > 0) {
      validateEmail(e.target.value, index);
    }
  };

  const copyLink = () => {
    navigator.clipboard.writeText(linkToCopy);
    showLinkCopiedMessage();
  };

  const showLinkCopiedMessage = () => {
    setShowLinkCopiedAlert(true);
    clearTimeout(linkCopiedTimeoutRef.current);

    linkCopiedTimeoutRef.current = setTimeout(() => {
      clearTimeout(linkCopiedTimeoutRef.current);
      setShowLinkCopiedAlert(false);
    }, ALERT_DURATION_MS);
  };

  const ErrorLine = ({ text }) => <p className="my-1 text-danger">{text}</p>;

  const emailList = (
    <ul className="list-unstyled m-0 p-0">
      {Object.entries(emails).map(([key, value], index) => {
        return (
          <li key={key} className="mb-1">
            {index === 0 && (
              <Label>{i18n.t("InviteColleagues.dialog.inputLabel")}</Label>
            )}
            <div
              className={`d-flex ${index === 0 ? "flex-column" : "flex-row"}`}>
              <Input
                className={`email-input ${
                  emailErrors[key] ? "is-invalid" : ""
                }`}
                value={value}
                placeholder={i18n.t("InviteColleagues.dialog.inputPlaceholder")}
                onChange={(e) => {
                  onFieldChange(e, key);
                }}
                onBlur={(e) => onBlurInput(e, key)}
              />
              {index === 0 && emailErrors[key] && (
                <ErrorLine text={emailErrors[key]} />
              )}
              {index > 0 && (
                <Button color="link" onClick={() => onFieldRemove(key)}>
                  <CustomIcon
                    icon="minus_icon"
                    className="icon-red"
                    size={16}
                  />
                </Button>
              )}
            </div>
            {index > 0 && emailErrors[key] && (
              <ErrorLine text={emailErrors[key]} />
            )}
          </li>
        );
      })}
    </ul>
  );

  if (submitted && !isProcessing && !processingError) {
    return (
      <Dialog
        centered
        isOpen={isOpen}
        onCancel={toggle}
        onConfirm={onClose}
        confirmText={i18n.t("InviteColleagues.confirmDialog.button")}
        type={DIALOG_TYPE.GATED}
        icon="web-bar_save_filled"
        title={i18n.t("InviteColleagues.confirmDialog.title")}
        body={i18n.t("InviteColleagues.confirmDialog.body")}
      />
    );
  }

  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      title={
        showLinkCopiedAlert && (
          <Alert className="bg-denim-blue border-denim-blue rounded-top-0 text-center text-white text-15 font-weight-bold w-295px mt-n3 mb-0 mx-auto">
            {i18n.t("InviteColleagues.dialog.linkCopiedTitle")}
          </Alert>
        )
      }
      body={
        <div className="w-100 text-left">
          <p className="m-0 helv-bold text-center text-18 line-height-28">
            {i18n.t("InviteColleagues.dialog.title")}
          </p>
          <hr style={{ borderWidth: 4 }} className="border-lighter-gray" />
          <p className="m-0 helv-bold text-18 line-height-28">
            {i18n.t("InviteColleagues.dialog.descTitle")}
          </p>
          <p className="text-muted text-16 line-height-20">
            {i18n.t("InviteColleagues.dialog.description")}
          </p>
          <form className="my-3 w-100">
            {emailList}
            {canAddEmails && (
              <Button color="link" className="pl-0" onClick={onEmailAdd}>
                <CustomIcon
                  icon="plus_sign"
                  className="icon-denim-blue"
                  size={16}
                />{" "}
                {i18n.t("InviteColleagues.dialog.addAnother")}
              </Button>
            )}
            {!canAddEmails && (
              <p className="text-battleship-gray">
                {i18n.t("InviteColleagues.emailMax")}
              </p>
            )}
          </form>

          {processingError && (
            <div className="alert alert-danger w-100">
              <p className="m-0">{processingError}</p>
            </div>
          )}

          <LoadingButton
            color="primary"
            size="lg"
            className="m-1 py-2 w-100 helv-bold"
            loading={isProcessing}
            disabled={!canSendEmails || isProcessing}
            onClick={onSubmit}>
            {i18n.t(
              Object.keys(emails).length === 1
                ? "InviteColleagues.dialog.buttonSingular"
                : "InviteColleagues.dialog.buttonPlural"
            )}
          </LoadingButton>

          <F1HorizontalDivider className="w-75" color="light-gray">
            <span className="color-battleship-gray text-16">
              {i18n.t("InviteColleagues.dialog.divider")}
            </span>
          </F1HorizontalDivider>

          <Button
            color="secondary"
            size="lg"
            className="m-1 py-2 w-100 helv-bold"
            onClick={copyLink}>
            <CustomIcon
              className="mr-2 icon-medium-blue"
              icon={"sheet_copy_link"}
              size={18}
            />
            {i18n.t("InviteColleagues.dialog.copyLink")}
          </Button>
        </div>
      }
    />
  );
};

export default InviteColleaguesModal;
