/** @format */

import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardText,
  CardTitle,
  Container,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row
} from "reactstrap";
import lottie from "lottie-web";
import animationData from "../../../assets/lottie-files/cme-complete.json";
import { updateCaseProgress } from "../../../actions/case.actions";
import * as ROUTES from "../../../constants/routes";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import i18n from "../../../utils/i18n";
import { getUrl } from "../../../utils/route-utils";
import CMEModalButton from "./CMEModalFooterButton";
import useTrackSlideView from "../../../hooks/useTrackSlideView";
import { noop } from "lodash";
import useGatedActionGate from "../../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../../../components/gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../../metrics/constants.metrics";

const CMEModalSectionComplete = ({ caseContent, onExit }) => {
  /****************************** Config **************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  const lottieContainerRef = useRef(null);
  const lottieAnimation = useRef(null);
  const { caseUuid } = caseContent;
  const hasCertificate = !!caseContent?.certificates?.length;
  /****************************** Hooks ***************************************/

  useEffect(() => {
    dispatch(updateCaseProgress(caseUuid, null, true));
  }, [caseUuid, dispatch]);

  useEffect(() => {
    lottieAnimation.current = lottie.loadAnimation({
      container: lottieContainerRef.current,
      loop: false,
      autoplay: true,
      animationData
    });
  }, []);

  const { sendMetrics } = useTrackSlideView({
    items: [caseContent],
    next: noop,
    previous: noop,
    activeIndex: 0
  });
  /****************************** Functions ***********************************/

  const goToCMEHub = () => {
    sendMetrics();
    history.push(getCMEHubUrl());
  };

  const getCMEHubUrl = () => {
    return hasCertificate
      ? getUrl(ROUTES.CME_CERTIFICATE, { caseId: caseUuid })
      : getUrl(ROUTES.CME_POST_NOTICE, { caseId: caseUuid });
  };

  const gatedGoToCMEHub = useGatedActionGate(
    goToCMEHub,
    GATED_MODAL_TYPE.CERTIFICATE,
    UNGATED_ACTIONS.ORIGINAL_ACTIONS.CME_CLAIM_CERT,
    false,
    getCMEHubUrl()
  );
  /****************************** Render **************************************/
  return (
    <>
      <div
        className="position-relative flex-grow-1 overflow-y-auto"
        style={{
          backgroundImage: "linear-gradient(122deg, #374685, #77c2c1 115%)"
        }}>
        <ModalHeader
          className="border-0 sticky-top bg-darkest flex-column-reverse"
          tag="div"
          close={
            <Button
              className="btn-text d-inline-flex justify-content-center align-items-center"
              color="battleship-gray"
              onClick={onExit}>
              <div className="bg-battleship-gray rounded mr-2 rounded-circle">
                <CustomIcon
                  className="icon-darkest m-2"
                  icon="modal_exit"
                  size={12}
                />
              </div>
              {i18n.t("CMECaseScreens.exitActivity")}
            </Button>
          }
        />
        <ModalBody className="d-flex justify-content-center align-items-center mw-491 m-auto">
          <Card className="bg-transparent border border-width-1 border-white text-white text-center mt-5">
            <CardBody className="pb-0">
              <div className="h-180px" ref={lottieContainerRef} />
            </CardBody>
            <CardBody className="px-5 pt-0 pb-5 mx-5 mt-n3">
              <CardTitle tag="h3" className="font-weight-normal">
                {hasCertificate
                  ? i18n.t("CMECaseScreens.completeTitle")
                  : i18n.t("CMECaseScreens.noCertCompleteTitle")}
              </CardTitle>
              <CardText className="">
                {hasCertificate
                  ? i18n.t("CMECaseScreens.completeBody")
                  : i18n.t("CMECaseScreens.noCertCompleteBody")}
              </CardText>
            </CardBody>
          </Card>
        </ModalBody>
        <ModalFooter
          className="d-flex justify-content-center fixed-bottom l-0 r-0 b-0 mw-491 mx-auto mb-3 bg-dark border-0 rounded"
          style={{
            boxShadow: "0px 4px 12px 0 rgba(25, 24, 24, 0.65)"
          }}>
          <Container className="px-4">
            <Row className="align-items-center">
              <CMEModalButton
                block
                color="denim-blue"
                onClick={gatedGoToCMEHub}>
                {hasCertificate
                  ? i18n.t("CMECaseScreens.claimCertificate")
                  : i18n.t("CMECaseScreens.noCertClaimText")}
              </CMEModalButton>
            </Row>
          </Container>
        </ModalFooter>
      </div>
    </>
  );
};

CMEModalSectionComplete.propTypes = {
  caseContent: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired
};

export default CMEModalSectionComplete;
