// import React from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import GenerateLoginLink from "./components/GenerateLoginLink";
import { useEffect } from "react";
import { trackPageEntry } from "../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";

const GenerateLoginLinkPage = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ***************************************/
  useEffect(() => {
    trackPageEntry(
      METRICS_EVENT_NAME.SCREEN.FORGOT_PASSWORD.FORGOT_PASSWORD_LOGIN_LINK_ENTRY
    );
  }, []);
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-5">
            <GenerateLoginLink />
          </div>
        }
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default GenerateLoginLinkPage;
