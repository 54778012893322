/** @format */

import React from "react";
import { Button, ListGroupItem } from "reactstrap";
import { NOTIFICATION_TYPES } from "../../../constants/activity-constants";
import placeholderImage from "../../../assets/images/image-text-case-related-cases.svg";
import RejectedPostItem from "./RejectedPostItem";
import DeletedPostItem from "./DeletedPostItem";
import NewFollowerItem from "./NewFollowerItem";
import "./ActivityItem.scss";
import F1Avatar from "../../../components/common/F1Avatar";
import i18n from "../../../utils/i18n";
import ActivityItemSummary from "./ActivityItemSummary";

const generateSummary = (content) => {
  if (!content) return "";
  const { longMessage, markdownMessage, createdAt } = content;

  switch (content.type) {
    case NOTIFICATION_TYPES.REJECT:
      return (
        <RejectedPostItem
          markdownMessage={markdownMessage}
          longMessage={longMessage}
          createdAt={createdAt}
        />
      );

    case NOTIFICATION_TYPES.CASE_DELETE:
    case NOTIFICATION_TYPES.COMMENT_DELETE:
      return (
        <DeletedPostItem
          markdownMessage={markdownMessage}
          longMessage={longMessage}
          createdAt={createdAt}
        />
      );

    case NOTIFICATION_TYPES.NEW_FOLLOWER:
      return (
        <NewFollowerItem
          markdownMessage={markdownMessage}
          longMessage={longMessage}
          createdAt={createdAt}
        />
      );
    case NOTIFICATION_TYPES.CASE_NOT_DIAGNOSIS_CHOSEN:
      return (
        <>
          <ActivityItemSummary
            longMessage={longMessage}
            markdownMessage={markdownMessage}
            createdAt={createdAt}
          />
          <Button className="px-4 mt-1" color="denim-blue" outline>
            {i18n.t("ActivityScreen.viewCase")}
          </Button>
        </>
      );
    default:
      return (
        <ActivityItemSummary
          longMessage={longMessage}
          markdownMessage={markdownMessage}
          createdAt={createdAt}
        />
      );
  }
};

const ActivityItemType = ({
  image,
  type,
  content,
  sourceUser,
  onClick,
  state,
  showPointer = true
}) => {
  return (
    <ListGroupItem
      className={`d-inline-flex align-items-center px-3 ${
        showPointer ? "cursor-pointer" : ""
      } ${state !== "read" ? "bg-unread" : ""}`}
      onClick={onClick}>
      <div className="position-relative mr-3">
        {type === NOTIFICATION_TYPES.NEW_FOLLOWER && (
          <F1Avatar
            className="profile-img"
            avatar={sourceUser?.avatar}
            username={sourceUser?.username || sourceUser?.displayName}
            size="md"
          />
        )}
        {type !== NOTIFICATION_TYPES.NEW_FOLLOWER && (
          <img
            width="75"
            height="75"
            src={image ? image : placeholderImage}
            alt=""
          />
        )}
      </div>
      <div className="flex-grow-1">
        <div className="mb-0">{content}</div>
      </div>
    </ListGroupItem>
  );
};

/**
 * There are a few special cases for images:
 * - All activity should come with an image, except for
 * text only cases which uses a local icon
 * - If it's a profile, the image should be circular
 */
const ActivityItem = ({ content, onClick }) => {
  const { type, notificationType, caseMedia, sourceUser, createdAt, state } =
    content;
  const summary = generateSummary(content);

  return (
    <ActivityItemType
      image={caseMedia?.caseMediaUrl}
      type={type || notificationType}
      content={summary}
      sourceUser={sourceUser}
      createdAt={createdAt}
      onClick={onClick}
      state={state}
    />
  );
};

export default ActivityItem;
