import React from "react";
import PropTypes from "prop-types";
import { Button, ModalHeader } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import classNames from "../../utils/class-names-utils";

const BaseDialogHeader = ({ center, children, onCancel, showCloseButton }) => {
  const closeProps = showCloseButton
    ? {
        close: (
          <div
            className="position-absolute r-0 p-3 pr-3"
            style={{ zIndex: "1080" }}>
            <Button close onClick={onCancel}>
              <CustomIcon icon="modal_exit" size={14} color={"black"} />
            </Button>
          </div>
        )
      }
    : {};

  return (
    <ModalHeader
      tag="div"
      className="border-0 p-0"
      cssModule={{ "modal-title": "modal-title w-100" }}
      {...closeProps}>
      {children && (
        <div className={classNames("mt-3 mx-4 px-3", center && "text-center")}>
          {children}
        </div>
      )}
    </ModalHeader>
  );
};

BaseDialogHeader.propTypes = {
  center: PropTypes.bool,
  onCancel: PropTypes.func.isRequired
};

BaseDialogHeader.defaultProps = {
  center: false
};

export default BaseDialogHeader;
