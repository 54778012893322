import React from "react";
import classnames from "../../utils/class-names-utils";
import "./F1AlertBoxWithArrow.scss";

const POSITIONS = {
  TOP: "top",
  BOTTOM: "bottom",
  LEFT: "left",
  RIGHT: "right"
};

const F1AlertBoxWithArrow = ({
  position = "top",
  text,
  color = "default-black",
  className,
  arrowClassName
}) => {
  const neededClass = (() => {
    switch (position) {
      case POSITIONS.TOP:
        return {
          box: `mb-3 bg-${color}`,
          arrow: `arrow--bottom border-top-${color}`
        };
      case POSITIONS.BOTTOM:
        return {
          box: `mt-3 bg-${color}`,
          arrow: `arrow--top border-bottom-${color}`
        };
      case POSITIONS.LEFT:
        return {
          box: `mr-3 bg-${color}`,
          arrow: `arrow--right border-left-${color}`
        };
      case POSITIONS.RIGHT:
        return {
          box: `ml-3 bg-${color}`,
          arrow: `arrow--left border-right-${color}`
        };
      default:
        return "";
    }
  })();

  return (
    <>
      <div className={classnames("box", neededClass.box, className)}>
        {position === POSITIONS.RIGHT && (
          <div className={classnames(neededClass.arrow, arrowClassName)} />
        )}
        <span className="d-flex align-items-center">{text}</span>
        {position !== POSITIONS.RIGHT && (
          <div className={classnames(neededClass.arrow, arrowClassName)} />
        )}
      </div>
    </>
  );
};

export default F1AlertBoxWithArrow;
