import React from "react";
import { Col, Container, Row } from "reactstrap";
import * as PropTypes from "prop-types";
import HeaderButton from "../components/common/HeaderButton";
import useTheme from "../components/theme/useTheme";
import classnames from "../utils/class-names-utils";
import THEMES from "../components/theme/Theme";
import CustomIcon from "../components/custom-icon/CustomIcon";
import TouchableOpacity from "../pages/cme/components/TouchableOpacity";
import useIsGated from "../hooks/useIsGated";
import { isMobileOnly } from "react-device-detect";
import BackButton from "../components/common/BackButton";

const Figure1PageContainer = React.forwardRef((props, ref) => {
  const { theme } = useTheme();
  const isGated = useIsGated();
  return (
    <>
      <Container
        className={classnames(
          "sticky-top",
          theme === THEMES.DARK ? "bg-darkest" : "bg-lighter-gray",
          props.className
        )}>
        {props.isChildPage && (
          <Row className={classnames(isMobileOnly ? "" : "py-3")}>
            {!isGated && (
              <>
                <Col>
                  <BackButton buttonText={props.backButtonText || null} />
                </Col>
                <Col>
                  {props.headerButtonTitle && (
                    <HeaderButton
                      buttonText={props.headerButtonTitle}
                      onClick={props.headerButtonClick}
                      loading={props.headerButtonLoadingStatus}
                    />
                  )}
                </Col>
                {props.isContactSupport && (
                  <Col className="d-flex flex-column align-items-end">
                    <TouchableOpacity onPush={props.contactSupportClick}>
                      <CustomIcon
                        icon={"contact_support"}
                        color={"#1a252b"}
                        size={20}
                      />
                    </TouchableOpacity>
                  </Col>
                )}
              </>
            )}
          </Row>
        )}
        {props.headerRowSlot && <>{props.headerRowSlot}</>}
      </Container>
      <Container className={classnames("position-relative", props.className)}>
        <Row className={props.isChildPage ? "pt-0 pb-2" : props.headerClass}>
          {props.children}
        </Row>
      </Container>
    </>
  );
});

Figure1PageContainer.propTypes = {
  isChildPage: PropTypes.bool,
  isContactSupport: PropTypes.bool,
  contactSupportClick: PropTypes.func,
  headerClass: PropTypes.string,
  headerButtonTitle: PropTypes.string,
  headerButtonClick: PropTypes.func,
  headerButtonLoadingStatus: PropTypes.bool,
  headerRowSlot: PropTypes.element
};

Figure1PageContainer.defaultProps = {
  isChildPage: true,
  headerClass: "py-5"
};

export default Figure1PageContainer;
