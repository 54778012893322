import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "../../components/dialog/Dialog";
import i18n from "../../utils/i18n";
import { useParams } from "react-router-dom";
import useCurrentGroup from "../../hooks/useCurrentGroup";
import { trackPageEntry } from "../../actions/metrics.actions";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";

const GroupDescriptionModal = ({ isOpen, toggle }) => {
  /************************************ CONFIG ***************************************/
  const routeParams = useParams();
  const currentGroup = useCurrentGroup(routeParams?.feedTypeUuid);

  /************************************ HOOKS ****************************************/
  useEffect(() => {
    if (isOpen) {
      trackPageEntry(METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_DESCRIPTION_ENTRY, {
        groupUuid: currentGroup?.groupUuid
      });
    }
  }, [isOpen, currentGroup?.groupUuid]);
  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      toggle={toggle}
      body={
        <div className="w-100">
          <h2 className="d-flex align-items-center justify-content-center px-0 pb-2 flex-grow-1 m-0 helv-bold text-18 line-height-28">
            {i18n.t("GroupsFeed.DescriptionModalTitle")}
          </h2>
          <hr className="border-width-4 border-lighter-gray" />
          <h2 className="d-flex align-item-center text-left py-3 text-15 line-height-22 m-0 ">
            {i18n.t(currentGroup?.groupDescription)}
          </h2>
        </div>
      }
      confirmText={i18n.t("common.done")}
      onConfirm={toggle}
    />
  );
};

GroupDescriptionModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

GroupDescriptionModal.defaultProps = {};

export default GroupDescriptionModal;
