import { Button } from "reactstrap";
import CustomIcon from "../../../../components/custom-icon/CustomIcon";
import BaseDialogFooter from "../../../../components/dialog/BaseDialogFooter";
import Dialog from "../../../../components/dialog/Dialog";
import DIALOG_TYPE from "../../../../components/dialog/DialogType";
import i18n from "../../../../utils/i18n";
import EmailIcon from "../../../../assets/images/email_icon_web.svg";

const ResetConfirmationDialog = ({ userEmail, isOpen, onClosed }) => {
  /************************************ CONFIG ***************************************/

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/
  return (
    <Dialog
      isOpen={isOpen}
      type={DIALOG_TYPE.INFORM}
      body={
        <div className="mb-3">
          <img src={EmailIcon} alt="" className="mt-4" />
          <h5 className="helv-bold text-22 my-4">
            {i18n.t("Settings.Account.resetPasswordConfirmationDialog.title")}
          </h5>
          <p className="mb-4 text-18 line-height-26">
            {i18n.t(
              "Settings.Account.resetPasswordConfirmationDialog.description",
              { email: userEmail }
            )}
          </p>
          <div
            className="d-flex align-items-center bg-lighter-gray p-2 text-15 line-height-16"
            style={{ borderRadius: "4px" }}>
            <CustomIcon className="mr-2" size={18} icon="information_filled" />
            {i18n.t(
              "Settings.Account.resetPasswordConfirmationDialog.validity"
            )}
          </div>
        </div>
      }
      customFooter={
        <BaseDialogFooter>
          <Button
            className={"text-16 helv-bold px-5 py-2 mx-auto mb-4"}
            block
            size="lg"
            color="primary"
            onClick={onClosed}>
            {i18n.t("common.done")}
          </Button>
          <p className="m-0 text-muted text-14 line-height-16">
            {i18n.t("Settings.Account.resetPasswordConfirmationDialog.note")}
          </p>
        </BaseDialogFooter>
      }
      toggle={onClosed}
      onCancel={onClosed}
      onClosed={onClosed}
    />
  );
};

export default ResetConfirmationDialog;
