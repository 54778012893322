import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem
} from "reactstrap";
import {
  CME_ROOT,
  DRAFTS_ROOT,
  SAVED_CASES_ROOT,
  PROFILE_NETWORK_TYPE,
  PROFILE_DETAIL,
  SETTINGS_ROOT,
  SIGN_OUT
} from "../../constants/routes";
import DropdownItemLink from "../common/DropdownItemLink";
import { useSelector } from "react-redux";
import { getUserCountry } from "../../utils/cme-utils";
import CustomIcon from "../custom-icon/CustomIcon";
import { getUrl } from "../../utils/route-utils";
import appSettings from "../../appSettings";

const MoreDropdownV2 = () => {
  const { t } = useTranslation();
  const userUuid = useSelector((state) => state.user.userUuid);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);
  const isUS = useSelector((state) => getUserCountry(state.user) === "US");
  const cmeLinkText = isUS ? t("navigation.cme") : t("navigation.cme-non-us");

  return (
    <Dropdown
      className="dropdown-more"
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}>
      <DropdownToggle tag="span" aria-expanded={isDropdownOpen}>
        <div className="d-flex flex-column justify-content-center align-items-center">
          <CustomIcon
            className="inactive-icon mb-1"
            icon={"hamburger_menu"}
            size={22}
          />
          <span className="helv-med text-11">{t("navigation.menu")}</span>
        </div>
      </DropdownToggle>
      <DropdownMenu
        className="dropdown-menu--right dropdown-menu--pointer-top-center dropdown-menu-scrollable"
        right>
        <DropdownItemLink to={getUrl(PROFILE_DETAIL, { userUuid })}>
          {t("navigation.myProfile")}
        </DropdownItemLink>
        <DropdownItemLink to={DRAFTS_ROOT}>
          {t("navigation.drats")}
        </DropdownItemLink>
        <DropdownItemLink to={SAVED_CASES_ROOT}>
          {t("navigation.savedCases")}
        </DropdownItemLink>
        <DropdownItemLink to={CME_ROOT}>{cmeLinkText}</DropdownItemLink>
        <DropdownItemLink
          to={{
            pathname: getUrl(PROFILE_NETWORK_TYPE, {
              userUuid,
              viewType: "followers"
            }),
            state: {
              context: "Hamburger menu"
            }
          }}>
          {t("Network.MyNetwork")}
        </DropdownItemLink>
        <DropdownItemLink to={SETTINGS_ROOT}>
          {t("navigation.account-settings")}
        </DropdownItemLink>
        <DropdownItemLink to={SIGN_OUT}>
          {t("navigation.log-out")}
        </DropdownItemLink>
        <DropdownItem divider className="mx-4" />
        <span tabIndex="-1" className="dropdown-item-text text-battleship-gray">
          {t("navigation.version", {
            versionNum: appSettings.version
          })}
        </span>
      </DropdownMenu>
    </Dropdown>
  );
};

MoreDropdownV2.propTypes = {};

export default MoreDropdownV2;
