import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";
import Button from "reactstrap/lib/Button";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import TopicalFeedScreen from "../feed/TopicalFeedScreen";
import { Link, useHistory, useParams } from "react-router-dom";
import i18n from "../../utils/i18n";
import { trackShareCase } from "../../actions/metrics.actions";
import PromoCardFeed from "../feed/components/PromoCardFeed";
import ChannelNavigation from "../../components/channel-navigation/ChannelNavigation";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import FeedBanner from "./FeedBanner";
import { FEED_NAMES } from "../../constants/feed-type";
import EmptyTopicalFeedMessage from "../feed/components/EmptyTopicalFeedMessage";
import TopicalEndOfFeedCard from "../feed/components/TopicalEndOfFeedCard";
import RecommendedEndOfFeedCard from "../feed/components/RecommendedEndOfFeedCard";
import EmptyRecommendedFeedMessage from "../feed/components/EmptyRecommendedFeedMessage";
import useIsGroupFeed from "../../hooks/useIsGroupFeed";
import GroupEndOfFeedCard from "../groups/GroupEndOfFeedCard";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";
import { useFirstInstitutionGroupUuid } from "../../selectors/group.selectors";
import { isMobile } from "react-device-detect";
import useIsRecommendedFeed from "../../hooks/useIsRecommendedFeed";
import FeedSearch from "../../components/global-header/FeedSearch";
import { find, get, isEmpty, negate } from "lodash";
import { FEED_META_LABELS } from "../../constants/feed-type";
import "./HomePage.scss";
import WhatsNewModal from "../whatsnew/WhatsNewModal";
import InviteColleaguesCard from "./components/InviteColleaguesCard";

const HomePage = () => {
  /** ********************************** CONFIG ***************************************/

  const routeParams = useParams();
  const history = useHistory();
  const searchRef = useRef(null);

  const user = useSelector((state) => state.user);
  const [activeFeed, setActiveFeed] = useState(null);
  const hasGroups = useSelector((state) => {
    if (
      !state.groups?.data ||
      (state.groups?.data && state.groups?.isProcessing)
    )
      return undefined;

    return state.groups?.data;
  });

  const isGroupFeed = useIsGroupFeed(routeParams.feedTypeUuid);
  const currentFeedUuid = useCurrentFeedTypeUuid();

  const firstInstitutionGroupUuid = useFirstInstitutionGroupUuid();

  const isRecommended = useIsRecommendedFeed();
  const feed = useSelector((state) => {
    const currentFeed = get(
      state,
      `references.feedsMeta.data.${currentFeedUuid}`
    );
    const everythingFeed = Object.values(
      state?.references?.feedsMeta?.data || {}
    )?.find((m) => m.label === FEED_META_LABELS.EVERYTHING);

    return find([currentFeed, everythingFeed], negate(isEmpty));
  });

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (hasGroups !== undefined) {
      if (routeParams.feed === FEED_NAMES.GROUP && isGroupFeed) {
        history.replace(
          getUrl(ROUTES.GROUPS_FEED, {
            feedTypeUuid: routeParams.feedTypeUuid
          })
        );
      } else if (routeParams.feed === FEED_NAMES.TOPIC && isGroupFeed) {
        history.replace(
          getUrl(ROUTES.HOME_ROOT_TOPIC, {
            feed: FEED_NAMES.GROUP,
            feedTypeUuid: routeParams.feedTypeUuid
          })
        );
      } else if (routeParams.feed === FEED_NAMES.GROUP && !isGroupFeed) {
        history.replace(
          getUrl(ROUTES.HOME_ROOT_TOPIC, {
            feed: FEED_NAMES.TOPIC,
            feedTypeUuid: routeParams.feedTypeUuid
          })
        );
      }
    }
  }, [hasGroups, routeParams, history, isGroupFeed]);

  useEffect(() => {
    // Check to see if we are at the "home" route. If so, nothing happens on the
    // base "home" route, so let's just re-route to the recommended feed or group feed
    if (Object.values(FEED_NAMES).includes(routeParams.feed)) {
      return;
    }

    const redirectUrl =
      user.groups?.length > 0 && firstInstitutionGroupUuid
        ? getUrl(ROUTES.GROUPS_FEED, {
            feedTypeUuid: firstInstitutionGroupUuid
          })
        : getUrl(ROUTES.HOME_ROOT_FEED, { feed: FEED_NAMES.RECOMMENDED });

    history.replace(redirectUrl);
  }, [
    firstInstitutionGroupUuid,
    history,
    routeParams.feed,
    routeParams.feedTypeUuid,
    JSON.stringify(user?.groups)
  ]);

  useEffect(() => {
    if (routeParams.feed === FEED_NAMES.EVERYTHING) {
      setActiveFeed(FEED_NAMES.EVERYTHING);
    } else if (routeParams.feed === FEED_NAMES.RECOMMENDED) {
      setActiveFeed(FEED_NAMES.RECOMMENDED);
    } else if (routeParams.feed === FEED_NAMES.TRENDING) {
      setActiveFeed(FEED_NAMES.TRENDING);
    } else if (routeParams.feed === FEED_NAMES.QUIZZES) {
      setActiveFeed(FEED_NAMES.QUIZZES);
    } else if (
      routeParams.feed === FEED_NAMES.TOPIC ||
      routeParams.feed === FEED_NAMES.GROUP
    ) {
      setActiveFeed(routeParams.feedTypeUuid);
    }
  }, [history, routeParams]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (!user) {
    return <Loading />;
  }

  let content = (
    <TopicalFeedScreen
      emptyFeedComponent={<EmptyRecommendedFeedMessage />}
      eofComponent={<RecommendedEndOfFeedCard />}
    />
  );

  if (routeParams.feed === FEED_NAMES.EVERYTHING) {
    content = (
      <>
        <FeedBanner />
        <TopicalFeedScreen />
      </>
    );
  } else if (routeParams.feed === FEED_NAMES.TRENDING) {
    content = (
      <>
        <FeedBanner />
        <TopicalFeedScreen
          emptyFeedComponent={<EmptyTopicalFeedMessage />}
          eofComponent={<TopicalEndOfFeedCard />}
        />
      </>
    );
  } else if (routeParams.feed === FEED_NAMES.QUIZZES) {
    content = (
      <>
        <FeedBanner />
        <TopicalFeedScreen
          emptyFeedComponent={<EmptyTopicalFeedMessage />}
          eofComponent={<TopicalEndOfFeedCard />}
        />
      </>
    );
  } else if (routeParams.feed === FEED_NAMES.TOPIC) {
    // need a sanity check that the feedTypeUuid isn't a group
    content = (
      <>
        <FeedBanner />
        <TopicalFeedScreen
          emptyFeedComponent={<EmptyTopicalFeedMessage />}
          eofComponent={<TopicalEndOfFeedCard />}
        />
      </>
    );
  } else if (routeParams.feed === FEED_NAMES.GROUP) {
    if (!hasGroups) {
      content = null;
    }
    content = (
      <>
        <TopicalFeedScreen
          emptyFeedComponent={<div></div>}
          eofComponent={<GroupEndOfFeedCard groupUuid={currentFeedUuid} />}
        />
      </>
    );
  }

  const rightSideContent = (
    <div className="sticky-top feed-sidebar-right z-1 mt-n5 pt-5 mb-3">
      <div className="d-none d-lg-block">
        <Button
          className="w-100 text-15 py-3"
          size="lg"
          color="primary"
          tag={Link}
          onClick={() => {
            trackShareCase({
              context: UPLOAD_CASE_ORIGIN.FEED,
              groupUuid: isGroupFeed ? currentFeedUuid : null
            });
          }}
          to={ROUTES.CREATE_NEW_CASE_POST_OPTIONS}>
          {i18n.t("navigation.submit-case")}
        </Button>
      </div>
      <div className="pt-3 mb-2">
        <PromoCardFeed />
      </div>
      <div>
        <InviteColleaguesCard />
      </div>
    </div>
  );

  return (
    <>
      {isMobile && isRecommended && (
        <div
          className={`d-flex w-100 align-items-center bg-white px-3 py-2 search-shadow`}>
          <FeedSearch
            ref={searchRef}
            inputStyle={{
              backgroundColor: "#eef1f7",
              borderWidth: 0,
              borderRadius: "4px"
            }}
            currentFeed={feed}
          />
        </div>
      )}
      <Figure1Page3Col
        leftSidebarContent={<ChannelNavigation activeFeed={activeFeed} />}
        mainContent={
          <>
            {content}
            <WhatsNewModal />
          </>
        }
        rightSidebarContent={rightSideContent}
      />
    </>
  );
};

export default HomePage;
