import React, { useRef, useState } from "react";
import { UncontrolledTooltip } from "reactstrap";
import { Link } from "react-router-dom";
import { head, pick } from "lodash";
import PropTypes from "prop-types";
import F1AvatarLink from "../common/F1AvatarLink";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import { keysToCamelCase } from "../../utils/camelCaseUtils";
import i18n from "../../utils/i18n";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import { getUserDisplayName } from "../../utils/user-utils";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { useDispatch } from "react-redux";
import {
  setGatedMessageOrigin,
  setGatedMessageType
} from "../../actions/global.actions";
import useIsGated from "../../hooks/useIsGated";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import { trackUserProfileClick } from "../../actions/metrics.actions";
import { StaticStrings } from "../../constants/static-string-constants";
import useFollowUser from "../../hooks/useFollowUser";
import LoadingButton from "../common/LoadingButton";
import AnonymousCaseModalContents from "./AnonymousCaseModalContents";
import useDialog from "../dialog/useDialog";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import useSignUpModalClick from "../../hooks/useSignUpModalClick";

const MAX_USERNAME_LENGTH = 18;

const CaseUserCell = ({
  caseContent,
  tagline,
  inline = false,
  displayFollowUser = false,
  truncateUsername = false
}) => {
  /** ********************************** CONFIG ***************************************/

  const [showFollowing, setShowFollowing] = useState(false);
  const toolTipAnchor = useRef(null);
  const { theme } = useTheme();
  const { sponsoredContent } = keysToCamelCase(caseContent);
  const dispatch = useDispatch();
  const isGated = useIsGated();
  const { inform } = useDialog();

  // Note: This seems to be for spon con from admin tools only.
  // `isSponsored` is old placeholder code. Might need to extend this if institutional profiles & their posts are different.
  const { sponsoredText, disclosureText } = pick(sponsoredContent, [
    "sponsoredText",
    "disclosureText"
  ]);

  const author = head(caseContent?.authors) || {};
  const isAnonymous = caseContent?.isAnonymous || author.isAnonymous;
  const isCommentAuthorDeleted = author.isDeleted;
  const quizLayout = caseContent?.isPoll || caseContent?.isQuiz;

  const userExists = useAnonUserExists();
  const { showLoginModal } = useLoginModalClick();
  const { showSignUpModal } = useSignUpModalClick();

  const toSeeMoreClick = userExists ? showLoginModal : showSignUpModal;

  const { isCurrentUser, isFollowing, isProcessing, onFollowClick } =
    useFollowUser({
      userUuid: author.userUuid,
      hasAvatar: !!author.avatar,
      username: author.username,
      context: "Feedcard"
    });
  const shouldDisplayFollowUser =
    displayFollowUser &&
    !isCurrentUser &&
    (showFollowing || !isFollowing) &&
    !isAnonymous;
  const shouldDisplayToSeeMore =
    !shouldDisplayFollowUser && quizLayout && isGated;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const trackUserClick = () => {
    trackUserProfileClick({ ...author, isGated });
  };

  const gatedClick = () => {
    trackUserClick();

    if (quizLayout) {
      toSeeMoreClick();
    } else {
      dispatch(setGatedMessageType(GATED_MODAL_TYPE.PROFILE));
      dispatch(
        setGatedMessageOrigin(UNGATED_ACTIONS.ORIGINAL_ACTIONS.AUTHOR_NAME)
      );
    }
  };

  const anonClick = async () => {
    await inform({
      body: <AnonymousCaseModalContents />,
      primaryButtonText: i18n.t("common.confirm"),
      extra: {
        confirmClassName: "w-295px text-16 py-3"
      }
    });
  };

  /** ********************************** RENDER ***************************************/
  const authorProps = {
    ...author,
    avatar: author.avatar || caseContent.avatar
  };
  let avatarEl;
  let authorUsername;
  let authorLink;
  let nameContainer;
  let toolTipAnchorText;

  if (!isAnonymous) {
    avatarEl = <F1AvatarLink user={authorProps} quizLayout={quizLayout} />;
    authorUsername =
      truncateUsername && author.username?.length > MAX_USERNAME_LENGTH
        ? `${author.username.substring(0, MAX_USERNAME_LENGTH)}...`
        : author.username;

    toolTipAnchorText = getUserDisplayName(author, sponsoredText);

    authorLink = isGated ? (
      <div
        onClick={gatedClick}
        className={classNames(
          "cursor-pointer",
          "text-decoration-none",
          theme === THEMES.DARK ? "text-light" : "text-default-black"
        )}>
        {authorUsername}
      </div>
    ) : (
      <Link
        className={classNames(
          "text-decoration-none",
          theme === THEMES.DARK ? "text-light" : "text-default-black"
        )}
        onClick={trackUserClick}
        to={getUrl(ROUTES.PROFILE_DETAIL, {
          userUuid: author.userUuid
        })}>
        {authorUsername}
      </Link>
    );

    nameContainer = isCommentAuthorDeleted ? (
      <p
        className={classNames(
          "font-weight-bold mb-0",
          theme === THEMES.DARK ? "text-light" : "text-muted"
        )}>
        {i18n.t("Profile.deletedAccount")}
      </p>
    ) : (
      <>
        {tagline && (
          <p className="text-uppercase font-weight-bold mb-0 pr-2">{tagline}</p>
        )}
        <div className="d-inline-flex align-items-center">
          <div className="font-weight-bold mb-0">{authorLink}</div>
          {shouldDisplayFollowUser && (
            <>
              <span className="px-2 text-default-black">
                {StaticStrings.middleDot}
              </span>
              <LoadingButton
                className={classNames(
                  "text-13 p-0 font-weight-bold",
                  isFollowing ? "text-default-black" : "text-cool-blue"
                )}
                color="link"
                spinnerSize="sm"
                spinnerColor="primary"
                disabled={isProcessing}
                loading={isProcessing}
                onClick={() => {
                  if (!isFollowing) {
                    setShowFollowing(true);
                  }
                  onFollowClick();
                }}>
                {isFollowing
                  ? i18n.t("common.unfollow")
                  : i18n.t("common.follow")}
              </LoadingButton>
            </>
          )}
          {shouldDisplayToSeeMore && (
            <>
              <span className="px-2 text-default-black">
                {StaticStrings.middleDot}
              </span>
              <LoadingButton
                className="text-13 p-0 font-weight-bold text-cool-blue"
                color="link"
                spinnerSize="sm"
                spinnerColor="primary"
                disabled={isProcessing}
                loading={isProcessing}
                onClick={toSeeMoreClick}>
                {userExists
                  ? i18n.t("common.loginToSeeMore")
                  : i18n.t("common.signupToSeeMore")}
              </LoadingButton>
            </>
          )}
        </div>
        {inline && (
          <div className="divider divider-aqua divider-14 mx-2 mb-1" />
        )}
        <p className="mb-0 cursor-pointer" ref={toolTipAnchor}>
          {toolTipAnchorText}
        </p>
        {!!disclosureText && (
          <UncontrolledTooltip
            className="pointer-events-none"
            popperClassName="tooltip-battleship-gray"
            placement="bottom"
            target={toolTipAnchor}>
            {disclosureText}
          </UncontrolledTooltip>
        )}
      </>
    );
  } else {
    avatarEl = <F1AvatarLink user={authorProps} />;
    toolTipAnchorText = i18n.t("caseDetail.anonymousCase.postedAnonymously");
    authorLink = (
      <div
        onClick={() => (isGated ? gatedClick() : anonClick())}
        className={classNames(
          "cursor-pointer",
          "text-decoration-none",
          theme === THEMES.DARK ? "text-light" : "text-battleship-gray"
        )}>
        <div className="d-flex">{author.profileDisplayName}</div>
      </div>
    );

    nameContainer = (
      <>
        <div className="d-inline-flex align-items-center">
          <div className="mb-0">{authorLink}</div>
        </div>
        {inline && (
          <div className="divider divider-aqua divider-14 mx-2 mb-1" />
        )}
        <p
          onClick={() => (isGated ? gatedClick() : anonClick())}
          className="mb-0 cursor-pointer"
          ref={toolTipAnchor}>
          {toolTipAnchorText}
        </p>
      </>
    );
  }

  return (
    <div className="d-flex align-items-center justify-content-start">
      <div className="h-40px w-40px mr-2">{avatarEl}</div>
      <div
        className={classNames(
          "text-13",
          inline && "d-inline-flex justify-content-between align-items-center",
          theme === THEMES.DARK
            ? "text-light-gray-blue"
            : "text-battleship-gray"
        )}>
        {nameContainer}
      </div>
    </div>
  );
};

CaseUserCell.propTypes = {
  caseContent: PropTypes.shape({
    isSponsored: PropTypes.bool,
    sponsoredContent: PropTypes.shape({
      disclosureText: PropTypes.string,
      sponsoredText: PropTypes.string
    }),
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        specialtyUuid: PropTypes.string,
        depth: PropTypes.number,
        firstName: PropTypes.string,
        legacyAccount: PropTypes.bool,
        userUid: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        userUuid: PropTypes.string,
        label: PropTypes.string,
        name: PropTypes.string,
        lastName: PropTypes.string
      })
    )
  })
};
export default CaseUserCell;
