/** @format */

const CASE_STATE = {
  REJECTED: "REJECTED",
  REPORTED: "REPORTED",
  DELETED: "DELETED",

  DRAFT: "DRAFT",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  FLAGGED: "FLAGGED",
  EDIT_SUGGESTED: "EDIT_SUGGESTED",
  APPROVED: "APPROVED",
  PENDING_NLP: "PENDING_NLP",
  PENDING_TAGGING: "PENDING_TAGGING",
  FLAGGED_TAGGER: "FLAGGED_TAGGER",

  SC_DRAFT: "SC_DRAFT",
  SC_REVIEW: "SC_REVIEW",
  SC_APPROVED: "SC_APPROVED",
  SC_ARCHIVED: "SC_ARCHIVED"
};

export const DISPLAYABLE_CASE_STATES = [
  CASE_STATE.APPROVED,
  CASE_STATE.SC_APPROVED,
  CASE_STATE.SC_REVIEW
];

export default CASE_STATE;
