import React from "react";
import { Card, CardBody, CardTitle, Col, Container, Row } from "reactstrap";
import { isMobileOnly } from "react-device-detect";
import { FEED_NAMES } from "../../constants/feed-type";
import { HOME_ROOT_FEED } from "../../constants/routes";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import CustomIcon from "../custom-icon/CustomIcon";

const MoreForYouNavigationItem = ({ icon, text, bgColor, onClick, feed }) => {
  return (
    <Col>
      <Card
        className={`min-h-130px ${isMobileOnly ? "mb-2" : ""} cursor-pointer`}
        tag="a"
        href={getUrl(HOME_ROOT_FEED, { feed })}>
        <CardBody className={`px-2 pt-3 pb-2 bg-${bgColor}`}>
          <CustomIcon icon={icon} size={34} className="mb-3" />
          <p className="m-0 helv-bold text-16 line-hight-24 text-white">
            {text}
          </p>
        </CardBody>
      </Card>
    </Col>
  );
};

const MoreForYouNavigation = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Card className="mt-2">
      <CardBody>
        <CardTitle tag="h5">{i18n.t("caseDetail.moreForYou.title")}</CardTitle>
        <Container className="p-0">
          <Row className="">
            <MoreForYouNavigationItem
              icon="more-quiz"
              text={i18n.t("caseDetail.moreForYou.quizzes")}
              bgColor="pale-red"
              feed={FEED_NAMES.QUIZZES}
            />
            <MoreForYouNavigationItem
              icon="more-new_cases"
              text={i18n.t("caseDetail.moreForYou.newCases")}
              bgColor="pale-purple"
              feed={FEED_NAMES.EVERYTHING}
            />
            <MoreForYouNavigationItem
              icon="more-trending"
              text={i18n.t("caseDetail.moreForYou.trendingNow")}
              bgColor="pale-orange"
              feed={FEED_NAMES.TRENDING}
            />
          </Row>
        </Container>
      </CardBody>
    </Card>
  );
};

export default MoreForYouNavigation;
