import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import { resetGatedState, setGatedState } from "../../actions/global.actions";
import { setAnonUserData } from "../../actions/login.actions";
import useCreateAnonUserIfNeeded from "../../hooks/useCreateAnonUserIfNeeded";
import useGlobalLoading from "../../hooks/useIsGlobalLoading";
import useUserCompletedOnboarding from "../../hooks/useUserCompletedOnboarding";

import LoadingPage from "../../pages/home/LoadingPage";
import { userSignedIn } from "../../utils/route-utils";
import LoginModal from "../login-modal/LoginModal";
import SignUpModal from "../signup-modal/SignUpModal";

const UngatedRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const loc = useLocation();
  const isUserCompleted = useUserCompletedOnboarding();
  const globalLoading = useGlobalLoading();
  const isSignedIn = useSelector((state) => userSignedIn(state.user));
  const userExists = !!useSelector((state) => state.authentication.user);
  const isInitialized = useSelector(
    (state) => state.authentication.initialized
  );
  const isInitializing = useSelector(
    (state) => state.authentication.initializing
  );

  const urlParams = new URLSearchParams(loc.search);
  const email = urlParams.get("email");

  const isGated = isInitialized && (!isSignedIn || !isUserCompleted);

  const componentProps = rest.componentProps;

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (!isGated) {
      dispatch(setGatedState(false, false));
    } else if (!isInitializing && isGated) {
      dispatch(setGatedState(true, false));
    }

    if (isInitialized && isSignedIn && isUserCompleted) {
      // This is for an edge case where the user was gated, completed their sign up
      // but somehow got back here and was still somehow gated, fix it
      dispatch(resetGatedState());
    }
  }, [
    isInitializing,
    isInitialized,
    isGated,
    isSignedIn,
    isUserCompleted,
    dispatch
  ]);

  useEffect(() => {
    if (email) {
      dispatch(setAnonUserData(undefined, undefined, email));
      history.replace(loc.pathname, {});
    }
  }, [history, dispatch, loc, email]);

  useCreateAnonUserIfNeeded();

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Route
      {...rest}
      render={(routeProps) => {
        const content =
          globalLoading || !isInitialized || !userExists ? (
            <LoadingPage />
          ) : (
            <Component {...routeProps} {...componentProps} />
          );

        return (
          <>
            <LoginModal />
            <SignUpModal />
            {content}
          </>
        );
      }}
    />
  );
};

export default UngatedRoute;
