import React from "react";
import { Button, CardBody, CardText } from "reactstrap";
import ReactMarkdown from "react-markdown";
import { Selectors, Utils } from "@figure1/f1-pro-fe-brain";
import CaseMediaView from "./CaseMediaView";
import CaseTitle from "./CaseTitle";
import QuestionExternalLink from "./QuestionExternalLink";
import CaseReferences from "./CaseReferences";
import {
  CASE_CARD_ORIGIN,
  SHARE_LOCATIONS,
  TRANSLATION_SOURCES,
  TRANSLATION_VIEWS
} from "../../metrics/constants.metrics";
import GatedCaseNotice from "./GatedCaseNotice";
import F1Spacer from "../common/F1Spacer";
import { useCaseTranslation, useIsCaseAuthor } from "../../hooks";
import CaseDetailUpdatesCard from "./CaseDetailUpdatesCard";
import i18n from "../../utils/i18n";
import { trackTranslationClicked } from "../../actions/metrics.actions";
import { useSelector } from "react-redux";
import CaseActions from "./CaseActions";
import { getUrl } from "../../utils/route-utils";
import { Link } from "react-router-dom";
import { CASE_DETAIL_UPDATE } from "../../constants/routes";
import CustomIcon from "../custom-icon/CustomIcon";
import CaseUserCell from "./CaseUserCell";
import { isMobileOnly } from "react-device-detect";
import AboutThisCaseCard from "./AboutThisCaseCard";
import { ELIGIBLE_CASE_LABELS_ABOUT_THIS_CASE } from "../../constants/case-label-types";
import CaseSpecialtiesBar from "./CaseSpecialtiesBar";

const CaseDetailCardInner = ({
  caseContent,
  commentStatus,
  videoPlayerConfig,
  showNewUngated,
  handleShowBanner = () => {},
  onShareClick = () => {}
}) => {
  /** ********************************** CONFIG ***************************************/
  const {
    translation,
    isTranslationEnabled,
    isViewingTranslation,
    toggleTranslation
  } = useCaseTranslation(caseContent);
  const currentLanguageCode = useSelector(
    Selectors.LocalizationSelectors.selectCurrentLanguageCode
  );
  const isAuthor = useIsCaseAuthor(caseContent);
  const isCaseActionsOnTop = isAuthor && !caseContent.isSponsored;

  const isStaticCase = Utils.CaseUtils.isStaticCase(caseContent?.caseState);
  const isNonMedicalPost = Utils.CaseUtils.isNonMedicalContent(caseContent);

  const showAboutThisCaseCard =
    !showNewUngated &&
    !isStaticCase &&
    caseContent.isCase &&
    caseContent?.labels?.some((label) =>
      ELIGIBLE_CASE_LABELS_ABOUT_THIS_CASE.includes(label)
    );

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const handleTranslationClick = () => {
    trackTranslationClicked({
      source: TRANSLATION_SOURCES.CAPTION,
      currentLanguage: currentLanguageCode,
      viewPreClick: isViewingTranslation
        ? TRANSLATION_VIEWS.TRANSLATED
        : TRANSLATION_VIEWS.ORIGINAL,
      caseUuid: caseContent.caseUuid
    });
    toggleTranslation();
  };

  /** ********************************** RENDER ***************************************/

  const caseTitleEl = translation.title ? (
    <CardBody className="pt-0">
      <CaseTitle
        title={translation.title}
        publishedAt={caseContent.publishedAt}
        isPagingCase={caseContent.isPagingCase}
      />
    </CardBody>
  ) : (
    <>
      {isMobileOnly && (
        <CardBody className="pt-0">
          <F1Spacer />
        </CardBody>
      )}
    </>
  );

  const CaseUpdateButton = (
    <Link
      className="btn btn-sm btn-denim-blue d-inline-flex justify-content-center align-items-center w-100 py-2"
      role="button"
      to={getUrl(CASE_DETAIL_UPDATE, {
        caseId: caseContent.caseUuid
      })}>
      <CustomIcon
        className="icon-white mr-2"
        icon="upload_shortcut"
        size={16}
      />
      {i18n.t("caseDetail.updateCase")}
    </Link>
  );

  return (
    <>
      <CardBody className="d-inline-flex justify-content-between align-items-center w-100 bg-white border-0">
        <CaseUserCell caseContent={caseContent} displayFollowUser={true} />
        <span className="d-flex align-items-center">
          {isCaseActionsOnTop &&
            !isMobileOnly &&
            caseContent?.isCase &&
            CaseUpdateButton}
          <CaseActions
            caseContent={caseContent}
            caseNotification={handleShowBanner}
            from={CASE_CARD_ORIGIN.DETAIL}
            commentStatus={commentStatus}
            hideShareText={false}
            context={CASE_CARD_ORIGIN.DETAIL}
            location={SHARE_LOCATIONS.TOP_BAR}
            onShareClick={() => onShareClick(SHARE_LOCATIONS.TOP_BAR)}
          />
        </span>
      </CardBody>

      {isCaseActionsOnTop && isMobileOnly && caseContent?.isCase && (
        <CardBody className="pt-0">{CaseUpdateButton}</CardBody>
      )}

      {caseTitleEl}

      {isNonMedicalPost && (
        <CaseSpecialtiesBar caseContent={caseContent} className="pb-3" />
      )}

      {caseContent?.media?.length > 0 && (
        <CaseMediaView
          caseUuid={caseContent.caseUuid}
          caseType={caseContent.caseType}
          className={`${caseContent.media.length === 1 ? "pb-3" : "pb-0"} ${
            isNonMedicalPost ? "bg-lighter-gray-2" : ""
          }`}
          media={caseContent.media}
          showImages={true}
          context={CASE_CARD_ORIGIN.DETAIL}
          videoPlayerConfig={videoPlayerConfig}
          carouselItemClassName={isNonMedicalPost ? "bg-lighter-gray-3" : ""}
        />
      )}

      <div className={`${isNonMedicalPost ? "mb-3" : ""}`}>
        <CardBody
          className={`${isNonMedicalPost ? "bg-lighter-gray-2" : ""} pt-0`}>
          <GatedCaseNotice
            visible={showNewUngated}
            className={isNonMedicalPost ? "bg-white" : ""}
          />
          <CardText
            className="feed-card-info feed-card-info-markdown feed-card-detail"
            tag="div">
            <ReactMarkdown>{translation.caption}</ReactMarkdown>
          </CardText>
        </CardBody>

        {isTranslationEnabled && (
          <CardBody
            className={`${
              isNonMedicalPost ? "bg-lighter-gray-2" : ""
            } pt-0 pb-1 mt-n3`}>
            <Button
              className="px-0 font-weight-500 text-battleship-gray text-15"
              color="link"
              onClick={handleTranslationClick}>
              {i18n.t(
                isViewingTranslation
                  ? "CaseTranslation.seeOriginal"
                  : "CaseTranslation.seeTranslation"
              )}
            </Button>
          </CardBody>
        )}
      </div>

      {caseContent?.references && (
        <CardBody className="pt-0">
          <CaseReferences references={caseContent?.references} />
        </CardBody>
      )}
      {!!caseContent?.externalLinkUrl && (
        <CardBody className="pt-0">
          <QuestionExternalLink
            caseUuid={caseContent.caseUuid}
            contentUuid={caseContent.contentUuid}
            campaignUuid={caseContent.campaignUuid}
            text={caseContent.externalLinkText}
            url={caseContent.externalLinkUrl}
          />
        </CardBody>
      )}
      {caseContent?.isCase && (
        <CardBody className="pt-0">
          <CaseDetailUpdatesCard caseContent={caseContent} />
        </CardBody>
      )}
      {showAboutThisCaseCard && (
        <CardBody className="pt-0">
          <AboutThisCaseCard caseContent={caseContent} />
        </CardBody>
      )}
    </>
  );
};

export default CaseDetailCardInner;
