import { useRef } from "react";
import { Label } from "reactstrap";

import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";

import { AutoLinkNode, LinkNode } from "@lexical/link";
import { LinkPlugin } from "@lexical/react/LexicalLinkPlugin";
import { $getRoot } from "lexical";

import AutoLinkPlugin from "./textEditorPlugins/AutoLinkPlugin";
import { MaxLengthPlugin } from "./textEditorPlugins/MaxLengthPlugin";
import { InitialContentPlugin } from "./textEditorPlugins/InitialContentPlugin";

import "./RichTextEditor.scss";

const exampleTheme = {
  placeholder: "editor-placeholder",
  paragraph: "editor-paragraph",
  link: "editor-link"
};

const defaultEditorConfig = {
  // The editor theme
  theme: exampleTheme,
  // Handling of errors during update
  onError(error) {
    throw error;
  },
  // Any custom nodes go here
  nodes: [AutoLinkNode, LinkNode]
};

export default function PlainTextEditor({
  className,
  placeholder = "Enter some rich text...",
  maxLength = null,
  label = "",
  defaultValue = "",
  callback = () => {}
}) {
  const editorConfig = { ...defaultEditorConfig };

  const placeHolderEl = <div className="editor-placeholder">{placeholder}</div>;
  const maxLengthPlug =
    maxLength !== null ? <MaxLengthPlugin maxLength={maxLength} /> : null;

  const labelEl = (
    <Label
      for="caption"
      className="d-flex text-nowrap justify-content-between mb-2">
      {label && (
        <>
          <span className="helv-med text-15 line-height-21 text-default-black">
            {label}
          </span>{" "}
        </>
      )}
      {maxLengthPlug}
    </Label>
  );

  const editorTextRef = useRef();

  function onChange(state) {
    state.read(() => {
      const root = $getRoot();
      editorTextRef.current = root?.getTextContent();
      callback(root?.getTextContent());
    });
  }

  return (
    <LexicalComposer initialConfig={editorConfig}>
      {labelEl}
      <div className={`editor-container`}>
        <div className={`editor-inner`}>
          <PlainTextPlugin
            contentEditable={
              <ContentEditable className={`editor-input ${className}`} />
            }
            placeholder={placeHolderEl}
          />
          <HistoryPlugin />
          <LinkPlugin />
          <AutoLinkPlugin />
          <OnChangePlugin onChange={onChange} />
          <InitialContentPlugin defaultValue={defaultValue} />
        </div>
      </div>
    </LexicalComposer>
  );
}
