import React from "react";
import i18n from "../../utils/i18n";
import CMEPic from "../../assets/images/CME bottom sheet.svg";

const CaseCMECTAModalContents = () => {
  return (
    <div className="text-center p-2 px-4">
      <img src={CMEPic} className="mb-4" style={{ width: "225px" }} />
      <h2 className="helv-med mb-4">{i18n.t("CaseCMECTA.modal.heading")}</h2>
      <p className="mb-0 text-18 text-battleship-gray">
        {i18n.t("CaseCMECTA.modal.body")}
      </p>
    </div>
  );
};

export default CaseCMECTAModalContents;
