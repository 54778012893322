import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import { CASE_LABEL_TYPES } from "../../constants/case-label-types";
import { decideIfPagingIsActive } from "../../utils/case-utils";
import i18n from "../../utils/i18n";
import pagingIcon from "../../assets/images/feedcard_paging_icon.svg";
import rareIcon from "../../assets/images/feedcard_rare_icon.svg";
import unresolvedIcon from "../../assets/images/feedcard_unresolved_icon.svg";
import createPost from "../../assets/images/post_icon_no_background.svg";

const CaseCardLabels = ({ caseContent, isNonMedicalPost }) => {
  /** ********************************** CONFIG ***************************************/
  let icon;
  let label;
  let className = "px-2";
  let iconClassName = "mr-1";

  const [specialtyNames, setSpecialtyNames] = useState("");
  const isPagingActive = decideIfPagingIsActive(
    caseContent?.publishedAt,
    caseContent?.isPagingCase
  );
  const hasAnswer = caseContent?.hasAcceptedAnswer;

  if (isNonMedicalPost) {
    label = i18n.t("CaseCard.labels.post");
    icon = createPost;
  } else if (
    isPagingActive &&
    caseContent?.labels.includes(CASE_LABEL_TYPES.UNRESOLVED) &&
    !hasAnswer
  ) {
    label = i18n.t("CaseCard.labels.paging");
    icon = pagingIcon;
    className = "px-2 bg-coral-alt";
    iconClassName = "mr-2";
  } else if (
    caseContent?.requestHelp &&
    caseContent?.labels.includes(CASE_LABEL_TYPES.UNRESOLVED) &&
    !hasAnswer
  ) {
    label = i18n.t("CaseCard.labels.unresolved");
    icon = unresolvedIcon;
    iconClassName = "m-0";
  } else if (
    caseContent?.labels.includes(CASE_LABEL_TYPES.VERIFIED_LITERATURE)
  ) {
    label = i18n.t("CaseCard.labels.verified_literature");
    className = "p-0";
  } else if (caseContent?.labels.includes(CASE_LABEL_TYPES.RARE_CONDITION)) {
    label = i18n.t("CaseCard.labels.rare");
    icon = rareIcon;
    iconClassName = "m-0";
  } else if (
    caseContent?.labels.includes(CASE_LABEL_TYPES.COMMON_PRESENTATION)
  ) {
    label = i18n.t("CaseCard.labels.common_presentation");
    className = "p-0";
  } else if (caseContent?.labels.includes(CASE_LABEL_TYPES.TRENDING)) {
    label = i18n.t("CaseCard.labels.trending");
    className = "p-0";
  }
  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    setSpecialtyNames((caseContent?.specialtyNames || []).join(", "));
  }, [caseContent?.specialtyNames]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (!label) return null;

  return (
    <>
      <Card
        className={`border-0 rounded-0 ${
          isNonMedicalPost && "bg-lighter-gray-2"
        }`}
        style={{ marginBottom: "1px" }}>
        <CardBody className="d-flex justify-content-start align-items-center py-2 px-3 border-0">
          {icon && <img src={icon} alt="" className={iconClassName} />}
          <div className={`py-1 rounded ${className}`}>
            <p className={`text-clamp-21-1 mb-0 text-13`}>
              <span className="helv-bold mr-1">{label}</span>
              {i18n.t("CaseCard.in")} {specialtyNames}
            </p>
          </div>
        </CardBody>
      </Card>
      {!isNonMedicalPost && (
        <hr style={{ borderTopWidth: "2px" }} className="mt-0 mb-2" />
      )}
    </>
  );
};

export default CaseCardLabels;
