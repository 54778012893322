import React, { useEffect, useState } from "react";
import { PopoverBody, UncontrolledPopover } from "reactstrap";
import i18n from "../../../utils/i18n";
import {
  getAndParseLocalStorageItem,
  setLocalStorageItem
} from "../../../utils/local-storage-utils";
import CustomIcon from "../../custom-icon/CustomIcon";
import LOCAL_STORAGE_CONSTANTS from "../../../constants/local-storage-constants";
import "./GetCreditsTooltip.scss";
import useOnScreen from "../../../hooks/use-on-screen.hooks";

const ON_SCREEN_OPTIONS = {
  threshold: 0.75
};

const GetCreditsTooltip = ({ screen, target, placement }) => {
  /** ********************************** CONFIG ***************************************/
  const refIsInView = useOnScreen(target, ON_SCREEN_OPTIONS);
  const tooltips =
    getAndParseLocalStorageItem(LOCAL_STORAGE_CONSTANTS.GET_CREDITS_TOOLTIP) ||
    {};
  const displayed = tooltips[screen];

  const [showTooltip, setShowTooltip] = useState(!displayed && refIsInView);

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    setShowTooltip(!displayed && refIsInView);
  }, [displayed, refIsInView]);

  useEffect(() => {
    return () => {
      tooltips[screen] = true;
      setLocalStorageItem(
        LOCAL_STORAGE_CONSTANTS.GET_CREDITS_TOOLTIP,
        JSON.stringify(tooltips)
      );
      setShowTooltip(false);
    };
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  const closeTooltip = (e) => {
    if (e) {
      e.persist();
      e.nativeEvent.stopImmediatePropagation();
      e.stopPropagation();
    }
    tooltips[screen] = true;
    setLocalStorageItem(
      LOCAL_STORAGE_CONSTANTS.GET_CREDITS_TOOLTIP,
      JSON.stringify(tooltips)
    );
    setShowTooltip(false);
  };

  /** ********************************** RENDER ***************************************/
  return (
    <UncontrolledPopover
      isOpen={showTooltip}
      popperClassName="popover-aqua mw-315 popover-shadow"
      placement={placement}
      target={target}>
      <PopoverBody className="color-white py-3 pl-3 pr-4">
        <div className="d-flex">
          <p className="m-0 mb-2 helv-bold text-15 line-height-18">
            {i18n.t("GetCreditsTooltip.title")}
          </p>
          <div className="cursor-pointer mr-n3 mt-n2" onClick={closeTooltip}>
            <CustomIcon icon="modal_exit" color="white" size={16} />
          </div>
        </div>
        <p className="m-0 text-13 line-height-19">
          {i18n.t("GetCreditsTooltip.explanation")}
        </p>
      </PopoverBody>
    </UncontrolledPopover>
  );
};

export default GetCreditsTooltip;
