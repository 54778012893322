import { useCallback } from "react";
import {
  trackHelpModalPageEntry,
  trackOnboardingSupportClick
} from "../actions/metrics.actions";
import useDialog from "../components/dialog/useDialog";
import { StaticStrings } from "../constants/static-string-constants";
import i18n from "../utils/i18n";
import * as ROUTES from "../constants/routes";
import F1HorizontalDivider from "../components/common/F1HorizontalDivider";

export function useContactSupport() {
  const { confirm } = useDialog();
  const emailString = `mailto:${StaticStrings.supportEmail}`;

  const isRegflow =
    [
      ROUTES.REGISTRATION_INFORMATION_V2,
      ROUTES.REGISTRATION_VERFICATION_PHOTO_V2
    ].indexOf(window.location.pathname) > -1;

  const handleContactSupportClick = useCallback(async () => {
    trackHelpModalPageEntry();
    const confirmed = await confirm({
      message: i18n.t("RegistrationScreens.needHelpModal.title"),
      messageDetail: (
        <>
          {isRegflow && (
            <div class="w-100 text-left">
              <h5 className="helv-bold text-18">
                {i18n.t(
                  "RegistrationScreens.needHelpModal.retiredMessaging.title"
                )}
              </h5>
              <p className="text-15">
                {i18n.t(
                  "RegistrationScreens.needHelpModal.retiredMessaging.description"
                )}
              </p>
              <ul className="check-list">
                <li className="text-16 m-0 mb-3">
                  {i18n.t(
                    "RegistrationScreens.needHelpModal.retiredMessaging.bullet1"
                  )}
                </li>
                <li className="text-16 m-0">
                  {i18n.t(
                    "RegistrationScreens.needHelpModal.retiredMessaging.bullet2"
                  )}
                </li>
              </ul>
              <F1HorizontalDivider className="w-75" color="light-gray">
                <span className="color-battleship-gray text-16">
                  {i18n.t("RegistrationScreens.verificationPage.v2.divider")}
                </span>
              </F1HorizontalDivider>
            </div>
          )}
          <p className="text-15 text-left">
            {i18n.t("RegistrationScreens.needHelpModal.prompt1")}
            <a href={emailString}>{StaticStrings.supportEmail}</a>
            {i18n.t("RegistrationScreens.needHelpModal.prompt2")}
          </p>
        </>
      ),
      confirmText: i18n.t("RegistrationScreens.needHelpModal.contactSupport"),
      cancelText: ""
    });

    if (confirmed) {
      trackOnboardingSupportClick();
      document.location = emailString;
    }
  }, [confirm, emailString, isRegflow]);

  return handleContactSupportClick;
}
