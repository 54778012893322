import { orderBy } from "lodash";
import React, { useEffect, useState } from "react";
import { CASE_LABEL_TYPES } from "../../constants/case-label-types";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";

const AboutThisCaseCard = ({ caseContent, showNewUngated = false }) => {
  /** ********************************** CONFIG ***************************************/
  const [displayList, setDisplayList] = useState([]);

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    const labelsArray = [];
    console.log(caseContent.labels);
    caseContent?.labels?.forEach((label) => {
      if (label === CASE_LABEL_TYPES.UNRESOLVED && !caseContent.hasAnswer) {
        labelsArray.push({
          label: i18n.t("AboutThisCaseCard.labels.unresolved"),
          icon: "detail_label_unresolved",
          order: 1
        });
      } else if (label === CASE_LABEL_TYPES.RESOLVED) {
        labelsArray.push({
          label: i18n.t("AboutThisCaseCard.labels.resolved"),
          icon: "detail_label_resolved",
          order: 2
        });
      } else if (label === CASE_LABEL_TYPES.VERIFIED_LITERATURE) {
        labelsArray.push({
          label: i18n.t("AboutThisCaseCard.labels.verified_literature"),
          icon: "detail_label_verified_literature",
          order: 3
        });
      } else if (label === CASE_LABEL_TYPES.RARE_CONDITION) {
        labelsArray.push({
          label: i18n.t("AboutThisCaseCard.labels.rare"),
          icon: "detail_label_rare",
          order: 4
        });
      } else if (label === CASE_LABEL_TYPES.COMMON_PRESENTATION) {
        labelsArray.push({
          label: i18n.t("AboutThisCaseCard.labels.common_presentation"),
          icon: "detail_label_common",
          order: 5
        });
      } else if (label === CASE_LABEL_TYPES.TRENDING) {
        labelsArray.push({
          label: i18n.t("AboutThisCaseCard.labels.trending"),
          icon: "detail_label_trending",
          order: 6
        });
      }
    });
    // add resolved if there is answer
    if (
      !caseContent?.labels.includes(CASE_LABEL_TYPES.RESOLVED) &&
      caseContent?.hasAnswer
    ) {
      labelsArray.push({
        label: i18n.t("AboutThisCaseCard.labels.resolved"),
        icon: "detail_label_resolved",
        order: 2
      });
    }

    setDisplayList(labelsArray);
  }, [caseContent?.labels, caseContent?.requestHelp]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (caseContent?.isSponsored) {
    return null;
  }

  return (
    <>
      <p
        className={`text-16 mb-2 font-weight-bold ${
          !showNewUngated && "pt-3 border-top border-light-gray"
        }`}>
        {i18n.t("AboutThisCaseCard.title")}
      </p>
      <p
        className="d-flex m-0 text-15 pb-3 text-nowrap"
        style={{
          overflow: "auto"
        }}>
        {orderBy(displayList, ["order"]).map((displayLabel) => (
          <span className="d-flex align-items-center mr-2">
            <CustomIcon icon={displayLabel.icon} size={18} className="mr-1" />
            {displayLabel.label}
          </span>
        ))}
      </p>
    </>
  );
};

export default AboutThisCaseCard;
