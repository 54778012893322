import React from "react";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import { DRAFTS_ROOT, SAVED_CASES_ROOT } from "../../constants/routes";
import ProfileDropdown from "./ProfileDropdown";
import F1Badge from "../common/F1Badge";
import "./RightNavComponent.scss";

const RightNavComponent = ({ numDrafts = 0 }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Nav className={`align-items-center`} navbar>
        <NavItem>
          <NavLink to={DRAFTS_ROOT} className="nav-link">
            <div className="draft mr-2">
              <CustomIcon icon={"webnav_drafts_unselected"} size={24} />
              <F1Badge
                pill
                textColor="black"
                color="light-gray"
                name={numDrafts}
                className={"button_badge"}
                px={numDrafts > 9 ? 1 : 2}
              />
            </div>
          </NavLink>
        </NavItem>
        <span className="nav-divider" />
      </Nav>

      <Nav className={`align-items-center`} navbar>
        <NavItem>
          <NavLink to={SAVED_CASES_ROOT} className="nav-link">
            <CustomIcon icon={"webnav_save_unselected"} size={24} />
          </NavLink>
        </NavItem>
        <span className="nav-divider" />
      </Nav>

      <Nav className="align-items-center" navbar>
        <NavItem>
          <ProfileDropdown />
        </NavItem>
      </Nav>
    </>
  );
};

export default RightNavComponent;
