import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Figure1Layout from "../../@layouts/Figure1Layout";
import {
  addMediaToDraft,
  createEmptyDraft,
  saveDraft,
  sendDraftToBackend,
  setAndPrepareCurrentDraft,
  isValidDraftProps,
  getMediaUploadLocation,
  clearCurrentDraft
} from "../../actions/user-draft.actions";
import { get, useForm } from "react-hook-form";
import { fetchSpecialties } from "../../actions/case-specialties.actions";
import Loading from "../../components/loading";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { showErrorMessage } from "../../actions/global.actions";
import SubmittedCaseModal from "./components/SubmittedCaseModal";
import { HOME_ROOT, POST } from "../../constants/routes";
import { UPDATE_CURRENT_DRAFT_MEDIA } from "../../actions/user-draft.actions";
import {
  trackEvent,
  trackPageEntry,
  trackUploadDetails
} from "../../actions/metrics.actions";
import {
  getFileNameWithoutExtension,
  sanitizeFileName
} from "../../utils/media-utils";
import UploadImageType from "../../constants/uploadImageType.constants";
import AppPrompt from "../../components/app-prompt/AppPrompt";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";
import { Card, CardBody } from "reactstrap";
import NewPostForm from "./NewPostForm";
import i18n from "../../utils/i18n";
import WhatCanIPostModal from "./components/WhatCanIPostModal";
import LoadingButton from "../../components/common/LoadingButton";
import useCombinedSpecialties from "../../hooks/useCombinedSpecialties";
import {
  METRICS_EVENT_NAME,
  METRICS_EVENT_PROPERTY
} from "../../metrics/constants.metrics";
import { Constants } from "@figure1/f1-pro-fe-brain";

const extractValuesFromSelectData = (formData, name) => {
  if (Array.isArray(formData[name])) {
    return get(formData, name, []).map((s) => (s.value ? s.value : s));
  } else {
    return formData[name]?.value ? [formData[name]?.value] : [];
  }
};
const getSubmissionData = (formData, submit) => {
  const specialtiesUuid = extractValuesFromSelectData(formData, "specialties");
  const subSpecialtiesUuid = extractValuesFromSelectData(
    formData,
    "subSpecialties"
  );

  const submissionData = {
    caseClassification: Constants.CaseType.CASE_CLASSIFICATIONS.NON_MEDICAL,
    groupUuid: formData.groupUuid?.value,
    caption: formData.caption,
    post_process_media: true,
    specialtiesUuid,
    subSpecialtiesUuid
  };

  if (submissionData?.groupUuid === "public" && submit) {
    submissionData.groupUuid = null;
  }

  return submissionData;
};

const PostDraftDetails = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const history = useHistory();
  const { draftUid } = useParams();
  const draft = useSelector((state) => state?.userDraft?.currentDraft);
  const fileRef = useRef(null);

  const { handleSubmit, control, setValue, errors, getValues } = useForm({
    mode: "onChange",
    shouldUnregister: false
  });

  const [postSubmitted, setPostSubmitted] = useState(false);
  const [whatCanIPostModalOpen, setWhatCanIPostModalOpen] = useState(false);

  const userUid = useSelector((state) => state.user.userUid);

  const existingDraft = useSelector((state) =>
    draftUid ? state?.userDraft?.data?.[draftUid] : null
  );

  const isSavingDraft = useSelector((state) => state?.userDraft?.isProcessing);
  const [isSaving, setIsSaving] = useState(false);
  const hasError = useSelector((state) => state?.error);
  const isPageLoadingData = useSelector(
    (state) => !state.caseSpecialties.fetched
  );

  const combinedSpecialties = useCombinedSpecialties();

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    trackPageEntry(METRICS_EVENT_NAME.SCREEN.UPLOAD.UPLOAD_CREATE_ENTRY, {
      [METRICS_EVENT_PROPERTY.CASE.CASE_CLASSIFICATION]:
        Constants.CaseType.CASE_CLASSIFICATIONS.NON_MEDICAL
    });
  }, []);
  // make sure the case specialties are loaded
  useEffect(() => {
    if (!draftUid) {
      dispatch(setAndPrepareCurrentDraft(null));
    }
    dispatch(fetchSpecialties());
  }, [draftUid, dispatch]);

  const setupDraft = useCallback(async () => {
    if (!draftUid) {
      const emptyDraftUid = await dispatch(createEmptyDraft(null, false));
      await dispatch(setAndPrepareCurrentDraft(emptyDraftUid));
    } else if (draftUid && existingDraft) {
      await dispatch(setAndPrepareCurrentDraft(draftUid));
    }
  }, [draftUid, dispatch, existingDraft]);

  useEffect(() => {
    setupDraft();

    return () => {
      dispatch(clearCurrentDraft());
    };
  }, [dispatch, setupDraft]);

  /** ********************************* FUNCTIONS *************************************/

  const onRemoveFile = (error, file) => {
    let match = false;
    let doDispatch = false;
    if (file.filename) {
      const media = draft.media
        .map((m) => {
          const outputNameWithoutExtension = getFileNameWithoutExtension(
            file.filename
          );
          const mediaFileNameWithoutExtension = getFileNameWithoutExtension(
            m.filename
          );
          match = outputNameWithoutExtension === mediaFileNameWithoutExtension;
          if (match) {
            doDispatch = true;
            return null;
          }
          return m;
        })
        .filter((m) => !!m);

      if (doDispatch) {
        dispatch({
          type: UPDATE_CURRENT_DRAFT_MEDIA,
          payload: { draftUid: draft.draftUid, media }
        });
      }
    }
  };

  const onFileEdit = (output, item) => {
    let match = false;
    let doDispatch = false;
    if (item.filename) {
      const media = draft.media.map((m) => {
        const outputNameWithoutExtension = getFileNameWithoutExtension(
          item.filename
        );
        const mediaFileNameWithoutExtension = getFileNameWithoutExtension(
          m.filename
        );
        match = outputNameWithoutExtension === mediaFileNameWithoutExtension;

        if (match) {
          doDispatch = match;
          delete m.uploaded_to_aws;
        }
        return m;
      });

      if (doDispatch) {
        dispatch({
          type: UPDATE_CURRENT_DRAFT_MEDIA,
          payload: { draftUid: draft.draftUid, media }
        });
      }
    }
  };

  const doFormProcessing = async (formData, submit) => {
    setIsSaving(true);
    const submissionData = getSubmissionData(formData, submit);

    const files = await fileRef.current.prepareFiles();

    const mediaList = files.filter((f) => {
      return !!f.output;
    });
    const shouldFetchAws = !!mediaList.length;

    // redundant check for submission, but it works
    if (!isValidDraftProps({ ...formData, media: mediaList })) {
      dispatch(
        showErrorMessage(
          "DraftsScreen.draftValidationMinRequirementsErrorForPosts",
          null,
          false,
          null
        )
      );
      setIsSaving(false);
      return;
    }

    if (shouldFetchAws) {
      let awsValues;

      try {
        awsValues = await dispatch(
          getMediaUploadLocation({
            uploadType: UploadImageType.DRAFT,
            draftUid: draft?.draftUid
          })
        );
      } catch (e) {
        dispatch(
          showErrorMessage("DraftsScreen.awsValueFetchError", null, false, null)
        );
        setIsSaving(false);

        return;
      }

      const _newFiles = mediaList
        .map((f, i) => {
          const output = f.output;
          if (!output) return; //eslint-disable-line array-callback-return

          const existingFile = draft.media.find((m) => {
            const outputNameWithoutExtension = getFileNameWithoutExtension(
              output.name
            );
            const mediaFileNameWithoutExtension = getFileNameWithoutExtension(
              m.filename
            );
            return outputNameWithoutExtension === mediaFileNameWithoutExtension;
          });

          if (existingFile) {
            existingFile.index = i;
            if (!existingFile.uploaded_to_aws) {
              // eventually redundant
              if (!existingFile.originalFilename) {
                existingFile.originalFilename = existingFile.filename;
              }

              existingFile.file = output;
              // tick up itteration and update the filename
              existingFile.itteration =
                existingFile.itteration != null
                  ? existingFile.itteration + 1
                  : 1;
              existingFile.filename = `${
                existingFile.itteration
              }-${sanitizeFileName(existingFile.originalFilename)}`;

              existingFile.url = `${awsValues.media_download_domain}drafts/${userUid}/${draft.draftUid}/${existingFile.filename}`;
            }
            return existingFile;
          }

          return {
            originalFilename: output.name,
            filename: `1-${sanitizeFileName(output.name)}`,
            index: i,
            type: "image", // hardcoded for now until we support multiple types
            url: `${awsValues.media_download_domain}drafts/${userUid}/${
              draft.draftUid
            }/1-${sanitizeFileName(output.name)}`,
            file: output,
            itteration: 1
          };
        })
        .filter((f) => f != null);

      try {
        await dispatch(
          addMediaToDraft(draft.draftUid, _newFiles, awsValues.media_upload_url)
        );
      } catch {
        setIsSaving(false);
        return;
      }
      submissionData.media = _newFiles;
    }

    const specialties = submissionData.specialtiesUuid.map((id) => {
      const label = combinedSpecialties.find((l) => l.value === id);
      return label ? label.label : label.value;
    });

    trackUploadDetails({
      groupUuid: submissionData.groupUuid || null,
      caption: submissionData.caption,
      mediaCount: submissionData.media?.length || 0,
      specialties: specialties,
      draftUuid: draft?.draftUid,
      isSaveDraft: !submit,
      isAnonymous: submissionData.isAnonymous,
      caseClassification: Constants.CaseType.CASE_CLASSIFICATIONS.NON_MEDICAL
    });

    fileRef.current.removeFiles();
    if (submit) {
      await dispatch(
        sendDraftToBackend(userUid, draft?.draftUid, false, submissionData)
      );
      setPostSubmitted(true);
    } else {
      await dispatch(saveDraft(draft.draftUid, submissionData));
    }
    setIsSaving(false);
  };

  const onSavePost = async (formData) => {
    return await doFormProcessing(formData, true);
  };

  const onSaveDraft = async () => {
    await doFormProcessing(getValues(), false);
    history.push(`${POST}/${draft?.draftUid}`);
  };

  const verifiedOnSavePost = useVerifiedUserGate(onSavePost);

  const onSubmitComplete = () => {
    setPostSubmitted((isOpen) => !isOpen);
  };

  const toggleWhatCanIPostModal = () => {
    setWhatCanIPostModalOpen((isOpen) => !isOpen);
  };

  const learnMoreClick = () => {
    trackEvent(METRICS_EVENT_NAME.UPLOAD.LEARN_MORE_CLICK);
    toggleWhatCanIPostModal();
  };

  /** ********************************** RENDER ***************************************/

  if (isPageLoadingData) {
    return <Loading />;
  }

  const rejectedInfo =
    draft?.rejectionReasonMessage || draft?.rejectionReason ? (
      <div className="warning-box mb-3 p-3 text-16">
        <span className="font-weight-bold">
          {i18n.t("DraftsScreen.requiredEditBanner")}:{" "}
        </span>
        {draft.rejectionReasonMessage || draft.rejectionReason}
      </div>
    ) : null;

  return (
    <Figure1Layout footer={<AppPrompt />}>
      <Figure1Page3Col
        headerRowSlot={rejectedInfo}
        mainContent={
          <>
            <div className="d-flex justify-content-end mb-2 mt-n2">
              <LoadingButton
                size="md"
                spinnerSize="sm"
                spinnerColor="primary"
                disabled={isSavingDraft || isSaving}
                loading={isSavingDraft || isSaving}
                onClick={onSaveDraft}
                className="cursor-pointer text-cool-blue text-15">
                {i18n.t("shareCase.buttonSaveDraft")}
              </LoadingButton>
            </div>
            <Card>
              <CardBody className="helv-bold text-center text-16 p-3">
                {i18n.t("sharePost.title")}
              </CardBody>
            </Card>
            <NewPostForm
              draft={draft}
              combinedSpecialties={combinedSpecialties}
              control={control}
              errors={errors}
              setValue={setValue}
              getValues={getValues}
              isSaving={isSavingDraft || isSaving}
              fileRef={fileRef}
              onFileEdit={onFileEdit}
              onRemoveFile={onRemoveFile}
              learnMoreClick={learnMoreClick}
              onSubmit={handleSubmit(verifiedOnSavePost)}
            />
            <SubmittedCaseModal
              post
              draftUid={draft?.draftUid}
              toggle={onSubmitComplete}
              isOpen={postSubmitted && !hasError}
              route={HOME_ROOT}
            />
            <WhatCanIPostModal
              isOpen={whatCanIPostModalOpen}
              toggle={toggleWhatCanIPostModal}
            />
          </>
        }
      />
    </Figure1Layout>
  );
};

export default PostDraftDetails;
