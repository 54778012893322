import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import {
  clearLocalVerificationPictureInfo,
  submitPhotoVerification
} from "../../../actions/user-verification.actions";
import "./VerificationPhotoUpload.scss";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { trackEvent, trackVerifyPhoto } from "../../../actions/metrics.actions";
import useIsAStudent from "../../../hooks/useIsAStudent";
import useIsUSAUser from "../../../hooks/useIsUSAUser";
import VerificationPhotoUploadForm from "./VerificationPhotoUploadForm";
import {
  METRICS_EVENT_NAME,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics";

const VerificationPhotoUploadV2 = () => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const onboardingNavigate = useOnboardingNavigation();

  const { handleSubmit } = useForm({
    mode: "onChange"
  });

  const userVerificationStore = useSelector((state) => state.userVerification);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const isStudent = useIsAStudent();
  const isUsa = useIsUSAUser();
  const showNpiLink = isUsa && !isStudent;
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const handleSubmitClick = async () => {
    setErrorMessage("");
    setIsLoading(true);
    trackVerifyPhoto();

    try {
      if (!userVerificationStore?.verificationPictureInfos?.length) {
        throw new Error("At least one photo must be uploaded for verification");
      }
      const result = await dispatch(
        submitPhotoVerification(userVerificationStore?.verificationPictureInfos)
      );

      if (result.error) {
        trackEvent(METRICS_EVENT_NAME.ERROR, {
          [METRICS_USER_PROPERTY.ERROR_MESSAGE]: result.message ?? ""
        });
        throw new Error(result.message);
      } else {
        dispatch(clearLocalVerificationPictureInfo());
        onboardingNavigate(result.user);
      }

      setIsLoading(false);
    } catch (e) {
      setErrorMessage(e.message);
      setIsLoading(false);
    }
  };

  const onSelectFile = () => {
    setErrorMessage("");
  };

  /** ********************************** RENDER ***************************************/

  return (
    <VerificationPhotoUploadForm
      errorMessage={errorMessage}
      isLoading={isLoading}
      onSubmit={handleSubmit(handleSubmitClick)}
      onSelectFile={onSelectFile}
      showNpiLink={showNpiLink}
    />
  );
};

export default VerificationPhotoUploadV2;
