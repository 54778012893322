import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  FormFeedback,
  FormGroup,
  FormText,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";
import i18n from "../../../utils/i18n";

import "./LegacyTransitionModal.scss";
import { Controller, useForm } from "react-hook-form";
import LoadingButton from "../../../components/common/LoadingButton";
import { useDispatch, useSelector } from "react-redux";
import FormGlobalError from "./FormGlobalError";
import { postVerification } from "../../../actions/user-verification.actions";
import {
  trackInstitutionalEmailPageEntry,
  trackVerifyInsitutionalEmail
} from "../../../actions/metrics.actions";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import Regex from "../../../constants/regex";
import { isMobile } from "react-device-detect";
import { trim } from "lodash";

const EmailVerificationModal = ({ isOpen, onSave = () => true, ...rest }) => {
  const { onCancel } = rest;
  /************************************ CONFIG ***************************************/
  const { handleSubmit, control, errors, formState } = useForm({
    mode: "onBlur"
  });
  const [isSaving, setIsSaving] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const dispatch = useDispatch();

  const onboardingNavigate = useOnboardingNavigation();

  const user = useSelector((state) => state.user);

  /************************************ HOOKS ****************************************/

  useEffect(() => {
    trackInstitutionalEmailPageEntry();
  }, []);

  /*********************************** FUNCTIONS *************************************/
  const handleSubmitClick = async (formData) => {
    if (!formState.isValid) {
      return;
    }
    setIsSaving(true);
    setErrorMessage("");

    const payload = {
      institutionalEmail: trim(formData.email),
      method: "institutional_email",
      country: user?.country.countryUuid
    };

    trackVerifyInsitutionalEmail(payload.institutionalEmail);

    try {
      const saveProfileResult = await onSave();

      if (saveProfileResult) {
        // Save npi request first
        const result = await dispatch(postVerification(payload));

        if (result.error) {
          throw new Error(result.message);
        } else {
          onboardingNavigate(result.user);
        }
      }
    } catch (e) {
      setErrorMessage(e.message);
      setIsSaving(false);
    }
  };

  const handleCancelClick = () => {
    onCancel();
  };
  /************************************ RENDER ***************************************/

  return (
    <Modal centered isOpen={isOpen} {...rest}>
      <form onSubmit={handleSubmit(handleSubmitClick)}>
        <ModalHeader className="flex-column justify-content-center align-items-center mt-3 mx-4 helv-bold">
          {i18n.t("RegistrationScreens.emailVerificationPopup.title")}
        </ModalHeader>
        <ModalBody className="pb-4 px-4">
          <FormGlobalError errorText={errorMessage} />

          <FormGroup>
            <Label for="email">
              {i18n.t("RegistrationScreens.emailVerificationPopup.emailLabel")}
            </Label>
            <Controller
              as={Input}
              control={control}
              id="email"
              name="email"
              type="text"
              invalid={!!errors.email}
              placeholder={i18n.t(
                "RegistrationScreens.emailVerificationPopup.emailPlaceholder"
              )}
              rules={{ required: true, pattern: Regex.SimpleEmail }}
            />
            <FormFeedback>
              {i18n.t("RegistrationScreens.emailVerificationPopup.emailError")}
            </FormFeedback>
            <FormText>
              {i18n.t("RegistrationScreens.emailVerificationPopup.emailNote")}
            </FormText>
          </FormGroup>
        </ModalBody>

        <ModalFooter className="d-flex justify-content-between align-items-center border-0 p-4 mb-0 pt-3">
          <LoadingButton
            size="lg"
            color="secondary"
            onClick={handleCancelClick}
            className={`text-16 ${isMobile ? "min-w-130px" : "min-w-171px"}`}>
            {i18n.t("RegistrationScreens.verificationNpiPage.modalCancel")}
          </LoadingButton>

          <LoadingButton
            size="lg"
            color="primary"
            loading={isSaving}
            className={`text-16 ${isMobile ? "min-w-130px" : "min-w-171px"}`}>
            {i18n.t("RegistrationScreens.verificationNpiPage.modalConfirm")}
          </LoadingButton>
        </ModalFooter>
      </form>
    </Modal>
  );
};

EmailVerificationModal.propTypes = {
  isOpen: PropTypes.bool,
  redirectUrl: PropTypes.string
};

EmailVerificationModal.defaultProps = {};

export default EmailVerificationModal;
