/** @format */

import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import { isEmpty } from "lodash";
import activityCentreActions from "../../actions/activity-centre.actions";
import {
  CASE_NOTIFICATIONS,
  COMMENT_REPLY_NOTIFICATIONS,
  NOTIFICATION_TYPES
} from "../../constants/activity-constants";
import {
  CASE_COMMENT_DETAIL,
  CASE_DETAIL,
  CASE_POSTING_EDIT,
  GROUPS_MEMBERS,
  POST_EDIT,
  PROFILE_DETAIL
} from "../../constants/routes";
import { Constants } from "@figure1/f1-pro-fe-brain";
import i18n from "../../utils/i18n";
import { getUrl } from "../../utils/route-utils";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import NotificationEmpty from "./components/NotificationsEmpty";
import NotificationsFound from "./components/NotificationsFound";
import NotificationsLoading from "./components/NotificationsLoading";
import {
  trackActivityCenterEntry,
  trackMarkAllAsReadClick,
  trackNotificationClick,
  trackShareCase
} from "../../actions/metrics.actions";
import DeletedPostDialog from "./components/DeletedPostDialog";
import moment from "moment";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import useDialog from "../../components/dialog/useDialog";

/**
 * Go to view more about activity based on its type:
 * - comment, reply, posted, updated, approve -> go to case
 * - follow -> go to follower's profile
 *
 * Note: Ignoring rejected for now since it needs more fleshing out.
 */
// TODO: figure out navigation for DRAFT_REMINDER
const goToFeature = (history, activity) => {
  const {
    type,
    caseUuid,
    commentUuid,
    sourceUuid,
    draftUid,
    userUuid,
    sourceGroup
  } = activity;
  if (CASE_NOTIFICATIONS.includes(type)) {
    history.push(getUrl(CASE_DETAIL, { caseId: caseUuid }));
  } else if (COMMENT_REPLY_NOTIFICATIONS.includes(type)) {
    history.push(
      getUrl(CASE_COMMENT_DETAIL, { caseId: caseUuid, commentId: commentUuid })
    );
  } else if (type === NOTIFICATION_TYPES.NEW_FOLLOWER) {
    history.push(getUrl(PROFILE_DETAIL, { userUuid: sourceUuid }));
  } else if (type === NOTIFICATION_TYPES.PROFESSION_CHANGE_APPROVED) {
    history.push(getUrl(PROFILE_DETAIL, { userUuid: userUuid }));
  } else if (type === NOTIFICATION_TYPES.REJECT) {
    trackShareCase({ context: UPLOAD_CASE_ORIGIN.NOTIFICATION_SCREEN });
    history.push(
      getUrl(
        activity?.case?.caseClassification ===
          Constants.CaseType.CASE_CLASSIFICATIONS.NON_MEDICAL
          ? POST_EDIT
          : CASE_POSTING_EDIT,
        {
          draftUid
        }
      )
    );
  } else if (type === NOTIFICATION_TYPES.GROUP_INVITE_ACCEPTED) {
    history.replace(
      getUrl(GROUPS_MEMBERS, {
        feedTypeUuid: sourceGroup.groupUuid
      })
    );
  }
};

/**
 * Supports getting more activity data in these scenarios:
 *
 * 1) On load, fetch the first set of newest activity records from now.
 * 2) On scroll down, fetch the next set of older activity records
 * based off the timestamp of the current last activity record in the redux.
 * 3) On scroll up, refresh and get the newest activity records from now.
 *
 * Activity Images:
 * - Follow: show follower's profile
 * - Case related: show case image or an icon if it's a text only case
 */
const LIMIT = 12;

const NotificationsDetailsPage = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { confirm } = useDialog();
  const isLoading = useSelector((state) => state.activity.isActivityLoading);
  const isEndReached = useSelector((state) => state.activity.endReached);
  const activities = useSelector((state) => state.activity.allActivity);
  const [isDeleteDialogOpen, setIsDeletedDialogOpen] = useState(false);
  const [displayedDeletedItem, setDisplayedDeletedItem] = useState({
    date: null,
    reason: null,
    type: null
  });
  const readAllButtonDisabled =
    isEmpty(activities) ||
    activities?.filter((activity) => activity.state !== "read")?.length === 0;

  const fetchMorePastActivity = useCallback(() => {
    const lastActivityTimestamp = activities[activities.length - 1].createdAt;
    if (!isLoading) {
      dispatch(
        activityCentreActions.fetchActivityRecord({
          limit: LIMIT,
          startAfterTimestamp: lastActivityTimestamp
        })
      );
    }
  }, [dispatch, activities, isLoading]);

  const fetchNewestActivity = useCallback(
    (callback) => {
      dispatch(
        activityCentreActions.fetchActivityRecord({
          limit: LIMIT,
          shouldClearOldActivity: true,
          callback
        })
      );
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch(activityCentreActions.setAllActivityAcknowledged());
    fetchNewestActivity();
  }, [fetchNewestActivity, dispatch]);

  useEffect(() => {
    trackActivityCenterEntry();
  }, []);

  const onClickActivity = (history, activity) => {
    const { type, rejectionReasonMessage } = activity;

    trackNotificationClick({
      notificationType: activity.notificationType,
      notificationUUID: activity.notificationUuid
    });

    dispatch(
      activityCentreActions.setActivityAsRead(activity.notificationUuid)
    );

    if (
      [
        NOTIFICATION_TYPES.CASE_DELETE,
        NOTIFICATION_TYPES.COMMENT_DELETE
      ].includes(type)
    ) {
      const d = moment(activity.case?.publishedAt).toDate();

      const formattedDate = Intl.DateTimeFormat("en-US", {
        year: "numeric",
        weekday: "long",
        month: "long",
        day: "numeric",
        timeZone: "UTC"
      }).format(d);

      setDisplayedDeletedItem({
        date: formattedDate,
        reason: rejectionReasonMessage,
        type
      });
      setIsDeletedDialogOpen(true);
    } else {
      goToFeature(history, activity);
    }
  };

  const onMarkAllAsRead = async () => {
    const confirmed = await confirm({
      message: i18n.t("ActivityScreen.modal.title"),
      confirmText: i18n.t("ActivityScreen.modal.confirmBtn"),
      extra: {
        backdrop: true
      }
    });

    if (!confirmed) {
      return;
    }
    trackMarkAllAsReadClick();
    dispatch(activityCentreActions.setAllActivityRead());
  };

  return (
    <>
      <DeletedPostDialog
        {...displayedDeletedItem}
        isOpen={isDeleteDialogOpen}
        onCancel={() => setIsDeletedDialogOpen(false)}
      />
      <Figure1Page1Col
        isChildPage
        mainContent={
          <Card className="border-0 rounded-0">
            <CardBody>
              <CardTitle
                className="text-16 text-default-black pb-4 pt-2 mb-0 d-flex justify-content-between align-items-center"
                tag="h5">
                {i18n.t("ActivityScreen.notifications")}
                <Button
                  disabled={readAllButtonDisabled}
                  color="link"
                  onClick={onMarkAllAsRead}>
                  {i18n.t("ActivityScreen.markAsReadBtn")}
                </Button>
              </CardTitle>
              <Card className="border-0">
                {isEmpty(activities) ? (
                  isLoading ? (
                    <NotificationsLoading />
                  ) : (
                    <NotificationEmpty />
                  )
                ) : (
                  <NotificationsFound
                    activities={activities}
                    isLoading={isLoading}
                    isEndReached={isEndReached}
                    onClickActivity={(activity) =>
                      onClickActivity(history, activity)
                    }
                    onFetchMorePastActivity={fetchMorePastActivity}
                  />
                )}
              </Card>
            </CardBody>
          </Card>
        }
      />
    </>
  );
};

export default NotificationsDetailsPage;
