import { AutoLinkPlugin } from "@lexical/react/LexicalAutoLinkPlugin";

// Because why not use regex, and have more problems :D -- Corey
const URL_MATCHER =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/;

const MATCHERS = [
  (text) => {
    const match = URL_MATCHER.exec(text);
    if (match === null) {
      return null;
    }
    const fullMatch = match[0];

    return {
      index: match.index,
      length: fullMatch.length,
      text: fullMatch,
      url: fullMatch.startsWith("http") ? fullMatch : `https://${fullMatch}`
      // attributes: { rel: 'noopener', target: '_blank' }, // Optional link attributes
    };
  }
];

export default function CustomAutoLinkPlugin() {
  return <AutoLinkPlugin matchers={MATCHERS} />;
}
