/** @format */

import React from "react";
import PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import { Card, CardBody, CardText } from "reactstrap";
import { ContentProp } from "../../../prop-types/ContentProp";
import CaseMediaView from "../../../components/case/CaseMediaView";
import QuestionExternalLink from "../../../components/case/QuestionExternalLink";
import CaseReferences from "../../../components/case/CaseReferences";
import { CASE_CARD_ORIGIN } from "../../../metrics/constants.metrics";
import * as ROUTES from "../../../constants/routes";
import { getUrl } from "../../../utils/route-utils";

const CMCoverSlide = ({ content, caseMeta }) => {
  /****************************** Config **************************************/
  const linkUrl = getUrl(ROUTES.CLINICAL_MOMENTS_ACTIVITY, {
    caseId: caseMeta.caseUuid
  });

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <div className="feed-card">
      <Card className="border-0 rounded-0" color="dark-gray" inverse>
        {content.media?.length > 0 && (
          <CaseMediaView
            className="pb-0"
            caseUuid={content.caseUuid}
            caseType={content.caseType}
            media={content.media}
            showImages={false}
            context={CASE_CARD_ORIGIN.DETAIL}
            linkUrl={linkUrl}
          />
        )}

        {content.sponsoredContent?.jobCode && (
          <CardBody className="py-0">
            <CardText className="float-right text-battleship-gray">
              <small>{content.sponsoredContent?.jobCode}</small>
            </CardText>
          </CardBody>
        )}

        <CardBody>
          <CardText
            className="feed-card-info-markdown opacity-90 feed-card-detail"
            tag="div">
            <ReactMarkdown>{content.caption}</ReactMarkdown>
          </CardText>
        </CardBody>

        {content?.references && (
          <CardBody className="pt-0">
            <CaseReferences references={content?.references} />
          </CardBody>
        )}

        {!!content.externalLinkText && !!content.externalLinkUrl && (
          <CardBody className="pt-0">
            <QuestionExternalLink
              caseUuid={content.caseUuid}
              contentUuid={content.contentUuid}
              campaignUuid={content.campaignUuid}
              text={content.externalLinkText}
              url={content.externalLinkUrl}
            />
          </CardBody>
        )}
      </Card>
    </div>
  );
};

CMCoverSlide.propTypes = {
  content: ContentProp,
  caseMeta: PropTypes.object
};

export default CMCoverSlide;
