/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Badge, ListGroupItem } from "reactstrap";
import F1Avatar from "../../../components/common/F1Avatar";
import FollowUserButton from "../../../components/common/FollowUserButton";
import ProfessionLine from "../../../components/common/ProfessionLine";

const SearchProfileResultItem = ({
  image,
  username,
  displayName,
  profileDisplayName,
  onClick,
  userUuid,
  professionName,
  tree,
  avatar,
  badgeText
}) => {
  const name = displayName || username;
  const profession =
    profileDisplayName || tree?.profileDisplayName || professionName;

  return (
    <ListGroupItem className="d-inline-flex align-items-center justify-content-between px-0 cursor-pointer">
      <div
        className="d-flex flex-grow-1 align-items-start justify-content-between px-0"
        onClick={onClick}>
        <F1Avatar className="mr-2" avatar={image || avatar} username={name} />
        <div className="flex-fill text-left">
          <p className="text-14 helv-med m-0">{username}</p>
          <p className="text-13 text-muted m-0">
            {badgeText && (
              <Badge
                className="mr-1 text-10 helv-med"
                color={"battleship-gray"}>
                {badgeText}
              </Badge>
            )}
            {name}
          </p>
          {!!profession && (
            <p className="text-12 text-muted m-0">
              {<ProfessionLine profession={profession} />}
            </p>
          )}
        </div>
      </div>

      <FollowUserButton
        size="lg"
        style={{ minWidth: "7.5rem" }}
        userUuid={userUuid}
        hasAvatar={!!image}
        username={name}
        context="Search"
      />
    </ListGroupItem>
  );
};

SearchProfileResultItem.propTypes = {
  image: PropTypes.string,
  username: PropTypes.string.isRequired,
  displayName: PropTypes.string,
  profileDisplayName: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  userUuid: PropTypes.string.isRequired
};

SearchProfileResultItem.defaultProps = {
  image: "",
  displayName: "",
  profileDisplayName: ""
};

export default SearchProfileResultItem;
