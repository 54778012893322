import React, { useRef } from "react";
import { Button, Card, CardBody, CardText } from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Selectors, Utils } from "@figure1/f1-pro-fe-brain";
import CaseHeader from "./CaseHeader";
import CaseMediaView from "./CaseMediaView";
import ReactMarkdown from "react-markdown";
import CardFooter from "reactstrap/lib/CardFooter";
import VIDEO_CONTROL_MODE from "../../constants/video-control-mode-constants";
import classNames from "../../utils/class-names-utils";
import CaseBanner from "./CaseBanner";
import SocialContainer from "./SocialContainer";
import { useCaseBanner } from "../../hooks/use-case-banner.hooks";
import TagsContainer from "./TagsContainer";
import { defaultFeatures } from "../../constants/case-feature-constants";
import {
  CASE_CARD_ORIGIN,
  TRANSLATION_SOURCES,
  TRANSLATION_VIEWS
} from "../../metrics/constants.metrics";
import { getCaseTypeUrl } from "../../utils/case-utils";
import CaseTitle from "./CaseTitle";
import { viewTranslatedCase } from "../../actions/case.actions";
import i18n from "../../utils/i18n";
import { trackTranslationClicked } from "../../actions/metrics.actions";
import GetCreditsTooltip from "./get-credits-tooltip/GetCreditsTooltip";
import { tooltipScreens } from "../../constants/local-storage-constants";
import CMEPic from "../../assets/images/case cme.svg";
import { CASE_DETAIL } from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import useCaseCmeEligible from "../../hooks/useCaseCmeEligible";
import CaseCardBanner from "./CaseCardBanner";
import AcceptedAnsIcon from "../../assets/images/accepted_answer.svg";
import CaseCardLabels from "./CaseCardLabels";

const DISPLAY_PANEL_NIL = 0;
const DISPLAY_PANEL_DIAGNOSIS = 1;
const DISPLAY_PANEL_ACCEPTED = 2;
const DISPLAY_PANEL_UPDATES = 3;

const CaseCard = ({
  caseContent,
  className,
  position,
  onIsFrameSwipeVisible
}) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const history = useHistory();
  const tooltipRef = useRef(null);
  const { showBanner, bannerType, handleShowBanner } = useCaseBanner();
  const isNonMedicalPost = Utils.CaseUtils.isNonMedicalContent(caseContent);
  const features = caseContent?.features || defaultFeatures;
  const showCaseActionBar =
    features.commentsEnabled || features.reactionsEnabled;
  const linkUrl = getCaseTypeUrl(caseContent);
  const currentLanguageCode = useSelector(
    Selectors.LocalizationSelectors.selectCurrentLanguageCode
  );
  const isTranslateButtonShown = Utils.CaseUtils.isTranslationEnabled(
    caseContent,
    currentLanguageCode
  );

  const isStaticCase = Utils.CaseUtils.isStaticCase(caseContent?.caseState);

  const showDiagnosisBanner = caseContent.hasDiagnosis;
  const showAcceptedAnswerBanner = !!caseContent?.hasAcceptedAnswer;
  const showCMECTA = useCaseCmeEligible(caseContent) && !isNonMedicalPost;

  // used for deciding which update panel to display
  let updatePanel = DISPLAY_PANEL_NIL;
  if (showDiagnosisBanner && !isNonMedicalPost) {
    updatePanel = DISPLAY_PANEL_DIAGNOSIS;
  } else if (showAcceptedAnswerBanner && !isNonMedicalPost) {
    updatePanel = DISPLAY_PANEL_ACCEPTED;
  } else if (caseContent?.updateCount > 0 && !isNonMedicalPost) {
    updatePanel = DISPLAY_PANEL_UPDATES;
  }

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const handleTranslationClick = () => {
    trackTranslationClicked({
      source: TRANSLATION_SOURCES.FEED,
      currentLanguage: currentLanguageCode,
      viewPreClick: TRANSLATION_VIEWS.ORIGINAL,
      caseUuid: caseContent.caseUuid
    });
    dispatch(viewTranslatedCase(caseContent));
    history.push(linkUrl);
  };

  /** ********************************** RENDER ***************************************/

  return (
    <>
      {caseContent && (
        <div
          className={classNames(
            "card feed-card border-outline-gray",
            className
          )}>
          {!isStaticCase && !isNonMedicalPost && (
            <CaseCardLabels caseContent={caseContent} />
          )}
          {showBanner && !isNonMedicalPost && (
            <CaseBanner bannerType={bannerType} />
          )}

          <Card className="border-0 rounded-0">
            <CaseHeader
              caseContent={caseContent}
              caseNotification={handleShowBanner}
            />
            <div
              className={!isNonMedicalPost ? null : "bg-lighter-gray-2 mb-3"}>
              {isNonMedicalPost && (
                <CaseCardLabels caseContent={caseContent} isNonMedicalPost />
              )}
              {caseContent.media?.length > 0 && (
                <CaseMediaView
                  className={caseContent.media.length === 1 ? "pb-3" : "pb-0"}
                  canNavigate={true}
                  caseUuid={caseContent.caseUuid}
                  caseType={caseContent.caseType}
                  media={caseContent.media}
                  videoPlayerConfig={{
                    videoControlMode: VIDEO_CONTROL_MODE.FEED
                  }}
                  linkUrl={linkUrl}
                  context={CASE_CARD_ORIGIN.FEED}
                  position={position}
                  onIsFrameSwipeVisible={onIsFrameSwipeVisible}
                />
              )}
              <CardBody
                className={`pt-0 ${
                  caseContent.media?.length === 0 && isNonMedicalPost && "mt-2"
                }`}>
                <div className="d-flex w-100 align-items-start">
                  <Link
                    to={{
                      pathname: linkUrl,
                      state: {
                        position
                      }
                    }}
                    className="text-decoration-none flex-grow-1">
                    {caseContent.title && (
                      <div className="no-underline d-flex mb-1">
                        <CaseTitle title={caseContent.title} />
                      </div>
                    )}
                    <div className="feed-card-info feed-card-info-markdown mb-0">
                      {caseContent.caption && (
                        <CardText
                          tag="div"
                          className={`text-default-black ${
                            caseContent.title || isNonMedicalPost
                              ? "text-clamp-21-3"
                              : "text-clamp-21-2"
                          }`}>
                          <ReactMarkdown>{caseContent.caption}</ReactMarkdown>
                        </CardText>
                      )}
                    </div>
                  </Link>
                  {showCMECTA && (
                    <>
                      <div ref={tooltipRef}>
                        <Link
                          className="ml-1"
                          to={getUrl(CASE_DETAIL, {
                            caseId: caseContent.caseUuid
                          })}>
                          <img src={CMEPic} alt="" />
                        </Link>
                      </div>
                      {caseContent.showTooltip && (
                        <GetCreditsTooltip
                          screen={tooltipScreens.FEED}
                          target={tooltipRef}
                          placement={"top"}
                        />
                      )}
                    </>
                  )}
                </div>
                {isTranslateButtonShown && (
                  <Button
                    className="px-0 font-weight-500 text-battleship-gray"
                    color="link"
                    onClick={handleTranslationClick}>
                    {i18n.t("CaseTranslation.seeTranslation")}
                  </Button>
                )}
                <TagsContainer
                  className="mt-2"
                  caseContent={caseContent}
                  isFeedCard={true}
                />
              </CardBody>
              {updatePanel === DISPLAY_PANEL_DIAGNOSIS && (
                <CardBody className="pt-0">
                  <Link
                    to={{ pathname: linkUrl, state: { position } }}
                    className="text-decoration-none">
                    <CaseCardBanner
                      icon="diagnosis"
                      iconClassName="icon-denim-blue ml-0 my-0 mr-2"
                      header={i18n.t("CaseCard.diagnosis.header")}
                      subHeader={i18n.t("CaseCard.diagnosis.subHeader")}
                    />
                  </Link>
                </CardBody>
              )}
              {updatePanel === DISPLAY_PANEL_ACCEPTED && (
                <CardBody className="pt-0">
                  <Link
                    to={{ pathname: linkUrl, state: { position } }}
                    className="text-decoration-none">
                    <CaseCardBanner
                      iconImg={AcceptedAnsIcon}
                      iconImgClassName="ml-0 my-0 mr-2"
                      header={i18n.t("CaseCard.acceptedAnswer.header")}
                      subHeader={i18n.t("CaseCard.acceptedAnswer.subHeader")}
                    />
                  </Link>
                </CardBody>
              )}
              {updatePanel === DISPLAY_PANEL_UPDATES && (
                <CardBody className="pt-0">
                  <Link
                    to={{ pathname: linkUrl, state: { position } }}
                    className="text-decoration-none">
                    <CaseCardBanner
                      header={i18n.t("CaseCard.caseUpdate.updatesAdded", {
                        count: caseContent.updateCount
                      })}
                      subHeader={i18n.t("CaseCard.caseUpdate.subHeader")}
                    />
                  </Link>
                </CardBody>
              )}
            </div>
            {showCaseActionBar && (
              <CardFooter className="bg-white">
                <SocialContainer
                  caseContent={caseContent}
                  hideComments={!features.commentsEnabled}
                  hideReactions={!features.reactionsEnabled}
                  userReactions={caseContent.userReactions || []}
                  position={position}
                  socialContext={CASE_CARD_ORIGIN.FEED}
                  activateListener={false}
                  caseNotification={handleShowBanner}
                  hideSave={false}
                  iconSize={24}
                />
              </CardFooter>
            )}
          </Card>
        </div>
      )}
    </>
  );
};

CaseCard.propTypes = {
  caseContent: PropTypes.object,
  className: PropTypes.string
};

export default CaseCard;
