import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { anonSignIn } from "../actions/login.actions";
import LOCAL_STORAGE_CONSTANTS from "../constants/local-storage-constants";
import { getAndParseLocalStorageItem } from "../utils/local-storage-utils";

/**
 * Hook to check if a user is completed sign up and caches the result if completed
 * This is to get around old user listener updates that might return old (invalid)
 * user states during the reg flow.
 *
 * @returns boolean representing if the user has completed onboarding
 */
const useCreateAnonUserIfNeeded = () => {
  const dispatch = useDispatch();
  const authUser = getAndParseLocalStorageItem(
    LOCAL_STORAGE_CONSTANTS.AUTH_USER
  );
  const userEmailExists = useSelector((state) => state?.nonUser?.anonUserEmail);

  useEffect(() => {
    // Only create anon user if user doesn't actually exist at all, OR if we have
    // an email passed, in which case we want to create the user with said email.
    // Or if the user exists in local storage and is anonymous.

    const isAnonymous = authUser?.isAnonymous ?? true;
    if (isAnonymous || userEmailExists) {
      dispatch(anonSignIn(true));
    }
  }, [dispatch, authUser?.isAnonymous, userEmailExists]);
};

export default useCreateAnonUserIfNeeded;
