import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { resetGatedState } from "../actions/global.actions";
import useIsGated from "./useIsGated";

const useForceGatedReset = () => {
  const isGated = useIsGated();
  const dispatch = useDispatch();
  const isUserInitialized = useSelector((state) => !!state.user?.initialized);

  useEffect(() => {
    if (isUserInitialized && isGated) {
      dispatch(resetGatedState());
    }
  }, [dispatch, isGated, isUserInitialized]);
};

export default useForceGatedReset;
