import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import NavLinkList from "./NavLinkList";
import { FEED_META_LABELS } from "../../constants/feed-type";

const SpecialtyList = () => {
  /** ********************************** CONFIG ***************************************/

  const [categoryList, setCategoryList] = useState([]);
  const topics = useSelector((state) => state.topics?.data);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (!categoryList.length && topics?.length > 0) {
      const mutatedTopics = topics.filter(
        (topic) =>
          topic.label !== FEED_META_LABELS.TRENDING &&
          topic.label !== FEED_META_LABELS.QUIZZES
      );

      setCategoryList(_.orderBy(mutatedTopics, "name"));
    }
  }, [topics, categoryList.length]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return <NavLinkList items={categoryList} iconName="web_nav_specialty" />;
};

export default SpecialtyList;
