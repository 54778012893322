/** @format */

import Firebase from "../firebase";
import { isRequestSuccess } from "../utils/general-utils";

const functions = Firebase.functions;

export const authenticationCheckCall = async (data) => {
  const result = await functions.httpsCallable("backendUserAuthCheck")(data);
  return result.data;
};

export const legacyUpdateUID = async (data) => {
  const result = await functions.httpsCallable("backendUserUpdateUid")(data);
  return result.data;
};

export const createUser = async (data) => {
  const result = await functions.httpsCallable("backendUserCreateUser")(data);
  return result.data;
};

export const updateUser = async (data, screenId) => {
  const result = await functions.httpsCallable("backendUserUpdateUser")({
    ...data,
    screenId: screenId
  });
  return result.data;
};

export const postVerificationRequest = async (data) => {
  try {
    const result = await functions.httpsCallable("backendUserVerificationUser")(
      data
    );
    if (isRequestSuccess(result.data)) {
      return result.data;
    } else {
      throw new Error(`Status ${result.data.status} - ${result.data.store}`);
    }
  } catch (error) {
    throw error;
  }
};

export const postNotificationPreferencesForVerification = async (data) => {
  try {
    const result = await functions.httpsCallable(
      "backendUserVerificationNotification"
    )(data);
    if (isRequestSuccess(result.data)) {
      return result.data;
    } else {
      throw new Error(`Status ${result.data.status} - ${result.data.store}`);
    }
  } catch (error) {
    throw new Error(error);
  }
};

export const validateUsername = async (username) => {
  const result = await functions.httpsCallable("backendUserValidateUsername")({
    username: username
  });
  return result.data;
};

export const validateEmail = async (email) => {
  const result = await functions.httpsCallable("backendUserValidateEmail")({
    email: email
  });
  return result.data;
};

export const uploadPictureForVerification = async (formData) => {
  try {
    const projectId = Firebase.app.app().options.projectId;

    const postUrl = `https://us-central1-${projectId}.cloudfunctions.net/backendUserVerificationUserPhoto`;

    //const postUrl = `https://us-central1-${projectId}.cloudfunctions.net/verification/upload`;

    const userToken = await Firebase.auth.currentUser.getIdToken(true);
    let response = await fetch(postUrl, {
      method: "POST",
      body: formData,
      headers: {
        Authorization: `Bearer ${userToken}`
      }
    });

    if (isRequestSuccess(response)) {
      return true;
    } else {
      const json = await response.json();
      throw new Error(json.message);
    }
  } catch (error) {
    throw new Error("error while uploading the file");
  }
};

export const resetPasswordCall = async (data) => {
  const result = await functions.httpsCallable("backendUserPasswordReset")(
    data
  );
  return result.data;
};

export const generateLoginLinkCall = async (data) => {
  const result = await functions.httpsCallable("backendUserLoginLink")(data);
  return result.data;
};

export const createUngatedUser = async (data) => {
  const result = await functions.httpsCallable("backendUngatedUserCreate")(
    data
  );
  return result.data?.store?.userUuid;
};

export const postNpiInfo = async (rawNpiResult) => {
  try {
    const result = await functions.httpsCallable(
      "backendUserVerificationParseNpi"
    )({
      result: rawNpiResult
    });
    return result.data;
  } catch (e) {
    throw new Error(
      "error while calling backend to fetch user info with NPI data"
    );
  }
};

export const checkNpiInfo = async (number) => {
  try {
    const data = {
      number
    };

    const result = await functions.httpsCallable("backendUserNpiLookupProxy")(
      data
    );
    return result.data;
  } catch (e) {
    console.log(e);
    throw new Error(
      "error while calling backend to fetch NPI info from NPI database"
    );
  }
};

export const getPotentialGroup = async (groupFilterUuid) => {
  try {
    const callableFn = functions.httpsCallable("backendUserPotentialGroup");
    const result = await callableFn({ groupFilterUuid });
    return result.data;
  } catch (error) {
    console.log(error);
    throw new Error(
      "error while calling backend to fetch potential groups of user"
    );
  }
};

export const associateAnonUser = async ({ anonUid, userUid }) => {
  try {
    const result = await functions.httpsCallable("backendUngatedUserAssociate")(
      {
        anonymous_uid: anonUid,
        user_uid: userUid
      }
    );
    return result?.data;
  } catch (e) {
    console.log("Error while associate anon user record: ", e.message);
    throw new Error("Error while associate anon user record ", userUid);
  }
};

export const deleteUserCall = async (userUid) => {
  const callableFn = functions.httpsCallable("backendUserDelete");
  const result = await callableFn({ userUid });
  return result.data;
};
