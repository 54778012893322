import React from "react";
import PropTypes from "prop-types";
import { Constants, Utils } from "@figure1/f1-pro-fe-brain";
import i18n from "../../utils/i18n";
import { Button, Input } from "reactstrap";
import Dialog from "../dialog/Dialog";
import DIALOG_TYPE from "../dialog/DialogType";
import useShareCase from "../../hooks/useShareCase";
import { isPoll } from "../../utils/tactic-utils";
import { Source, StaticStrings } from "../../constants/static-string-constants";
import { useSelectCurrentUser } from "../../selectors/user.selectors";
import { isMobile } from "react-device-detect";
import { getUrl } from "../../utils/route-utils";

const getType = (caseContent) => {
  const isNonMedicalPost = Utils.CaseUtils.isNonMedicalContent(caseContent);
  if (isNonMedicalPost) {
    return "post";
  }

  switch (caseContent.caseType) {
    case Constants.CaseType.CASE_TYPE.STATIC: {
      return "case";
    }
    case Constants.CaseType.CASE_TYPE.QUIZ:
    case Constants.CaseType.CASE_TYPE.QUIZ_SERIES: {
      return caseContent.isPoll || isPoll(caseContent) ? "poll" : "quiz";
    }
    case Constants.CaseType.CASE_TYPE.CLINICAL_MOMENTS: {
      return "activity";
    }
    case Constants.CaseType.CASE_TYPE.CME: {
      return "CME";
    }
    default: {
      return "case";
    }
  }
};

const ShareCaseDialog = ({
  isOpen,
  caseContent,
  location,
  context,
  onClosed
}) => {
  /************************************ CONFIG ***************************************/
  const user = useSelectCurrentUser();
  const { copyLink } = useShareCase({ caseContent, location, context });
  const type = getType(caseContent);
  const title = i18n
    .t("CaseAction.sharePostMessage")
    // eslint-disable-next-line no-template-curly-in-string
    .replace("${TYPE}", type);

  const shareLink = getUrl(
    `${caseContent?.shareLink}${StaticStrings.caseShareLinkParams}`,
    {
      CASE_UUID: caseContent?.caseUuid,
      SOURCE: isMobile ? Source.MOBILE_WEB : Source.WEB,
      USER_UUID: user?.userUuid
    }
  );
  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const handleCopyLinkClick = () => {
    copyLink();
    onClosed();
  };

  /************************************ RENDER ***************************************/
  return (
    <Dialog
      isOpen={isOpen}
      type={DIALOG_TYPE.CONFIRM}
      title={title}
      body={
        <div className="d-inline-flex align-items-center w-100 mt-2">
          <Input
            className="border border-light-gray border-width-2 border-style-dashed mr-2 bg-white"
            type="text"
            name="shareLink"
            value={shareLink}
            readOnly
          />
          <Button
            className="flex-shrink-0"
            color="denim-blue"
            onClick={handleCopyLinkClick}>
            {i18n.t("CaseAction.copyLink")}
          </Button>
        </div>
      }
      toggle={onClosed}
      onCancel={onClosed}
      onClosed={onClosed}
      onConfirm={handleCopyLinkClick}
    />
  );
};

ShareCaseDialog.propTypes = {
  isOpen: PropTypes.bool,
  caseContent: PropTypes.object,
  location: PropTypes.string,
  context: PropTypes.string,
  onClosed: PropTypes.func
};

export default ShareCaseDialog;
