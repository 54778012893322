import {
  HIDE_GLOBAL_MESSAGE,
  SHOW_GLOBAL_MESSAGE,
  SET_GATED,
  SET_GATED_MESSAGE_TYPE,
  SET_GATED_MESSAGE_ORIGIN,
  RESET_GATED_STATE,
  APP_PROMPT_INTERACTED,
  APP_PROMPT_HEIGHT_CHANGED,
  SET_GATED_VIEW_COUNT,
  SET_EMAIL_MISMATCH_DETAILS,
  CLEAR_EMAIL_MISMATCH_DETAILS,
  SET_GLOBAL_LOADING,
  SET_GATED_MESSAGE_REDIRECT,
  RESET_GATED_MODAL,
  PERFORM_SIGNOUT,
  TOGGLE_LOGIN_MODAL,
  TOGGLE_SIGNUP_MODAL,
  TOGGLE_EXTERNAL_MODAL,
  SET_INITIAL_REFERRER
} from "../actions/global.actions";
import { get } from "lodash";
import { isIOS, isAndroid } from "react-device-detect";
import TOAST_TYPE from "../components/popup/ToastType";

const INITIAL_STATE = {
  message: null,
  title: null,
  error: null,
  messageType: TOAST_TYPE.INFO,
  persist: false,
  isGated: false,
  gatedMessageType: null,
  gatedMessageOrigin: null,
  gatedMessageRedirect: null,
  loading: false,
  ungatedViewCount: 0,
  appPrompt: {
    isShown: isIOS || isAndroid,
    isExpanded: false,
    height: 0
  },
  loginModalVisible: false,
  signUpModalVisible: false,
  performSignout: true,
  externalModalVisible: false,
  externalModalLink: null,
  initialReferrer: null
};

const globalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // should this have a reset_all call? -- Corey
    case SHOW_GLOBAL_MESSAGE: {
      let errorMessage;
      try {
        errorMessage =
          action.error &&
          action.error.message &&
          get(JSON.parse(action.error.message), "store.error");
      } catch (e) {}
      return {
        ...state,
        message: errorMessage ? errorMessage : action.message,
        title: action.title,
        messageType: action.messageType || TOAST_TYPE.INFO,
        error: action.error,
        persist: action.persist || false
      };
    }
    case HIDE_GLOBAL_MESSAGE: {
      return {
        ...state,
        message: null,
        title: null,
        messageType: null,
        error: null,
        persist: null
      };
    }
    case SET_GATED: {
      return {
        ...state,
        isGated: action.isGated,
        isSponsored: action.isSponsored
      };
    }
    case SET_GATED_MESSAGE_TYPE: {
      return {
        ...state,
        gatedMessageType: action.messageType
      };
    }
    case SET_GATED_MESSAGE_ORIGIN: {
      return {
        ...state,
        gatedMessageOrigin: action.origin
      };
    }
    case SET_GATED_MESSAGE_REDIRECT: {
      return {
        ...state,
        gatedMessageRedirect: action.redirect
      };
    }

    case RESET_GATED_MODAL: {
      return {
        ...state,
        gatedMessageType: null,
        gatedMessageOrigin: null,
        gatedMessageRedirect: null
      };
    }
    case RESET_GATED_STATE: {
      return {
        ...state,
        isGated: false,
        gatedMessageType: null,
        gatedMessageOrigin: null,
        gatedMessageRedirect: null
      };
    }
    case APP_PROMPT_INTERACTED: {
      return {
        ...state,
        appPrompt: { ...state.appPrompt, isExpanded: action.isExpanded }
      };
    }
    case APP_PROMPT_HEIGHT_CHANGED: {
      return {
        ...state,
        appPrompt: { ...state.appPrompt, height: action.height }
      };
    }
    case SET_GATED_VIEW_COUNT: {
      return {
        ...state,
        ungatedViewCount: state.ungatedViewCount + 1
      };
    }
    case SET_EMAIL_MISMATCH_DETAILS: {
      return {
        ...state,
        emailMismatchModal: action.emailMismatchModal
      };
    }
    case CLEAR_EMAIL_MISMATCH_DETAILS: {
      return {
        ...state,
        emailMismatchModal: action.emailMismatchModal
      };
    }
    case TOGGLE_LOGIN_MODAL: {
      return {
        ...state,
        loginModalVisible: action.visible
      };
    }
    case TOGGLE_SIGNUP_MODAL: {
      return {
        ...state,
        signUpModalVisible: action.visible
      };
    }
    case PERFORM_SIGNOUT: {
      return {
        ...state,
        performSignout: action.perform
      };
    }
    case SET_GLOBAL_LOADING: {
      return {
        ...state,
        loading: action.loading
      };
    }
    case TOGGLE_EXTERNAL_MODAL: {
      return {
        ...state,
        externalModalVisible: action.visible,
        externalModalLink: action.link
      };
    }
    case SET_INITIAL_REFERRER: {
      console.log("SET_INITIAL_REFERRER", action.value);
      return {
        ...state,
        initialReferrer: action.value
      };
    }
    default:
      return state;
  }
};

export default globalReducer;
