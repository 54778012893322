import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem,
  Badge
} from "reactstrap";
import { isMobile } from "react-device-detect";
import CustomIcon from "../custom-icon/CustomIcon";
import DropdownItemLink from "../common/DropdownItemLink";
import { CASE_POSTING_NEW, POST_NEW } from "../../constants/routes";
import { trackShareCase } from "../../actions/metrics.actions";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import CircleCustomIcon from "../custom-icon/CircleCustomIcon";

const CreateDropdown = ({ isGroupFeed, currentFeedUuid }) => {
  /** ********************************** CONFIG ***************************************/
  const { t } = useTranslation();
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const toggleDropdown = () => setDropdownOpen((prevState) => !prevState);

  /** ********************************** RENDER ***************************************/

  return (
    <Dropdown
      className="dropdown-more"
      isOpen={isDropdownOpen}
      toggle={toggleDropdown}>
      <DropdownToggle tag="span" aria-expanded={isDropdownOpen}>
        <div className="d-flex flex-column justify-content-center align-items-center nav-link">
          <CustomIcon icon={"webnav_newcase_unselected"} size={20} />
          <div className={`${isMobile && "text-11"}`}>
            {t("navigation.create.title")}
          </div>
        </div>
      </DropdownToggle>
      <DropdownMenu className="dropdown-menu--center dropdown-menu--pointer-top-center dropdown-menu-scrollable">
        <DropdownItemLink
          className="bg-white"
          to={CASE_POSTING_NEW}
          onClick={() => {
            trackShareCase({
              context: UPLOAD_CASE_ORIGIN.PLUS_SIGN,
              groupUuid: isGroupFeed ? currentFeedUuid : null
            });
          }}>
          <div className="case-dropdown-item align-items-center">
            <CircleCustomIcon
              circleColor="lighter-gray"
              icon="clinical-case-icon"
              color="charcoal-gray"
              innerClassName="mb-0"
              size={26}
              className="mr-2 p-2"
            />
            <div className="case-dropdown-text-area mw-200">
              <p className="m-0 helv-bold text-16 ">
                {t("navigation.create.caseTitle")}
              </p>
              <p className="m-0 text-muted text-13 text-wrap">
                {t("navigation.create.caseDescription")}
              </p>
            </div>
          </div>
        </DropdownItemLink>
        <DropdownItem divider className="mx-4" />
        <DropdownItemLink className="bg-white" to={POST_NEW} onClick={() => {}}>
          <div className="case-dropdown-item align-items-center">
            <CircleCustomIcon
              circleColor="lighter-gray"
              icon="post-icon"
              color="charcoal-gray"
              innerClassName="mb-0"
              size={26}
              className="mr-2 p-2"
            />
            <div className="case-dropdown-text-area">
              <p className="m-0 helv-bold text-16">
                {t("navigation.create.postTitle")}
                <Badge
                  color="aqua"
                  className="ml-2 pt-1 text-uppercase helv-bold">
                  {t("common.new")}
                </Badge>
              </p>
              <p className="m-0 text-muted text-13">
                {t("navigation.create.postDescription1")}
              </p>
              <p className="m-0 text-muted text-13">
                {t("navigation.create.postDescription2")}
              </p>
            </div>
          </div>
        </DropdownItemLink>
      </DropdownMenu>
    </Dropdown>
  );
};

CreateDropdown.propTypes = {};

export default CreateDropdown;
