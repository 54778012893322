import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Card } from "reactstrap";
import PropTypes from "prop-types";
import { useWatch } from "react-hook-form";
import { CASE_LABEL_TYPES } from "../../constants/case-label-types";
import i18n from "../../utils/i18n";
import LoadingButton from "../../components/common/LoadingButton";
import TitleSummaryInputs from "./components/TitleSummaryInputs";
import useUserHasGroups from "../../hooks/useUserHasGroups";
import { compact, trim } from "lodash";
import CaseTagsGroup from "../../components/upload-case/CaseTagsGroup";
import CaseStatusGroup from "../../components/upload-case/CaseStatusGroup";
import UserSelector from "../../components/upload-case/UserSelector";
import { useSelectCurrentUser } from "../../selectors/user.selectors";
import ShareWithInput from "./components/ShareWithInput";
import SpecialtySelectInput from "./components/SpecialtySelectInput";
import ImagesUploader from "./components/ImagesUploader";

const PUBLIC_GROUP = {
  label: "Figure 1 public",
  value: "public"
};

const NewCaseForm = ({
  draft,
  caseLabels,
  combinedSpecialties,
  onSubmit,
  control,
  errors = {},
  setValue,
  isSaving,
  fileRef,
  onFileEdit,
  onRemoveFile
}) => {
  /** ********************************** CONFIG ***************************************/
  const hasGroups = useUserHasGroups();
  const fieldWatch = useWatch({
    control,
    name: ["title", "caption", "specialties", "groupUuid", "isAnonymous"],
    defaultValue: {
      title: "",
      caption: "",
      specialties: [],
      groupUuid: null,
      isAnonymous: null
    }
  });
  const user = useSelectCurrentUser();
  const userGroups = useSelector((state) => state?.user?.groups);
  const groupData = useSelector((state) => state?.groups?.data);

  const [caseLabelList, setCaseLabelList] = useState(null);
  const [availableGroups, setAvailableGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([PUBLIC_GROUP]);

  const isGroupCase =
    !!fieldWatch?.groupUuid?.value && fieldWatch?.groupUuid?.value !== "public";

  const disableSubmit =
    isSaving ||
    (hasGroups && !fieldWatch?.groupUuid?.value) ||
    !trim(fieldWatch?.caption);

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (userGroups && groupData) {
      const groups = compact(
        userGroups.map((userGroup) => {
          const group = groupData?.[userGroup.groupUuid];
          if (!group) return null;

          return { label: group.groupName, value: userGroup.groupUuid };
        })
      );

      setAvailableGroups(groups);
      setGroupOptions([PUBLIC_GROUP, ...groups]);
    }
  }, [userGroups, groupData, setAvailableGroups]);

  useEffect(() => {
    if (draft && availableGroups?.length) {
      const result = draft?.groupUuid
        ? groupOptions.find((option) => option.value === draft.groupUuid)
        : null;
      setValue("groupUuid", result);
    }
  }, [draft, availableGroups, setValue, groupOptions]);

  useEffect(() => {
    if (caseLabels && caseLabels?.length > 0) {
      const labels = [];
      // rare
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.RARE_CONDITION
        )[0] || { name: "", labelUuid: "nolabelresolved" }
      );
      // common
      labels.push(
        caseLabels.filter(
          (l) => l.labelKind === CASE_LABEL_TYPES.COMMON_PRESENTATION
        )[0] || { name: "", labelUuid: "nolabelresolved" }
      );

      setCaseLabelList(labels);
    }
  }, [caseLabels]);

  useEffect(() => {
    if (userGroups?.length > 0 && !fieldWatch?.groupUuid?.value) {
      setValue("isAnonymous", null);
    } else if (draft) {
      setValue("isAnonymous", { value: draft?.isAnonymous });
    }
  }, [draft, fieldWatch?.groupUuid?.value, setValue, userGroups?.length]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (!Object.keys(draft || {}).length) return null;

  return (
    <form onSubmit={onSubmit}>
      <Card className="px-5 py-4">
        {/* share with dropdown */}
        <ShareWithInput
          draft={draft}
          control={control}
          errors={errors}
          setValue={setValue}
          className="mt-2"
          label={i18n.t("shareCase.shareWith")}
          placeholder={i18n.t("shareCase.shareWithPlaceholder")}
        />
        {/* post as dropdown */}
        {!isGroupCase && (
          <UserSelector
            postAsAnon={fieldWatch?.isAnonymous}
            control={control}
            user={user}
          />
        )}
        {/* images uploader */}
        <ImagesUploader
          className="mt-3"
          label={i18n.t("shareCase.addPhotos")}
          fileRef={fileRef}
          media={draft?.media}
          onFileEdit={onFileEdit}
          onRemoveFile={onRemoveFile}
        />
        {/* case title and summary input */}
        <TitleSummaryInputs
          draft={draft}
          control={control}
          setValue={setValue}
          fieldWatch={fieldWatch}
          errors={errors}
        />
        {/* case tags input */}
        <CaseTagsGroup
          control={control}
          caseLabels={caseLabelList}
          draftLabels={draft?.labelsUuid || []}
          label={i18n.t("shareCase.addTagsLabel")}
          errors={errors.caseLabels}
        />
        {/* case status (resolved/unresolved) input */}
        <CaseStatusGroup
          setValue={setValue}
          control={control}
          errors={errors}
          draft={draft}
          caseLabels={caseLabels}
          isGroupCase={isGroupCase}
        />
        {/* specialties dropdown */}
        <SpecialtySelectInput
          draft={draft}
          combinedSpecialties={combinedSpecialties}
          control={control}
          errors={errors}
          className="mt-2"
          label={i18n.t("shareCase.addSpecialties")}
          placeholder={i18n.t("shareCase.addSpecialtiesPlaceholder")}
        />

        <LoadingButton
          spinnerSize="sm"
          spinnerColor="secondary"
          disabled={disableSubmit}
          loading={isSaving}
          color="primary"
          size="md"
          type="submit"
          className="w-100 text-15 mt-3 py-3">
          {i18n.t("shareCase.buttonSubmitCase")}
        </LoadingButton>
      </Card>
    </form>
  );
};

NewCaseForm.propTypes = {
  saveCase: PropTypes.func,
  caseLabels: PropTypes.array
};

export default NewCaseForm;
