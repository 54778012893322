/** @format */
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

const outer = {
  height: "20px",
  width: "100%",
  marginTop: "10px"
};
const segment = {
  height: "1px",
  flex: 1,
  margin: "0px 15px"
};

const CMEDateSectionHeader = ({ date }) => {
  const str = moment(date).format("MMMM YYYY");
  return (
    <div
      className="d-flex flex-row align-items-center justify-content-between"
      style={outer}>
      <div className="bg-lighter-grey" style={segment}></div>
      <div className="text-11 text-uppercase helv-med line-height-16">
        {str}
      </div>
      <div className="bg-lighter-grey" style={segment}></div>
    </div>
  );
};

CMEDateSectionHeader.propTypes = {
  date: PropTypes.object.isRequired
};

export default CMEDateSectionHeader;
