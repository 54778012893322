/** @format */
import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain";

import {
  DEFAULT_LISTENER_ON,
  ELASTIC_SEARCH_LISTENER_ON,
  FETCH_CONFIGURATION_COMPLETE,
  FETCH_CONFIGURATION,
  FETCH_ELASTIC_SEARCH_CONFIGURATION_COMPLETE,
  LISTENERS_OFF
} from "../actions/configuration.actions";
import { RESET_ALL } from "../actions/global.actions";

const INITIAL_STATE = {
  data: {},
  selected: {},
  isProcessing: false,
  error: false,
  message: null,
  listeners: {},
  elasticSearch: {},
  ...InitialReducerStates.CONFIGURATION_INITIAL_STATE
};

const configurationReducer = (baseState = INITIAL_STATE, action) => {
  // Do everything in base first, and then anything custom after
  const state = { ...BrainReducers.ConfigurationReducer(baseState, action) };

  switch (action.type) {
    case FETCH_CONFIGURATION:
      return { ...state, isProcessing: true };
    case FETCH_CONFIGURATION_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        data: action.payload?.data || state.data,
        error: action.error,
        message: action.message
      };
    case FETCH_ELASTIC_SEARCH_CONFIGURATION_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        elasticSearch: action.payload.data
      };
    case DEFAULT_LISTENER_ON:
      return {
        ...state,
        listeners: { ...state.listeners, defaultListener: action.listener }
      };
    case ELASTIC_SEARCH_LISTENER_ON:
      return {
        ...state,
        listeners: {
          ...state.listeners,
          elasticSearchListener: action.listener
        }
      };
    case LISTENERS_OFF:
      return { ...state, listeners: {} };
    case RESET_ALL:
      return { ...state, elasticSearch: {} };
    default:
      return state;
  }
};

export default configurationReducer;
