import React, { useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { Badge, FormGroup, Label } from "reactstrap";
import Select, { components } from "react-select";
import F1Avatar from "../common/F1Avatar";
import UserSelectorAnonInfobox from "./UserSelectorAnonInfobox";
import "./UserSelector.scss";
import i18n from "../../utils/i18n";

const AvatarEl = ({ user, size = "sm", className = "mr-2", ...props }) => {
  return (
    <F1Avatar
      {...user}
      isAnonymous={!user}
      size={size}
      className={className}
      {...props}
    />
  );
};

const CustomSingleValue = (props) => {
  return (
    <components.SingleValue {...props}>
      <div className="d-flex align-items-center">
        <AvatarEl size="xs" user={props?.data?.user} className="" />{" "}
        <span className="ml-1 text-truncate">{props.data?.displayName}</span>
        <span className={"ml-1 helv-bold flex1"}>{props.data?.anonLabel}</span>
      </div>
    </components.SingleValue>
  );
};

const CustomGroup = (props) => {
  return (
    <div>
      <components.Group {...props} className="p-0 m-0" />
      <hr className="p-0 mx-3 my-2 mb-2" />
    </div>
  );
};

const CustomOption = (props) => {
  const { innerRef, innerProps, data, ...rest } = props;

  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={`option p-3 ${rest.isSelected ? "selected" : ""}`}>
      <div className="d-flex align-items-start pr-5">
        <AvatarEl
          user={data?.user}
          className={data?.avatarClass || "mr-2"}
          style={data?.style}
        />
        <div>
          <div className="helv-bold text-16">
            {data.label}{" "}
            <Badge color="aqua" className="ml-2 text-uppercase helv-bold">
              {data.labelBadge}
            </Badge>
          </div>
          <div className="text-battleship-gray text-14">{data.description}</div>
        </div>
      </div>
    </div>
  );
};

const UserSelector = ({ postAsAnon, control, user }) => {
  const [showInfoBox, setShowInfoBox] = useState(false);

  const containerRef = useRef();

  const closeTooltip = () => {
    setShowInfoBox(false);
  };

  const onSelectChange = (e) => {
    setShowInfoBox(!!e.value);
  };

  const options = [
    {
      options: [
        {
          value: false,
          user: user,
          displayName: user?.displayName,
          anonLabel: i18n.t("shareCase.anonymousPosting.myself"),
          label: i18n.t("shareCase.anonymousPosting.postMyself"),
          description: i18n.t("shareCase.anonymousPosting.myselfDescription")
        }
      ]
    },
    {
      options: [
        {
          value: true,
          displayName: user?.profileDisplayName,
          anonLabel: i18n.t("shareCase.anonymousPosting.anonymous"),
          label: i18n.t("shareCase.anonymousPosting.anon"),
          description: i18n.t("shareCase.anonymousPosting.anonDescription"),
          avatarClass: "border border-width-1 border-white mr-2",
          style: { borderRadius: "36px" }
        }
      ]
    }
  ];
  const selectedOption =
    options.find((ele) => ele.options[0]?.value === postAsAnon?.value)
      ?.options[0] || null;

  return (
    <FormGroup className="mt-2">
      <Label
        for="isAnonymous"
        className="text-15 font-weight-500 text-default-black d-flex align-items-center">
        {i18n.t("shareCase.anonymousPosting.postAs")}
      </Label>
      <div ref={containerRef}>
        <Controller
          control={control}
          name="isAnonymous"
          defaultValue={selectedOption}
          render={({ onChange }) => (
            <Select
              isSearchable={false}
              className="userSelect"
              options={options}
              value={selectedOption}
              onChange={(e) => {
                onSelectChange(e);
                onChange(e);
              }}
              components={{
                Option: CustomOption,
                Group: CustomGroup,
                SingleValue: CustomSingleValue
              }}
            />
          )}
        />
      </div>
      <UserSelectorAnonInfobox
        showTooltip={showInfoBox}
        closeTooltip={closeTooltip}
        target={containerRef}
      />
    </FormGroup>
  );
};

export default UserSelector;
