import React, { useState } from "react";
import { Button, Card, CardBody, CardText } from "reactstrap";
import { trackDiscoverPublicGroupsClick } from "../../actions/metrics.actions";
import groupImage from "../../assets/images/Group-image.svg";
import { StaticStrings } from "../../constants/static-string-constants";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { DISCOVER_PUBLIC_GROUPS_LOCATIONS } from "../../metrics/constants.metrics";
import i18n from "../../utils/i18n";
import BulletIcon from "./components/BulletIcon";
import CreateGroupModal from "./CreateGroupModal";

const GroupsIntro = () => {
  /** ********************************** CONFIG ***************************************/
  const [isCreateGroupModalOpen, setCreateGroupModalOpen] = useState(false);
  /** ********************************** HOOKS ****************************************/
  /** ********************************* FUNCTIONS *************************************/
  const toggleCretaeGroupModal = () =>
    setCreateGroupModalOpen((prevState) => !prevState);
  const verifiedToggleCreateGroup = useVerifiedUserGate(toggleCretaeGroupModal);

  const onClickDiscover = () => {
    trackDiscoverPublicGroupsClick(
      DISCOVER_PUBLIC_GROUPS_LOCATIONS.EMPTY_STATE
    );
  };
  /** ********************************** RENDER ***************************************/

  return (
    <div>
      <Card className="border-0 rounded-0">
        <img alt="Group" src={groupImage} />
        <CardBody>
          <CardText tag="div" className="text-16 line-height-28">
            {i18n.t("GroupsFeed.IntroPage.introducing")}
          </CardText>
          <CardText tag="div" className="helv-bold text-20 mb-3 line-height-28">
            {i18n.t("GroupsFeed.IntroPage.title")}
          </CardText>
          <CardText tag="div" className="text-16 mb-4 line-height-20">
            {i18n.t("GroupsFeed.IntroPage.description")}
          </CardText>
          <ul className="p-0">
            <li className="d-flex mb-3 text-20 align-items-center">
              <BulletIcon icon="groups_share-case" />{" "}
              <div className="text-16 line-height-20">
                {i18n.t("GroupsFeed.IntroPage.checklist.item1")}
              </div>
            </li>
            <li className="d-flex mb-4 text-20 align-items-center">
              <BulletIcon icon="groups_discussion" />{" "}
              <div className="text-16 line-height-20">
                {i18n.t("GroupsFeed.IntroPage.checklist.item2")}
              </div>
            </li>
          </ul>
          <div
            className="d-flex justify-content-between"
            style={{ marginTop: "35px", marginBottom: "25px" }}>
            <a
              data-bypass-block={true}
              href={StaticStrings.discoverGroups}
              target="_blank"
              rel="noreferrer"
              className="btn btn-primary w-100 text-16 helv-bold mr-2"
              style={{ lineHeight: 2 }}
              onClick={onClickDiscover}>
              {i18n.t("navigation.discover-groups")}
            </a>
            <Button
              color="primary"
              outline
              className="text-16 w-100 helv-bold ml-2"
              style={{ lineHeight: 2 }}
              onClick={verifiedToggleCreateGroup}>
              {i18n.t("navigation.create-group")}
            </Button>
          </div>
        </CardBody>
      </Card>
      <CreateGroupModal
        isOpen={isCreateGroupModalOpen}
        toggle={toggleCretaeGroupModal}
      />
    </div>
  );
};

export default GroupsIntro;
