import React, { useEffect } from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import fallbackImg from "../../assets/images/web hero.webp";
import { getRegistrationRoute } from "../../actions/login.actions";
import { getUrl } from "../../utils/route-utils";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { useParams } from "react-router-dom";
import "./GroupWelcomeSplashPage.scss";
import { trackLegacyWelcomePageEntry } from "../../actions/metrics.actions";
import RegistrationHeader from "./components/RegistrationHeader";
import groupActions from "../../actions/groups.actions";
import { isEmpty } from "lodash";
import InstitutionalSplashPage from "./components/InstitutionalSplashPage";
import { isInstitutionGroup } from "../../utils/GroupUtils";
import SelfFormingSplashPage from "./components/SelfFormingSplashPage";

const GroupWelcomeSplashPage = () => {
  /** ********************************** CONFIG ***************************************/
  const { groupUuid } = useParams();
  const user = useSelector((state) => state.user);

  const nextRoute = getRegistrationRoute(user, true);
  const history = useHistory();
  const dispatch = useDispatch();

  const allGroups = useSelector((state) => state.groups?.data);
  const selectedGroup = useSelector((state) => state.groups?.currentGroup);
  const isInstitutionalGroup = isInstitutionGroup(selectedGroup);

  /** ********************************* HOOKS *************************************/
  useEffect(() => {
    trackLegacyWelcomePageEntry();
  }, []);

  // fetch groups if required
  useEffect(() => {
    if (groupUuid) {
      dispatch(groupActions.fetchGroupsByIds([groupUuid]));
    }
  }, [dispatch, groupUuid]);

  useEffect(() => {
    if (groupUuid && isEmpty(selectedGroup) && !isEmpty(allGroups[groupUuid])) {
      dispatch(groupActions.selectGroup(allGroups[groupUuid]));
    }
  }, [dispatch, allGroups, groupUuid, selectedGroup]);
  /** ********************************* FUNCTIONS *************************************/

  const onContinueClick = () => {
    history.push(getUrl(nextRoute));
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeader />}>
      <div className="text-white d-flex flex-column align-items-center m-0 groupSpashPage">
        <>
          {isInstitutionalGroup && (
            <InstitutionalSplashPage onContinueClick={onContinueClick} />
          )}
          {!isInstitutionalGroup && (
            <SelfFormingSplashPage onContinueClick={onContinueClick} />
          )}
        </>
      </div>
      <div id="background-media">
        <img src={fallbackImg} alt="" />
      </div>
    </Figure1Layout>
  );
};

export default GroupWelcomeSplashPage;
