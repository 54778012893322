import Firebase from "../firebase";

const auth = Firebase.auth;

export const anonLogin = async () => {
  return await auth.signInAnonymously();
};

export const login = async (email, pass) => {
  return await auth.signInWithEmailAndPassword(email, pass);
};

export const isSignInWithEmailLink = async (loginUrl) => {
  return await auth.isSignInWithEmailLink(loginUrl);
};

export const signInWithEmailLink = async (email, loginUrl) => {
  return await auth.signInWithEmailLink(email, loginUrl);
};

export const signUp = async (email, pass) => {
  return await auth.createUserWithEmailAndPassword(email, pass);
};

export const signOut = () => auth.signOut();

export const resetPassword = async (email) => {
  return await auth.sendPasswordResetEmail(email);
};

export const reAuthenticate = async (pass) => {
  const user = auth.currentUser;
  if (user) {
    const credential = auth.EmailAuthProvider.credential(user.email, pass);
    return await user.reauthenticateWithCredential(credential);
  } else {
    throw new Error("reAuth: user not logged in");
  }
};

export const updateEmail = async (newEmail) => {
  const user = auth.currentUser;
  if (user) {
    await user.updateEmail(newEmail);
  } else {
    console.debug("reAuth: user not logged in");
  }
};
