import React, { useEffect, useState, useRef } from "react";
import { isEmpty } from "lodash";
import { TransformComponent, TransformWrapper } from "react-zoom-pan-pinch";
import { useHistory } from "react-router-dom";
import CustomIcon from "../custom-icon/CustomIcon";
import { trackFrameSwipeClicked } from "../../actions/metrics.actions";
import {
  FRAME_SWIPE_ACTION,
  FRAME_SWIPE_ORIENTATION
} from "../../metrics/constants.metrics";

const LOWER_BOUND_RATIO = 0.5;
const UPPER_BOUND_RATIO = 2;
const CONTAINER_HEIGHT = 492;

const CaseMediaPhoto = ({
  url,
  width,
  height,
  caseUuid,
  constrainToContainer = true,
  linkUrl = null,
  position = null,
  onIsFrameSwipeVisible = () => {}
}) => {
  /** ********************************** CONFIG ***************************************/

  const ref = useRef(null);
  const panning = useRef(false);
  const panningTimeout = useRef(null);
  const history = useHistory();
  const [posX, setPosX] = useState(0);
  const [posY, setPosY] = useState(0);
  const [cursor, setCursor] = useState(null);
  const isWide = width > height;
  const isSquare = width === height;
  const [constraint, setConstraint] = useState(null);
  const [isFrameSwipeEnabled, setIsFrameSwipeEnabled] = useState(false);
  const [isFrameSwipeIconVisible, setIsFrameSwipeIconVisible] = useState(false);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (ref.current) {
      let containerWidth = ref.current.offsetWidth;
      let containerHeight = ref.current.offsetHeight;

      // If we have no width or height, that means the element isn't displayed, so
      // lets get the containing element and use that instead. It's not ideal but
      // setting the ref via a callback makes the carousel component to lose it's
      // mind, so this is the best fallback I can think of. -- Corey

      if (containerWidth === 0 || containerHeight === 0) {
        const parent = ref.current.closest(".carousel-inner");
        containerWidth = parent ? parent.offsetWidth : containerWidth;
        containerHeight = parent ? parent.offsetHeight : containerHeight;
      }

      if (isWide) {
        setConstraint(containerWidth);
        setPosX(parseInt(containerWidth));
      } else {
        setPosY(parseInt(containerHeight));
        // temporary to force thin images to be wide
        // setConstraint(containerHeight);
        setConstraint(containerWidth);
      }

      if (width !== height) {
        // only setting a cursor if it's not a square image
        setCursor(
          isFrameSwipeEnabled
            ? isWide
              ? "cursor-ew-resize"
              : "cursor-ns-resize"
            : null
        );
      }
    }
  }, [width, height, isWide, isFrameSwipeEnabled]);

  useEffect(() => {
    if (width > 0 && height > 0) {
      const ratio = height / width;
      const enabled = ratio > UPPER_BOUND_RATIO || ratio < LOWER_BOUND_RATIO;
      setIsFrameSwipeIconVisible(enabled);
    }
  }, [width, height]);

  useEffect(() => {
    onIsFrameSwipeVisible(caseUuid, isFrameSwipeIconVisible);
  }, [caseUuid, isFrameSwipeIconVisible, onIsFrameSwipeVisible]);

  /** ******************************** FUNCTIONS **************************************/

  const onFrameSwipeToggle = () => {
    trackFrameSwipeClicked({
      caseUuid,
      orientation:
        height >= width
          ? FRAME_SWIPE_ORIENTATION.VERTICAL
          : FRAME_SWIPE_ORIENTATION.HORIZONTAL,
      action: isFrameSwipeEnabled
        ? FRAME_SWIPE_ACTION.DESELECT
        : FRAME_SWIPE_ACTION.SELECT
    });
    setIsFrameSwipeEnabled((isEnabled) => !isEnabled);
  };

  const onPanningStop = () => {
    if (!isSquare) {
      clearTimeout(panningTimeout.current);

      panningTimeout.current = setTimeout(() => {
        panning.current = false;
        clearTimeout(panningTimeout.current);
        panningTimeout.current = null;
      }, 200);
    }
  };

  const onPanning = () => {
    if (!isSquare) {
      panning.current = true;
      clearTimeout(panningTimeout.current);
      panningTimeout.current = null;
    }
  };

  const onPanningStart = () => {
    if (!isSquare) {
      clearTimeout(panningTimeout.current);
      panningTimeout.current = null;
    }
  };

  /** ******************************** RENDER ****************************************/

  let limit = constraint && constrainToContainer ? constraint : null;

  if (!url || isEmpty(url)) {
    return null;
  }

  const onClickProps = linkUrl
    ? {
        onClick: () => {
          if (!panning.current) {
            history.push(linkUrl, { position });
          }
        }
      }
    : {};
  const img = (
    <div className={cursor} {...onClickProps}>
      <img
        className=""
        src={`${url}${limit ? (isWide ? `?h=${limit}` : `?w=${limit}`) : ""}`}
        alt=""
      />
    </div>
  );

  // This forces the image to center horizontally, because for some reason
  // the plugin isn't doing it correctly -- Corey
  const extraProps = { initialPositionX: 0 };
  let ratio = 1;
  if (isWide) {
    if (height > constraint) {
      ratio = CONTAINER_HEIGHT / height;
    }
    extraProps.initialPositionX =
      ((width * ratio) / 2) * -1 + CONTAINER_HEIGHT / 2;
  }

  return (
    <>
      <div className="d-flex" ref={ref}>
        <TransformWrapper
          initialScale={1}
          {...extraProps}
          limitToBounds={true}
          disabled={!isFrameSwipeEnabled}
          wheel={{
            disabled: true
          }}
          doubleClick={{ disabled: true }}
          panning={{
            velocityDisabled: false,
            lockAxisX: height >= width,
            lockAxisY: width >= height,
            disabled: !isFrameSwipeEnabled
          }}
          onPanningStart={onPanningStart}
          onPanning={onPanning}
          onPanningStop={onPanningStop}>
          {({ positionX, positionY, resetTransform, ...rest }) => (
            <TransformComponent
              contentStyle={{ alignContent: "center", alignItems: "center" }}
              wrapperStyle={{ alignContent: "center", alignItems: "center" }}>
              {img}
            </TransformComponent>
          )}
        </TransformWrapper>
      </div>
      {isFrameSwipeIconVisible && (
        <div
          className="position-absolute b-0 r-0 mr-4 mb-4 z-2 cursor-pointer"
          onClick={onFrameSwipeToggle}>
          <CustomIcon
            icon={
              isWide
                ? isFrameSwipeEnabled
                  ? "frameswipe_horizontal_active"
                  : "frameswipe_horizontal_inactive"
                : isFrameSwipeEnabled
                ? "frameswipe_vertical_active"
                : "frameswipe_vertical_inactive"
            }
            size={42}
          />
        </div>
      )}
    </>
  );
};

export default CaseMediaPhoto;
