import React from "react";
import { trackEvent } from "../../../actions/metrics.actions";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import Dialog from "../../../components/dialog/Dialog";
import { StaticStrings } from "../../../constants/static-string-constants";
import { METRICS_EVENT_NAME } from "../../../metrics/constants.metrics";
import i18n from "../../../utils/i18n";

const BulletSectionItem = ({ icon, bulletTitle, bulletDesc, className }) => (
  <section
    className={`d-flex align-item-center justify-content-center text-left ${className}`}>
    {icon && <CustomIcon className="mr-2" icon={icon} size={36} />}
    <span className="d-flex flex-column justify-content-center">
      {bulletTitle && (
        <p className="m-0 text-16 line-height-20">{bulletTitle}</p>
      )}
      {bulletDesc && (
        <p className="m-0 text-muted text-13 line-Height-20">{bulletDesc}</p>
      )}
    </span>
  </section>
);

const WhatCanIPostModal = ({ isOpen, toggle }) => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ***************************************/
  /** ********************************** FUNCTIONS ***************************************/
  /** ********************************** RENDER ***************************************/
  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      toggle={toggle}
      body={
        <div className="w-100">
          <h2 className="d-flex align-items-center justify-content-center px-0 pb-2 flex-grow-1 m-0 helv-bold text-18 line-height-28">
            {i18n.t("sharePost.whatCanIPostModal.title")}
          </h2>
          <hr className="border-width-4 border-lighter-gray" />
          <div className="d-flex align-items-center justify-content-center my-4">
            <BulletSectionItem
              icon={"ugc_checkmark"}
              bulletTitle={i18n.t(
                "sharePost.whatCanIPostModal.positiveBullets.bullet1"
              )}
              className="mr-4"
            />
            <BulletSectionItem
              icon={"ugc_checkmark"}
              bulletTitle={i18n.t(
                "sharePost.whatCanIPostModal.positiveBullets.bullet2"
              )}
            />
          </div>
          <div className="d-flex align-items-center justify-content-center my-4">
            <BulletSectionItem
              icon={"ugc_checkmark"}
              bulletTitle={i18n.t(
                "sharePost.whatCanIPostModal.positiveBullets.bullet3"
              )}
              className="mr-4"
            />
            <BulletSectionItem
              icon={"ugc_checkmark"}
              bulletTitle={i18n.t(
                "sharePost.whatCanIPostModal.positiveBullets.bullet4"
              )}
            />
          </div>
          <hr className="border-width-2 border-lighter-gray" />
          <div className="d-flex align-items-center my-4">
            <BulletSectionItem
              icon={"ugc_cross"}
              bulletTitle={i18n.t(
                "sharePost.whatCanIPostModal.negativeBullets.bullet1"
              )}
              className="pl-5"
            />
          </div>
          <div className="d-flex align-items-center my-4">
            <BulletSectionItem
              icon={"ugc_cross"}
              bulletTitle={i18n.t(
                "sharePost.whatCanIPostModal.negativeBullets.bullet2"
              )}
              bulletDesc={i18n.t(
                "sharePost.whatCanIPostModal.negativeBullets.bulletDesc2"
              )}
              className="pl-5"
            />
          </div>
          <div className="mt-5 text-muted text-13 line-height-17">
            {i18n.t("sharePost.whatCanIPostModal.moreInformation")}{" "}
            <a
              href={StaticStrings.communityGuidelines}
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                trackEvent(
                  METRICS_EVENT_NAME.UPLOAD.COMMUNITY_GUIDELINES_CLICK
                );
              }}>
              {i18n.t("RegistrationScreens.privacyPage.communityGuidelines")}
            </a>
          </div>
        </div>
      }
      confirmText={i18n.t("common.gotIt")}
      confirmClassName={"w-295px helv-bold"}
      cancelClassName={"w-295px helv-bold"}
      onConfirm={toggle}
    />
  );
};

export default WhatCanIPostModal;
