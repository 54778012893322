import React from "react";
import { Button } from "reactstrap";
import ReactMarkdown from "react-markdown";
import moment from "moment";
import i18n from "../../../utils/i18n";

import "./DeletedPostItem.scss";

const DeletedPostItem = ({ markdownMessage, longMessage, createdAt }) => {
  return (
    <>
      <div className="notif-markdown">
        <ReactMarkdown>{markdownMessage || longMessage}</ReactMarkdown>
      </div>
      <div>
        <Button
          className="toggle-button color-battleship-gray px-0"
          color="link">
          {i18n.t("ActivityScreen.deletedCaseButton")}
        </Button>
      </div>
      <div className="mb-0 text-muted">{moment(createdAt).fromNow(true)}</div>
    </>
  );
};

export default DeletedPostItem;
