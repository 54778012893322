import React from "react";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";
import { REGISTRATION_NEW_V2 } from "../../../constants/routes";
import useLoginModalClick from "../../../hooks/useLoginModalClick";
import useSignUpModalClick from "../../../hooks/useSignUpModalClick";
import i18n from "../../../utils/i18n";

const BecomeMemberCTA = ({ inModal = false }) => {
  const { hideLoginModal } = useLoginModalClick();
  const { showSignUpModal } = useSignUpModalClick();

  const onClick = (e) => {
    if (inModal) {
      hideLoginModal(e);
      showSignUpModal(e);
    }
  };

  return (
    <p
      className={`w-100 mb-0 text-battleship-gray text-center text-15 ${
        inModal ? "helv-bold" : ""
      }`}>
      {i18n.t("RegistrationScreens.loginForm.dontHaveAccount")}
      <Link
        onClick={onClick}
        className={`text-cool-blue cursor-pointer pl-1 ${
          inModal ? "" : "font-weight-bolder"
        }`}
        to={REGISTRATION_NEW_V2}>
        {!isMobile && i18n.t("RegistrationScreens.loginForm.becomeMemberNow")}
        {isMobile && i18n.t("RegistrationScreens.loginForm.becomeMemberMobile")}
      </Link>
    </p>
  );
};
export default BecomeMemberCTA;
