const LOCAL_STORAGE_CONSTANTS = {
  AUTH_USER: "authUser",
  GET_CREDITS_TOOLTIP: "getCreditsTooltip",
  ANON_POSTING_MODAL: "anonymousPostingInfoModal",
  ANON_POSTING_INFO_BOX: "anonymousPostingInfoBox",
  LOGIN_LINK_EMAIL: "loginLinkEmail"
};

export const tooltipScreens = {
  CASE_DETAIL: "caseDetail",
  FEED: "feed"
};

export const WHATS_NEW_SCREENS = {
  ACCEPTED_ANSWER: "acceptedAnswer",
  POSTS: "posts"
};

export default LOCAL_STORAGE_CONSTANTS;
