import React, { useEffect } from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import SearchPage from "./SearchPage";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { SEARCH_PATH } from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import { SEARCH_RESULT_TYPES } from "../../constants/search-result-constants";
import AppPrompt from "../../components/app-prompt/AppPrompt";

const Search = () => {
  const { tab } = useParams();
  const loc = useLocation();
  const urlParams = new URLSearchParams(loc.search);
  const urlSearchValue = urlParams.get("s");

  const history = useHistory();

  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (
      tab !== SEARCH_RESULT_TYPES.CASE &&
      tab !== SEARCH_RESULT_TYPES.PROFILE
    ) {
      history.replace({
        pathname: getUrl(SEARCH_PATH, { tab: SEARCH_RESULT_TYPES.CASE }),
        search: urlSearchValue ? `?s=${urlSearchValue}` : null
      });
    }
  }, [history, tab, urlSearchValue]);
  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPrompt />}>
      <Figure1Page1Col isChildPage mainContent={<SearchPage />} />
    </Figure1Layout>
  );
};

export default Search;
