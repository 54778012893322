import React from "react";
import { isMobileOnly } from "react-device-detect";
import Figure1Page2Col from "../../../@layouts/Figure1Page2Col";
import useAppPrompt from "../../../components/app-prompt/useAppPrompt";
import AutoSizer from "../../../components/common/AutoSizer";
import ISILayout from "../../../components/isi/ISILayout";
import useISI from "../../../components/isi/useISI";
import { ISI_FOOTER_HEIGHT } from "../../../constants/isi-constants";
import useIsGated from "../../../hooks/useIsGated";
import useIsSponsored from "../../../hooks/useIsSponsored";
import i18n from "../../../utils/i18n";
import BlockedCaseCarousel from "../../../components/case/BlockedCaseCarousel";
import BlockedCaseContent from "../../../components/case/BlockedCaseContent";
import BlockedCaseUpsell from "../../../components/case/BlockedCaseUpsell";
import BlockedSimilarCases from "../../../components/case/BlockedSimilarCases";

import "./BlockedCaseDetailPage.scss";
import { trackUngatedCTA } from "../../../actions/metrics.actions";
import useAnonUserExists from "../../../hooks/useAnonUserExists";
import { UNGATED_ACTIONS } from "../../../metrics/constants.metrics";
import { Card, CardBody } from "reactstrap";
import AboutThisCaseCard from "../../../components/case/AboutThisCaseCard";
import { Utils } from "@figure1/f1-pro-fe-brain";

const BlockedCaseDetailPage = ({
  caseDetails,
  itemContent,
  commentStatus,
  trendingCasesData
}) => {
  const isGated = useIsGated();
  const isSponsored = useIsSponsored();
  const userExists = useAnonUserExists();
  const quizLayout = itemContent?.isPoll || itemContent?.isQuiz;
  const isNonMedicalPost = Utils.CaseUtils.isNonMedicalContent(itemContent);

  const { showFooter: showISIFooter } = useISI(itemContent, false);
  const { isShown: isAppPromptShown, height: appPromptHeight } = useAppPrompt();

  let content = null;
  let rightContent = null;

  const trendingLinkText = userExists
    ? "Blocked.trendingCases.linkText2"
    : "Blocked.trendingCases.linkText";

  const isStaticCase = Utils.CaseUtils.isStaticCase(itemContent?.caseState);

  if (isMobileOnly) {
    content = (
      <>
        <BlockedCaseContent
          itemContent={itemContent}
          commentStatus={commentStatus}
          caseDetails={caseDetails}
        />
        {!quizLayout && !isStaticCase && !isNonMedicalPost && (
          <Card className="mt-2">
            <CardBody>
              <AboutThisCaseCard caseContent={itemContent} showNewUngated />
            </CardBody>
          </Card>
        )}

        <BlockedCaseUpsell itemContent={itemContent} />
        {!quizLayout && (
          <>
            <BlockedCaseCarousel
              className="mb-2"
              title={i18n.t("Blocked.relatedCases.title")}
              linkText={i18n.t("Blocked.relatedCases.linkText")}
              cases={caseDetails.relatedCases}
            />

            {trendingCasesData?.length && (
              <BlockedCaseCarousel
                title={i18n.t("Blocked.trendingCases.title")}
                linkText={i18n.t(trendingLinkText)}
                cases={trendingCasesData}
              />
            )}
          </>
        )}
      </>
    );
  } else {
    content = (
      <>
        <BlockedCaseContent
          itemContent={itemContent}
          commentStatus={commentStatus}
          caseDetails={caseDetails}
        />
        {!quizLayout && !isStaticCase && !isNonMedicalPost && (
          <Card className="mt-2">
            <CardBody>
              <AboutThisCaseCard caseContent={itemContent} showNewUngated />
            </CardBody>
          </Card>
        )}
      </>
    );

    rightContent = (
      <>
        <BlockedCaseUpsell itemContent={itemContent} />

        {!quizLayout && (
          <>
            {!isNonMedicalPost && (
              <BlockedSimilarCases
                className="mb-2"
                cases={caseDetails.relatedCases}
                title={i18n.t("Blocked.relatedCases.title")}
                linkText={i18n.t("Blocked.relatedCases.linkText")}
                trackingClick={() =>
                  trackUngatedCTA(
                    UNGATED_ACTIONS.CTA_LOCATION.SIMILAR_CASES,
                    userExists,
                    itemContent?.caseType
                  )
                }
              />
            )}

            {trendingCasesData?.length && (
              <BlockedSimilarCases
                className="mb-2"
                cases={trendingCasesData}
                title={i18n.t("Blocked.trendingCases.title")}
                linkText={i18n.t(trendingLinkText)}
                trackingClick={() =>
                  trackUngatedCTA(
                    UNGATED_ACTIONS.CTA_LOCATION.TRENDING,
                    userExists,
                    itemContent?.caseType
                  )
                }
              />
            )}
          </>
        )}
      </>
    );
  }

  const styleProps = {};
  if (showISIFooter && !isMobileOnly) {
    styleProps.marginBottom = ISI_FOOTER_HEIGHT;
  }
  if (isAppPromptShown) {
    styleProps.marginBottom = (styleProps.marginBottom ?? 0) + appPromptHeight;
  }
  if (isGated && !isSponsored) {
    if (isSponsored) {
      styleProps.bottom = "5rem";
    } else {
      styleProps.bottom = "0";
    }
  }

  return (
    <ISILayout isi={itemContent} enableGatedBanner>
      <Figure1Page2Col
        isChildPage
        mainContent={content}
        rightSidebarContent={
          <>
            {rightContent && (
              <AutoSizer
                className={`position-lg-fixed ${
                  isGated && !isSponsored ? "" : "b-lg-2"
                } overflow-y-lg-auto ${isGated && !isSponsored ? "gated" : ""}`}
                style={styleProps}>
                {rightContent}
              </AutoSizer>
            )}
          </>
        }
      />
    </ISILayout>
  );
};

export default BlockedCaseDetailPage;
