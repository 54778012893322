/** @format */

import React from "react";
import { Card, CardBody } from "reactstrap";
import standInImage from "../../../assets/images/searchTextImage.png";

const MiniCaseCard = ({ caseDetails }) => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  if (!caseDetails) return null;

  const imgUrl = caseDetails?.media?.[0]
    ? `${caseDetails.media?.[0].media_url}?fit=crop&max-h=216&w=216`
    : null;

  const imgEl = (
    <img
      src={imgUrl ? imgUrl : standInImage}
      alt=""
      style={{ maxWidth: "100%" }}
    />
  );

  return (
    <Card className="border-0">
      <CardBody>
        <div className="mb-2">{imgEl}</div>
        <div className="text-clamp-18-3">
          {caseDetails?.title ? caseDetails?.title : caseDetails?.caption}
        </div>
      </CardBody>
    </Card>
  );
};

export default MiniCaseCard;
