/** @format */
import {
  SET_CURRENT_DRAFT_STARTED,
  SET_CURRENT_DRAFT_CORE_DATA,
  SET_CURRENT_DRAFT_COMPLETE,
  UPDATE_CURRENT_DRAFT_MEDIA,
  LOAD_USER_DRAFTS_COMPLETED,
  USER_DRAFT_LISTENER_ON,
  USER_DRAFT_LISTENER_OFF,
  SAVE_DRAFT_START,
  SAVE_DRAFT_COMPLETE,
  DELETE_DRAFT_START,
  DELETE_DRAFT_COMPLETE,
  UPDATE_DRAFT_MEDIA_START,
  UPDATE_DRAFT_MEDIA_COMPLETE,
  SEND_DRAFT_TO_BACKEND_START,
  SEND_DRAFT_TO_BACKEND_COMPLETE,
  LOG_ERROR,
  CLEAR_CURRENT_DRAFT
} from "../actions/user-draft.actions";
import { RESET_ALL } from "../actions/global.actions";

export const INITIAL_STATE = {
  data: {},
  listener: null,
  isProcessing: false,
  error: false,
  message: null,
  nbMediaToUpload: 0,
  currentDraft: null
};

const UserDraftReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ALL:
      return { ...INITIAL_STATE };
    case SET_CURRENT_DRAFT_CORE_DATA:
      return {
        ...state,
        currentDraft: { ...action.payload }
      };
    case CLEAR_CURRENT_DRAFT: {
      return {
        ...state,
        currentDraft: null
      };
    }
    case SET_CURRENT_DRAFT_COMPLETE:
      const newDraft = { ...action.payload.currentDraft };

      if (newDraft.groupUuid === null) {
        newDraft.groupUuid = "public";
      }

      return {
        ...state,
        isProcessing: false,
        currentDraft: newDraft
      };
    case LOAD_USER_DRAFTS_COMPLETED:
      let currentDraft = { ...state.currentDraft };
      if (
        currentDraft &&
        action.payload.data &&
        action.payload.data[currentDraft.uid]
      ) {
        // only taking the media on update for now
        currentDraft.media = [...action.payload.data[currentDraft.uid].media];
      }
      return {
        ...state,
        currentDraft: { ...currentDraft },
        data: action.payload.data
      };
    case USER_DRAFT_LISTENER_ON:
      return { ...state, listener: action.payload.listener };
    case SET_CURRENT_DRAFT_STARTED:
    case SAVE_DRAFT_START:
      return { ...state, isProcessing: true };
    case SAVE_DRAFT_COMPLETE: {
      const newData = { ...state.data };
      newData[action.payload.draftUid] = { ...action.payload.data };

      return {
        ...state,
        data: { ...newData },
        isProcessing: false,
        error: false
      };
    }
    case DELETE_DRAFT_START:
      return { ...state, isProcessing: true };
    case DELETE_DRAFT_COMPLETE:
      return { ...state, isProcessing: false, error: false };
    case UPDATE_DRAFT_MEDIA_START:
      return { ...state, isProcessing: true };
    case UPDATE_DRAFT_MEDIA_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: false
      };
    case SEND_DRAFT_TO_BACKEND_START:
      return { ...state, isProcessing: true };
    case SEND_DRAFT_TO_BACKEND_COMPLETE:
      return { ...state, isProcessing: false, error: false };
    case UPDATE_CURRENT_DRAFT_MEDIA: {
      const updatedCurrentDraft = {
        ...state?.currentDraft,
        media: action.payload.media
      };

      return {
        ...state,
        currentDraft: updatedCurrentDraft
      };
    }
    case USER_DRAFT_LISTENER_OFF:
      return {
        ...state,
        isProcessing: false,
        listener: null,
        error: action.error,
        message: action.message
      };
    case LOG_ERROR:
      return { ...state, isProcessing: false, error: action.payload.error };
    default:
      return state;
  }
};

export default UserDraftReducer;
