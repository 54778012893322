import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Dialog from "../../components/dialog/Dialog";
import i18n from "../../utils/i18n";
import * as ROUTES from "../../constants/routes";
import { Alert, FormFeedback, FormGroup, Label } from "reactstrap";
import { Controller, useForm } from "react-hook-form";
import TextareaAutosize from "react-textarea-autosize";
import classNames from "../../utils/class-names-utils";
import { useDispatch, useSelector } from "react-redux";
import groupActions from "../../actions/groups.actions";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";
import { trackGroupEvent, trackPageEntry } from "../../actions/metrics.actions";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import F1Badge from "../../components/common/F1Badge";
import F1ToggleButton from "../../components/common/F1ToggleButton";

const MAX_GROUP_NAME_CHAR = 40;
const MAX_DESCRIPTION_CHAR = 2500;

const CreateGroupModal = ({ isOpen, toggle }) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const history = useHistory();
  const processing = useSelector((state) => state.groups?.isProcessing);
  const [serverError, setServerError] = useState(null);

  const { handleSubmit, setValue, control, errors, watch } = useForm({
    mode: "onChange"
  });

  const watchGroupName = watch("groupName");
  const watchDescription = watch("description");
  const watchIsPublicGroup = watch("isPublicGroup");

  const confirmBtnDisabled =
    !watchGroupName || !!errors.groupName || !!errors.description;

  /************************************ HOOKS ****************************************/
  useEffect(() => {
    if (isOpen)
      trackPageEntry(METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_CREATION_ENTRY);
  }, [isOpen]);
  /*********************************** FUNCTIONS *************************************/

  const onToggle = () => {
    if (isOpen) {
      dispatch(groupActions.clearError());
      setServerError(null);
    }
    toggle();
  };

  const togglePagingCase = () => {
    setValue("isPublicGroup", !watchIsPublicGroup);
  };

  const save = async (values) => {
    try {
      trackGroupEvent({
        eventName: METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_CREATE,
        groupName: values.groupName,
        groupDescription: values.description,
        publicGroup: !!values.isPublicGroup
      });
      setServerError(null);

      const result = await dispatch(
        groupActions.createGroup({
          groupName: values.groupName,
          groupDescription: values.description,
          isPublicGroup: !!values.isPublicGroup
        })
      );

      if (result.error) {
        setServerError(
          i18n.t(
            `GroupsFeed.CreateGroupModal.errorCodes.${result?.payload?.response?.status}`
          )
        );
      }

      if (result?.payload?.groupUuid) {
        onToggle();
        history.replace(
          getUrl(ROUTES.GROUPS_FEED_INVITE, {
            feedTypeUuid: result.payload.groupUuid
          })
        );
      }
    } catch (e) {
      setServerError(e.message);
      console.log(e.message);
    }
  };

  /************************************ RENDER ***************************************/

  return (
    <Dialog
      isOpen={isOpen}
      onCancel={onToggle}
      toggle={onToggle}
      body={
        <div className="w-100">
          <h2 className="d-flex align-items-center justify-content-center px-0 pb-2 flex-grow-1 m-0 helv-bold text-18 line-height-28">
            {i18n.t("GroupsFeed.CreateGroupModal.title")}
          </h2>
          <hr className="border-width-4 border-lighter-gray" />
          <div>
            {serverError && <Alert color="danger">{serverError}</Alert>}
            <FormGroup>
              <Label
                for="groupName"
                className="d-flex text-nowrap justify-content-between">
                <span className="text-15 line-height-21 helv-med text-default-black">
                  {i18n.t("GroupsFeed.CreateGroupModal.groupNameLabel")}
                </span>
                <span className="text-13 line-height-21 text-muted">
                  {watchGroupName?.length || 0}/{MAX_GROUP_NAME_CHAR}
                </span>
              </Label>
              <Controller
                className={classNames(
                  "form-control text-15 text-default-black resize-none",
                  !!errors.groupName && "is-invalid"
                )}
                as={TextareaAutosize}
                control={control}
                id="groupName"
                name="groupName"
                placeholder={i18n.t(
                  "GroupsFeed.CreateGroupModal.groupNamePlaceholder"
                )}
                type="textarea"
                defaultValue={""}
                minRows={1}
                maxRows={2}
                rules={{
                  required: {
                    value: true,
                    message: i18n.t(
                      "GroupsFeed.CreateGroupModal.groupNameRequired"
                    )
                  },
                  maxLength: {
                    value: MAX_GROUP_NAME_CHAR,
                    message: i18n.t(
                      "GroupsFeed.CreateGroupModal.groupNameLength",
                      {
                        length: MAX_GROUP_NAME_CHAR
                      }
                    )
                  }
                }}
              />
              {!!errors.groupName && (
                <FormFeedback className="text-left">
                  {errors.groupName.message}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <Label
                for="description"
                className="d-flex text-nowrap justify-content-between">
                <span className="text-15 line-height-21 helv-med text-default-black">
                  {i18n.t("GroupsFeed.CreateGroupModal.descriptionLabel")}
                </span>{" "}
                <span className="text-13 line-height-21 text-muted">
                  {watchDescription?.length || 0}/{MAX_DESCRIPTION_CHAR}
                </span>
              </Label>
              <Controller
                className={classNames(
                  "form-control text-15 text-default-black resize-none",
                  !!errors.description && "is-invalid"
                )}
                as={TextareaAutosize}
                control={control}
                id="description"
                name="description"
                placeholder={i18n.t(
                  "GroupsFeed.CreateGroupModal.descriptionPlaceholder"
                )}
                type="textarea"
                defaultValue={""}
                minRows={4}
                maxRows={6}
                rules={{
                  maxLength: {
                    value: MAX_DESCRIPTION_CHAR,
                    message: i18n.t(
                      "GroupsFeed.CreateGroupModal.descriptionLength",
                      {
                        length: MAX_DESCRIPTION_CHAR
                      }
                    )
                  }
                }}
              />
              {!!errors.description && (
                <FormFeedback className="text-left">
                  {errors.description.message}
                </FormFeedback>
              )}
            </FormGroup>
            <FormGroup>
              <div className="border rounded pl-2 pr-3 py-3 d-flex justify-content-between">
                <p className="m-0 d-flex text-nowrap align-item-center">
                  <CustomIcon
                    className="mr-2"
                    icon="public_group_icon"
                    size={35}
                  />
                  <Label
                    for="isPublicGroup"
                    className="d-flex flex-column text-left">
                    <span className="text-15 line-height-21 helv-med text-default-black">
                      {i18n.t("GroupsFeed.CreateGroupModal.isPublicGroupLabel")}{" "}
                      <F1Badge
                        pill={false}
                        textColor="white"
                        color="battleship-gray"
                        name={i18n.t("common.beta")}
                        className="text-uppercase text-10"
                      />
                    </span>
                    <span className="text-14 line-height-18 text-muted">
                      {i18n.t("GroupsFeed.CreateGroupModal.isPublicGroupDesc1")}
                    </span>
                    <span className="text-14 line-height-18 text-muted">
                      {i18n.t("GroupsFeed.CreateGroupModal.isPublicGroupDesc2")}
                    </span>
                  </Label>
                </p>
                <Controller
                  control={control}
                  id="isPublicGroup"
                  name="isPublicGroup"
                  defaultValue={!!watchIsPublicGroup}
                  render={() => (
                    <F1ToggleButton
                      short={true}
                      inactiveLabel={""}
                      activeLabel={""}
                      value={!!watchIsPublicGroup}
                      onClick={togglePagingCase}
                    />
                  )}
                />
              </div>
            </FormGroup>
          </div>
        </div>
      }
      confirmText={i18n.t("GroupsFeed.CreateGroupModal.confirmBtnText")}
      confirmLoading={processing}
      confirmDisabled={confirmBtnDisabled}
      onConfirm={handleSubmit(save)}
    />
  );
};

CreateGroupModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

CreateGroupModal.defaultProps = {};

export default CreateGroupModal;
