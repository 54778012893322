import React, { useEffect, useState } from "react";
import BaseDialog from "../../components/dialog/BaseDialog";
import BaseDialogButton from "../../components/dialog/BaseDialogButton";
import BaseDialogFooter from "../../components/dialog/BaseDialogFooter";
import BaseDialogHeader from "../../components/dialog/BaseDialogHeader";
import { WHATS_NEW_SCREENS } from "../../constants/local-storage-constants";
import i18n from "../../utils/i18n";
import useIsGated from "../../hooks/useIsGated";
import { useDispatch, useSelector } from "react-redux";
import whatsNewActions from "../../actions/whatsNewFlags.actions";
import { POST_NEW } from "../../constants/routes";
import { useHistory } from "react-router-dom";
import PostsDialogContents from "./PostsDialogContents";
import AcceptedAnswerDialogContents from "./AcceptedAnswerDialogContents";
import checkFlag, { FLAG_POSTS } from "../../FeatureFlags";

const postsFlag = checkFlag(FLAG_POSTS);
const WHATS_NEW_KEY = postsFlag
  ? WHATS_NEW_SCREENS.POSTS
  : WHATS_NEW_SCREENS.ACCEPTED_ANSWER;

const WhatsNewModal = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const isGated = useIsGated();
  const history = useHistory();

  const showWhatsNew = useSelector((state) => {
    const flags = state.whatsNew?.flags;
    return flags !== null && !flags[WHATS_NEW_KEY];
  });

  const [showModal, setShowModal] = useState(false);
  const content = postsFlag ? (
    <PostsDialogContents />
  ) : (
    <AcceptedAnswerDialogContents />
  );
  const confirmBtnText = postsFlag ? "common.confirm" : "common.gotIt";
  /** ********************************** HOOKS ***************************************/
  useEffect(() => {
    if (!isGated) {
      dispatch(whatsNewActions.fetchFlags());
    }
  }, [dispatch, isGated]);

  useEffect(() => {
    if (showWhatsNew) {
      setShowModal(true);
      dispatch(whatsNewActions.markAsShown(WHATS_NEW_KEY));
    }
  }, [showWhatsNew]);
  /** ********************************** FUNCTIONS ***************************************/
  const closeModal = () => {
    setShowModal(false);
  };

  const onConfirmClick = () => {
    closeModal();
    // Do anying custom here
  };
  /** ********************************** RENDER ***************************************/

  if (!showModal || isGated) return null;

  return (
    <BaseDialog isOpen={showModal} centered toggle={closeModal}>
      <BaseDialogHeader center onCancel={closeModal} showCloseButton>
        <h3 className="helv-bold text-18">{i18n.t("WhatsNew.title")}</h3>
      </BaseDialogHeader>

      {content}

      <BaseDialogFooter>
        <BaseDialogButton
          block={true}
          className={`mt-0 w-215px`}
          color="primary"
          onClick={onConfirmClick}>
          {i18n.t(confirmBtnText)}
        </BaseDialogButton>
      </BaseDialogFooter>
    </BaseDialog>
  );
};

export default WhatsNewModal;
