import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import { useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import { fetchProfessionsIfNeeded } from "../../../actions/reference.actions";
import { signUpV2 } from "../../../actions/non-user.actions";
import { Link, useHistory } from "react-router-dom";
import FormElementEmail from "./FormElementEmail";
import FormElementPassword from "./FormElementPassword";
import { signOut, toggleStateListeners } from "../../../actions/login.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import {
  trackEvent,
  trackRegistration,
  trackSignUpPageEntry
} from "../../../actions/metrics.actions";
import FormElementCountry from "./FormElementCountry";
import FormGlobalError from "./FormGlobalError";
import useOnboardingNavigation from "../../../hooks/useOnboardingNavigation";
import { isMobile } from "react-device-detect";
import {
  METRICS_EVENT_NAME,
  METRICS_EVENT_PROPERTY,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics";
import { getUrl } from "../../../utils/route-utils";
import { trim } from "lodash";
import BecomeMemberCTA from "./BecomeMemberCTA";
import ExternalTextLink from "./ExternalTextLink";
import { StaticStrings } from "../../../constants/static-string-constants";
import useLoginModalClick from "../../../hooks/useLoginModalClick";
import useSignUpModalClick from "../../../hooks/useSignUpModalClick";

const RegistrationFormV2 = ({
  inModal = false,
  performSignout,
  email = "",
  onSignIn
}) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const onboardingNavigate = useOnboardingNavigation();
  const { showLoginModal } = useLoginModalClick();
  const { hideSignUpModal } = useSignUpModalClick();
  const history = useHistory();

  const { handleSubmit, control, errors, formState } = useForm({
    mode: "onChange",
    defaultValues: {
      email
    }
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  /** *********************************** HOOKS ***************************************/
  useEffect(() => {
    dispatch(fetchProfessionsIfNeeded());
    if (performSignout) {
      dispatch(signOut(null, true, false));
    }
  }, [dispatch, performSignout]);

  useEffect(() => {
    trackSignUpPageEntry({
      [METRICS_EVENT_PROPERTY.MODAL]: inModal
    });
  }, [inModal]);

  /** ********************************* FUNCTIONS *************************************/
  const handleSubmitClick = async (formData) => {
    // if form's still being constructed, these fields won't exist, so they'll be undefined
    if (
      formData.email === undefined ||
      formData.password === undefined ||
      formData.country === undefined
    ) {
      return;
    }
    if (!formState.isValid) {
      return;
    }

    trackRegistration({
      country: formData.country,
      email: trim(formData.email)
    });

    setIsLoading(true);
    const result = await dispatch(
      signUpV2(formData.email, formData.password, formData.country?.value)
    );

    if (result.error && result.message) {
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]: result.message ?? ""
      });
      setIsLoading(false);
      setErrorMessage(result.message);
    } else {
      trackRegistration({
        registrationSuccess: true,
        country: formData.country,
        email: trim(formData.email)
      });
      dispatch(toggleStateListeners(result.authResult?.user, true));
      if (onSignIn) {
        onSignIn();
      }
      if (result.user?.group_uuids && result.user?.group_uuids.length > 0) {
        history.push(
          getUrl(ROUTES.GROUP_WELCOME_SPLASH, {
            groupUuid: result.user?.group_uuids[0]
          })
        );
      } else {
        onboardingNavigate(result.user);
      }
    }
  };

  const onLoginClick = (e) => {
    if (inModal) {
      hideSignUpModal(e);
      showLoginModal(e);
    }
  };
  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card
        className={` rounded-0 ${
          inModal ? "border-width-0 pt-0 w-100 mb-3" : "p-4 mb-4"
        }`}>
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          {!inModal && (
            <>
              <h1
                className={`${
                  isMobile ? "text-18" : "text-22"
                } text-center helv-bold mb-2`}>
                {i18n.t("RegistrationScreens.createRegistration.v2.title")}
              </h1>
              <p
                className={`${
                  isMobile ? "text-15" : "text-16"
                } color-battleship-gray text-center mb-3 mb-lg-4`}>
                {i18n.t(
                  "RegistrationScreens.createRegistration.v2.description"
                )}
              </p>
            </>
          )}
          <FormGlobalError errorText={errorMessage} />
          <FormElementEmail
            errors={errors}
            control={control}
            showFeedback={true}
          />
          <FormElementPassword
            control={control}
            errors={errors}
            isLoading={isLoading}
            showFeedback={true}
            useHelpAsError="true"
            helpText={i18n.t("RegistrationScreens.loginForm.passwordRequired")}
          />
          <FormElementCountry
            control={control}
            errors={errors}
            showFeedback={true}
            helpText={i18n.t(
              "RegistrationScreens.createRegistration.v2.countryHelpText"
            )}
          />

          <LoadingButton
            className="w-100 mt-4"
            color="primary"
            loading={isLoading}
            spinnerColor="white"
            size="lg"
            disabled={isLoading}>
            {i18n.t("RegistrationScreens.createRegistration.v2.btnSignUp")}
          </LoadingButton>
          {inModal && (
            <div className="mt-3">
              <p className="text-center text-15 mb-0">
                <span className="text-battleship-gray">
                  {i18n.t("RegistrationScreens.loginForm.bySigningUp")}
                </span>
                <ExternalTextLink
                  className="text-cool-blue cursor-pointer"
                  url={StaticStrings.termsOfServiceUrl}
                  linkText={i18n.t(
                    "RegistrationScreens.loginForm.termsAndConditions"
                  )}
                />
              </p>
              <hr className="border-width-3" />
            </div>
          )}

          <p
            className={`d-flex w-100 mt-4 mb-1 text-15 align-items-center justify-content-center ${
              inModal ? "helv-bold" : ""
            }`}>
            <span className="text-battleship-gray">
              {i18n.t("RegistrationScreens.createRegistration.alreadyMember")}{" "}
            </span>

            <Link
              onClick={onLoginClick}
              className="text-cool-blue cursor-pointer pl-1"
              to={ROUTES.SIGN_IN}>
              {i18n.t("RegistrationScreens.createRegistration.signIn")}
            </Link>
          </p>
        </form>
      </Card>
    </>
  );
};

export default RegistrationFormV2;
