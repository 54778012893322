import React, { useEffect } from "react";
import { Controller } from "react-hook-form";
import { FormGroup, Label } from "reactstrap";
import Select from "react-select";
import { useState } from "react";
import { useSelector } from "react-redux";
import { compact } from "lodash";
import useUserHasGroups from "../../../hooks/useUserHasGroups";

const PUBLIC_GROUP = {
  label: "Figure 1 public",
  value: "public"
};

const ShareWithInput = ({
  draft,
  control,
  errors = {},
  setValue,
  className,
  label,
  placeholder
}) => {
  /** ********************************** CONFIG ***************************************/
  const hasGroups = useUserHasGroups();
  const userGroups = useSelector((state) => state?.user?.groups);
  const groupData = useSelector((state) => state?.groups?.data);

  const [availableGroups, setAvailableGroups] = useState([]);
  const [groupOptions, setGroupOptions] = useState([PUBLIC_GROUP]);

  /** ********************************** HOOKS ***************************************/
  useEffect(() => {
    if (userGroups && groupData) {
      const groups = compact(
        userGroups.map((userGroup) => {
          const group = groupData?.[userGroup.groupUuid];
          if (!group) return null;

          return { label: group.groupName, value: userGroup.groupUuid };
        })
      );

      setAvailableGroups(groups);
      setGroupOptions([PUBLIC_GROUP, ...groups]);
    }
  }, [userGroups, groupData, setAvailableGroups]);

  useEffect(() => {
    if (draft && availableGroups?.length) {
      const result = draft?.groupUuid
        ? groupOptions.find((option) => option.value === draft.groupUuid)
        : null;
      setValue("groupUuid", result);
    }
  }, [draft, availableGroups, setValue, groupOptions]);
  /** ********************************** FUNCTIONS ***************************************/
  /** ********************************** RENDER ***************************************/
  if (!hasGroups || availableGroups?.length === 0) {
    return null;
  }

  return (
    <FormGroup className={className}>
      <Label
        for="groupUuid"
        className="helv-med text-15 line-height-21 text-default-black mb-2">
        {label}
      </Label>
      <Controller
        control={control}
        name="groupUuid"
        invalid={!!errors?.groupUuid ? 1 : 0}
        defaultValue={""}
        as={
          <Select
            className="w-100 text-14 line-height-16"
            options={groupOptions}
            placeholder={placeholder}
          />
        }
      />
    </FormGroup>
  );
};

export default ShareWithInput;
