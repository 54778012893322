/** @format */

import { RESET_ALL } from "../actions/global.actions";
import {
  FETCH_VOTES_START,
  VOTES_LISTENER_ON,
  VOTES_LISTENER_OFF,
  VOTES_UPDATED,
  ERROR
} from "../actions/user-vote-answer.actions";

const INITIAL_STATE = {
  data: {},
  selected: {},
  isProcessing: false,
  error: false,
  message: null,
  listener: null
};

const UserVoteAnswer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_VOTES_START:
      return { ...state, isProcessing: true };
    case VOTES_LISTENER_ON:
      return {
        ...state,
        isProcessing: false,
        listener: action.payload.listener
      };
    case VOTES_LISTENER_OFF:
      return { ...state, isProcessing: false, listener: null };
    case VOTES_UPDATED:
      return {
        ...state,
        isProcessing: false,
        error: null,
        data: action.payload.data,
        message: action.message
      };
    case ERROR:
      return {
        ...state,
        isProcessing: false,
        error: action.error
      };
    case RESET_ALL:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default UserVoteAnswer;
