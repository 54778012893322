/** @format */
import React from "react";
import PropTypes from "prop-types";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";

const outer = {
  backgroundColor: "white"
};
const topRowText = {
  whiteSpace: "pre-line"
};
const rightMatter = {
  padding: "4px"
};
const greenBlock = {
  margin: "15px",
  padding: "10px",
  borderRadius: "4px"
};

const CMECompletedActivitiesHeader = ({ totalCredits, creditsThisMonth }) => {
  return (
    <div style={outer} className="d-flex flex-column">
      <div className="bg-denim-blue d-flex flex-row align-items-center justify-content-between">
        <div
          className="px-2 py-0 text-white text-14 line-height-18"
          style={topRowText}>
          {i18n.t("CMEScreens.creditEarned")}
        </div>
        <div className="d-flex flex-column align-items-end" style={rightMatter}>
          <span
            className="px-2 py-0 text-white helv-bold text-15"
            style={topRowText}>
            {creditsThisMonth.toFixed(2)}
          </span>
          <span className="px-2 py-0 text-white text-12" style={topRowText}>
            {i18n.t("CMEScreens.cme1")}
          </span>
        </div>
      </div>
      <div
        className="bg-green d-flex flex-column justify-content-start"
        style={greenBlock}>
        <div className="text-battleship-gray text-uppercase helv-med text-12">
          {i18n.t("CMEScreens.youHaveEarned")}
        </div>
        <div>
          <h2 className="helv-med text-18 line-height-28 m-0">
            <CustomIcon
              icon={"webnav_education_selected"}
              size={24}
              className="ml-0 icon-aqua "
            />
            {i18n.t("CMEScreens.totalEarned", {
              credits: totalCredits.toFixed(2)
            })}
          </h2>
        </div>
      </div>
    </div>
  );
};

CMECompletedActivitiesHeader.propTypes = {
  totalCredits: PropTypes.number.isRequired,
  creditsThisMonth: PropTypes.number.isRequired
};

export default CMECompletedActivitiesHeader;
