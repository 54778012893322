/** @format */

import React, { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  Nav,
  NavItem,
  Button,
  Spinner
} from "reactstrap";
import Figure1Layout from "../../@layouts/Figure1Layout";
import {
  fetchUserFollowers,
  fetchUserFollowing,
  fetchUserProfile
} from "../../actions/user-profiles.actions";
import { PROFILE_DETAIL, PROFILE_NETWORK_TYPE } from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import { NavLink } from "react-router-dom";

import "./NetworkPage.scss";
import F1FlatList from "../../components/common/F1FlatList";
import SearchProfileResultItem from "../search/components/SearchProfileResultItem";
import Loading from "../../components/loading";
import i18n from "../../utils/i18n";
import NetworkCTA from "./components/NetworkCTA";
import { trackNetworkPageEntry } from "../../actions/metrics.actions";
import useIsCurrentUser from "../../hooks/useIsCurrentUser";
import Figure1Page2Col from "../../@layouts/Figure1Page2Col";
import InviteColleaguesCTA from "./components/InviteColleaguesCTA";
import AppPrompt from "../../components/app-prompt/AppPrompt";

export const FOLLOW_TAB = {
  FOLLOWERS: "followers",
  FOLLOWING: "following"
};

const FOLLOW_LIMIT = 20;

const NetworkPage = () => {
  /** ********************************** CONFIG ***************************************/
  const { userUuid, viewType } = useParams();
  const location = useLocation();
  const locationState = location?.state;
  const metrics = useRef({});
  const history = useHistory();
  const dispatch = useDispatch();
  const isCurrentUser = useIsCurrentUser(userUuid);
  const profile = useSelector(
    (state) => state.userProfiles?.profiles?.[userUuid]
  );

  const isFollowers = viewType === FOLLOW_TAB.FOLLOWERS;

  if (!viewType) {
    history.replace(
      getUrl(PROFILE_NETWORK_TYPE, { userUuid, viewType: FOLLOW_TAB.FOLLOWERS })
    );
  }

  const followers = useSelector(
    (state) => state.userProfiles.followers[userUuid]?.data || []
  );

  const following = useSelector(
    (state) => state.userProfiles.following[userUuid]?.data || []
  );

  const isLoading = useSelector((state) => {
    return isFollowers
      ? state.userProfiles.followers[userUuid]?.loading
      : state.userProfiles.following[userUuid]?.loading;
  });
  const isEnd = useSelector((state) => {
    return isFollowers
      ? state.userProfiles.followers[userUuid]?.endReached
      : state.userProfiles.following[userUuid]?.endReached;
  });

  const title = isCurrentUser
    ? i18n.t("Network.MyNetwork")
    : i18n
        .t("Network.OtherNetwork")
        .replace("[name]", profile?.firstName || profile?.username);

  /** ********************************* FUNCTIONS *************************************/

  const loadFirstPage = useCallback(() => {
    if (isFollowers) {
      dispatch(fetchUserFollowers(userUuid, FOLLOW_LIMIT, true));
    } else if (!isCurrentUser) {
      dispatch(fetchUserFollowing(userUuid, FOLLOW_LIMIT, true));
    }
  }, [dispatch, userUuid, isFollowers, isCurrentUser]);

  const loadMore = useCallback(() => {
    if (isEnd) {
      return;
    }

    if (!isLoading && !isEnd) {
      if (isFollowers) {
        dispatch(fetchUserFollowers(userUuid, FOLLOW_LIMIT, false));
      } else {
        dispatch(fetchUserFollowing(userUuid, FOLLOW_LIMIT, false));
      }
    }
  }, [dispatch, userUuid, isEnd, isLoading, isFollowers]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (!profile) {
      dispatch(fetchUserProfile(userUuid));
    }
  }, [userUuid, dispatch, profile]);

  useEffect(() => {
    if (viewType) {
      loadFirstPage();
    }
  }, [viewType, loadFirstPage]);

  useEffect(() => {
    metrics.current.currentUser = isCurrentUser;

    if (userUuid) {
      metrics.current.userUuid = userUuid;
    }
    if (locationState?.context != null) {
      metrics.current.source = locationState.context;
    }
    if (viewType != null) {
      metrics.current.view = viewType;
    }

    if (metrics.current.view) {
      trackNetworkPageEntry(metrics.current);
    }
  }, [locationState, viewType, userUuid, isCurrentUser]);

  /** ********************************** RENDER ***************************************/

  const showLoadMore =
    !isEnd &&
    ((isFollowers && followers.length >= FOLLOW_LIMIT) ||
      (!isFollowers && !isCurrentUser));

  if (!profile) {
    return <Loading />;
  }

  return (
    <>
      <Figure1Layout footer={<AppPrompt />}>
        <Figure1Page2Col
          rightSidebarContent={<>{isCurrentUser && <InviteColleaguesCTA />}</>}
          isChildPage
          mainContentSize={9}
          rightSidebarContentSize={3}
          mainContent={
            <>
              <Card>
                <CardHeader className="bg-white">
                  <h1 className="text-18 m-0 text-center">{title}</h1>
                </CardHeader>
                <CardBody className="p-0">
                  <Nav className="d-flex bordered-nav">
                    <NavItem className="flex-grow-1 align-items-center">
                      <NavLink
                        replace
                        to={{
                          pathname: getUrl(PROFILE_NETWORK_TYPE, {
                            userUuid,
                            viewType: "followers"
                          }),
                          state: {
                            context: "Network Tab"
                          }
                        }}
                        activeClassName="active"
                        className="nav-link helv-med text-15">
                        {i18n.t("Network.Followers")}
                      </NavLink>
                    </NavItem>

                    <NavItem className="flex-grow-1">
                      <NavLink
                        replace
                        to={{
                          pathname: getUrl(PROFILE_NETWORK_TYPE, {
                            userUuid,
                            viewType: "following"
                          }),
                          state: {
                            context: "Network Tab"
                          }
                        }}
                        activeClassName="active"
                        className="nav-link helv-med text-15">
                        {i18n.t("Network.Following")}
                      </NavLink>
                    </NavItem>
                  </Nav>
                </CardBody>
                <hr className="m-0" />
                <CardBody>
                  {isCurrentUser && !isFollowers && following.length === 0 && (
                    <NetworkCTA message={i18n.t("Network.FollowingCTA")} />
                  )}

                  {!isCurrentUser && !isFollowers && following.length === 0 && (
                    <NetworkCTA
                      tall={true}
                      message={i18n.t("Network.OtherFollowingCTA")}
                    />
                  )}

                  {isFollowers && followers.length === 0 && (
                    <NetworkCTA
                      tall={true}
                      message={
                        isCurrentUser
                          ? i18n.t("Network.FollowerCTA")
                          : i18n.t("Network.OtherFollowerCTA")
                      }
                    />
                  )}

                  {(isFollowers ? followers : following).length > 0 && (
                    <F1FlatList
                      data={isFollowers ? followers : following}
                      renderItem={({ item }) => (
                        <SearchProfileResultItem
                          key={`${item.userUuid}`}
                          {...item}
                          onClick={() => {
                            history.push(
                              getUrl(PROFILE_DETAIL, {
                                userUuid: item.userUuid
                              })
                            );
                          }}
                        />
                      )}
                      ListFooterComponent={() =>
                        showLoadMore && (
                          <Button
                            className="rounded mt-3"
                            block
                            size="lg"
                            color="secondary"
                            disabled={isLoading}
                            onClick={() => {
                              if (!isLoading) {
                                loadMore();
                              }
                            }}>
                            {isLoading ? (
                              <Spinner size="sm" />
                            ) : (
                              i18n.t("Network.ViewMore")
                            )}
                          </Button>
                        )
                      }
                    />
                  )}
                </CardBody>
              </Card>
            </>
          }
        />
      </Figure1Layout>
    </>
  );
};

export default NetworkPage;
