import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { userCmeActions } from "../actions/user-cme.actions";

const useIsCaseCMECompleted = (caseUuid) => {
  const dispatch = useDispatch();

  const isCMECompleted = useSelector(
    (state) => state.userCme?.data?.[caseUuid]
  );

  useEffect(() => {
    if (caseUuid) {
      dispatch(userCmeActions.fetchCompletedCmeIfNeeded(caseUuid));
    }
  }, [caseUuid]);

  return isCMECompleted;
};

export default useIsCaseCMECompleted;
