import React from "react";
import { Col } from "reactstrap";
import * as PropTypes from "prop-types";
import Figure1PageContainer from "./Figure1PageContainer";

const Figure1Page3Col = React.forwardRef((props, ref) => {
  return (
    <Figure1PageContainer
      isChildPage={props.isChildPage}
      isContactSupport={props.isContactSupport}
      contactSupportClick={props.contactSupportClick}
      headerClass={
        props.headerClass ?? "flex-sm-column-reverse flex-lg-row pt-3 pt-lg-5"
      }
      headerButtonTitle={props.headerButtonTitle}
      headerButtonClick={props.headerButtonClick}
      headerButtonLoadingStatus={props.headerButtonLoadingStatus}
      headerRowSlot={props.headerRowSlot}
      backButtonText={props.backButtonText}>
      <Col lg="3" className="d-none d-lg-block p-0">
        {props.leftSidebarContent}
      </Col>

      {props.mainContent && <Col lg="6">{props.mainContent}</Col>}

      {props.rightSidebarContent && (
        <Col lg="3">{props.rightSidebarContent}</Col>
      )}
    </Figure1PageContainer>
  );
});

Figure1Page3Col.propTypes = {
  mainContent: PropTypes.node,
  leftSidebarContent: PropTypes.node,
  rightSidebarContent: PropTypes.node,
  isChildPage: PropTypes.bool,
  headerRowSlot: PropTypes.element
};

Figure1Page3Col.defaultProps = {
  isChildPage: false
};

export default Figure1Page3Col;
