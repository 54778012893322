import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loading from "../../components/loading";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import TopicalFeedScreen from "../feed/TopicalFeedScreen";
import { useHistory, useParams } from "react-router-dom";
import ChannelNavigation from "../../components/channel-navigation/ChannelNavigation";
import GroupEndOfFeedCard from "./GroupEndOfFeedCard";
import useCurrentFeedTypeUuid from "../../hooks/useCurrentFeedTypeUuid";
import GroupsFeedBanner from "./GroupsFeedBanner";
import GroupsIntro from "./GroupsIntro";
import GroupEmptyFeedCard from "./GroupEmptyFeedCard";
import useCurrentGroup from "../../hooks/useCurrentGroup";
import { useRedirectGroupUuid } from "../../selectors/group.selectors";
import groupActions from "../../actions/groups.actions";
import InviteModal from "./InviteModal";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";

const GroupsHomePage = (props) => {
  /** ********************************** CONFIG ***************************************/
  const routeParams = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);
  const [activeFeed, setActiveFeed] = useState(null);
  const hasGroups = useSelector((state) => {
    if (!state.groups?.data || (state.groups?.data && state.groups?.loading))
      return undefined;

    return state.groups?.data;
  });

  const currentGroup = useCurrentGroup(routeParams.feedTypeUuid);
  const currentFeedUuid = useCurrentFeedTypeUuid();
  const redirectGroupUuid = useRedirectGroupUuid();
  const showInviteModal = props.showInviteModal;

  const [isInviteModalOpen, setInviteModalOpen] = useState(false);
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (showInviteModal) {
      setInviteModalOpen(true);
    }
  }, [showInviteModal]);

  // fetch groups if required
  useEffect(() => {
    dispatch(
      groupActions.fetchGroupsByIds(user?.groups?.map((g) => g.groupUuid))
    );
  }, [dispatch, JSON.stringify(user?.groups)]);

  useEffect(() => {
    if (user.groups?.length > 0) {
      const redirectUrl = getUrl(ROUTES.GROUPS_FEED, {
        feedTypeUuid: redirectGroupUuid || user.groups[0]?.groupUuid
      });
      history.replace(redirectUrl);
    } else {
      history.replace(getUrl(ROUTES.GROUPS_HOME));
    }
  }, [history, redirectGroupUuid, JSON.stringify(user?.groups)]);

  useEffect(() => {
    setActiveFeed(routeParams.feedTypeUuid);
    dispatch(groupActions.selectGroup(currentGroup));
  }, [currentGroup, dispatch, history, routeParams]);

  /** ********************************* FUNCTIONS *************************************/
  const toggleInviteModal = () => setInviteModalOpen((prevState) => !prevState);
  const verifiedToggleInvite = useVerifiedUserGate(toggleInviteModal);

  /** ********************************** RENDER ***************************************/

  if (!user) {
    return <Loading />;
  }

  let content = (
    <>
      <GroupsFeedBanner
        verifiedToggleInvite={verifiedToggleInvite}
        showMembersModal={props.showMembersModal}
      />
      <TopicalFeedScreen
        emptyFeedComponent={<GroupEmptyFeedCard currentGroup={currentGroup} />}
        eofComponent={<GroupEndOfFeedCard groupUuid={currentFeedUuid} />}
        isGroupFeed
      />
    </>
  );

  if (!hasGroups) {
    content = null;
  } else if (
    (!user.groups || user.groups.length === 0) &&
    !props.showInviteModal
  ) {
    content = <GroupsIntro />;
  }

  return (
    <>
      <Figure1Page3Col
        leftSidebarContent={
          <ChannelNavigation activeFeed={activeFeed} isGroupFeed />
        }
        mainContent={content}
      />
      <InviteModal isOpen={isInviteModalOpen} toggle={toggleInviteModal} />
    </>
  );
};

export default GroupsHomePage;
