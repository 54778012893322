/** @format */
import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardTitle } from "reactstrap";
import ReactMarkdown from "react-markdown";
import classNames from "../../utils/class-names-utils";
import HeadingBanner from "./HeadingBanner";
import TagsContainer from "./TagsContainer";
import QuizSummaryScoreCard from "./QuizSummaryScoreCard";
import QuestionExternalLink from "./QuestionExternalLink";
import PreviousQuestionButton from "./PreviousQuestionButton";
import CaseReferences from "./CaseReferences";
import ShareCaseBanner from "./ShareCaseBanner";
import ShareButton from "./ShareButton";
import { SHARE_LOCATIONS } from "../../metrics/constants.metrics";

const QuizSummaryDetailCard = ({
  className,
  caseContent,
  nbQuestions,
  nbUserRightAnswers,
  onPrev,
  onShareClick
}) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className={classNames("feed-card", className)}>
      <Card className="border-0 rounded-0">
        <CardBody className="d-inline-flex justify-content-between align-items-center w-100 py-3">
          <TagsContainer caseContent={caseContent} />
          <ShareButton
            className="btn-text px-2 py-1 mr-n2"
            onClick={() => onShareClick(SHARE_LOCATIONS.TOP_BAR)}
          />
        </CardBody>

        {!caseContent.isPoll && (
          <CardBody className="py-3 bg-denim-blue-opaque text-white">
            <QuizSummaryScoreCard
              nbQuestions={nbQuestions}
              nbRightAnswers={nbUserRightAnswers}
              caseContent={caseContent}
            />
          </CardBody>
        )}

        {!!caseContent.heading && (
          <HeadingBanner
            className="mb-3"
            text={caseContent.heading}
            isPoll={caseContent.isPoll}
          />
        )}

        <CardBody className="pt-0">
          {!!caseContent.title && (
            <CardTitle className="mb-2 text-darker text-16" tag="h6">
              {caseContent.title}
            </CardTitle>
          )}
          <ReactMarkdown className="feed-card-info feed-card-info-markdown feed-card-detail">
            {caseContent.caption}
          </ReactMarkdown>
        </CardBody>

        {!caseContent.isSponsored && (
          <CardBody className="pt-0">
            <ShareCaseBanner
              isPoll={caseContent.isPoll}
              onShareClick={() => onShareClick(SHARE_LOCATIONS.RESULTS)}
            />
          </CardBody>
        )}

        {caseContent?.references && (
          <CardBody className="pt-0">
            <CaseReferences references={caseContent?.references} />
          </CardBody>
        )}

        {!!caseContent.externalLinkUrl && (
          <CardBody className="pt-0">
            <QuestionExternalLink
              caseUuid={caseContent.caseUuid}
              contentUuid={caseContent.contentUuid}
              campaignUuid={caseContent.campaignUuid}
              text={caseContent.externalLinkText}
              url={caseContent.externalLinkUrl}
            />
          </CardBody>
        )}

        <CardBody className="pt-0">
          <PreviousQuestionButton className="ml-n3" onClick={onPrev} />
        </CardBody>
      </Card>
    </div>
  );
};

export default QuizSummaryDetailCard;

QuizSummaryDetailCard.propTypes = {
  className: PropTypes.string,
  nbQuestions: PropTypes.number,
  nbUserRightAnswers: PropTypes.number,
  caseContent: PropTypes.shape({
    authors: PropTypes.arrayOf(
      PropTypes.shape({
        depth: PropTypes.number,
        email: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        label: PropTypes.string,
        legacyAccount: PropTypes.bool,
        name: PropTypes.string,
        specialtyUuid: PropTypes.string,
        authorUid: PropTypes.string,
        username: PropTypes.string
      })
    ),
    author_uid: PropTypes.string,
    labels: PropTypes.array,
    allReactions: PropTypes.object,
    publishedAt: PropTypes.string,
    updatedAt: PropTypes.string,
    author_username: PropTypes.string,
    author_profession_label: PropTypes.string,
    userSaved: PropTypes.array,
    meshTerms: PropTypes.array,
    features: PropTypes.object,
    specialtyUuids: PropTypes.array,
    externalLinkText: PropTypes.string,
    externalLinkUrl: PropTypes.string,
    authorProfessionalLabel: PropTypes.string,
    authorProfessionalUuid: PropTypes.string,
    contentType: PropTypes.string,
    contentUuid: PropTypes.string,
    heading: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    caseUuid: PropTypes.string,
    case_uuid: PropTypes.string,
    isSaved: PropTypes.bool,
    is_saved: PropTypes.bool,
    buttonText: PropTypes.string,
    questionAnswerDetails: PropTypes.string,
    questionOptions: PropTypes.arrayOf(
      PropTypes.shape({
        displayOrder: PropTypes.number,
        isAnswer: PropTypes.bool,
        questionOptionUuid: PropTypes.string,
        text: PropTypes.string,
        votes: PropTypes.number
      })
    ).isRequired,
    sponsoredContent: PropTypes.shape({
      disclosureText: PropTypes.string,
      sponsoredText: PropTypes.string
    }),
    media: PropTypes.arrayOf(
      PropTypes.shape({
        caseUuid: PropTypes.string,
        contentUuid: PropTypes.string,
        displayOrder: PropTypes.number,
        filename: PropTypes.string,
        height: PropTypes.number,
        mediaId: null,
        mediaUuid: PropTypes.string,
        type: PropTypes.string,
        url: PropTypes.string,
        uuid: PropTypes.string,
        width: PropTypes.number
      })
    ),
    shareLink: PropTypes.string,
    share_link: PropTypes.string
  }),
  onShareClick: PropTypes.func,
  onPrev: PropTypes.func
};
