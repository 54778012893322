/** @format */

import { Actions } from "@figure1/f1-pro-fe-brain";
import Firebase from "../firebase";

const brainActions = Actions(Firebase).AccountActions;

const accountActions = {
  ...brainActions
};

export default accountActions;
