/** @format */

import React, { Fragment, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { Button, Card, CardBody, CardFooter, Label } from "reactstrap";
import { trackCaseCMENextQuestionClick } from "../../../actions/metrics.actions";
import LoadingButton from "../../../components/common/LoadingButton";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import i18n from "../../../utils/i18n";
import CMEFormElementCheckboxList from "./CMEFormElementCheckboxList";
import CMEFormElementTextarea from "./CMEFormElementTextarea";

const CMEFormGenerator = ({
  questions,
  onSubmit,
  onFieldDirtied,
  caseUuid
}) => {
  /****************************** Config **************************************/

  const { handleSubmit, control, errors, setValue, trigger } = useForm({
    mode: "onChange"
  });

  const [touchedQuestions, setTouchedQuestions] = useState([]);
  const [latestQuestion, setLatestQuestion] = useState();
  const [pendingLatestQuestion, setPendingLatestQuestion] = useState();
  const [finalQuestionAnswered, setFinalQuestionAnswered] = useState(false);
  const isProcessing = useSelector((state) => state.question?.isProcessing);

  const displayQuestions = useMemo(() => {
    return [...touchedQuestions, latestQuestion].filter((q) => !!q);
  }, [touchedQuestions, latestQuestion]);

  const endOfQuestions = useMemo(() => {
    if (latestQuestion && !latestQuestion.nextQuestion) {
      let hasNext = false;
      latestQuestion.answerGroups.forEach((ag) => {
        hasNext = !ag.answers.find((answer) => answer.nextQuestion);
      });

      return hasNext;
    }
    return false;
  }, [latestQuestion]);

  /****************************** Hooks ***************************************/
  useEffect(() => {
    if (questions?.length && !latestQuestion) {
      const firstQ = questions[0];
      setLatestQuestion(firstQ);
    }
  }, [questions]);
  /****************************** Functions ***********************************/

  const onNextQuestion = async () => {
    const result = await trigger();
    if (result) {
      trackCaseCMENextQuestionClick(
        latestQuestion.questionUuid,
        touchedQuestions.length + 1,
        caseUuid
      );

      setTouchedQuestions(
        [...touchedQuestions, latestQuestion].filter((q) => !!q)
      );
      setLatestQuestion(pendingLatestQuestion);
      setPendingLatestQuestion();
    }
  };

  const findNextQuestion = (nextQuestionUuid) => {
    return questions.find((q) => q.questionUuid === nextQuestionUuid);
  };

  const findQuestionIndex = (questionCollection, question) => {
    let foundQindex = -1;
    questionCollection.forEach((q, i) => {
      if (q.questionUuid === question.questionUuid) {
        foundQindex = i;
      }
    });

    return foundQindex;
  };

  const onFieldDirtiedInner = (question, answer) => {
    const index = findQuestionIndex(displayQuestions, question);
    // answered question is the latest in the sequence
    if (latestQuestion.questionUuid === question.questionUuid) {
      if (answer) {
        const nextQuestion = findNextQuestion(
          answer.nextQuestion || question.nextQuestion
        );
        setPendingLatestQuestion(nextQuestion);
        setFinalQuestionAnswered(!nextQuestion);
      } else {
        setPendingLatestQuestion(null);
      }
    }
    // not the latest question
    else {
      // if next question from current answer != currently displayed next question
      if (
        !answer ||
        (answer.nextQuestion || question.nextQuestion) !==
          displayQuestions[index + 1]?.questionUuid
      ) {
        // the next question in the sequence is NOT correct anymore
        // roll touched questions back
        setTouchedQuestions([...touchedQuestions.slice(0, index)]);
        // reset latest question to current question
        setLatestQuestion(question);
        // this destroys the future

        if (answer) {
          const nextQuestion = findNextQuestion(
            answer.nextQuestion || question.nextQuestion
          );
          setPendingLatestQuestion(nextQuestion);
          setFinalQuestionAnswered(!nextQuestion);
        } else {
          setPendingLatestQuestion(null);
        }
      }
    }
    onFieldDirtied();
  };

  const pickQuestionElement = (q) => {
    switch (q.questionType) {
      case "checkboxlist":
        return (
          <CMEFormElementCheckboxList
            key={`${q.questionUuid}-${q.questionType}`}
            question={q}
            control={control}
            setValue={setValue}
            errors={errors}
            onFieldDirtied={onFieldDirtiedInner}
          />
        );
      case "freeform":
      default:
        return (
          <CMEFormElementTextarea
            key={`${q.questionUuid}-${q.questionType}`}
            question={q}
            control={control}
            setValue={setValue}
            errors={errors}
            onFieldDirtied={onFieldDirtiedInner}
          />
        );
    }
  };

  const nextQuestionEl = endOfQuestions ? null : (
    <Button
      color="link"
      onClick={onNextQuestion}
      className="d-flex align-items-center mx-auto">
      {i18n.t("CaseCMEQuestions.nextQuestion")}{" "}
      <CustomIcon icon="double-chevron" className="icon-denim-blue" size={10} />
    </Button>
  );

  const submitBtnEl = endOfQuestions ? (
    <LoadingButton
      disabled={
        !finalQuestionAnswered || Object.keys(errors).length > 0 || isProcessing
      }
      loading={isProcessing}
      size="lg"
      color="primary"
      spinnerColor="white"
      className="d-flex align-items-center mx-auto w-230px justify-content-center mb-3 text-capitalize">
      {i18n.t("common.submit")}
    </LoadingButton>
  ) : null;

  /****************************** Render **************************************/
  if (displayQuestions.length < 1) {
    return null;
  }

  return (
    <Card className="border-0">
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardBody className="pb-0">
          {displayQuestions.map((q, i) => {
            return (
              <Fragment key={i}>
                <Label
                  key={`question-title-${i}`}
                  className="text-battleship-gray text-uppercase text-13 mb-0">
                  {i18n.t("CaseCMEQuestions.question")} {i + 1} of 3
                </Label>
                {pickQuestionElement(q)}
              </Fragment>
            );
          })}
        </CardBody>
        <CardFooter className="border-0 bg-white d-flex align-items-center">
          {nextQuestionEl}
          {submitBtnEl}
        </CardFooter>
      </form>
    </Card>
  );
};

export default CMEFormGenerator;
