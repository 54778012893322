import React from "react";
import { isMobileOnly } from "react-device-detect";
import ReactMarkdown from "react-markdown";
import { Link } from "react-router-dom";
import { CardBody, CardText } from "reactstrap";
import CaseMediaView from "../../components/case/CaseMediaView";
import CaseTitle from "../../components/case/CaseTitle";
import TagsContainer from "../../components/case/TagsContainer";
import { SIGN_IN, SIGN_UP } from "../../constants/routes";
import VIDEO_CONTROL_MODE from "../../constants/video-control-mode-constants";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import { CASE_CARD_ORIGIN } from "../../metrics/constants.metrics";
import i18n from "../../utils/i18n";
import { getUrlWithRedirect } from "../../utils/route-utils";
import VoteOptionsList from "./VoteOptionsList";

const BlockedQuizDetail = ({ itemContent, loginClick }) => {
  const userExists = useAnonUserExists();
  let linkText = i18n.t("Blocked.quiz.signUpText");
  let upsellText = i18n.t("Blocked.quiz.upsell");
  const hasMedia = itemContent?.media?.length > 0;

  if (itemContent?.isPoll) {
    linkText = i18n.t("Blocked.poll.signUpText");

    if (userExists) {
      linkText = i18n.t("Blocked.poll.loginText");
    }
  } else if (userExists) {
    linkText = i18n.t("Blocked.quiz.loginText");
  }

  return (
    <>
      <div
        className={`position-relative ${
          hasMedia ? "quiz-media-overlay-container" : ""
        }`}
        style={{ height: hasMedia ? "200px" : "auto" }}>
        {itemContent?.media?.length > 0 && (
          <CaseMediaView
            disabled
            caseUuid={itemContent.caseUuid}
            caseType={itemContent.caseType}
            className={itemContent.media.length === 1 ? "pb-3" : "pb-0"}
            media={itemContent.media}
            showImages={true}
            context={CASE_CARD_ORIGIN.DETAIL}
            videoPlayerConfig={{
              videoControlMode: VIDEO_CONTROL_MODE.NONE,
              forceAutoplay: false,
              forceMuted: true
            }}
          />
        )}

        <div
          className="quiz-overlay d-flex flex-column justify-content-center align-items-center"
          style={isMobileOnly ? { paddingTop: "90px" } : {}}></div>
      </div>
      <CardBody className="pt-0 pb-2">
        <TagsContainer caseContent={itemContent} />
      </CardBody>
      <CardBody className="pt-0 pb-1">
        <CaseTitle
          className="helv-med"
          title={itemContent.title ? itemContent.title : itemContent.caption}
          publishedAt={itemContent.publishedAt}
          isPagingCase={itemContent.isPagingCase}
        />
      </CardBody>

      <CardBody className="pt-0">
        <CardText
          className="feed-card-info feed-card-info-markdown feed-card-detail"
          tag="div">
          <ReactMarkdown>{itemContent.caption}</ReactMarkdown>
        </CardText>
      </CardBody>

      <CardBody className="pt-0">
        <div className="position-relative quiz-overlay-container mh-250px">
          <VoteOptionsList
            readOnly
            options={itemContent.questionOptions}
            userAnswer={""}
          />
          <div
            className="media-overlay d-flex flex-column justify-content-end align-items-center"
            style={{ marginTop: "50px" }}>
            <div className="cta-container text-center mb-4 pt-2">
              <Link
                className={`btn btn-lg ${
                  isMobileOnly ? "text-15" : "text-16"
                } btn-primary helv-bold mt-4`}
                to={getUrlWithRedirect(userExists ? SIGN_IN : SIGN_UP)}
                {...loginClick}>
                {linkText}
              </Link>
              {!userExists && (
                <p className="mt-3 text-14 text-battleship-gray mb-0">
                  {upsellText}
                </p>
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </>
  );
};

export default BlockedQuizDetail;
