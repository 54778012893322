/** @format */
import {
  USER_FEED_META_UPDATED,
  SET_REQUIRED_FEEDS,
  USER_FEED_META_LISTENER_ON,
  USER_FEED_META_LISTENER_OFF,
  USER_FEED_META_FOLLOW,
  USER_FEED_META_FOLLOW_COMPLETE
} from "../actions/user-feed-meta.actions";
import FEED_KIND from "../constants/feed-type";
import { EMPTY_FEED_TYPE_UUID } from "../constants/empty-feed-type-uuid";
import { RESET_ALL } from "../actions/global.actions";

const defaultMadeForYouFeedInfo = {
  feed_type_uuid: EMPTY_FEED_TYPE_UUID.MADE_FOR_YOU,
  feed_kind: "naMadeForyou",
  feed_name: "No made for you feed"
};
const defaultEverythingFeedInfo = {
  feed_type_uuid: EMPTY_FEED_TYPE_UUID.EVERYTHING,
  feed_kind: "naEverything",
  feed_name: "No everything feed"
};
const defaultSearchFeedInfo = {
  feed_type_uuid: EMPTY_FEED_TYPE_UUID.SEARCH,
  feed_kind: "naSearch",
  feed_name: "No Search feed"
};

export const INITIAL_STATE = {
  data: { feeds: {} },
  everythingFeed: defaultEverythingFeedInfo,
  madeForYouFeed: defaultMadeForYouFeedInfo,
  searchFeed: defaultSearchFeedInfo,
  followedFeeds: [],
  listener: null,
  isProcessing: false,
  error: false,
  message: null
};

const UserFeedMetaReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_ALL:
      return { ...INITIAL_STATE };
    case USER_FEED_META_UPDATED:
      let everythingFeed = state.everythingFeed;
      let searchFeed = state.searchFeed;
      let madeForYouFeed = state.madeForYouFeed;
      let followedFeeds = null;
      if (action.payload.data.feeds && action.payload.data.feeds !== {}) {
        const firestoreFeeds = Object.values(action.payload.data.feeds);
        const madeForYouFeedTmp = firestoreFeeds.find((f) => {
          return f.feed_kind === FEED_KIND.RECOMMENDED;
        });
        if (madeForYouFeedTmp) {
          madeForYouFeed = madeForYouFeedTmp;
        }
        const everythingFeedTmp = firestoreFeeds.find((f) => {
          return f.feed_kind === FEED_KIND.EVERYTHING;
        });
        if (everythingFeedTmp) {
          everythingFeed = everythingFeedTmp;
        }
        const searchFeedTmp = firestoreFeeds.find((f) => {
          return f.feed_kind === FEED_KIND.SEARCH;
        });
        if (searchFeedTmp) {
          searchFeed = searchFeedTmp;
        }
        followedFeeds = firestoreFeeds.filter((f) => {
          return f.feed_kind === FEED_KIND.TOPIC && f.is_followed;
        });
      }
      return {
        ...state,
        data: { feeds: {}, ...action.payload.data },
        everythingFeed: everythingFeed,
        madeForYouFeed: madeForYouFeed,
        searchFeed: searchFeed,
        followedFeeds: followedFeeds || []
      };
    case SET_REQUIRED_FEEDS:
      if (action.payload.data && action.payload.data.length > 0) {
        const madeForYouFeed = action.payload.data.find((f) => {
          return f.feed_kind === FEED_KIND.RECOMMENDED;
        });
        const everythingFeed = action.payload.data.find((f) => {
          return f.feed_kind === FEED_KIND.EVERYTHING;
        });
        const searchFeed = action.payload.data.find((f) => {
          return f.feed_kind === FEED_KIND.SEARCH;
        });
        return {
          ...state,
          everythingFeed: everythingFeed || defaultEverythingFeedInfo,
          madeForYouFeed: madeForYouFeed || defaultMadeForYouFeedInfo,
          searchFeed: searchFeed || defaultSearchFeedInfo
        };
      } else {
        return { ...state };
      }
    case USER_FEED_META_LISTENER_ON:
      return { ...state, listener: action.payload.listener };
    case USER_FEED_META_LISTENER_OFF:
      return {
        ...state,
        isProcessing: false,
        listener: {},
        data: state.data,
        error: action.error,
        message: action.message
      };
    case USER_FEED_META_FOLLOW:
      const updates = action.payload.updates;
      const updatedFeeds = { ...state.data.feeds };

      // Optimistically toggle feed follow
      Object.keys(updates).forEach((feedUuid) => {
        // Placeholder feed object if not exists, Firestore will update it will full data later
        const feed = updatedFeeds[feedUuid] || {};
        const newIsFollowed = updates[feedUuid].shouldFollow;

        feed.is_followed = newIsFollowed;
        feed.isFollowed = newIsFollowed;

        updatedFeeds[feedUuid] = feed;
      });

      return {
        ...state,
        isProcessing: true,
        data: { feeds: updatedFeeds }
      };
    case USER_FEED_META_FOLLOW_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        message: action.message
      };
    default:
      return state;
  }
};

export default UserFeedMetaReducer;
