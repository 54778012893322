import Firebase from "../firebase";
import { Actions } from "@figure1/f1-pro-fe-brain";

const actionsPrefix = "groups";
export const CACHE_GROUP_UUID = `${actionsPrefix}/CACHE_GROUP_UUID`;
export const RESET_GROUP_UUID_CACHE = `${actionsPrefix}/RESET_GROUP_UUID_CACHE`;

const result = Actions(Firebase).GroupsActions;
const groupActions = { ...result };

export default groupActions;
