/** @format */

import React from "react";
import Figure1Layout from "../../@layouts/Figure1Layout";
import DraftsPage from "./DraftsPage";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import AppPrompt from "../../components/app-prompt/AppPrompt";

const Drafts = () => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ****************************************/
  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPrompt />}>
      <div className="mw-491 mx-auto">
        <Figure1Page1Col isChildPage mainContent={<DraftsPage />} />
      </div>
    </Figure1Layout>
  );
};

export default Drafts;
