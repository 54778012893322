import React from "react";
import { Badge, Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import PropTypes from "prop-types";
import i18n from "../../utils/i18n";
import F1Carousel from "../common/F1Carousel";
import CaseScientificLiteratureCardItem from "./CaseScientificLiteratureCardItem";
import { trackClickedLiterature } from "../../actions/metrics.actions";

const CaseScientificLiteratureCard = ({ caseUuid, casePublications }) => {
  /** ********************************** CONFIG ***************************************/
  const subtitle = i18n.t("ScientificLiterature.subtitle").replace(
    // eslint-disable-next-line no-template-curly-in-string
    "${NUM_MATCHES}",
    casePublications.length
  );

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Card className="mb-2">
      <CardBody className="pb-0">
        <CardTitle tag="h5" className="mb-2">
          {i18n.t("ScientificLiterature.title")}
        </CardTitle>
        <CardSubtitle tag="h6" className="text-muted">
          {subtitle}
        </CardSubtitle>
      </CardBody>
      <CardBody className="pt-3 pr-0">
        <F1Carousel colSize={2}>
          {casePublications.map((publication, index) => {
            return (
              <CaseScientificLiteratureCardItem
                key={publication.pubMedId}
                title={publication.title}
                url={publication.url}
                onClick={() => {
                  trackClickedLiterature({
                    pubMedId: publication.pubMedId,
                    url: publication.url,
                    position: index,
                    caseUuid
                  });
                }}
              />
            );
          })}
        </F1Carousel>
      </CardBody>
    </Card>
  );
};

CaseScientificLiteratureCard.propTypes = {
  casePublications: PropTypes.arrayOf(
    PropTypes.shape({
      caseUuid: PropTypes.string,
      displayOrder: PropTypes.number,
      pubMedId: PropTypes.string,
      pubUuid: PropTypes.string,
      source: PropTypes.string,
      title: PropTypes.string,
      url: PropTypes.string,
      uuid: PropTypes.string
    })
  ).isRequired
};

export default CaseScientificLiteratureCard;
