/** @format */

import MixpanelMetrics from "../metrics/mixpanel.metrics";
import {
  METRICS_CATEGORY,
  METRICS_EVENT_KEY,
  METRICS_EVENT_NAME,
  METRICS_EVENT_PROPERTY,
  METRICS_USER_PROPERTY,
  UNGATED_ACTIONS
} from "../metrics/constants.metrics";
import { QUESTION_TYPE } from "../components/case/VoteOptionItem";
import appSettings from "../appSettings";
import _ from "lodash";
import {
  getSpecialtiesLabel,
  getUserProfession
} from "../utils/specialty-utils";
import { trackDetailViews, trackFeedViews } from "../api/track.cloud-functions";
import { isEmpty } from "lodash";
import { PREVIEW_FEED_TYPE } from "../constants/case-type";
import { getShareCaseType } from "../utils/case-utils";

const STRING_CAP = 20;

const actionsPrefix = "@@Metrics";

export const CACHE_DETAIL_VIEW = `${actionsPrefix}/CACHE_DETAIL_VIEW`;
export const CACHE_FEED_VIEW = `${actionsPrefix}/CACHE_FEED_VIEW`;
export const RESET_CACHE = `${actionsPrefix}/RESET_CACHE`;

export const initMetricsService = () => {
  return async () => {
    MixpanelMetrics.setup();
  };
};

export const toggleTracking = (enabled) => {
  return async () => {
    MixpanelMetrics.toggleTracking(enabled);
  };
};

export const initSuperProps = (superProps) => {
  return async () => {
    MixpanelMetrics.setSessionProperty(superProps);
    MixpanelMetrics.registerCampaignParams();
  };
};

const appendMetadata = (payload) => {
  return {
    [METRICS_EVENT_KEY.VERSION]: appSettings.version,
    ...payload
  };
};

export const initAnonUserInfo = (user) => {
  if (!user) {
    return;
  }

  try {
    const payload = {
      userUuid: user.userUuid,
      userUid: user.userUid,
      $email: user.email
    };
    MixpanelMetrics.updateUser(payload);
  } catch (e) {
    console.log("ERROR: ", e.message);
    throw new Error("error while updating anon user info in mixpanel");
  }
};

export const initUserInfo = (user) => {
  if (!user) {
    return;
  }
  try {
    const payload = {
      userUuid: user.userUuid,
      username: user.username,
      firstName: user.firstName,
      lastName: user.lastName,
      verificationMethod: user.verification?.verificationType,
      verificationStatus: user.verification?.verificationStatus,
      gradYear: user.verification?.gradYear,
      school: user.schoolName,
      profession: getUserProfession(user.public?.specialties)?.name,
      specialties: getSpecialtiesLabel(user.public?.specialties),
      interests: getSpecialtiesLabel(user.interests),
      $email: user.email
    };
    MixpanelMetrics.updateUser(payload);
  } catch (e) {
    console.log("ERROR: ", e.message);
    throw new Error("error while updating user info in mixpanel");
  }
};

export const trackEvent = (eventCategory, payload) => {
  MixpanelMetrics.track(eventCategory, appendMetadata(payload || {}));
};

export const trackEventWithName = (eventCategory, eventName, payload = {}) => {
  trackEvent(eventCategory, {
    [METRICS_EVENT_KEY.EVENT_NAME]: eventName,
    ...payload
  });
};

export const trackUploadCase = (name, context, draftID) => {
  const payload = {
    [METRICS_EVENT_KEY.DRAFT_ID]: draftID,
    [METRICS_EVENT_KEY.CONTEXT]: context
  };
  trackEventWithName(METRICS_CATEGORY.UPLOAD_CASE, name, payload);
};

export const trackSlideView = (caseUuid, contentUuid, campaignUuid, index) => {
  const payload = {
    [METRICS_EVENT_KEY.CASE_UUID]: caseUuid,
    [METRICS_EVENT_KEY.CAMPAIGN_ID]: campaignUuid,
    [METRICS_EVENT_KEY.CONTENT_UUID]: contentUuid,
    [METRICS_EVENT_KEY.INDEX]: index
  };
  trackEvent(METRICS_CATEGORY.SLIDE_VIEW, payload);
};

export const trackMediaViewTraversal = ({
  contentUuid,
  mediaCount,
  maxPosition,
  context,
  currentPosition
}) => {
  trackEvent(METRICS_EVENT_NAME.SWIPE_PHOTO, {
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.PHOTO_COUNT]: mediaCount,
    [METRICS_EVENT_PROPERTY.CASE.PHOTO_MAX_POS]: maxPosition,
    [METRICS_EVENT_PROPERTY.CASE.CONTEXT]: context,
    [METRICS_EVENT_PROPERTY.CASE.PHOTO_CURRENT_POS]: currentPosition
  });
};

/**
 * FEED EVENTS
 */
export const trackFeedView = ({
  caseUuid,
  caseClassification,
  channel,
  position,
  duration,
  filters,
  groupUuid,
  label,
  caseSpecialties,
  isAnonymous
}) => {
  trackEvent(METRICS_EVENT_NAME.FEED_VIEW, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: channel,
    [METRICS_EVENT_PROPERTY.CASE.POSITION]: position,
    [METRICS_EVENT_PROPERTY.CASE.DURATION]: duration ? duration / 1000 : 0,
    [METRICS_EVENT_PROPERTY.CASE.FILTERS]: filters,
    [METRICS_EVENT_PROPERTY.GROUP_UUID]: groupUuid,
    [METRICS_EVENT_PROPERTY.CASE.LABELS]: label,
    [METRICS_EVENT_PROPERTY.CASE.CASE_CLASSIFICATION]: caseClassification,
    [METRICS_EVENT_PROPERTY.CASE.CASE_SPECIALTIES]: caseSpecialties,
    [METRICS_EVENT_PROPERTY.CASE.IS_ANONYMOUS]: isAnonymous
  });
  MixpanelMetrics.userIncrement(METRICS_EVENT_PROPERTY.FEED_VIEW_COUNT, 1);
};

export const trackFeedViewEntry = ({
  caseUuid,
  caseClassification,
  channel,
  position,
  filters,
  groupUuid,
  translationApplicable,
  currentLanguage,
  frameSwipeApplicable,
  hasDiagnosis,
  acceptedAnswer,
  label,
  caseSpecialties,
  isAnonymous
}) => {
  trackEvent(METRICS_EVENT_NAME.FEED_VIEW_ENTRY, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: channel,
    [METRICS_EVENT_PROPERTY.CASE.POSITION]: position,
    [METRICS_EVENT_PROPERTY.CASE.FILTERS]: filters,
    [METRICS_EVENT_PROPERTY.GROUP_UUID]: groupUuid,
    [METRICS_EVENT_PROPERTY.TRANSLATION.TRANSLATION_APPLICABLE]:
      translationApplicable,
    [METRICS_EVENT_PROPERTY.TRANSLATION.USER_LANGUAGE]: currentLanguage,
    [METRICS_EVENT_PROPERTY.FRAME_SWIPE.APPLICABLE]: frameSwipeApplicable,
    [METRICS_EVENT_PROPERTY.DIAGNOSIS]: hasDiagnosis,
    [METRICS_EVENT_PROPERTY.ACCEPTED_ANSWER]: acceptedAnswer,
    [METRICS_EVENT_PROPERTY.CASE.CASE_CLASSIFICATION]: caseClassification,
    [METRICS_EVENT_PROPERTY.CASE.LABELS]: label,
    [METRICS_EVENT_PROPERTY.CASE.CASE_SPECIALTIES]: caseSpecialties,
    [METRICS_EVENT_PROPERTY.CASE.IS_ANONYMOUS]: isAnonymous
  });
  MixpanelMetrics.userIncrement(METRICS_EVENT_PROPERTY.FEED_VIEW_COUNT, 1);
};

export const trackSwitchFeed = ({ target, context, from }) => {
  trackEvent(METRICS_EVENT_NAME.SWITCH_FEED, {
    [METRICS_EVENT_PROPERTY.CONTEXT]: context,
    [METRICS_EVENT_PROPERTY.TARGET]: target,
    [METRICS_EVENT_PROPERTY.FROM]: from
  });
};

/**
 * CASE EVENTS
 */
export const trackShare = ({
  caseContent,
  commentStatus,
  context,
  isAuthor,
  via,
  location,
  ungated
}) => {
  trackCaseEvent({
    eventName: METRICS_EVENT_NAME.SHARE_CASE,
    caseContent,
    commentStatus,
    context,
    extra: {
      [METRICS_EVENT_PROPERTY.UNGATED]: ungated,
      [METRICS_EVENT_PROPERTY.CASE.CASE_OWNER]: isAuthor,
      [METRICS_EVENT_PROPERTY.CASE.VIA]: via,
      // Override the existing case type metric as that one does not contain all of the properties needed.
      [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: getShareCaseType(caseContent),
      [METRICS_EVENT_PROPERTY.LOCATION]: location,
      [METRICS_EVENT_PROPERTY.CONTEXT]: context
    }
  });
  MixpanelMetrics.userIncrement(
    METRICS_EVENT_PROPERTY.CASE.SHARED_CASE_COUNT,
    1
  );
};

export const trackCopyLink = ({ caseContent, ungated }) => {
  trackEvent(METRICS_EVENT_NAME.COPY_LINK, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseContent.caseUuid,
    [METRICS_EVENT_PROPERTY.UNGATED]: ungated,
    [METRICS_EVENT_PROPERTY.CASE.CASE_CLASSIFICATION]:
      caseContent?.caseClassification
  });
};

export const trackCaseDetailEntryView = ({
  caseContent,
  commentCount,
  position,
  context,
  ungated,
  ungatedState,
  translationApplicable,
  currentLanguage,
  hasDiagnosis,
  isAnonymous,
  acceptedAnswer
}) => {
  trackCaseEvent({
    caseContent,
    commentCount,
    position,
    context,
    eventName: METRICS_CATEGORY.CASE_DETAIL_VIEW_ENTRY,
    extra: {
      [METRICS_EVENT_PROPERTY.UNGATED]: ungated,
      [METRICS_EVENT_PROPERTY.UNGATED_STATE]: ungatedState,
      [METRICS_EVENT_PROPERTY.TRANSLATION.TRANSLATION_APPLICABLE]:
        translationApplicable,
      [METRICS_EVENT_PROPERTY.TRANSLATION.USER_LANGUAGE]: currentLanguage,
      [METRICS_EVENT_PROPERTY.DIAGNOSIS]: hasDiagnosis,
      [METRICS_EVENT_PROPERTY.ANONYMOUS]: isAnonymous,
      [METRICS_EVENT_PROPERTY.ACCEPTED_ANSWER]: acceptedAnswer
    }
  });
  MixpanelMetrics.userIncrement(
    METRICS_EVENT_PROPERTY.CASE.DETAIL_VIEW_COUNT,
    1
  );
};

export const trackCaseDetailView = ({
  caseContent,
  commentCount,
  position,
  context,
  duration,
  ungated,
  isAnonymous
}) => {
  trackCaseEvent({
    caseContent,
    commentCount,
    position,
    context,
    eventName: METRICS_CATEGORY.CASE_DETAIL_VIEW,
    extra: {
      [METRICS_EVENT_PROPERTY.CASE.DURATION]: duration / 1000,
      [METRICS_EVENT_PROPERTY.UNGATED]: ungated,
      [METRICS_EVENT_PROPERTY.ANONYMOUS]: isAnonymous
    }
  });
};

export const trackCaseEvent = ({
  eventName,
  caseContent,
  caseCaption,
  commentStatus,
  commentCount,
  position,
  context,
  extra = {}
}) => {
  if (!caseContent) {
    return;
  }

  const mediaCount = caseContent.contentItems?.reduce((acc, cur) => {
    return acc + (cur.media?.length || 0);
  }, 0);
  const cCount = commentCount || _.get(commentStatus, ["allCount"], 0);
  const reactionCount = Object.values(caseContent.allReactions || {}).reduce(
    (t, n) => t + n,
    0
  );
  const author = caseContent.authors ? caseContent.authors[0] : null;
  const caption = caseCaption || caseContent.caption;

  trackEvent(eventName, {
    [METRICS_EVENT_PROPERTY.CASE.TITLE]: caseContent.title?.substring(0, 20),
    [METRICS_EVENT_PROPERTY.CASE.CAPTION]: caption?.substring(0, 20),
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseContent.caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.LABELS]: caseContent.labels,
    [METRICS_EVENT_PROPERTY.CASE.PHOTO_COUNT]: mediaCount,
    [METRICS_EVENT_PROPERTY.CASE.COMMENT_COUNT]: cCount,
    [METRICS_EVENT_PROPERTY.CASE.REACTION_COUNT]: reactionCount,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: caseContent.caseType,
    [METRICS_EVENT_PROPERTY.CASE.POSITION]: position,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: caseContent.campaignUuid,
    [METRICS_EVENT_PROPERTY.AUTHOR.UUID]: author?.userUuid,
    [METRICS_EVENT_PROPERTY.AUTHOR.USERNAME]: author?.username,
    [METRICS_EVENT_PROPERTY.AUTHOR.HAS_AVATAR]: !!author?.avatar,
    [METRICS_EVENT_PROPERTY.AUTHOR.SPECIALTY]: author?.profileDisplayName,
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: context,
    [METRICS_EVENT_PROPERTY.GROUP_UUID]: caseContent.groupUuid,
    [METRICS_EVENT_PROPERTY.CASE.CASE_SPECIALTIES]: caseContent.specialtyNames,
    [METRICS_EVENT_PROPERTY.CASE.CASE_CLASSIFICATION]:
      caseContent.caseClassification,
    ...extra
  });
};

export const trackCaseFeedCommentTap = (caseUuid, campaignUuid) => {
  const payload = {
    [METRICS_EVENT_KEY.CASE_UUID]: caseUuid,
    [METRICS_EVENT_KEY.CAMPAIGN_ID]: campaignUuid
  };
  trackEventWithName(
    METRICS_CATEGORY.CASE_FEED_VIEW,
    "TapViewComments",
    payload
  );
};

export const trackReaction = ({
  caseContent,
  position,
  reactionName,
  reactionContext,
  on
}) => {
  trackCaseEvent({
    eventName: METRICS_EVENT_NAME.REACTION,
    caseContent,
    position,
    extra: {
      [METRICS_EVENT_PROPERTY.CASE.REACTION_NAME]: reactionName,
      [METRICS_EVENT_PROPERTY.CASE.CONTEXT]: reactionContext,
      [METRICS_EVENT_PROPERTY.ON]: on
    }
  });

  MixpanelMetrics.userIncrement(METRICS_EVENT_NAME.REACTION, on ? 1 : -1);
};

export const trackComment = ({
  caseContent,
  commentStatus,
  charCount,
  isAuthor,
  position,
  isAnonymous
}) => {
  trackCaseEvent({
    eventName: METRICS_EVENT_NAME.COMMENT_CASE,
    caseContent,
    commentStatus,
    position,
    extra: {
      [METRICS_EVENT_PROPERTY.CASE.COMMENT_LENGTH]: charCount,
      [METRICS_EVENT_PROPERTY.CASE.CASE_OWNER]: isAuthor,
      [METRICS_EVENT_PROPERTY.CASE.IS_ANONYMOUS]: isAnonymous
    }
  });
};

export const trackSaveCase = ({ caseContent, commentStatus, from }) => {
  trackCaseEvent({
    eventName: METRICS_EVENT_NAME.SAVE_CASE,
    caseContent: caseContent,
    commentStatus: commentStatus,
    context: from
  });

  MixpanelMetrics.userIncrement(METRICS_CATEGORY.SAVED_CASES, 1);
};

export const trackReportCase = ({ caseContent, commentStatus, from }) => {
  trackCaseEvent({
    eventName: METRICS_CATEGORY.REPORT_CASE,
    caseContent: caseContent,
    commentStatus: commentStatus,
    context: from
  });
};

export const trackCommentEdit = ({
  commentUuid,
  contentUuid,
  charCount,
  isAnonymous
}) => {
  trackEvent(METRICS_EVENT_NAME.EDIT_COMMENT_CASE, {
    commentUuid,
    contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.COMMENT_LENGTH]: charCount,
    [METRICS_EVENT_PROPERTY.CASE.IS_ANONYMOUS]: isAnonymous
  });
};

export const trackCaseUpdate = ({
  caseContent,
  diagnosis,
  caseCaption,
  unresolved
}) => {
  trackCaseEvent({
    eventName: METRICS_EVENT_NAME.CASE_UPDATE,
    caseContent,
    caseCaption,
    extra: {
      [METRICS_EVENT_PROPERTY.CASE.DIAGNOSIS]: diagnosis,
      [METRICS_EVENT_PROPERTY.CASE.UNRESOLVED]: unresolved
    }
  });
};

/**
 * USER ACTIONS
 */
export const trackUserSignIn = ({ afterSignInsuccess = false, groupUuid }) => {
  const trackingValues = {};
  if (groupUuid) {
    trackingValues[METRICS_USER_PROPERTY.GROUP_UUID] = groupUuid;
  }
  trackEvent(
    afterSignInsuccess
      ? METRICS_EVENT_NAME.SIGN_IN_SUCCESS
      : METRICS_EVENT_NAME.SIGN_IN,
    trackingValues
  );
};

export const trackUserAutoSignIn = () => {
  trackEvent(METRICS_EVENT_NAME.AUTO_SIGN_IN, {});
};

export const trackUserSignOut = ({ signoutClick }) => {
  trackEvent(
    signoutClick
      ? METRICS_EVENT_NAME.SIGN_OUT
      : METRICS_EVENT_NAME.SIGN_OUT_LOGGED_OUT,
    {}
  );
};

/**
 * FOLLOWER/FOLLOWING
 */

export const trackToggleUserFollow = ({
  username,
  userUuid,
  hasAvatar,
  context,
  isFollow,
  isGated
}) => {
  const name = isFollow
    ? METRICS_EVENT_NAME.PROFILE.FOLLOW
    : METRICS_EVENT_NAME.PROFILE.Unfollow;

  trackEvent(name, {
    [METRICS_EVENT_PROPERTY.CONTEXT]: context,
    [METRICS_EVENT_PROPERTY.PROFILE.USERNAME]: username,
    [METRICS_EVENT_PROPERTY.PROFILE.UUID]: userUuid,
    [METRICS_EVENT_PROPERTY.PROFILE.HAS_AVATAR]: hasAvatar,
    [METRICS_EVENT_PROPERTY.UNGATED]: isGated
  });
};

export const trackUserProfileClick = ({ username, userUuid, isGated }) => {
  trackEvent(METRICS_EVENT_NAME.PROFILE.CLICK, {
    [METRICS_EVENT_PROPERTY.PROFILE.USERNAME]: username,
    [METRICS_EVENT_PROPERTY.PROFILE.UUID]: userUuid,
    [METRICS_EVENT_PROPERTY.UNGATED]: isGated
  });
};

export const trackUserFollowClick = ({ isGated }) => {
  trackEvent(METRICS_EVENT_NAME.PROFILE.FOLLOW_CLICK, {
    [METRICS_EVENT_PROPERTY.UNGATED]: isGated
  });
};

/**
 * SHARE CASE ACTIONS
 *  */
export const trackShareCase = ({ context, groupUuid }) => {
  const content = {
    [METRICS_EVENT_PROPERTY.CASE.CONTEXT]: context
  };
  if (groupUuid) {
    content[METRICS_EVENT_PROPERTY.GROUP_UUID] = groupUuid;
  }

  trackEvent(METRICS_EVENT_NAME.UPLOAD.START, content);
};

export const trackShareCaseOption = (option) => {
  trackEvent(METRICS_EVENT_NAME.UPLOAD.OPTIONS, {
    [METRICS_EVENT_PROPERTY.UPLOAD.SHARE_OPTION]: option
  });
};

export const trackUploadDetails = ({
  mediaCount,
  title,
  caption,
  caseStatus,
  caseLabels,
  shareOption,
  draftUuid,
  isSaveDraft,
  specialties,
  subSpecialties,
  groupUuid,
  isAnonymous,
  caseClassification
}) => {
  const name = isSaveDraft
    ? METRICS_EVENT_NAME.UPLOAD.DRAFT
    : METRICS_EVENT_NAME.UPLOAD.SUBMISSION;
  trackEvent(name, {
    [METRICS_EVENT_PROPERTY.UPLOAD.MEDIA_COUNT]: mediaCount,
    [METRICS_EVENT_PROPERTY.UPLOAD.TITLE]: title?.substring(0, 20),
    [METRICS_EVENT_PROPERTY.UPLOAD.TITLE_COUNT]: title?.length,
    [METRICS_EVENT_PROPERTY.UPLOAD.CAPTION]: caption?.substring(0, 20),
    [METRICS_EVENT_PROPERTY.UPLOAD.CAPTION_COUNT]: caption?.length,
    [METRICS_EVENT_PROPERTY.UPLOAD.CASE_STATUS]: caseStatus,
    [METRICS_EVENT_PROPERTY.UPLOAD.CASE_LABELS]: caseLabels,
    [METRICS_EVENT_PROPERTY.UPLOAD.DRAFT_UID]: draftUuid,
    [METRICS_EVENT_PROPERTY.UPLOAD.SHARE_OPTION]: shareOption,
    [METRICS_EVENT_PROPERTY.GROUP_UUID]: groupUuid,
    [METRICS_EVENT_PROPERTY.UPLOAD.SPECIALTIES]: specialties,
    [METRICS_EVENT_PROPERTY.UPLOAD.SUBSPECIALTIES]: subSpecialties,
    [METRICS_EVENT_PROPERTY.UPLOAD.IS_ANONYMOUS]: isAnonymous,
    [METRICS_EVENT_PROPERTY.UPLOAD.CASE_CLASSIFICATION]: caseClassification
  });

  if (!isSaveDraft) {
    MixpanelMetrics.userIncrement(
      METRICS_EVENT_PROPERTY.CASE.SHARED_CASE_COUNT,
      1
    );
  }
};

/**
 * SEARCH ACTIONS
 */
export const trackUserSearch = ({ context, term }) => {
  trackEvent(METRICS_EVENT_NAME.SEARCH, {
    [METRICS_EVENT_PROPERTY.SEARCH_TERM]: term,
    [METRICS_EVENT_KEY.CONTEXT]: context
  });
  MixpanelMetrics.userIncrement(METRICS_EVENT_PROPERTY.NO_OF_SEARCHES, 1);
};

/**
 * PROFILE ACTIONS
 */
export const trackVisitProfile = ({
  username,
  userUuid,
  userType,
  specialty,
  hasAvatar,
  contentCount
}) => {
  trackEvent(METRICS_EVENT_NAME.PROFILE.VISIT, {
    [METRICS_EVENT_PROPERTY.PROFILE.USERNAME]: username,
    [METRICS_EVENT_PROPERTY.PROFILE.UUID]: userUuid,
    [METRICS_EVENT_PROPERTY.PROFILE.TYPE]: userType,
    [METRICS_EVENT_PROPERTY.PROFILE.SPECIALTY]: specialty,
    [METRICS_EVENT_PROPERTY.PROFILE.HAS_AVATAR]: hasAvatar,
    [METRICS_EVENT_PROPERTY.PROFILE.CONTENT_COUNT]: contentCount
  });
};

export const trackProfileLink = ({ link, username, userUuid, userType }) => {
  trackEvent(METRICS_EVENT_NAME.PROFILE.LINK_TAP, {
    [METRICS_EVENT_PROPERTY.PROFILE.LINK]: link,
    [METRICS_EVENT_PROPERTY.PROFILE.USERNAME]: username,
    [METRICS_EVENT_PROPERTY.PROFILE.UUID]: userUuid,
    [METRICS_EVENT_PROPERTY.PROFILE.TYPE]: userType
  });
};

export const trackEditAbout = (fieldName) => {
  trackEvent(METRICS_EVENT_NAME.PROFILE.EDIT_ABOUT, {
    [METRICS_EVENT_PROPERTY.FIELD]: fieldName
  });
};

export const trackEditProfile = (fields) => {
  trackEvent(METRICS_EVENT_NAME.PROFILE.EDIT_PROFILE, {
    [METRICS_EVENT_PROPERTY.FIELD]: fields
  });
};

export const trackProfileEditClick = (changeRequestExists) => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.EDIT, {
    [METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.EDIT_PROFESSION_CHANGE]:
      changeRequestExists
  });
};

export const trackProfileSaveClick = (context, extra = {}) => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.SAVE, {
    [METRICS_EVENT_PROPERTY.CONTEXT]: context,
    ...extra
  });
};

export const trackProfileVerifyClick = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.VERIFY);
};

export const trackProfileNpiVerificationClick = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.VERIFY_NPI);
};

export const trackProfilePhotoVerificationClick = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.VERIFY_PHOTO);
};

/**
 * CME ACTIONS
 */

export const trackCMELanding = (source) => {
  trackEvent(METRICS_EVENT_NAME.CME_CENTRE_VIEW, {
    [METRICS_EVENT_PROPERTY.CONTEXT]: source
  });
};

export const trackTacticExternalLink = ({
  url,
  caseUuid,
  campaignUuid,
  contentUuid
}) => {
  trackEvent(METRICS_EVENT_NAME.TACTIC_LINK, {
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: campaignUuid,
    [METRICS_EVENT_PROPERTY.CASE.LINK]: url
  });
};

export const trackCMESlide = ({
  caseContent,
  campaignUuid,
  contentUuid,
  displayOrder,
  section,
  duration,
  contentType,
  isUngated
}) => {
  const payload = {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseContent?.caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: caseContent?.caseType,
    [METRICS_EVENT_PROPERTY.CASE.CONTENT_TYPE]: contentType,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: campaignUuid,
    [METRICS_EVENT_PROPERTY.CASE.SECTION]: section,
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.DISPLAY_ORDER]: displayOrder,
    [METRICS_EVENT_PROPERTY.CASE.DURATION]: duration ? duration / 1000 : 0,
    [METRICS_EVENT_PROPERTY.UNGATED]: isUngated
  };
  trackEvent(METRICS_EVENT_NAME.TACTIC_SLIDE_VIEW, payload);
};

export const trackQuestionAnswer = ({
  caseUuid,
  campaignUuid,
  contentItem,
  answer,
  isUngated
}) => {
  trackEvent(METRICS_EVENT_NAME.QUESTION_ANSWERED, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: campaignUuid,
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: contentItem?.contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAPTION]: contentItem?.caption,
    [METRICS_EVENT_PROPERTY.CASE.CONTENT_TITLE]: contentItem?.title,
    [METRICS_EVENT_PROPERTY.CASE.QUESTION_OPTION_ID]:
      answer?.questionOptionUuid,
    [METRICS_EVENT_PROPERTY.CASE.ANSWER]: answer?.text?.substring(0, 20),
    [METRICS_EVENT_PROPERTY.CASE.QUESTION_TYPE]: contentItem?.isPoll
      ? QUESTION_TYPE.POLL
      : QUESTION_TYPE.QUIZ,
    [METRICS_EVENT_PROPERTY.CASE.CORRECT_ANSWER]: answer?.isAnswer,
    [METRICS_EVENT_PROPERTY.UNGATED]: isUngated
  });
};

export const trackQuestionCompletion = ({
  score,
  caseUuid,
  campaignUuid,
  type,
  isUngated
}) => {
  trackEvent(METRICS_EVENT_NAME.COMPLETE_QUIZ, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: campaignUuid,
    [METRICS_EVENT_PROPERTY.CASE.SCORE]: score,
    [METRICS_EVENT_PROPERTY.TYPE]: type,
    [METRICS_EVENT_PROPERTY.UNGATED]: isUngated
  });
};

export const trackClaimCredit = ({ degree, caseUuid, campaignUuid }) => {
  trackEvent(METRICS_EVENT_NAME.CLAIM_SCORE, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: campaignUuid,
    [METRICS_EVENT_PROPERTY.DEGREE_TYPE]: degree
  });
};

export const trackDownloadCertificate = ({
  link,
  caseUuid,
  campaignUuid,
  caseType
}) => {
  trackEvent(METRICS_EVENT_NAME.DOWNLOAD_CERTIFICATE, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: campaignUuid,
    [METRICS_EVENT_PROPERTY.CASE.LINK]: link,
    [METRICS_EVENT_PROPERTY.CASE.TYPE]: caseType
  });
};

/*****
 * GATED
 ****/

export const trackGatedCTA = ({
  caseUuid,
  campaignUuid,
  caseType,
  source,
  action,
  originalAction
}) => {
  trackEvent(METRICS_EVENT_NAME.UNGATED, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: campaignUuid,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: caseType,
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: source,
    [METRICS_EVENT_PROPERTY.ACTION]: action,
    [METRICS_EVENT_PROPERTY.ORIGINAL_ACTION]: originalAction
  });
};

export const trackGatedCTAClickthru = (name, props) => {
  trackEvent(`${name} CTA`, props);
};

export const trackUngatedCTA = (location, login, caseType) => {
  const label = login ? UNGATED_ACTIONS.CTA.LOGIN : UNGATED_ACTIONS.CTA.SIGN_UP;

  trackGatedCTAClickthru(label, {
    [METRICS_EVENT_PROPERTY.LOCATION]: location,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: caseType
  });
};

/*****
 * Registration/Onboarding
 ****/

// Specific metrics
export const trackRegistration = ({
  registrationSuccess = false,
  email,
  first,
  last,
  profession,
  country,
  groupUuid,
  npi
}) => {
  const trackingValues = {};
  if (email) {
    trackingValues[METRICS_USER_PROPERTY.EMAIL] = email;
  }
  if (country) {
    trackingValues[METRICS_USER_PROPERTY.COUNTRY] = country;
  }
  if (first) {
    trackingValues[METRICS_USER_PROPERTY.FIRST_NAME] = first;
  }
  if (last) {
    trackingValues[METRICS_USER_PROPERTY.LAST_NAME] = last;
  }
  if (first && last) {
    trackingValues[METRICS_USER_PROPERTY.NAME] = `${first} ${last}`;
  }
  if (profession) {
    trackingValues[METRICS_USER_PROPERTY.PROFESSION] = profession;
  }
  if (groupUuid) {
    trackingValues[METRICS_USER_PROPERTY.GROUP_UUID] = groupUuid;
  }
  if (npi) {
    trackingValues[METRICS_USER_PROPERTY.NPI] = npi;
  }

  trackEvent(
    registrationSuccess
      ? METRICS_EVENT_NAME.REGISTRATION_SUCCESS
      : METRICS_EVENT_NAME.REGISTRATION,
    trackingValues
  );
};

export const trackOnboardingAccountDetails = ({
  interests,
  specialty,
  username
}) => {
  trackEvent(METRICS_EVENT_NAME.ACCOUNT_DETAILS, {
    [METRICS_USER_PROPERTY.USERNAME]: username,
    [METRICS_USER_PROPERTY.INTERESTS]: interests,
    [METRICS_EVENT_PROPERTY.PROFILE.SPECIALTY]: specialty
  });
};

export const trackVerifyNonNpiEntry = () => {
  trackEvent(METRICS_EVENT_NAME.NON_NPI_ACCOUNT_DETAIL_ENTRY);
};

export const trackVerifyNonNpi = ({
  firstName,
  lastName,
  primarySpecialty,
  graduationDate
}) => {
  trackEvent(METRICS_EVENT_NAME.NON_NPI_ACCOUNT_DETAIL, {
    [METRICS_USER_PROPERTY.FIRST_NAME]: firstName,
    [METRICS_USER_PROPERTY.LAST_NAME]: lastName,
    [METRICS_USER_PROPERTY.SPECIALTY]: primarySpecialty,
    [METRICS_USER_PROPERTY.GRADUATION_DATE]: graduationDate
  });
};

export const trackCheckNpi = (npiNumber) => {
  trackEvent(METRICS_EVENT_NAME.VERIFY_NPI, {
    [METRICS_USER_PROPERTY.NPI]: npiNumber
  });
};

export const trackVerifyNpiEntry = (npi) => {
  const trackingValues = {};
  if (npi) {
    trackingValues[METRICS_USER_PROPERTY.NPI] = npi;
  }
  trackEvent(METRICS_EVENT_NAME.NPI_ACCOUNT_DETAIL_ENTRY, trackingValues);
};

export const trackVerifyNpi = ({
  firstName,
  lastName,
  primarySpecialty,
  npiNumber
}) => {
  trackEvent(METRICS_EVENT_NAME.VERIFY_NPI, {
    [METRICS_USER_PROPERTY.FIRST_NAME]: firstName,
    [METRICS_USER_PROPERTY.LAST_NAME]: lastName,
    [METRICS_USER_PROPERTY.SPECIALTY]: primarySpecialty,
    [METRICS_USER_PROPERTY.NPI]: npiNumber
  });
};

export const trackSelectUsernameExit = (username) => {
  trackEvent(METRICS_EVENT_NAME.SELECT_USERNAME_EXIT, {
    [METRICS_USER_PROPERTY.USERNAME]: username
  });
};

export const trackVerifyPhoto = () => {
  trackEvent(METRICS_EVENT_NAME.VERIFY_PHOTO);
};
export const trackVerifyInsitutionalEmail = (email) => {
  trackEvent(METRICS_EVENT_NAME.VERIFY_EMAIL, {
    [METRICS_USER_PROPERTY.INSTITUTION_EMAIL]: email
  });
};

export const trackOnboardingSupportClick = () => {
  trackEvent(METRICS_EVENT_NAME.ONBOARDING_CONTACT_SUPPRT);
};

export const trackRegPageEntry = (section, extra) => {
  trackPageEntry(section + METRICS_EVENT_NAME.ENTRY, extra);
};

export const trackInstitutionalEmailPageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.VERIFY_EMAIL, extra);
};

export const trackPhotoPageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.VERIFY_PHOTO, extra);
};

export const trackSignUpPageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.ACCOUNT_CREATE, extra);
};

export const trackUsernamePageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.SELECT_USERNAME_EXIT, extra);
};

export const trackNPIFastTrackPageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.NPI_FAST_TRACK, extra);
};

export const trackLegacyWelcomePageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.LEGACY_WELCOME, extra);
};

export const trackCountryPageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.CONFIRM_COUNTRY, extra);
};

export const trackHelpModalPageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.ONBOARDING_HELP_MODAL, extra);
};

export const trackLogInPageEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.LOGIN_PAGE, extra);
};

export const trackWhoCanJoinModalEntry = (extra) => {
  trackRegPageEntry(METRICS_EVENT_NAME.ONBOARDING_WHO_CAN_JOIN_MODAL, extra);
};

/**************
 * Promo cards
 **************/

export const trackPromoCard = ({ name, card }) => {
  trackEvent(name, {
    [METRICS_EVENT_PROPERTY.CASE.TITLE]: card?.title?.substring(0, STRING_CAP),
    [METRICS_EVENT_PROPERTY.CASE.CAPTION]: card?.caption?.substring(
      0,
      STRING_CAP
    ),
    [METRICS_EVENT_PROPERTY.CASE.CTA]: card?.buttonText,
    [METRICS_EVENT_PROPERTY.CASE.UUID]: card?.caseUuid,
    [METRICS_EVENT_PROPERTY.CASE.CAMPAIGN_UUID]: card?.campaignUuid
  });
};

export const trackPromoCardServed = (card) => {
  trackPromoCard({ name: METRICS_EVENT_NAME.PROMO.SERVED, card });
};

export const trackPromoCardDismissed = (card) => {
  trackPromoCard({ name: METRICS_EVENT_NAME.PROMO.DISMISSED, card });
};

export const trackPromoCardClicked = (card) => {
  trackPromoCard({ name: METRICS_EVENT_NAME.PROMO.CLICKED, card });
};

/********
 * Filters
 ********/

export const trackFiltersOpen = () => {
  trackEvent(METRICS_EVENT_NAME.FILTERS.ENTRY);
};

export const trackApplyFilters = (filters) => {
  try {
    if (filters) {
      trackEvent(METRICS_EVENT_NAME.FILTERS.APPLY, {
        [METRICS_EVENT_PROPERTY.FILTERS]: Object.entries(filters)
          .filter(([_, value]) => value)
          .map((arr) => arr[0])
      });
    }
  } catch (e) {
    console.log(e.message);
  }
};

/********
 * Page entry
 ********/

// seems redundant, but could be expanded on later
export const trackPageEntry = (pageName, extra = {}) => {
  trackEvent(pageName, extra);
};

export const trackNetworkPageEntry = ({
  source,
  view,
  userUuid,
  currentUser
}) => {
  trackPageEntry(METRICS_EVENT_NAME.SCREEN.NETWORK.ENTRY, {
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: source,
    [METRICS_EVENT_KEY.VIEW_NAME]: view,
    [METRICS_EVENT_PROPERTY.NETWORK.UUID]: userUuid,
    [METRICS_EVENT_PROPERTY.NETWORK.CURRENT_USER]: currentUser
  });
};

export const trackActivityCenterEntry = () => {
  trackPageEntry(METRICS_EVENT_NAME.SCREEN.ACTIVITY_CENTER.ENTRY);
};

export const trackSavedCasesEntry = () => {
  trackPageEntry(METRICS_EVENT_NAME.SCREEN.SAVED_CASES.ENTRY);
};

export const trackDraftsPageEntry = () => {
  trackPageEntry(METRICS_EVENT_NAME.SCREEN.DRAFTS.ENTRY);
};

export const trackSettingsInterestsEntry = () => {
  trackPageEntry(METRICS_EVENT_NAME.SCREEN.SETTINGS.INTERESTS.ENTRY);
};

export const trackSettingsProfileEntry = () => {
  trackPageEntry(METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.ENTRY);
};

export const trackSettingsVerificationEntry = () => {
  trackPageEntry(METRICS_EVENT_NAME.SCREEN.SETTINGS.PROFILE.VERIFY_ENTRY);
};

/********
 * Preview feed card
 ********/
export const trackPreviewFeedCardEntry = ({ type, channel, position }) => {
  let name = "";
  switch (type) {
    case PREVIEW_FEED_TYPE.TRENDING_CASES:
      name = METRICS_EVENT_NAME.PREVIEW_FEED.TRENDING_CASES_FEED_VIEW_ENTRY;
      break;
    case PREVIEW_FEED_TYPE.NEW_CASES:
      name = METRICS_EVENT_NAME.PREVIEW_FEED.NEW_CASES_FEED_VIEW_ENTRY;
      break;
    default:
      break;
  }

  trackEvent(name, {
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: channel,
    [METRICS_EVENT_PROPERTY.CASE.POSITION]: position
  });
};

export const trackPreviewFeedCard = ({ type, channel, position, duration }) => {
  let name = "";
  switch (type) {
    case PREVIEW_FEED_TYPE.TRENDING_CASES:
      name = METRICS_EVENT_NAME.PREVIEW_FEED.TRENDING_CASES_FEED_VIEW;
      break;
    case PREVIEW_FEED_TYPE.NEW_CASES:
      name = METRICS_EVENT_NAME.PREVIEW_FEED.NEW_CASES_FEED_VIEW;
      break;
    default:
      break;
  }

  trackEvent(name, {
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: channel,
    [METRICS_EVENT_PROPERTY.CASE.POSITION]: position,
    [METRICS_EVENT_PROPERTY.CASE.DURATION]: duration
  });
};

export const trackPreviewFeedItemClick = ({ caseUuid, previewFeedType }) => {
  trackEvent(METRICS_EVENT_NAME.PREVIEW_FEED.PREVIEW_FEED_ITEM_CLICKED, {
    [METRICS_EVENT_PROPERTY.CASE.SOURCE]: previewFeedType,
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid
  });
};

/**************
 * Backend metrics
 **************/

export const cacheDetailViewMetrics = (caseUuid) => {
  return {
    type: CACHE_DETAIL_VIEW,
    payload: {
      caseUuid
    }
  };
};

export const cacheFeedViewMetrics = ({ caseUuid, feedTypeUuid }) => {
  return {
    type: CACHE_FEED_VIEW,
    payload: {
      caseUuid,
      feedTypeUuid
    }
  };
};

export const sendCachedMetrics = () => {
  return (dispatch, getState) => {
    const detailViews = getState().metrics.detailViewUuids;
    if (!isEmpty(detailViews)) {
      trackDetailViews(detailViews);
    }

    const feedViews = getState().metrics.feedViewUuids.reduce((acc, cur) => {
      if (!acc[cur.feedTypeUuid]) {
        acc[cur.feedTypeUuid] = [];
      }
      acc[cur.feedTypeUuid].push(cur.caseUuid);
      return acc;
    }, {});
    if (!isEmpty(feedViews)) {
      const payload = [];
      for (const [key, value] of Object.entries(feedViews)) {
        payload.push({ feedTypeUuid: key, caseUuids: value });
      }
      trackFeedViews(payload);
    }

    dispatch({
      type: RESET_CACHE
    });
  };
};

/*********
 * Invite colleagues
 */

export const trackInviteColleaguesStart = () => {
  trackEvent(METRICS_EVENT_NAME.INVITE_COLLEAGUES.START);
};

export const trackInviteColleaguesAddEmail = () => {
  trackEvent(METRICS_EVENT_NAME.INVITE_COLLEAGUES.ADD_EMAIL);
};

export const trackInviteColleaguesRemoveEmail = (hasEmail) => {
  trackEvent(METRICS_EVENT_NAME.INVITE_COLLEAGUES.REMOVE_EMAIL, {
    [METRICS_EVENT_PROPERTY.INVITE_COLLEAGUES.HAS_EMAIL]: hasEmail
  });
};

export const trackInviteColleaguesSubmission = (emails) => {
  trackEvent(METRICS_EVENT_NAME.INVITE_COLLEAGUES.SUBMISSION, {
    [METRICS_EVENT_PROPERTY.INVITE_COLLEAGUES.EMAILS]: emails
  });
};

/**************
 * App Prompt
 **************/

export const trackGetAppClicked = () => {
  trackEvent(METRICS_EVENT_NAME.GET_APP, {});
};

/**************
 * Scientific Literature
 **************/

export const trackClickedLiterature = ({
  pubMedId,
  url,
  position,
  caseUuid
}) => {
  trackEvent(METRICS_EVENT_NAME.CLICKED_LITERATURE, {
    [METRICS_EVENT_PROPERTY.SCIENTIFIC_LITERATURE.ARTICLE_ID]: pubMedId,
    [METRICS_EVENT_PROPERTY.SCIENTIFIC_LITERATURE.URL]: url,
    [METRICS_EVENT_PROPERTY.SCIENTIFIC_LITERATURE.POSITION]: position,
    [METRICS_EVENT_PROPERTY.SCIENTIFIC_LITERATURE.CASE_UUID]: caseUuid
  });
};

/**
 * Notification Page
 */

export const trackNotificationClick = ({
  notificationType,
  notificationUUID
}) => {
  trackEvent(METRICS_EVENT_NAME.ACTIVITY_CENTER_CLICK, {
    [METRICS_EVENT_PROPERTY.NOTIFICATION]: notificationType,
    [METRICS_EVENT_PROPERTY.NOTIFICATION_UUID]: notificationUUID
  });
};

export const trackMarkAllAsReadClick = () => {
  trackEvent(METRICS_EVENT_NAME.ACTIVITY_CENTER_MARK_ALL_READ_CLICK);
};

/***
 * Generic Clicks
 */

export const trackLogoClick = ({ isGated }) => {
  trackEvent(METRICS_EVENT_NAME.LOGO, {
    [METRICS_EVENT_PROPERTY.UNGATED]: isGated
  });
};

export const trackTranslationClicked = ({
  source,
  currentLanguage,
  viewPreClick,
  caseUuid
}) => {
  trackEvent(METRICS_EVENT_NAME.CLICKED_TRANSLATION, {
    [METRICS_EVENT_PROPERTY.TRANSLATION.SOURCE]: source,
    [METRICS_EVENT_PROPERTY.TRANSLATION.USER_LANGUAGE]: currentLanguage,
    [METRICS_EVENT_PROPERTY.TRANSLATION.VIEW_PRE_CLICK]: viewPreClick,
    [METRICS_EVENT_PROPERTY.TRANSLATION.CASE_UUID]: caseUuid
  });
};

export const trackFrameSwipeClicked = ({ caseUuid, orientation, action }) => {
  trackEvent(METRICS_EVENT_NAME.CLICKED_FRAME_SWIPE, {
    [METRICS_EVENT_PROPERTY.FRAME_SWIPE.CASE_UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.FRAME_SWIPE.ORIENTATION]: orientation,
    [METRICS_EVENT_PROPERTY.FRAME_SWIPE.ACTION]: action
  });
};

export const trackDiagnosisClick = ({ caseUuid }) => {
  trackEvent(METRICS_EVENT_NAME.CLICKED_DIAGNOSIS, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid
  });
};

/**
 * GROUPS EVENTS
 */

export const trackGroupTabEntry = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_TAB_ENTRY);
};

export const trackGroupEvent = ({
  eventName,
  groupUuid,
  groupName,
  groupDescription,
  publicGroup
}) => {
  trackEvent(eventName, {
    [METRICS_EVENT_PROPERTY.GROUP.GROUP_UUID]: groupUuid,
    [METRICS_EVENT_PROPERTY.GROUP.GROUP_NAME]: groupName,
    [METRICS_EVENT_PROPERTY.GROUP.GROUP_DESCRIPTION]:
      groupDescription?.substring(0, 20),
    [METRICS_EVENT_PROPERTY.GROUP.PUBLIC_GROUP]: publicGroup
  });
};

export const trackGroupInviteFigure1Members = ({ groupUuid, userUuids }) => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_INVITE_F1_MEMBERS_SEND, {
    [METRICS_EVENT_PROPERTY.GROUP_UUID]: groupUuid,
    [METRICS_EVENT_PROPERTY.USER_UUID]: userUuids
  });
};

/**
 * ACCOUNT DELETION EVENTS
 */

export const trackSettingsManageAccountEntry = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.MANAGE_ACCOUNT.ENTRY);
};

export const trackResetPasswordEntry = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.RESET_PASSWORD.ENTRY);
};

export const trackResetPasswordSendLinkClick = () => {
  trackEvent(
    METRICS_EVENT_NAME.SCREEN.SETTINGS.RESET_PASSWORD.SEND_RESET_LINK_CLICK
  );
};

export const trackSettingsSignOut = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.MANAGE_ACCOUNT.SIGN_OUT);
};

export const trackSettingsDeleteAccountEntry = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.DELETE_ACCOUNT.ENTRY);
};

export const trackSettingsDeleteAccountContinue = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.DELETE_ACCOUNT.CONTINUE);
};

export const trackSettingsDeleteAccountReasonsEntry = () => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.DELETE_ACCOUNT_REASONS.ENTRY);
};

export const trackSettingsDeleteAccount = ({
  reasonNotHcp,
  reasonVerification,
  reasonNotUsingApp,
  reasonBreak,
  reasonTooManyEmailNotifications,
  reasonPrivacyConcerns,
  reasonOther
}) => {
  trackEvent(METRICS_EVENT_NAME.SCREEN.SETTINGS.DELETE_ACCOUNT_REASONS.DELETE, {
    [METRICS_EVENT_PROPERTY.DELETE_ACCOUNT.REASONS.NOT_HCP]: reasonNotHcp,
    [METRICS_EVENT_PROPERTY.DELETE_ACCOUNT.REASONS.VERIFICATION]:
      reasonVerification,
    [METRICS_EVENT_PROPERTY.DELETE_ACCOUNT.REASONS.NOT_USING_APP]:
      reasonNotUsingApp,
    [METRICS_EVENT_PROPERTY.DELETE_ACCOUNT.REASONS.BREAK]: reasonBreak,
    [METRICS_EVENT_PROPERTY.DELETE_ACCOUNT.REASONS
      .TOO_MANY_EMAILS_NOTIFICATIONS]: reasonTooManyEmailNotifications,
    [METRICS_EVENT_PROPERTY.DELETE_ACCOUNT.REASONS.PRIVACY_CONCERNS]:
      reasonPrivacyConcerns,
    [METRICS_EVENT_PROPERTY.DELETE_ACCOUNT.REASONS.OTHER]: reasonOther
  });
};

/**
 * Case CME
 */

export const trackCaseCMECTAClick = (caseUuid, completed) => {
  trackEvent(METRICS_EVENT_PROPERTY.CASE_CME.CTA, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE_CME.COMPLETED]: completed
  });
};

export const trackCaseCMECTAQuestionEntry = (caseUuid) => {
  trackEvent(METRICS_EVENT_PROPERTY.CASE_CME.QUESTION_ENTRY, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid
  });
};

export const trackCaseCMENextQuestionClick = (
  questionUuid,
  questionIndex,
  caseUuid
) => {
  trackEvent(METRICS_EVENT_PROPERTY.CASE_CME.QUESTION_NEXT, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid,
    [METRICS_EVENT_PROPERTY.CASE_CME.QUESTION_UUID]: questionUuid,
    [METRICS_EVENT_PROPERTY.CASE_CME.QUESTION_INDEX]: questionIndex
  });
};

export const trackCaseCMESubmissionClick = (caseUuid) => {
  trackEvent(METRICS_EVENT_PROPERTY.CASE_CME.QUESTION_SUBMISSION, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid
  });
};

export const trackCaseCMEFrontMatterEntry = (caseUuid) => {
  trackEvent(METRICS_EVENT_PROPERTY.CASE_CME.FRONT_MATTER_ENTRY, {
    [METRICS_EVENT_PROPERTY.CASE.UUID]: caseUuid
  });
};

export const trackAcceptedAnswerEvent = ({ event, commentUuid, caseUuid }) => {
  trackEvent(event, {
    [METRICS_EVENT_KEY.COMMENT_UUID]: commentUuid,
    [METRICS_EVENT_KEY.CASE_UUID_2]: caseUuid
  });
};

export const trackDiscoverPublicGroupsClick = (location) => {
  trackEvent(METRICS_EVENT_NAME.PUBLIC_GROUPS_CLICK, {
    [METRICS_EVENT_PROPERTY.LOCATION]: location
  });
};

// Video
export const trackVideoPlay = ({ meta, autoplay, timestamp }) => {
  trackEvent(METRICS_EVENT_NAME.VIDEO.PLAY, {
    [METRICS_EVENT_PROPERTY.VIDEO.AUTO_PLAY]: autoplay,
    [METRICS_EVENT_PROPERTY.VIDEO.TIMESTAMP]: timestamp,
    [METRICS_EVENT_PROPERTY.CASE.UUID]: meta?.caseUuid,
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: meta?.contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: meta?.caseType,
    [METRICS_EVENT_PROPERTY.UNGATED]: meta?.ungated,
    [METRICS_EVENT_PROPERTY.CASE.CONTEXT]: meta?.context
  });
};

export const trackVideoPause = ({ meta, timestamp }) => {
  trackEvent(METRICS_EVENT_NAME.VIDEO.PAUSE, {
    [METRICS_EVENT_PROPERTY.VIDEO.TIMESTAMP]: timestamp,
    [METRICS_EVENT_PROPERTY.CASE.UUID]: meta?.caseUuid,
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: meta?.contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: meta?.caseType,
    [METRICS_EVENT_PROPERTY.UNGATED]: meta?.ungated,
    [METRICS_EVENT_PROPERTY.CASE.CONTEXT]: meta?.context
  });
};

export const trackVideoStop = ({ meta, timestamp }) => {
  trackEvent(METRICS_EVENT_NAME.VIDEO.STOP, {
    [METRICS_EVENT_PROPERTY.VIDEO.TIMESTAMP]: timestamp,
    [METRICS_EVENT_PROPERTY.CASE.UUID]: meta?.caseUuid,
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: meta?.contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: meta?.caseType,
    [METRICS_EVENT_PROPERTY.UNGATED]: meta?.ungated,
    [METRICS_EVENT_PROPERTY.CASE.CONTEXT]: meta?.context
  });
};

export const trackVideoDuration = ({ meta, duration }) => {
  trackEvent(METRICS_EVENT_NAME.VIDEO.DURATION, {
    [METRICS_EVENT_PROPERTY.VIDEO.DURATION]: duration,
    [METRICS_EVENT_PROPERTY.CASE.UUID]: meta?.caseUuid,
    [METRICS_EVENT_PROPERTY.CONTENT_UUID]: meta?.contentUuid,
    [METRICS_EVENT_PROPERTY.CASE.CASE_TYPE]: meta?.caseType,
    [METRICS_EVENT_PROPERTY.UNGATED]: meta?.ungated,
    [METRICS_EVENT_PROPERTY.CASE.CONTEXT]: meta?.context
  });
};
