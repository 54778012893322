import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import localizationActions from "../../actions/localization.actions";
import useEventListener from "../../hooks/useEventListener";

const withLocalization = (Component) => {
  function WithLocalization(props) {
    /** ********************************** CONFIG ***************************************/
    const dispatch = useDispatch();

    /** ********************************** HOOKS ****************************************/

    useEffect(() => {
      dispatch(
        localizationActions.updateLocalization({
          currentLanguage: window.navigator.language
        })
      );
    }, [dispatch]);

    useEventListener(window, "languagechange", () => {
      dispatch(
        localizationActions.updateLocalization({
          currentLanguage: window.navigator.language
        })
      );
    });

    /** ********************************* FUNCTIONS *************************************/

    /** ********************************** RENDER ***************************************/
    return <Component {...props} />;
  }

  return WithLocalization;
};

export default withLocalization;
