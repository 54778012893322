import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toggleSignUpModal } from "../../actions/global.actions";
import { setRedirectRoute } from "../../actions/login.actions";
import RegistrationFormV2 from "../../pages/authentication/components/RegistrationForm.v2";
import i18n from "../../utils/i18n";
import BaseDialog from "../dialog/BaseDialog";
import BaseDialogBody from "../dialog/BaseDialogBody";
import BaseDialogHeader from "../dialog/BaseDialogHeader";

const SignUpModal = () => {
  /************************************ CONFIG ***************************************/

  const dispatch = useDispatch();
  const loc = useLocation();
  const isOpen = useSelector((state) => state?.global?.signUpModalVisible);
  const performSignout = useSelector((state) => state?.global?.performSignout);

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  const onCancel = () => {
    dispatch(toggleSignUpModal(false));
  };

  const onSignIn = () => {
    dispatch(setRedirectRoute(loc.pathname));
    onCancel();
  };
  /************************************ RENDER ***************************************/

  return (
    <BaseDialog isOpen={isOpen} centered>
      <BaseDialogHeader center onCancel={onCancel} showCloseButton>
        <h3 className="helv-bold text-18">
          {i18n.t("RegistrationScreens.signUpForm.modalTitle")}
        </h3>
        <hr className="border-lighter-gray border-width-3" />
      </BaseDialogHeader>

      <BaseDialogBody>
        <RegistrationFormV2
          inModal={true}
          performSignout={performSignout}
          onSignIn={onSignIn}
        />
      </BaseDialogBody>
    </BaseDialog>
  );
};

export default SignUpModal;
