import React from "react";
import BaseDialogBody from "../../components/dialog/BaseDialogBody";
import i18n from "../../utils/i18n";
import AcceptedAnswerPromoImg from "../../assets/images/accepted_answer_promo.png";

/** retired, but I'm leaving it for the time being -- Corey */
const AcceptedAnswerDialogContents = () => {
  /** ********************************** CONFIG ***************************************/
  const bullets = [
    "WhatsNew.AcceptedAnswer.point1",
    "WhatsNew.AcceptedAnswer.point2",
    "WhatsNew.AcceptedAnswer.point3"
  ];
  /** ********************************** HOOKS ***************************************/

  /** ********************************** FUNCTIONS ***************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <div className="bg-lighter-gray text-center mb-3">
        <img
          src={AcceptedAnswerPromoImg}
          alt=""
          style={{ maxWidth: "240px" }}
        />
      </div>
      <BaseDialogBody>
        <div className="text-center px-3">
          <h1 className="text-22 helv-med mb-2">
            {i18n.t("WhatsNew.AcceptedAnswer.subTitle")}
          </h1>
          <p className="text-16 text-battleship-gray mb-0">
            {i18n.t("WhatsNew.AcceptedAnswer.description")}
          </p>
        </div>
        <div className="mt-3 ml-5 text-battleship-gray">
          <ul className="check-list">
            {bullets.map((str, i) => {
              return (
                <li key={i} className="text-16 m-0 mb-3">
                  {i18n.t(str)}
                </li>
              );
            })}
          </ul>
        </div>
      </BaseDialogBody>
    </>
  );
};

export default AcceptedAnswerDialogContents;
