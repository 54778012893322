/** @format */

import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import _ from "lodash";
import {
  syncCMEHub,
  toggleUserCmeAvailableListener,
  toggleUserCmeCompletedListener,
  toggleUserCmeMetaListener
} from "../../actions/user-cme.actions";
import moment from "moment";
import i18n from "../../utils/i18n";
import Figure1Layout from "../../@layouts/Figure1Layout";
import Figure1Page2Col from "../../@layouts/Figure1Page2Col";
import CMEAvailableActivitiesGallery from "./components/CMEAvailableActivitiesGallery";
import CMECompletedActivitiesGallery from "./components/CMECompletedActivitiesGallery";
import { ACTIVITY_DATE, COMPLETED_ACTIVITY } from "./constants";
import activitiesJson from "./mocked/activities.json";
import completedJson from "./mocked/completed.json";
import useDialog from "../../components/dialog/useDialog";
import { getUserCountry } from "../../utils/cme-utils";
import { StaticStrings } from "../../constants/static-string-constants";
import { useHistory, useLocation } from "react-router-dom";
import { trackCMELanding } from "../../actions/metrics.actions";
import AppPrompt from "../../components/app-prompt/AppPrompt";

const USE_MOCKED_DATA = process.env.USE_MOCKED_DATA || false;

const CMEPage = () => {
  const dispatch = useDispatch();
  const { confirm } = useDialog();
  const location = useLocation();
  const completedCMERef = useRef();
  const history = useHistory();
  const urlParams = new URLSearchParams(history.location.search);

  const [hasSentMetrics, setHasSentMetrics] = useState(false);
  const isUS = useSelector((state) => getUserCountry(state.user) === "US");
  const cmeStore = useSelector((state) => state.userCme);
  const { availableActivities = [], completedActivities = [] } = USE_MOCKED_DATA
    ? {
        availableActivities: activitiesJson,
        completedActivities: completedJson
      }
    : cmeStore;
  const syncHub = useCallback(() => {
    dispatch(syncCMEHub());
  }, [dispatch]);

  useEffect(() => {
    const similar = urlParams.get("completed");

    if (similar) {
      if (isMobile) {
        completedCMERef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end"
        });
      }
      window.history.replaceState(
        {},
        document.title,
        history.location.pathname
      );
    }
  }, [urlParams, completedCMERef?.current, history]);

  useEffect(() => {
    syncHub();
  }, [syncHub]);

  useEffect(() => {
    try {
      dispatch(toggleUserCmeMetaListener(true));
      dispatch(toggleUserCmeAvailableListener(true));
      dispatch(toggleUserCmeCompletedListener(true));
      return () => {
        dispatch(toggleUserCmeMetaListener(false));
        dispatch(toggleUserCmeAvailableListener(false));
        dispatch(toggleUserCmeCompletedListener(false));
      };
    } catch (e) {
      console.log(e.message);
    }
  }, [dispatch]);

  // METRICS EFFECTS
  useEffect(() => {
    if (location?.state?.context != null && !hasSentMetrics) {
      trackCMELanding(location.state.context);
      const state = { ...location.state };
      delete state.context;
      window.history.replaceState(state, document.title);
      setHasSentMetrics(true);
    }
  }, [hasSentMetrics, location]);

  const onClickSupport = async () => {
    const confirmed = await confirm({
      message: i18n.t("CMEScreens.promptTitle"),
      messageDetail: i18n.t("CMEScreens.promptDownload"),
      confirmText: i18n.t("CMEScreens.promptDownloadAction")
    });

    if (!confirmed) {
      return;
    }

    document.location = `mailto:${StaticStrings.supportEmail}`;
  };

  const availableEmptyText = i18n.t("CMEScreens.availableActivityEmptyState");
  const completedEmptyText = i18n.t("CMEScreens.completedActivityEmptyState");
  const title = isUS
    ? i18n.t("CMEScreens.titleUS")
    : i18n.t("CMEScreens.titleNonUS");
  const subtitle = isUS
    ? i18n.t("CMEScreens.subtitleUS")
    : i18n.t("CMEScreens.subtitleNonUS");

  const completedData = () => {
    if (_.isEmpty(completedActivities)) {
      return [];
    }

    let result = [];

    const activities = completedActivities.sort(
      (a, b) => moment(b.completedAt) - moment(a.completedAt)
    );

    // build data with month segregation
    let currentMonth = null;
    for (let i = 0; i < activities.length; i++) {
      const activity = activities[i];
      const month = moment(activity.completedAt)?.month();
      if (currentMonth !== month) {
        currentMonth = month;
        result.push({
          type: ACTIVITY_DATE,
          date: moment(activity.completedAt)
        });
      }
      result.push({ type: COMPLETED_ACTIVITY, ...activity });
    }
    return result;
  };

  const filteredCompletedActivities = completedData();

  const leftPane = (
    <CMEAvailableActivitiesGallery
      activities={availableActivities}
      title={title}
      subtitle={subtitle}
      emptyText={availableEmptyText}
    />
  );
  const rightPane = (
    <CMECompletedActivitiesGallery
      ref={completedCMERef}
      activities={filteredCompletedActivities}
      emptyText={completedEmptyText}
    />
  );

  return (
    <Figure1Layout footer={<AppPrompt />}>
      <Figure1Page2Col
        isChildPage
        isContactSupport
        contactSupportClick={onClickSupport}
        mainContent={leftPane}
        rightSidebarContent={rightPane}
      />
    </Figure1Layout>
  );
};

export default CMEPage;
