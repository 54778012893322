import React, { useEffect, useState } from "react";
import TextareaAutosize from "react-textarea-autosize";
import { FormGroup, FormText, Label } from "reactstrap";
import { Controller } from "react-hook-form";

import i18n from "../../../utils/i18n";
import FormFeedback from "reactstrap/lib/FormFeedback";
import { MAX_SUMMARY_CHAR } from "../../../constants/case-constants";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import PlainTextEditor from "./PlainTextEditor";

const TitleSummaryInputs = ({
  draft,
  control,
  setValue,
  fieldWatch,
  errors
}) => {
  const MAX_TITLE_CHAR = 100;

  const titleContents = fieldWatch?.title ?? null;
  const captionContents = fieldWatch?.caption ?? null;

  const [titleError, setTitleError] = useState(null);
  const [captionError, setCaptionError] = useState(null);

  const onCaptionChange = (v) => {
    setValue("caption", v);
  };

  useEffect(() => {
    setValue("title", draft?.title);
    setValue("caption", draft?.caption);
  }, [draft?.caption, draft?.title, setValue]);

  useEffect(() => {
    if (errors.title) {
      setTitleError("shareCase.titleLength");
    }
  }, [errors.title]);

  useEffect(() => {
    if (errors.caption) {
      switch (errors.caption.type) {
        case "maxLength":
          setCaptionError("shareCase.caseSummaryLength");
          break;
        case "required":
        default:
          setCaptionError("shareCase.caseSummaryRequired");
          break;
      }
    }
  }, [errors.caption]);

  return (
    <>
      <FormGroup className="mt-3">
        <Label
          for="title"
          className="d-flex text-nowrap justify-content-between">
          <span className="text-15 font-weight-500 text-default-black">
            {i18n.t("shareCase.caseTitleLabel")}
          </span>
          <span className="text-muted">
            {titleContents?.length || 0} / {MAX_TITLE_CHAR}
          </span>
        </Label>
        <Controller
          control={control}
          name="title"
          rules={{
            maxLength: MAX_TITLE_CHAR
          }}
          invalid={!!errors.title ? 1 : 0}
          as={
            <TextareaAutosize
              className={
                errors.title
                  ? "autoresize-textarea is-invalid"
                  : "autoresize-textarea"
              }
              minRows={1}
              maxRows={2}
              placeholder={i18n.t("shareCase.caseTitlePlaceholder")}
            />
          }
          defaultValue={draft?.title || ""}
        />

        <FormFeedback>{i18n.t(titleError)}</FormFeedback>
      </FormGroup>
      <FormGroup className="mt-2">
        <Controller
          control={control}
          name="caption"
          rules={{
            required: true,
            maxLength: MAX_SUMMARY_CHAR
          }}
          invalid={!!errors?.caption ? 1 : 0}
          as={
            <PlainTextEditor
              defaultValue={draft?.caption || ""}
              label={i18n.t("shareCase.caseDescriptionLabel")}
              maxLength={MAX_SUMMARY_CHAR}
              placeholder={i18n.t("shareCase.caseDescriptionPlaceholder")}
              callback={onCaptionChange}
              className={errors?.caption ? "is-invalid" : ""}
            />
          }
          defaultValue={draft?.caption || ""}
        />

        <FormFeedback>{i18n.t(captionError)}</FormFeedback>

        <FormText className="text-13 line-height-18">
          <CustomIcon
            className="mr-2 icon-battleship-gray"
            icon={"sheet_copy_link"}
            size={14}
          />
          {i18n.t("shareCase.caseDescriptionFootnote")}
        </FormText>
      </FormGroup>
    </>
  );
};

export default TitleSummaryInputs;
