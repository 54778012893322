import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _, { isBoolean } from "lodash";
import Figure1Page3Col from "../../@layouts/Figure1Page3Col";
import ISILayout from "../../components/isi/ISILayout";
import { Alert, Card, CardBody, Col, Row } from "reactstrap";
import MiniCaseCard from "./components/MiniCaseCard";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import CMEFormGenerator from "./components/CMEFormGenerator";
import questionActions from "../../actions/question.actions";
import { Prompt, useHistory } from "react-router-dom";
import { useBeforeunload } from "react-beforeunload";
import i18n from "../../utils/i18n";
import { CASE_DETAIL } from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import DegreeTypeModal from "./components/DegreeTypeModal";
import CaseCMESuccessModal from "./components/CaseCMESuccessModal";
import {
  trackCaseCMECTAQuestionEntry,
  trackCaseCMESubmissionClick
} from "../../actions/metrics.actions";

const CaseCMEPage = ({ caseId }) => {
  /** ********************************** CONFIG ***************************************/
  const caseCMEQuestions = useSelector(
    (state) => state.question?.caseCMEQuestions
  );
  const caseDetails = useSelector((state) => state.case.cases[caseId]?.data);
  const userDegree = useSelector((state) => state.user?.degreeType || null);
  const dispatch = useDispatch();
  const [dirtyForm, setDirtyForm] = useState(false);
  const history = useHistory();
  const [showDegreeModal, setShowDegreeModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const submittedFormData = useRef(null);
  const submitDegree = useRef(userDegree);
  const submitError = useSelector((state) =>
    state.question?.error ? state.question?.message : ""
  );

  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    trackCaseCMECTAQuestionEntry(caseId);
  }, []);

  useEffect(() => {
    if (userDegree) {
      submitDegree.current = userDegree;
    }
  }, [userDegree]);

  useEffect(() => {
    dispatch(questionActions.fetchCaseCMEQuestionsIfNeeded());
  }, []);

  useBeforeunload((event) => {
    if (dirtyForm) {
      event.preventDefault();
      return i18n.t("CaseCMEQuestions.unsavedPrompt");
    }
  });

  /** ********************************* FUNCTIONS *************************************/

  const onFieldDirtied = () => {
    // We're not really concerned with what fields are dirty or how many.
    // as soon as one's triggered, we're dirty forever.
    setDirtyForm(true);
  };

  const submitAnswers = async () => {
    const answers = [];

    caseCMEQuestions.forEach((question) => {
      question.answerGroups.forEach((ag) => {
        ag.answers.forEach((answer) => {
          const entry = {
            questionUuid: question.questionUuid
          };

          if (submittedFormData.current[answer.answerUuid]) {
            entry.answerUuid = answer.answerUuid;
            if (!isBoolean(submittedFormData.current[answer.answerUuid])) {
              entry.answerText = submittedFormData.current[answer.answerUuid];
            }
          }
          // override answer text when answer
          if (submittedFormData.current[`${answer.answerUuid}-text`]) {
            entry.answerText =
              submittedFormData.current[`${answer.answerUuid}-text`];
          }

          if (entry.answerUuid) {
            answers.push(entry);
          }
        });
      });
    });

    try {
      await dispatch(
        questionActions.submitCaseCmeAnswers({
          caseUuid: caseId,
          answers,
          degreeType: submitDegree.current
        })
      );

      setShowSuccessModal(true);
    } catch (e) {
      console.log(e);
    }
  };

  const onSubmit = (formData) => {
    trackCaseCMESubmissionClick(caseId);
    // store formData in case we need to show the modal first
    submittedFormData.current = formData;
    // turn off flag, so redirect doesn't set off a prompt
    setDirtyForm(false);

    if (submitDegree.current) {
      submitAnswers();
    } else {
      setShowDegreeModal(true);
    }
  };

  const onDegreeModalSave = (result) => {
    submitDegree.current = result?.degreeType;
    onDegreeModalCancel();
    submitAnswers();
  };

  const onDegreeModalCancel = () => {
    setShowDegreeModal(false);
  };

  const onSuccessModalClose = () => {
    history.push(getUrl(CASE_DETAIL, { caseId: caseId }));
  };
  /** ********************************** RENDER ***************************************/

  const layoutProps = {
    isChildPage: true
  };

  return (
    <ISILayout enableGatedBanner>
      <Figure1Page3Col
        {...layoutProps}
        mainContent={
          <>
            <Prompt
              when={dirtyForm}
              message={i18n.t("CaseCMEQuestions.unsavedPrompt")}
            />

            <Card className="mb-1 border-0">
              <CardBody className="">
                <Row>
                  <Col size={2}></Col>
                  <Col size={8} className="text-center">
                    <h1 className="text-18 flex-1 helv-bold flex-grow-1 text-nowrap mb-0">
                      {i18n.t("CaseCME.caseActivityLabel")}
                    </h1>
                  </Col>
                  <Col size={2} className="text-right text-battleship-gray">
                    <div>
                      <CustomIcon
                        icon="webnav_education_selected"
                        className="icon-battleship-gray"
                        size={20}
                      />
                      {i18n.t("CaseCME.creditsAmount")}
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
            {submitError && (
              <Alert className="mt-3" color="danger">
                {submitError}
              </Alert>
            )}
            <CMEFormGenerator
              questions={caseCMEQuestions}
              onFieldDirtied={onFieldDirtied}
              onSubmit={onSubmit}
              caseUuid={caseId}
            />
            <DegreeTypeModal
              isOpen={showDegreeModal}
              onSave={onDegreeModalSave}
              onCancel={onDegreeModalCancel}
            />
            <CaseCMESuccessModal
              onClose={onSuccessModalClose}
              caseId={caseId}
              isOpen={showSuccessModal}
            />
          </>
        }
        leftSidebarContent={<MiniCaseCard caseDetails={caseDetails} />}
      />
    </ISILayout>
  );
};

export default CaseCMEPage;
