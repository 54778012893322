import React, { useRef } from "react";
import AppPromptSmall from "./AppPromptSmall";
import AppPromptFull from "./AppPromptFull";
import useAppPrompt from "./useAppPrompt";
import useResizeObserver from "../../hooks/use-resize-observer.hooks";
import "./AppPrompt.scss";

const AppPrompt = () => {
  /****************************** Config **************************************/
  const appPromptRef = useRef(null);
  const { isShown, isExpanded, interactAppPrompt, setAppPromptHeight } =
    useAppPrompt();

  /****************************** Hooks ***************************************/

  useResizeObserver({
    resizeObserverCallback: (entries) => {
      if (!Array.isArray(entries) || !entries.length) {
        return;
      }

      setAppPromptHeight(entries[0].target.getBoundingClientRect().height);
    },
    elementRef: appPromptRef
  });

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/

  return (
    isShown && (
      <>
        {!isExpanded ? (
          <AppPromptSmall
            ref={appPromptRef}
            cardClassName="app-prompt-declined"
            confirmButtonColor="denim-blue"
            titleColor="default-black"
            onToggle={interactAppPrompt}
          />
        ) : (
          <AppPromptFull ref={appPromptRef} onToggle={interactAppPrompt} />
        )}
      </>
    )
  );
};

export default AppPrompt;
