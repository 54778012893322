import React from "react";
import { FormGroup, Label } from "reactstrap";
import CaseImageEditor from "../../../components/upload-case/CaseImageEditor";

const ImagesUploader = ({
  className,
  label,
  placeholder,
  fileRef,
  media,
  onFileEdit,
  onRemoveFile
}) => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ***************************************/
  /** ********************************** FUNCTIONS ***************************************/
  /** ********************************** RENDER ***************************************/
  return (
    <FormGroup className={className}>
      <Label
        for="images"
        className="helv-med text-15 line-height-21 text-default-black mb-3">
        {label}
      </Label>
      <CaseImageEditor
        fileRef={fileRef}
        media={media}
        onFileEdit={onFileEdit}
        onRemoveFile={onRemoveFile}
      />
    </FormGroup>
  );
};

export default ImagesUploader;
