import React from "react";
import F1AvatarLink from "../../common/F1AvatarLink";
import CommentHeader from "../CommentHeader";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../custom-icon/CustomIcon";

const ReportedComment = ({ comment = {} }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const avatar = <F1AvatarLink user={comment.author ?? comment} size="sm" />;
  return (
    <>
      <div className="d-flex">
        {avatar}
        <div className="w-100 ml-2 d-flex align-items-center text-15">
          <CommentHeader
            comment={comment}
            reply
            showEdited={false}
            showAcceptedAnswerBadge={false}
          />
        </div>
      </div>
      <div className="d-flex pt-3 align-items-center">
        <CustomIcon icon="reported_accepted_answer" color="red" size="25" />
        <p className="ml-1 text-battleship-gray m-0 text-15">
          {i18n.t("comments.reportedAnswer")}
        </p>
      </div>
    </>
  );
};

export default ReportedComment;
