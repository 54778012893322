import React, { useState } from "react";
import { Card } from "reactstrap";

import i18n from "../../../../utils/i18n";
import { useForm } from "react-hook-form";
import useIsAStudent from "../../../../hooks/useIsAStudent";
import CustomIcon from "../../../../components/custom-icon/CustomIcon";
import { useDispatch, useSelector } from "react-redux";
import {
  clearLocalVerificationPictureInfo,
  submitPhotoVerification
} from "../../../../actions/user-verification.actions";
import VerificationPhotoUploadForm from "../../../authentication/components/VerificationPhotoUploadForm";
import { trackProfilePhotoVerificationClick } from "../../../../actions/metrics.actions";

const PhotoUploadForm = ({ isSaving, setIsSaving, onSave }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const isStudent = useIsAStudent();
  const { handleSubmit } = useForm({
    mode: "onChange"
  });

  const userVerificationStore = useSelector((state) => state.userVerification);

  const [errorMessage, setErrorMessage] = useState("");
  const checkListItems = isStudent ? (
    <>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.student.checklist.item1"
        )}
      </li>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.student.checklist.item2"
        )}
      </li>
    </>
  ) : (
    <>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.other.checklist.item1"
        )}
      </li>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.other.checklist.item2"
        )}
      </li>
      <li className="text-16 m-0 mb-4">
        {i18n.t(
          "RegistrationScreens.verificationPhotoPage.v2.other.checklist.item3"
        )}
      </li>
    </>
  );

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const onPhotoSubmit = async () => {
    setErrorMessage("");
    setIsSaving(true);
    trackProfilePhotoVerificationClick();

    try {
      if (!userVerificationStore?.verificationPictureInfos?.length) {
        throw new Error("At least one photo must be uploaded for verification");
      }
      const saveProfileResult = await onSave();

      if (saveProfileResult) {
        const result = await dispatch(
          submitPhotoVerification(
            userVerificationStore?.verificationPictureInfos
          )
        );

        if (result.error) {
          throw new Error(result.message);
        } else {
          dispatch(clearLocalVerificationPictureInfo());
        }
      }
    } catch (e) {
      setErrorMessage(e.message);
      setIsSaving(false);
    }
  };

  const onSelectFile = () => {
    setErrorMessage("");
  };

  /** ********************************** RENDER ***************************************/

  return (
    <Card className="p-3 p-lg-4 rounded-0 shadow">
      <h2 className="d-flex text-18 helv-bold mb-3">
        {i18n.t("Settings.Profile.AccountDetails.photoUploadForm.heading")}
      </h2>
      <ul className="check-list">{checkListItems}</ul>
      <div className="alert alert-aqua rounded text-battleship-gray p-2 d-flex align-items-center border-0">
        <CustomIcon
          icon={"verification_checkshield_web"}
          size={28}
          className="mr-2"
        />
        {isStudent &&
          i18n.t(
            "RegistrationScreens.verificationPhotoPage.v2.student.badgeText"
          )}
        {!isStudent &&
          i18n.t("RegistrationScreens.verificationPhotoPage.v2.badgeText")}
      </div>
      <VerificationPhotoUploadForm
        onSelectFile={onSelectFile}
        errorMessage={errorMessage}
        isLoading={isSaving}
        onSubmit={handleSubmit(onPhotoSubmit)}
        showNpiLink={false}
      />
    </Card>
  );
};

export default PhotoUploadForm;
