import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Card } from "reactstrap";
import BackButton from "../../components/common/BackButton";
import { FEED_NAMES } from "../../constants/feed-type";
import useIsRecommendedFeed from "../../hooks/useIsRecommendedFeed";
import i18n from "../../utils/i18n";

const FeedBanner = () => {
  const isRecommended = useIsRecommendedFeed();

  const routeParams = useParams();
  const [possibleFeeds, setPossibleFeeds] = useState([]);
  const [feedTitle, setFeedTitle] = useState("");
  const followedFeeds = useSelector(
    (state) => state.userFeedMeta?.followedFeeds
  );
  const topics = useSelector((state) => state.topics?.data);

  useEffect(() => {
    setPossibleFeeds([
      ...followedFeeds.map((f) => {
        return { name: f.feedName, uuid: f.feedTypeUuid };
      }),
      ...topics
    ]);
  }, [followedFeeds, topics]);

  useEffect(() => {
    if (routeParams.feed === FEED_NAMES.TOPIC) {
      const feed = possibleFeeds.find(
        (f) => f.uuid === routeParams.feedTypeUuid
      );

      setFeedTitle(feed?.name);
    } else if (routeParams.feed === FEED_NAMES.EVERYTHING) {
      setFeedTitle(i18n.t("HomeFeed.newCasesTitle"));
    } else if (routeParams.feed === FEED_NAMES.TRENDING) {
      setFeedTitle(i18n.t("HomeFeed.trendingCasesTitle"));
    } else if (routeParams.feed === FEED_NAMES.QUIZZES) {
      setFeedTitle(i18n.t("HomeFeed.quizzesTitle"));
    }
  }, [possibleFeeds, routeParams.feed, routeParams.feedTypeUuid]);

  if (isRecommended) return null;

  return (
    <Card className="border-outline-gray py-3 mb-1 mt-1">
      <div className="ml-n3 mb-1">
        <BackButton />
      </div>
      <h2 className="bg-white py-3 m-0 mb-1 helv-bold text-16 text-center">
        {feedTitle}
      </h2>
    </Card>
  );
};

export default FeedBanner;
