import React from "react";
import { Card, CardBody, CardTitle } from "reactstrap";
import { capitalize } from "lodash";
import i18n from "../../utils/i18n";
import { useCaseTranslation } from "../../hooks";
import classNames from "../../utils/class-names-utils";
import TextExpander from "../common/TextExpander";
import CaseDetailDiagnosis from "./CaseDetailDiagnosis";
import "./CaseDetailUpdatesCard.scss";

// TODO: Move these functions to brain so they can be re-used in mobile.
const getGroupedUpdates = (updates) => {
  /** @type {{ [key: string]: { diagnosis?: {}, update?: {} } }} */
  const groupedUpdatesDict = updates.reduce((acc, update, index) => {
    const { linkedUpdateUuid = index } = update;

    if (!acc[linkedUpdateUuid]) {
      acc[linkedUpdateUuid] = {};
    }

    acc[linkedUpdateUuid][update.updateType] = update;

    return acc;
  }, {});

  const groupedUpdates = Object.values(groupedUpdatesDict).sort((a, b) => {
    const datesA = Object.values(a).map(
      (x) => x.createdAt ?? new Date(9999, 1, 1)
    );

    const datesB = Object.values(b).map(
      (x) => x.createdAt ?? new Date(9999, 1, 1)
    );

    return Math.min(datesA) - Math.min(datesB);
  });

  return groupedUpdates;
};

const getUpdatesWithInitialDiagnosis = (updates) => {
  const groupedUpdates = getGroupedUpdates(updates);

  const isCaseCreatedWithDiagnosis =
    groupedUpdates.length > 0 &&
    !groupedUpdates[0].update &&
    groupedUpdates[0].diagnosis;

  let initialDiagnosis = isCaseCreatedWithDiagnosis
    ? groupedUpdates.shift().diagnosis
    : undefined;

  return {
    groupedUpdates,
    initialDiagnosis
  };
};

const CaseDetailUpdatesCard = ({ caseContent }) => {
  /** ********************************** CONFIG ***************************************/
  const { updateTranslations } = useCaseTranslation(caseContent);

  /** ********************************** HOOKS ****************************************/

  const { groupedUpdates, initialDiagnosis } =
    getUpdatesWithInitialDiagnosis(updateTranslations);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  if (
    caseContent.isSponsored ||
    (!initialDiagnosis && groupedUpdates.length === 0)
  ) {
    return null;
  }

  return (
    <>
      {initialDiagnosis && (
        <Card className="border-0">
          <CaseDetailDiagnosis
            className={classNames(
              "px-0 border-top border-light-gray pt-3",
              groupedUpdates.length === 0 ? "pb-0" : ""
            )}
            diagnosis={initialDiagnosis.text}
            label={i18n.t("AddUpdateScreen.diagnosisAddedByAuthor")}
          />
        </Card>
      )}
      {groupedUpdates.length > 0 && (
        <p className="text-16 font-weight-bold border-top border-light-gray pt-3">
          {groupedUpdates.length === 1
            ? i18n.t("AddUpdateScreen.updateTitleSingle")
            : i18n.t("AddUpdateScreen.updateTitleMultiple", {
                count: groupedUpdates.length
              })}
        </p>
      )}
      {groupedUpdates.map(({ update, diagnosis }, index) => (
        <CaseUpdate
          key={index}
          className={index < groupedUpdates.length - 1 ? "mb-3" : ""}
          index={index + 1}
          title={`${capitalize(i18n.t("common.update"))} ${index + 1}`}
          text={update?.text}
          diagnosisText={diagnosis?.text}
          caseUuid={caseContent.caseUuid}
        />
      ))}
    </>
  );
};

const CaseUpdate = ({ className, title, text, diagnosisText, caseUuid }) => {
  return (
    <Card
      className={classNames("border-lighter-gray border-width-2", className)}>
      <CardBody
        className={classNames(
          "case-update-card-body",
          diagnosisText ? "pb-0" : ""
        )}>
        <CardTitle className="font-weight-bold text-14 mb-1">{title}</CardTitle>
        <TextExpander className="feed-card-info text-15" numberOfLines={2}>
          {text}
        </TextExpander>
      </CardBody>
      {diagnosisText && (
        <CaseDetailDiagnosis
          className="case-update-card-body"
          diagnosis={diagnosisText}
          label={i18n.t("AddUpdateScreen.diagnosisAdded")}
          caseUuid={caseUuid}
        />
      )}
    </Card>
  );
};

export default CaseDetailUpdatesCard;
