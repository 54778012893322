import React from "react";
import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { Nav, NavItem } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import {
  GROUPS_HOME,
  CME_ROOT,
  NOTIFICATIONS_ROOT,
  CASE_POSTING_NEW,
  HOME_ROOT_FEED
} from "../../constants/routes";
import { trackShareCase } from "../../actions/metrics.actions";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import F1Badge from "../common/F1Badge";
import { isMobile } from "react-device-detect";
import MoreDropdownV2 from "./MoreDropdown.v2";
import { getUrl } from "../../utils/route-utils";
import { FEED_NAMES } from "../../constants/feed-type";
import CreateDropdown from "./CreateDropdown";
import checkFlag, { FLAG_POSTS } from "../../FeatureFlags";

const CentreNavComponent = ({
  isGroupFeed,
  currentFeedUuid,
  cmeLinkText,
  numNotifications
}) => {
  /** ********************************** CONFIG ***************************************/
  const { t } = useTranslation();
  const postsFlag = checkFlag(FLAG_POSTS);
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Nav
      className={`align-items-center center-nav ${
        isMobile ? "justify-content-between" : ""
      }`}
      navbar>
      <NavItem>
        <NavLink
          to={{
            pathname: getUrl(HOME_ROOT_FEED, { feed: FEED_NAMES.RECOMMENDED }),
            state: {
              context: "Navigation"
            }
          }}
          activeClassName="active"
          className="nav-link">
          <CustomIcon
            className="inactive-icon"
            icon={"webnav_home_unselected"}
            size={18}
          />
          <CustomIcon
            className="active-icon"
            icon={"webnav_home_selected"}
            size={18}
          />
          <div className={`${isMobile && "text-11"}`}>
            {t("navigation.home")}
          </div>
        </NavLink>
      </NavItem>

      <NavItem>
        <NavLink
          to={{
            pathname: GROUPS_HOME,
            state: {
              context: "Navigation"
            }
          }}
          activeClassName="active"
          className="nav-link">
          <CustomIcon
            className="inactive-icon"
            icon={"webnav_group_unselected"}
            size={26}
            style={{ marginBottom: "2px" }}
          />
          <CustomIcon
            className="active-icon"
            icon={"webnav_group_selected"}
            size={26}
            style={{ marginBottom: "2px" }}
          />

          <div className={`${isMobile && "text-11"}`}>
            {t("navigation.groups")}
          </div>
        </NavLink>
      </NavItem>

      <NavItem>
        {postsFlag ? (
          <CreateDropdown
            isGroupFeed={isGroupFeed}
            currentFeedUuid={currentFeedUuid}
          />
        ) : (
          <NavLink
            to={CASE_POSTING_NEW}
            onClick={() => {
              trackShareCase({
                context: UPLOAD_CASE_ORIGIN.PLUS_SIGN,
                groupUuid: isGroupFeed ? currentFeedUuid : null
              });
            }}
            activeClassName="active"
            className="nav-link">
            <CustomIcon
              className="inactive-icon"
              icon={"webnav_newcase_unselected"}
              size={20}
            />
            <CustomIcon
              className="active-icon"
              icon={"webnav_newcase_selected"}
              size={20}
            />
            <div className={`${isMobile && "text-11"}`}>
              {t("navigation.newCase")}
            </div>
          </NavLink>
        )}
      </NavItem>

      {!isMobile && (
        <NavItem>
          <NavLink
            to={{
              pathname: CME_ROOT,
              state: {
                context: "Navigation"
              }
            }}
            activeClassName="active"
            className="nav-link">
            <CustomIcon
              className="inactive-icon offset"
              icon={"webnav_education_unselected"}
              size={20}
            />
            <CustomIcon
              className="active-icon offset"
              icon={"webnav_education_selected"}
              size={20}
            />
            <div className={`${isMobile && "text-11"}`}>{cmeLinkText}</div>
          </NavLink>
        </NavItem>
      )}

      <NavItem>
        <NavLink
          to={NOTIFICATIONS_ROOT}
          activeClassName="active"
          className="nav-link">
          <div className="notification">
            <CustomIcon
              className="inactive-icon offset"
              icon={"webnav_notif_unselected"}
              size={20}
            />
            {numNotifications > 0 && (
              <F1Badge
                pill
                textColor="white"
                color="red"
                name={numNotifications}
                className={"button_badge"}
                px={numNotifications > 9 ? 1 : 2}
              />
            )}
            <CustomIcon
              className="active-icon offset"
              icon={"webnav_notif_selected"}
              size={20}
            />
            {numNotifications > 0 && (
              <F1Badge
                pill
                textColor="white"
                color="red"
                name={numNotifications}
                className={"button_badge"}
                px={numNotifications > 9 ? 1 : 2}
              />
            )}
          </div>
          <div className={`${isMobile && "text-11"}`}>
            {t("navigation.notificationsTitle")}
          </div>
        </NavLink>
      </NavItem>

      {isMobile && (
        <NavItem>
          <div className="nav-link">
            <MoreDropdownV2 />
          </div>
        </NavItem>
      )}
    </Nav>
  );
};

export default CentreNavComponent;
