import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Badge, Card } from "reactstrap";
import {
  getPreviewFeedIfNeeded,
  getPreviewFeedMetaIfNeeded
} from "../../actions/reference.actions";
import { FEED_NAMES } from "../../constants/feed-type";
import i18n from "../../utils/i18n";
import "./ExploreCategoryCTA.scss";
import TopicViewAllLink from "./TopicViewAllLink";

const MAX_IMAGES = 4;

const ExploreCategoryCTA = ({
  title,
  titleImg,
  description,
  feedLabel = FEED_NAMES.EVERYTHING,
  showNewBadge
}) => {
  /****************************** Config **************************************/
  const metaDataId = useSelector(
    (state) =>
      Object.values(state?.references?.feedsMeta?.data || {})?.find(
        (m) => m.label === feedLabel
      )?.feed_type_uuid
  );

  const feedData = useSelector(
    (state) => state?.references?.feeds?.[metaDataId]?.data
  );

  const [feedFetchMade, setFeedFetchMade] = useState(false);
  const [images, setImages] = useState([]);
  const dispatch = useDispatch();

  /****************************** Hooks ***************************************/

  useEffect(() => {
    if (feedLabel && !metaDataId) {
      dispatch(getPreviewFeedMetaIfNeeded());
    }
  }, [metaDataId, feedLabel, dispatch]);

  useEffect(() => {
    if (metaDataId && !feedData?.length && !feedFetchMade) {
      dispatch(
        getPreviewFeedIfNeeded({
          feedTypeUuid: metaDataId
        })
      );
      setFeedFetchMade(true);
    }
  }, [metaDataId, feedData, feedFetchMade, dispatch]);

  useEffect(() => {
    if (feedData?.length && !images.length) {
      const images = [];
      for (let i = 0; i < feedData?.length; i++) {
        const entry = feedData?.[i] || null;
        if (images.length === MAX_IMAGES) {
          break;
        }
        const mediaItem = entry?.media?.[0];
        if (mediaItem) {
          const url = `${mediaItem?.media_url}?w=31&h=31&fit=crop `;
          images.push({
            key: mediaItem.contentUuid,
            url
          });
        }
      }
      setImages(images);
    }
  }, [feedData, images.length]);

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <Card className="explore-category-cta p-2 border-outline-gray mb-3">
      <h3 className="section-header d-flex justify-content-between align-items-center font-weight-bold text-12 text-uppercase py-2 mb-2">
        <span className="d-flex align-items-center">
          {titleImg && <img src={titleImg} className="mr-1 mb-n1" alt="" />}
          {title}
        </span>
        <span>
          {showNewBadge && (
            <Badge
              color="aqua"
              className="ml-2 text-10 text-uppercase helv-bold">
              {i18n.t("common.new")}
            </Badge>
          )}
        </span>
      </h3>
      <div className="d-flex align-items-start w-100">
        <ul className="d-flex flex-wrap preview-images">
          {!!images.length &&
            images.map((image) => (
              <li key={image.key}>
                <img src={image.url} alt="" />
              </li>
            ))}
        </ul>
        <div>
          <p className="m-0 text-13">{description}</p>
          <TopicViewAllLink feedUuid={metaDataId} />
        </div>
      </div>
    </Card>
  );
};

export default ExploreCategoryCTA;
