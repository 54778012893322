import React from "react";
import { Link } from "react-router-dom";
import SimilarCases from "../../components/similar-cases/SimilarCases";
import { SIGN_IN, SIGN_UP } from "../../constants/routes";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import useSignUpModalClick from "../../hooks/useSignUpModalClick";
import { getUrlWithRedirect } from "../../utils/route-utils";

const BlockedSimilarCases = ({
  className,
  cases,
  title,
  linkText,
  trackingClick
}) => {
  const userExists = useAnonUserExists();
  const { showLoginModal } = useLoginModalClick();
  const { showSignUpModal } = useSignUpModalClick();

  const loginClick = userExists ? showLoginModal : showSignUpModal;

  return (
    <SimilarCases
      className={className}
      relatedCases={cases || []}
      itemLimit={4}
      size={6}
      headerElement={
        <h3 className="helv-bold text-18 text-center mb-3">{title}</h3>
      }
      footerElement={
        <Link
          className="btn btn-lg btn-secondary btn-block helv-bold"
          onClick={(e) => {
            trackingClick();
            loginClick(e);
          }}
          to={getUrlWithRedirect(userExists ? SIGN_IN : SIGN_UP)}>
          {linkText}
        </Link>
      }
    />
  );
};

export default BlockedSimilarCases;
