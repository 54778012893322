/** @format */

import _ from "lodash";

export const getUserProfession = (userSpecialties) => {
  if (_.isEmpty(userSpecialties)) {
    return null;
  }

  for (const s of userSpecialties) {
    if (s.path) {
      const length = s.path.split(".").length;
      if (length === 1) {
        return s;
      }
    }
  }
  return null;
};

export const getUserSpecialties = (userSpecialties) => {
  let specialties = [];
  if (!userSpecialties) {
    return specialties;
  }

  const uniqueSpecialties = userSpecialties.reduce((unique, o) => {
    if (!unique.some((obj) => obj.treeUuid === o.treeUuid)) {
      unique.push(o);
    }
    return unique;
  }, []);

  for (const specialty of uniqueSpecialties) {
    if (specialty.path) {
      const length = specialty.path.split(".").length;
      if (length === 2) {
        specialty.children = userSpecialties.filter(
          (s) =>
            _.startsWith(s.path, specialty.path) && s.path !== specialty.path
        );
        specialties.push(specialty);
      }
    }
  }
  return specialties;
};

export const getUserSubSpecialties = (userSpecialties) => {
  let subspecialties = [];
  if (!userSpecialties) {
    return subspecialties;
  }

  const uniqueSpecialties = userSpecialties.reduce((unique, o) => {
    if (!unique.some((obj) => obj.treeUuid === o.treeUuid)) {
      unique.push(o);
    }
    return unique;
  }, []);

  for (const specialty of uniqueSpecialties) {
    if (specialty.path) {
      const length = specialty.path.split(".").length;
      if (length === 3) {
        subspecialties.push(specialty);
      }
    }
  }
  return subspecialties;
};

export const getSpecialtiesLabel = (userSpecialties) => {
  if (_.isEmpty(userSpecialties)) {
    return "";
  }

  const specialties = getUserSpecialties(userSpecialties);

  let label = "";
  if (!_.isEmpty(specialties)) {
    label += specialties.reduce((acc, s, index) => {
      return acc + (index === 0 ? "" : ", ") + s.name;
    }, "");
  }
  return label;
};

const physician = "physician";
export const isPhysician = (user) => {
  if (_.isEmpty(user)) {
    return false;
  }

  const specialty = _.get(
    user,
    "primarySpecialty.tree.profession.professionLabel",
    ""
  );
  if (specialty === physician) {
    return true;
  }
  return false;
};
