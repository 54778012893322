import React, { useState, useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import i18n from "../../utils/i18n";
import CommentInput from "./CommentInput";
import PropTypes from "prop-types";
import { editComment } from "../../actions/comment.actions";
import { COMMENT_TYPES } from "../../constants/case-constants";
import usePopup from "../../components/popup/usePopup";
import TOAST_TYPE from "../../components/popup/ToastType";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { trackCommentEdit } from "../../actions/metrics.actions";
import CustomIcon from "../custom-icon/CustomIcon";

const EditContainer = ({ onCloseClick, comment, caseContent }) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const { showToast, hideToast } = usePopup();
  const [loading, setLoading] = useState(false);
  const commentRef = useRef(null);
  const [replyToText, setReplyToText] = useState(null);
  const [originalText, setOriginalText] = useState(null);

  const isReply = comment.commentUuid !== comment.parentUuid;

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (isReply) {
      const splitText = comment?.text.split(" ");
      const targetUser =
        splitText.length > 0 && splitText[0]?.startsWith("@")
          ? splitText.shift()
          : i18n.t("comments.author");

      setOriginalText(splitText.join(" "));
      setReplyToText(`${i18n.t("comments.replyTo")} ${targetUser}`);
    } else {
      setOriginalText(comment?.text);
    }
  }, [isReply, comment?.text]);

  /** ********************************* FUNCTIONS *************************************/

  const onPost = async ({ text, isAnonComment }) => {
    try {
      trackCommentEdit({
        commentUuid: comment?.commentUuid,
        contentUuid: comment?.contentUuid,
        charCount: text.length,
        isAnonymous: isAnonComment
      });

      setLoading(true);

      const result = await dispatch(editComment(comment?.commentUuid, text));

      setLoading(false);

      if (!result.error) {
        showToast({
          message: i18n.t("comments.editCommentSuccess"),
          toastType: TOAST_TYPE.SUCCESS
        });

        // reset the comment
        commentRef.current.value = null;
        onCloseClick();
      } else {
        showToast({
          message: i18n.t("comments.editCommentFailure"),
          toastType: TOAST_TYPE.ERROR
        });
      }

      setTimeout((t) => {
        hideToast();
      }, 3000);
    } catch (error) {
      console.log("Post comment error: ", error.message);
    }
  };

  const verifiedOnPost = useVerifiedUserGate(onPost);

  /** ********************************** RENDER ***************************************/
  return (
    <div className="pb-2">
      {replyToText && (
        <div
          style={{ height: "44px" }}
          className="px-3 bg-battleship-gray d-flex align-items-center justify-content-between">
          <div className="text-white">{replyToText}</div>
          <div className="cursor-pointer" onClick={onCloseClick}>
            <CustomIcon icon="modal_exit" color="white" size={14} />
          </div>
        </div>
      )}
      {originalText && (
        <CommentInput
          ref={commentRef}
          onPost={verifiedOnPost}
          commentType={COMMENT_TYPES.EDIT}
          borderRadius="0 0 4px 4px"
          loading={loading}
          existingText={originalText}
          onCancelClick={onCloseClick}
          caseContent={caseContent}
        />
      )}
    </div>
  );
};

EditContainer.propTypes = {
  comment: PropTypes.object.isRequired,
  onCloseClick: PropTypes.func
};

export default EditContainer;
