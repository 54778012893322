import React from "react";
import { Button } from "reactstrap";
import ReactMarkdown from "react-markdown";
import i18n from "../../../utils/i18n";
import moment from "moment";
import "./DeletedPostItem.scss";

const NewFollowerItem = ({ markdownMessage, longMessage, createdAt }) => {
  return (
    <>
      <div className="notif-markdown">
        <ReactMarkdown>{markdownMessage || longMessage}</ReactMarkdown>
      </div>
      <div className="mb-0 text-muted">{moment(createdAt).fromNow(true)}</div>
      <div className="mt-2">
        <Button color="primary" outline>
          {i18n.t("ActivityScreen.followBackButton")}
        </Button>
      </div>
    </>
  );
};

export default NewFollowerItem;
