import React from "react";
import { Button } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";

const ShareButton = ({
  className = "btn-text px-2 py-1",
  buttonColor = "battleship-gray",
  iconClassName = "mr-2",
  iconColor = "battleship-gray",
  text = i18n.t("CaseAction.sharePost"),
  onClick
}) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <Button
      className={classNames(
        "d-inline-flex align-items-center justify-content-center",
        className
      )}
      color={buttonColor}
      onClick={onClick}>
      <CustomIcon
        className={classNames(`icon-${iconColor}`, iconClassName)}
        icon="sheet_share"
        size={20}
      />
      {text}
    </Button>
  );
};

export default ShareButton;
