import React, { useState } from "react";
import {
  Button,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter
} from "reactstrap";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import BaseDialogHeader from "../../../components/dialog/BaseDialogHeader";
import i18n from "../../../utils/i18n";

const DEGREES = {
  "M.D.": "CaseCME.degreeModal.MD",
  "D.O.": "CaseCME.degreeModal.DO"
};

const DegreeTypeModal = ({ onSave, onCancel, isOpen = false }) => {
  const [errors, setErrors] = useState({});
  const [degreeType, setDegreeType] = useState(null);

  const validateForm = () => {
    const newErrors = {};

    //validation
    if (!degreeType) {
      newErrors.degreeType = "select one, dummy";
    }
    setErrors(newErrors);
    return newErrors;
  };

  const localOnSave = () => {
    const newErrors = validateForm(degreeType);

    if (!newErrors.degreeType) {
      onSave({ degreeType });
    }
  };

  const onOptionSelect = (val) => {
    setDegreeType(val);
    validateForm();
  };

  return (
    <Modal isOpen={isOpen} toggle={onCancel} onAbort={onCancel}>
      <BaseDialogHeader onCancel={onCancel} showCloseButton={true} />

      <ModalBody className="px-4 mt-5">
        <h5 className="helv-med text-18 text-center mx-4">
          {i18n.t("CaseCME.degreeModal.title")}
        </h5>
        <p className="d-flex align-items-center justify-content-center text-battleship-gray text-16 mb-4">
          <CustomIcon
            icon="information"
            size={14}
            className="icon-battleship-gray"
          />
          <span>{i18n.t("CaseCME.degreeModal.description")}</span>
        </p>
        <FormGroup className="mb-0 d-flex">
          {Object.keys(DEGREES).map((key, i) => {
            const text = i18n.t(DEGREES[key]);
            const selected = key === degreeType;
            return (
              <Button
                style={{ width: "49%" }}
                className={`${i === 0 ? "mr-2 " : ""}
                  ${selected ? "active-button" : "inactive-border-button"}
                `}
                key={key}
                onClick={() => onOptionSelect(key)}>
                {text}
              </Button>
            );
          })}
          <FormFeedback>{errors.degreeType}</FormFeedback>
        </FormGroup>
      </ModalBody>
      <ModalFooter className="pt-4 px-5 pb-5 border-0">
        <div className="d-flex justify-content-center w-100">
          <Button
            size="lg"
            color="primary"
            disabled={!degreeType}
            onClick={localOnSave}
            className="w-215px">
            {i18n.t("common.save")}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DegreeTypeModal;
