import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { FEED_META_LABELS, FEED_NAMES } from "../constants/feed-type";
import groupActions from "../actions/groups.actions";

const useCurrentFeedTypeUuid = (isGroupFeed = false) => {
  const dispatch = useDispatch();
  const routeParams = useParams();
  const user = useSelector((state) => state.user);

  const feedMeta = useSelector((state) => state?.references?.feedsMeta?.data);
  const groupFeedUuid = useSelector(
    (state) => state.groups?.data?.[routeParams.feedTypeUuid]?.feedTypeUuid
  );

  const getFeedUuidByLabel = (label) =>
    Object.values(feedMeta || {})?.find((m) => m.label === label)
      ?.feed_type_uuid;

  useEffect(() => {
    dispatch(
      groupActions.fetchGroupsByIds(user.groups?.map((g) => g.groupUuid))
    );
  }, [dispatch, JSON.stringify(user?.groups)]);

  if (routeParams?.feed === FEED_NAMES.EVERYTHING) {
    return getFeedUuidByLabel(FEED_NAMES.EVERYTHING);
  } else if (routeParams?.feed === FEED_NAMES.RECOMMENDED) {
    return getFeedUuidByLabel(FEED_META_LABELS.RECOMMENDED);
  } else if (routeParams?.feed === FEED_NAMES.TRENDING) {
    return getFeedUuidByLabel(FEED_META_LABELS.TRENDING);
  } else if (routeParams?.feed === FEED_NAMES.QUIZZES) {
    return getFeedUuidByLabel(FEED_META_LABELS.QUIZZES);
  } else if (routeParams?.feed === FEED_NAMES.TOPIC) {
    return routeParams.feedTypeUuid;
  } else if (routeParams?.feed === FEED_NAMES.GROUP || isGroupFeed) {
    return groupFeedUuid;
  } else {
    return null;
  }
};

export default useCurrentFeedTypeUuid;
