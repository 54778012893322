import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import LoginForm from "./components/LoginForm";
import { useHistory } from "react-router-dom";
import { hideGlobalMessage } from "../../actions/global.actions";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import useInitialRouteRedirect from "../../hooks/useInitialRouteRedirect";
import { isMobile } from "react-device-detect";
import i18n from "../../utils/i18n";
import ExternalTextLink from "./components/ExternalTextLink";
import { StaticStrings } from "../../constants/static-string-constants";
import BecomeMemberCTA from "./components/BecomeMemberCTA";
import { Card, CardSubtitle, CardTitle } from "reactstrap";
import AppPromptFullCTA from "../../components/app-prompt/AppPromptFullCTA";
import useRedirectLinkHook from "../../hooks/useRedirectLinkHook";

const LoginPage = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  /** ********************************* FUNCTIONS *************************************/

  useInitialRouteRedirect();
  useRedirectLinkHook();

  // hide error toast when moving away from login screen
  useEffect(() => {
    const unsub = history.listen(() => {
      dispatch(hideGlobalMessage());
    });

    return () => {
      unsub();
    };
  }, [history, dispatch]);

  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-0 mt-lg-5">
            <Card className="p-4 mb-4 rounded-0 shadow">
              <h1
                className={`helv-bold text-center ${
                  isMobile ? "text-18 mb-3" : "text-22 mb-5"
                } `}>
                {i18n.t("RegistrationScreens.loginForm.title")}
              </h1>
              <LoginForm inModal={false} />
              {isMobile && (
                <>
                  <div className="mt-3">
                    <BecomeMemberCTA />
                  </div>

                  <hr className="border-width-2 my-3" />
                  <div className="text-center shadow p-3">
                    <CardTitle
                      tag="h5"
                      className="line-height-snug text-16 helv-med">
                      {i18n.t("AppPrompt.largePrompt.title")}
                    </CardTitle>
                    <CardSubtitle
                      tag="h6"
                      className="mb-2 mt-n1 line-height-snug text-14">
                      {i18n.t("AppPrompt.largePrompt.subtitle")}
                    </CardSubtitle>
                    <AppPromptFullCTA secondary={true} />
                  </div>
                </>
              )}
            </Card>
            {!isMobile && (
              <>
                <p className="text-center text-15 mb-4">
                  <span className="text-battleship-gray">
                    {i18n.t("RegistrationScreens.loginForm.bySigningIn")}
                  </span>
                  <ExternalTextLink
                    className="text-cool-blue cursor-pointer"
                    url={StaticStrings.termsOfServiceUrl}
                    linkText={i18n.t(
                      "RegistrationScreens.loginForm.termsAndConditions"
                    )}
                  />
                </p>
                <Card className="p-4 mb-4 rounded-0 shadow">
                  <BecomeMemberCTA />
                </Card>
              </>
            )}
          </div>
        }
        headerClass="py-0"
        hideBackButton={true}
        noGatedHeader={true}
      />
    </Figure1Layout>
  );
};

export default LoginPage;
