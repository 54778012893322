/** @format */

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Figure1Layout from "../../@layouts/Figure1Layout";
import { trackGroupTabEntry } from "../../actions/metrics.actions";
import AppPrompt from "../../components/app-prompt/AppPrompt";
import GroupsHomePage from "./GroupsHomePage";

const Groups = (props) => {
  /** ********************************** CONFIG ***************************************/
  const loc = useLocation();
  /** ********************************** HOOKS ****************************************/
  useEffect(() => {
    if (loc?.state?.context === "Navigation") {
      trackGroupTabEntry();
    }
  }, []);
  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout footer={<AppPrompt />}>
      <GroupsHomePage {...props} />
    </Figure1Layout>
  );
};

export default Groups;
