import React, { useState } from "react";
import { CardBody } from "reactstrap";
import * as PropTypes from "prop-types";
import CaseUserCell from "./CaseUserCell";
import {
  CASE_CARD_ORIGIN,
  SHARE_CONTEXT,
  SHARE_LOCATIONS
} from "../../metrics/constants.metrics";
import ShareCaseDialog from "./ShareCaseDialog";
import CaseActions from "./CaseActions";

const CaseHeader = ({ caseContent, caseNotification }) => {
  /** ********************************** CONFIG ***************************************/
  const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <>
      {caseContent && (
        <CardBody>
          <div className="d-inline-flex justify-content-between align-items-center w-100">
            <CaseUserCell
              caseContent={caseContent}
              displayFollowUser
              truncateUsername
            />
            <CaseActions
              caseContent={caseContent}
              caseNotification={caseNotification}
              from={CASE_CARD_ORIGIN.FEED}
              hideShareText
              context={SHARE_CONTEXT.FEED}
              location={SHARE_LOCATIONS.TOP_BAR}
              onShareClick={() => setIsShareDialogOpen(true)}
            />
          </div>
        </CardBody>
      )}

      <ShareCaseDialog
        isOpen={isShareDialogOpen}
        caseContent={caseContent}
        location={SHARE_LOCATIONS.TOP_BAR}
        context={SHARE_CONTEXT.FEED}
        onClosed={() => setIsShareDialogOpen(false)}
      />
    </>
  );
};

CaseHeader.propTypes = {
  title: PropTypes.string,
  name: PropTypes.string,
  subName: PropTypes.string,
  avatar: PropTypes.string
};

CaseHeader.defaultProps = {};

export default CaseHeader;
