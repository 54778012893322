import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Nav, NavItem, Spinner } from "reactstrap";
import { capitalize, isEmpty } from "lodash";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  fetchUserCreatedContent,
  listenUserProfile,
  setActiveProfileTab,
  syncUserProfileIfNeeded
} from "../../actions/user-profiles.actions";
import {
  trackShareCase,
  trackVisitProfile
} from "../../actions/metrics.actions";
import Figure1Page2Col from "../../@layouts/Figure1Page2Col";
import AutoSizer from "../../components/common/AutoSizer";
import Loading from "../../components/loading";
import ProfileBasicInfoView from "./components/ProfileBasicInfoView";
import ProfileAboutView from "./components/ProfileAboutView";
import ProfileCommentCard from "./components/ProfileCommentCard";
import ProfileCaseCommentHeaderCard from "./components/ProfileCaseCommentHeaderCard";
import CaseCard from "../../components/case/CaseCard";
import ProfileCaseCard from "./components/ProfileCaseCard";
import F1FlatList from "../../components/common/F1FlatList";
import F1MasonryGrid from "../../components/common/F1MasonryGrid";
import LoadingButton from "../../components/common/LoadingButton";
import ProfileImageBackground from "./components/ProfileImageBackground";
import { PROFILE_INFO_CARD_STATE } from "../../constants/profile-info-constants";
import { CREATE_NEW_CASE_POST_OPTIONS } from "../../constants/routes";
import ProfileEmptyCard from "./components/ProfileEmptyCard";
import { getCaseTypeUrl } from "../../utils/case-utils";
import { fetchCaseLabel } from "../../actions/case-label.actions";
import ProfileSectionHeaderCard from "./components/ProfileSectionHeaderCard";
import useIsCurrentUser from "../../hooks/useIsCurrentUser";
import "./ProfilePage.scss";
import { USER_CONTENT_TYPES } from "../../db/user-profile.db";
import EmptyProfileFeed from "./EmptyProfileFeed";
import { UPLOAD_CASE_ORIGIN } from "../../metrics/constants.metrics";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import MoreOptions from "./components/MoreOptions";

const TOP_TYPE = "TOP";
const TOP_TYPE_BACKGROUND = "TOP_BACKGROUND";
const ABOUT_TYPE = "ABOUT";
const EMPTY_CONTENT_CARD = "EMPTY_CONTENT_CARD";
const PAGE_SIZE = 20;
const MAX_COLUMN_WIDTH = 12;

const ProfilePage = ({ userUuid }) => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [hasSentMetrics, setHasSentMetrics] = useState(false);

  const userProfile = useSelector(
    (state) => state.userProfiles.profiles[userUuid]
  );
  const feedView = userProfile?.activeTab || USER_CONTENT_TYPES.CASE;

  const userContent = useSelector((state) => {
    const _content = state.userProfiles.contents[userUuid]?.[feedView];

    if (isEmpty(_content)) {
      return { data: [] };
    } else if (!userProfile?.caseFeedEnabled) {
      return _content;
    }
    // Append user profile data so case actions can work when displaying
    // sponsored profile in feed-style posts and comments
    return {
      ..._content,
      data: _content.data?.map((item) => ({
        ...item,
        isSponsored: userProfile?._isSponsored,
        authorUsername: userProfile?.username,
        authorAvatar: userProfile?.avatar,
        userUuid: userProfile?.userUuid
      }))
    };
  });
  const isLoading = useSelector((state) => {
    return state.userProfiles.contents[userUuid]?.[feedView]?.loading;
  });

  const isCurrentUser = useIsCurrentUser(userUuid);

  const showCaseFeed = userProfile?.caseFeedEnabled;
  const isInstitutional = userProfile?._isInstitutional;

  const userExists = !!userProfile?.userUuid;

  const profileData = isInstitutional
    ? [TOP_TYPE_BACKGROUND, TOP_TYPE]
    : [TOP_TYPE, ABOUT_TYPE];

  const caseData =
    userContent?.endReached &&
    userContent?.data &&
    isEmpty(userContent.data || [])
      ? [TOP_TYPE, EMPTY_CONTENT_CARD]
      : [TOP_TYPE, ...(userContent.data || [])];

  const fetchContent = useCallback(
    (reloading) => {
      dispatch(
        fetchUserCreatedContent(userUuid, PAGE_SIZE, reloading, feedView)
      );
    },
    [dispatch, userUuid, feedView]
  );

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    dispatch(syncUserProfileIfNeeded(userUuid));
  }, [userUuid, dispatch]);

  useEffect(() => {
    dispatch(fetchCaseLabel());
  }, [dispatch]);

  useEffect(() => {
    dispatch(listenUserProfile(userUuid, true));
    return () => {
      dispatch(listenUserProfile(userUuid, false));
    };
  }, [userUuid, dispatch]);

  useEffect(() => {
    if (userUuid && userProfile && userExists && !hasSentMetrics) {
      trackVisitProfile({
        username: userProfile.username,
        userUuid: userProfile.userUuid,
        userType: userProfile.userType,
        hasAvatar: !!userProfile.avatar,
        contentCount: userProfile.activityCount,
        specialty: userProfile.profileDisplayName
      });
      setHasSentMetrics(true);
    }
  }, [userUuid, userProfile, userExists, hasSentMetrics]);

  useEffect(() => {
    if (!userContent?.data?.length && !isLoading && !userContent?.endReached) {
      fetchContent(true);
    }
  }, [
    userContent.data,
    dispatch,
    isLoading,
    userUuid,
    feedView,
    fetchContent,
    userContent?.endReached
  ]);

  /** ********************************* FUNCTIONS *************************************/

  const openCase = (caseContent) => {
    history.push(getCaseTypeUrl(caseContent));
  };

  const tapCaseCard = (caseContent) => {
    openCase(caseContent);
  };

  const tapCommentCard = (comment) => {
    openCase(comment);
  };

  const onLoadMoreClick = () => {
    if (!userContent.loading && !userContent.endReached) {
      fetchContent(false);
    }
  };

  const onTabClick = (tab) => {
    dispatch(setActiveProfileTab(userUuid, tab));
  };

  const onEmptCaseClick = () => {
    trackShareCase({ context: UPLOAD_CASE_ORIGIN.PROFILE });
    history.push(CREATE_NEW_CASE_POST_OPTIONS);
  };

  const renderItem = ({ item, index }) => {
    if (item === EMPTY_CONTENT_CARD) {
      let message =
        feedView === USER_CONTENT_TYPES.CASE
          ? "Profile.emptyUserCaseFeed"
          : "Profile.emptyUserCommentFeed";
      let icon =
        feedView === USER_CONTENT_TYPES.CASE ? "post_case" : "comment_circle";
      const action =
        feedView === USER_CONTENT_TYPES.CASE && isCurrentUser
          ? onEmptCaseClick
          : null;
      const actionText =
        feedView === USER_CONTENT_TYPES.CASE && isCurrentUser
          ? "Profile.addCase"
          : null;

      if (!isCurrentUser) {
        message =
          feedView === USER_CONTENT_TYPES.CASE
            ? "Profile.emptyOtherCaseFeed"
            : "Profile.emptyOtherCommentFeed";
      }

      return (
        <EmptyProfileFeed
          key={index}
          message={message}
          icon={icon}
          action={action}
          actionText={actionText}
        />
      );
    } else if (item.type === USER_CONTENT_TYPES.COMMENT) {
      if (showCaseFeed) {
        return null;
      }

      return (
        <ProfileCommentCard
          onClick={() => tapCommentCard(item)}
          data={item}
          key={index}
        />
      );
    } else if (item.type === USER_CONTENT_TYPES.CASE) {
      if (showCaseFeed) {
        return (
          <CaseCard
            className="mb-0"
            caseContent={item}
            key={index}
            onPush={() => openCase(item)}
          />
        );
      }

      return (
        <ProfileCaseCard
          onClick={() => tapCaseCard(item)}
          caseContent={item}
          key={index}
          position={index}
          isCurrentUser={isCurrentUser}
        />
      );
    }

    return null;
  };

  const renderFooter = () => {
    if (isLoading) {
      return (
        <div className="d-flex justify-content-center align-items-center flex-grow-1 spinner my-2 p-3">
          <Spinner
            key="flat-list-spinner"
            size="lg"
            className="m-2"
            color="primary"
          />
        </div>
      );
    }
    if (!isEmpty(userContent.data) && !userContent.endReached) {
      return (
        <Card className="border-0 rounded-0 mt-2">
          <CardBody>
            <LoadingButton
              className="rounded"
              block
              size="lg"
              color="secondary"
              disabled={userContent.loading}
              loading={userContent.loading}
              onClick={onLoadMoreClick}>
              {t("common.viewMore")}
            </LoadingButton>
          </CardBody>
        </Card>
      );
    }
    return null;
  };

  /** ********************************** RENDER ***************************************/

  if (!userUuid || !userProfile) {
    return <Loading />;
  }

  if (!userExists) {
    return (
      <Figure1Page2Col
        isChildPage
        mainContentSize={isInstitutional ? MAX_COLUMN_WIDTH : undefined}
        rightSidebarContentSize={isInstitutional ? MAX_COLUMN_WIDTH : undefined}
        mainContent={
          <Card className="border-0 rounded-0 overflow-hidden">
            <CardBody className="p-4 position-relative">
              <ProfileEmptyCard title={t("Profile.notFound")} />
            </CardBody>
          </Card>
        }></Figure1Page2Col>
    );
  }
  const mainContent = (
    <>
      <Card className="border-0 rounded-0 overflow-hidden">
        <CardBody className="p-4 position-relative">
          {profileData.map((item, index) => {
            if (item === TOP_TYPE_BACKGROUND) {
              return (
                <ProfileImageBackground
                  avatar={userProfile.backgroundImage || userProfile.avatar}
                  isInstitutional={isInstitutional}
                  key={index}
                />
              );
            } else if (item === TOP_TYPE) {
              return (
                <ProfileBasicInfoView
                  profileData={userProfile}
                  isCurrentUser={isCurrentUser}
                  key={index}
                />
              );
            }
            return null;
          })}
        </CardBody>
      </Card>
      {isInstitutional ? (
        <F1MasonryGrid
          className="mt-3"
          data={caseData}
          ListHeaderComponent={() => (
            <ProfileCaseCommentHeaderCard
              isCurrentUser={isCurrentUser}
              profile={userProfile}
            />
          )}
          renderItem={renderItem}
          ListFooterComponent={renderFooter}
          numColumns={
            caseData.indexOf(EMPTY_CONTENT_CARD) !== -1
              ? { xs: 1, lg: 1 }
              : { xs: 1, lg: 2 }
          }
        />
      ) : (
        <>
          {!showCaseFeed && (
            <Nav className="mt-3 d-flex justify-content-center align-items-center flex-grow-1 bordered-nav mb-2">
              <NavItem className="w-50">
                <Button
                  color="link"
                  onClick={() => onTabClick(USER_CONTENT_TYPES.CASE)}
                  className={`w-100 nav-link helv-med text-15 ${
                    feedView === USER_CONTENT_TYPES.CASE ? "active" : ""
                  }`}>
                  {t("Profile.casesAndPosts", {
                    count: userProfile?.approvedCaseCount || 0
                  })}
                </Button>
              </NavItem>
              <NavItem className="w-50">
                <Button
                  color="link"
                  onClick={() => onTabClick(USER_CONTENT_TYPES.COMMENT)}
                  className={`w-100 nav-link helv-med text-15 ${
                    feedView === USER_CONTENT_TYPES.COMMENT ? "active" : ""
                  }`}>
                  {t("Profile.comments", {
                    count: userProfile?.approvedCommentCount || 0
                  })}
                </Button>
              </NavItem>
            </Nav>
          )}

          {showCaseFeed && (
            <ProfileCaseCommentHeaderCard
              className="mt-3"
              isCurrentUser={isCurrentUser}
              profile={userProfile}
            />
          )}

          <F1FlatList
            bordered={false}
            data={caseData}
            ItemSeparatorComponent={({ index }) => {
              return index === 0 ? null : <div className="pt-1 py-1" />;
            }}
            renderItem={renderItem}
            ListFooterComponent={renderFooter}
          />
        </>
      )}
    </>
  );

  if (showCaseFeed && !isInstitutional) {
    return <Figure1Page1Col isChildPage mainContent={mainContent} />;
  }

  return (
    <Figure1Page2Col
      isChildPage
      mainContent={mainContent}
      mainContentSize={isInstitutional ? MAX_COLUMN_WIDTH : undefined}
      rightSidebarContentSize={isInstitutional ? MAX_COLUMN_WIDTH : undefined}
      rightSidebarContent={
        isInstitutional ? null : (
          <>
            <AutoSizer className="position-lg-fixed b-lg-2 overflow-y-lg-auto">
              <Card className="border-0 rounded-0 overflow-hidden">
                <ProfileSectionHeaderCard className="t-lg-0">
                  {capitalize(t("common.about"))}
                </ProfileSectionHeaderCard>
                <CardBody className="p-3 position-relative">
                  <ProfileAboutView
                    profileData={userProfile}
                    state={
                      isCurrentUser
                        ? PROFILE_INFO_CARD_STATE.EDIT
                        : PROFILE_INFO_CARD_STATE.DISPLAY
                    }
                    isCurrentUser={isCurrentUser}
                  />
                </CardBody>
              </Card>
              {isCurrentUser && (
                <Card className="border-0 rounded-0 overflow-hidden mt-3">
                  <CardBody className="p-3 position-relative">
                    <MoreOptions />
                  </CardBody>
                </Card>
              )}
            </AutoSizer>
          </>
        )
      }
    />
  );
};

export default ProfilePage;
