import React, { useEffect, useMemo, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useLocation
} from "react-router-dom";
import ClinicalMomentsPage from "./ClinicalMomentsPage";
import CMEActivityModal from "./components/CMEActivityModal";
import { withOpenModalManagement } from "../../components/common/WithModalManagement";
import { withLightTheme } from "../../components/theme/ThemeProvider";
import * as ROUTES from "../../constants/routes";

import "./clinical-moments.scss";
import { getUrl } from "../../utils/route-utils";

const ClinicalMoments = () => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const { caseId, commentId } = useParams();
  const loc = useLocation();
  const [qParams, setQParams] = useState(null);
  const urlParams = new URLSearchParams(loc.search);
  const section = urlParams.get("section");
  const slideIndex = (urlParams.get("slideIndex") || 0) - 1;

  const clinicalMomentsRoutes = useMemo(
    () => [
      {
        path: ROUTES.CLINICAL_MOMENTS_ACTIVITY,
        component: withLightTheme(withOpenModalManagement(CMEActivityModal)),
        props: {
          caseUuid: caseId,
          onClosed: () => {
            setQParams(null);
            history.replace(
              getUrl(ROUTES.CLINICAL_MOMENTS_DETAIL, { caseId }),
              {}
            );
          },
          ...qParams
        }
      }
    ],
    [caseId, history, qParams]
  );

  /** ********************************** HOOKS ****************************************/

  // grab the params and strip them out
  useEffect(() => {
    if (!qParams && section) {
      let index = slideIndex;
      // pre/post should always start on the first slide
      if (section === "pre_test" || section === "post_test" || isNaN(index)) {
        index = 0;
      }

      setQParams({ section, slideIndex: index });
      // if we have the section pram, we should redirect to the activity automatically
      history.replace(getUrl(ROUTES.CLINICAL_MOMENTS_ACTIVITY, { caseId }), {});
    }
  }, [history, loc, qParams, section, slideIndex, caseId]);
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <ClinicalMomentsPage caseUuid={caseId} commentId={commentId} />
      <Switch>
        {clinicalMomentsRoutes.map((route) => (
          <Route key={route.path} path={route.path}>
            <route.component {...route.props} />
          </Route>
        ))}
      </Switch>
    </>
  );
};

export default ClinicalMoments;
