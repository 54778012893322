import React, { useEffect, useState } from "react";
import { CardBody } from "reactstrap";
import CustomIcon from "../custom-icon/CustomIcon";
import i18n from "../../utils/i18n";
import { upperFirst } from "lodash";

const CaseSpecialtiesBar = ({ caseContent, className }) => {
  const [selectedSpecialties, setSelectedSpecialties] = useState("");

  useEffect(() => {
    if (caseContent?.specialtyNames) {
      setSelectedSpecialties(caseContent.specialtyNames.join(", "));
    }
  }, [caseContent]);

  if (!caseContent?.specialtyNames.length) return null;

  return (
    <CardBody className={`bg-lighter-gray-2 py-2 ${className}`}>
      <div className="d-flex flex-row align-items-center">
        <CustomIcon
          icon="post-icon"
          color="charcoal-gray"
          size={26}
          className="mr-2"
        />
        <div className="text-13 line-height-tight">
          <b>{upperFirst(i18n.t("common.post"))}</b> {i18n.t("common.in")}{" "}
          {selectedSpecialties}
        </div>
      </div>
    </CardBody>
  );
};

export default CaseSpecialtiesBar;
