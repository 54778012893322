import React from "react";

import "./UserSelector.scss";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";

const UserSelectorAnonInfobox = ({ showTooltip, target, closeTooltip }) => {
  if (!showTooltip) {
    return null;
  }
  return (
    <div className="bg-default-black text-white p-3 my-2 rounded popover-shadow">
      <div className="d-flex">
        <ul className="m-0 p-0 pl-3 text-14">
          <li>{i18n.t("shareCase.anonymousPosting.tooltip.l1")}</li>
          <li>{i18n.t("shareCase.anonymousPosting.tooltip.l2")}</li>
          <li>{i18n.t("shareCase.anonymousPosting.tooltip.l3")}</li>
        </ul>

        <div className="cursor-pointer mr-n1 mt-n2" onClick={closeTooltip}>
          <CustomIcon icon="modal_exit" color="white" size={16} />
        </div>
      </div>
    </div>
  );
};

export default UserSelectorAnonInfobox;
