import React, { useState } from "react";
import { Button } from "reactstrap";
import i18n from "../../../utils/i18n";
import EmailVerificationModal from "./EmailVerificationModal";

const StudentEmailSignupCTA = ({ onSave }) => {
  /** ********************************** CONFIG ***************************************/

  const [modalOpen, setModalOpen] = useState(false);
  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <div className="text-center text-battleship-gray my-3">
        <p className="text-16 m-0">
          {i18n.t(
            "RegistrationScreens.verificationPhotoPage.v2.student.noPhotoMessage"
          )}
        </p>
        <Button
          className="text-16"
          color="link"
          onClick={() => setModalOpen(true)}>
          {i18n.t(
            "RegistrationScreens.verificationPhotoPage.v2.student.btnSubmit"
          )}
        </Button>
      </div>
      {modalOpen && (
        <EmailVerificationModal
          onSave={onSave}
          onCancel={() => {
            setModalOpen(false);
          }}
          isOpen={modalOpen}
        />
      )}
    </>
  );
};

export default StudentEmailSignupCTA;
