/** @format */

import React from "react";
import Figure1Layout from "../../../@layouts/Figure1Layout";
import Figure1Page1Col from "../../../@layouts/Figure1Page1Col";
import RegistrationHeaderV2 from "../components/RegistrationHeader.v2";
import AutoRegHomePage from "./AutoRegHomePage";

const AutoReg = () => {
  /** ********************************** CONFIG ***************************************/
  /** ********************************** HOOKS ****************************************/
  /** ********************************* FUNCTIONS *************************************/
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={<AutoRegHomePage />}
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default AutoReg;
