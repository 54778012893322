import React from "react";
import i18n from "../../utils/i18n";
import "./styles.scss";
import useDialog from "../dialog/useDialog";
import ProvisionalMemberMessagePopup from "./ProvisionalMemberMessagePopup";

const ProvisionalMemberMessage = () => {
  /** ********************************** CONFIG ***************************************/
  const { inform } = useDialog();

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const onReadMore = async () => {
    await inform({
      title: i18n.t("common.provisionalMemberPopup.title"),
      subtitle: i18n.t("common.provisionalMemberPopup.p1"),
      body: <ProvisionalMemberMessagePopup />,
      primaryButtonText: i18n.t("common.confirm")
    });
  };
  /** ********************************** RENDER ***************************************/

  return (
    <span className="provisional-member-message-container">
      {i18n.t("common.provisionalMember.explanation")}
      <br />
      {i18n.t("common.provisionalMember.learnMore")}{" "}
      <button className="btn-link" onClick={onReadMore}>
        {i18n.t("common.provisionalMember.here")}.
      </button>
    </span>
  );
};

export default ProvisionalMemberMessage;
