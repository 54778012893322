import React, { useEffect, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { isEmpty } from "lodash";
import { isMobileOnly } from "react-device-detect";
import Figure1Page2Col from "../../@layouts/Figure1Page2Col";
import {
  requestCaseSyncIfNeeded,
  toggleCaseDetailsListener,
  toggleCaseUserActionsListener
} from "../../actions/case.actions";
import CaseCardLoading from "../../components/case/CaseCardLoading";
import CaseEmptyView from "../../components/case/CaseEmptyView";
import Comments from "../../components/comments/Comments";
import AutoSizer from "../../components/common/AutoSizer";
import ISILayout from "../../components/isi/ISILayout";
import CMCoverSlide from "./components/CMCoverSlide";
import CMCoverSlideRow from "./components/CMCoverSlideRow";
import {
  cacheDetailViewMetrics,
  trackCaseDetailEntryView,
  trackCaseDetailView
} from "../../actions/metrics.actions";
import useListenInteractiveCaseState from "../../hooks/useListenInteractiveCaseState";
import CME_SECTION from "../../constants/cme-section";
import useTheme from "../../components/theme/useTheme";
import useComments from "../../components/comments/useComments";
import * as ROUTES from "../../constants/routes";
import { getUrl } from "../../utils/route-utils";
import { ISI_FOOTER_HEIGHT } from "../../constants/isi-constants";
import useISI from "../../components/isi/useISI";
import CMCoverSlideRowLoading from "./components/CMCoverSlideRowLoading";
import useIsGated from "../../hooks/useIsGated";
import useGetCommentContentID from "../../hooks/useGetCommentContentID";
import useAppPrompt from "../../components/app-prompt/useAppPrompt";
import { setGatedState } from "../../actions/global.actions";

const ClinicalMomentsPage = ({ caseUuid, commentId }) => {
  /** ********************************** CONFIG ***************************************/
  const history = useHistory();
  const dispatch = useDispatch();
  const { setTheme } = useTheme();
  const userReactions = useSelector(
    (state) => state.case.cases[caseUuid]?.userReactions
  );
  const caseData = useSelector((state) => state.case.cases[caseUuid]?.data);
  const isDisplayable = caseData?.displayable;
  const enterTimestamp = useRef(new Date().getTime());
  const [currentIndex] = useState(0);
  const data = caseData?.[CME_SECTION.ACTIVITY] || [];
  const isGated = useIsGated();
  const isAnonymous = !!caseData?.isAnonymous;
  const hasAcceptedAnswer = !isEmpty(caseData?.acceptedAnswer);
  const { isShown: isAppPromptShown, height: appPromptHeight } = useAppPrompt();

  const contentUuidForComments = useGetCommentContentID(caseData?.contentItems);

  const { commentStatus } = useComments(caseUuid, contentUuidForComments);
  const commentCount = commentStatus?.allCount || 0;

  const itemContent = data[currentIndex];
  const location = useLocation();
  const metrics = useRef({});
  const [position, setPosition] = useState(null);
  const caseLinkUrl = getUrl(ROUTES.CLINICAL_MOMENTS_DETAIL, {
    caseId: caseUuid
  });
  const { showFooter: showISIFooter } = useISI(itemContent, false);
  const [enterTrackingSent, setEnterTrackingSent] = useState(false);
  const [haveSetUngated, setHaveSetUngated] = useState(false);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    setTheme("dark");
    return () => {
      setTheme("light");
    };
  }, [setTheme]);

  useEffect(() => {
    dispatch(requestCaseSyncIfNeeded(caseUuid));
    dispatch(toggleCaseDetailsListener(caseUuid, true));
    dispatch(toggleCaseUserActionsListener(caseUuid, true));
    return () => {
      dispatch(toggleCaseDetailsListener(caseUuid, false));
      dispatch(toggleCaseUserActionsListener(caseUuid, false));
    };
  }, [caseUuid, dispatch]);

  useListenInteractiveCaseState(caseUuid);

  // METRICS EFFECTS
  useEffect(() => {
    metrics.current.ungated = isGated;
    metrics.current.isAnonymous = isAnonymous;
    metrics.current.acceptedAnswer = hasAcceptedAnswer;

    if (location?.state?.position != null) {
      metrics.current.position = location.state.position;
      setPosition(location.state.position);
    }
    if (caseData) {
      metrics.current.caseContent = caseData;
    }
    if (commentCount != null) {
      metrics.current.commentCount = commentCount;
    }

    // Only send this if we have the data, and we've not already sent it
    if (
      !enterTrackingSent &&
      metrics.current?.caseContent &&
      metrics.current?.commentCount != null
    ) {
      trackCaseDetailEntryView(metrics.current);
      dispatch(cacheDetailViewMetrics(metrics.current?.caseContent?.caseUuid));
      setEnterTrackingSent(true);
    }
  }, [
    isAnonymous,
    hasAcceptedAnswer,
    enterTrackingSent,
    location,
    caseData,
    commentCount,
    isGated,
    dispatch
  ]);

  useEffect(() => {
    return () => {
      // eslint-disable-next-line
      metrics.current.duration = new Date().getTime() - enterTimestamp.current;
      // eslint-disable-next-line
      trackCaseDetailView(metrics.current);
    };
  }, []);

  useEffect(() => {
    if (isGated && !haveSetUngated) {
      dispatch(setGatedState(true, true));
      setHaveSetUngated(true);
    }
  }, [dispatch, isGated, haveSetUngated]);

  /** ********************************* FUNCTIONS *************************************/

  const onStartActivity = () => {
    history.push(
      getUrl(ROUTES.CLINICAL_MOMENTS_ACTIVITY, { caseId: caseUuid })
    );
  };

  /** ********************************** RENDER ***************************************/

  let content = null;
  let headerContent = null;

  if (isEmpty(caseData)) {
    content = <CaseCardLoading />;
    headerContent = <CMCoverSlideRowLoading />;
  } else if (!isDisplayable) {
    content = <CaseEmptyView onOk={history.goBack} />;
    headerContent = null;
  } else if (itemContent) {
    content = <CMCoverSlide caseMeta={caseData} content={itemContent} />;
    headerContent = (
      <CMCoverSlideRow
        caseMeta={caseData}
        content={itemContent}
        onStartActivity={onStartActivity}
      />
    );
  }

  const styleProps = {};
  if (showISIFooter && !isMobileOnly) {
    styleProps.marginBottom = ISI_FOOTER_HEIGHT;
  }
  if (isAppPromptShown) {
    styleProps.marginBottom = (styleProps.marginBottom ?? 0) + appPromptHeight;
  }
  if (isGated) {
    styleProps.bottom = "5rem";
  }

  return (
    <ISILayout isi={itemContent} enableGatedBanner>
      <Figure1Page2Col
        isChildPage
        headerRowSlot={headerContent}
        mainContent={content}
        rightSidebarContent={
          <AutoSizer
            className={`position-lg-fixed ${
              isGated ? "" : "b-lg-2"
            } overflow-y-lg-auto ${isGated ? "gated" : ""}`}
            style={styleProps}>
            <div>
              {caseData && isDisplayable && (
                <Comments
                  caseContent={caseData}
                  currentItem={itemContent}
                  commentId={commentId}
                  commentStatus={commentStatus}
                  hideComments={!itemContent?.features.commentsEnabled}
                  hideCommentsSwitch={true}
                  hideReactions={!itemContent?.features.reactionsEnabled}
                  userReactions={userReactions || []}
                  position={position}
                  caseLinkUrl={caseLinkUrl}
                  forceAllComments={true}
                />
              )}
            </div>
          </AutoSizer>
        }
      />
    </ISILayout>
  );
};

export default ClinicalMomentsPage;
