import React from "react";
import { Card, CardBody } from "reactstrap";
import CustomIcon from "../../../../components/custom-icon/CustomIcon";
import CircleCustomIcon from "../../../../components/custom-icon/CircleCustomIcon";

const OptionRowCard = ({
  className = "",
  icon,
  iconColor,
  iconCircleColor,
  text,
  textColor,
  onClick
}) => {
  return (
    <Card
      className={`border-width-2 cursor-pointer ${className}`}
      outline
      color="lighter-gray"
      onClick={onClick}>
      <CardBody className="d-inline-flex justify-content-between align-items-center py-2">
        <div className="d-inline-flex align-items-center p-1">
          <CircleCustomIcon
            circleColor={`${iconCircleColor} p-2`}
            icon={icon}
            className="mr-3"
            innerClassName={`icon-${iconColor}`}
            size={25}
          />
          <span className={`text-${textColor} text-18`}>{text}</span>
        </div>
        <CustomIcon
          className="icon-battleship-gray"
          icon="drawer_draft_chevron"
          size={22}
        />
      </CardBody>
    </Card>
  );
};

export default OptionRowCard;
