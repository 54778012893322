/** @format */
import React, { useMemo } from "react";
import PropTypes from "prop-types";
import CMECompletedActivityCell from "./CMECompletedActivityCell";
import CMECompletedActivitiesHeader from "./CMECompletedActivitiesHeader";
import CMEDateSectionHeader from "./CMEDateSectionHeader";
import { getCompletedCredits } from "../../../utils/cme-utils";
import { ACTIVITY_DATE, COMPLETED_ACTIVITY } from "../constants";
import "../cme.scss";
import i18n from "../../../utils/i18n";

const CMECompletedActivitiesGallery = React.forwardRef(
  ({ activities = [], emptyText = "" }, ref) => {
    const totalCredits = useMemo(() => {
      return getCompletedCredits(activities);
    }, [activities]);
    const creditsThisMonth = useMemo(() => {
      return getCompletedCredits(activities, true);
    }, [activities]);
    const outerStyle = {
      maxWidth: "406px",
      width: "98%"
    };
    const contentStyle = {
      backgroundColor: "white",
      marginTop: "10px"
    };

    return (
      <div style={outerStyle} ref={ref}>
        <CMECompletedActivitiesHeader
          totalCredits={totalCredits}
          creditsThisMonth={creditsThisMonth}
        />
        {activities.length === 0 ? (
          <div className="emptyActivities">{emptyText}</div>
        ) : (
          <>
            <div className="mt-3 helv-bold text-battleship-gray text-16 line-height-26">
              {i18n.t("CMEScreens.completedCME")}
            </div>
            <div
              className="d-flex flex-column align-items-center flex-nowrap"
              style={contentStyle}>
              {activities.map((item, index) => {
                if (item.type === ACTIVITY_DATE) {
                  return (
                    <CMEDateSectionHeader
                      key={index.toString()}
                      date={item.date}
                    />
                  );
                } else if (item.type === COMPLETED_ACTIVITY) {
                  return (
                    <CMECompletedActivityCell
                      key={index.toString()}
                      activity={item}
                    />
                  );
                }
                return <div></div>;
              })}
            </div>
          </>
        )}
      </div>
    );
  }
);

CMECompletedActivitiesGallery.propTypes = {
  activities: PropTypes.array.isRequired,
  emptyText: PropTypes.string.isRequired
};

export default CMECompletedActivitiesGallery;
