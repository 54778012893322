import React, { useCallback, useEffect, useState } from "react";
import i18n from "../../utils/i18n";
import Figure1Layout from "../../@layouts/Figure1Layout";
import AppPrompt from "../../components/app-prompt/AppPrompt";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import F1HorizontalDivider from "../../components/common/F1HorizontalDivider";

import NPIForm from "./components/profile/NPIForm";
import PhotoUploadForm from "./components/profile/PhotoUploadForm";
import { useSelector } from "react-redux";
import useDialog from "../../components/dialog/useDialog";
import { useHistory } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import { SETTINGS_PROFILE } from "../../constants/routes";
import useIsUSAUser from "../../hooks/useIsUSAUser";
import VERIFICATION_STATUS from "../../constants/verification-status";
import { trackSettingsVerificationEntry } from "../../actions/metrics.actions";
import useProfileLogic from "./hooks/useProfileLogic";
import StudentEmailSignupCTA from "../authentication/components/StudentEmailSignupCTA";
import { useIsAStudentByName } from "../../hooks/useIsAStudent";

const ProfileVerificationSettings = () => {
  /** ********************************** CONFIG ***************************************/

  const showModal = useSelector(
    (state) =>
      Object.keys(state.user?.professionChangeRequest).length > 0 &&
      state.user?.verification?.verificationStatus !==
        VERIFICATION_STATUS.VERIFIED
  );

  const { transientDetails, clearTempDetails, onSave } = useProfileLogic();
  const [modalShowing, setModalShowing] = useState(false);
  const history = useHistory();
  const isUsa = useIsUSAUser();
  const [isSaving, setIsSaving] = useState(false);
  const { inform } = useDialog();

  const isStudent = useIsAStudentByName(transientDetails?.profession?.label);

  const saveAction = async () => {
    const results = await onSave(transientDetails);
    if (results) {
      clearTempDetails();
    }
    return results;
  };

  /** ********************************* FUNCTIONS *************************************/
  const onPostVerification = useCallback(async () => {
    await inform({
      title: i18n.t(
        "Settings.Profile.AccountDetails.postVerificationModal.title"
      ),
      body: (
        <p className="text-16">
          {i18n.t(
            "Settings.Profile.AccountDetails.postVerificationModal.description"
          )}
        </p>
      ),
      primaryButtonText: i18n.t("common.confirm"),
      extra: {
        icon: "verification_icon",
        iconSize: 50,
        showCloseButton: false
      }
    });

    history.replace(getUrl(SETTINGS_PROFILE));
  }, [inform, history]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (!modalShowing && showModal) {
      onPostVerification();
      setModalShowing(true);
      setIsSaving(false);
    }
  }, [showModal, onPostVerification]);

  useEffect(() => {
    trackSettingsVerificationEntry();
  }, []);

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Figure1Layout topHeader={<></>} footer={<AppPrompt />}>
        <Figure1Page1Col
          isChildPage={true}
          mainContent={
            <>
              {isUsa && (
                <>
                  <NPIForm
                    isSaving={isSaving}
                    setIsSaving={setIsSaving}
                    onSave={saveAction}
                  />
                  <F1HorizontalDivider className="w-50" color="light-gray">
                    <span className="color-battleship-gray text-16">
                      {i18n.t(
                        "RegistrationScreens.verificationPage.v2.divider"
                      )}
                    </span>
                  </F1HorizontalDivider>
                </>
              )}
              <PhotoUploadForm
                isSaving={isSaving}
                setIsSaving={setIsSaving}
                onSave={saveAction}
              />
              {isStudent && <StudentEmailSignupCTA onSave={saveAction} />}
            </>
          }
        />
      </Figure1Layout>
    </>
  );
};

export default ProfileVerificationSettings;
