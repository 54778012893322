import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { toggleExternalLinkModal } from "../actions/global.actions";
import AppConfig from "../AppConfig";
import { StaticStrings } from "../constants/static-string-constants";

const ACCEPTED_LINKS = [
  StaticStrings.fig1,
  StaticStrings.npiRegistry,
  StaticStrings.androidStoreUrl,
  StaticStrings.iOSStoreUrl
];

const useExternalLinkHook = () => {
  const dispatch = useDispatch();

  const getLinkTarget = (e) => {
    let isChild = null;
    if (e?.path) {
      for (let i = 0; i < e.path.length; i++) {
        const target = e.path[i];
        if (target.tagName === "A") {
          isChild = target;
          break;
        }
      }
    } else if (e?.target?.tagName === "A") {
      isChild = e.target;
    }
    return isChild;
  };

  const interruptLink = (e) => {
    const linkTarget = getLinkTarget(e);

    if (
      !linkTarget ||
      !linkTarget?.href ||
      linkTarget?.href.includes(AppConfig.appSchema)
    )
      return;

    const targetUrl = new URL(linkTarget.href);
    const currentPage = new URL(window.location.href);

    if (
      currentPage.host !== targetUrl.host &&
      !ACCEPTED_LINKS.includes(targetUrl?.origin) &&
      !linkTarget.dataset?.bypassBlock
    ) {
      e?.preventDefault();
      e?.stopPropagation();
      dispatch(toggleExternalLinkModal(true, targetUrl));
      return false;
    }
  };

  useEffect(() => {
    window.addEventListener("click", interruptLink, { capture: true });

    return () => {
      window.removeEventListener("click", interruptLink);
    };
  }, []);
};

export default useExternalLinkHook;
