import React from "react";
import { Card } from "reactstrap";
import DiscoverGroupsImg from "../../assets/images/discover_groups.svg";
import CustomIcon from "../custom-icon/CustomIcon";
import i18n from "../../utils/i18n";
import F1Badge from "../common/F1Badge";
import { StaticStrings } from "../../constants/static-string-constants";
import { trackDiscoverPublicGroupsClick } from "../../actions/metrics.actions";
import { DISCOVER_PUBLIC_GROUPS_LOCATIONS } from "../../metrics/constants.metrics";

const DiscoverGroupsCard = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/
  const onClickDiscover = () => {
    trackDiscoverPublicGroupsClick(
      DISCOVER_PUBLIC_GROUPS_LOCATIONS.LEFT_NAVIGATION
    );
  };
  /** ********************************** RENDER ***************************************/

  return (
    <Card className="mt-3 px-0 py-2 border-outline-gray">
      <h3 className="font-weight-bold text-12 px-2 mb-2 d-flex justify-content-between align-items-center">
        <span className="text-uppercase d-flex align-items-center">
          <img src={DiscoverGroupsImg} alt="" className="mr-2" />
          {i18n.t("DiscoverGroupsCard.title")}
        </span>
        <F1Badge
          pill={false}
          textColor="white"
          color="battleship-gray"
          name={i18n.t("common.beta")}
          className="text-uppercase text-10"
        />
      </h3>
      <hr style={{ borderTopWidth: "2px" }} className="mt-0 mb-2" />
      <div className="m-0 px-2">
        <p className="mb-2 text-12 line-height-15 text-muted">
          {i18n.t("DiscoverGroupsCard.description")}
        </p>
        <p className="mb-1">
          <a
            data-bypass-block={true}
            href={StaticStrings.discoverGroups}
            target="_blank"
            rel="noopener noreferrer"
            onClick={onClickDiscover}>
            <>
              {i18n.t("DiscoverGroupsCard.discoverNow")}
              <CustomIcon icon="drawer_draft_chevron" size={12} />
            </>
          </a>
        </p>
      </div>
    </Card>
  );
};

export default DiscoverGroupsCard;
