/** @format */

import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain";

const accountReducer = (
  baseState = InitialReducerStates.ACCOUNT_INITIAL_STATE,
  action
) => {
  const state = { ...BrainReducers.AccountReducer(baseState, action) };

  switch (action.type) {
    default:
      return state;
  }
};

export default accountReducer;
