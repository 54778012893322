/** @format */

export const METRICS_EVENT_NAME = {
  CASE: {
    FEED: "CASE.FEED",
    DETAIL: "CASE.DETAIL"
  },
  SCREEN: {
    REGISTRATION: {
      INTRO: "REGISTRATION.INTRO",
      SIGN_UP: "REGISTRATION.SIGN_UP",
      SIGN_IN: "REGISTRATION.SIGN_IN",
      FORGET_PASSWORD: "REGISTRATION.FORGET_PASSWORD",
      PRIVACY_MODAL: "REGISTRATION.PRIVACY_MODAL",
      PRIVACY_DETAILS: "REGISTRATION.PRIVACY_DETAILS"
    },
    VERIFICATION: {
      SELECT_METHOD: "VERIFICATION.SELECT_METHOD",
      NPI: "VERIFICATION.NPI",
      MEDICAL_LICENSE: "VERIFICATION.MEDICAL_LICENSE",
      PHOTO: "VERIFICATION.PHOTO",
      EMAIL: "VERIFICATION.EMAIL",
      NOTIFICATION_CHOICE: "VERIFICATION.NOTIFICATION_CHOICE"
    },
    ONBOARDING: {
      CREATE_ACCOUNT: "ONBOARDING.CREATE_ACCOUNT",
      CHOOSE_TOPIC: "ONBOARDING.CHOOSE_TOPIC"
    },
    UPLOAD: {
      CHOOSE_CASE_TYPE: "UPLOAD.CHOOSE_CASE_TYPE",
      COMPOSE_CASE: "UPLOAD.COMPOSE_CASE",
      CHOOSE_SPECIALTIES: "UPLOAD.CHOOSE_SPECIALTIES",
      WAITING_APPROVAL: "UPLOAD.WAITING_APPROVAL",
      DRAFT_LIST: "UPLOAD.DRAFT_LIST",
      UPLOAD_SELECT_CLASSIFICATION_ENTRY: "Upload Select Classification entry",
      UPLOAD_CREATE_ENTRY: "Upload Create entry"
    },
    CHANNELS: {
      FEED_FILTER: "CHANNELS.FEED_FILTER",
      MANAGE_CHANNELS: "CHANNELS.MANAGE_CHANNELS",
      ENTRY: "Manage channels entry"
    },
    NETWORK: {
      ENTRY: "Following Followers entry"
    },
    ACTIVITY_CENTER: {
      ENTRY: "Activity center entry"
    },
    SAVED_CASES: {
      ENTRY: "Saved cases entry"
    },
    DRAFTS: {
      ENTRY: "Drafts entry"
    },
    SETTINGS: {
      INTERESTS: {
        ENTRY: "Settings manage interests entry"
      },
      PROFILE: {
        ENTRY: "Settings Profile entry",
        EDIT: "Settings Profile edit",
        EDIT_PROFESSION_CHANGE: "Profession change request active",
        SAVE: "Settings Profile save",
        VERIFY: "Settings Profile save verify",
        VERIFY_ENTRY: "Profession edit verification entry",
        VERIFY_PHOTO: "Profession edit Verify by Photo",
        VERIFY_NPI: "Profession edit Verify by NPI",
        SECTIONS: {
          ACCOUNT_DETAILS: "Account details",
          SECONDARY_SPECIALTIES: "Secondary specialties",
          PRACTICE_LOCATION: "Practice location",
          PRACTICE_HOSPITAL: "Practice hospital",
          BIOGRAPHY: "Biography"
        },
        PROPS: {
          PRIOR_GRAD_DATE: "Prior grad date",
          NEW_GRAD_DATE: "New grad date"
        }
      },
      MANAGE_ACCOUNT: {
        ENTRY: "Settings manage account entry",
        SIGN_OUT: "Settings sign out"
      },
      RESET_PASSWORD: {
        ENTRY: "Settings reset password entry",
        SEND_RESET_LINK_CLICK: "Settings reset password click send link"
      },
      DELETE_ACCOUNT: {
        ENTRY: "Settings delete account entry",
        CONTINUE: "Settings delete account continue"
      },
      DELETE_ACCOUNT_REASONS: {
        ENTRY: "Settings delete account reasons entry",
        DELETE: "Settings delete account"
      }
    },
    GROUPS: {
      GROUP_TAB_ENTRY: "Group tab entry",
      GROUP_NAME_CLICK: "Group name click",
      GROUP_ELLIPSES: "Group ellipses",
      GROUP_VIEW_MEMBERS_ENTRY: "Group view members entry",
      GROUP_DESCRIPTION_ENTRY: "Group description entry",
      GROUP_LEAVE_ENTRY: "Group leave entry",
      GROUP_LEAVE: "Group leave",
      GROUP_CREATION_ENTRY: "Group creation entry",
      GROUP_CREATE: "Group create",
      GROUP_INVITE_ENTRY: "Group invite entry",
      GROUP_SKIP_INVITE: "Group skip invite",
      GROUP_COPY_INVITE_LINK: "Group copy invite link",
      GROUP_INVITE_F1_MEMBERS_ENTRY: "Group Invite Figure 1 members entry",
      GROUP_INVITE_F1_MEMBERS_SEND: "Group Invite Figure 1 members send"
    },
    FORGOT_PASSWORD: {
      FORGOT_PASSWORD_ENTRY: "Forgot Password entry",
      FORGOT_PASSWORD_LOGIN_LINK_ENTRY: "Forgot Password Login link entry",
      FORGOT_PASSWORD_RESET_PASSWORD_ENTRY:
        "Forgot Password Reset password entry"
    }
  },
  TAP: {
    UPLOAD: {
      SUBMIT: "UPLOAD.SUBMIT",
      SAVE_DRAFT: "UPLOAD.SAVE_DRAFT",
      ADD_MEDIA: "UPLOAD.ADD_MEDIA",
      USE_CAMERA: "USE_CAMERA",
      USE_GALLERY: "USE_GALLERY",
      REMOVE_IMAGE: "UPLOAD.REMOVE_IMAGE",
      EDIT_IMAGE: "UPLOAD.EDIT_IMAGE"
    },
    CASE: {
      SUBMIT_REACTION: "CASE.SUBMIT_REACTION",
      SHARE: "CASE.SHARE",
      SAVE: "CASE.SAVE",
      REPORT: "CASE.REPORT",
      COMMENT: "CASE.COMMENT",
      LONG_PRESS_BACK: "LONG_PRESS_BACK"
    },
    PROFILE: {
      FOLLOW: "PROFILE.FOLLOW",
      EDIT_AVATAR: "PROFILE.EDIT_AVATAR",
      START_EDIT_INFO: "PROFILE.START_EDIT_INFO",
      SUBMIT_EDIT_INFO: "PROFILE.SUBMIT_EDIT_INFO",
      CANCEL_EDIT_INFO: "PROFILE.CANCEL_EDIT_INFO",
      EDIT_NAME: "PROFILE.EDIT_NAME",
      EDIT_SPECIALTIES: "PROFILE.EDIT_SPECIALTIES",
      EDIT_LOCATION: "PROFILE.EDIT_LOCATION",
      EDIT_HOSPITAL: "PROFILE.EDIT_HOSPITAL",
      EDIT_BIO: "PROFILE.EDIT_BIO",
      EDIT_ABOUT: "PROFILE.EDIT_ABOUT",
      EDIT_EDUCATION: "PROFILE.EDIT_EDUCATION",
      EDIT_EXPERIENCE: "PROFILE.EDIT_EXPERIENCE",
      EDIT_AFFILIATION: "PROFILE.EDIT_AFFILIATION",
      EDIT_SUBSPECIALTIES: "PROFILE.EDIT_SUBSPECIALTIES",
      EDIT_PRACTICE_AREA: "PROFILE.EDIT_PRACTICE_AREA"
    },
    SEARCH: {
      FEED: "SEARCH.FEED",
      GENERAL: "SEARCH.GENERAL"
    },
    CHANNELS: {
      TOGGLE_FOLLOW: "CHANNELS.TOGGLE_FOLLOW"
    }
  },
  SIGN_IN: "Sign In",
  SIGN_IN_SUCCESS: "Sign In Success",
  AUTO_SIGN_IN: "Auto Sign In",
  SIGN_OUT: "Sign Out",
  SIGN_OUT_LOGGED_OUT: "Sign Out (logged out)",
  FEED_VIEW: "Feed View",
  FEED_VIEW_ENTRY: "Feed View Entry",
  REACTION: "Reaction",
  COMMENT_CASE: "Comment Case",
  SHARE_CASE: "Share Case",
  COPY_LINK: "Copy Link",
  EDIT_COMMENT_CASE: "Edit Comment Case",
  SAVE_CASE: "Save Case",
  CASE_UPDATE: "Case Update Added",
  SWIPE_PHOTO: "Swipe Photos",
  SWITCH_FEED: "Switch Feed",
  UPLOAD: {
    START: "Upload Start",
    OPTIONS: "Case Upload (Options)",
    DETAILS: "Case Upload (Details)",
    DRAFT: "Save as Draft",
    SUBMISSION: "Upload Submission",
    LEARN_MORE_CLICK: "Upload Learn more click",
    COMMUNITY_GUIDELINES_CLICK: "Upload Community Guidelines click"
  },
  SEARCH: "Search",
  PROFILE: {
    VISIT: "Visit Profile",
    LINK_TAP: "Tap Profile Link",
    FOLLOW: "Follow User",
    Unfollow: "Unfollow User",
    EDIT_ABOUT: "Edit About",
    EDIT_PROFILE: "Edit Profile",
    MANAGE_INTERESTS: "Manage Interests",
    CLICK: "Profile Click",
    FOLLOW_CLICK: "Follow Click"
  },
  VERIFIED: "Verified",
  VERIFY_NPI: "Verify by NPI",
  VERIFY_LICENSE: "Verify by License",
  VERIFY_PHOTO: "Verify by Photo",
  VERIFY_EMAIL: "Verify by Institutional Email",
  ACCOUNT_CREATE: "Onboarding Account Creation",
  ACCOUNT_DETAILS: "Onboarding Account Details",
  ONBOARDING_PRIVACY: "Onboarding Privacy",
  ONBOARDING_CHANNELS: "Onboarding Channels",
  SWIPE_ONBOARDING: "Swipe Onboarding",
  SKIP_ONBOARDING: "Skip Onboarding",
  ERROR: "Error",
  FORGOT_PASSWORD: "Forgot Password",
  FORGOT_PASSWORD_LOGIN_LINK_REQUEST: "Forgot Password Login link request",
  FORGOT_PASSWORD_RESET_PASSWORD_REQUEST:
    "Forgot Password Reset password request",
  CME_CENTRE_VIEW: "CME Centre View",
  CME_CENTRE_TAP: "CME Centre Tap",
  TACTIC_LINK: "Tactic Link",
  TACTIC_SLIDE_VIEW: "Tactic Slide View",
  QUESTION_ANSWERED: "Question Answered",
  COMPLETE_QUIZ: "Completed Quiz Score",
  CLAIM_SCORE: "Claim Credit",
  DOWNLOAD_CERTIFICATE: "Download Certificate",
  UNGATED: "Ungated Banner",
  FOLLOW_SCREEN_ENTRY: "Following Followers entry",
  PROMO: {
    SERVED: "Promo Card Served",
    DISMISSED: "Promo Card Dismissed",
    CLICKED: "Promo Card Clicked"
  },
  FILTERS: {
    ENTRY: "Feed Filters entry",
    APPLY: "Apply Filters"
  },
  PREVIEW_FEED: {
    TRENDING_CASES_FEED_VIEW: "Trending Cases View",
    TRENDING_CASES_FEED_VIEW_ENTRY: "Trending Cases View Entry",
    NEW_CASES_FEED_VIEW: "New Cases View",
    NEW_CASES_FEED_VIEW_ENTRY: "New Cases View Entry",
    PREVIEW_FEED_ITEM_CLICKED: "Preview Feed item clicked"
  },
  INVITE_COLLEAGUES: {
    START: "Invite Colleague (Start)",
    ADD_EMAIL: "Add Colleague Email",
    REMOVE_EMAIL: "Remove Colleague Email",
    SUBMISSION: "Invite Colleague (Submission)"
  },
  REGISTRATION: "Onboarding Account Creation",
  REGISTRATION_SUCCESS: "Onboarding Account Creation Success",
  NPI_ACCOUNT_DETAIL_ENTRY: "NPI Account details entry",
  NPI_ACCOUNT_DETAIL_EXIT: "NPI Account details exit",
  NON_NPI_ACCOUNT_DETAIL_ENTRY: "Onboarding Non-NPI Account details entry",
  NON_NPI_ACCOUNT_DETAIL: "Onboarding Non-NPI Account details",
  SELECT_USERNAME_EXIT: "Onboarding Username",
  ENTRY: " entry",
  NPI_FAST_TRACK: "Fast Track",
  LEGACY_WELCOME: "Onboarding Legacy Welcome",
  CONFIRM_COUNTRY: "Onboarding Confirm Country",
  ONBOARDING_HELP_MODAL: "Onboarding Help Modal",
  ONBOARDING_CONTACT_SUPPRT: "Onboarding Contact Support",
  ONBOARDING_WHO_CAN_JOIN_MODAL: "Who can join Modal",
  GET_APP: "Get App",
  LOGIN_PAGE: "Account sign in",
  CLICKED_LITERATURE: "Clicked literature",
  LOGO: "Logo click",
  CLICKED_TRANSLATION: "Clicked Translation",
  ACTIVITY_CENTER_CLICK: "Activity center notification click",
  ACTIVITY_CENTER_MARK_ALL_READ_CLICK: "Activity center mark all as read",
  CLICKED_FRAME_SWIPE: "Clicked Frame Swipe",
  CLICKED_DIAGNOSIS: "Diagnosis click",
  CASE_CME: "Case CME",
  COMMENT_OR_REPLY: {
    ACCEPTED_ANSWER: {
      SELECTED_ACCEPTED_ANSWER: "Selected Accepted Answer",
      REMOVED_ACCEPTED_ANSWER: "Removed Accepted Answer",
      REPLACED_ACCEPTED_ANSWER: "Replaced Accepted Answer",
      CLICKED_ACCEPTED_ANSWER: "Clicked Accepted Answer"
    }
  },
  PUBLIC_GROUPS_CLICK: "Public groups click",
  VIDEO: {
    DURATION: "Video Duration",
    PLAY: "Video Play",
    PAUSE: "Video Pause",
    STOP: "Video Stop"
  }
};

export const METRICS_EVENT_PROPERTY = {
  BUTTON: "Button",
  CASE: {
    TITLE: "Case Title",
    CAPTION: "Case Caption",
    CONTENT_TITLE: "Content Title",
    UUID: "Case UUID",
    SOURCE: "Source",
    LABELS: "Labels",
    LABEL: "Label",
    POSITION: "Position",
    PHOTO_COUNT: "Media Count",
    COMMENT_COUNT: "Comment Count",
    REACTION_COUNT: "Reaction Count",
    CASE_TYPE: "Case Type",
    CONTENT_TYPE: "Content Type",
    DURATION: "Duration",
    CAMPAIGN_UUID: "Campaign UUID",
    DETAIL_VIEW_COUNT: "Detail View Case Count",
    REACTION_NAME: "Reaction Name",
    CONTEXT: "Context",
    COMMENT_LENGTH: "Character Count",
    CASE_OWNER: "Case Owner",
    SHARED_CASE_COUNT: "Shared Case Count",
    VIA: "Via",
    PHOTO_MAX_POS: "Photo Max Position",
    PHOTO_CURRENT_POS: "Photo Current Position",
    SECTION: "Section",
    DISPLAY_ORDER: "Display Order",
    QUESTION_OPTION_ID: "Option UUID",
    ANSWER: "Answer",
    QUESTION_TYPE: "Question Type",
    CORRECT_ANSWER: "Correct Answer",
    LINK: "Link",
    SCORE: "Score",
    FILTERS: "filters",
    UNGATED: "Ungated",
    CTA: "CTA",
    TYPE: "Type",
    IS_ANONYMOUS: "Anonymous Case",
    DIAGNOSIS: "Diagnosis",
    UNRESOLVED: "Unresolved",
    CASE_CLASSIFICATION: "caseClassification",
    CASE_SPECIALTIES: "caseSpecialties"
  },
  GROUP: {
    GROUP_UUID: "Group UUID",
    GROUP_NAME: "Group name",
    GROUP_DESCRIPTION: "Group description",
    PUBLIC_GROUP: "Public group"
  },
  AUTHOR: {
    UUID: "Author UUID",
    USERNAME: "Author Username",
    SPECIALTY: "Author Specialty",
    HAS_AVATAR: "Has Avatar"
  },
  ON: "On",
  CONTENT_UUID: "ContentUuid",
  FEED_VIEW_COUNT: "Feed View Case Count",
  FROM: "From",
  TARGET: "Target",
  CONTEXT: "Context",
  SUB_CHANNELS: "Subscribed channels",
  UNSUB_CHANNELS: "Unsubscribed channels",
  UPLOAD: {
    SHARE_OPTION: "Sharing Option",
    MEDIA_COUNT: "Media Count",
    TITLE_COUNT: "Title Char Count",
    CAPTION_COUNT: "Caption Char Count",
    TITLE: "Title",
    CAPTION: "Caption",
    CASE_STATUS: "Case Status",
    CASE_LABELS: "Case Labels",
    DRAFT_UID: "Draft ID",
    SPECIALTIES: "Specialties",
    SUBSPECIALTIES: "Subspecialties",
    IS_ANONYMOUS: "Anonymous",
    CASE_CLASSIFICATION: "caseClassification"
  },
  SEARCH_TERM: "Search Term",
  NO_OF_SEARCHES: "# of Searches",
  PROFILE: {
    USERNAME: "User Username",
    UUID: "User UUID",
    TYPE: "User Type",
    SPECIALTY: "Specialty",
    HAS_AVATAR: "Has Avatar",
    CONTENT_COUNT: "Cases and comments",
    LINK: "Link",
    INTERESTS: "Interests"
  },
  FIELD: "Field",
  NOTIFICATION: "Notification",
  NOTIFICATION_UUID: "Notification UUID",
  NOTIFICATION_TYPE: "Notification Type",
  NOTIFICATION_STATE: "Notification State",
  DEGREE_TYPE: "Degree Type",
  TYPE: "Type",
  SUPER: {
    PLATFORM: "PLATFORM",
    VERSION: "VERSION",
    NOTIFIED: "Notified"
  },
  ERROR_MSG: "Error Message",
  ACTION: "Action",
  ORIGINAL_ACTION: "Original Action",
  UNGATED: "Ungated",
  NETWORK: {
    UUID: "User UUID",
    CURRENT_USER: "Viewing own network"
  },
  FILTERS: "Filters",
  INVITE_COLLEAGUES: {
    HAS_EMAIL: "hasEmail",
    EMAILS: "emails"
  },
  GROUP_UUID: "Group UUID",
  UNGATED_STATE: "Ungated user state",
  MODAL: "modal",
  SCIENTIFIC_LITERATURE: {
    ARTICLE_ID: "article id",
    URL: "url",
    POSITION: "position",
    CASE_UUID: "case uuid"
  },
  LOCATION: "Location",
  TRANSLATION_APPLICABLE: "translation applicable",
  USER_LANGUAGE: "user language",
  TRANSLATION: {
    TRANSLATION_APPLICABLE: "translation applicable",
    USER_LANGUAGE: "user language",
    CASE_UUID: "case uuid",
    VIEW_PRE_CLICK: "view pre click",
    SOURCE: "source"
  },
  FRAME_SWIPE: {
    CASE_UUID: "case uuid",
    ORIENTATION: "orientation",
    ACTION: "action",
    APPLICABLE: "frame swipe applicable"
  },
  DIAGNOSIS: "Diagnosis",
  CASE_CME: {
    CTA: "Earn Case CME click",
    FRONT_MATTER_ENTRY: "Case CME Front matter entry",
    QUESTION_ENTRY: "Case CME Questions entry",
    QUESTION_NEXT: "Case CME Questions Next CTA",
    QUESTION_SUBMISSION: "Case CME Question submission",
    QUESTION_UUID: "Question UUID",
    QUESTION_INDEX: "Question Index",
    COMPLETED: "Completed"
  },
  DELETE_ACCOUNT: {
    REASONS: {
      NOT_HCP: "Reason not HCP",
      VERIFICATION: "Reason verification",
      NOT_USING_APP: "Reason not using app",
      BREAK: "Reason break",
      TOO_MANY_EMAILS_NOTIFICATIONS: "Reason too many emails notifications",
      PRIVACY_CONCERNS: "Reason privacy concerns",
      OTHER: "Reason other"
    }
  },
  ANONYMOUS: "Anonymous",
  ACCEPTED_ANSWER: "accepted answer",
  VIDEO: {
    AUTO_PLAY: "Auto play",
    AUTO_STOP: "Auto stop",
    TIMESTAMP: "Playtime time stamp",
    DURATION: "Duration"
  }
};

export const METRICS_EVENT_KEY = {
  EVENT_NAME: "EventName",
  VERSION: "VERSION",
  TAP_NAME: "TAP_NAME",
  VIEW_NAME: "VIEW_NAME",
  CASE_UUID: "CASE_UUID",
  CASE_UUID_2: "caseUuid",
  COMMENT_UUID: "commentUuid",
  TIME_SPENT: "TIME_SPENT",
  ENABLED: "ENABLED",
  FEED_NAME: "FEED_NAME",
  FEED_UUID: "FEED_UUID",
  FROM: "FROM",
  ACTION: "ACTION",
  CAMPAIGN_ID: "CampaignUuid",
  CONTEXT: "Context",
  DRAFT_ID: "DRAFT_ID",
  REASON: "REASON",
  CONTENT_UUID: "CONTENT_UUID",
  INDEX: "Index",

  // CASE View
  POSITION: "POSITION",
  EXTRA: "EXTRA_INFO",
  REACTION_NAME: "REACTION_NAME",
  QUESTION_OPTION_ID: "QUESTION_OPTION_ID",

  // Profile
  OTHER_USE_UUID: "OTHER_USE_UUID",
  EDITED_FIELDS: "EDITED_FIELDS",

  // SEARCH
  SEARCH_TERM: "SEARCH_TERM",

  INPUT: "Input"
};

export const DETAIL_ORIGIN = {
  SIMILAR_CASE: "SIMILAR_CASE",
  PROFILE: "PROFILE",
  NOTIFICATION: "NOTIFICATION",
  SEARCH: "SEARCH"
};

export const FEED_ORIGIN = {
  FEED: "QuickSelectionDropDown",
  HAMBURGER: "Hamburger"
};

export const CASE_CARD_ORIGIN = {
  FEED: "FEED",
  DETAIL: "DETAIL"
};

export const METRICS_EVENT_TYPE = {
  VIEW: "View",
  TAP: "Tap"
};

export const UPLOAD_CASE_ORIGIN = {
  FEED: "FEED",
  PLUS_SIGN: "PLUS_SIGN",
  HAMBURGER: "Hamburger",
  PROFILE: "PROFILE",
  NOTIFICATION_SCREEN: "NOTIFICATION_SCREEN",
  END_OF_FEED: "End of Feed Card",
  GROUP_FEED: "Group Feed"
};

export const UPLOAD_CASE_OPTIONS = {
  SHARE: "Share a case",
  PAGE: "Page a specialist",
  LINK: "How we protect patient privacy"
};

export const UPLOAD_CASE_TYPES = {
  NORMAL: "Normal case",
  PAGING: "Paging case"
};

export const SEARCH_TYPE = {
  FEED: "Feed",
  SUGGESTION: "Suggestion",
  SEARCH_FEED: "Term on Feed",
  DIRECT: "Direct search",
  SEARCH_SCREEN: "In Search Screen"
};

export const METRICS_USER_PROPERTY = {
  NAME: "$name",
  EMAIL: "$email",
  FIRST_NAME: "$first_name",
  LAST_NAME: "$last_name",
  USERNAME: "Username",
  BUTTON: "Button",
  PROFESSION: "Profession",
  METHOD: "Method",
  NPI: "NPI Number",
  SCHOOL: "School",
  GRADUATION_YEAR: "Year of Graduation",
  GRADUATION_DATE: "Graduation date",
  LICENSE: "Medical License Number",
  COUNTRY: "Country",
  INSTITUTION_EMAIL: "Institution Email",
  SPECIALTY: "Specialty",
  SUBSPECIALTY: "Subspecialty",
  INTERESTS: "Interests",
  CREATED: "Account Created",
  CHANNELS: "Subscribed Channels",
  DETAIL_VIEW_COUNT: "Detail View Case Count",
  LEGACY: "Legacy",
  VERIFIED: "Verified",
  HCP: "HCP",
  MAPPED_SPECIALTY: "Mapped Specialty",
  MAPPED_PROFESSION: "Mapped Profession Path",
  ONBOARDING_SCREEN_SWIPE_NUMBER: "Onboarding Screen Swipe Number",
  ONBOARDING_SCREEN_SKIP_NUMBER: "Onboarding Screen Skip Number",
  ERROR_MESSAGE: "Error Message",
  LOGIN: "Login",
  GROUP_UUID: "Group_Uuid"
};

export const METRICS_CATEGORY = {
  VIEW_WELCOME_SCREEN: "ViewWelcomeScreen",
  SIGN_IN: "SignIn",
  CREATE_ACCOUNT: "CreateAccount",
  WHATS_NEW: "WhatsNew",
  SWITCH_FEED: "SwitchFeed",
  MANAGE_CHANNELS: "ManageChannels",
  CASE_FEED_VIEW: "CaseFeedView",
  CASE_REACTION: "CaseReaction",
  CASE_DETAIL_VIEW: "Case Detail View",
  CASE_DETAIL_VIEW_ENTRY: "CaseDetailViewEntry",
  SAVED_CASES: "SavedCases",
  PROFILE: "Profile",
  ACCOUNT_SETTINGS: "AccountSettings",
  ADD_COMMENT: "AddComment",
  UPLOAD_CASE: "UploadCase",
  REPORT_CASE: "Report Case",
  COMMENT_REPORTED: "CommentReported",
  SEARCH: "Search",
  FOLLOW_USER: "FollowUser",
  ACTIVITY_NOTIFICATION: "ActivityNotification",
  QUIZ: "Quiz",
  VIEW_ISI: "ViewISI",
  CME: "CME",
  EDIT_INTERESTS: "EditInterests",
  EDIT_PROFILE: "EditProfile",
  END_OF_FEED: "EndOfFeed",
  PROMO_CARD: "PromoCard",
  BUTTON_TAP: "BUTTON_TAP",
  SLIDE_VIEW: "SLIDE_VIEW"
};

export const UNGATED_ACTIONS = {
  ORIGIN: {
    GENERIC_MODAL: "UNGATED.GENERIC_MODAL",
    REACT_MODAL: "UNGATED.REACT_MODAL",
    PROFILE_MODAL: "UNGATED.PROFILE_MODAL",
    CERTIFICATE_MODAL: "UNGATED.CERTIFICATE_MODAL",
    COMMENT_MODAL: "UNGATED.COMMENT_MODAL",
    EXPANDED_BANNER: "UNGATED.EXPANDED_BANNER",
    COLLAPSED_BANNER: "UNGATED.COLLAPSED_BANNER",
    COMMENT_BLOCK: "UNGATED.COMMENT_BLOCK"
  },
  CTA: {
    LOGIN: "Ungated Login",
    SIGN_UP: "Ungated Sign up"
  },
  CTA_LOCATION: {
    TOP_BAR: "Top bar",
    BLOCKED_IMAGE: "Blocked image",
    LOGIN_COMMENT: "Login to comment",
    SIGNUP_COMMENT: "Sign up to comment",
    UNLIMITED_ACCESS: "Unlimited access",
    SIMILAR_CASES: "Ungated Similar Cases",
    TRENDING: "Ungated Trending",
    JOIN: "Ungated Join Card"
  },
  BLOCKED_STATUS: {
    NEW_NO_VIEWS: "New user unblocked image",
    NEW_LIMIT_HIT_IN_SESSION: "New user keep exploring blocked image",
    NEW_LIMIT_HIT: "New user see full case blocked image",
    EXISTING_USER: "Existing user",
    EXISTING_USER_QUIZ: "Existing user quiz blocked image",
    NEW_USER_QUIZ: "New user quiz blocked image",
    EXISTING_USER_POLL: "Existing user poll blocked image",
    NEW_USER_POLL: "New user poll blocked image"
  },
  ACTION: {
    SIGN_IN: "UNGATED.SIGN_IN",
    SIGN_UP: "UNGATED.SIGN_UP"
  },
  ORIGINAL_ACTIONS: {
    REACTION: "Reaction",
    COMMENT_DROPDOWN: "Comment dropdown",
    COMMENT_REPLY: "Comment reply",
    COMMENT_POST: "Comment post",
    COMMENT_AT_REPLY: "Comment @reply",

    CME_CLAIM_CERT: "Claim your credit",
    TOPBAR_NAV_LOGO: "Top nav logo",
    TOPBAR_NAV_MENU: "Top nav menu",
    TOPBAR_NAV_SEARCH: "Top nav search",
    TOPBAR_NAV_AVATAR: "Top nav avatar",

    AUTHOR_AVATAR: "Author avatar",
    AUTHOR_NAME: "Author username",
    AUTHOR_FOLLOW: "Author follow",

    AVATAR_NAME: "Avatar name",
    AVATAR_PICTURE: "Avatar picture",

    SIMILAR_CASE: "Similar Case"
  }
};

export const TRANSLATION_SOURCES = {
  FEED: "feed",
  CAPTION: "caption",
  COMMENT: "comment",
  REPLY: "reply"
};

export const TRANSLATION_VIEWS = {
  ORIGINAL: "original",
  TRANSLATED: "translated"
};

export const SHARE_LOCATIONS = {
  TOP_BAR: "top bar",
  RESULTS: "results"
};

export const SHARE_CONTEXT = {
  FEED: "feed",
  DETAILED_VIEW: "detailed view",
  SLIDE_VIEW: "slide view"
};

export const SHARE_CASE_TYPE = {
  STATIC: "static",
  POLL: "poll",
  QUIZ: "quiz",
  POLL_SERIES: "poll series",
  QUIZ_SERIES: "quiz series",
  CLINICAL_MOMENTS: "clinical moments",
  CME: "cme"
};

export const FRAME_SWIPE_ORIENTATION = {
  HORIZONTAL: "horizontal",
  VERTICAL: "vertical"
};

export const FRAME_SWIPE_ACTION = {
  SELECT: "select",
  DESELECT: "deselect"
};

export const DISCOVER_PUBLIC_GROUPS_LOCATIONS = {
  LEFT_NAVIGATION: "Web left navigation",
  EMPTY_STATE: "Web empty state"
};
