/** @format */

import React from "react";
import { Carousel, CarouselItem } from "reactstrap";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import F1Spacer from "../../../components/common/F1Spacer";
import CME_SECTION from "../../../constants/cme-section";
import { CaseDataProp } from "../../../prop-types/ContentProp";
import CMNormalSlide from "./CMNormalSlide";
import CMEModalSection from "./CMEModalSection";
import useCarouselNavigation from "../../../hooks/useCarouselNavigation";
import CMEModalButton from "./CMEModalFooterButton";
import useTrackSlideView from "../../../hooks/useTrackSlideView";

const CMEModalSectionFrontMatter = ({
  caseContent,
  onExit,
  navigateToSection,
  initialIndex,
  onShareClick
}) => {
  /****************************** Config **************************************/
  const items = caseContent?.[CME_SECTION.FRONT_MATTER] || [];

  const { activeIndex, next, previous, onExiting, onExited } =
    useCarouselNavigation({ items, currentIndex: initialIndex });

  const { onNext, onPrev, sendMetrics } = useTrackSlideView({
    items,
    activeIndex,
    next,
    previous
  });

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  const start = () => {
    const hasPreTest = !isEmpty(caseContent?.[CME_SECTION.PRE_TEST]);
    sendMetrics();

    if (hasPreTest) {
      navigateToSection(CME_SECTION.PRE_TEST);
    } else {
      navigateToSection(CME_SECTION.ACTIVITY);
    }
  };
  /****************************** Render **************************************/

  const slides = items.map((item, index) => {
    return (
      <CarouselItem onExiting={onExiting} onExited={onExited} key={index}>
        <F1Spacer className="sticky-top t-0 w-100 mx-0 mb-1 pt-4 bg-darkest opacity-90" />
        <CMNormalSlide content={item} />
      </CarouselItem>
    );
  });

  return (
    <CMEModalSection
      canShare={!caseContent.isSponsored}
      onExit={onExit}
      onShareClick={onShareClick}
      footer={
        <CMEModalButton block color="denim-blue" onClick={start}>
          {items[0]?.buttonText}
        </CMEModalButton>
      }>
      <Carousel
        className="pt-0 w-100"
        activeIndex={activeIndex}
        next={onNext}
        previous={onPrev}
        interval={false}>
        {slides}
      </Carousel>
    </CMEModalSection>
  );
};

CMEModalSectionFrontMatter.propTypes = {
  caseContent: CaseDataProp.isRequired,
  onExit: PropTypes.func.isRequired,
  navigateToSection: PropTypes.func.isRequired
};

export default CMEModalSectionFrontMatter;
