import React, { useEffect, useState } from "react";
import { Button, PopoverBody, UncontrolledPopover } from "reactstrap";
import TextareaAutosize from "react-textarea-autosize";
import { trim } from "lodash";
import i18n from "../../utils/i18n";
import PropTypes from "prop-types";
import { COMMENT_TYPES } from "../../constants/case-constants";
import classNames from "../../utils/class-names-utils";
import useTheme from "../theme/useTheme";
import THEMES from "../theme/Theme";
import useIsGated from "../../hooks/useIsGated";
import useGatedActionGate from "../../hooks/useGatedActionGate";
import GATED_MODAL_TYPE from "../gated-modal/GatedModalType";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import "./CommentInput.scss";
import LoadingButton from "../common/LoadingButton";
import useUserHasGroups from "../../hooks/useUserHasGroups";
import CustomIcon from "../custom-icon/CustomIcon";
import { useIsAnonymousCaseAuthor } from "../../hooks";

/**
 * Component mimicking comment text input and post button,
 * used to show gated modal.
 */
const FakeCommentInput = ({
  placeholderText = "",
  buttonText = "",
  onClick = () => {}
}) => {
  const { theme } = useTheme();

  const color = theme === THEMES.DARK ? "light-gray" : "cool-blue";
  const backgroundColor = theme === THEMES.DARK ? "black" : "white";

  return (
    <Button
      onClick={onClick}
      className={classNames(
        "fake-comment-input-container no-decoration-button w-100 border-light-gray bg-transparent",
        backgroundColor
      )}>
      <div className="d-flex justify-content-between align-items-center w-100">
        <p className="fake-text-input m-0 color-f1-gray">{placeholderText}</p>
        <p
          className={classNames("fake-post-button font-weight-500 m-0", color)}>
          {buttonText}
        </p>
      </div>
    </Button>
  );
};

FakeCommentInput.propTypes = {
  placeholderText: PropTypes.string,
  buttonText: PropTypes.string,
  onClick: PropTypes.func
};

const CommentInput = React.forwardRef(
  (
    {
      parentComment,
      onPost,
      borderRadius,
      commentType,
      loading,
      caseContent,
      onFocus = () => {},
      onCancelClick = () => {},
      existingText = "",
      isGroup = false,
      isMedicalCase = true
    },
    ref
  ) => {
    /** ********************************** CONFIG ***************************************/

    const [placeholderText, setPlaceholderText] = useState(null);
    const [buttonText, setButtonText] = useState(null);
    const [hasChanged, setHasChanged] = useState(false);
    const [text, setText] = useState(existingText);
    const { theme } = useTheme();
    const isGated = useIsGated();
    const editMode = commentType === COMMENT_TYPES.EDIT;
    const userHasGroups = useUserHasGroups();

    const isAnonymousCase = !!caseContent?.isAnonymous;
    const isAnonCaseAuthor = useIsAnonymousCaseAuthor(caseContent?.caseUuid);
    const anonymousCommenting = isAnonymousCase && isAnonCaseAuthor;
    /** ********************************** HOOKS ****************************************/

    useEffect(() => {
      if (commentType === COMMENT_TYPES.COMMENT) {
        setPlaceholderText(
          i18n.t(
            anonymousCommenting
              ? "comments.addAnonComment"
              : isGroup
              ? "comments.addGroupComment"
              : userHasGroups
              ? "comments.addPublicComment"
              : "comments.addComment"
          )
        );
        setButtonText(i18n.t("comments.postText"));
      } else if (commentType === COMMENT_TYPES.REPLY) {
        setPlaceholderText(
          i18n.t(
            anonymousCommenting
              ? "comments.addAnonComment"
              : isGroup
              ? "comments.addGroupReply"
              : userHasGroups
              ? "comments.addPublicReply"
              : "comments.addReply"
          )
        );
        setButtonText(i18n.t("comments.replyText"));
      } else if (editMode) {
        setPlaceholderText("");
        setButtonText(i18n.t("comments.editCommentButton"));
      }
    }, [commentType, editMode, isGroup, anonymousCommenting, userHasGroups]);
    /** ********************************* FUNCTIONS *************************************/

    const onChange = (e) => {
      setHasChanged(e.target.value !== existingText);
      setText(e.target.value);
    };

    const validatePost = async () => {
      if (trim(text).length > 0) {
        if (commentType !== COMMENT_TYPES.EDIT || (editMode && hasChanged)) {
          const success = await onPost({
            text,
            parentComment,
            isAnonComment: anonymousCommenting
          });
          if (success) {
            setText(editMode ? text : "");
          }
        }
      }
    };

    const gatedValidatePost = useGatedActionGate(
      validatePost,
      GATED_MODAL_TYPE.COMMENT,
      UNGATED_ACTIONS.ORIGINAL_ACTIONS.COMMENT_POST
    );
    const descriptionText = isMedicalCase
      ? "comments.anonymousCommenting.description"
      : "comments.anonymousCommenting.postDescription";

    /** ********************************** RENDER ***************************************/
    return (
      <div style={{ position: "relative" }}>
        {isGated ? (
          <FakeCommentInput
            placeholderText={placeholderText}
            buttonText={buttonText}
            onClick={gatedValidatePost}
          />
        ) : (
          <>
            {!parentComment && anonymousCommenting && (
              <>
                <p className="text-muted mb-1">
                  {i18n.t(descriptionText)}{" "}
                  <CustomIcon
                    id="anonPostInfoIcon"
                    size="14"
                    icon="information"
                    className="icon-battleship-gray cursor-pointer"
                  />
                </p>
                <UncontrolledPopover
                  popperClassName="popover-default-black mw-315 popover-shadow"
                  placement="bottom"
                  trigger="hover"
                  target="anonPostInfoIcon">
                  <PopoverBody className="color-white py-2 px-3">
                    <p className="m-0 text-13 line-height-17">
                      {i18n.t("comments.anonymousCommenting.tooltip")}
                    </p>
                  </PopoverBody>
                </UncontrolledPopover>
              </>
            )}
            <div
              className={classNames(
                `autoresize-textarea-container d-flex align-items-stretch pr-1 ${
                  editMode ? "edit-mode flex-column" : ""
                }`,
                isGroup ? "border-bottom-0" : ""
              )}
              style={{
                borderRadius: borderRadius ? borderRadius : "4px",
                backgroundColor: theme === THEMES.DARK ? "black" : "white"
              }}>
              <div
                className={classNames(
                  "d-flex align-items-center",
                  editMode ? "mb-2" : "w-85"
                )}>
                <TextareaAutosize
                  defaultValue={text}
                  className={classNames(
                    theme === THEMES.DARK
                      ? "autoresize-textarea bg-transparent text-white placeholder-light-gray"
                      : "autoresize-textarea"
                  )}
                  style={{
                    lineHeight: "1.3rem"
                  }}
                  ref={ref}
                  onChange={onChange}
                  onFocus={onFocus}
                  minRows={1}
                  maxRows={5}
                  placeholder={placeholderText}
                />
              </div>
              <div
                className={`post-container d-flex align-items-end ${
                  editMode ? "edit-mode justify-content-end" : ""
                }`}>
                {editMode && onCancelClick && (
                  <Button
                    disabled={loading}
                    outline
                    onClick={onCancelClick}
                    className={classNames(
                      "btn-text no-focus-button border-0",
                      loading && "p-0"
                    )}
                    color={
                      theme === THEMES.DARK ? "light-gray" : "battleship-gray"
                    }>
                    <span className="font-weight-500">
                      {i18n.t("common.cancel")}
                    </span>
                  </Button>
                )}
                <LoadingButton
                  disabled={(editMode && !hasChanged) || loading ? true : false}
                  outline
                  onClick={gatedValidatePost}
                  className={classNames("btn-text no-focus-button border-0")}
                  color={theme === THEMES.DARK ? "light-gray" : "cool-blue"}
                  loading={loading}>
                  <span className="font-weight-500">{buttonText}</span>
                </LoadingButton>
              </div>
            </div>
            {isGroup && (
              <div className="d-inline-flex justify-content-end align-items-center bg-lighter-gray w-100 border border-light-gray rounded-bottom px-3 py-2">
                <span className="d-inline-flex bg-battleship-gray rounded-sm">
                  <CustomIcon
                    className="icon-white"
                    icon="webnav_group_selected"
                    size={14}
                  />
                </span>
                <p className="text-11 text-battleship-gray mb-0 ml-1">
                  {i18n.t("comments.groupCase").toUpperCase()}
                </p>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
);

CommentInput.propTypes = {
  borderRadius: PropTypes.string,
  commentType: PropTypes.string,
  buttonText: PropTypes.string,
  existingText: PropTypes.string,
  onCancelClick: PropTypes.func,
  onPost: PropTypes.func
};

export default CommentInput;
