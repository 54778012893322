import React, { useState } from "react";
import { useDispatch } from "react-redux";
import "./style.scss";
import {
  DropdownToggle,
  DropdownMenu,
  Dropdown,
  DropdownItem
} from "reactstrap";
import { capitalize } from "lodash";
import { Utils } from "@figure1/f1-pro-fe-brain";
import CustomIcon from "../custom-icon/CustomIcon";
import CaseDropdownItem from "./CaseDropdownItem";
import i18n from "../../utils/i18n";
import { trackReportCase } from "../../actions/metrics.actions";
import { reportCase } from "../../actions/case.actions";
import { keysToCamelCase } from "../../utils/camelCaseUtils";
import { defaultFeatures } from "../../constants/case-feature-constants";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import useDialog from "../dialog/useDialog";
import useIsGated from "../../hooks/useIsGated";

const CASE_TYPE = {
  CASE: "case",
  POST: "post"
};

const CaseDropdown = ({ caseContent, commentStatus, from }) => {
  /** ********************************** CONFIG ***************************************/
  const { caseUuid, features = defaultFeatures } = keysToCamelCase(caseContent);
  const { reportEnabled } = features;

  const dispatch = useDispatch();
  const { confirm } = useDialog();

  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const isGated = useIsGated();

  const isNonMedicalPost = Utils.CaseUtils.isNonMedicalContent(caseContent);

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const toggleDropdown = () => {
    setDropdownOpen((prevState) => !prevState);
  };

  const onSubmitReport = async (reportId) => {
    await dispatch(reportCase(caseUuid, reportId));
  };

  const reportCaseTap = async () => {
    const confirmed = await confirm({
      message: i18n.t("CaseAction.reportModalTitle", {
        TYPE: isNonMedicalPost ? CASE_TYPE.POST : CASE_TYPE.CASE
      }),
      confirmText: capitalize(i18n.t("common.report"))
    });

    trackReportCase({ caseContent, commentStatus, from });

    if (!confirmed) {
      return;
    }

    onSubmitReport("unspecified reason");
  };

  const verifiedReportCaseTap = useVerifiedUserGate(reportCaseTap);

  /** ********************************** RENDER ***************************************/

  return (
    <div className="d-flex">
      <Dropdown
        className="dropdown-profile"
        isOpen={isDropdownOpen}
        toggle={toggleDropdown}>
        <DropdownToggle
          className="cursor-pointer"
          tag="span"
          aria-expanded={isDropdownOpen}>
          <CustomIcon icon="ellipses" color="#6b778b" size={20} />
        </DropdownToggle>
        <DropdownMenu right className="px-3">
          {isGated && (
            <>
              <div className="disabled py-2">
                <div className="case-dropdown-text-area d-flex justify-content-center">
                  <div className="case-dropdown-text-secondary text-center helv-med">
                    {i18n.t("caseDropdown.disabledHeader")}
                  </div>
                </div>
              </div>
              <DropdownItem divider />
            </>
          )}
          {reportEnabled && (
            <CaseDropdownItem
              disabled={isGated}
              icon="sheet_report"
              iconColor={isGated ? "#adb5bd" : "#ed1c30"}
              primaryText={i18n.t("CaseAction.reportActionTitle", {
                TYPE: isNonMedicalPost ? CASE_TYPE.POST : CASE_TYPE.CASE
              })}
              secondaryText={i18n.t("CaseAction.reportPostSubtitle")}
              handleClick={verifiedReportCaseTap}
            />
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default CaseDropdown;
