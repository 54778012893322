import React, { useEffect, useRef, useState } from "react";
import { Selectors, Utils } from "@figure1/f1-pro-fe-brain";
import F1AvatarLink from "../common/F1AvatarLink";
import CommentHeader from "./CommentHeader";
import CommentFooter from "./CommentFooter";
import i18n from "../../utils/i18n";
import Collapse from "reactstrap/lib/Collapse";
import ReplyThread from "./ReplyThread";
import ReplyContainer from "./ReplyContainer";
import PropTypes from "prop-types";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { useSelector } from "react-redux";
import "./Comment.scss";
import { injectUserTag } from "../../utils/comment-utils";
import EditContainer from "./EditContainer";
import {
  useCommentTranslation,
  useIsAnonymousCommentAuthor,
  useIsCaseAuthor
} from "../../hooks";
import {
  TRANSLATION_SOURCES,
  TRANSLATION_VIEWS
} from "../../metrics/constants.metrics";
import { trackTranslationClicked } from "../../actions/metrics.actions";
import { isEmpty } from "lodash";
import { caseAuthor } from "../../utils/user-utils";
import CustomIcon from "../custom-icon/CustomIcon";

const Comment = ({
  comment,
  reply,
  reportEnabled,
  caseUuid,
  contentUuid,
  position,
  caseContent,
  commentStatus,
  highlightComment,
  isGroup = false,
  isMedicalCase = true
}) => {
  /** ********************************** CONFIG ***************************************/
  const [isOpen, setIsOpen] = useState(false);
  const [showReply, setShowReply] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [replyThreadText, setReplyThreadText] = useState(
    i18n.t("comments.viewAllReplies")
  );
  const currentUser = useSelector((state) => state.user);

  const { theme } = useTheme();
  const { isDeleted, isReported, deletedAt, reportedAt } = comment;

  const isCommentAnon = comment?.isAnonymous || comment?.author?.isAnonymous;
  const isAnonCommentAuthor = useIsAnonymousCommentAuthor(comment?.commentUuid);
  const userIsCommentAuthor = isCommentAnon
    ? isAnonCommentAuthor
    : comment.authorUuid === currentUser.userUuid;

  const isUserGeneratedCase = Utils.CaseUtils.isUserGeneratedCase(caseContent);
  const isCaseAuthor = useIsCaseAuthor(caseContent);
  const caseAuthorObj = caseAuthor(caseContent);
  const isCommentAuthorIsCaseAuthor =
    !!isCommentAnon || comment?.author?.userUuid === caseAuthorObj?.userUuid;

  const [injectedCommentText, setInjectedCommentText] = useState("");
  const commentQueueEnabled = caseContent?.features?.commentQueueEnabled;
  const {
    text,
    isTranslationEnabled,
    isLoadingTranslation,
    isViewingTranslation,
    toggleTranslation
  } = useCommentTranslation(caseUuid, comment.commentUuid);
  const currentLanguageCode = useSelector(
    Selectors.LocalizationSelectors.selectCurrentLanguageCode
  );

  const showHighlightComment =
    highlightComment?.commentUuid === comment?.commentUuid;
  const commentRef = useRef(null);

  /** ********************************** HOOKS ****************************************/

  //TODO: this is temp solution, we had to add delay coz the react squizes changing of the opening of reply thread and scrolling in the same render
  //TODO: we should figure out how to have both renders separate
  useEffect(() => {
    if (showHighlightComment) {
      setTimeout(() => {
        commentRef?.current?.scrollIntoView({
          behavior: "smooth",
          block: "end"
        });
      }, 500);
    }
  }, [showHighlightComment, isOpen]);

  /** ********************************* FUNCTIONS *************************************/

  const toggleReplyThread = () => {
    setIsOpen((isOpen) => !isOpen);
  };

  useEffect(() => {
    setReplyThreadText(
      isOpen
        ? i18n.t("comments.hideReplies")
        : i18n.t("comments.viewAllReplies")
    );
  }, [isOpen]);

  useEffect(() => {
    setInjectedCommentText(injectUserTag(text));
  }, [text]);

  const openReply = () => {
    setShowReply(true);
  };

  const closeReply = () => {
    setShowReply(false);
  };

  const onExpandReplies = () => {
    setIsOpen(true);
  };

  const toggleEditComment = (show) => {
    setIsEditing(show);
  };
  const verifiedOpenReply = useVerifiedUserGate(openReply);

  const handleToggleTranslation = () => {
    trackTranslationClicked({
      source: reply ? TRANSLATION_SOURCES.REPLY : TRANSLATION_SOURCES.COMMENT,
      currentLanguage: currentLanguageCode,
      viewPreClick: isViewingTranslation
        ? TRANSLATION_VIEWS.TRANSLATED
        : TRANSLATION_VIEWS.ORIGINAL,
      caseUuid: caseUuid
    });
    toggleTranslation();
  };

  /** ********************************** RENDER ***************************************/

  const avatar = (
    <div className="d-flex flex-column">
      <F1AvatarLink
        // for old comments without an author, just pass the comment
        user={comment.author ? comment.author : comment}
        {...(reply && { size: "sm" })}
      />
      {showHighlightComment && (
        <div className=" mt-2 d-flex justify-content-center">
          <div
            className="w-12px h-12px bg-cool-blue"
            style={{
              borderRadius: "50%"
            }}
          />
        </div>
      )}
    </div>
  );

  let commentElement = (
    <div
      className={`d-flex px-1 py-1 ${showHighlightComment && "flashing-div"}`}
      ref={commentRef}>
      {avatar}
      <div className="w-100 ml-2 d-flex flex-column">
        <CommentHeader caseContent={caseContent} comment={comment} reply />
        <div className="comment">{injectedCommentText}</div>
        <CommentFooter
          caseUuid={caseUuid}
          reportEnabled={reportEnabled}
          onReplyClick={verifiedOpenReply}
          onEditClick={() => toggleEditComment(true)}
          replyEnabled={!comment?.author?.isDeleted || comment.replyable}
          deleteEnabled={userIsCommentAuthor}
          editEnabled={userIsCommentAuthor && !commentQueueEnabled}
          comment={comment}
          isTranslationEnabled={isTranslationEnabled}
          isLoadingTranslation={isLoadingTranslation}
          isViewingTranslation={isViewingTranslation}
          onToggleTranslation={handleToggleTranslation}
          showAcceptedAnswerOptions={
            isUserGeneratedCase &&
            isCaseAuthor &&
            !isCommentAuthorIsCaseAuthor &&
            isMedicalCase
          }
          hasAcceptedAnswer={!isEmpty(caseContent?.acceptedAnswer)}
          caseContent={caseContent}
        />
      </div>
    </div>
  );

  if (isDeleted || deletedAt) {
    commentElement = (
      <div className="deleted-container">
        <CustomIcon
          icon={"deleted_reported_comment_icon"}
          className="mr-2"
          size={40}
        />
        {i18n.t("comments.commentDeleted")}
      </div>
    );
  }

  if (isReported || reportedAt) {
    commentElement = (
      <div className="deleted-container">
        <CustomIcon
          icon={"deleted_reported_comment_icon"}
          className="mr-2"
          size={40}
        />
        {i18n.t("comments.commentReported")}
      </div>
    );
  }

  if (isEditing) {
    commentElement = (
      <EditContainer
        onCloseClick={() => toggleEditComment(false)}
        comment={comment}
        caseContent={caseContent}
      />
    );
  }

  return (
    <>
      {commentElement}

      <Collapse isOpen={showReply}>
        <ReplyContainer
          parentComment={comment}
          caseUuid={caseUuid}
          contentUuid={contentUuid}
          onCloseClick={closeReply}
          onExpandReplies={onExpandReplies}
          replyTo={comment.username}
          position={position}
          caseContent={caseContent}
          commentStatus={commentStatus}
          isGroup={isGroup}
        />
      </Collapse>

      <div className="reply-padding">
        {comment.replyThread && (
          <>
            <Collapse isOpen={isOpen}>
              <ReplyThread
                replies={comment.replyThread}
                reportEnabled={reportEnabled}
                caseUuid={caseUuid}
                contentUuid={contentUuid}
                position={position}
                caseContent={caseContent}
                commentStatus={commentStatus}
                isGroup={isGroup}
                highlightComment={highlightComment}
                isThreadOpen={isOpen}
                toggleReplyThread={toggleReplyThread}
              />
            </Collapse>

            <div
              className={classNames(
                "d-inline-flex align-items-center cursor-pointer mb-3",
                theme === THEMES.DARK ? "text-light-gray-blue" : null
              )}
              onClick={toggleReplyThread}>
              <div style={{ width: "36px" }}>
                <hr
                  className={classNames(
                    "my-2",
                    theme === THEMES.DARK ? "border-light-gray-blue" : null
                  )}
                />
              </div>
              <div className="pl-2 font-weight-bold text-13">
                {replyThreadText}
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

Comment.propTypes = {
  comment: PropTypes.object,
  reply: PropTypes.bool,
  reportEnabled: PropTypes.bool,
  caseUuid: PropTypes.string.isRequired,
  contentUuid: PropTypes.string.isRequired
};

export default Comment;
