import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { trackSettingsProfileEntry } from "../../actions/metrics.actions";
import { Card, CardBody } from "reactstrap";
import { syncUserProfileIfNeeded } from "../../actions/user-profiles.actions";
import AccountDetails from "./components/profile/AccountDetails";
import SecondarySpecialtiesForm from "./components/profile/SecondarySpecialtiesForm";
import PracticeForm from "./components/profile/PracticeForm";
import PracticeHospitalForm from "./components/profile/PracticeHospitalForm";
import BiographyForm from "./components/profile/BiographyForm";
import i18n from "../../utils/i18n";
import { Link } from "react-router-dom";
import { getUrl } from "../../utils/route-utils";
import { PROFILE_DETAIL } from "../../constants/routes";
import CustomIcon from "../../components/custom-icon/CustomIcon";

const ProfileSettings = () => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    trackSettingsProfileEntry();
  }, []);

  useEffect(() => {
    dispatch(syncUserProfileIfNeeded(user.userUuid));
  }, [user.userUuid, dispatch]);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Link to={getUrl(PROFILE_DETAIL, { userUuid: user.userUuid })}>
        <div className="d-inline-flex flex-row align-items-center text-nowrap mb-3">
          <CustomIcon
            className="rotate-180 icon-denim-blue"
            icon="drawer_chevron"
            size="12"
          />
          <div className="pl-1">{i18n.t("Settings.Profile.backButton")}</div>
        </div>
      </Link>
      <Card className="mb-1">
        <CardBody className="d-flex justify-content-center align-items-center">
          <h2 className="m-0 text-18 helv-bold">
            {i18n.t("Settings.Profile.editProfileTitle")}
          </h2>
        </CardBody>
      </Card>
      <Card className="mb-4">
        <CardBody>
          <AccountDetails />
        </CardBody>
      </Card>

      <Card>
        <CardBody>
          <h2 className="text-18 helv-bold">
            {i18n.t("Settings.Profile.aboutTitle")}
          </h2>
          {user && (
            <>
              <SecondarySpecialtiesForm profileData={user} />
              <PracticeForm profileData={user} />
              <PracticeHospitalForm profileData={user} />
              <BiographyForm profileData={user} />
            </>
          )}
        </CardBody>
      </Card>
    </>
  );
};

export default ProfileSettings;
