/** @format */
import Firebase from "../firebase";
import { Actions } from "@figure1/f1-pro-fe-brain";

const brainActions = Actions(Firebase).ActivityCentreActions;

const activityCentreActions = {
  ...brainActions
};

export default activityCentreActions;
