import React, { useState } from "react";
import { Controller } from "react-hook-form";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import i18n from "../../../../utils/i18n";
import ProfileBioEditor from "../../../profile/components/ProfileBioEditor";
import useProfileLogic from "../../hooks/useProfileLogic";

const MAX_BIO_CHAR = 140;
const WARNING_BIO_CHAR = 50;

const BiographyForm = ({ profileData }) => {
  const { actionBar, readOnlyBar, editMode, form } = useProfileLogic();
  const { control, setValue, errors } = form;

  const [remainBioChar, setRemainBioChar] = useState(MAX_BIO_CHAR);

  const ActionBarComponent = actionBar();
  const ReadOnlyBarComponent = readOnlyBar(
    profileData.userBio,
    "Settings.Profile.addBiographyCTA"
  );

  const bioCharLabel = (remainChar) => {
    let result = `${Math.abs(remainChar)} `;

    if (remainChar === MAX_BIO_CHAR) {
      result = i18n.t("Profile.bioLimit", { count: remainChar });
    } else if (remainChar >= 0) {
      result += i18n.t("Profile.bioCharLeft", { count: remainChar });
    } else {
      result += i18n.t("Profile.bioCharOver", { count: remainChar });
    }

    return result;
  };

  if (editMode) {
    return (
      <>
        <FormGroup>
          <Label for="bio">
            {i18n.t("Profile.bioLabel")} (
            <span
              className={
                remainBioChar < WARNING_BIO_CHAR ? "text-danger" : null
              }>
              {bioCharLabel(remainBioChar)}
            </span>
            )
          </Label>
          <Controller
            as={ProfileBioEditor}
            control={control}
            id="userBio"
            name="userBio"
            placeholder={i18n.t("Profile.bioLabel")}
            type="textarea"
            defaultValue={profileData.userBio || ""}
            onChangeText={(text) => {
              setValue("userBio", text, true);
              setRemainBioChar(MAX_BIO_CHAR - text.length);
            }}
            minRows={3}
            rules={{ maxLength: MAX_BIO_CHAR }}
            invalid={!!errors.userBio}
          />
          {errors.userBio && (
            <FormFeedback>{i18n.t("Profile.bioError")}</FormFeedback>
          )}
        </FormGroup>
        {ActionBarComponent}
      </>
    );
  } else {
    return (
      <div className="mb-2">
        <h3 className="label text-14 helv-med mb-0">
          <span>{i18n.t("Profile.bioLabel")}</span>{" "}
        </h3>
        {ReadOnlyBarComponent}
      </div>
    );
  }
};

export default BiographyForm;
