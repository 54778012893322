/** @format */
import { OTHER_PROFESSION_CATEGORY_LABELS } from "../constants/profession-category-labels";
import { PROFILE_USER_TYPES } from "../constants/profile-info-constants";

export const userIsVerified = (user) => {
  return user?.isVerified;
};

export const isOtherProfessionCategory = (professionCategoryLabel) =>
  OTHER_PROFESSION_CATEGORY_LABELS.includes(professionCategoryLabel);

export const isUserProfessionCategoryOther = (user) => {
  const tree = user?.primarySpecialty?.tree;

  return isOtherProfessionCategory(tree?.profession?.professionCategoryLabel);
};

/**
 * Return the correct display name to user for institutional users.
 *
 * For institutional profiles, the `profileDisplayName` holds "Institution or Focus" value.
 * this might change.
 *
 * Note: caseCommentDisplayName - usually specialty for most users,
 * can be set in modified tool for.
 *
 * Note 2: New institutional profiles created via admin tool (so not modifyign an existing
 * user) do not have `caseCommentDisplayName` set, thus use `profileDisplayName`.
 *
 * @param {Object} userOrAuthor - either user or author object. They have similar structure.
 * @returns {string} display name
 */
export const getInstitutionalCommentDisplayName = (userOrAuthor) =>
  userOrAuthor?.caseCommentDisplayName || userOrAuthor?.profileDisplayName;

export const isInstitutionalUserType = (userType) =>
  userType === PROFILE_USER_TYPES.INSTITUTIONALUSER;

/**
 * Return string used for user display name in:
 * 1) Case card user cell, text under username
 * 2) Comment text after user's username
 *
 * This is in prioritized order. Having `sponsoredText` included
 * is a little weird but I wanted to show this order fully!
 *
 * @param {Object} userOrAuthor
 * @param {string} sponsoredText
 * @returns {string}
 */
export const getUserDisplayName = (userOrAuthor, sponsoredText) => {
  const caseCommentDisplayName = userOrAuthor?.caseCommentDisplayName;

  if (userOrAuthor?.isDeleted) {
    return "";
  } else if (sponsoredText) {
    return sponsoredText;
  } else if (
    userOrAuthor?.isPartner ||
    isInstitutionalUserType(userOrAuthor?.userType)
  ) {
    return getInstitutionalCommentDisplayName(userOrAuthor);
  } else {
    // Fallback: show nothing if nothing is defined
    return caseCommentDisplayName || "";
  }
};

export const caseAuthor = (caseContent) => {
  return caseContent?.authors?.[0];
};

export const isCaseAuthor = ({ caseContent, user }) => {
  return caseAuthor(caseContent)?.userUuid === user?.userUuid;
};

export const isUserUSA = (user) => {
  return user?.userUid && user?.country?.countryCode === "US";
};
