import React, { useEffect, useState } from "react";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import { Constants } from "@figure1/f1-pro-fe-brain";
import VerificationNpiV2 from "./components/VerificationNpi.v2";
import i18n from "../../utils/i18n";
import F1HorizontalDivider from "../../components/common/F1HorizontalDivider";
import VerificationNonNpi from "./components/VerificationNonNpi";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import useIsUSAUser from "../../hooks/useIsUSAUser";
import { useSelector } from "react-redux";
import Loading from "../../components/loading";
import ExternalTextLink from "./components/ExternalTextLink";
import { StaticStrings } from "../../constants/static-string-constants";
import { isMobile } from "react-device-detect";
import { trackNPIFastTrackPageEntry } from "../../actions/metrics.actions";
import { useVerifiedMemberStatus } from "../../selectors/member-status-selector";
import useForceGatedReset from "../../hooks/useForceGatedReset";
import { Button } from "reactstrap";
import { useWhoCanJoinDialog } from "../../hooks/useWhoCanJoinDialog";

const VerificationPageV2 = () => {
  /** ********************************** CONFIG ***************************************/
  const isUsa = useIsUSAUser();
  const isUserInitialized = useSelector(
    (state) => !!state.user?.initialized && state.user?.country?.countryCode
  );
  const isVerified = useVerifiedMemberStatus();
  const onboardingState = useSelector((state) => state.user?.onboardingState);
  const [moreInfo, setMoreInfo] = useState("");
  const [trackingSent, setTrackingSent] = useState(false);
  const onWhoCanJoin = useWhoCanJoinDialog();

  useForceGatedReset();
  /** *********************************** HOOKS ***************************************/

  useEffect(() => {
    if (!trackingSent && isUserInitialized) {
      if (isUsa) {
        trackNPIFastTrackPageEntry();
      } else {
      }
      setTrackingSent(true);
    }
  }, [trackingSent, isUserInitialized, isUsa]);

  useEffect(() => {
    // icky, but only way to really inject the links without doing a lot of stored strings
    let str = i18n
      .t("RegistrationScreens.verificationPage.v2.moreInfo")
      .split("|");
    str.splice(
      1,
      0,
      <a
        className="text-cool-blue"
        href={StaticStrings.communityGuidelines}
        target="_blank"
        rel="noreferrer">
        {i18n.t("RegistrationScreens.privacyPage.communityGuidelines")}
      </a>
    );

    str.splice(
      3,
      0,
      <ExternalTextLink
        className="text-cool-blue cursor-pointer"
        url={StaticStrings.privacyLink}
        linkText={i18n.t("RegistrationScreens.privacyPage.privacyPolicy")}
      />
    );

    setMoreInfo(str);
  }, []);
  /** ********************************** RENDER ***************************************/

  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <Figure1Page1Col
        mainContentSmall={
          <div className="mt-3 mt-lg-5">
            <h1
              className={`${
                isMobile ? "text-20" : "text-22"
              } helv-bold mx-sm-n5 text-center`}>
              {i18n.t("RegistrationScreens.verificationPage.v2.pageTitle")}
            </h1>
            <p
              className={`color-battleship-gray mx-sm-n5 text-center ${
                isMobile ? "text-14" : "text-15"
              } mb-4`}>
              {i18n.t(
                "RegistrationScreens.verificationPage.v2.pageDescription"
              )}
              <br />
              <Button
                color="link"
                className="py-0 color-cool-blue"
                onClick={onWhoCanJoin}>
                {i18n.t("RegistrationScreens.verificationPage.v2.pageCTA")}
              </Button>
            </p>

            {!isUserInitialized && (
              <div style={{ height: 200 }}>
                <Loading />
              </div>
            )}
            {isUserInitialized &&
              isUsa &&
              (!isVerified ||
                onboardingState ===
                  Constants.ONBOARDING_STATE.CONFIRMATION) && (
                <>
                  <VerificationNpiV2 />
                  <F1HorizontalDivider className="w-50" color="light-gray">
                    <span className="color-battleship-gray text-16">
                      {i18n.t(
                        "RegistrationScreens.verificationPage.v2.divider"
                      )}
                    </span>
                  </F1HorizontalDivider>
                </>
              )}
            {isUserInitialized && <VerificationNonNpi />}

            <p className="mx-3 mt-3 text-15 text-center color-battleship-gray">
              {moreInfo}
            </p>
          </div>
        }
        hideBackButton={true}
        headerClass="py-0"
      />
    </Figure1Layout>
  );
};

export default VerificationPageV2;
