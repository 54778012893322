/** @format */

import React, { useState } from "react";
import Figure1Page2ColLeftNav from "../../@layouts/Figure1Page2ColLeftNav";
import Figure1Layout from "../../@layouts/Figure1Layout";
import SettingsNavigation from "./components/SettingsNavigation";
import useBreakpoint, { BREAKPOINT_SIZES } from "../../hooks/useBreakpoint";

import "./SettingsPage.scss";
import { useForm } from "react-hook-form";
import { Card, CardBody, Form } from "reactstrap";
import LoadingButton from "../../components/common/LoadingButton";
import i18n from "../../utils/i18n";
import { useDispatch } from "react-redux";
import {
  showErrorMessage,
  showSuccessMessage
} from "../../actions/global.actions";
import {
  updateInterests,
  UPDATE_USER_PROFILE_COMPLETE
} from "../../actions/user-profiles.actions";
import AppPrompt from "../../components/app-prompt/AppPrompt";

const Settings = ({ omitSaveOption, childView: ChildView }) => {
  /** ********************************** CONFIG ***************************************/
  const size = useBreakpoint();
  const dispatch = useDispatch();

  const { handleSubmit, control, errors, formState, reset, setValue } = useForm(
    {
      mode: "onChange"
    }
  );
  const [isSaving, setIsSaving] = useState(false);
  const { isDirty, isValid } = formState;

  const headerClass =
    size === BREAKPOINT_SIZES.EXTRA_SMALL || size === BREAKPOINT_SIZES.SMALL
      ? "settings-header mobile-mode"
      : "settings-header py-5";

  const onSubmit = async (formData) => {
    setIsSaving(true);

    if (formData.interests) {
      const result = await dispatch(
        updateInterests(formData.interests.map((i) => i.value))
      );

      if (result && result.type === UPDATE_USER_PROFILE_COMPLETE) {
        dispatch(showSuccessMessage("Your interests have been saved."));
        reset(formData, {
          keepErrors: true,
          keepDirty: false,
          keepIsSubmitted: false,
          keepTouched: false,
          keepIsValid: false,
          keepSubmitCount: false
        });
      } else {
        dispatch(showErrorMessage(result.message));
      }
    }

    setIsSaving(false);
  };
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <>
      <Figure1Layout footer={<AppPrompt />}>
        <Figure1Page2ColLeftNav
          headerClass={headerClass}
          leftSidebarContent={<SettingsNavigation />}
          mainContent={
            <>
              {omitSaveOption && <ChildView />}

              {!omitSaveOption && (
                <Form onSubmit={onSubmit}>
                  <ChildView
                    isSaving={isSaving}
                    control={control}
                    errors={errors}
                    formState={formState}
                    setValue={setValue}
                  />
                  <Card className="border-0 mt-3">
                    <CardBody>
                      <div className="d-flex justify-content-end">
                        <LoadingButton
                          color="primary"
                          spinnerColor="secondary"
                          type="submit"
                          loading={isSaving}
                          disabled={isSaving || !isDirty || !isValid}
                          onClick={handleSubmit(onSubmit)}>
                          {i18n.t("common.save")}
                        </LoadingButton>
                      </div>
                    </CardBody>
                  </Card>
                </Form>
              )}
            </>
          }
        />
      </Figure1Layout>
    </>
  );
};

export default Settings;
