import React from "react";
import { Controller } from "react-hook-form";
import { FormGroup, Input, Label } from "reactstrap";
import i18n from "../../../../utils/i18n";
import useProfileLogic from "../../hooks/useProfileLogic";

const PracticeForm = ({ profileData }) => {
  const { actionBar, readOnlyBar, editMode, form } = useProfileLogic();
  const ActionBarComponent = actionBar();
  const ReadOnlyBarComponent = readOnlyBar(
    profileData.practiceLocation,
    "Settings.Profile.addPracticeLocationCTA"
  );
  if (editMode) {
    return (
      <>
        <FormGroup>
          <Label for="userPracticeLocation">
            {i18n.t("Profile.practiceLabel")}
          </Label>
          <Controller
            as={Input}
            control={form.control}
            id="userPracticeLocation"
            name="userPracticeLocation"
            type="text"
            placeholder={i18n.t("Profile.practiceLabel")}
            defaultValue={profileData.practiceLocation || ""}
          />
        </FormGroup>
        {ActionBarComponent}
      </>
    );
  } else {
    return (
      <div className="mb-2">
        <h3 className="label text-14 helv-med mb-0">
          <span>{i18n.t("Profile.practiceLabel")}</span>{" "}
        </h3>
        {ReadOnlyBarComponent}
      </div>
    );
  }
};

export default PracticeForm;
