import React, { useEffect, useState } from "react";
import { FormFeedback, FormGroup, Label } from "reactstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";
import i18n from "../../../utils/i18n";
import moment from "moment";

const FormElementGraduationInfo = ({ control, errors, graduationDate }) => {
  /** ********************************** CONFIG ***************************************/
  const [graduationYears, setGraduationYears] = useState([]);
  const [months, setMonths] = useState([]);

  const invalidYear = !!errors?.graduationYear;
  const invalidMonth = !!errors?.graduationMonth;
  const currentYear = new Date().getFullYear();
  const [gradDatePieces, setGradDatePieces] = useState([]);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (graduationDate) {
      const date = moment.utc(graduationDate);

      setGradDatePieces([
        { label: date.year(), value: date.year() },
        {
          label: getMonthName(date.month()),
          value: (date.month() + 1).toLocaleString("en-US", {
            minimumIntegerDigits: 2,
            useGrouping: false
          })
        }
      ]);
    }
  }, [graduationDate]);

  useEffect(() => {
    const years = [
      {
        label: currentYear,
        value: currentYear
      }
    ];

    const newMonths = [];

    for (let i = 1; i <= 7; i++) {
      const newYear = currentYear + i;
      years.push({
        label: newYear,
        value: newYear
      });
    }

    for (let i = 0; i < 12; i++) {
      newMonths.push({
        label: getMonthName(i),
        value: (i + 1).toLocaleString("en-US", {
          minimumIntegerDigits: 2,
          useGrouping: false
        })
      });
    }
    setGraduationYears(years);
    setMonths(newMonths);
  }, [currentYear]);

  /** ********************************* FUNCTIONS *************************************/

  const getMonthName = (month) => {
    return i18n.t(`common.months.${month}`);
  };

  /** ********************************** RENDER ***************************************/

  if (graduationDate && !gradDatePieces.length) return null;

  return (
    <FormGroup className={"mt-4"}>
      <Label for="graduationYear" className="mb-1">
        Expected graduation month and year
      </Label>
      <div className="d-flex flex-row flex-nowrap">
        <Controller
          className={`mr-2 flex-grow-1 ${invalidMonth ? "is-invalid" : ""}`}
          as={<Select options={months} />}
          control={control}
          id={"graduationMonth"}
          name={"graduationMonth"}
          type="text"
          placeholder={"Graduation month"}
          rules={{ required: true }}
          defaultValue={gradDatePieces?.[1] || null}
          invalid={invalidMonth}
        />

        <Controller
          className={`flex-grow-1 ${invalidYear ? "is-invalid" : ""}`}
          as={<Select options={graduationYears} />}
          control={control}
          id={"graduationYear"}
          name={"graduationYear"}
          type="text"
          placeholder={"Graduation year"}
          rules={{ required: true }}
          defaultValue={gradDatePieces?.[0] || null}
          invalid={invalidYear}
        />
      </div>

      <FormFeedback>
        {i18n.t("RegistrationScreens.userInfo.v2.professionErrorText")}
      </FormFeedback>
    </FormGroup>
  );
};

export default FormElementGraduationInfo;
