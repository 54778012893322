import React, { useMemo } from "react";
import CustomIcon from "../custom-icon/CustomIcon";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import i18n from "../../utils/i18n";
import useComments from "../comments/useComments";

const SocialCountContainer = ({
  caseContent,
  hideComments = false,
  hideReactions = false,
  activateListener = true,
  className = "d-flex w-100 justify-content-between align-items-center row no-gutters"
}) => {
  /** ********************************** CONFIG ***************************************/
  const { theme } = useTheme();

  const { commentStatus } = useComments(
    caseContent.caseUuid,
    caseContent.contentUuid,
    activateListener
  );

  const { commentCount, reactionCount } = useMemo(() => {
    const totalCommentCount = activateListener
      ? commentStatus?.allCount
      : caseContent.commentCount;

    const rawReactionCount =
      caseContent.reactionCount ||
      Object.values(caseContent.allReactions || {}).reduce((t, n) => t + n, 0);
    const totalReactionCount = Math.max(rawReactionCount, 0);

    return {
      commentCount: totalCommentCount,
      reactionCount: totalReactionCount
    };
  }, [
    activateListener,
    caseContent.allReactions,
    caseContent.commentCount,
    caseContent.reactionCount,
    commentStatus?.allCount
  ]);

  const showCommentsCount = !hideComments && commentCount > 0;
  const showReactionCount = !hideReactions && reactionCount > 0;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  const commentsSection = showCommentsCount ? (
    <div className="d-flex justify-content-between align-items-center">
      <span
        className={classNames(
          "text-13 helv-bold",
          theme === THEMES.DARK ? "text-white" : "text-default-black"
        )}>
        {commentCount}
      </span>
      <span
        className={classNames(
          "text-13 text-muted",
          theme === THEMES.DARK ? "text-white" : "text-battleship-gray"
        )}>
        &nbsp;
        {i18n.t("common.comment", {
          count: commentCount
        })}
      </span>
    </div>
  ) : (
    <span />
  );

  const likesSection = showReactionCount ? (
    <span
      className={classNames(
        "d-flex justify-content-between align-items-center text-13 text-muted",
        theme === THEMES.DARK ? "text-white" : "text-battleship-gray"
      )}>
      <CustomIcon icon="blue_like_icon_circle" size={16} />
      &nbsp;{reactionCount}
    </span>
  ) : (
    <span />
  );

  return (
    <div className={className}>
      {commentsSection}
      {likesSection}
    </div>
  );
};

export default SocialCountContainer;
