/** @format */

import * as userDraftsDB from "../db/user-draft.db";
import { isEmpty } from "lodash";
import {
  saveDraftCall,
  sendToBackendCall,
  deleteDraftCall,
  deleteDraftMediaCall
} from "../api/draft.cloud-functions";
import { DRAFT_STATES } from "../constants/case-constants";
import { showSuccessMessage, showErrorMessage } from "./global.actions";
import {
  refreshMediaUploadUrlCall,
  uploadMediaToAWS
} from "../api/mediaUpload.cloud-functions";
const actionsPrefix = "userDraft";

export const SET_CURRENT_DRAFT_STARTED = `${actionsPrefix}/SET_CURRENT_DRAFT_STARTED`;
export const SET_CURRENT_DRAFT_CORE_DATA = `${actionsPrefix}/SET_CURRENT_DRAFT_CORE_DATA`;
export const SET_CURRENT_DRAFT_COMPLETE = `${actionsPrefix}/SET_CURRENT_DRAFT_COMPLETE`;
export const UPDATE_CURRENT_DRAFT_MEDIA = `${actionsPrefix}/UPDATE_CURRENT_DRAFT_MEDIA`;
export const CLEAR_CURRENT_DRAFT = `${actionsPrefix}/CLEAR_CURRENT_DRAFT`;
export const LOAD_USER_DRAFTS_COMPLETED = `${actionsPrefix}/LOAD_USER_DRAFTS_COMPLETED`;
export const USER_DRAFT_LISTENER_ON = `${actionsPrefix}/USER_DRAFT_LISTENER_ON`;
export const USER_DRAFT_LISTENER_OFF = `${actionsPrefix}/USER_DRAFT_LISTENER_OFF`;
export const SAVE_DRAFT_START = `${actionsPrefix}/SAVE_DRAFT_START`;
export const SAVE_DRAFT_COMPLETE = `${actionsPrefix}/SAVE_DRAFT_COMPLETE`;
export const DELETE_DRAFT_START = `${actionsPrefix}/DELETE_DRAFT_START`;
export const DELETE_DRAFT_COMPLETE = `${actionsPrefix}/DELETE_DRAFT_COMPLETE`;
export const UPDATE_DRAFT_MEDIA_START = `${actionsPrefix}/UPDATE_DRAFT_MEDIA_START`;
export const UPDATE_DRAFT_MEDIA_COMPLETE = `${actionsPrefix}/UPDATE_DRAFT_MEDIA_COMPLETE`;
export const SEND_DRAFT_TO_BACKEND_START = `${actionsPrefix}/SEND_DRAFT_TO_BACKEND_START`;
export const SEND_DRAFT_TO_BACKEND_COMPLETE = `${actionsPrefix}/SEND_DRAFT_TO_BACKEND_COMPLETE`;
export const GET_MEDIA_UPLOAD_LOCATION_START = `${actionsPrefix}/GET_MEDIA_UPLOAD_LOCATION_START`;
export const GET_MEDIA_UPLOAD_LOCATION_COMPLETE = `${actionsPrefix}/GET_MEDIA_UPLOAD_LOCATION_COMPLETE`;
export const LOG_ERROR = `${actionsPrefix}/LOG_ERROR`;

// a.k.a 10MB
// const FIREBASE_UPLOAD_MAX_BYTES = 10000000;

/**
 * If draft uid given, get current draft data from redux and save a copy of it.
 * Temporarily download all media associated and process for faces.
 * It will be deleted later. Downloading media show only happen when viewing a previous draft.
 *
 * To hopefully make the screen load fast with content, the core data is set in redux first
 * before download and processing.
 *
 * If no draft uid given, unset current draft in redux store. Also delete
 * all temp media files associated it.
 *
 */
export const setAndPrepareCurrentDraft = (draftUid) => {
  return async (dispatch, getState) => {
    try {
      const newCurrentDraft = getState()?.userDraft?.data?.[draftUid] || null;

      dispatch({
        type: SET_CURRENT_DRAFT_STARTED
      });

      if (!isEmpty(newCurrentDraft)) {
        dispatch({
          type: SET_CURRENT_DRAFT_CORE_DATA,
          payload: {
            ...newCurrentDraft,
            post_process_media: true
          }
        });
      }

      dispatch({
        type: SET_CURRENT_DRAFT_COMPLETE,
        payload: {
          currentDraft: newCurrentDraft
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
};

// not currently used but could be reinstated later -- Corey
// const isWithinMaxUploadSize = async (file) => {
//   return parseInt(file.size, 10) <= FIREBASE_UPLOAD_MAX_BYTES;
// };

export const createEmptyDraft = (userUid, isPaging = false) => {
  return async (dispatch, getState) => {
    try {
      const currentUserUid = getState()?.user.userUid;

      // the default label of a case is "unresolved"
      const newDraftUid = await userDraftsDB.createNewDraft(
        userUid || currentUserUid,
        isPaging
      );

      return newDraftUid;
    } catch (error) {
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });
      console.log(error.message);
    }
  };
};

export const addMediaToDraft = (draftUid, mediaList, awsFields) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: UPDATE_DRAFT_MEDIA_START
      });

      // Upload media in the background. It's really fast!
      for (let idx = 0; idx < mediaList.length; idx++) {
        const media = mediaList[idx];
        if (media.uploaded_to_aws) {
          continue;
        }

        await createAWSFormAndUpdateFile(media, awsFields);
        mediaList[idx].uploaded_to_aws = true;
        delete mediaList[idx].file;
      }
    } catch (error) {
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });
      dispatch(
        showErrorMessage("DraftsScreen.addMediaError", null, false, error)
      );
      throw error;
    } finally {
      // This allows the user to see their uploaded images while waiting for processing.
      // Moved this to the finally so that even if uploading fails part way through, we can keep
      // what items did successfully upload and prevent duplicates on retry -- Corey
      dispatch({
        type: UPDATE_CURRENT_DRAFT_MEDIA,
        payload: {
          draftUid: draftUid,
          media: [...mediaList]
        }
      });

      dispatch({
        type: UPDATE_DRAFT_MEDIA_COMPLETE
      });
    }
  };
};

export const deleteMediaDraft = (draftUid, index) => {
  return async (dispatch, getState) => {
    try {
      let currentMedia = getState()?.userDraft?.currentDraft?.media;
      currentMedia.splice(index, 1);

      dispatch({
        type: UPDATE_CURRENT_DRAFT_MEDIA,
        payload: { draftUid: draftUid, media: currentMedia }
      });

      await deleteDraftMediaCall(draftUid, index);
    } catch (error) {
      console.log("Error in deleteMediaDraft: ", error.message);
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });
      throw error;
    }
  };
};

export const deleteDraft = (draftUid) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: DELETE_DRAFT_START
      });
      await deleteDraftCall(draftUid);
      dispatch({
        type: DELETE_DRAFT_COMPLETE
      });
    } catch (error) {
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });
      throw error;
    }
  };
};

export const saveDraft = (draftUid, data) => {
  return async (dispatch, getState) => {
    try {
      dispatch({
        type: SAVE_DRAFT_START
      });
      await saveDraftCall({ ...data, draftUid: draftUid });
      dispatch({
        type: SAVE_DRAFT_COMPLETE,
        payload: { draftUid, data }
      });
      dispatch(showSuccessMessage("DraftsScreen.saveDraftComplete", null));
    } catch (error) {
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });
      dispatch(
        showErrorMessage("DraftsScreen.saveDraftError", null, false, error)
      );

      throw error;
    }
  };
};

export const stopProcessing = (userUid, draftUid, data) => {
  return async (dispatch, getState) => {
    dispatch({
      type: LOG_ERROR,
      payload: { error: new Error("user stopped processing") }
    });
  };
};

// send the draft to the backend.
// here isBackup indicates if the backend must push it to the queue
// if draftData is not null, the draft is saved first
export const sendDraftToBackend = (userUid, draftUid, isBackup, draftData) => {
  return async (dispatch, getState) => {
    try {
      if (draftData) {
        dispatch({
          type: SAVE_DRAFT_START
        });

        await saveDraftCall({ ...draftData, draftUid });
        dispatch({
          type: SAVE_DRAFT_COMPLETE,
          payload: { draftUid, data: draftData }
        });
      }
      dispatch({
        type: SEND_DRAFT_TO_BACKEND_START
      });
      await sendToBackendCall({
        userUid,
        draftUid: draftUid,
        isBackup: isBackup
      });
      dispatch({
        type: SEND_DRAFT_TO_BACKEND_COMPLETE
      });
    } catch (error) {
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });

      dispatch(
        showErrorMessage("DraftsScreen.sendDraftError", null, false, error)
      );

      throw error;
    }
  };
};

export const toggleUserDraftListener = (userUid, userEmail, forceListener) => {
  return async (dispatch, getState) => {
    if (userUid) {
      if (forceListener || !getState().userDraft?.listener) {
        if (forceListener) {
          await getState().userDraft?.listener?.();
        }

        await userDraftsDB.initUserCollection(userUid, userEmail);
        const listener = await userDraftsDB.listenForChanges(
          (querySnapshot) => {
            if (querySnapshot && querySnapshot.size) {
              let drafts = {};
              for (
                let draftIndex = 0;
                draftIndex < querySnapshot.size;
                draftIndex++
              ) {
                const draft = querySnapshot.docs[draftIndex];
                drafts[draft.id] = { ...draft.data(), uid: draft.id };
              }
              dispatch({
                type: LOAD_USER_DRAFTS_COMPLETED,
                payload: { data: drafts }
              });
            } else {
              console.log("There is no draft for this user");
              dispatch({
                type: LOAD_USER_DRAFTS_COMPLETED,
                payload: { data: {} }
              });
            }
          },
          userUid
        );

        return dispatch({
          type: USER_DRAFT_LISTENER_ON,
          payload: { listener }
        });
      }
    } else {
      if (getState().userDraft && getState().userDraft.listener) {
        await getState().userDraft.listener();
      }
      return {
        type: USER_DRAFT_LISTENER_OFF
      };
    }
  };
};

export const isValidDraftProps = (d) => {
  return d.media?.length > 0 || d.title || d.caption;
};

export const shouldShowDraftToUser = (d) => {
  const stateIsOkay =
    !d.state ||
    d.state === DRAFT_STATES.DRAFT ||
    d.state === DRAFT_STATES.REJECTED;

  return stateIsOkay && isValidDraftProps(d);
};

export const getMediaUploadLocation = (params) => {
  return async (dispatch) => {
    try {
      dispatch({
        type: GET_MEDIA_UPLOAD_LOCATION_START
      });
      const result = await refreshMediaUploadUrlCall(params);
      dispatch({
        type: GET_MEDIA_UPLOAD_LOCATION_COMPLETE,
        payload: { awsValues: result?.data?.store }
      });
      return result?.data?.store;
    } catch (error) {
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });
      throw error;
    }
  };
};

const createAWSFormAndUpdateFile = async (media, awsFields) => {
  if (!awsFields || !awsFields.fields) {
    throw new Error("AWS upload values must be passed");
  }

  const data = new FormData();
  Object.keys(awsFields.fields).forEach((key) => {
    data.append(key, awsFields.fields[key]);
  });

  data.append("file", media.file, media.filename);

  return uploadMediaToAWS(awsFields.url, data);
};

export const clearCurrentDraft = () => {
  return async (dispatch) => {
    try {
      dispatch({
        type: CLEAR_CURRENT_DRAFT
      });
    } catch (error) {
      dispatch({
        type: LOG_ERROR,
        payload: { error }
      });
      console.log(error.message);
    }
  };
};
