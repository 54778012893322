/** @format */

import { RESET_ALL } from "../actions/global.actions";
import {
  META_LISTENER_UPDATE,
  AVAILABLE_LISTENER_UPDATE,
  COMPLETED_LISTENER_UPDATE,
  META_UPDATE,
  AVAILABLE_UPDATE,
  COMPLETED_UPDATE,
  SUBMIT_CERTIFICATE_DEGREE,
  SUBMIT_CERTIFICATE_DEGREE_COMPLETE
} from "../actions/user-cme.actions";

import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain/";

const UserCmeReducer = (
  baseState = InitialReducerStates.USER_CME_INITIAL_STATE,
  action
) => {
  // Do everything in base first, and then anything custom after
  const state = { ...BrainReducers.UserCmeReducer(baseState, action) };

  switch (action.type) {
    case META_LISTENER_UPDATE:
      return { ...state, metaUnsub: action.metaUnsub };
    case AVAILABLE_LISTENER_UPDATE:
      return { ...state, availableUnsub: action.availableUnsub };
    case COMPLETED_LISTENER_UPDATE:
      return { ...state, completedUnsub: action.completedUnsub };
    case META_UPDATE:
      return { ...state, meta: action.meta };
    case AVAILABLE_UPDATE:
      return { ...state, availableActivities: action.availableActivities };
    case COMPLETED_UPDATE:
      return { ...state, completedActivities: action.completedActivities };
    case SUBMIT_CERTIFICATE_DEGREE:
      return { ...state, isProcessing: true };
    case SUBMIT_CERTIFICATE_DEGREE_COMPLETE:
      return { ...state, isProcessing: false };
    case RESET_ALL:
      return { ...baseState };
    default:
      return state;
  }
};

export default UserCmeReducer;
