import { toArray } from "lodash";
import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Card, CardBody, FormGroup, Label } from "reactstrap";
import { fetchInterestsIfNeeded } from "../../actions/reference.actions";
import Select from "react-select";
import i18n from "../../utils/i18n";
import { trackSettingsInterestsEntry } from "../../actions/metrics.actions";

const InterestSettings = ({ control, errors }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const [model, setModel] = useState({ interests: null });

  const interestsData = useSelector((state) => state?.references?.interests);
  const [interestOptions, setInterestOptions] = useState();
  const [existingValues, setExistingValues] = useState(null);

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    dispatch(fetchInterestsIfNeeded());
  }, [dispatch]);

  useEffect(() => {
    const newInterests = user?.interests?.map((x) => x.interestUuid) ?? [];
    setModel({ interests: newInterests });
  }, [user, user.interests]);

  const interestsArr = model?.interests;

  useEffect(() => {
    // check to see if there's any pre-defined values, if so, map to values
    // compatible with react select control
    if (user && Object.keys(interestsData).length) {
      if (model?.interests) {
        // will be set to _something_ when the effect above triggers
        const existingValues = [];
        model.interests.forEach((i) => {
          const interest = interestsData[i];
          if (interest) {
            existingValues.push({
              value: interest.interestUuid,
              label: interest.interestName
            });
          }
        });
        setExistingValues(existingValues || []);
      }

      setInterestOptions(
        toArray(interestsData)
          .map((interest) => ({
            value: interest.interestUuid,
            label: interest.interestName
          }))
          .sort((a, b) => (a.label > b.label ? 1 : -1))
      );
    }
  }, [user, model, interestsArr, interestsData]);

  useEffect(() => {
    trackSettingsInterestsEntry();
  }, []);

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/
  return (
    <>
      {interestsData && existingValues !== null && (
        <Card className="border-0">
          <CardBody>
            <FormGroup>
              <Label for="firstName" className="mb-1">
                {i18n.t("RegistrationScreens.profileSetup.interestsLabel")}
              </Label>
              <Controller
                className="mr-1"
                as={
                  <Select
                    options={interestOptions}
                    isClearable={false}
                    isMulti
                  />
                }
                control={control}
                defaultValue={existingValues}
                id="interests"
                name="interests"
                type="text"
                placeholder={i18n.t(
                  "RegistrationScreens.profileSetup.selectInterestsPlaceholder"
                )}
                rules={{ required: true }}
                invalid={!!errors.interests}
              />
              <div className="text-10 color-lighter-grey pl-1 pt-1">
                {i18n.t("RegistrationScreens.profileSetup.interestsLabel2")}
              </div>
            </FormGroup>
          </CardBody>
        </Card>
      )}
    </>
  );
};

export default InterestSettings;
