import React from "react";
import { CardBody } from "reactstrap";
import { getUrlWithRedirect } from "../../utils/route-utils";
import { SIGN_IN, SIGN_UP } from "../../constants/routes";
import { Link } from "react-router-dom";
import SocialContainer from "./SocialContainer";
import useAnonUserExists from "../../hooks/useAnonUserExists";
import useLoginModalClick from "../../hooks/useLoginModalClick";
import { trackUngatedCTA } from "../../actions/metrics.actions";
import { UNGATED_ACTIONS } from "../../metrics/constants.metrics";
import useSignUpModalClick from "../../hooks/useSignUpModalClick";

const GatedCommentBar = ({ caseDetails, disableClick, linkText }) => {
  /** ********************************** CONFIG ***************************************/
  const userExists = useAnonUserExists();
  const { showLoginModal } = useLoginModalClick();
  const { showSignUpModal } = useSignUpModalClick();

  const loginClick = {
    onClick: userExists
      ? (e) => {
          trackUngatedCTA(
            UNGATED_ACTIONS.CTA_LOCATION.LOGIN_COMMENT,
            userExists
          );
          showLoginModal(e);
        }
      : (e) => {
          trackUngatedCTA(
            UNGATED_ACTIONS.CTA_LOCATION.SIGNUP_COMMENT,
            userExists
          );
          showSignUpModal(e);
        }
  };

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <CardBody className="d-flex justify-content-start align-items-center text-13">
      <SocialContainer
        caseContent={caseDetails?.data}
        hideComments={false}
        hideReactions={true}
        userReactions={caseDetails.userReactions || []}
        activateListener={false}
        hideSave={true}
        iconSize={20}
        className="d-flex justify-content-between align-items-center mr-2"
        disableClick={disableClick}
      />
      <Link
        to={getUrlWithRedirect(userExists ? SIGN_IN : SIGN_UP)}
        style={{ marginBottom: "0.125rem" }}
        {...loginClick}>
        {linkText}
      </Link>
    </CardBody>
  );
};

export default GatedCommentBar;
