import React, { useEffect } from "react";
import PropTypes from "prop-types";
import Dialog from "../../../components/dialog/Dialog";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../../components/custom-icon/CustomIcon";
import QuestionSection from "./QuestionSection";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import questionActions from "../../../actions/question.actions";
import { userCmeActions } from "../../../actions/user-cme.actions";
import { isEmpty } from "lodash";
import { CME_QUESTION_TYPE } from "../../../constants/cme-section";

const CMESummaryModal = ({ isOpen, toggle, activity }) => {
  /************************************ CONFIG ***************************************/
  const dispatch = useDispatch();
  const completedCaseUuid = activity?.caseUuid;
  const cmeQuestions = useSelector((state) => state.question?.caseCMEQuestions);
  const cmeAnswers = useSelector(
    (state) =>
      state.userCme?.completedCmeAnswers &&
      state.userCme?.completedCmeAnswers[completedCaseUuid]
  );
  const completedDate = moment(activity?.completedAt)?.format("MMMM DD, YYYY");
  const summary = (() => {
    const queAnsArray = [];
    cmeAnswers?.forEach((answerObj) => {
      const questionObj = cmeQuestions?.find(
        (que) => que?.questionUuid === answerObj?.questionUuid
      );
      const answer = [];
      if (questionObj?.questionType === CME_QUESTION_TYPE.FREE_FORM) {
        answer.push(answerObj?.userAnswerText);
      } else {
        answer.push(
          ...Object.values(answerObj?.questionAnswerOption)
            ?.filter((que) => !que?.hasExtraInput)
            ?.sort((a, b) => a?.displayOrder - b?.displayOrder)
            ?.map((que) => que?.answerText)
        );
        if (
          !isEmpty(
            Object.values(answerObj?.questionAnswerOption)?.filter(
              (que) => que?.hasExtraInput
            )
          )
        ) {
          answer.push(answerObj?.userAnswerText);
        }
      }
      queAnsArray.push({
        questionUuid: questionObj?.questionUuid,
        question: questionObj?.questionText,
        answer: answer.join(", ")
      });
    });
    return queAnsArray;
  })();
  /************************************ HOOKS ****************************************/
  useEffect(() => {
    dispatch(questionActions.fetchCaseCMEQuestionsIfNeeded());
  }, []);

  useEffect(() => {
    dispatch(userCmeActions.fetchCompletedCmeAnswers(completedCaseUuid));
  }, [dispatch, completedCaseUuid]);
  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  return (
    <Dialog
      isOpen={isOpen}
      onCancel={toggle}
      toggle={toggle}
      fullWidthBody
      body={
        <div className="w-100">
          <h2 className="d-flex align-items-center justify-content-center flex-grow-1 helv-med text-20 line-height-26 mt-2 mb-4">
            {i18n.t("CMESummaryModal.title")}
          </h2>
          <div className="bg-lighter-gray px-4 py-5 d-flex justify-content-between">
            <div className="text-left">
              <h2 className="m-0 helv-bold text-26 line-height-30">
                {i18n.t("CMESummaryModal.youCompletedThis")}
              </h2>
              <h2 className="m-0 helv-bold text-26 line-height-30">
                {i18n.t("CMESummaryModal.activity")}
              </h2>
              <h2 className="mt-2 helv-standard text-18">{completedDate}</h2>
            </div>
            <CustomIcon className="mr-3 mt-n3" icon="CME_success" size={100} />
          </div>
          <div className="px-4 mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="m-0 helv-med text-22 line-height-28">
                {i18n.t("CMESummaryModal.creditsEarned")}
              </h2>
              <span className="d-flex align-items-center">
                <CustomIcon icon="drawer_CME" className="icon-aqua" size={26} />
                <h2 className="m-0 helv-med text-22 line-height-28">
                  {activity?.credits}
                </h2>
              </span>
            </div>
            <div className="mt-5 text-left">
              <p className="m-0 helv-med text-13 text-battleship-gray text-uppercase mb-3">
                {i18n.t("CMESummaryModal.yourActivity")}
              </p>
              <div className="pre-scrollable">
                {summary.map((item) => (
                  <QuestionSection
                    key={item.questionUuid}
                    questionText={item.question}
                    answerText={item.answer}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      }
      customFooter={<></>}
    />
  );
};

CMESummaryModal.propTypes = {
  toggle: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired
};

CMESummaryModal.defaultProps = {};

export default CMESummaryModal;
