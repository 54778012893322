import React from "react";
import { defaultFeatures } from "../../constants/case-feature-constants";
import useShareCase from "../../hooks/useShareCase";
import i18n from "../../utils/i18n";
import CaseDropdown from "./CaseDropdown";
import ShareButton from "./ShareButton";

const CaseActions = ({
  caseContent,
  caseNotification,
  commentStatus,
  from,
  hideShareText,
  onShareClick,
  context,
  location
}) => {
  /** ********************************** CONFIG ***************************************/
  const { features = defaultFeatures } = caseContent;
  const showCaseDropdown = features.reportEnabled;
  const { shareCase } = useShareCase({ caseContent, location, context });

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div className="d-inline-flex justify-content-end align-items-center">
      <ShareButton
        className={
          showCaseDropdown ? "btn-text px-2 py-1" : "btn-text px-2 py-1 mr-n2"
        }
        iconClassName={hideShareText ? "" : "mr-2"}
        text={hideShareText ? null : i18n.t("CaseAction.sharePost")}
        onClick={(e) => {
          shareCase();
          onShareClick(e);
        }}
      />
      {showCaseDropdown && (
        <CaseDropdown
          caseContent={caseContent}
          caseNotification={caseNotification}
          from={from}
          commentStatus={commentStatus}
        />
      )}
    </div>
  );
};

export default CaseActions;
