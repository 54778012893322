// Authentication Routes
export const ROOT = `/`;
export const SIGN_IN = `/login`;
export const SIGN_UP = `/registration/new`;
export const SIGN_OUT = `/logout`;
export const NOT_FOUND = "/404";

// Home Routes
export const HOME_ROOT_TOPIC = `/home/:feed/:feedTypeUuid`;
export const HOME_ROOT_FEED = `/home/:feed`;
export const HOME_ROOT = `/home`;
export const NOT_AUTHORIZED = `${HOME_ROOT}/not-authorized`;

// Groups Routes
export const GROUPS_HOME = `/groups`;
export const GROUPS_FEED = `/groups/:feedTypeUuid`;
export const GROUPS_FEED_INVITE = `/groups/:feedTypeUuid/invite`;
export const GROUPS_MEMBERS = `/groups/:feedTypeUuid/members`;

// Feed Routes
export const CASE_DETAIL = `/case-detail/:caseId`;
export const CASE_COMMENT_DETAIL = `/case-detail/:caseId/comment/:commentId`;
export const CASE_DETAIL_UPDATE = `${CASE_DETAIL}/update`;
export const MANAGE_CHANNELS = `/manage-channels`;
export const CASES = `/cases/:caseId`;
export const CASE_CME = `/case-detail/:caseId/cme`;
export const CASE_CME_FRONTMATTER = `/case-detail/:caseId/cme/frontmatter`;
export const CASE_CME_INELIGIBLE = `/case-detail/:caseId/cme/ineligible`;

// Case Explorer Routes
export const CASE_EXPLORER_ROOT = `/case-explorer`;

// Case Explorer Routes
export const CME_ROOT = `/cme`;
export const CME_CERTIFICATE = `${CME_ROOT}/:caseId/certificate`;
export const CME_POST_NOTICE = `${CME_ROOT}/:caseId/postNotice`;

// Clinical Moments Routes
export const CLINICAL_MOMENTS_ROOT = `/clinical-moments`;
export const CLINICAL_MOMENTS_DETAIL = `${CLINICAL_MOMENTS_ROOT}/:caseId`;
export const CLINICAL_MOMENTS_COMMENT_DETAIL = `${CLINICAL_MOMENTS_ROOT}/:caseId/comment/:commentId`;
export const CLINICAL_MOMENTS_ACTIVITY = `${CLINICAL_MOMENTS_ROOT}/:caseId/activity`;

export const CREATE_NEW_CASE_POST_OPTIONS = `/create-new`;

// Clinical Cases
export const CASE_POSTING = `/draft-detail/case`;
export const CASE_POSTING_NEW = `${CASE_POSTING}/new`;
export const CASE_POSTING_EDIT = `${CASE_POSTING}/:draftUid`;

// Posts
export const POST = `/draft-detail/post`;
export const POST_NEW = `${POST}/new`;
export const POST_EDIT = `${POST}/:draftUid`;

// Drafts Routes
export const DRAFTS_ROOT = `/drafts`;

// Saved Cases Routes
export const SAVED_CASES_ROOT = `/saved-cases`;

// Saved Cases Routes
export const ACCOUNT_SETTINGS_ROOT = `/account-settings`;

// Notifications Routes
export const NOTIFICATIONS_ROOT = `/notifications`;

// Search Routes
export const SEARCH_ROOT = `/search`;
export const SEARCH_PATH = `/search/:tab`;

// Profile Routes
export const PROFILE_ROOT = `/profile`;
export const PROFILE_DETAIL = `${PROFILE_ROOT}/:userUuid`;
export const PROFILE_USERNAME_REDIRECT = `${PROFILE_ROOT}/name/:username`;
export const PROFILE_EDIT_INFO = `${PROFILE_DETAIL}/edit/info`;
export const PROFILE_EDIT_IMAGE = `${PROFILE_DETAIL}/edit/image`;
export const PROFILE_EDIT_SUBSPECIALTIES = `${PROFILE_DETAIL}/edit/subspecialties`;
export const PROFILE_EDIT_PRACTICE_AREAS = `${PROFILE_DETAIL}/edit/practiceareas`;
export const PROFILE_EDIT_EXPERIENCE = `${PROFILE_DETAIL}/edit/experience`;
export const PROFILE_EDIT_EDUCATION = `${PROFILE_DETAIL}/edit/education`;
export const PROFILE_EDIT_MEMBERSHIP = `${PROFILE_DETAIL}/edit/membership`;
export const PROFILE_NETWORK = `${PROFILE_DETAIL}/network`;
export const PROFILE_NETWORK_TYPE = `${PROFILE_DETAIL}/network/:viewType`;

// Registration Routes
export const REGISTRATION = `/registration`;
export const REGISTRATION_NEW_V2 = `/registration/new`;
export const REGISTRATION_ONBOARDING_V2 = `/registration/onboarding`;
export const REGISTRATION_COUNTRY_V2 = `/registration/country`;
export const REGISTRATION_INFORMATION_V2 = `/registration/information`;
export const REGISTRATION_NPI_CONFIRMATION = `/registration/npi-confirmation`;
export const REGISTRATION_VERFICATION_PHOTO_V2 = `/registration/photo-verification`;
export const REGISTRATION_USERNAME_V2 = `/registration/username`;
export const REGISTRATION_ONBOARDING_NON_HCP = `/registration/onboarding/non-hcp`;
export const REGISTRATION_VERFICATION_COMPLETE = `/registration/complete`;

// forgot-password routes
export const FORGOT_PASSWORD_OPTIONS = `/forgot-password`;
export const GENERATE_LOGIN_LINK = `/generate-login-link`;
export const RESET_PASSWORD = `/reset-password`;

export const LOGIN_LINK_PREFIX = `/__/auth/action`;
export const ACCEPT_LOGIN_LINK = `/accept-login-link`;

// auto-registration
export const AUTO_REGISTRATION = `/auto-registration/:email/:npi`;
export const AUTO_REGISTRATION_INFORMATION = `/auto-registration/information`;

// Group Registration Routes
export const CLAIM_GROUP = `/claim/:groupUuid`;
export const CLAIM_GROUP_WITH_PROFILE = `/claim/profile/:groupFilterUuid`;
export const GROUP_WELCOME_SPLASH = `/groupwelcome/:groupUuid`;

// Legacy Routes

export const LEGACY_HELLO = `/hello`;
export const LEGACY_RD = `/rd`;
export const LEGACY_REDIRECT = `/rfy`;

// Settings Routes

export const SETTINGS_ROOT = `/settings`;
export const SETTINGS_INTERESTS = `/settings/interests`;
export const SETTINGS_PROFILE = `/settings/profile`;
// can eventually swap this to a variable
export const SETTINGS_PROFILE_EDIT = `/settings/profile/edit`;
export const SETTINGS_PROFILE_VERIFICATION = `/settings/profile/verification`;
export const SETTINGS_ACCOUNT = `/settings/account`;
export const SETTINGS_ACCOUNT_DELETE = `/settings/account/delete`;
