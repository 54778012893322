import React, { useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import CommentSortDropdown from "./CommentSortDropdown";
import CommentInput from "./CommentInput";
import CommentBlock from "./CommentBlock";
import { COMMENT_TYPES } from "../../constants/case-constants";
import PropTypes from "prop-types";
import { cacheComment, postComment } from "../../actions/comment.actions";
import usePopup from "../../components/popup/usePopup";
import TOAST_TYPE from "../../components/popup/ToastType";
import i18n from "../../utils/i18n";
import { Card, CardBody } from "reactstrap";
import useTheme from "../theme/useTheme";
import classNames from "../../utils/class-names-utils";
import THEMES from "../theme/Theme";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { trackComment } from "../../actions/metrics.actions";
import useIsGated from "../../hooks/useIsGated";
import { useIsCaseAuthor } from "../../hooks";
import F1AlertBoxWithArrow from "../common/F1AlertBoxWithArrow";
import UnresolvedIcon from "../../assets/images/unresolved_icon.svg";
import { CASE_LABEL_TYPES } from "../../constants/case-label-types";
import CustomIcon from "../custom-icon/CustomIcon";
import { isEmpty } from "lodash";

const CommentContainer = ({
  caseUuid,
  contentUuid,
  onChangeSort,
  caseContent,
  commentStatus,
  position,
  isGroup = false,
  isMedicalCase = true
}) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const { showToast } = usePopup();
  const commentRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { theme } = useTheme();
  const user = useSelector((state) => state.user);
  const commentQueueEnabled = caseContent?.features?.commentQueueEnabled;
  const isGated = useIsGated();
  const isCaseAuthor = useIsCaseAuthor(caseContent);

  const unresolved = caseContent?.labels?.includes(CASE_LABEL_TYPES.UNRESOLVED);
  const requestHelp = caseContent?.requestHelp;
  const isPagingCase = caseContent?.isPagingCase;
  const hasAcceptedAnswer = !isEmpty(caseContent?.acceptedAnswer);
  const showAlert =
    !isCaseAuthor &&
    unresolved &&
    !hasAcceptedAnswer &&
    (requestHelp || isPagingCase);
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  const onPost = async ({ text, parentComment, isAnonComment }) => {
    try {
      setLoading(true);

      const result = await dispatch(
        postComment(
          caseUuid,
          contentUuid,
          text,
          parentComment ? parentComment.commentUuid : null
        )
      );
      trackComment({
        caseContent,
        commentStatus,
        charCount: text.length,
        isAuthor: isCaseAuthor,
        position,
        isAnonymous: isAnonComment
      });

      setLoading(false);

      if (!result.error) {
        showToast({
          message: i18n.t("comments.addCommentSuccess"),
          toastType: TOAST_TYPE.SUCCESS
        });

        // cache comment locally if comment queue is enabled
        if (commentQueueEnabled) {
          dispatch(
            cacheComment(caseUuid, contentUuid, {
              text: text,
              parentUuid: parentComment?.commentUuid,
              author: user,
              authorUuid: user?.userUuid,
              avatar: user?.avatar,
              username: user?.username,
              verified: user?.verified,
              createdAt: new Date().toISOString(),
              replyable: false,
              commentUuid: "cache",
              professionLabel: user?.primarySpecialty?.caseCommentDisplayName
            })
          );
        }

        // reset the comment
        commentRef.current.value = null;
        return true;
      } else {
        showToast({
          message: i18n.t("comments.addCommentFailure"),
          toastType: TOAST_TYPE.ERROR
        });
        return false;
      }
    } catch (error) {
      console.log("Post comment error: ", error.message);
      return false;
    }
  };

  const verifiedOnPost = useVerifiedUserGate(onPost);
  const verifiedOnFocus = useVerifiedUserGate(() => {});
  const alertTextKey = isMedicalCase
    ? "comments.alert.text"
    : "comments.alert.postText";
  /** ********************************** RENDER ***************************************/
  const AlertText = isPagingCase ? (
    <>
      <CustomIcon className="mr-1" icon="paging" size="20" />
      <span className="helv-bold mr-1">
        {i18n.t("comments.alert.paging")}
      </span>{" "}
      {i18n.t(alertTextKey)}
    </>
  ) : (
    <>
      <img src={UnresolvedIcon} alt="" className="mr-2 mt-n1" />
      {i18n.t(alertTextKey)}
    </>
  );
  return (
    <>
      <Card
        className="border-0"
        color={theme === THEMES.DARK ? "dark-gray" : null}>
        <CardBody
          className={classNames(
            isGated ? "" : "pb-0",
            "border-bottom border-width-3",
            theme === THEMES.DARK
              ? "border-charcoal-gray"
              : "border-lighter-gray"
          )}>
          {showAlert && (
            <F1AlertBoxWithArrow
              position="top"
              className="text-default-black text-13"
              arrowClassName="arrow-x-right"
              color={isPagingCase ? "alert-red" : "alert-yellow"}
              text={AlertText}
            />
          )}
          <CommentInput
            ref={commentRef}
            commentType={COMMENT_TYPES.COMMENT}
            onPost={verifiedOnPost}
            onFocus={verifiedOnFocus}
            loading={loading}
            isGroup={isGroup}
            borderRadius={isGroup ? "4px 4px 0 0" : ""}
            caseContent={caseContent}
            isMedicalCase={isMedicalCase}
          />

          {!isGated && (
            <div
              className={classNames(
                "py-2 d-flex justify-content-end align-items-bottom",
                theme === THEMES.DARK ? "" : "text-denim-blue"
              )}>
              <div className="text-13">
                <span>{i18n.t("common.commentsSortBy")}</span>
              </div>
              <CommentSortDropdown onChangeSort={onChangeSort} />
            </div>
          )}
        </CardBody>
      </Card>
      {isGated && (
        <Card
          className="border-0"
          color={theme === THEMES.DARK ? "dark-gray" : null}>
          <CardBody
            className={classNames(
              "pb-0 border-bottom border-width-3",
              theme === THEMES.DARK
                ? "border-charcoal-gray"
                : "border-lighter-gray"
            )}>
            <div
              className={classNames(
                "py-2 d-flex align-items-bottom",
                theme === THEMES.DARK ? "" : ""
              )}>
              <CommentBlock
                campaignUuid={caseContent.campaignUuid}
                caseUuid={caseContent.caseUuid}
                caseType={caseContent.caseType}
              />
            </div>
          </CardBody>
        </Card>
      )}
    </>
  );
};

CommentContainer.propTypes = {
  caseUuid: PropTypes.string.isRequired,
  contentUuid: PropTypes.string.isRequired,
  onChangeSort: PropTypes.func.isRequired
};

export default CommentContainer;
