import React from "react";
import BaseDialogBody from "../../components/dialog/BaseDialogBody";
import i18n from "../../utils/i18n";
import PostsPromoImg from "../../assets/images/posts_image_promo.png";
import CustomIcon from "../../components/custom-icon/CustomIcon";

const PostsDialogContents = () => {
  /** ********************************** CONFIG ***************************************/
  const bullets = [
    "WhatsNew.Posts.point1",
    "WhatsNew.Posts.point2",
    "WhatsNew.Posts.point3",
    "WhatsNew.Posts.point4",
    "WhatsNew.Posts.point5"
  ];

  /** ********************************** HOOKS ***************************************/

  /** ********************************** FUNCTIONS ***************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <div className="bg-lighter-gray text-center mb-3">
        <img src={PostsPromoImg} alt="" style={{ maxWidth: "100%" }} />
      </div>
      <BaseDialogBody>
        <div className="text-center">
          <h1 className="text-22 helv-bold mb-2">
            {i18n.t("WhatsNew.Posts.subTitle")}
          </h1>
          <p className="text-16 line-height-24">
            {i18n.t("WhatsNew.Posts.description")}
          </p>
        </div>
        <div className="mx-2">
          <ul className="d-inline-flex flex-wrap list-unstyled mb-0 justify-content-around pb-0">
            {bullets.map((str, i) => {
              return (
                <li key={i} className="text-16 m-0 mb-3 helv-med">
                  <CustomIcon
                    icon="verification_check_mobile"
                    size={15}
                    className="icon-aqua mr-2"
                  />
                  {i18n.t(str)}
                </li>
              );
            })}
          </ul>
        </div>
      </BaseDialogBody>
    </>
  );
};

export default PostsDialogContents;
