import React from "react";
import { Card, CardBody, CardFooter, CardText } from "reactstrap";
import PropTypes from "prop-types";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";
import ExternalTextLink from "../../pages/authentication/components/ExternalTextLink";

const CaseScientificLiteratureCardItem = ({ title, url, onClick }) => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Card className="h-100">
      <CardBody className="p-3">
        <CardText className="text-clamp-snug-5">{title}</CardText>
      </CardBody>
      <CardFooter className="border-0 bg-white pt-0 pb-3 px-3">
        <ExternalTextLink
          className="d-inline-flex justify-content-start align-items-baseline text-cool-blue"
          url={url}
          linkText={
            <>
              {i18n.t("ScientificLiterature.readArticle")}
              <CustomIcon
                className="icon-cool-blue ml-2"
                icon="external_link"
                size={9}
              />
            </>
          }
          trackClick={onClick}
        />
      </CardFooter>
    </Card>
  );
};

CaseScientificLiteratureCardItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
};

export default CaseScientificLiteratureCardItem;
