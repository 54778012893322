/** @format */

import { RESET_ALL } from "../actions/global.actions";
import {
  POST_VERIFICATION_REQUEST_START,
  POST_VERIFICATION_REQUEST_COMPLETE,
  UPDATE_VERIFICATION_PICTURE_INFO,
  POST_VERIFICATION_PICTURE_START,
  POST_VERIFICATION_PICTURE_COMPLETE,
  POST_NOTIFICATION_REQUEST_START,
  POST_NOTIFICATION_REQUEST_COMPLETE,
  RESET_STATE,
  FETCH_INFO_WITH_NPI_COMPLETE,
  FETCH_INFO_WITH_NPI,
  CLEAR_FETCH_NPI_INFO,
  CLEAR_VERIFICATION_PICTURE_INFO,
  SET_TRANSIENT_EDIT_DETAILS,
  CLEAR_TRANSIENT_EDIT_DETAILS
} from "../actions/user-verification.actions";

export const INITIAL_STATE = {
  data: null,
  npiData: null,
  attemptedNpiNumber: null,
  selected: {},
  isProcessing: false,
  incomplete: false,
  error: null,
  message: null,
  verificationPictureInfo: null,
  verificationRequestState: "none",
  notificationSent: false,
  transientEditDetails: {}
};

const userVerificationReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case POST_VERIFICATION_REQUEST_START:
      return {
        ...state,
        isProcessing: true
      };
    case POST_VERIFICATION_REQUEST_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        message: action.message,
        verificationRequestState: action.error ? "none" : "pending"
      };
    case UPDATE_VERIFICATION_PICTURE_INFO:
      if (action.payload.verificationPictureInfos) {
        return {
          ...state,
          verificationPictureInfos: action.payload.verificationPictureInfos
        };
      } else {
        return {
          ...state,
          verificationPictureInfo: action.payload.verificationPictureInfo
        };
      }
    case CLEAR_VERIFICATION_PICTURE_INFO: {
      return {
        ...state,
        verificationPictureInfos: null,
        verificationPictureInfo: null
      };
    }
    case POST_VERIFICATION_PICTURE_START:
      return {
        ...state,
        isProcessing: true,
        error: null,
        message: null
      };
    case POST_VERIFICATION_PICTURE_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        message: action.message,
        verificationRequestState: action.error ? "none" : "pending"
      };
    case POST_NOTIFICATION_REQUEST_START:
      return {
        ...state,
        isProcessing: true,
        error: null,
        message: null
      };
    case POST_NOTIFICATION_REQUEST_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        message: action.message,
        notificationSent: action.error ? false : true
      };
    case FETCH_INFO_WITH_NPI:
      return {
        ...state,
        isProcessing: true,
        error: null,
        message: null,
        npiData: null,
        attemptedNpiNumber: null,
        incomplete: false
      };
    case FETCH_INFO_WITH_NPI_COMPLETE:
      return {
        ...state,
        isProcessing: false,
        error: action.error,
        message: action.message,
        npiData: action.payload?.data,
        attemptedNpiNumber: action.payload?.npiNumber,
        incomplete: action.payload?.incomplete
      };
    case CLEAR_FETCH_NPI_INFO:
      return {
        ...state,
        isProcessing: false,
        error: null,
        message: null,
        npiData: null,
        attemptedNpiNumber: null,
        incomplete: false
      };
    case SET_TRANSIENT_EDIT_DETAILS: {
      return {
        ...state,
        transientEditDetails: { ...(action.payload?.details || {}) }
      };
    }
    case CLEAR_TRANSIENT_EDIT_DETAILS: {
      return {
        ...state,
        transientEditDetails: {}
      };
    }
    case RESET_ALL:
    case RESET_STATE:
      return { ...INITIAL_STATE };
    default:
      return state;
  }
};

export default userVerificationReducer;
