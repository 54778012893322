import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Card from "reactstrap/lib/Card";
import PropTypes from "prop-types";
import { Controller, useForm } from "react-hook-form";
import i18n from "../../../utils/i18n";
import * as ROUTES from "../../../constants/routes";
import LoadingButton from "../../../components/common/LoadingButton";
import { authCheckAndSignIn } from "../../../actions/non-user.actions";
import { StaticStrings } from "../../../constants/static-string-constants";
import ExternalTextLink from "./ExternalTextLink";
import FormElementPassword from "./FormElementPassword";
import { Link, useHistory } from "react-router-dom";
import { trackEvent, trackUserSignIn } from "../../../actions/metrics.actions";
import { isMobile } from "react-device-detect";
import FormGlobalError from "./FormGlobalError";
import {
  METRICS_EVENT_NAME,
  METRICS_USER_PROPERTY
} from "../../../metrics/constants.metrics.js";
import { Button, FormFeedback, FormGroup, Input, Label } from "reactstrap";
import { useContactSupport } from "../../../hooks/useContactSupport";
import { trim } from "lodash";

const ClaimGroupSigninForm = ({ userEmail, groupUuid, switchFlow }) => {
  /** ********************************** CONFIG ***************************************/
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, control, errors } = useForm({
    mode: "onChange"
  });

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const onContactSupport = useContactSupport();

  /****************************** Hooks ***************************************/

  /** ********************************* FUNCTIONS *************************************/
  const handleSubmitClick = async (loginData) => {
    try {
      setIsLoading(true);
      trackUserSignIn({ groupUuid });
      const result = await dispatch(
        authCheckAndSignIn(
          trim(loginData.email || userEmail),
          loginData.password,
          true
        )
      );
      if (result.error) {
        setIsLoading(false);
        setErrorMessage(result.message);
      } else {
        trackUserSignIn({ afterSignInsuccess: true, groupUuid });
      }
    } catch (e) {
      trackEvent(METRICS_EVENT_NAME.ERROR, {
        [METRICS_USER_PROPERTY.ERROR_MESSAGE]: e.message
      });
      setIsLoading(false);
      setErrorMessage(e.message);
    }
  };

  const handleForgotPasswordClick = () => {
    history.push(ROUTES.FORGOT_PASSWORD_OPTIONS);
  };
  const signupSection = (
    <p className="w-100 mb-0 text-battleship-gray text-center text-15">
      {i18n.t("RegistrationScreens.claimGroupPage.dontHaveAccount")}
      <Link
        className="text-cool-blue cursor-pointer pl-1 font-weight-bolder"
        onClick={switchFlow}>
        {i18n.t("RegistrationScreens.claimGroupPage.btnSignUp")}
      </Link>
    </p>
  );

  /** ********************************** RENDER ***************************************/

  return (
    <>
      <Card className="p-4 mb-4 rounded-0 shadow">
        <form onSubmit={handleSubmit(handleSubmitClick)}>
          <FormGlobalError errorText={errorMessage} />
          {!userEmail && (
            <FormGroup className="mb-4">
              <Label for="email">
                {i18n.t("RegistrationScreens.claimGroupPage.emailOrUsername")}
              </Label>
              <Controller
                as={Input}
                control={control}
                disabled={isLoading}
                id="email"
                name="email"
                type="text"
                invalid={!!errors.email}
                placeholder={i18n.t(
                  "RegistrationScreens.claimGroupPage.emailOrUsernamePlaceholder"
                )}
                rules={{ required: true }}
              />
              <FormFeedback>
                {i18n.t("RegistrationScreens.claimGroupPage.emailRequired")}
              </FormFeedback>
            </FormGroup>
          )}
          <FormElementPassword
            control={control}
            errors={errors}
            isLoading={isLoading}
            showFeedback={true}
            useHelpAsError="true"
            helpText={i18n.t(
              "RegistrationScreens.claimGroupPage.passwordRequired"
            )}
          />
          <p className="mb-0">
            <Button
              color="link"
              className="pl-0 text-cool-blue text-15"
              onClick={handleForgotPasswordClick}>
              {i18n.t("RegistrationScreens.loginForm.forgotPassword")}
            </Button>
          </p>
          <p className="mt-1 mb-3">
            <Button
              color="link"
              className=" pl-0 text-cool-blue text-15"
              onClick={onContactSupport}>
              {i18n.t("RegistrationScreens.loginForm.iDontKnowUsername")}
            </Button>
          </p>
          <LoadingButton
            className="mt-2 w-100"
            color="primary"
            size="lg"
            loading={isLoading}
            spinnerColor="white"
            disabled={isLoading}>
            {i18n.t("RegistrationScreens.claimGroupPage.btnSignIn")}
          </LoadingButton>
        </form>
      </Card>

      <p className="text-center text-15 mb-4">
        <span className="text-battleship-gray">
          {i18n.t("RegistrationScreens.claimGroupPage.bySigningIn")}
        </span>
        {isMobile && <br />}
        <ExternalTextLink
          className="text-cool-blue cursor-pointer"
          url={StaticStrings.termsOfServiceUrl}
          linkText={i18n.t(
            "RegistrationScreens.claimGroupPage.termsAndConditionsLink"
          )}
        />
      </p>

      <Card className="p-4 mb-4 rounded-0 shadow">{signupSection}</Card>
    </>
  );
};

ClaimGroupSigninForm.propTypes = {
  registrationState: PropTypes.string,
  setRegistrationState: PropTypes.func
};

export default ClaimGroupSigninForm;
