import React from "react";
import PagingIcon from "../common/PulsatingDot";
import { Row } from "reactstrap";
import i18n from "../../utils/i18n";

const PagingHeader = () => {
  /****************************** Config **************************************/

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/
  return (
    <Row className="paging-container d-flex justify-content-center helv-bold mx-0">
      <div className="paging-dot">
        <PagingIcon />
      </div>
      <div>{i18n.t("CaseCard.pagingLabel")}</div>
    </Row>
  );
};

PagingHeader.propTypes = {};

export default PagingHeader;
