import React from "react";
import i18n from "../../../utils/i18n";
import CustomIcon from "../../custom-icon/CustomIcon";

const AcceptedAnswerInfobox = () => {
  return (
    <div className="d-flex mb-3 align-items-center">
      <CustomIcon
        icon="select_accepted_answer_inverted"
        className="mr-2"
        size={20}
      />
      <p className="m-0 text-13">{i18n.t("comments.acceptedAnswerInfoText")}</p>
    </div>
  );
};

export default AcceptedAnswerInfobox;
