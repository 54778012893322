import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../utils/i18n";
import CommentInput from "./CommentInput";
import CustomIcon from "../custom-icon/CustomIcon";
import PropTypes from "prop-types";
import { cacheComment, postComment } from "../../actions/comment.actions";
import { COMMENT_TYPES } from "../../constants/case-constants";
import usePopup from "../../components/popup/usePopup";
import TOAST_TYPE from "../../components/popup/ToastType";
import useVerifiedUserGate from "../../hooks/use-verified-user.hooks";
import { trackComment } from "../../actions/metrics.actions";

const ReplyContainer = ({
  replyTo,
  caseUuid,
  parentComment,
  contentUuid,
  onCloseClick,
  onExpandReplies,
  position,
  caseContent,
  commentStatus,
  isGroup = false
}) => {
  /** ********************************** CONFIG ***************************************/

  const dispatch = useDispatch();
  const { showToast, hideToast } = usePopup();
  const [replyToText, setReplyToText] = useState(null);
  const [loading, setLoading] = useState(false);
  const commentRef = useRef(null);
  const author = caseContent.authors ? caseContent.authors[0] : null;
  const user = useSelector((state) => state.user);
  const commentQueueEnabled = caseContent?.features?.commentQueueEnabled;
  const isAnonymous = parentComment.isAnonymous;

  /** ********************************** HOOKS ****************************************/

  useEffect(() => {
    if (replyTo) {
      setReplyToText(`${i18n.t("comments.replyTo")} ${replyTo}`);
    } else if (isAnonymous) {
      setReplyToText(
        `${i18n.t("comments.replyTo")} ${i18n.t("comments.author")}`
      );
    }
  }, [replyTo, isAnonymous]);

  /** ********************************* FUNCTIONS *************************************/

  const onPost = async ({ text, isAnonComment }) => {
    try {
      setLoading(true);

      const result = await dispatch(
        postComment(
          caseUuid,
          contentUuid,
          text,
          parentComment ? parentComment.commentUuid : null
        )
      );

      trackComment({
        caseContent,
        commentStatus,
        charCount: text.length,
        isAuthor: author.userUuid === user?.userUuid,
        position,
        isAnonymous: isAnonComment
      });

      setLoading(false);

      if (!result.error) {
        showToast({
          message: i18n.t("comments.addReplySuccess"),
          toastType: TOAST_TYPE.SUCCESS
        });

        // cache comment locally if comment queue is enabled
        if (commentQueueEnabled) {
          dispatch(
            cacheComment(caseUuid, contentUuid, {
              text: text,
              parentUuid: parentComment?.commentUuid,
              author: user,
              authorUuid: user?.userUuid,
              avatar: user?.avatar,
              username: user?.username,
              verified: user?.verified,
              createdAt: new Date().toISOString(),
              replyable: false,
              commentUuid: "cache",
              professionLabel: user?.primarySpecialty?.caseCommentDisplayName
            })
          );
        }

        // reset the comment
        commentRef.current.value = null;
        handleCloseReply();
      } else {
        showToast({
          message: i18n.t("comments.addReplyFailure"),
          toastType: TOAST_TYPE.ERROR
        });
      }

      setTimeout((t) => {
        hideToast();
      }, 3000);
    } catch (error) {
      console.log("Post comment error: ", error.message);
    }
  };

  const handleCloseReply = () => {
    onCloseClick();
    onExpandReplies();
  };

  const verifiedOnPost = useVerifiedUserGate(onPost);

  /** ********************************** RENDER ***************************************/
  return (
    <div className="pb-2">
      <div
        style={{ height: "44px" }}
        className="px-3 bg-battleship-gray d-flex align-items-center justify-content-between">
        <div className="text-white">{replyToText}</div>
        <div className="cursor-pointer" onClick={handleCloseReply}>
          <CustomIcon icon="modal_exit" color="white" size={14} />
        </div>
      </div>
      <CommentInput
        ref={commentRef}
        onPost={verifiedOnPost}
        commentType={COMMENT_TYPES.REPLY}
        borderRadius={isGroup ? "0" : "0 0 4px 4px"}
        loading={loading}
        isGroup={isGroup}
        parentComment={parentComment}
        caseContent={caseContent}
      />
    </div>
  );
};

ReplyContainer.propTypes = {
  replyTo: PropTypes.string.isRequired,
  caseUuid: PropTypes.string.isRequired,
  parentComment: PropTypes.object.isRequired,
  contentUuid: PropTypes.string.isRequired,
  onCloseClick: PropTypes.func.isRequired,
  onExpandReplies: PropTypes.func
};

export default ReplyContainer;
