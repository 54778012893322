import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { setRedirectRoute } from "../actions/login.actions";

const useRedirectLinkHook = () => {
  const dispatch = useDispatch();
  const loc = useLocation();
  const history = useHistory();
  const urlParams = new URLSearchParams(loc.search);
  const redirect = urlParams.get("redirect");

  useEffect(() => {
    if (redirect) {
      dispatch(setRedirectRoute(redirect));
      history.replace(loc.pathname, {});
    }
  }, [history, dispatch, loc, redirect]);
};

export default useRedirectLinkHook;
