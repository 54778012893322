import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { useParams } from "react-router-dom";
import { Button, Card } from "reactstrap";
import { trackGroupEvent } from "../../actions/metrics.actions";
import CustomIcon from "../../components/custom-icon/CustomIcon";
import useCurrentGroup from "../../hooks/useCurrentGroup";
import useIsGroupFeed from "../../hooks/useIsGroupFeed";
import { METRICS_EVENT_NAME } from "../../metrics/constants.metrics";
import { isInstitutionGroup } from "../../utils/GroupUtils";
import i18n from "../../utils/i18n";
import GroupOptionsDropdown from "./GroupOptionsDropdown";
import PrivateGroupsModal from "./PrivateGroupsModal";

const GroupsFeedBanner = ({ showMembersModal, verifiedToggleInvite }) => {
  /** ********************************** CONFIG ***************************************/
  const routeParams = useParams();

  const [isPrivateGroupsModalOpen, setPrivateGroupsModalOpen] = useState(false);

  const isGroupFeed = useIsGroupFeed(routeParams.feedTypeUuid);
  const currentGroup = useCurrentGroup(routeParams.feedTypeUuid);
  const isInstitutionalGroup = isInstitutionGroup(currentGroup);

  /** ********************************** HOOKS ****************************************/
  /** ********************************* FUNCTIONS *************************************/
  const togglePrivateGroupsModal = () => {
    if (!isPrivateGroupsModalOpen) {
      trackGroupEvent({
        eventName: METRICS_EVENT_NAME.SCREEN.GROUPS.GROUP_NAME_CLICK
      });
    }
    setPrivateGroupsModalOpen((prevState) => !prevState);
  };
  /** ********************************** RENDER ***************************************/
  if (!isGroupFeed) return null;

  return (
    <Card className={`border-outline-gray ${isMobile ? "mb-2" : "mb-3"}`}>
      <div className="d-flex justify-content-between align-item-center bg-white p-3">
        <div>
          <h2
            onClick={() => {
              if (isMobile) {
                togglePrivateGroupsModal();
              }
            }}
            className="helv-med text-18 mb-1">
            {currentGroup?.groupName || ""}
            {isMobile && (
              <CustomIcon
                className="icon-default-black"
                style={{ marginTop: "-1" }}
                icon="drawer_draft_chevron"
                size={18}
              />
            )}
          </h2>
          <h3 className="text-14 m-0">
            <span className="helv-bold">{currentGroup?.membersCount || 0}</span>{" "}
            <span className="text-muted">
              {i18n.t("GroupsFeed.Banner.noOfMembers", {
                count: currentGroup?.membersCount
              })}
            </span>
          </h3>
        </div>
        <div className="d-flex flex-row align-self-center">
          {!isInstitutionalGroup && (
            <Button
              onClick={verifiedToggleInvite}
              className="helv-med text-14 text-default-black py-1">
              <CustomIcon
                style={{ marginTop: -1 }}
                icon={"groups_invite"}
                size={20}
              />{" "}
              {i18n.t("GroupsFeed.Banner.invite")}
            </Button>
          )}
          <GroupOptionsDropdown
            currentGroup={currentGroup}
            showMembersModal={showMembersModal}
          />
        </div>
        <PrivateGroupsModal
          isOpen={isPrivateGroupsModalOpen}
          toggle={togglePrivateGroupsModal}
        />
      </div>
    </Card>
  );
};

export default GroupsFeedBanner;
