/** @format */

import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain/";

const AnonymousAuthorsReducer = (
  state = InitialReducerStates.ANONYMOUS_AUTHORS_INITIAL_STATE,
  action
) => {
  const base = { ...BrainReducers.AnonymousAuthorsReducer(state, action) };

  switch (action.type) {
    default:
      return base;
  }
};

export default AnonymousAuthorsReducer;
