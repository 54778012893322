import React from "react";
import { Badge } from "reactstrap";
import CircleCustomIcon from "../custom-icon/CircleCustomIcon";
import CustomIcon from "../custom-icon/CustomIcon";
import "./F1FullScreenOptionsCard.scss";

const F1FullScreenOptionsCard = ({
  icon,
  iconImg,
  circleIcon,
  title,
  badgeText,
  description,
  descriptionMuted = false,
  showChevron,
  className,
  onOptionClick
}) => {
  /************************************ CONFIG ***************************************/

  /************************************ HOOKS ****************************************/

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/

  const displayIcon =
    !iconImg && circleIcon ? (
      <CircleCustomIcon
        circleColor="lighter-gray p-2"
        icon={icon}
        className="mr-2"
        size={26}
      />
    ) : (
      <CustomIcon className="mr-2" icon={icon} size="26" />
    );

  return (
    <div
      className={`h-100px bg-white shadow rounded p-3 d-flex align-items-center justify-content-between hover-option ${className}`}
      onClick={onOptionClick}>
      <div className="d-flex align-items-center justify-content-start">
        {icon && displayIcon}
        {iconImg && (
          <img
            alt="options"
            height={48}
            width={48}
            src={iconImg}
            className="mr-2"
          />
        )}
        <div className="pr-3 d-flex flex-column justify-content-center">
          <p className="m-0 helv-med text-18 line-height-28 d-flex align-items-center">
            {title}{" "}
            {badgeText && (
              <Badge
                style={{
                  backgroundImage:
                    "linear-gradient(102deg, #3bafa8 11%, #15223b 269%)"
                }}
                className="ml-2 text-uppercase text-white helv-med text-11">
                {badgeText}
              </Badge>
            )}
          </p>
          <p
            className={`m-0 text-15 line-height-20 ${
              descriptionMuted ? "text-battleship-gray" : "text-blue-gray"
            }`}>
            {description}
          </p>
        </div>
      </div>
      {showChevron && (
        <div>
          <CustomIcon className="" icon="drawer_chevron" size="22" />
        </div>
      )}
    </div>
  );
};

F1FullScreenOptionsCard.propTypes = {};

F1FullScreenOptionsCard.defaultProps = {};

export default F1FullScreenOptionsCard;
