/** @format */

import { get } from "lodash";
import CASE_TYPE from "../constants/case-type";
import { CONTENT_TYPE } from "../constants/content-type";
import moment from "moment";
import { CASE_DETAIL, CLINICAL_MOMENTS_DETAIL } from "../constants/routes";
import { getUrl } from "../utils/route-utils";
import { Constants } from "@figure1/f1-pro-fe-brain";
import { SHARE_CASE_TYPE } from "../metrics/constants.metrics";
import { isPoll } from "./tactic-utils";
import { isPhysician } from "./specialty-utils";
import { isCaseAuthor, isUserUSA } from "./user-utils";

export const findCasesInFeed = (caseId, feedContent) => {
  let result = [];
  for (let feed of Object.values(feedContent)) {
    const cases = feed.filter((c) => c.caseUuid === caseId);
    result = [...result, ...cases];
  }
  return result;
};

export function getCurrentContentItem(caseContent, currentIndex) {
  const detailsItems = get(caseContent, "contentItems", []);
  const sortedItems = detailsItems.sort(
    (i1, i2) => i1.displayOrder - i2.displayOrder
  );
  return sortedItems[currentIndex];
}

export function hideCommentsSwitch(caseContent, currentIndex) {
  const currentItem = getCurrentContentItem(caseContent, currentIndex) || {};

  const { commentsEnabled = false, commentTabsEnabled = true } =
    currentItem?.features ?? {};

  /**
   * Note: The backend will be setting `content.features` appropriately such that comments will
   * be enabled on the correct content only. So won't need to check if we're on Quiz Summary.
   * Can delete below once that change is made!
   */
  let areCommentsAllowedForCurrentItem = true;

  if (caseContent?.caseType === CASE_TYPE.QUIZ_SERIES) {
    const isLastItem =
      currentIndex === get(caseContent, "contentItems", []).length - 1;
    if (currentItem.contentType !== CONTENT_TYPE.QUIZ_SUMMARY) {
      areCommentsAllowedForCurrentItem = false;
    } else if (currentItem.isPoll && !isLastItem) {
      areCommentsAllowedForCurrentItem = false;
    }
  }

  const mustDisplayComments =
    commentsEnabled && areCommentsAllowedForCurrentItem;

  const shouldHideCommentsSwitch = !mustDisplayComments || !commentTabsEnabled;

  return shouldHideCommentsSwitch;
}

// paging is deactivated after 48 hours
export const decideIfPagingIsActive = (publishingDate, isCasePaging) => {
  let isPagingActive = false;
  const hoursSincePublishing = moment().diff(moment(publishingDate), "hours");

  if (isCasePaging && hoursSincePublishing <= 48) {
    isPagingActive = true;
  }
  return isPagingActive;
};

export const getCaseTypeUrl = (caseContent) => {
  let routeUrl = CASE_DETAIL;

  switch (caseContent?.caseType) {
    case CASE_TYPE.CME:
    case CASE_TYPE.CLINICAL_MOMENTS:
      routeUrl = CLINICAL_MOMENTS_DETAIL;
      break;
    default:
      break;
  }
  return getUrl(routeUrl, {
    caseId: caseContent?.caseUuid
  });
};

export const getShareCaseType = (caseContent) => {
  switch (caseContent.caseType) {
    case Constants.CaseType.CASE_TYPE.STATIC: {
      return SHARE_CASE_TYPE.STATIC;
    }
    case Constants.CaseType.CASE_TYPE.QUIZ: {
      return caseContent.isPoll || isPoll(caseContent)
        ? SHARE_CASE_TYPE.POLL
        : SHARE_CASE_TYPE.QUIZ;
    }
    case Constants.CaseType.CASE_TYPE.QUIZ_SERIES: {
      return caseContent.isPoll || isPoll(caseContent)
        ? SHARE_CASE_TYPE.POLL_SERIES
        : SHARE_CASE_TYPE.QUIZ_SERIES;
    }
    case Constants.CaseType.CASE_TYPE.CLINICAL_MOMENTS: {
      return SHARE_CASE_TYPE.CLINICAL_MOMENTS;
    }
    case Constants.CaseType.CASE_TYPE.CME: {
      return SHARE_CASE_TYPE.CME;
    }
    default: {
      return "";
    }
  }
};

export const isCaseCmeEligible = ({ user, ungated, caseData }) => {
  const isUSPhysician = isUserUSA(user) && isPhysician(user);
  const isAuthor = isCaseAuthor({ caseContent: caseData, user });
  const isCase =
    caseData?.caseClassification ===
    Constants.CaseType.CASE_CLASSIFICATIONS.MEDICAL;

  if (!caseData) {
    return false;
  }

  // the labels check is a conditional fallback that can probably be removed
  // in the near future once the isCaseCme flag is consistently everywhere -- Corey
  return (
    (caseData.isCaseCme || caseData.labels?.indexOf("case_cme") > -1) &&
    isUSPhysician &&
    !ungated &&
    !isAuthor &&
    isCase
  );
};
