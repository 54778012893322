const useGetCommentContentID = (contentItems) => {
  let contentUuidForComments = null;

  if (contentItems?.length === 1) {
    contentUuidForComments = contentItems[0].contentUuid;
  } else if (contentItems?.length > 1) {
    const contentComments = contentItems.find((item) => item.hasComments);
    contentUuidForComments = contentComments?.contentUuid;
  }

  return contentUuidForComments;
};

export default useGetCommentContentID;
