import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
import { GROUP_TYPE } from "../constants/group-constant";
import { isInstitutionGroup } from "../utils/GroupUtils";
import { useParams } from "react-router-dom";

export const useSelectUserGroups = () => {
  return useSelector((state) => {
    const userGroupUuids = state.user.groups?.map((g) => g.groupUuid);
    if (isEmpty(userGroupUuids)) {
      return [];
    }

    const allGroups = state.groups?.data
      ? Object.values(state.groups?.data)
      : [];
    if (isEmpty(allGroups)) {
      return [];
    }

    return allGroups
      .filter((g) => userGroupUuids.includes(g.groupUuid))
      .sort((a, b) => {
        if (isInstitutionGroup(a) && !isInstitutionGroup(b)) {
          return -1;
        } else if (!isInstitutionGroup(a) && isInstitutionGroup(b)) {
          return 1;
        } else {
          return a.groupName.localeCompare(b.groupName);
        }
      });
  });
};

export const useFirstInstitutionGroupUuid = () => {
  const userGroups = useSelectUserGroups();
  const firstInstitutionGroup = userGroups.find(
    (g) => g.groupType === GROUP_TYPE.INSTITUTION
  );
  return firstInstitutionGroup?.groupUuid;
};

export const useRedirectGroupUuid = () => {
  const routeParams = useParams();
  const userGroups = useSelectUserGroups();
  if (routeParams?.feedTypeUuid) {
    return routeParams.feedTypeUuid;
  }
  if (userGroups.length === 0) {
    return;
  }
  const firstInstitutionGroup = userGroups.find(
    (g) => g.groupType === GROUP_TYPE.INSTITUTION
  );
  return firstInstitutionGroup?.groupUuid || userGroups[0]?.groupUuid;
};

export const useCurrentGroup = () => {
  return useSelector((state) => state.groups?.currentGroup);
};
