/** @format */

import React from "react";
import PropTypes from "prop-types";
import { Card, CardBody, CardText } from "reactstrap";
import ReactMarkdown from "react-markdown";
import { isEmpty } from "lodash";
import { ContentProp } from "../../../prop-types/ContentProp";
import CaseMediaView from "../../../components/case/CaseMediaView";
import CMSlideBase from "./CMSlideBase";
import QuestionExternalLink from "../../../components/case/QuestionExternalLink";
import CaseReferences from "../../../components/case/CaseReferences";
import { CASE_CARD_ORIGIN } from "../../../metrics/constants.metrics";

const CMNormalSlide = ({ content, children }) => {
  /****************************** Config **************************************/
  const hasMedia = !isEmpty(content?.media);

  const hasCTA = !!content?.externalLinkUrl && !!content?.externalLinkText;

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/

  /****************************** Render **************************************/

  return (
    <CMSlideBase content={content}>
      <Card className="border-0 rounded-0">
        {hasMedia && (
          <CaseMediaView
            className="pb-3"
            caseUuid={content.contentUuid}
            caseType={content.caseType}
            media={content.media}
            showImages={true}
            context={CASE_CARD_ORIGIN.DETAIL}
          />
        )}
        <CardBody className="pt-0">
          <CardText
            className="feed-card-info feed-card-info-markdown feed-card-detail"
            tag="div">
            <ReactMarkdown>{content.caption}</ReactMarkdown>
          </CardText>
        </CardBody>
        {children}
        {content?.references && (
          <CardBody className="bg-white">
            <CaseReferences references={content?.references} />
          </CardBody>
        )}
        {hasCTA && (
          <CardBody className="pt-0">
            <QuestionExternalLink
              caseUuid={content.caseUuid}
              contentUuid={content.contentUuid}
              campaignUuid={content.campaignUuid}
              text={content.externalLinkText}
              url={content.externalLinkUrl}
            />
          </CardBody>
        )}
      </Card>
    </CMSlideBase>
  );
};

CMNormalSlide.propTypes = {
  content: ContentProp,
  children: PropTypes.node
};

export default CMNormalSlide;
