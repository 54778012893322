import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as ROUTES from "../../constants/routes";
import { getUrl, userSignedIn } from "../../utils/route-utils";
import i18n from "../../utils/i18n";
import fallbackImg from "../../assets/images/web hero.webp";
import { useParams } from "react-router-dom";
import Figure1Page1Col from "../../@layouts/Figure1Page1Col";
import Figure1Layout from "../../@layouts/Figure1Layout";
import "./ClaimGroupPage.scss";
import groupActions from "../../actions/groups.actions";
import { useHistory } from "react-router-dom";
import {
  clearEmailMismatchModal,
  hideGlobalMessage,
  setEmailMismatchModal
} from "../../actions/global.actions";
import RegistrationHeaderV2 from "./components/RegistrationHeader.v2";
import useInitialRouteRedirect from "../../hooks/useInitialRouteRedirect";
import ClaimGroupSignupForm from "./components/ClaimGroupSignupForm";
import { getPotentialGroupDetails } from "../../actions/reference.actions";
import ClaimGroupSigninForm from "./components/ClaimGroupSigninForm";
import { getRegistrationRoute } from "../../actions/login.actions";
import Loading from "../../components/loading";
import { isEmpty } from "lodash";

const ClaimGroupPage = () => {
  /** ********************************** CONFIG ***************************************/
  const { groupFilterUuid, groupUuid } = useParams();

  const history = useHistory();
  const dispatch = useDispatch();

  const [showSignUpFlow, setShowSignUpFlow] = useState(true);

  const user = useSelector((state) => state.user);
  const potentialGroupDetails = useSelector(
    (state) => state?.references?.potentialGroupDetails?.data || {}
  );

  const usersGroupsArray = user?.groups || [];
  const usersGroups = usersGroupsArray.map((g) => g.groupUuid);
  const isSignedIn = useSelector((state) => userSignedIn(state.user));

  const isLoadingGroups = useSelector((state) => state.groups?.isProcessing);

  const isLoadingPotGroupDetails = useSelector((state) =>
    groupFilterUuid ? state.references.potentialGroupDetails?.loading : false
  );

  const allGroups = useSelector((state) => state.groups?.data);
  const selectedGroup = useSelector((state) => state.groups?.currentGroup);
  /** ********************************* FUNCTIONS *************************************/

  useInitialRouteRedirect();

  useEffect(() => {
    if (groupUuid) {
      dispatch(groupActions.fetchGroupsByIds([groupUuid]));
    }
  }, [dispatch, groupUuid]);

  useEffect(() => {
    if (groupFilterUuid) {
      dispatch(getPotentialGroupDetails(groupFilterUuid));
    }
  }, [dispatch, groupFilterUuid]);

  useEffect(() => {
    if (isEmpty(selectedGroup)) {
      if (groupFilterUuid) {
        dispatch(
          groupActions.selectGroup(potentialGroupDetails?.potentialGroup || {})
        );
      } else {
        dispatch(groupActions.selectGroup(allGroups[groupUuid] || {}));
      }
    }
  }, [
    dispatch,
    groupFilterUuid,
    groupUuid,
    potentialGroupDetails,
    selectedGroup,
    allGroups
  ]);

  // hide error toast when moving away from login screen
  useEffect(() => {
    const unsub = history.listen(() => {
      dispatch(hideGlobalMessage());
    });

    return () => {
      unsub();
    };
  }, [history, dispatch]);

  useEffect(() => {
    if (
      isSignedIn &&
      !isLoadingGroups &&
      !isLoadingPotGroupDetails &&
      !isEmpty(selectedGroup)
    ) {
      if (groupFilterUuid && user.email !== potentialGroupDetails.userEmail) {
        dispatch(setEmailMismatchModal(true, groupFilterUuid));
        const nextRoute = getRegistrationRoute(user);
        history.push(getUrl(nextRoute));
      } else if (!usersGroups.includes(selectedGroup.groupUuid)) {
        dispatch(
          groupActions.addMembers(selectedGroup.groupUuid, [user?.userUuid])
        );

        history.push(
          getUrl(ROUTES.GROUP_WELCOME_SPLASH, {
            groupUuid: selectedGroup.groupUuid
          })
        );
      } else {
        dispatch(clearEmailMismatchModal());
        const nextRoute = getRegistrationRoute(user);
        history.push(getUrl(nextRoute));
      }
    }
  }, [
    dispatch,
    groupFilterUuid,
    history,
    isLoadingGroups,
    isLoadingPotGroupDetails,
    isSignedIn,
    potentialGroupDetails,
    potentialGroupDetails.userEmail,
    selectedGroup.groupUuid,
    user,
    usersGroups
  ]);

  const switchFlow = () => {
    setShowSignUpFlow(!showSignUpFlow);
  };

  const targetForm = showSignUpFlow ? (
    <ClaimGroupSignupForm
      userEmail={groupFilterUuid ? potentialGroupDetails.userEmail : ""}
      groupUuid={selectedGroup.groupUuid}
      switchFlow={switchFlow}
    />
  ) : (
    <ClaimGroupSigninForm
      userEmail={groupFilterUuid ? potentialGroupDetails.userEmail : ""}
      groupUuid={selectedGroup.groupUuid}
      switchFlow={switchFlow}
    />
  );

  /** ********************************** RENDER ***************************************/
  return (
    <Figure1Layout noGatedHeader={true} topHeader={<RegistrationHeaderV2 />}>
      <div className="d-flex flex-column align-items-center m-0">
        <>
          <div className="claimGroupPage">
            <h1 className="text-white text-center helv-standard">
              {i18n.t("RegistrationScreens.claimGroupPage.title", {
                groupname: selectedGroup.groupName
              })}
            </h1>
            <h2 className="text-center helv-standard text-white-80">
              {i18n.t("RegistrationScreens.claimGroupPage.subTitle", {
                groupname: selectedGroup.groupName
              })}
            </h2>
          </div>
          <div id="background-media">
            <img src={fallbackImg} alt="" />
          </div>
        </>
      </div>
      <div className="d-flex flex-column w-100 h-100 align-items-center m-0 bg-lighter-gray">
        <Figure1Page1Col
          mainContentSmall={
            <div className="mt-0 mt-5 mb-5">
              {(isLoadingGroups || isLoadingPotGroupDetails) && (
                <div style={{ height: 200 }}>
                  <Loading />
                </div>
              )}
              {!isLoadingGroups && !isLoadingPotGroupDetails && (
                <>{targetForm}</>
              )}
            </div>
          }
          headerClass="py-0"
          noGatedHeader={true}
          hideBackButton={true}
        />
      </div>
    </Figure1Layout>
  );
};

export default ClaimGroupPage;
