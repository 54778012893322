import React from "react";
import { StaticStrings } from "../../constants/static-string-constants";
import i18n from "../../utils/i18n";
import "./styles.scss";

const ProvisionalMemberMessagePopup = () => {
  /** ********************************** CONFIG ***************************************/

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <div>
      <h2 className="text-16 mb-2">
        {i18n.t("common.provisionalMemberPopup.subTitle1")}
      </h2>
      <ul className="text-14 pl-3">
        <li>{i18n.t("common.provisionalMemberPopup.li1")}</li>
        <li>{i18n.t("common.provisionalMemberPopup.li2")}</li>
        <li>{i18n.t("common.provisionalMemberPopup.li3")}</li>
      </ul>

      <h2 className="text-16 mb-2">
        {i18n.t("common.provisionalMemberPopup.subTitle2")}
      </h2>
      <ul className="text-14 pl-3">
        <li>{i18n.t("common.provisionalMemberPopup.li4")}</li>
        <li>{i18n.t("common.provisionalMemberPopup.li5")}</li>
        <li>{i18n.t("common.provisionalMemberPopup.li6")}</li>
        <li>{i18n.t("common.provisionalMemberPopup.li7")}</li>
        <li>{i18n.t("common.provisionalMemberPopup.li8")}</li>
      </ul>
      <p className="text-14 mb-0">
        {i18n.t("common.provisionalMemberPopup.contactUs1")}{" "}
        <a href={`mailto:${StaticStrings.supportEmail}`}>
          {i18n.t("common.provisionalMemberPopup.contactUsLink")}
        </a>{" "}
        {i18n.t("common.provisionalMemberPopup.contactUs2")}
      </p>
    </div>
  );
};

export default ProvisionalMemberMessagePopup;
