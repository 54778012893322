import React from "react";
import { useSelector } from "react-redux";
import icon from "../../../assets/images/moderator_review.svg";
import ModalWithText from "../../../components/common/ModalWithText";
import i18n from "../../../utils/i18n";

const SubmittedCaseModal = ({ draftUid, post, toggle, isOpen, route }) => {
  const submissionDraft = useSelector((state) =>
    draftUid ? state?.userDraft?.data?.[draftUid] : null
  );
  const isPaging = submissionDraft?.paging;
  const isAnonymous = submissionDraft?.isAnonymous;
  const isGroup = !!submissionDraft?.groupUuid;

  /** ********************************** CONFIG ***************************************/

  const title = isPaging
    ? "DraftsScreen.submittedDraftPageModalTitle"
    : "DraftsScreen.submittedDraftModalTitle";
  let body;

  if (isGroup) {
    if (!!post) {
      body = "DraftsScreen.submittedPostModal.groupDescription";
    } else {
      body = "DraftsScreen.submittedDraftGroupModalBody";
    }
  } else {
    if (isPaging) {
      body = "DraftsScreen.submittedDraftPageModalBody";
    } else {
      if (!!post) {
        body = "DraftsScreen.submittedPostModal.description";
      } else {
        body = "DraftsScreen.submittedDraftModalBody";
      }
    }
  }

  const anonRider = isAnonymous ? (
    <div className="bg-lighter-gray p-3 text-center">
      <h2 className="helv-bold text-16">
        {i18n.t("DraftsScreen.submittedDraftAnonModalTitle")}
      </h2>
      <p className="mb-0 text-14">
        {i18n.t("DraftsScreen.submittedDraftAnonModalBody")}
      </p>
    </div>
  ) : null;
  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  const modalTitle = (
    <div className="mt-4">
      <img width="75" height="75" src={icon} alt="" />
      <p className="helv-med text-20 line-height-25 mt-3 mb-0 mr-5">
        {!!post
          ? i18n.t("DraftsScreen.submittedPostModal.title")
          : i18n.t(title)}
      </p>
    </div>
  );

  return (
    <ModalWithText
      toggle={toggle}
      isOpen={isOpen}
      title={modalTitle}
      dividerClass="border-bottom border-width-4 approved-border w-25 pt-3 px-4"
      confirmText="common.done"
      showFooter={true}
      showClose={false}
      route={route}>
      <>
        <p className="text-16 line-height-24 mb-3">{i18n.t(body)}</p>
        {anonRider}
      </>
    </ModalWithText>
  );
};

export default SubmittedCaseModal;
