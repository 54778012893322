/** @format */

import React from "react";
import { Card, CardBody, CardFooter } from "reactstrap";
import ReactMarkdown from "react-markdown";
import { get, isEmpty, trim } from "lodash";
import PropTypes from "prop-types";
import { getImageUrl } from "../../../utils/media-utils";
import SocialCountContainer from "../../../components/case/SocialCountContainer";
import { defaultFeatures } from "../../../constants/case-feature-constants";

import "./ProfileCaseCard.scss";

const IMG_DIMENSIONS = 86;

const ProfileCaseCard = ({ caseContent, onClick, position, isCurrentUser }) => {
  /** ********************************** CONFIG ***************************************/
  const imageUrl = get(caseContent, "media[0].url", "");
  const caseTitle = trim(caseContent.title) || "";
  const features = caseContent?.features || defaultFeatures;

  /** ********************************** HOOKS ****************************************/

  /** ********************************* FUNCTIONS *************************************/

  /** ********************************** RENDER ***************************************/

  return (
    <Card className="border-0 rounded-0 cursor-pointer" onClick={onClick}>
      <CardBody className="p-3 d-flex flex-row justify-content-between w-100">
        <div>
          {caseTitle ? (
            <p className="mb-2 text-16 font-weight-bold text-clamp-21-2 card-title">
              {caseTitle}
            </p>
          ) : null}
          <p className="feed-card-info feed-card-info-markdown mb-0 text-14 text-clamp-21-3">
            <ReactMarkdown>{caseContent.caption ?? ""}</ReactMarkdown>
          </p>
        </div>
        <div className="">
          {isEmpty(imageUrl) ? null : (
            <img
              className="ml-3 rounded"
              style={{
                width: `${IMG_DIMENSIONS}px`,
                height: `${IMG_DIMENSIONS}px`
              }}
              width={IMG_DIMENSIONS}
              height={IMG_DIMENSIONS}
              src={getImageUrl(
                { type: "image", url: imageUrl },
                IMG_DIMENSIONS,
                IMG_DIMENSIONS,
                "crop"
              )}
              alt=""
            />
          )}
        </div>
      </CardBody>
      <CardFooter className="px-3 bg-white">
        <SocialCountContainer
          caseContent={caseContent}
          hideComments={!features.commentsEnabled}
          hideReactions={!features.reactionsEnabled}
          activateListener={false}
        />
      </CardFooter>
    </Card>
  );
};

ProfileCaseCard.propTypes = {
  caseContent: PropTypes.shape({
    title: PropTypes.string,
    caption: PropTypes.string,
    commentCount: PropTypes.number,
    media: PropTypes.arrayOf(
      PropTypes.shape({
        url: PropTypes.string
      })
    )
  }),
  onClick: PropTypes.func.isRequired
};

export default ProfileCaseCard;
