import { isCaseCmeEligible } from "../utils/case-utils";
import { useSelectCurrentUser } from "../selectors/user.selectors";
import useIsGated from "./useIsGated";

const useCaseCmeEligible = (caseData) => {
  const user = useSelectCurrentUser();
  const ungated = useIsGated();

  return isCaseCmeEligible({ user, ungated, caseData });
};

export default useCaseCmeEligible;
