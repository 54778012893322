import React from "react";
import { Button, Card, CardBody, CardTitle } from "reactstrap";
import figure1Logo from "../../assets/images/Fig.1 app icon_60px.png";
import classNames from "../../utils/class-names-utils";
import i18n from "../../utils/i18n";

const AppPromptSmall = React.forwardRef(function AppPromptSmall(
  { appUrl, cardClassName, confirmButtonColor, titleColor, onToggle },
  ref
) {
  return (
    <Card
      className={classNames("p-1 rounded-0 border-0", cardClassName)}
      innerRef={ref}>
      <CardBody className="d-inline-flex justify-content-between align-items-center p-2">
        <div className="d-inline-flex justify-content-start align-items-center">
          <img
            className="rounded-sm"
            src={figure1Logo}
            alt="Figure 1"
            height={34}
            width={34}
          />
          <CardTitle
            tag="h6"
            className={classNames("mb-0 mx-2", `text-${titleColor}`)}>
            {i18n.t("AppPrompt.smallPrompt.title")}
          </CardTitle>
        </div>
        <Button
          tag="a"
          href={appUrl}
          className="btn-text text-primary font-weight-bold flex-shrink-0"
          color={confirmButtonColor}
          onClick={onToggle}>
          {i18n.t("AppPrompt.btnConfirm")}
        </Button>
      </CardBody>
    </Card>
  );
});

export default AppPromptSmall;
