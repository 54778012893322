/** @format */

import React, { useEffect, useState } from "react";
import { FormFeedback, FormGroup, FormText, Input, Label } from "reactstrap";
import { cloneDeep } from "lodash";
import CMEFormElementCheckbox from "./CMEFormElementCheckbox";
import { Controller } from "react-hook-form";
import i18n from "../../../utils/i18n";

const CMEFormElementCheckboxFieldset = ({
  answerSet,
  control,
  errors,
  checkCB,
  index,
  checkMap
}) => {
  const wrappedCheckCB = (answer, value) => {
    checkCB(index, answer, value);
  };

  const legendEl = answerSet.answerGroupHeading ? (
    <legend className="text-battleship-gray text-uppercase text-14 m-0 mb-2">
      {answerSet.answerGroupHeading}
    </legend>
  ) : null;

  return (
    <FormGroup tag="fieldset" className="mb-0">
      {legendEl}
      {answerSet.answers.length > 1 && (
        <FormText className="mb-3 text-13">
          {i18n.t("CaseCMEQuestions.selectAllText")}
        </FormText>
      )}
      {answerSet.answers.map((answer) => (
        <CMEFormElementCheckbox
          key={answer.answerUuid}
          control={control}
          errors={errors}
          checkCB={wrappedCheckCB}
          answer={answer}
          checked={checkMap[index][answer.answerUuid]}
          className="mb-1"
        />
      ))}
    </FormGroup>
  );
};

const CMEFormElementCheckboxList = ({
  question,
  control,
  errors,
  onFieldDirtied,
  setValue
}) => {
  /****************************** Config **************************************/
  const [answerGroupCheckMap, setAnswerGroupCheckMap] = useState(null);
  const [activeAGIndex, setActiveAGIndex] = useState(-1);

  const answerGroups = question?.answerGroups || [];
  const fielsetName = `${question.questionUuid}-fieldset`;

  /****************************** Hooks ***************************************/

  useEffect(() => {
    if (answerGroups.length) {
      // set up the initial checkbox AG mapping
      const t = answerGroups.map((ag) => {
        const answers = {};
        ag.answers?.map((answer) => {
          answers[answer.answerUuid] = false;
        });

        return answers;
      });
      setAnswerGroupCheckMap(t);
    }
  }, [answerGroups]);
  /****************************** Functions ***********************************/

  const onChildChange = (index, answer, value) => {
    const newAnswers = cloneDeep(answerGroupCheckMap);
    newAnswers[index][answer.answerUuid] = value;

    // force other ag values to false
    newAnswers.forEach((ag, i) => {
      if (i === index) {
        return;
      }
      Object.keys(ag).forEach((key) => (newAnswers[i][key] = false));
    });

    // finding out if ANY checkboxes are on
    let validAgIndex = newAnswers.findIndex((ag, i) => {
      const foundKey = Object.keys(ag).find((key) => newAnswers[i][key]);
      return !!foundKey;
    });

    setAnswerGroupCheckMap(newAnswers);
    if (validAgIndex > -1) {
      if (validAgIndex !== activeAGIndex) {
        setActiveAGIndex(validAgIndex);
      }
      setValue(fielsetName, true, { shouldValidate: true });
      onFieldDirtied(question, question?.answerGroups[validAgIndex].answers[0]);
    } else {
      setActiveAGIndex(-1);
      setValue(fielsetName, false, { shouldValidate: true });

      onFieldDirtied(question, null);
    }
  };

  /****************************** Render **************************************/
  if (!answerGroupCheckMap) {
    return null;
  }

  return (
    <FormGroup>
      <Label className="text-15 font-weight-500 text-default-black mb-0">
        {question.questionText}
      </Label>
      <Controller
        control={control}
        name={fielsetName}
        rules={{
          required: true
        }}
        invalid={!!errors[fielsetName]}
        as={<Input />}
        type="hidden"
        defaultValue={false}
      />
      {!!errors[fielsetName] && (
        <FormFeedback className="mb-3 mt-0 text-13">
          {i18n.t("CaseCMEQuestions.requiredError")}
        </FormFeedback>
      )}

      {question.answerGroups.map((ag, i) => (
        <CMEFormElementCheckboxFieldset
          checkMap={answerGroupCheckMap}
          key={i}
          control={control}
          errors={errors}
          checkCB={onChildChange}
          answerSet={ag}
          index={i}
        />
      ))}
    </FormGroup>
  );
};

export default CMEFormElementCheckboxList;
