/** @format */

import React from "react";
import PropTypes from "prop-types";
import { ListGroupItem } from "reactstrap";
import classNames from "../../utils/class-names-utils";

const POSSIBLE_VOTE_STATE = {
  DEFAULT: "NO ANSWER",
  POLL: "POLL",
  POLL_USER_ANSWER: "POLL_USER_ANSWER",
  NEUTRAL_ANSWER: "NEUTRAL_ANSWER",
  RIGHT_ANSWER: "RIGHT_ANSWER",
  WRONG_ANSWER: "WRONG_ANSWER",
  CME_ANSWER: "CME_ANSWER",
  CME: "CME"
};

const additionalStyle = {
  [POSSIBLE_VOTE_STATE.DEFAULT]: {
    container: ["border-light-gray"],
    text: [],
    gauge: []
  },
  [POSSIBLE_VOTE_STATE.POLL]: {
    container: ["border-light-gray"],
    text: ["text-battleship-gray"],
    gauge: ["bg-light-gray", "opacity-40"]
  },
  [POSSIBLE_VOTE_STATE.POLL_USER_ANSWER]: {
    container: ["border-cool-blue"],
    text: ["text-cool-blue"],
    gauge: ["bg-cool-blue", "opacity-20"]
  },
  [POSSIBLE_VOTE_STATE.NEUTRAL_ANSWER]: {
    container: ["border-light-gray"],
    text: ["text-battleship-gray"],
    gauge: ["bg-lighter-gray"]
  },
  [POSSIBLE_VOTE_STATE.RIGHT_ANSWER]: {
    container: ["border-aqua", "border-width-1"],
    text: ["text-aqua"],
    gauge: ["bg-aqua-opaque"]
  },
  [POSSIBLE_VOTE_STATE.WRONG_ANSWER]: {
    container: ["border-red", "border-width-1"],
    text: ["text-red"],
    gauge: ["bg-red-opaque"]
  },
  [POSSIBLE_VOTE_STATE.CME_ANSWER]: {
    container: ["border-cool-blue", "border-width-1"],
    text: ["text-cool-blue"],
    gauge: ["bg-cool-blue", "opacity-20"]
  },
  [POSSIBLE_VOTE_STATE.CME]: {
    container: ["border-lightBlueGrey", "border-width-1"],
    text: ["text-battleship-grey"],
    gauge: []
  }
};

function computeAnswerState(userVote, questionOptionUuid, isAnswer, type) {
  let state = POSSIBLE_VOTE_STATE.DEFAULT;

  if (type === QUESTION_TYPE.CME_QUIZ) {
    if (userVote) {
      if (userVote === questionOptionUuid) {
        state = POSSIBLE_VOTE_STATE.CME_ANSWER;
      } else {
        state = POSSIBLE_VOTE_STATE.CME;
      }
    }
  } else if (type === QUESTION_TYPE.POLL && userVote) {
    if (userVote === questionOptionUuid) {
      state = POSSIBLE_VOTE_STATE.POLL_USER_ANSWER;
    } else {
      state = POSSIBLE_VOTE_STATE.POLL;
    }
  } else if (type === QUESTION_TYPE.QUIZ && userVote) {
    const isUserVote = userVote === questionOptionUuid;
    if (isAnswer) {
      state = POSSIBLE_VOTE_STATE.RIGHT_ANSWER;
    } else if (isUserVote && !isAnswer) {
      state = POSSIBLE_VOTE_STATE.WRONG_ANSWER;
    } else {
      state = POSSIBLE_VOTE_STATE.NEUTRAL_ANSWER;
    }
  }
  return state;
}

function computeVotePercentage(
  optionVotes,
  userVote,
  questionOptionUuid,
  totalVotes,
  type
) {
  if (type === QUESTION_TYPE.CME_QUIZ) {
    return 100;
  }

  const newOptionVote = optionVotes + (userVote === questionOptionUuid ? 1 : 0);
  const ratio = newOptionVote / (totalVotes + 1);
  return (ratio * 100).toFixed(0);
}

const VoteOptionItem = (props) => {
  /****************************** Config **************************************/
  const percentage = computeVotePercentage(
    props.option.votes,
    props.userVote,
    props.option.questionOptionUuid,
    props.totalVotes,
    props.type
  );

  const optionState = computeAnswerState(
    props.userVote,
    props.option.questionOptionUuid,
    props.option.isAnswer,
    props.type
  );

  const showPercent = ![
    POSSIBLE_VOTE_STATE.DEFAULT,
    POSSIBLE_VOTE_STATE.CME_ANSWER,
    POSSIBLE_VOTE_STATE.CME
  ].includes(optionState);

  /****************************** Hooks ***************************************/

  /****************************** Functions ***********************************/
  const onVote = () => {
    props.onVote(props.option.questionOptionUuid);
  };

  /****************************** Render **************************************/
  return (
    <ListGroupItem
      tag={!!props.userVote === false && !props.readOnly ? "button" : undefined}
      action={!!props.userVote === false && !props.readOnly}
      className={classNames(
        optionState,
        "rounded border-width-1 overflow-hidden",
        ...additionalStyle[optionState].container
      )}
      onClick={onVote}>
      <div
        className={classNames(
          "position-absolute t-0 r-0 b-0 l-0",
          ...additionalStyle[optionState].gauge
        )}
        style={{ width: `${percentage}%` }}
      />
      <div
        className={classNames(
          "position-relative d-inline-flex justify-content-between text-16 text-default-black line-height-tight w-100"
        )}>
        <p className={classNames("mb-0", ...additionalStyle[optionState].text)}>
          {props.option.text}
        </p>
        {showPercent && (
          <p
            className={classNames(
              "mb-0 pl-3",
              ...additionalStyle[optionState].text
            )}>
            {percentage}%
          </p>
        )}
      </div>
    </ListGroupItem>
  );
};

VoteOptionItem.propTypes = {
  userVote: PropTypes.string,
  totalVotes: PropTypes.number,
  type: PropTypes.string,
  onVote: PropTypes.func.isRequired,
  option: PropTypes.shape({
    displayOrder: PropTypes.number,
    isAnswer: PropTypes.bool,
    questionOptionUuid: PropTypes.string,
    text: PropTypes.string,
    votes: PropTypes.number
  }).isRequired
};

export default VoteOptionItem;

export const QUESTION_TYPE = {
  QUIZ: "quiz",
  POLL: "poll",
  CME_QUIZ: "cme-quiz"
};
