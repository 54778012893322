/** @format */

import {
  Reducers as BrainReducers,
  InitialReducerStates
} from "@figure1/f1-pro-fe-brain/";

const QuestionReducer = (
  state = InitialReducerStates.QUESTION_INITIAL_STATE,
  action
) => {
  // Do everything in base first, and then anything custom after
  const base = { ...BrainReducers.QuestionReducer(state, action) };

  return base;
};

export default QuestionReducer;
