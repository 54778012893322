import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Button } from "reactstrap";
import { trackResetPasswordEntry } from "../../../../actions/metrics.actions";
import BaseDialogFooter from "../../../../components/dialog/BaseDialogFooter";
import Dialog from "../../../../components/dialog/Dialog";
import DIALOG_TYPE from "../../../../components/dialog/DialogType";
import i18n from "../../../../utils/i18n";

const PasswordResetLinkDialog = ({ isOpen, onClosed, onResetClick }) => {
  /************************************ CONFIG ***************************************/
  const processing = useSelector((state) => state.account.resetting);
  /************************************ HOOKS ****************************************/

  useEffect(() => {
    if (isOpen) {
      trackResetPasswordEntry();
    }
  }, [isOpen]);

  /*********************************** FUNCTIONS *************************************/

  /************************************ RENDER ***************************************/
  return (
    <Dialog
      isOpen={isOpen}
      type={DIALOG_TYPE.INFORM}
      body={
        <div>
          <h5 className="helv-bold text-22 my-4">
            {i18n.t("Settings.Account.passwordResetLinkDialog.title")}
          </h5>
          <p className="mb-4 text-18 line-height-26">
            {i18n.t("Settings.Account.passwordResetLinkDialog.description")}
          </p>
        </div>
      }
      customFooter={
        <BaseDialogFooter>
          <Button
            className={"text-16 helv-bold px-5 py-2 mx-auto"}
            block
            size="lg"
            color="primary"
            disabled={processing}
            onClick={onResetClick}>
            {i18n.t("Settings.Account.passwordResetLinkDialog.sendLink")}
          </Button>
          <Button
            className={"text-16 helv-bold px-5 py-2 mx-auto mt-3 btn-text"}
            block
            size="lg"
            color="battleship-gray"
            onClick={onClosed}>
            {i18n.t("Settings.Account.cancelButton")}
          </Button>
        </BaseDialogFooter>
      }
      toggle={onClosed}
      onCancel={onClosed}
      onClosed={onClosed}
    />
  );
};

export default PasswordResetLinkDialog;
