import React from "react";
import { useDispatch, useSelector } from "react-redux";
import i18n from "../../utils/i18n";
import CustomIcon from "../custom-icon/CustomIcon";
import { Button } from "reactstrap";
import { toggleExternalLinkModal } from "../../actions/global.actions";
import BaseDialog from "../dialog/BaseDialog";
import BaseDialogHeader from "../dialog/BaseDialogHeader";
import BaseDialogBody from "../dialog/BaseDialogBody";

import logoIcon from "../../assets/images/app_icon_rounded@2x.svg";

const ExternalLinkModal = () => {
  const dispatch = useDispatch();

  const externalLink = useSelector((state) => state.global.externalModalLink);
  const modalVisible = useSelector(
    (state) => state.global.externalModalVisible
  );

  const clearGatedMessage = () => {
    dispatch(toggleExternalLinkModal(false));
  };

  const onClosed = () => {
    clearGatedMessage();
  };

  const explanationTextArr = i18n
    .t("ExternalLinkModal.explanation")
    .split("$LINK_TEXT")
    .map((e, i) => <span key={i}>{e}</span>);
  explanationTextArr.splice(
    1,
    0,
    <em key="99">{i18n.t("ExternalLinkModal.openLinkText")}</em>
  );

  if (!modalVisible) return null;

  return (
    <BaseDialog isOpen={modalVisible} centered>
      <BaseDialogHeader center onCancel={onClosed} showCloseButton>
        {" "}
      </BaseDialogHeader>

      <BaseDialogBody className="align-items-center">
        <img
          src={logoIcon}
          className="m-4 mb-5"
          alt=""
          style={{ width: "81px" }}
        />
        <h3 className="helv-med text-20">
          {i18n.t("ExternalLinkModal.title")}
        </h3>
        <p className="text-16">{explanationTextArr}</p>
        <p
          className="text-15 mb-4"
          style={{
            maxWidth: "80%",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
          <a
            href={externalLink}
            target="_blank"
            rel="noreferrer"
            data-bypass-block={true}
            onClick={onClosed}>{`${externalLink}`}</a>
        </p>
        <a
          href={externalLink}
          target="_blank"
          rel="noreferrer"
          data-bypass-block={true}
          className="btn btn-lg text-16 btn-primary helv-bold w-175px mb-3"
          onClick={onClosed}>
          {i18n.t("ExternalLinkModal.openLinkBtn")}
        </a>
        <Button
          color="link"
          className="text-16 helv-bold d-flex align-items-center mb-3"
          onClick={onClosed}>
          <CustomIcon
            icon="arrow_back"
            size="16"
            className="icon-denim-blue mr-2"
          />
          {i18n.t("ExternalLinkModal.backBtn")}
        </Button>
      </BaseDialogBody>
    </BaseDialog>
  );
};

ExternalLinkModal.propTypes = {};

export default ExternalLinkModal;
